export function arrayFromRange(start: number, stop: number, step?: number) {
    var a = [start],
        b = start;
    while (b < stop) {
        a.push((b += step || 1));
    }
    return a;
}

export function splitConsecutiveNumberArray(array: number[]): number[][] {
    const result: number[][] = array.reduce((r, n) => {
        const lastSubArray = r[r.length - 1];

        if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== n - 1) {
            r.push([]);
        }

        r[r.length - 1].push(n);

        return r;
    }, []);

    return result;
}

export function chunkArray(array: any[], chunkSize: number): any[] {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        chunks.push(chunk);
    }
    return chunks;
}
