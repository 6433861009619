import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';

@Directive({
    selector: '[dropZone]'
})
export class DropZoneDirective implements OnChanges {
    @Input() label: string;
    @Input() description: string;
    @Output() dropped = new EventEmitter<FileList>();
    @Output() hovered = new EventEmitter<boolean>();

    constructor(private _el: ElementRef) {}

    ngOnChanges({ label, description }: SimpleChanges) {
        if (label) {
            this._el.nativeElement.setAttribute('data-dropzone-label', this.label);
        }
        if (description) {
            this._el.nativeElement.setAttribute('data-dropzone-description', this.description);
        }
    }

    @HostListener('drop', ['$event'])
    onDrop($event) {
        $event.preventDefault();
        this.dropped.emit($event.dataTransfer.files);
        this.hovered.emit(false);
    }

    @HostListener('dragover', ['$event'])
    onDragOver($event) {
        $event.preventDefault();
        this.hovered.emit(true);
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave($event) {
        $event.preventDefault();
        this.hovered.emit(false);
    }
}
