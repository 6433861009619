import { EditorService } from '../../services/editor.service';
import { Command } from '../command';

export class ChangeSubtitleBreakCommand implements Command {
    private readonly oldWordIndex: number;
    private readonly newWordIndex: number;
    private wordOldSubtitleState: boolean;
    private wordNewSubtitleState: boolean;
    private editorService: EditorService;

    constructor(changeSubtitleBreakCommand: { editorService; oldWordIndex: number; newWordIndex: number }) {
        this.editorService = changeSubtitleBreakCommand.editorService;
        this.oldWordIndex = changeSubtitleBreakCommand.oldWordIndex;
        this.newWordIndex = changeSubtitleBreakCommand.newWordIndex;
    }

    execute() {
        this.editorService.jumpToWordIfNotInside({ startWord: this.newWordIndex, endWord: this.newWordIndex });
        this.wordOldSubtitleState = this.editorService.currentTranscription.words[this.oldWordIndex].hasSubtitlebreak;
        this.wordNewSubtitleState = this.editorService.currentTranscription.words[this.newWordIndex].hasSubtitlebreak;
        this.editorService.currentTranscription.words[this.oldWordIndex].hasSubtitlebreak = false;
        this.editorService.currentTranscription.words[this.newWordIndex].hasSubtitlebreak = true;
        this.editorService.updateWords([this.oldWordIndex, this.newWordIndex]);
        return this;
    }

    undo() {
        this.editorService.jumpToWordIfNotInside({ startWord: this.oldWordIndex, endWord: this.oldWordIndex });
        this.editorService.currentTranscription.words[this.oldWordIndex].hasSubtitlebreak = this.wordOldSubtitleState;
        this.editorService.currentTranscription.words[this.newWordIndex].hasSubtitlebreak = this.wordNewSubtitleState;
        this.editorService.updateWords([this.oldWordIndex, this.newWordIndex]);
    }
}
