import { EditorService } from '../../services/editor.service';
import { Command } from '../command';

export class ReplaceWordCommand implements Command {
    private readonly oldValue: string;
    private readonly newValue: string;
    private editorService: EditorService;
    private readonly indicesToCheck: number[];

    constructor(replaceWordObject: {
        oldValue: string;
        newValue: string;
        indicesToCheck: number[];
        editorService: EditorService;
    }) {
        this.oldValue = replaceWordObject.oldValue;
        this.newValue = replaceWordObject.newValue;
        this.indicesToCheck = replaceWordObject.indicesToCheck;
        this.editorService = replaceWordObject.editorService;
    }

    execute() {
        this.replaceWords(this.oldValue, this.newValue);
        this.editorService.searchHighlightedIndices$.next(
            this.editorService.searchHighlightedIndices$
                .getValue()
                .filter((index) => !this.indicesToCheck.includes(index))
        );
        return this;
    }

    undo() {
        this.replaceWords(this.newValue, this.oldValue);
        this.editorService.searchHighlightedIndices$.next(
            this.editorService.searchHighlightedIndices$
                .getValue()
                .concat(...this.indicesToCheck)
                .sort((a, b) => a - b)
        );
    }

    private replaceWords(oldValue: string, newValue: string) {
        // Loop through all found indices
        for (const index of this.indicesToCheck) {
            const word = this.editorService.currentTranscription.words[index];
            // Check if the word at the search index includes the whole old value
            // This can be false if you search for "befree" which will result in two search indices - "be" and "free" -> in that case nothing should happen
            if (!word.text?.toLocaleLowerCase().trim().includes(oldValue.toLocaleLowerCase().trim()) && !word.isPause) {
                continue;
            }

            // TODO: When we update node just change it to replaceAll instead of regex
            // If its a pause just apply the new value directly as the regex might fail
            word.text = word.isPause
                ? newValue.trim()
                : word.text?.replace(new RegExp(oldValue, 'gi'), newValue.trim());
            word.isPause = word.text === '';
        }
        this.editorService.updateWords(this.indicesToCheck);
        this.editorService.updatePreviewTemplates(
            this.indicesToCheck[0],
            this.indicesToCheck[this.indicesToCheck.length - 1]
        );
    }
}
