import { defaultAudioLimitationExtensions, defaultVideoLimitationExtensions } from './limitations';

export interface WebsiteImport {
    name: string;
    service: ImportWebsite;
    regex: RegExp;
}

export enum ImportWebsite {
    YOUTUBE = 'youtube',
    GOOGLE_DRIVE = 'google_drive',
    DROPBOX = 'dropbox',
    SQUADCAST = 'squadcast',
    STREAMLABS = 'streamlabs'
}

export const WebsiteImports: WebsiteImport[] = [
    {
        name: 'YouTube',
        service: ImportWebsite.YOUTUBE,
        //(?:(https?:\/\/)?((www\.)|(m.))?youtu(\.be|(be\.com))\/?.*(watch|embed)?(.*v=|v\/|\/))([\w\-]+)+?
        regex: new RegExp(
            '(https?:\\/\\/)?((www\\.)|(m.))?youtu(\\.be|(be\\.com))\\/?.*(watch|embed)?(.*v=|v\\/|\\/)([\\w\\-]+)+?'
        )
    },
    {
        name: 'Google Drive',
        service: ImportWebsite.GOOGLE_DRIVE,
        // (https?:\/\/)?(www\.)?(drive.google.com\/file\/d\/)([a-z|A-Z|0-9]+)(\/view)?
        regex: new RegExp('(https?:\\/\\/)?(www\\.)?(drive.google.com\\/file\\/d\\/)([a-z|A-Z|0-9]+)(\\/view)?')
    },
    {
        name: 'Dropbox',
        service: ImportWebsite.DROPBOX,
        // Keep in mind in future if something is not working. These are all dropbox domains: https://help.dropbox.com/de-de/accounts-billing/security/official-domains#domains
        regex: new RegExp('(https?:\\/\\/)?(www\\.)?(dropbox.com\\/(s|sh|sc|scl|u|t)\\/)([a-z|A-Z|0-9]+)\\/(.*)')
    },
    {
        name: 'Squadcast',
        service: ImportWebsite.SQUADCAST,
        regex: new RegExp('(https?:\\/\\/)?(www\\.)?(sqdc.st\\/studio\\/)([a-z|A-Z|0-9]+)')
    },
    {
        name: 'Streamlabs',
        service: ImportWebsite.STREAMLABS,
        regex: new RegExp('(https?:\\/\\/)?(www\\.)?(streamlabs-storage(-staging)?.s3\\.)(\\S+)')
    }
];

/**
 * This is only for error handling. A correct url will pass the regex checks above. This array contains domains which we generally support but the user may have entered a private link or a folder.
 */
export const WebsiteImportDomains = ['drive.google.com'];

function getSupportedFileExtensionsAsRegExp() {
    const limitations = defaultVideoLimitationExtensions.concat(defaultAudioLimitationExtensions);
    return '(' + limitations.join('|') + ')';
}
