import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { PromotionCode } from '@type/shared-models/payment/promotion-code.models';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { UserService } from '../../services/payment-dialog-user.service';
import { UpgradeService } from '../../services/payment-dialog-upgrade.service';

@Component({
    selector: 'type-promotion-input',
    templateUrl: './promotion-input.component.html',
    styleUrls: ['./promotion-input.component.scss']
})
export class PromotionInputComponent implements OnDestroy {
    @Input() inPaymentProcess: boolean;
    @Output() promotionCodeInputSuccess = new EventEmitter<PromotionCode>();
    @Output() didRemovePromotionCode = new EventEmitter<void>();

    private promotionCodeInput: HTMLInputElement;
    @ViewChild('promotionCodeInput') set promotionCodeInputProperty(content: ElementRef) {
        if (content) {
            this.promotionCodeInput = content.nativeElement;
            this.promotionCodeInput.focus();
        }
    }

    promotionCodeInputValue: string;
    promotionCodeErrorMessage: string;
    promotionCodeValid: boolean;
    applyButtonVisible = false;
    requestPending = false;

    userSubscription: Subscription;
    validPromotionCode: string = null;
    constructor(private upgradeService: UpgradeService, private userService: UserService) {
        this.subscribeUser();
    }

    ngOnDestroy(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    /**
     * Send request to Stripe and checks if the promotion code is valid
     * if so, emit discount to upgrade parentComponent
     * if not set error state
     */
    public async applyPromotionCode() {
        // const validPromotionCode = false;
        if (this.promotionCodeInputValue) {
            const validatePromotionCodeData = await this.validatePromotionCode(this.promotionCodeInputValue);

            if (validatePromotionCodeData.valid) {
                //successfully added promotionCode

                this.promotionCodeErrorMessage = null;
                this.promotionCodeValid = true;
                this.validPromotionCode = validatePromotionCodeData.code;
                this.promotionCodeInputSuccess.emit(validatePromotionCodeData);
                this.updateUserPromotionCodeId(validatePromotionCodeData.promotionCodeId);
                this.openPayment();
            } else {
                this.promotionCodeErrorMessage = validatePromotionCodeData.message;
                console.log(this.promotionCodeErrorMessage);
            }
        } else {
            this.promotionCodeErrorMessage = 'No code entered.';
        }
    }

    /**
     *
     */
    public removePromotionCode() {
        this.promotionCodeValid = false;
        // this.promotionCodeInputActive = false;
        this.updateUserPromotionCodeId(null);
        this.didRemovePromotionCode.emit();
    }

    public promotionCodeInputChanged() {
        if (this.promotionCodeInputValue.trim() !== '') {
            this.promotionCodeInputValue = this.promotionCodeInputValue.toUpperCase();
            this.applyButtonVisible = true;
        } else {
            this.applyButtonVisible = false;
        }
    }

    /** Opens up the PromotionCode input field */
    public activatePromotionCodeInput() {
        console.log('add Promotion Code');
        // this.promotionCodeInputActive = true;
    }

    /** Hide input if nothing was entered */
    public deactivatePromotionCodeInput() {
        if (this.promotionCodeInputValue && this.promotionCodeInputValue.trim() === '') {
            // this.promotionCodeInputActive = false;
        }
    }
    private async validatePromotionCode(promotionCode: string): Promise<PromotionCode> {
        this.requestPending = true;
        const validatePromotionCodeData: PromotionCode = await this.upgradeService.validatePromotionCode(promotionCode);
        this.requestPending = false;
        return validatePromotionCodeData;
    }
    private updateUserPromotionCodeId(promotionCodeId: string) {
        this.userService.updatePromotionCodeId(promotionCodeId);
    }

    /** Checks in user if valid promotionCode exists
     * if so valid promotion code is shown to user
     * if not expired promotion code gets removed from user
     */
    private subscribeUser() {
        this.userSubscription = this.userService.user$
            .pipe(
                filter((user) => user != null),
                first()
            )
            .subscribe(async (user) => {
                if (user.promotionCodeId) {
                    const validatePromotionCodeData = await this.validatePromotionCode(user.promotionCodeId);
                    if (validatePromotionCodeData.valid) {
                        this.promotionCodeValid = true;
                        this.validPromotionCode = validatePromotionCodeData.code;
                        this.promotionCodeInputSuccess.emit(validatePromotionCodeData);
                    } else {
                        this.updateUserPromotionCodeId(null);
                    }
                }
            });
    }

    openPayment() {
        console.log('open');
        // this.paymentService.openModal();
    }
}
