import {
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges
} from '@angular/core';
import { DialogService } from '../dialog.service';
import { TooltipComponent } from './tooltip.component';
import { DialogRef } from '../dialog-ref';
import { DialogPositionsType } from '@type/dialog';

@Directive({ selector: '[appTooltip]' })
export class TooltipTriggerDirective implements OnChanges, OnDestroy {
    @Input() keys: string[];

    @HostBinding('attr.aria-label')
    @Input()
    appTooltip: string;

    @Input() description: string;
    @Input() disableTooltip: boolean;
    @Input() position: DialogPositionsType;
    ref: DialogRef;
    @HostBinding('class.app-tooltip') classToAdd = true;
    private toolTooltipTimeOut;
    @HostListener('mouseenter')
    openTooltip(openAfterMillis = 1000) {
        const openAfter = openAfterMillis;

        if (this.disableTooltip) return;
        this.toolTooltipTimeOut = setTimeout(() => {
            this.ref = this.dialogService.openPopover(TooltipComponent, this._el, {
                hasBackDrop: false,
                offsetY: 4,
                noAutoFocus: true,
                defaultPosition: this.position,
                data: {
                    keys: this.keys,
                    message: this.appTooltip,
                    description: this.description
                }
            });
        }, openAfter);
    }
    @HostListener('mouseleave')
    @HostListener('click')
    closeTooltip() {
        if (this.toolTooltipTimeOut) clearTimeout(this.toolTooltipTimeOut);
        this.ref?.close();
        this.ref = null;
    }

    constructor(private dialogService: DialogService, protected _el: ElementRef) {}

    ngOnChanges({ keys, description, appTooltip, disableTooltip }: SimpleChanges) {
        if (!this.ref) return;
        if (keys || description || appTooltip || disableTooltip) {
            this.closeTooltip();
            this.openTooltip();
        }
    }

    ngOnDestroy() {
        this.closeTooltip();
    }

    openTooltipManually(openAfterMillis = 1000) {
        const openAfter = openAfterMillis;

        // if (this.disableTooltip) return;
        this.toolTooltipTimeOut = setTimeout(() => {
            this.ref = this.dialogService.openPopover(TooltipComponent, this._el, {
                hasBackDrop: false,
                offsetY: 4,
                noAutoFocus: true,
                defaultPosition: this.position,
                data: {
                    keys: this.keys,
                    message: this.appTooltip,
                    description: this.description
                }
            });
        }, openAfter);
    }
}
