import { Component, Input, Optional } from '@angular/core';
import { AuthService } from '../../../../../../apps/type-mvp/src/app/services/auth.service';
import { Router } from '@angular/router';
import { UserService } from '../../../../../../apps/type-mvp/src/app/services/user.service';
import { DialogRef } from '@type/dialog';
import routes from '@type/shared-models/consts/routes';

@Component({
    template: `
        <app-dialog-header>
            Change account
            <app-dialog-close-button (click)="dialogRef.close()"></app-dialog-close-button>
        </app-dialog-header>
        <div class="grid justify-items-center w-96">
            <p>
                In order to change your account, sign out of your current account and log in with the account you want
                to use.
            </p>
            <div class="pt-8 flex">
                <app-button class="pr-8" color="light" (btnClick)="closeDialog()"> Back </app-button>
                <app-button color="primary" (btnClick)="switchUser()"> Sign out</app-button>
            </div>
        </div>
    `
})
export class SwitchUserComponent {
    @Input() backClick: () => void;

    dialogRef: DialogRef;

    constructor(
        private authService: AuthService,
        private router: Router,
        private userService: UserService,
        @Optional() ref: DialogRef
    ) {
        this.dialogRef = ref;
    }
    async switchUser() {
        this.dialogRef.close();
        const currentRoute = this.router.url;
        await this.authService.signOut();
        this.router.navigate([routes.LOGIN]);
        this.authService.slidSignIn(false, false, false, () => {
            console.log('navigate currentRoute', currentRoute);
            this.router.navigateByUrl(decodeURIComponent(currentRoute));
        });
    }
    closeDialog() {
        this.dialogRef?.config?.data?.backClick();
        this.dialogRef.close();
    }
}
