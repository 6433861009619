import { Component, HostListener, Input } from '@angular/core';
import { PlanType } from '@type/shared-models/payment/products';
import { PaymentDialogService } from './payment-dialog.service';
import { UserService } from './services/payment-dialog-user.service';

@Component({
    selector: 'type-payment-dialog',
    template: `
        <div class="type-payment-dialog-wrapper">
            <ng-content></ng-content>
        </div>
    `,
    styles: [
        `
            .type-payment-dialog-wrapper {
                height: 100%;
                display: grid;
            }
        `
    ]
})
export class PaymentDialogComponent {
    @Input() planType: PlanType;
    @Input() paymentReason: string;
    @Input() disabled = false;
    constructor(private dialogService: PaymentDialogService, private userService: UserService) {}

    @HostListener('click')
    openDialog() {
        if (this.disabled) {
            return;
        }
        this.dialogService.openPaymentDialog(this.planType, this.paymentReason);
    }
}
