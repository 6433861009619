import { Component, Directive, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Directive()
export class MenuItemBase {
    // states
    @Input() inProgress = false;
    @Input() disabled = false;
    @Input() selected = false;
    @Input() selectedColor: string;
}

@Component({
    selector: 'app-menu-item[href]',
    template: `
        <a target="_blank" [href]="href" class="typ-menu-item" [class.selected]="selected" type="button">
            <ng-content select="[appIcon]:not([justify='end'])"></ng-content>
            <span class="typ-menu-item-label" [class.loading-ellipsis]="inProgress">
                <ng-content></ng-content>
            </span>
            <ng-content select="[justify='end']"></ng-content>
        </a>
    `
})
export class MenuItemAnchorComponent extends MenuItemBase {
    @Input() href: string;
}

@Component({
    selector: 'app-menu-item:not([href])',
    template: `
        <div class="menu-item-separator" *ngIf="separator === 'top'"></div>
        <button
            #itemButtonElement
            class="typ-menu-item"
            [class.selected]="selected"
            [style]="selected && selectedColor ? 'background-color:' + selectedColor + '25; color: #000000;' : ''"
            type="button"
            [disabled]="disabled"
        >
            <ng-content select="[appIcon]:not([justify='end'])"></ng-content>
            <span class="typ-menu-item-label" [class.loading-ellipsis]="inProgress">
                <ng-content></ng-content>
            </span>
            <ng-content select="[justify='end']"></ng-content>
        </button>
        <div class="menu-item-separator" *ngIf="separator === 'bottom'"></div>
    `
})
export class MenuItemButtonComponent extends MenuItemBase {
    @ViewChild('itemButtonElement', { read: ElementRef }) itemButtonElement: ElementRef<HTMLButtonElement>;

    @Output() btnClick = new EventEmitter<MouseEvent>();
    @Input() separator: 'top' | 'bottom';
    @HostListener('click', ['$event'])
    buttonClicked(e) {
        if (this.disabled) {
            // TODO: Should we stop propagation
            return;
        }
        this.btnClick.emit(e);
    }
}
