import { Injectable } from '@angular/core';
import { TypSelectFieldComponent } from '@type-mvp/app/forms/typ-select-field/typ-select-field.component';

@Injectable()
export class TypDropdownService {
    private select: TypSelectFieldComponent;

    public register(select: TypSelectFieldComponent) {
        this.select = select;
    }

    public getSelect(): TypSelectFieldComponent {
        return this.select;
    }
}
