import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hideItems'
})
export class HideItemsPipe implements PipeTransform {
    transform<T>(items: T[], itemsToHide, compareKey?: string): T[] {
        const remainingItems = [];
        if (compareKey) {
            items.forEach((item) => {
                if (!itemsToHide.some((i) => i[compareKey] === item[compareKey])) {
                    remainingItems.push(item);
                }
            });
        } else {
            items.forEach((item) => {
                if (!itemsToHide.includes(item)) {
                    remainingItems.push(item);
                }
            });
        }
        return remainingItems;
    }
}
