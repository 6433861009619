import { Component, Input } from '@angular/core';
import { ThemingColorType } from '@type/ui/models';

@Component({
    selector: 'app-dialog-close-button',
    template: `
        <app-button data-spec="dialog-close-button" [color]="color" size="md" aria-label="Close dialog">
            <svg-icon appIcon
                ><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L11 11M11 1L1 11" stroke="black" />
                </svg>
            </svg-icon>
        </app-button>
    `
})
export class DialogCloseButtonComponent {
    @Input() color: ThemingColorType = 'secondary';
}
