<div *ngIf="!embedService.isEmbedded" class="header-bar bg-fullWhite do-not-close-text">
    <a class="logo" data-spec="logo-button" (click)="backToProjects()" (mouseenter)="checkRoute()">
        <div class="logo-icon logo-hover" #logoHoverTrigger></div>
    </a>

    <!-- START Project title 
        shown for user projects
        when authenticated example project 

        hidden for unauthenticated users in example project
    -->
    <div
        class="project-title"
        *ngIf="
            !((isExampleEditor$ | async) || (auth.isSignedIn && (isExampleEditor$ | async) === true)) &&
            (hideTitle$ | async) === false &&
            (isOnRecorderPage$ | async) === false &&
            (currentProject$ | async)?.title as projectTitle
        "
    >
        <!-- Editing disabled -->
        <div class="project-title__title text-fullBlack" *ngIf="!titleEditing" (click)="startEditingTitle()">
            {{ projectTitle }}
        </div>

        <!-- Editing enabled -->
        <div *ngIf="titleEditing">
            <input type="text" class="edit-input" id="myinput" [value]="projectTitle" #editedTitleInput />
            <a (click)="finishEditingTitle()" class="save-changes" style="cursor: pointer">
                <img src="/assets/ic/check.svg" alt="check" />
            </a>
        </div>
    </div>
    <!-- END Project title -->

    <!-- Signup button for example page -->
    <app-button *ngIf="(isExampleEditor$ | async) === true" class="ml-4" color="primary" (click)="newProject()">
        Upload own video or audio
    </app-button>
    <!-- END signup button for example page -->

    <div class="justify-self-start ml-6" *ngIf="isOnRecorderPage$ | async">
        <app-button (btnClick)="reloadPage()" style="margin-top: -0.5rem">Upload media</app-button>
    </div>

    <div class="flex-grow"></div>

    <!-- Sign up button on dashboard -->
    <ng-container *ngIf="(onProjects$ | async) || (onMobileUploadPage | async)">
        <app-button
            *ngIf="(userService.user$ | async)?.isAnonymous"
            icon="left"
            class="mr-2 signUptoSave"
            [appTooltip]="
                !userService.user.uploadedOnce
                    ? !userService.user.uploadedOnce
                        ? 'Log in if you already have an '
                        : 'Log in if you already have an account to access your existing projects'
                    : !userService.user.uploadedOnce
                    ? 'Save your projects for later to continue editing'
                    : ''
            "
            (btnClick)="signUpOrLogin()"
        >
            <svg-icon
                appIcon
                [src]="!userService.user.uploadedOnce || isMobile ? '' : '/assets/ic/24/upload-without-background.svg'"
                size="xl"
            ></svg-icon>
            {{
                !userService.user.uploadedOnce
                    ? isMobile
                        ? 'Login'
                        : 'Already have an account? Login'
                    : isMobile
                    ? 'Sign up'
                    : 'Sign up to save your projects'
            }}
        </app-button>
    </ng-container>

    <div class="account">
        <app-button class="sm:hidden" *ngIf="!userService.user?.isPaying && !isMobile" color="upgrade" appMobileUpgrade>
            Upgrade
        </app-button>

        <button
            *ngIf="auth.isSignedIn; else onboarding"
            [appTriggerFor]="profileMenu"
            class="profile-menu-button"
            class="hover:bg-black05 rounded-full w-10 h-10"
            data-spec="profile-menu-button"
        >
            <img
                class="rounded-full w-10 h-10 object-cover"
                *ngIf="userService.user?.profilePicture"
                [src]="userService.user.profilePicture"
            />
            <svg-icon
                class="h-10 w-10"
                *ngIf="!userService.user?.profilePicture && !userService.user?.isAnonymous"
                src="/assets/ic/profile.svg"
            ></svg-icon>
            <svg-icon
                class="h-10 w-10 flex items-center justify-center"
                *ngIf="!userService.user?.profilePicture && userService.user?.isAnonymous"
                src="/assets/ic/24/more.svg"
            ></svg-icon>
        </button>
        <app-menu #profileMenu="appMenu">
            <app-menu-item data-spec="profile-open-button" routerLink="/profile">Profile</app-menu-item>
            <app-menu-item *ngIf="!userService.user?.isPaying" routerLink="/pricing">Pricing</app-menu-item>
            <!-- Removed until the new SL website is up -->
            <!-- <app-menu-item data-spec="tutorials-open-button" (btnClick)="openTutorials()"> Tutorials </app-menu-item> -->
            <app-menu-item class="hidden sm:block" data-spec="contact-us-open-button" (btnClick)="openContactUs()">
                Contact us
            </app-menu-item>
            <app-menu-item class="hidden sm:block" data-spec="shortcuts-open-button" (btnClick)="openShortcutList()">
                Shortcuts
            </app-menu-item>
            <app-menu-item
                data-spec="sign-up-open-button"
                *ngIf="userService.user?.isAnonymous; else signedIn"
                (btnClick)="openSignUpDialog()"
                >Sign Up</app-menu-item
            >

            <ng-template #signedIn>
                <app-menu-item data-spec="sign-out-open-button" (btnClick)="signOut()">Sign Out</app-menu-item>
            </ng-template>
        </app-menu>

        <ng-template #onboarding>
            <button
                *ngIf="(onLogin$ | async) && (isExampleEditor$ | async) !== true"
                class="button button__secondary button-custom-sign-up"
                (click)="switchOnboarding('signup')"
            >
                Sign up
            </button>
            <!-- <button
                *ngIf="(onLogin$ | async) === false && (isExampleEditor$ | async) !== true"
                class="button button__secondary button-custom-sign-up"
                (click)="switchOnboarding('login')"
            >
                Login
            </button> -->
        </ng-template>
    </div>
</div>
<ng-template let-ref="ref" #contactSelectionDialog>
    <ng-container *ngIf="!selectedContactForm">
        <app-dialog-header>
            Contact us
            <app-dialog-close-button (click)="ref.close()"></app-dialog-close-button>
        </app-dialog-header>
        <div class="grid gap-2 justify-start">
            <app-button data-spec="send-feedback-button" (btnClick)="selectedContactForm = 'feedback'" size="md">
                <span appIcon>📝</span>
                Send feedback
            </app-button>
            <!-- <app-button data-spec="schedule-call-button" (btnClick)="selectedContactForm = 'calendy'" size="md">
                <span appIcon>🗓</span>
                Schedule call
            </app-button> -->
        </div>
    </ng-container>

    <ng-container *ngIf="selectedContactForm === 'calendy'">
        <app-dialog-header>
            <!-- <app-dialog-back-button (click)="selectedContactForm = null"></app-dialog-back-button>
            Schedule call
            <app-dialog-close-button (click)="ref.close()"></app-dialog-close-button> -->
        </app-dialog-header>
        <app-calendly></app-calendly>
    </ng-container>
    <ng-container *ngIf="selectedContactForm === 'feedback'">
        <app-dialog-header>
            <app-dialog-back-button (click)="selectedContactForm = null"></app-dialog-back-button>
            Send feedback
            <app-dialog-close-button (click)="ref.close()"></app-dialog-close-button>
        </app-dialog-header>
        <app-contact></app-contact>
    </ng-container>
</ng-template>

<ng-template let-ref="ref" #tutorialSelectionDialog>
    <app-dialog-header>
        Tutorials
        <app-dialog-close-button (click)="ref.close()"></app-dialog-close-button>
    </app-dialog-header>
    <div class="grid grid-cols-2 gap-1">
        <div class="col-start-1 col-span-1">
            <app-button (btnClick)="openHelpCenter()" size="md"><span appIcon>📚</span>Help center</app-button>
        </div>
        <!-- <div class="col-start-1 col-span-1">
            <app-button (btnClick)="openVideoTutorials()" size="md">
                <span appIcon>📺</span>
                Video tutorials
            </app-button>
        </div> -->
    </div>
</ng-template>
