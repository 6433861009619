import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable, throwError } from 'rxjs';
import { DialogService } from '@type/dialog';

export type Platform = 'apple' | 'pc';

export type AllowInElement = 'INPUT' | 'TEXTAREA' | 'SELECT';
interface Options {
    group: string;
    element: HTMLElement;
    trigger: 'keydown' | 'keyup';
    allowIn: AllowInElement[];
    description: string;
    showInHelpMenu: boolean;
    preventDefault: boolean;
    priority: number; // low priority = 1, higher priority = 2, etc.
}

export interface HotkeyGroup {
    group: string;
    hotkeys: { keys: string; description: string }[];
}

export type Hotkey = Partial<Options> & { keys: string };
export type HotkeyCallback = (event: KeyboardEvent, keys: string, target: HTMLElement) => void;

// This service is heavily inspired by https://github.com/ngneat/hotkeys
@Injectable({ providedIn: 'root' })
export class HotkeyService {
    private readonly hotkeys = new Map<string, Array<Hotkey>>();
    private readonly defaults: Options = {
        trigger: 'keydown',
        allowIn: [],
        element: this.document.documentElement,
        group: undefined,
        description: undefined,
        showInHelpMenu: true,
        preventDefault: true,
        priority: 1
    };
    private callbacks: HotkeyCallback[] = [];
    private shortcutsDisabled = false;
    private openDialogCount = 0;

    constructor(
        private eventManager: EventManager,
        @Inject(DOCUMENT) private document,
        private dialogService: DialogService
    ) {
        this.dialogService.overlayOpened$.subscribe((overlayRef) => {
            if (!overlayRef.config?.allowHotkeys) {
                this.disableShortcuts();
                this.openDialogCount++;
                overlayRef.afterClosed().subscribe(() => {
                    this.openDialogCount--;
                    if (!this.openDialogCount) {
                        this.enableShortcuts();
                    }
                });
            }
        });
    }

    getHotkeys(): Hotkey[] {
        return [].concat(...Array.from(this.hotkeys.values()));
    }

    getShortcuts(): HotkeyGroup[] {
        const hotkeys = this.getHotkeys();
        const groups: HotkeyGroup[] = [];

        for (const hotkey of hotkeys) {
            if (!hotkey.showInHelpMenu) {
                continue;
            }

            let group = groups.find((g) => g.group === hotkey.group);
            if (!group) {
                group = { group: hotkey.group, hotkeys: [] };
                groups.push(group);
            }

            const normalizedKeys = this.normalizeKeys(hotkey.keys, this.hostPlatform());
            group.hotkeys.push({ keys: normalizedKeys, description: hotkey.description });
        }

        return groups;
    }

    addShortcut(options: Hotkey): Observable<KeyboardEvent> {
        const mergedOptions = { ...this.defaults, ...options };
        const normalizedKeys = this.normalizeKeys(mergedOptions.keys, this.hostPlatform());

        let hotkeyArr: Array<Hotkey> = [];
        if (this.hotkeys.has(normalizedKeys)) {
            hotkeyArr = this.hotkeys.get(normalizedKeys);
            const hotkeyPriorities = hotkeyArr.map((h) => `${h.priority}_${h.trigger}`);
            const priorityToCheck = `${options.priority || 1}_${options.trigger}`;
            if (hotkeyPriorities.includes(priorityToCheck)) {
                console.warn('Tried to add duplicate shortcut with same priorities!', mergedOptions);
                // return throwError(() => new Error('Tried to add duplicate shortcut with same priorities!'));
            }
        }
        hotkeyArr.push(mergedOptions);

        this.hotkeys.set(normalizedKeys, hotkeyArr);
        const event = `${mergedOptions.trigger}.${normalizedKeys}`;

        return new Observable((observer) => {
            const handler = (e: KeyboardEvent) => {
                if (this.shortcutsDisabled) {
                    return;
                }
                const hotkey = this.getHotkey(normalizedKeys, options.priority);
                const excludedTargets = this.getExcludedTargets(hotkey.allowIn || []);

                const skipShortcutTrigger =
                    excludedTargets && excludedTargets.includes(document.activeElement.nodeName);
                if (skipShortcutTrigger) {
                    return;
                }

                // Check if shortcut has highest priority
                const highestPriority = this.getHighestPriority(normalizedKeys);
                if (highestPriority > mergedOptions.priority) {
                    return;
                }

                if (mergedOptions.preventDefault) {
                    e.preventDefault();
                }

                this.callbacks.forEach((cb) => cb(e, normalizedKeys, hotkey.element));
                observer.next(e);
            };
            const dispose = this.eventManager.addEventListener(mergedOptions.element, event, handler);

            return () => {
                this.deleteShortcut(normalizedKeys, mergedOptions.priority);
                dispose();
            };
        });
    }

    removeShortcuts(hotkeys: string | string[], priority: number = 1): void {
        const coercedHotkeys = this.coerceArray(hotkeys).map((hotkey) =>
            this.normalizeKeys(hotkey, this.hostPlatform())
        );
        coercedHotkeys.forEach((hotkey) => {
            if (!this.hotkeys.has(hotkey)) {
                console.warn(`Hotkey ${hotkey} not found`);
                return;
            }
            this.deleteShortcut(hotkey, priority);
        });
    }

    onShortcut(callback: HotkeyCallback): () => void {
        this.callbacks.push(callback);

        return () => (this.callbacks = this.callbacks.filter((cb) => cb !== callback));
    }

    private deleteShortcut(hotkey: string, priority: number = 1) {
        if (this.hotkeys.get(hotkey).length === 1) {
            this.hotkeys.delete(hotkey);
        } else {
            const hotkeyIndex = this.getHotkeyIndex(hotkey, priority);
            const remainingHotkeys = [...this.hotkeys.get(hotkey)];
            remainingHotkeys.splice(hotkeyIndex, 1);
            this.hotkeys.set(hotkey, remainingHotkeys);
        }
    }

    private getHighestPriority(normalizedKeys: string): number {
        const hotkeys = this.hotkeys.get(normalizedKeys);
        const sortedHotkeys = hotkeys.sort((a, b) => (a.priority < b.priority ? 1 : -1));
        return sortedHotkeys[0].priority;
    }

    private getHotkey(normalizedKeys: string, priority: number = 1): Hotkey {
        const hotkeys = this.hotkeys.get(normalizedKeys);
        return hotkeys.find((key) => key.priority === priority);
    }

    private getHotkeyIndex(normalizedKeys: string, priority: number = 1): number {
        const hotkeys = this.hotkeys.get(normalizedKeys);
        return hotkeys.findIndex((key) => key.priority === priority);
    }

    private getExcludedTargets(allowIn: AllowInElement[]) {
        return ['INPUT', 'SELECT', 'TEXTAREA'].filter((t) => !allowIn.includes(t as AllowInElement));
    }

    private coerceArray(params: any | any[]) {
        return Array.isArray(params) ? params : [params];
    }

    private normalizeKeys(keys: string, platform: Platform): string {
        const lowercaseKeys = keys.toLowerCase();
        switch (platform) {
            case 'pc':
                return lowercaseKeys
                    .split('.')
                    .map((k) => (k === 'meta' ? 'control' : k))
                    .join('.');
            default:
                return keys;
        }
    }

    private hostPlatform(): Platform {
        const appleDevices = ['Mac', 'iPhone', 'iPad', 'iPhone'];
        return appleDevices.some((d) => navigator.platform.includes(d)) ? 'apple' : 'pc';
    }

    disableShortcuts() {
        this.shortcutsDisabled = true;
    }

    enableShortcuts() {
        this.shortcutsDisabled = false;
    }
}
