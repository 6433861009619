import { NgModule } from '@angular/core';
import { UpgradeRequiredDirective } from './upgrade-required.directive';
import { CommonModule } from '@angular/common';
import { PaymentDialogModule } from '@type/payment-dialog/payment-dialog.module';

const exportItems = [UpgradeRequiredDirective];

@NgModule({
    imports: [PaymentDialogModule],
    declarations: [...exportItems],
    exports: [...exportItems]
})
export class UpgradeRequiredModule {}
