import { Component } from '@angular/core';

@Component({
    template: `
        <app-dialog-header>
            Oh no, your payment failed
            <app-dialog-close-button appDialogClose></app-dialog-close-button>
        </app-dialog-header>

        <p class="typ-p">We were unable to process your payment.</p>
        <br />
        <p class="typ-p">This could have happened for a number of reasons:</p>
        <ul class="typ-ul">
            <li>your card has expired</li>
            <li>lack of funds</li>
            <li>your bank declined the transaction.</li>
        </ul>
        <br />
        <br />
        <p class="typ-p">To keep Podcast Editor PRO you need to upgrade your account.</p>

        <app-dialog-footer>
            <app-button appDialogClose>Continue with FREE plan</app-button>
            <app-button color="upgrade" [isUpgradeRequired]="true" appUpgradeRequired="paymentFailed" appDialogClose
                >Upgrade to Starter</app-button
            >
        </app-dialog-footer>
    `
})
export class FailedSubscriptionComponent {}
