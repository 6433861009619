import { PlanType } from '../payment/products';
import { DateUtils } from '../utils/date-utils';
import { SubscriptionPlan } from './subscription-plan';

type SubscriptionProcessingStatus = 'good' | 'retry' | 'failed';

export class SubscriptionState {
    plan = new SubscriptionPlan(PlanType.TRIAL);

    // UNIX Timestamps
    startDate = DateUtils.toUnixTimestamp(new Date());
    endDate: number = DateUtils.addMonthsToDate(this.startDate, 1);
    nextResetDate: number;
    // usage is billed in minutes
    usedSeconds = 0;

    subscriptionId: string;
    status: SubscriptionProcessingStatus;
    isUltra = false;

    get remainingUsage(): number {
        return this.plan.usageLimitation - this.usedSeconds;
    }

    /**
     * Returns true if the user has a payed plan (BASIC, PERSONAL, PRO, ENTERPRISE, CANCELED, STARTER, PRO_NOV, BUSINESS)
     */
    get isPaying(): boolean {
        return [
            PlanType.PERSONAL,
            PlanType.BASIC,
            PlanType.PRO,
            PlanType.ULTRA,
            PlanType.CANCELED,
            PlanType.ENTERPRISE,
            PlanType.STARTER,
            PlanType.STARTER_BASIC,
            PlanType.PRO_TEAM,
            PlanType.BUSINESS
        ].includes(this.plan.type);
    }

    /**
     * Returns true if the user has the TRIAL (free) plan
     */
    get isTrial(): boolean {
        return [PlanType.TRIAL, PlanType.EXPIRED].includes(this.plan.type);
    }

    /**
     * Returns true if the user has the BASIC plan
     */
    get isBasic(): boolean {
        return [PlanType.BASIC, PlanType.STARTER_BASIC].includes(this.plan.type) || this.isPro;
    }

    /**
     * Returns true if the user has the PERSONAL, PRO or STARTER plan
     */
    get isStarter(): boolean {
        return [PlanType.PERSONAL, PlanType.STARTER].includes(this.plan.type) || this.isPro;
    }

    /**
     * Returns true if the user has the PRO plan
     */
    get isPro(): boolean {
        return [PlanType.PRO, PlanType.ULTRA].includes(this.plan.type);
    }
    /**
     * Returns true if the user has the PRO_TEAM plan
     */
    get isProTeam(): boolean {
        return this.plan.type === PlanType.PRO_TEAM;
    }

    /**
     * Returns true if the user has the BUSINESS plan
     */
    get isBusiness(): boolean {
        return this.plan.type === PlanType.BUSINESS;
    }

    /**
     * Returns true if the user has the ENTERPRISE plan
     */
    get isEnterprise(): boolean {
        return this.plan.type === PlanType.ENTERPRISE;
    }

    constructor(subscriptionState?: SubscriptionState) {
        if (!subscriptionState) return;
        Object.assign(this, subscriptionState);
        this.plan = new SubscriptionPlan(subscriptionState.plan?.type).fromFirebase(subscriptionState.plan);
    }

    eligibleToUpload(secondsToUpload: number): boolean {
        return this.remainingUsage >= secondsToUpload || this.usedSeconds == null;
    }

    toFirebase() {
        return {
            plan: this.plan.toFirebase(),
            startDate: this.startDate || null,
            endDate: this.endDate || null,
            usedSeconds: this.usedSeconds || null,
            subscriptionId: this.subscriptionId || null,
            isUltra: this.isUltra || false
        };
    }

    setUltraFromUltra() {
        this.isUltra = true;
        this.plan = new SubscriptionPlan(PlanType.ULTRA);
    }
    setTrialFromUltra() {
        this.isUltra = false;
        this.plan = new SubscriptionPlan(PlanType.TRIAL);
    }
}
