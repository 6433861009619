import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import routes from '@type/shared-models/consts/routes';
import { filter, first, map, startWith } from 'rxjs/operators';
import { DialogRef, DialogService } from '@type/dialog';
import { EditorService } from '../../../../../../apps/type-mvp/src/app/services/editor.service';
import { AuthService } from '../../../../../../apps/type-mvp/src/app/services/auth.service';
import { EmbedService } from '../../../../../../apps/type-mvp/src/app/services/embed.service';
import { RecorderService } from '../../../../../../apps/type-mvp/src/app/services/recorder.service';
import { UserService } from '../../../../../../apps/type-mvp/src/app/services/user.service';
import { ShortcutListComponent } from '../../../../../../apps/type-mvp/src/app/partials/shortcut-list/shortcut-list.component';
import { SignupComponent } from '../../../../../../apps/type-mvp/src/app/pages/auth/signup/signup.component';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
    GetSlidCheckoutUrlRequest,
    GetSlidCheckoutUrlResponse,
    SlidCheckoutAction
} from '@type/shared-models/auth/authenticate-slid.models';
import { FirebaseFunctionNames } from '@type/shared-models/consts/firebase-function-names';
import { environment } from '../../../../../../apps/type-mvp/src/environments/environment';
import { SwitchUserComponent } from '../switch-user/switch-user.component';
import { isLocalEnvironment } from '../../../../../../apps/type-mvp/src/environments/environment.utils';
import { PlanInterval } from '@type/shared-models/payment/products';

@Component({
    selector: 'type-slid-checkout',
    templateUrl: './slid-checkout.component.html',
    styleUrls: ['./slid-checkout.component.scss']
})
export class SlidCheckoutComponent implements AfterViewInit {
    @Input() checkoutAction: SlidCheckoutAction;
    @Input() planInterval: PlanInterval;
    @Input() disabled: boolean;
    constructor(
        public auth: AuthService,
        public userService: UserService,
        public embedService: EmbedService,
        private dialogService: DialogService,
        private firebaseFunctions: AngularFireFunctions
    ) {}
    ngAfterViewInit(): void {}

    private prepareCheckoutWindow() {
        return window.open(`${environment.url}/safe-space`, '_blank', 'height=900,width=700');
    }
    getCheckoutUrl(checkoutAction: SlidCheckoutAction) {
        return this.firebaseFunctions.httpsCallable<GetSlidCheckoutUrlRequest, GetSlidCheckoutUrlResponse>(
            FirebaseFunctionNames.getSlidCheckoutUrl
        )({
            checkoutAction,
            planInterval: this.planInterval,
            userId: this.userService.user.firebaseId,
            fromLocalEnvironment: isLocalEnvironment()
        });
    }
    openCancelPopup() {
        const checkoutWindow = this.prepareCheckoutWindow();
        this.getCheckoutUrl('cancel').subscribe((response: GetSlidCheckoutUrlResponse) => {
            console.log('🚀 ~ response:', response);
            const viewOrigin = new URL(response.slidCheckoutUrl).origin;
            checkoutWindow.location = response.slidCheckoutUrl;
            const callback = (payload) => {
                console.log('🚀 ~ payload:', payload);
                switch (payload.action) {
                    case 'userUnsubscribed':
                        this.onUnsubscribe(payload.data);
                        break;
                    default:
                        break;
                }
            };

            this.setUltraMessageListener({
                callback,
                viewOrigin,
                popout: checkoutWindow,
                source: 'sl-ultra',
                onSuccess: this.onSuccess,
                onClose: this.onClose
            });
        });
    }
    openBillingPopup() {
        const checkoutWindow = this.prepareCheckoutWindow();
        this.getCheckoutUrl('payment-method').subscribe((response: GetSlidCheckoutUrlResponse) => {
            console.log('🚀 ~ response:', response);
            const viewOrigin = new URL(response.slidCheckoutUrl).origin;
            checkoutWindow.location = response.slidCheckoutUrl;
            const callback = (payload) => {
                switch (payload.action) {
                    case 'login':
                        this.onLogin(payload.data);
                        break;
                    case 'switch':
                        this.onSwitchUser(payload.data);
                        break;
                    case 'userSubscribed':
                        this.onSubscribed(payload.data);
                        break;
                    default:
                        break;
                }
            };

            this.setUltraMessageListener({
                callback,
                viewOrigin,
                popout: checkoutWindow,
                source: 'sl-ultra',
                onSuccess: this.onSuccess,
                onClose: this.onClose
            });
        });
    }

    openCheckoutPopup() {
        console.log('openCheckout');
        const checkoutWindow = this.prepareCheckoutWindow();
        this.getCheckoutUrl('checkout').subscribe((response: GetSlidCheckoutUrlResponse) => {
            const viewOrigin = new URL(response.slidCheckoutUrl).origin;
            checkoutWindow.location = response.slidCheckoutUrl;
            const callback = (payload) => {
                switch (payload.action) {
                    case 'login':
                        this.onLogin(payload.data);
                        break;
                    case 'switch':
                        this.onSwitchUser(payload.data);
                        break;
                    case 'userSubscribed':
                        this.onSubscribed(payload.data);
                        break;
                    default:
                        break;
                }
            };

            this.setUltraMessageListener({
                callback,
                viewOrigin,
                popout: checkoutWindow,
                source: 'sl-ultra',
                onSuccess: this.onSuccess,
                onClose: this.onClose
            });
        });
    }

    onUnsubscribe(payload) {
        console.log('🚀 ~ on unsubscribe');
        this.userService.refreshSlidState(this.userService.user, true);
    }
    onClose() {
        console.log('🚀 ~ onClose');
    }
    onSuccess() {
        console.log('🚀 ~ onSuccess');
    }
    onLogin(payload) {
        console.log('🚀 ~ onLogin:', payload);
    }
    onSwitchUser(payload) {
        console.log('🚀 ~ onSwitchUser:', payload);
        this.dialogService.openDialog(SwitchUserComponent, { data: { backClick: () => this.openCheckoutPopup() } });
    }
    onSubscribed(payload) {
        console.log('🚀 ~ onSubscribed:', payload);
        this.dialogService.closeAll();
        this.userService.refreshSlidState(this.userService.user, true);
    }

    setUltraMessageListener({ viewOrigin, popout, source, callback, onSuccess, onClose }) {
        if (!popout) {
            throw new Error('No popout window given');
        }
        const messageListener = (event) => {
            if (event.origin !== viewOrigin) {
                console.log('🚀 ~ event.origin:', event.origin);
                return;
            }

            console.log('🚀 ~ event.data.source:', event.data.source);
            if (event.data.source !== source) {
                return;
            }

            callback({
                action: event.data.action,
                data: event.data.data
            });

            if (event.data.isExitEvent) {
                window.removeEventListener('message', messageListener, false);
                popout?.close();
                onSuccess(true);
            }
        };

        const interval = window.setInterval(() => {
            if (!popout || popout.closed) {
                window.clearInterval(interval);
                window.removeEventListener('message', messageListener, false);
                onClose(false);
            }
        }, 250);

        window.addEventListener('message', messageListener, false);
    }
}
