// TODO: put in shared location.
export class AnimationFactoryConfig {
    enterState?: string;
    exitState?: string;
    duration?: number;
}

export const defaultAnimationFactoryConfig: AnimationFactoryConfig = {
    enterState: '*',
    exitState: 'void',
    duration: 300
};
