// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { devEnvironment, devFirebaseFunctions } from '@type/configs';

export const environment = {
    production: false,
    firebase: devEnvironment.firebase,
    googleTagId: '0',
    stripeKey:
        'pk_test_51OpBEKBCb5eAmPX70hljYIOnzoawFmMzWdJJT8kmzoVx6mCpgCpiZnBLhQga0Q1ts6rW0kNtUajsmrbA9pO7wram003Ve0WWRo'
};

export const firebaseFunctions = devFirebaseFunctions;
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
