import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'secondsFormatting'
})
export class SecondsFormattingPipe implements PipeTransform {
    // Returns hh:mm:ss from a length input in seconds. No leading zeros
    transform(value: number): string {
        value = Math.round(value);
        if (value <= 0) {
            return '0:00';
        }
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);
        const seconds = value % 60;
        let result = `${minutes.toString().padStart(1, '0')}:${seconds.toString().padStart(2, '0')}`;
        if (hours) {
            result = `${hours.toString()}:${minutes.toString().padStart(2, '0')}:${seconds
                .toString()
                .padStart(2, '0')}`;
        }
        return result;
    }
}
