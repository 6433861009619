import { Component, Directive } from '@angular/core';

@Component({
    selector: 'app-dialog-footer',
    template: `
        <div class="pt-6 flex w-full">
            <div class="justify-self-start">
                <ng-content select="[appDialogFooterStart]"></ng-content>
            </div>
            <div class="flex-grow"></div>
            <div class="justify-self-end dialog-footer-default">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styles: [
        `
            .dialog-footer-default {
                display: grid;
                grid-auto-flow: column;
                grid-auto-columns: auto;
                grid-gap: 0.5rem;
            }
        `
    ]
})
export class DialogFooterComponent {}

@Directive({ selector: '[appDialogFooterStart]' })
export class DialogFooterStartDirective {}
