<div *ngIf="storageError" class="flex p-5 w-full min-h-full">
    <!-- align-items: center; align-content: center; -->

    <div class="h-full typ-card grid justify-items-center w-full">
        <h3>{{ storageError.title }}</h3>
        <p>{{ storageError.subtitle }}</p>
        <app-button *ngIf="storageError?.buttonLabel" class="pt-8" color="primary" (btnClick)="switchUser()">{{
            storageError.buttonLabel
        }}</app-button>
    </div>
</div>

<type-loader
    *ngIf="!storageError"
    [standalone]="true"
    [entertainerText]="[
        'Loading Podcast Editor',
        'Preparing subtitle menu',
        'Creating translations',
        'Setting up auto-clips ai',
        'Transferring video file',
        'Configuring project',
        'Loading latest updates'
    ]"
></type-loader>
