<div class="w-full center flex justify-center mb-2">
    <img class="w-12" src="assets/app_logo.svg" alt="" />
</div>

<div class="my-0 font-bold text-2xl mt-6 mb-2 mx-6 text-center">
    <span class="">{{ signupDialogConfig.headline ? signupDialogConfig.headline : 'Sign up' }}</span>
</div>
<div class="grid-cols-1 grid">
    <!-- <button
        (click)="signInWithGoogle()"
        class="button button__third auth-provider-button"
        appTooltip="Sign-up with Google"
        data-spec="google-signup-button"
    >
        <svg-icon class="auth-logo-icon" src="assets/third-party/google/google-logo.svg"></svg-icon>
    </button>
    <button
        (click)="signInWithFacebook()"
        class="button button__third auth-provider-button"
        appTooltip="Sign-up with Facebook"
        data-spec="facebook-signup-button"
    >
        <svg-icon class="auth-logo-icon" src="assets/third-party/facebook/facebook-logo.svg"></svg-icon>
    </button>
    <button
        (click)="signInWithApple()"
        class="button button__third auth-provider-button"
        appTooltip="Sign-up with Apple"
        data-spec="apple-signup-button"
    >
        <svg-icon class="auth-logo-icon" src="assets/third-party/apple/apple-logo.svg"></svg-icon>
    </button> -->
    <div *ngIf="signupDialogConfig.subHeadline; else subHeadline">
        <div class="text-center leading-5">{{ signupDialogConfig.subHeadline }}</div>
    </div>
    <ng-template #subHeadline>
        <div class="text-center leading-5">
            Create a <b>Streamlabs Id</b> account to use <b>Podcast Editor</b>
        </div></ng-template
    >
    <app-button
        (click)="signInWithSlid()"
        class="mt-8 icon-white flex justify-between w-full border rounded-lg border-solid border-black h-12 text-white font-medium items-center bg-black"
        [color]="'black'"
        [inProgress]="isSigningUp.slid"
        appTooltip="Sign-in with Streamlabs ID"
        data-spec="slid-signin-button"
    >
        <svg-icon class="auth-logo-icon" appIcon [size]="'xl'" src="assets/ic/24/streamlabs.svg"></svg-icon>
        Create Streamlabs ID
        <svg-icon class="icon-size-lg" appIcon justify="end" src="assets/ic/24/arrow-right.svg"> </svg-icon>
    </app-button>
</div>

<p
    class="default-input__message default-input__message--error"
    *ngIf="providerAuthError && providerAuthError.code !== 'auth/popup-closed-by-user'"
>
    {{ providerAuthError.message }}
</p>

<div class="separator">
    <!-- <div class="separator__line"></div>
    <div class="separator__or">or</div>
    <div class="separator__line"></div> -->
</div>

<!-- <form
    #signupForm="ngForm"
    (change)="signUpError = null"
    [formGroup]="signUpForm"
    (ngSubmit)="signUp()"
    class="grid-gap-1"
>
    <div class="default-input">
        <label for="name" class="default-input__label">Name</label>
        <div class="default-input__input-field">
            <input
                id="name"
                type="text"
                formControlName="name"
                class="default-input__input-field__input"
                name="name"
                placeholder="What do your friends call you?"
                autofocus
                data-spec="signup-name-input"
            />
        </div>
        <div class="error-wrapper">
            <p
                *ngIf="nameCtrl.invalid && signupForm.submitted"
                class="default-input__message default-input__message--error"
            >
                <span *ngIf="nameCtrl.errors?.required">Name is required.</span>
            </p>
        </div>
    </div>

    <div class="default-input">
        <label for="email" class="default-input__label">E-Mail Address</label>
        <div class="default-input__input-field">
            <input
                id="email"
                type="email"
                formControlName="email"
                class="default-input__input-field__input"
                name="email"
                placeholder="youlooknicetoday@email.com"
                data-spec="signup-email-input"
            />
        </div>
        <div class="error-wrapper">
            <p
                *ngIf="emailCtrl.invalid && signupForm.submitted"
                class="default-input__message default-input__message--error"
            >
                <span *ngIf="emailCtrl.errors?.required">Email address is required.</span>
                <span *ngIf="emailCtrl.errors?.email">Please enter a valid email address.</span>
            </p>
            <p class="default-input__message default-input__message--error" *ngIf="!!signUpError">
                {{ signUpError.message }}
            </p>
        </div>
    </div>

    <div class="default-input">
        <label for="password" class="default-input__label">Password</label>
        <div class="default-input__input-field">
            <input
                id="password"
                type="password"
                formControlName="password"
                class="default-input__input-field__input"
                name="password"
                required
                placeholder="Shh... (min. 6 characters)"
                data-spec="signup-password-input"
            />
        </div>
        <p
            *ngIf="passwordCtrl.invalid && signupForm.submitted"
            class="default-input__message default-input__message--error"
        >
            <span *ngIf="passwordCtrl.errors?.required">Password is required.</span>
            <span *ngIf="passwordCtrl.errors?.minlength">Must be at least 6 characters.</span>
            <span *ngIf="passwordCtrl.errors?.maxlength">Can't exceed 255 characters.</span>
        </p>
    </div>

    <div class="note">
        <p>
            By continuing, you agree to our
            <a class="note__label" href="https://streamlabs.com/privacy"> Privacy Policy</a>
        </p>
    </div>
    <div class="error-wrapper"></div>

    <div class="form-tile">
        <app-button
            [disabled]="isSigningUp"
            [inProgress]="isSigningUp"
            color="primary"
            data-spec="signup-submit-button"
            type="submit"
            class="w-full"
        >
            {{ isSigningUp ? 'Signing up...' : 'Sign up' }}
        </app-button>
    </div> -->

<!-- </form> -->
<ng-container *ngIf="!signupDialogConfig?.hideLogin">
    <p class="switch-onboarding">
        Already have an account?
        <a (click)="navigateLogin()" class="switch-onboarding__label">Login</a>
    </p>
</ng-container>

<p class="font-caveat text-sm text-center mt-6">Powered by <span class="font-caveat text-base">Streamlabs</span></p>
<app-toast id="needsSlidSignup">
    <p>You have to create a Streamlabs ID</p>
</app-toast>
