import { Injectable } from '@angular/core';
import { UpgradeComponent } from './components/upgrade.component';
import { PlanType } from '@type/shared-models/payment/products';
import { DialogService } from '@type/dialog';
import { UpgradeService } from './services/payment-dialog-upgrade.service';
import { take } from 'rxjs';

@Injectable()
export class PaymentDialogService {
    constructor(private dialogService: DialogService, private upgradeService: UpgradeService) {}

    openPaymentDialog(planType?: PlanType, paymentReason?: string, showEmail?, signUpComponent?, openPayment = true) {
        if (signUpComponent) {
            return this.dialogService.openDialog(signUpComponent, {
                maxWidth: 1300,
                data: { hideLogin: true, fromAnonymous: true, openPayment }
            });
        }
        const reference = this.dialogService.openDialog(UpgradeComponent, {
            data: { planType, showEmail, paymentReason },
            panelClass: ['dialog-no-padding']
        });
        reference
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
                if (this.upgradeService.successMessage) {
                    this.dialogService.closeAll();
                }
            });

        return reference;
    }
}
