<div class="input-wrapper">
    <div class="input" [class.required]="required" #dropreference>
        <label class="label-text mb-2 block">{{ label }}</label>
        <input
            #input
            placeholder="{{ placeholder }}"
            [disabled]="disabled"
            readonly
            class="button__third"
            [value]="displayText"
            (click)="showDropdown()"
            (keydown)="onKeyDown($event)"
            (blur)="onTouched()"
            autocomplete="off"
        />
        <span class="dropdown-arrow" (click)="onDropMenuIconClick($event)">
            <svg-icon appIcon class="icon-size-xl" src="/assets/ic/24/dropdown.svg"></svg-icon>
        </span>
        <typ-dropdown [reference]="dropreference" #dropdownComp>
            <div class="dropdown-options-container py-2 px-2 grid gap-1">
                <ng-content select="app-select-field-option"></ng-content>
            </div>
        </typ-dropdown>
    </div>
</div>
