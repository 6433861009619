import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FirebaseFunctionNames } from '@type/shared-models/consts/firebase-function-names';
import { TrackEventDataModel } from '@type/shared-models/communication/track-event.models';
import { SegmentService } from 'ngx-segment-analytics';
import { environment } from '../../environments/environment';

const TRACKING_LOGS_ENABLED = false;
@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    private segmentLoaded = false;

    constructor(private fireFunctions: AngularFireFunctions, private segmentService: SegmentService) {}

    async identify(userId: string, userProperties: any) {
        this.load();
        const isSegmentReady = await this.segmentService.ready();
        if (isSegmentReady) {
            this.segmentService.identify(userId, userProperties);
        }
    }
    track(eventName: string, fields: any) {
        if (TRACKING_LOGS_ENABLED) {
            console.log('%c🖥 track clientside' + `%c ${eventName}`, 'color: #9A9A9A', 'color: #1D6EBC', fields);
        }
        this.segmentService.track(eventName, fields);
    }
    trackServerside(eventName: string, userId: string, fields: any) {
        const trackEventData: TrackEventDataModel = { eventName, fields, userId };
        if (TRACKING_LOGS_ENABLED) {
            console.log('%c☁️ track serverside' + `%c ${eventName}`, 'color: #9A9A9A', 'color: #1D6EBC', fields);
        }
        this.fireFunctions.httpsCallable(FirebaseFunctionNames.trackEvent)(trackEventData).subscribe();
    }

    private load() {
        if (!this.segmentLoaded) {
            this.segmentService.load(environment.segmentKey);
            this.segmentLoaded = true;
        }
    }
}
