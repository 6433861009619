import { NgModule } from '@angular/core';
import { TabComponent } from './tab.component';
import { TabsComponent } from './tabs.component';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TriggerModule } from '../trigger/trigger.module';

const exportItem = [TabComponent, TabsComponent];

@NgModule({
    imports: [CommonModule, AngularSvgIconModule, TriggerModule],
    declarations: [...exportItem],
    exports: [...exportItem]
})
export class TabsModule {}
