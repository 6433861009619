import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: '[typeTrigger]' })
export class TriggerDirective {
    @Output() typeTrigger = new EventEmitter<ElementRef>();

    @HostListener('click')
    onClick() {
        this.typeTrigger.emit(this._el);
    }

    constructor(private _el: ElementRef) {}
}
