import { Pipe, PipeTransform } from '@angular/core';
import { CanvasElement } from '@type/shared-models/models/canvas/canvas-element';

@Pipe({
    name: 'isCanvasMarker'
})
export class IsCanvasMarkerPipe implements PipeTransform {
    transform(
        wordIndex: number,
        canvasElements: Array<CanvasElement>,
        markerType: 'start' | 'end'
    ): Array<CanvasElement> {
        const foundElements = canvasElements?.filter(
            (el) =>
                (markerType === 'start' && el.startWord === wordIndex) ||
                (markerType === 'end' && el.endWord === wordIndex)
        );
        if (foundElements?.length) {
            return foundElements;
        }
        return null;
    }
}
