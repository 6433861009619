import { Directive, HostListener, Input } from '@angular/core';
import { PaymentDialogService } from '@type/payment-dialog/payment-dialog.service';
import { PlanType } from '@type/shared-models/payment/products';
import { PaymentReasonType } from '@type/shared-models/consts/payment-reason';

@Directive({
    selector: '[appUpgradeRequired]'
})
export class UpgradeRequiredDirective {
    @Input() isUpgradeRequired = false;
    @Input() appUpgradeRequired: PaymentReasonType | string;

    @HostListener('click')
    clicked() {
        if (!this.isUpgradeRequired) {
            return;
        }
        this.paymentDialogService.openPaymentDialog(
            null,
            this.appUpgradeRequired
            // false,
            // this.userService.user?.isAnonymous ? SignupComponent : null
        );
    }

    constructor(private paymentDialogService: PaymentDialogService) {}
}
