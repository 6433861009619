import { Component, Injector, TemplateRef } from '@angular/core';
import { ComponentType, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DialogRef } from '../dialog-ref';
import { DialogConfig } from '../dialog.models';

@Component({
    template: `
        <div
            class="dialog-container"
            [cdkTrapFocus]="!ref.config.allowHotkeys"
            [cdkTrapFocusAutoCapture]="!ref.config.allowHotkeys"
        >
            <!-- This button is only here to trap focus -->
            <button class="focus-trap-button" *ngIf="!hideButton" (blur)="hideButton = true"></button>
            <ng-container [ngSwitch]="contentType">
                <ng-container *ngSwitchCase="'string'">
                    <div class="box">
                        <div [innerHTML]="content"></div>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'template'">
                    <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'component'">
                    <ng-container *ngComponentOutlet="content"></ng-container>
                </ng-container>
            </ng-container>
        </div>
    `,
    styles: [
        `
            .focus-trap-button {
                position: fixed;
                top: -9999px;
                opacity: 0;
                height: 0;
                width: 0;
                overflow: hidden;
            }
        `
    ]
})
export class DialogContainerComponent {
    contentType: 'template' | 'string' | 'component';
    content;
    context;
    disableClose: boolean;
    goBackComponent: ComponentType<any>;
    hideButton: boolean;

    constructor(public ref: DialogRef, private overlay: Overlay, private injector: Injector) {
        this.disableClose = ref.config.disableClose;
        this.goBackComponent = ref.config.goBackToComponent;
        if (!this.disableClose) {
            const sub = this.ref.overlayRef.backdropClick().subscribe(() => {
                this.ref.close();
                sub.unsubscribe();
            });
        }
        this.content = this.ref.content;

        if (typeof this.content === 'string') {
            this.contentType = 'string';
        } else if (this.content instanceof TemplateRef) {
            this.contentType = 'template';
            this.context = {
                ref: this.ref
            };
        } else {
            this.contentType = 'component';
        }
    }

    close(result?: any) {
        this.ref.close(result);
    }

    goBack() {
        const dialogConfig: DialogConfig = {
            hasBackdrop: true,
            positionStrategy: this.overlay.position().global(),
            scrollStrategy: this.overlay.scrollStrategies.block(),
            panelClass: ['bg-white', 'rounded-2xl', 'shadow-floating'],
            backdropClass: 'bg-backdrop'
        };

        const overlayRef = this.overlay.create(dialogConfig);

        const dialogRef = new DialogRef<any, any>({
            overlayRef,
            config: dialogConfig,
            content: this.goBackComponent,
            overlayType: 'dialog'
        });

        const injector = this.createInjector(dialogRef, this.injector);
        overlayRef.attach(new ComponentPortal(DialogContainerComponent, null, injector));

        this.close(dialogRef);
    }

    // TODO: Refactor with dialog service. Is there a better way?
    createInjector(ref: DialogRef, inj: Injector) {
        return Injector.create({
            providers: [
                {
                    provide: DialogRef,
                    useValue: ref
                }
            ],
            parent: inj
        });
    }
}
