import { Component } from '@angular/core';

// Empty component for users to land on while auth (or other) things occur
// that don't have authentication set up yet.
@Component({
    template: `<type-loader
        [standalone]="true"
        [entertainerText]="[
            'Configuring account',
            'Activating additional features',
            'Enabling export options',
            'Preparing storage'
        ]"
    ></type-loader>`
})
export class SafeSpaceComponent {}
