import { NgModule } from '@angular/core';
import { UiModule } from '@type/ui';
import { UsageLimitationComponent } from './usage-limitation.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { PaymentDialogModule } from '@type/payment-dialog/payment-dialog.module';

const exportComponents = [UsageLimitationComponent];

@NgModule({
    declarations: [...exportComponents],
    exports: [...exportComponents],
    imports: [UiModule, RoundProgressModule, PaymentDialogModule]
})
export class UsageLimitationModule {}
