import { Command } from '../command';
import { Word } from '@type/shared-models/editor/word';
import { arrayFromRange } from '@type/shared-models/shared-utils/array-utils';
import { EditorService } from '../../services/editor.service';

export class WordReAddCommand implements Command {
    private readonly startIndex: number;
    private readonly endIndex: number;
    private words: Word[];
    private editorService: EditorService;
    private oldIsCutValues: { wordIndex: number; isCut: boolean }[] = [];
    private reloadTranscript = false;
    constructor(reAddObject: { editorService; startIndex: number; endIndex: number; words: Word[] }) {
        this.startIndex = reAddObject.startIndex;
        this.endIndex = reAddObject.endIndex;
        this.words = reAddObject.words;
        this.editorService = reAddObject.editorService;
    }

    execute() {
        for (let i = this.startIndex; i <= this.endIndex; i++) {
            if (this.words[i].isCut) {
                this.oldIsCutValues.push({ wordIndex: i, isCut: this.words[i].isCut });
                this.words[i].isCut = false;
            }
        }
        this.reloadTranscript =
            this.words.slice(this.startIndex, this.endIndex + 1).filter((word) => word.hasLinebreak).length >= 5;

        this.editorService.removeSelection();
        this.editorService.updateChunkWordArray(this.reloadTranscript);
        this.editorService.updateWords(arrayFromRange(this.startIndex, this.endIndex));
        return this;
    }

    undo() {
        for (const value of this.oldIsCutValues) {
            this.words[value.wordIndex].isCut = value.isCut;
        }
        this.editorService.removeSelection();
        this.editorService.updateChunkWordArray(this.reloadTranscript);
        this.editorService.updateWords(arrayFromRange(this.startIndex, this.endIndex));
    }
}
