import { isDevMode } from '@angular/core';
import { environment } from './environment';

export const isLocalEnvironment = () =>
    environment.emulator === 'localhost' || environment.emulator === 'functions' || environment.emulator === 'full';

export const getEnvironmentUrl = () => {
    if (isLocalEnvironment()) {
        return 'http://localhost:4200';
    } else if (environment.production) {
        return 'https://podcasteditor.streamlabs.com';
    } else {
        return 'https://podcasteditor.streamlabs.dev';
    }
};
