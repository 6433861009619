/* eslint-disable @typescript-eslint/naming-convention */
export const Languages: Record<string, Language> = {
    'en-US': {
        name: 'English',
        codeLong: 'en-US',
        codeShort: 'US',
        emoji: '🇺🇸',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: true,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'es-ES': {
        name: 'Spanish',
        codeLong: 'es-ES',
        codeShort: 'ES',
        emoji: '🇪🇸',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Su transcripción aparecerá una vez que se complete la carga.'
    },
    'fr-FR': {
        name: 'French',
        codeLong: 'fr-FR',
        codeShort: 'FR',
        emoji: '🇫🇷',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Votre transcription apparaîtra une fois le téléchargement terminé.'
    },
    'it-IT': {
        name: 'Italian',
        codeLong: 'it-IT',
        codeShort: 'IT',
        emoji: '🇮🇹',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'de-DE': {
        name: 'German',
        codeLong: 'de-DE',
        codeShort: 'DE',
        emoji: '🇩🇪',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Das Transkript wird angezeigt, sobald der Upload abgeschlossen ist.'
    },

    'nl-NL': {
        name: 'Dutch',
        codeLong: 'nl-NL',
        codeShort: 'NL',
        emoji: '🇳🇱',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'no-NO': {
        name: 'Norwegian',
        codeLong: 'no-NO',
        codeShort: 'NO',
        emoji: '🇳🇴',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'da-DK': {
        name: 'Danish',
        codeLong: 'da-DK',
        codeShort: 'DK',
        emoji: '🇩🇰',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'fi-FI': {
        name: 'Finnish',
        codeLong: 'fi-FI',
        codeShort: 'FI',
        emoji: '🇫🇮',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: false,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'sv-SE': {
        name: 'Swedish',
        codeLong: 'sv-SE',
        codeShort: 'SE',
        emoji: '🇸🇪',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: false,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'tr-TR': {
        name: 'Turkish',
        codeLong: 'tr-TR',
        codeShort: 'TR',
        emoji: '🇹🇷',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },

    'pl-PL': {
        name: 'Polish',
        codeLong: 'pl-PL',
        codeShort: 'PL',
        emoji: '🇵🇱',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'pt-PT': {
        name: 'Portuguese',
        codeLong: 'pt-PT',
        codeShort: 'PT',
        emoji: '🇵🇹',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'el-GR': {
        name: 'Greek',
        codeLong: 'el-GR',
        codeShort: 'GR',
        emoji: '🇬🇷',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'hu-HU': {
        name: 'Hungarian',
        codeLong: 'hu-HU',
        codeShort: 'HU',
        emoji: '🇭🇺',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: false,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'ro-RO': {
        name: 'Romanian',
        codeLong: 'ro-RO',
        codeShort: 'RO',
        emoji: '🇷🇴',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: false,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },

    'cs-CZ': {
        name: 'Czech',
        codeLong: 'cs-CZ',
        codeShort: 'CZ',
        emoji: '🇨🇿',
        beta: false,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'ru-RU': {
        name: 'Russian',
        codeLong: 'ru-RU',
        codeShort: 'RU',
        emoji: '🇷🇺',
        beta: true,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'zh-CN': {
        name: 'Mandarin',
        codeLong: 'zh-CN',
        codeShort: 'ZH',
        emoji: '🇨🇳',
        beta: true,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'yue-Hant-HK': {
        name: 'Cantonese',
        codeLong: 'yue-Hant-HK',
        codeShort: 'HK',
        emoji: '🇭🇰',
        beta: true,
        transcription: true,
        textTranslation: false,
        voiceTranslation: false,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'ko-KR': {
        name: 'Korean',
        codeLong: 'ko-KR',
        codeShort: 'KR',
        emoji: '🇰🇷',
        beta: true,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'ja-JP': {
        name: 'Japanese',
        codeLong: 'ja-JP',
        codeShort: 'JP',
        emoji: '🇯🇵',
        beta: true,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'iw-IL': {
        name: 'Hebrew',
        codeLong: 'iw-IL',
        codeShort: 'IL',
        emoji: '🇮🇱',
        beta: true,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'ar-SA': {
        name: 'Arabic (Saudi Arabia)',
        codeLong: 'ar-SA',
        codeShort: 'SA',
        emoji: '🇸🇦',
        beta: true,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'ms-MY': {
        name: 'Malay',
        codeLong: 'ms-MY',
        codeShort: 'MY',
        emoji: '🇲🇾',
        beta: true,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'id-ID': {
        name: 'Indonesian',
        codeLong: 'id-ID',
        codeShort: 'ID',
        emoji: '🇮🇩',
        beta: true,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'ur-PK': {
        name: 'Urdu',
        codeLong: 'ur-PK',
        codeShort: 'PK',
        emoji: '🇵🇰',
        beta: true,
        transcription: true,
        textTranslation: true,
        voiceTranslation: false,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'hi-IN': {
        name: 'Hindi',
        codeLong: 'hi-IN',
        codeShort: 'IN',
        emoji: '🇮🇳',
        beta: true,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'mr-IN': {
        name: 'Marathi',
        codeLong: 'mr-IN',
        codeShort: 'IN',
        emoji: '🇮🇳',
        beta: true,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'ta-IN': {
        name: 'Tamil',
        codeLong: 'ta-IN',
        codeShort: 'IN',
        emoji: '🇮🇳',
        beta: true,
        transcription: true,
        textTranslation: true,
        voiceTranslation: true,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    },
    'bn-BD': {
        name: 'Bengali',
        codeLong: 'bn-BD',
        codeShort: 'BD',
        emoji: '🇧🇩',
        beta: true,
        transcription: true,
        textTranslation: true,
        voiceTranslation: false,
        autoHighlights: false,
        uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
    }
};

export const PlaceholderLanguage: Language = {
    name: 'Select a language',
    codeLong: '00-00',
    codeShort: '00',
    emoji: '🏳️',
    beta: false,
    transcription: true,
    voiceTranslation: true,
    textTranslation: true,
    autoHighlights: true,
    uploadingPlaceholderText: 'Your transcript will appear once the upload is complete.'
};

export interface Language {
    codeLong: string;
    codeShort: string;
    emoji: string;
    beta: boolean;
    transcription: boolean;
    textTranslation: boolean;
    voiceTranslation: boolean;
    autoHighlights: boolean;
    name: string;
    uploadingPlaceholderText: string;
}

export type LanguageSelectionType = 'transcription' | 'textTranslation' | 'voiceTranslation';
