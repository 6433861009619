export interface ExtractAudioResult {
    name: string;
    format: string;
    data: Blob;
}
export const videoToAudio = (videoFileData, targetAudioFormat): Promise<ExtractAudioResult> => {
    try {
        targetAudioFormat = targetAudioFormat.toLowerCase();
        const reader = new FileReader();
        return new Promise((resolve) => {
            reader.onload = (event) => {
                const contentType = 'audio/' + targetAudioFormat;
                const audioContext = new window.AudioContext();
                let myBuffer;
                const sampleRate = 16000;
                const numberOfChannels = 1;
                const videoFileAsBuffer = reader.result as ArrayBuffer;
                audioContext.decodeAudioData(videoFileAsBuffer).then((decodedAudioData) => {
                    const duration = decodedAudioData.duration;
                    const offlineAudioContext = new OfflineAudioContext(
                        numberOfChannels,
                        sampleRate * duration,
                        sampleRate
                    );
                    const soundSource = offlineAudioContext.createBufferSource();
                    myBuffer = decodedAudioData;
                    soundSource.buffer = myBuffer;
                    soundSource.connect(offlineAudioContext.destination);
                    soundSource.start();
                    offlineAudioContext
                        .startRendering()
                        .then((renderedBuffer) => {
                            const UintWave = createWaveFileData(renderedBuffer);
                            const b64Data = btoa(uint8ToString(UintWave));
                            const blob = getBlobFromBase64Data(b64Data, contentType);

                            const convertedAudio: ExtractAudioResult = {
                                name: videoFileData.name.substring(0, videoFileData.name.lastIndexOf('.')),
                                format: targetAudioFormat,
                                data: blob
                            };
                            resolve(convertedAudio);
                        })
                        .catch((err) => {
                            console.log('Rendering failed: ' + err);
                        });
                });
            };
            reader.readAsArrayBuffer(videoFileData);
        });
    } catch (e) {
        console.log('Error occurred while converting : ', e);
    }
};

const getBlobFromBase64Data = (b64Data, contentType, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

const createWaveFileData = (audioBuffer) => {
    const frameLength = audioBuffer.length;
    const numberOfChannels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const bitsPerSample = 16;
    const byteRate = (sampleRate * numberOfChannels * bitsPerSample) / 8;
    const blockAlign = (numberOfChannels * bitsPerSample) / 8;
    const wavDataByteLength = frameLength * numberOfChannels * 2;
    const headerByteLength = 44;
    const totalLength = headerByteLength + wavDataByteLength;

    const waveFileData = new Uint8Array(totalLength);

    const subChunk1Size = 16;
    const subChunk2Size = wavDataByteLength;
    const chunkSize = 4 + (8 + subChunk1Size) + (8 + subChunk2Size);

    writeString('RIFF', waveFileData, 0);
    writeInt32(chunkSize, waveFileData, 4);
    writeString('WAVE', waveFileData, 8);
    writeString('fmt ', waveFileData, 12);

    writeInt32(subChunk1Size, waveFileData, 16);
    writeInt16(1, waveFileData, 20);
    writeInt16(numberOfChannels, waveFileData, 22);
    writeInt32(sampleRate, waveFileData, 24);
    writeInt32(byteRate, waveFileData, 28);
    writeInt16(blockAlign, waveFileData, 32);
    writeInt32(bitsPerSample, waveFileData, 34);

    writeString('data', waveFileData, 36);
    writeInt32(subChunk2Size, waveFileData, 40);

    writeAudioBuffer(audioBuffer, waveFileData, 44);

    return waveFileData;
};

const writeString = (s, a, offset) => {
    for (let i = 0; i < s.length; ++i) {
        a[offset + i] = s.charCodeAt(i);
    }
};

const writeInt16 = (n, a, offset) => {
    n = Math.floor(n);

    const b1 = n & 255;
    const b2 = (n >> 8) & 255;

    a[offset + 0] = b1;
    a[offset + 1] = b2;
};

const writeInt32 = (n, a, offset) => {
    n = Math.floor(n);
    const b1 = n & 255;
    const b2 = (n >> 8) & 255;
    const b3 = (n >> 16) & 255;
    const b4 = (n >> 24) & 255;

    a[offset + 0] = b1;
    a[offset + 1] = b2;
    a[offset + 2] = b3;
    a[offset + 3] = b4;
};

const writeAudioBuffer = (audioBuffer, a, offset) => {
    const n = audioBuffer.length;
    const channels = audioBuffer.numberOfChannels;

    for (let i = 0; i < n; ++i) {
        for (let k = 0; k < channels; ++k) {
            const buffer = audioBuffer.getChannelData(k);
            let sample = buffer[i] * 32768.0;

            if (sample < -32768) {
                sample = -32768;
            }
            if (sample > 32767) {
                sample = 32767;
            }

            writeInt16(sample, a, offset);
            offset += 2;
        }
    }
};

const uint8ToString = (buf) => {
    let i;
    let length;
    let out = '';
    for (i = 0, length = buf.length; i < length; i += 1) {
        out += String.fromCharCode(buf[i]);
    }
    return out;
};
