import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ThemingIconSize } from '../models/theming';

@Directive({ selector: '[appIcon]' })
export class IconDirective implements OnChanges {
    _iconClasses: { [prop: string]: boolean } = { 'icon-size-default': true };

    @HostBinding('class.typ-icon') typIcon = true;
    @HostBinding('class') class = this._iconClasses;
    @Input() size: ThemingIconSize;

    ngOnChanges({ size }: SimpleChanges) {
        if (size) {
            const previousValue = size.previousValue ?? 'default';
            const currentValue = size.currentValue ?? 'default';
            this._iconClasses[`icon-size-${previousValue}`] = false;
            this._iconClasses[`icon-size-${currentValue}`] = true;
        }
    }
}
