import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'type-volume-slider',
    exportAs: 'slider',
    templateUrl: 'volume-slider.component.html',
    styleUrls: ['volume-slider.component.scss']
})
export class VolumeSliderComponent {
    @Output() volumeChanged = new EventEmitter<number>();

    private lastVolume;
    private _volume = 1;
    @Input()
    set volume(val: number) {
        this._volume = val;
    }
    get volume() {
        return this._volume;
    }
    sliderChanged(sliderChange) {
        this.volume = sliderChange.value;
    }

    emitVolume() {
        this.volumeChanged.emit(this._volume);
    }

    mute() {
        if (this.volume !== 0) {
            this.lastVolume = this._volume;
            this.sliderChanged({ value: 0 });
        } else {
            this.lastVolume = this.lastVolume ?? 1;
            this.sliderChanged({ value: this.lastVolume });
        }
        this.emitVolume();
    }
}
