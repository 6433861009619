export const PAYMENT_REASONS = {
    profile: 'profile',
    usageLimitationButton: 'usage limitation button',
    projectLimitation: 'project limitation',
    recordingLimitation: 'recording limitation',
    uploadFilesize: 'upload filesize',
    uploadDuration: 'upload duration',
    uploadUsageLimit: 'upload usage limit',
    linkUploadFilesize: 'link upload filesize',
    linkUploadDuration: 'link upload duration',
    linkUploadUsageLimit: 'link upload usage limit',
    translation: 'translation',
    translationLimitation: 'translation limitation',
    exportButton: 'export button',
    textExport: 'text export ',
    watermarkRemoval: 'remove watermark',
    privateInviteToProject: 'invite to project private link',
    afterSignup: 'after signup modal',
    paymentFailed: 'previous payment failed',
    planComparison: 'plan comparison',
    subtitleMenu: 'subtitle menu',
    subtitleCanvasMenu: 'subtitle canvas style menu',
    afterAnonymousSignup: 'afterAnonymousSignup'
} as const;

export type PaymentReasonType = typeof PAYMENT_REASONS[keyof typeof PAYMENT_REASONS];
