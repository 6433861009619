import { NgModule } from '@angular/core';
import { OpenOnDesktopDirective } from './open-on-desktop.directive';

const exportItems = [OpenOnDesktopDirective];
@NgModule({
    imports: [],
    declarations: [...exportItems],
    exports: [...exportItems]
})
export class OpenOnDesktopModule {}
