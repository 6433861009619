export enum KonvaAnimationType {
    OPACITY,
    SCALE,
    MOVE_UP
}

export enum KonvaAnimationDirection {
    ANIMATE_IN,
    ANIMATE_OUT,
    ANIMATE_IN_OUT
}
