const ErrorMessages = {
    CardDeclined: 'This card was declined! Please change your card information.'
};

export default ErrorMessages;

export enum UploadError {
    ERROR = 'error',
    SUCCESS = 'success',
    DEFAULT = 'default'
}

export interface UploadErrorElement {
    title: string;
    line1: string;
    line2?: string;
    upgradeButton: boolean;
    code?: string;
    sendSlackMessage?: boolean;
}

export const DurationAudioError: UploadErrorElement = {
    title: 'Your audio is too long',
    line1: 'Currently, our audio length for the {currentPlanName} plan is limited to {minutes} min max.',
    line2: 'If you would like to upload a longer audio you can upgrade to {higherPlanName}.',
    upgradeButton: true,
    code: 'audio duration'
};
export const DurationVideoError: UploadErrorElement = {
    title: 'Your video is too long',
    line1: 'Currently, our video length for the {currentPlanName} plan is limited to {minutes} min max.',
    line2: 'If you would like to upload a longer audio you can upgrade to {higherPlanName}.',
    upgradeButton: true,
    code: 'video duration'
};

export const FilesizeVideoError: UploadErrorElement = {
    title: 'Your video is too large',
    line1: 'Currently, our video size is limited to {sizeString} max.',
    line2: 'If you would like to upload a bigger file you can upgrade to {higherPlanName}.',
    upgradeButton: true,
    code: 'video filesize'
};
export const FilesizeAudioError: UploadErrorElement = {
    title: 'Your audio is too large',
    line1: 'Currently, our audio size is limited to {sizeString} max.',
    line2: 'If you would like to upload a bigger file you can upgrade to {higherPlanName}.',
    upgradeButton: true,
    code: 'audio filesize'
};

export const FilesizeFileError: UploadErrorElement = {
    title: 'Your file is too large',
    line1: 'Currently, our file size is limited to {sizeString} max.',
    line2: 'If you would like to upload a bigger file you can upgrade to {higherPlanName}.',
    upgradeButton: true,
    code: 'filesize'
};
export const ExtensionVideoError: UploadErrorElement = {
    title: 'Your video is the wrong file format',
    line1: 'You uploaded an invalid file format.',
    line2: 'Make sure its either {videoExtensionString}.',
    upgradeButton: false
};
export const InvalidFileError: UploadErrorElement = {
    title: 'Unsupported file',
    line1: "We couldn't handle the file your provided.",
    line2: "If you send us a message in the website chat, we'll be there to help.",
    upgradeButton: false
};
export const ExtensionAudioError: UploadErrorElement = {
    title: 'Your audio is the wrong file format',
    line1: 'You uploaded an invalid file format.',
    line2: 'Make sure its either {audioExtensionString}.',
    upgradeButton: false
};
export const ExtensionFileError: UploadErrorElement = {
    title: 'Your file is the wrong file format',
    line1: 'You uploaded an invalid file format.',
    line2: 'Make sure its either {allExtensionString}.',
    upgradeButton: false
};
export const UsageLimitationReachedError: UploadErrorElement = {
    title: 'You reached your upload limitation',
    line1: 'In our {currentPlanName} plan you can upload {hoursMonth} hours of videos or audios each month.',
    line2: 'If you would like to upload more you can upgrade to {higherPlanName}.',
    upgradeButton: true,
    code: 'usage limitation'
};
export const RecordingLimitationReachedError: UploadErrorElement = {
    title: 'Recording limit reached',
    line1: 'Our {currentPlanName} plan is limited to {minutes} min of recording time.',
    line2: 'If you want to record longer you can upgrade to {higherPlanName}.',
    upgradeButton: true,
    code: 'recording usage limitation'
};
export const UrlImportGeneralError: UploadErrorElement = {
    title: 'There was an error importing the file.',
    line1: "Please check if you've set the file to public access.",
    upgradeButton: false
};
export const YouTubeUrlError: UploadErrorElement = {
    title: 'Your YouTube link is in the wrong format.',
    line1: "Try to click 'share' below the video and paste the link.",
    line2: 'If you are still having trouble do not hesitate to contact us via the chat.',
    upgradeButton: false
};
export const YouTubeNetworkError: UploadErrorElement = {
    title: '',
    line1: 'Sorry, an error occurred while loading your video from YouTube. Please try again.',
    line2: '',
    upgradeButton: false
};
export const YouTubeChannelError: UploadErrorElement = {
    title: '',
    line1: 'The link you provided points to a channel, not a video.',
    line2: 'Please provide a link to a specific video.',
    upgradeButton: false
};
export const YouTubeLiveError: UploadErrorElement = {
    title: ' ',
    line1: 'The link you provided points to a live stream, not a video.',
    line2: 'Please provide a link to a specific video.',
    upgradeButton: false
};

/**
 * Returns a string based on and input array of extensions
 * @param extensions
 * @example input: [".mp4", ".mp3", ".wmv"] - output: ".mp4, .mp3 or .wmv"
 */
export function getExtensionsString(extensions: string[]) {
    return `${extensions.filter((ext, index) => index < extensions.length - 1).join(', ')} or ${[
        extensions[extensions.length - 1]
    ]}`;
}
