import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Language, Languages, LanguageSelectionType, PlaceholderLanguage } from '@type/shared-models/language';
import { browserSupportsLanguageEmojis } from '@type/shared-models/shared-utils/browser-utils';
import { DialogService } from '@type/dialog';
import { EditorService } from '../../services/editor.service';
import { ContactComponent } from '../contact/contact.component';
import { HTMLTextAlignment, ThemingColorType, ThemingSizeType } from '@type/ui/models';

@Component({
    selector: 'app-language-selection',
    templateUrl: './language-selection.component.html'
})
export class LanguageSelectionComponent implements OnChanges, OnInit {
    @Input() disabled = false;
    @Input() languageSelectionType: LanguageSelectionType;
    @Input() showLanguageName = false;
    @Input() showBeta = true;
    @Input() disableSelectedLanguage: boolean;
    @Input() selectedLanguage: string;
    @Input() hideLanguage: string;
    @Input() color: ThemingColorType = 'secondary';
    @Input() textAlignment: HTMLTextAlignment = 'center';
    @Input() size: ThemingSizeType = 'lg';

    @Output() languageChanged: EventEmitter<Language> = new EventEmitter();
    @Output() initialSelectedLanguage: EventEmitter<Language> = new EventEmitter();

    // set language without initial upload language, translate throws error for same languages
    languages = Object.values(Languages);
    currentSelectedLanguage: Language;

    filteredLanguages = this.languages;
    showFallbackEmojis = !browserSupportsLanguageEmojis();

    constructor(private editorService: EditorService, private dialogService: DialogService) {}

    ngOnChanges({ disableSelectedLanguage, languageSelectionType }: SimpleChanges) {
        if (disableSelectedLanguage && this.disableSelectedLanguage) {
            this.languages = this.languages.filter((language) => {
                const currentProjectLanguage = this.editorService.currentProject?.language;

                const isDifferentFromProjectLanguage = currentProjectLanguage
                    ? language.codeLong.substring(0, 2) !== currentProjectLanguage.substring(0, 2)
                    : true;

                // Check if language is different from the language that should be hidden in the dropdown
                const isDifferentFromHideLanguage = this.hideLanguage
                    ? language.codeLong.substring(0, 2) !== this.hideLanguage.substring(0, 2)
                    : true;

                return (
                    isDifferentFromProjectLanguage &&
                    isDifferentFromHideLanguage &&
                    this.checkLanguageAvailable(language, this.languageSelectionType)
                );

                // language.codeLong.substring(0, 2) !== this.editorService.currentProject.language.substring(0, 2) &&
                //     this.checkLanguageAvailable(language, this.languageSelectionType);
            });
            this.filteredLanguages = this.languages;
        }
        if (languageSelectionType) {
            this.filteredLanguages = this.languages.filter((language) =>
                this.checkLanguageAvailable(language, this.languageSelectionType)
            );
        }

        this.currentSelectedLanguage = this.getLanguageByLongCode(this.selectedLanguage) || this.languages[0];
    }

    ngOnInit() {
        this.initialSelectedLanguage.emit(this.currentSelectedLanguage);
    }

    getLanguageByLongCode(codeLong: string): Language {
        if (codeLong === PlaceholderLanguage.codeLong) {
            return PlaceholderLanguage;
        }

        return this.languages.find((language) => language.codeLong === codeLong);
    }

    selectLanguage(language: Language) {
        this.currentSelectedLanguage = language;
        this.languageChanged.emit(language);
        this.filteredLanguages = this.languages;
    }

    searchLanguage(search: string) {
        this.filteredLanguages = this.languages.filter((language) =>
            [language.name, language.codeShort, language.codeLong].some((item) =>
                item.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            )
        );
    }

    requestLanguage(event: MouseEvent) {
        event.stopPropagation(); // dont close language window
        this.dialogService.openDialog(ContactComponent, { data: { requestLanguage: true } });
    }

    checkLanguageAvailable(language: Language, languageSelectionType: LanguageSelectionType) {
        switch (languageSelectionType) {
            case 'transcription':
                return language.transcription;

            case 'voiceTranslation':
                return language.voiceTranslation;

            case 'textTranslation':
                return language.textTranslation;

            default:
                return false;
        }
    }
}
