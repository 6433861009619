export const BROWSERS = {
    edge: 'edge',
    edgeChromium: 'edge chromium',
    opera: 'opera',
    chrome: 'chrome',
    ie: 'ie',
    firefox: 'firefox',
    safari: 'safari',
    other: 'other'
} as const;

export type BrowserTypes = typeof BROWSERS[keyof typeof BROWSERS];

export const CURRENT_BROWSER = (function (agent) {
    if (agent.indexOf('edge') > -1) return BROWSERS.edge;
    if (agent.indexOf('edg') > -1) return BROWSERS.edgeChromium;
    if (agent.indexOf('opr') > -1 && !!(window as any).opr) return BROWSERS.opera;
    if (agent.indexOf('chrome') > -1 && !!(window as any).chrome) return BROWSERS.chrome;
    if (agent.indexOf('trident') > -1) return BROWSERS.ie;
    if (agent.indexOf('firefox') > -1) return BROWSERS.firefox;
    if (agent.indexOf('safari') > -1) return BROWSERS.safari;
    return BROWSERS.other;
})(window.navigator.userAgent.toLowerCase());

/**
 * Returns true if the current browser is Google Chrome (other Chrome browsers will return false)
 */
export function isGoogleChromeBrowser() {
    // See https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome and https://stackoverflow.com/questions/17278770/how-do-i-detect-chromium-specifically-vs-chrome
    const isChromium = window['chrome'];
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = typeof window['opr'] !== 'undefined';
    const isIEedge = winNav.userAgent.indexOf('Edg') > -1;
    const isChrome = () => {
        for (var i = 0; i < navigator.plugins.length; i++)
            if (navigator.plugins[i].name == 'Chrome PDF Viewer') return true;
        return false;
    };
    return (
        isChrome() &&
        isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false
    );
}
