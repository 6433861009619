<ng-container *ngIf="!upgradeService.emailAlreadyInUse && !upgradeService.successMessage">
    <app-button size="md" class="payment-dialog-close-button" appDialogClose>
        <svg-icon appIcon
            ><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L11 11M11 1L1 11" stroke="black" />
            </svg>
        </svg-icon>
    </app-button>
    <div>
        <div class="upgrade-sections-container" [class.is-overview-page]="(upgradeService.planType$ | async) === null">
            <div class="upgrade-sections-container__left">
                <type-upgrade-features></type-upgrade-features>
                <app-button
                    *ngIf="upgradeService.planType$ | async; else closeButton"
                    (btnClick)="openPlanSelection()"
                    class="mt-12"
                >
                    <svg-icon appIcon
                        ><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                stroke="#2B2B2B"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M11 7L7 11L11 15"
                                stroke="#2B2B2B"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M15 11H7"
                                stroke="#2B2B2B"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </svg-icon>
                    <div class="w-full text-left pl-1">Back</div>
                </app-button>
                <ng-template #closeButton>
                    <app-button class="mt-12" (btnClick)="closeModal()">
                        <svg-icon appIcon
                            ><svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                    stroke="#2B2B2B"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M11 7L7 11L11 15"
                                    stroke="#2B2B2B"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M15 11H7"
                                    stroke="#2B2B2B"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </svg-icon>
                        <div class="w-full text-left pl-1">
                            <ng-container *ngIf="currentPlanName$ | async; else onlyBack">
                                Continue with {{ currentPlanName$ | async }} Plan
                            </ng-container>
                            <ng-template #onlyBack>Back</ng-template>
                        </div>
                    </app-button>
                </ng-template>
            </div>

            <div *ngIf="upgradeService.planType$ | async; else planSelection" class="upgrade-sections-container__right">
                <div class="upgrade-sections-container__right__content shadow-floating z-10">
                    <form class="upgrade-email-form" *ngIf="showEmail">
                        <div class="default-input">
                            <label for="email" class="default-input__label">E-Mail Address</label>
                            <div class="default-input__input-field">
                                <input
                                    data-spec="upgrade-input"
                                    id="email"
                                    [formControl]="emailCtrl"
                                    required
                                    type="email"
                                    autocomplete="email"
                                    class="default-input__input-field__input"
                                    name="email"
                                    placeholder="youlooknicetoday@email.com"
                                    autofocus
                                />
                            </div>
                            <p
                                *ngIf="emailCtrl.invalid && emailCtrl.touched"
                                class="default-input__message default-input__message--error"
                            >
                                <span *ngIf="emailCtrl.errors?.required">Email address is required.</span>
                                <span *ngIf="emailCtrl.errors?.email">Please enter a valid email address.</span>
                            </p>
                        </div>
                    </form>

                    <type-upgrade-interval-selector></type-upgrade-interval-selector>

                    <type-upgrade-credit-card-input
                        [emailCtrl]="emailCtrl"
                        [paymentReason]="paymentReason"
                    ></type-upgrade-credit-card-input>
                </div>

                <span class="text-left text-xs max-w-lg p-3 mt-3 text-black bg-white rounded-2xl text-black">
                    You will be charged $US{{
                        (upgradeService.promotionCodeData$ | async)
                            ? ((1 - (upgradeService.promotionCodeData$ | async)?.discount / 100) *
                                  ((planPrice$ | async) -
                                      (1 - (upgradeService.promotionCodeData$ | async)?.discount / 100) *
                                          currentPlan.price)) /
                              100
                            : ((planPrice$ | async) - currentPlan.price) / 100
                    }}
                    today. To ensure uninterrupted service, your subscription will be set to continuous auto-renewal
                    payments of $US{{
                        (upgradeService.promotionCodeData$ | async)
                            ? ((1 - (upgradeService.promotionCodeData$ | async)?.discount / 100) *
                                  (planPrice$ | async)) /
                              100
                            : (planPrice$ | async) / 100
                    }}
                    per
                    <span
                        ><ng-container *ngIf="(selectedInterval$ | async) === 'monthly'; else annually">
                            month</ng-container
                        >
                        <ng-template #annually>year</ng-template></span
                    >. Your next payment is scheduled on
                    {{
                        (selectedInterval$ | async) === 'annually'
                            ? (nextPaymentDate.annually | date: 'MM/dd/yyyy')
                            : (nextPaymentDate.monthly | date: 'MM/dd/yyyy')
                    }}. You can modify or cancel your subscription any time in Profile settings, Billing section
                </span>
            </div>

            <ng-template #planSelection>
                <type-upgrade-plan-selector></type-upgrade-plan-selector>
            </ng-template>
        </div>
    </div>
</ng-container>

<div class="payment-messages" *ngIf="upgradeService.emailAlreadyInUse">
    <app-dialog-header>
        Account already exists
        <app-dialog-close-button appDialogClose></app-dialog-close-button>
    </app-dialog-header>
    <p>This account already exists. To update your plan, click here and log-in to complete the process.</p>
    <app-dialog-footer>
        <a [href]="pricingUrl">
            <app-button color="primary">Login</app-button>
        </a>
    </app-dialog-footer>
</div>

<div class="payment-messages" *ngIf="upgradeService.successMessage">
    <app-dialog-header>
        Success 🥳
        <app-dialog-close-button appDialogClose></app-dialog-close-button>
    </app-dialog-header>
    <p>{{ upgradeService.successMessage }}</p>
</div>
