export const FreeFeatureList: string[] = [
    '1h of content upload per month',
    'Edit videos up to 1h long',
    '15GB of storage',
    'Subtitle translation',
    'Streamlabs branding'
];
export const BasicFeatureList: string[] = [
    '5h per month',
    'Edit videos up to 1h long',
    '50GB of storage',
    'Export subtitles (.srt, vtt, .txt)',
    'Subtitle translation',
    'No watermark'
];
export const ProFeatureList: string[] = [
    '40h of content upload per month',
    'Edit videos up to 2 hours long',
    '250GB of storage',
    'Export subtitles (.srt, vtt, .txt)',
    'Translation',
    'No watermark'
];
export const ProTeamFeatureList: string[] = [
    '40h of content upload per month',
    'Edit videos up to 2 hours long',
    '500 GB of storage',
    'Export subtitles (.srt, .vtt, .txt)',
    'Subtitle translation',
    'No watermark',
    '2 collaborators'
];
export const BusinessFeatureList: string[] = [
    '100h per month',
    'Edit videos up to 2 hours long',
    '1.5TB of storage',
    'Export subtitles (.srt, .vtt, .txt)',
    'Subtitle translation',
    'No watermark',
    '5 collaborators',
    'Dedicated account manager',
    'User management'
];
export const UltraFeatureList: string[] = [
    'Everything in Pro, and',
    'Streamlabs for live streaming',
    'Talk Studio for recording',
    'Video Editor for advanced editing',
    'Cross Clip for clips creation',
    'Link Space for link in bio'
];

export interface PlanDescription {
    icon?: string;
    title: string;
    label?: string;
    description?: string;
    featureList: string[];
}

export const OverviewPlanDescription: PlanDescription = {
    // icon: '🚀',
    title: 'Upgrade Account',
    description: 'More productivity. More power.\n' + 'We’re here to help you grow with the following subscriptions.',
    featureList: []
};

export const ExportLimitDescription: PlanDescription = {
    // icon: '🚀',
    title: 'Upgrade Account',
    description: 'Video/audio over 1 hour can not be exported with a free plan.',
    featureList: []
};

export const FreePlanDescription: PlanDescription = {
    // icon: '⛱',
    title: 'Upgrade to STARTER',
    label: 'Free',
    description: 'For anyone who wants to start creating content and building an audience.',
    featureList: FreeFeatureList
};

export const StarterPlanDescription: PlanDescription = {
    icon: '⛺',
    title: 'Upgrade to STARTER',
    label: 'Starter',
    description: 'For content creators with weekly output.',
    featureList: ProFeatureList
};
export const StarterBasicPlanDescription: PlanDescription = {
    icon: '⛺',
    title: 'Upgrade to STARTER',
    label: 'Starter',
    description: 'For content creators with weekly output.',
    featureList: BasicFeatureList
};

export const ProPlanDescription: PlanDescription = {
    // icon: '🏠',
    title: 'Podcast Editor Pro',
    label: 'Pro',
    description: 'All the Podcast Editor features for editing your content and creating clips.',
    featureList: ProFeatureList
};

export const ProTeamPlanDescription: PlanDescription = {
    icon: '🏬',
    title: 'Upgrade to TEAM',
    label: 'Team',
    description: 'For businesses, to collaborate on automation.',
    featureList: ProTeamFeatureList
};

export const BusinessPlanDescription: PlanDescription = {
    icon: '🏬',
    title: 'Upgrade to BUSINESS',
    label: 'Business',
    description: 'For businesses, to collaborate on automation.',
    featureList: BusinessFeatureList
};

export const UltraPlanDescription: PlanDescription = {
    // icon: '🏠',
    title: 'Upgrade to ULTRA',
    label: 'Streamlabs Ultra',
    description: 'All the podcasting tools you need. Recording, streaming, editing and more.',
    featureList: UltraFeatureList
};

// This is structured like this because you can easily copy and paste the php array from the website (pricing_table.php) and replace : with :
export const FeatureComparisonTable = {
    featureArray: {
        videoUploadMonth: 'Video upload/month',
        videoLength: 'Video length',
        projects: 'Projects',
        storage: 'Storage',
        downloadCount: 'Downloads',
        filesize: 'Max. filesize',
        exportQuality: 'Export quality',
        watermark: 'Watermark',
        autoSubtitles: 'Auto subtitles',
        subtitleStyling: 'Subtitle styling',
        subtitleTranslation: 'Translation',
        subtitleExport: 'Export srt/vtt/txt',
        streamlabsDesktop: 'Streamlabs Desktop',
        streamlabsWebSuite: 'Streamlabs Web Suite',
        streamlabsMobile: 'Streamlabs Mobile',
        streamlabsConsole: 'Streamlabs Console',
        talkStudio: 'Talk Studio Pro',
        videoEditor: 'Video Editor Pro',
        crossClip: 'Cross Clip Pro',
        linkSpace: 'Link Space Pro'
    },
    descriptionArray: {
        videoUploadMonth: 'The max. length of all videos you can upload per month ',
        videoLength: 'The max. length of a video you can upload',
        projects: 'Number of projects you can store',
        storage: 'Maximum capacity to store your projects',
        downloadCount: 'Downloads per project',
        filesize: 'Maximum uploadable file size per video',
        exportQuality: 'Highest exportable video resolution',
        watermark: 'Whether a watermark is added in your video',
        autoSubtitles: 'Automatically add subtitles to your videos',
        subtitleStyling: 'Ability to style your subtitles in custom colors',
        subtitleTranslation: 'Translate your content into multiple languages',
        subtitleExport: 'Ability to export .srt, .vtt and .txt files with generated subtitles',
        streamlabsDesktop: 'All Streamlabs Desktop Ultra features for live streaming and recording',
        streamlabsWebSuite: 'All Streamlabs Web Suite tools for growing your audience',
        streamlabsMobile: 'Streamlabs Mobile Ultra to stream from your phone',
        streamlabsConsole: 'Streamlabs Console Ultra to stream from your console',
        talkStudio: 'All Talk Studio Pro features to stream and record in the browser',
        videoEditor: 'All Video Editor Pro features for advanced video editing',
        crossClip: 'All Cross Clip Pro features to create clips',
        linkSpace: 'All Link Space Pro features to create your link in bio'
    },
    freeArray: {
        videoUploadMonth: '1 hour',
        videoLength: '1 hour',
        projects: 'unlimited',
        storage: '15GB',
        downloadCount: 'unlimited',
        filesize: '1GB',
        exportQuality: '1080p',
        watermark: 'Yes',
        autoSubtitles: '✅',
        subtitleStyling: '✅',
        subtitleTranslation: '✅',
        subtitleExport: '',
        streamlabsDesktop: '',
        streamlabsWebSuite: '',
        streamlabsMobile: '',
        streamlabsConsole: '',
        talkStudio: '',
        videoEditor: '',
        crossClip: '',
        linkSpace: ''
    },
    proArray: {
        videoUploadMonth: '40 hours',
        videoLength: '2 hours',
        projects: 'unlimited',
        storage: '250GB',
        downloadCount: 'unlimited',
        filesize: '3.5GB',
        exportQuality: '1080p',
        watermark: 'No',
        autoSubtitles: '✅',
        subtitleStyling: '✅',
        subtitleTranslation: '✅',
        subtitleExport: '✅',
        streamlabsDesktop: '',
        streamlabsWebSuite: '',
        streamlabsMobile: '',
        streamlabsConsole: '',
        talkStudio: '',
        videoEditor: '',
        crossClip: '',
        linkSpace: ''
    },
    ultraArray: {
        videoUploadMonth: '40 hours',
        videoLength: '2 hours',
        projects: 'unlimited',
        storage: '250GB',
        downloadCount: 'unlimited',
        filesize: '3.5GB',
        exportQuality: '1080p',
        watermark: 'No',
        autoSubtitles: '✅',
        subtitleStyling: '✅',
        subtitleTranslation: '✅',
        subtitleExport: '✅',
        streamlabsDesktop: '✅',
        streamlabsWebSuite: '✅',
        streamlabsMobile: '✅',
        streamlabsConsole: '✅',
        talkStudio: '✅',
        videoEditor: '✅',
        crossClip: '✅',
        linkSpace: '✅'
    }
};
