import { Component } from '@angular/core';

@Component({
    selector: 'app-list-item',
    template: `
        <div class="list-item__container text">
            <ng-content select="[appListAction]:not([alignActionEnd])"></ng-content>

            <div class="list-item__lines text__label text-no-overflow">
                <ng-content></ng-content>
            </div>
            <ng-content select="[appListAction][alignActionEnd]"></ng-content>
        </div>
    `,
    styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent {
    // @Input() label: string;
    // @Input() subLabel: string;
}
