import { animate, state, style, transition, trigger } from '@angular/animations';
import { AnimationFactoryConfig, defaultAnimationFactoryConfig } from './animations.models';

export function fadeInOutAnimationFactory(animationFactoryConfig: AnimationFactoryConfig = {}) {
    const { enterState, exitState, duration } = {
        ...defaultAnimationFactoryConfig,
        ...animationFactoryConfig
    };
    return trigger('fadeIn', [
        state(enterState, style({ opacity: 1 })),
        state(exitState, style({ opacity: 0 })),
        transition(`${exitState} => ${enterState}`, [style({ opacity: 0 }), animate(duration, style({ opacity: 1 }))]),
        transition(`${enterState} => ${exitState}`, [style({ opacity: 1 }), animate(duration, style({ opacity: 0 }))])
    ]);
}
