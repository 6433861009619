import { Component } from '@angular/core';

@Component({
    selector: 'app-dialog-header',
    template: `
        <div class="dialog-header-wrapper">
            <div class="dialog-back-button-wrapper pr-2">
                <ng-content select="app-dialog-back-button"></ng-content>
            </div>
            <h2 data-spec="dialog-header-text" class="m-0 typ-h2 dialog-header-text-wrapper">
                <ng-content></ng-content>
            </h2>
            <div class="pl-2 dialog-justify-end-wrapper">
                <ng-content select="[appDialogJustifyEnd]"></ng-content>
            </div>
            <div class="dialog-close-button-wrapper pl-2">
                <ng-content select="app-dialog-close-button"></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['dialog-header.component.scss']
})
export class DialogHeaderComponent {}
