<div class="plan-interval-selectors">
    <ng-container *ngFor="let intervalButton of intervalButtons">
        <ng-container *ngIf="!userService.user || upgradeService.availableIntervals.includes(intervalButton.interval)">
            <app-button
                color="stroked"
                (btnClick)="intervalButtonClicked(intervalButton)"
                [isActive]="intervalButton.selected"
            >
                <div class="plan-interval">
                    <div class="plan-interval-details">
                        <div class="plan-interval-label">{{ intervalButton.title }}</div>
                        <div *ngIf="intervalButton.titleDescription" class="plan-interval-saving">
                            {{ intervalButton.titleDescription }}
                        </div>
                    </div>
                    <div class="plan-interval-price">
                        ${{
                            ((upgradeService.promotionCodeData$ | async)
                                ? (1 - (upgradeService.promotionCodeData$ | async).discount / 100) *
                                  intervalButton.monthlyPrice
                                : intervalButton.monthlyPrice
                            ) | number: '1.0-2'
                        }}<span class="typ-p">/mo</span>
                    </div>
                </div>
            </app-button>
        </ng-container>
    </ng-container>
</div>
