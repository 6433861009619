import { Directive, HostListener, Input } from '@angular/core';
import { DialogRef } from '../dialog-ref';

@Directive({
    selector: '[appDialogClose]'
})
export class DialogCloseDirective {
    @Input() disabled: boolean;
    @Input('appDialogClose') data;
    @HostListener('click')
    onClick() {
        if (this.disabled) return;
        this.ref.close(this.data);
    }

    constructor(private ref: DialogRef) {}
}
