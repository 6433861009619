export function getUnixTimestamp(date?: Date): number {
    if (date) {
        return Math.floor(date.getTime() / 1000);
    } else {
        return Math.floor(Date.now() / 1000);
    }
}
export function toSrtFormat(time: number): string {
    const o = new Date(0);
    const p = new Date(time * 1000);
    return new Date(p.getTime() - o.getTime()).toISOString().split('T')[1].split('Z')[0].replace('.', ',');
}
export function toVttFormat(time: number): string {
    const o = new Date(0);
    const p = new Date(time * 1000);
    return new Date(p.getTime() - o.getTime()).toISOString().split('T')[1].split('Z')[0];
}
export function roundTime(time: number): number {
    return Math.round(time * 100) / 100;
}
export function cutMillis(time: number) {
    return +toFixedTrunc(time, 3);
}
export function convertTimeToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return hours + '-' + minutes + '-' + Math.round(remainingSeconds) + '';
}

export function toFixedTrunc(x: number, n: number): string {
    const v = (typeof x === 'string' ? x : x.toString()).split('.');
    if (n <= 0) return v[0];
    let f = v[1] || '';
    if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
    while (f.length < n) f += '0';
    return `${v[0]}.${f}`;
}

export function secondsToFfmpegTimestamp(seconds: number) {
    return new Date(seconds * 1000).toISOString().substring(11, 19);
}
