import { Component, OnInit } from '@angular/core';
import { AnalyticsEvents } from '@type/shared-models/consts/analytic-events';
import { copyToClipboard } from '@type/shared-models/utils/text-utility';

@Component({
    selector: 'app-mobileerror',
    templateUrl: './mobile-error.component.html',
    styleUrls: ['./mobile-error.component.scss']
})
export class MobileErrorComponent implements OnInit {
    urlCopied = false;
    joinedWaitlist = false;

    constructor() {}

    ngOnInit() {}

    copyURL() {
        copyToClipboard('https://podcasteditor.streamlabs.com');
        this.urlCopied = true;
    }

    joinWaitlist() {
        this.joinedWaitlist = true;
    }
}
