import { User } from '@type/shared-models/user/user';

export enum MemberRole {
    OWNER = 'owner',
    EDITOR = 'editor'
}

export enum MemberStatus {
    ACTIVE = 'active',
    AWAITING_RESPONSE = 'awaiting_response'
}

export class MemberModel {
    firebaseId: string;
    name?: string;
    email?: string;
    role: MemberRole;
    status?: MemberStatus;

    static fromUserModel(
        { firebaseId, name, email }: User,
        role = MemberRole.EDITOR,
        status = MemberStatus.AWAITING_RESPONSE
    ): MemberModel {
        return { firebaseId, name, email, role, status };
    }
}

export class MemberMapModel {
    [firebaseId: string]: MemberModel;
}
