import { Resolution } from '../resolution';
import { roundTime, toFixedTrunc } from '../shared-utils/time-utils';
import { greatestCommonDivisor } from '../utils/math-utility';

export type VideoFormatOptionLabel = 'Landscape' | 'Portrait' | 'Square';
export interface VideoFormatOption {
    label: string;
    widthRatio: number;
    heightRatio: number;
    isLandscape: boolean;
    description?: string;
    aspectRatio: number;
}

export function getVideoFormatByResolution(resolution: Resolution) {
    return (
        AllVideoFormatOptions.find((format) => {
            return +toFixedTrunc(format.aspectRatio, 1) === +toFixedTrunc(Resolution.getAspectRatio(resolution), 1);
        }) || getOriginalVideoFormat(resolution)
    );
}
export function getVideoFormatByLabel(formatOptionName: VideoFormatOptionLabel) {
    return (
        AllVideoFormatOptions.find((format) => {
            return format.label === formatOptionName;
        }) || videoFormatOptions[0]
    );
}
export function getOriginalVideoFormat(resolution: Resolution): VideoFormatOption {
    const gcd = greatestCommonDivisor(resolution.width, resolution.height);
    return {
        label: 'Original',
        widthRatio: resolution.width / gcd,
        heightRatio: resolution.height / gcd,
        isLandscape: Resolution.getAspectRatio(resolution) >= 1,
        description: 'The format you uploaded',
        aspectRatio: Resolution.getAspectRatio(resolution)
    };
}
export const videoFormatOptions: Array<VideoFormatOption> = [
    {
        label: 'Landscape',
        widthRatio: 16,
        heightRatio: 9,
        isLandscape: true,
        description: 'Youtube, Vimeo',
        aspectRatio: 16 / 9
    },
    {
        label: 'Portrait',
        widthRatio: 9,
        heightRatio: 16,
        isLandscape: false,
        description: 'TikTok, Reels, Shorts',
        aspectRatio: 9 / 16
    },
    {
        label: 'Square',
        widthRatio: 1,
        heightRatio: 1,
        isLandscape: true, // okay?
        description: 'Instagram Post',
        aspectRatio: 1 / 1
    }
    // {
    //     label: 'Horizontal post',
    //     widthRatio: 5,
    //     heightRatio: 4,
    //     isLandscape: true,
    //     description: 'Facebook, Twitter',
    //     aspectRatio: 5 / 4
    // },
    // {
    //     label: 'Vertical post',
    //     widthRatio: 4,
    //     heightRatio: 5,
    //     isLandscape: false,
    //     description: 'Facebook, Twitter',
    //     aspectRatio: 4 / 5
    // },
    // {
    //     label: 'Pinterest',
    //     widthRatio: 2,
    //     heightRatio: 3,
    //     isLandscape: true,
    //     aspectRatio: 2 / 3
    // }
];
export const AllVideoFormatOptions = [
    ...videoFormatOptions,
    {
        label: 'Horizontal post',
        widthRatio: 5,
        heightRatio: 4,
        isLandscape: true,
        description: 'Facebook, Twitter',
        aspectRatio: 5 / 4
    },
    {
        label: 'Vertical post',
        widthRatio: 4,
        heightRatio: 5,
        isLandscape: false,
        description: 'Facebook, Twitter',
        aspectRatio: 4 / 5
    },
    {
        label: 'Pinterest',
        widthRatio: 2,
        heightRatio: 3,
        isLandscape: true,
        aspectRatio: 2 / 3
    }
];
