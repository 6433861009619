import { Project, TranscriptionState } from '@type/shared-models/project';
import { Transcription, TranscriptionMap } from '@type/shared-models/editor/transcription';
import { Word } from '@type/shared-models/editor/word';
import { Languages } from '@type/shared-models/language';
import { SubtitleMode } from '@type/shared-models/subtitle-mode';
import { transcriptionExample } from '@type-mvp/environments/example/example-transcription';
import { canvasElements } from '@type-mvp/environments/example/canvasElements';
import { VoiceTranslationMode } from '@type/shared-models/translation/voice-translation-mode';
import { environment } from '@type-mvp/environments/environment';
import { MediaType } from '@type/shared-models/media/media.utils';
import { Translation } from '@type/shared-models/translation/translation';

export const EXAMPLE_PROJECT_ID = 'qnG9IqMBjWhtZAk5GXjx';

const publicBasePath = environment.production
    ? 'https://storage.googleapis.com/type-mvp.appspot.com/public'
    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/public';

export const getExampleProject = () => {
    const exampleProject = new Project().init('example', '', 'Example Video');
    exampleProject.title = 'Example Project';
    exampleProject.sharingTitle = 'Example Project';
    exampleProject.thumbnailUrl = 'assets/thumbnails/example_video.png';
    exampleProject.isExample = true;
    exampleProject.mainVideoEditorConfig.canvasElements = canvasElements as any;
    exampleProject.mainVideoEditorConfig.canvasElements.media = [];
    exampleProject.mainVideoEditorConfig.canvasElements.progress = [];
    exampleProject.mainVideoEditorConfig.canvasElements.media.push({
        ID: 'example',
        startWord: 0,
        endWord: 1,
        konvaElement: '',
        mediaDuration: 1,
        mediaType: MediaType.VIDEO,
        mediaUri: transcriptionExample.videoURI,
        mediaUrl: transcriptionExample.videoFileURL,
        resolution: { width: 721, height: 406 },
        zIndex: 1,
        mediaIndex: 0,
        isMultilane: false,
        volume: 1
        // positionX: 0.5,
        // positionY: 0.5,
        // rotation: 0,
        // scale: 1,
    });

    // exampleProject.mediaType = MediaType.VIDEO;
    // exampleProject.mediaUri = transcriptionExample.videoURI;
    // exampleProject.mediaUrl = transcriptionExample.videoFileURL;
    exampleProject.mainVideoEditorConfig.canvasElements.subtitle.subtitleMode = SubtitleMode.disabled;
    exampleProject.transcriptionState = TranscriptionState.COMPLETED;
    exampleProject.transcription = new Transcription();
    exampleProject.transcription.mediaUrlsWithIndex = [{ mediaIndex: 0, mediaUrl: transcriptionExample.videoFileURL }];
    exampleProject.speakers = [{ index: 1, name: 'Alex', color: '#57CE5A', editable: false }];

    //.setVideoUri(exampleProject.mediaUri);
    exampleProject.mainVideoEditorConfig.resolution = { width: 721, height: 406 };
    exampleProject.language = Languages['en-US'].codeLong;
    exampleProject.transcription.words = transcriptionExample.transcription.map((word: Partial<Word>) => {
        const wordResult = new Word().clone(word as Word).setMediaIndex(0);
        wordResult.chunkIndex = 0;
        return wordResult;
    });
    return exampleProject;
};

export const getExampleVoiceTranslation = (
    exampleId: string,
    languageCode: string,
    voiceTranslationMode: VoiceTranslationMode
) => `public/${exampleId}/voice-translation/${languageCode}_${voiceTranslationMode.toLocaleUpperCase()}.mp3`;
