<!-- PROMOTION CODES -->
<type-promotion-input
    class="mb-2"
    [inPaymentProcess]="true"
    (promotionCodeInputSuccess)="applyPromotionCode($event)"
    (didRemovePromotionCode)="removePromotionCode()"
></type-promotion-input>

<div id="payment-request-button" class="mt-2 mb-4" #paymentRequestButton></div>

<div *ngIf="showPaymentRequestButton" class="separator wide__separator__or">
    <div class="separator__line"></div>
    <div class="separator__or wide__separator__or">Or pay with card</div>
    <div class="separator__line"></div>
</div>

<form id="subscription-form">
    <ng-container *ngIf="(isUpgradeAvailable$ | async) === false; else showUpgrade">
        <p class="credit-card-info">Credit card information</p>
        <div id="card-element" class="card-element" #cardInfo></div>
        <p *ngIf="upgradeService.cardError" id="card-errors" role="alert" class="card-error">
            {{ upgradeService.cardError }} Try again or contact us via the
            <span (click)="openWebsiteChat()" class="underline cursor-pointer">website chat</span>.
        </p>
        <div class="pb-4"></div>

        <!-- Currently the toggle is only visible for initial purchases, not upgrades -->
        <div class="flex gap-2 pt-2 pb-6 center items-center">
            <app-toggle-button [checked]="agreeToPolicies" (checkedChange)="togglePolicies($event)"></app-toggle-button>
            <p class="text-xs text-black transition-opacity  {{ agreeToPolicies ? 'opacity-100' : 'opacity-60' }}	">
                I agree that I have read and accepted the
                <a
                    href="https://streamlabs.com/terms/podcast-editor#:~:text=Automatic%20Subscription%20Renewals"
                    target="_blank"
                    class="underline cursor-pointer"
                    >Auto Renewal Policy,<br />
                </a>
                <a href="https://streamlabs.com/terms/podcast-editor" target="_blank" class="underline cursor-pointer"
                    >Terms of use
                </a>
                and
                <a href="https://streamlabs.com/privacy" target="_blank" class="underline cursor-pointer"
                    >Privacy Policy</a
                >
            </p>
        </div>
        <app-button
            color="primary"
            data-spec="upgrade-purchase-button"
            (btnClick)="submitPayment()"
            [inProgress]="upgradeService.loading"
            [disabled]="!agreeToPolicies"
            [disableTooltip]="agreeToPolicies"
            [appTooltip]="'To upgrade, agree to the Auto Renewal Policy, Terms of use and Privacy Policy first'"
        >
            <div class="flex justify-between">
                <span> Upgrade to {{ planName$ | async }} </span>
                <span>
                    ${{
                        (upgradeService.promotionCodeData$ | async)
                            ? ((1 - (upgradeService.promotionCodeData$ | async)?.discount / 100) *
                                  ((planPrice$ | async) -
                                      (1 - (upgradeService.promotionCodeData$ | async)?.discount / 100) *
                                          currentPlan.price)) /
                              100
                            : (((planPrice$ | async) - currentPlan.price) / 100 | number: '1.2-2')
                    }}
                    USD</span
                >
            </div>
        </app-button>
    </ng-container>

    <ng-template #showUpgrade>
        <div class="mt-4">
            <app-button color="primary" (btnClick)="openUpgradeConfirmation()" [inProgress]="upgradeService.loading">
                <div class="flex justify-between">
                    <span> Upgrade to {{ planName$ | async }} </span>
                    <span>
                        ${{
                            (upgradeService.promotionCodeData$ | async)
                                ? ((1 - (upgradeService.promotionCodeData$ | async)?.discount / 100) *
                                      ((planPrice$ | async) -
                                          (1 - (upgradeService.promotionCodeData$ | async)?.discount / 100) *
                                              currentPlan.price)) /
                                  100
                                : ((planPrice$ | async) - currentPlan.price) / 100
                        }}
                        USD</span
                    >
                </div>
            </app-button>
        </div>

        <p class="stripe-tag">
            ${{
                (upgradeService.promotionCodeData$ | async)
                    ? ((1 - (upgradeService.promotionCodeData$ | async)?.discount / 100) *
                          ((planPrice$ | async) -
                              (1 - (upgradeService.promotionCodeData$ | async)?.discount / 100) * currentPlan.price)) /
                      100
                    : ((planPrice$ | async) - currentPlan.price) / 100
            }}
            USD now, ${{
                (upgradeService.promotionCodeData$ | async)
                    ? ((1 - (upgradeService.promotionCodeData$ | async)?.discount / 100) * (planPrice$ | async)) / 100
                    : (planPrice$ | async) / 100
            }}
            USD starting

            <span
                ><ng-container *ngIf="(selectedInterval$ | async) === 'monthly'; else annually"
                    >next month</ng-container
                >
                <ng-template #annually>one year from now</ng-template></span
            >.
        </p>
    </ng-template>
</form>
