import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PromotionCode } from '@type/shared-models/payment/promotion-code.models';
import { UpgradeService } from '../../services/payment-dialog-upgrade.service';
import { FormControl } from '@angular/forms';
import { UserService } from '../../services/payment-dialog-user.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { PlanInterval, PlanTable, PlanType, PLAN_LEVELS } from '@type/shared-models/payment/products';
import { environment } from 'libs/payment-dialog/src/environments/environment';
import { BehaviorSubject, combineLatest, forkJoin, Subscription } from 'rxjs';
import { UpgradeSubscriptionConfirmation } from './upgrade-subscription-confirmation.component';
import { DialogService } from '@type/dialog';

@Component({
    selector: 'type-upgrade-credit-card-input',
    templateUrl: './upgrade-credit-card-input.component.html',
    styleUrls: ['./upgrade-credit-card-input.component.scss']
})
export class UpgradeCreditCardInputComponent implements OnDestroy, OnInit {
    showPaymentRequestButton = false;
    cardInfo: HTMLElement;
    paymentRequestButton: HTMLElement;
    agreeToPolicies = false;

    @ViewChild('cardInfo') set cardInfoProperty(content: ElementRef) {
        if (content) {
            this.cardInfo = content.nativeElement;
            this.initiateCardElement();
        }
    }
    @ViewChild('paymentRequestButton') set paymentRequestButtonProperty(content: ElementRef) {
        if (content) {
            this.paymentRequestButton = content.nativeElement;
            this.initPaymentRequestButton();
        }
    }

    isUpgradeAvailable$ = this.userService.user$.pipe(
        map((user) => {
            return !!user?.subscriptionState?.subscriptionId;
        })
    );

    card;

    @Input() emailCtrl: FormControl;
    @Input() paymentReason: string;

    planName$ = this.upgradeService.planDetails$.pipe(
        filter((plan) => !!plan),
        map((plan) => PLAN_LEVELS[plan.planType].label)
    );
    planPrice$ = this.upgradeService.planDetails$.pipe(map((plan) => plan?.price));
    selectedInterval$ = this.upgradeService.planInterval$;

    planDetailsSubscription: Subscription;

    currentPlan =
        this.upgradeService.planTable[this.userService.user?.subscriptionState?.plan?.type]?.[
            this.upgradeService.planDetails$.value.planInterval
        ] || this.upgradeService.planTable.trial.monthly;

    onChange = (change) => {
        const { error } = change;
        if (error) {
            this.upgradeService.cardError = error.message;
        } else {
            this.upgradeService.cardError = null;
        }
        this.cdRef.detectChanges();
    };

    constructor(
        public upgradeService: UpgradeService,
        private cdRef: ChangeDetectorRef,
        public userService: UserService,
        private dialogService: DialogService
    ) {}

    ngOnInit(): void {
        this.upgradeService.getNextPaymentDate();
    }

    ngOnDestroy() {
        if (this.card) {
            // We remove event listener here to keep memory clean
            this.card.removeEventListener('change', this.onChange);
            this.card.destroy();
        }
        this.planDetailsSubscription?.unsubscribe();
    }

    async initiateCardElement() {
        this.card = (await this.upgradeService.stripe).elements().create('card', {
            hidePostalCode: false,
            style: {
                base: {
                    color: '#969696',
                    fontFamily: '"Inter", Helvetica, sans-serif',
                    fontSize: '16px',
                    '::placeholder': {
                        color: '#969696'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            }
        });
        this.card.mount(this.cardInfo);
        this.card.addEventListener('change', this.onChange);
    }

    submitPayment() {
        if (!this.userService.user && this.emailCtrl.invalid && !this.agreeToPolicies) {
            this.emailCtrl.markAsDirty();
            this.emailCtrl.markAsTouched();
            return;
        }
        this.upgradeService.submitPayment(this.card, this.emailCtrl.value, this.paymentReason);
    }

    /**
     * Apple Pay
     * Google Pay
     */

    async initPaymentRequestButton() {
        this.planDetailsSubscription?.unsubscribe();
        this.planDetailsSubscription = combineLatest([
            this.upgradeService.planDetails$,
            this.upgradeService.promotionCodeData$
        ])
            .pipe(filter(([details]) => !!details))
            .subscribe(async ([details, promotionCodeData]) => {
                const calculatedPrice = promotionCodeData
                    ? (1 - promotionCodeData.discount / 100) * details.price
                    : details.price;
                const paymentRequest = (await this.upgradeService.stripe).paymentRequest({
                    country: 'US',
                    currency: 'usd',
                    total: {
                        label: details.planType,
                        amount: calculatedPrice
                    }
                });
                const prButton = (await this.upgradeService.stripe)
                    .elements()
                    .create('paymentRequestButton', { paymentRequest: paymentRequest });

                // Check the availability of the Payment Request API first.
                const canMakePayment = false; //await paymentRequest.canMakePayment();

                if (canMakePayment) {
                    this.showPaymentRequestButton = true;
                    prButton.mount(this.paymentRequestButton);
                    paymentRequest.on('paymentmethod', async (event) => {
                        if (!this.userService.user && this.emailCtrl.invalid) {
                            this.emailCtrl.markAsDirty();
                            this.emailCtrl.markAsTouched();
                            event.complete('invalid_payer_email');
                            return;
                        }
                        const paymentSuccessful = await this.upgradeService.createSubscription(
                            event.paymentMethod,
                            this.emailCtrl.value,
                            this.paymentReason,
                            true
                        );
                        if (paymentSuccessful) {
                            this.planDetailsSubscription?.unsubscribe();
                            event.complete('success');
                        } else {
                            this.planDetailsSubscription?.unsubscribe();
                            event.complete('fail');
                        }
                    });
                } else {
                    document.getElementById('payment-request-button').style.display = 'none';
                }
            });
    }

    openUpgradeConfirmation() {
        this.dialogService
            .openDialog(UpgradeSubscriptionConfirmation, { width: 385 })
            .afterClosed()
            .pipe(
                filter((res) => res),
                switchMap(() => this.upgradeService.upgradeSubscription(this.paymentReason))
            )
            .subscribe(() => {});
    }

    /**
     * Save the promotionCodeData
     */
    applyPromotionCode(promotionCodeResponseData: PromotionCode) {
        console.log(promotionCodeResponseData);
        this.upgradeService.promotionCodeData$.next(promotionCodeResponseData);
    }
    removePromotionCode() {
        this.upgradeService.promotionCodeData$.next(null);
        // console.log('🚀 ~ this.promotionCodeData', this.promotionCodeData);
    }

    /**
     * Website chat
     */
    openWebsiteChat() {
        let smallchatButton = document.getElementById('Smallchat');
        if (smallchatButton !== null && smallchatButton !== undefined) {
            //Show Smallchat
            smallchatButton.style.visibility = 'visible';
        } else {
            //Fallback
        }
    }

    togglePolicies(event: boolean) {
        this.agreeToPolicies = event;
    }
}
