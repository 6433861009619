import { Component } from '@angular/core';

@Component({
    selector: 'app-list',
    template: `
        <div class="list__container scroll-container--y-auto">
            <ng-content></ng-content>
            <!-- Spacer list item -->
            <div class="pb-1"></div>
        </div>
    `,
    styles: [
        `
            .list__container {
                display: grid;
                grid-gap: 0.5rem;
                grid-template-columns: minmax(1px, 1fr);
                height: 100%;
                max-height: 100%;
                align-content: start;
                padding: 0.5rem 1rem 0 0;
            }
        `
    ]
})
export class ListComponent {}
