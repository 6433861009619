"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiStyledText = void 0;
const Factory_1 = require("../Factory");
const Global_1 = require("../Global");
const Shape_1 = require("../Shape");
const Util_1 = require("../Util");
const Validators_1 = require("../Validators");
let dummyContext;
function getDummyContext() {
    if (dummyContext) {
        return dummyContext;
    }
    dummyContext = Util_1.Util.createCanvasElement().getContext('2d');
    return dummyContext;
}
function normalizeFontFamily(fontFamily) {
    return fontFamily
        .split(',')
        .map((family) => {
        family = family.trim();
        const hasSpace = family.indexOf(' ') >= 0;
        const hasQuotes = family.indexOf('"') >= 0 || family.indexOf("'") >= 0;
        if (hasSpace && !hasQuotes) {
            family = `"${family}"`;
        }
        return family;
    })
        .join(', ');
}
class MultiStyledText extends Shape_1.Shape {
    constructor(config) {
        super(config);
        this.className = 'MultiStyledText';
        this.textLines = [];
        this._fillFunc = (context) => {
            context.fillText(this.drawState.text, this.drawState.x, this.drawState.y);
        };
        this._strokeFunc = (context) => {
            context.strokeText(this.drawState.text, this.drawState.x, this.drawState.y, undefined);
        };
        for (const attr of [
            'padding',
            'wrap',
            'lineHeight',
            'letterSpacing',
            'textStyles',
            'width',
            'height',
            'text',
        ]) {
            this.on(`${attr}Change.konva`, this.computeTextParts);
        }
        this.computeTextParts();
    }
    formatFont(part) {
        return `${part.style.fontStyle} ${part.style.fontVariant} ${part.style.fontSize}px ${normalizeFontFamily(part.style.fontFamily)}`;
    }
    measurePart(part) {
        const context = getDummyContext();
        context.save();
        context.font = this.formatFont(part);
        const width = context.measureText(part.text).width;
        context.restore();
        return width;
    }
    computeTextParts() {
        this.textLines = [];
        const lines = this.text().split('\n');
        const maxWidth = this.attrs.width;
        const maxHeight = this.attrs.height;
        const hasFixedWidth = maxWidth !== 'auto' && maxWidth !== undefined;
        const hasFixedHeight = maxHeight !== 'auto' && maxHeight !== undefined;
        const shouldWrap = this.wrap() !== 'none';
        const wrapAtWord = this.wrap() !== 'char' && shouldWrap;
        const shouldAddEllipsis = this.ellipsis();
        const styles = this.textStyles();
        const ellipsis = '…';
        const additionalWidth = shouldAddEllipsis
            ? this.measurePart({
                text: ellipsis,
                style: styles[styles.length - 1],
            })
            : 0;
        const stylesByChar = Array.from(this.text()).map((char, index) => {
            return {
                char,
                style: styles.find((style) => index >= style.start &&
                    (typeof style.end === 'undefined' || style.end >= index)),
            };
        });
        const findParts = (start, end) => {
            const chars = stylesByChar.slice(start, end);
            const parts = [];
            for (const char of chars) {
                const similarGroupIndex = parts.findIndex((part) => part.style === char.style);
                if (similarGroupIndex === -1) {
                    parts.push({
                        text: char.char,
                        width: 0,
                        style: char.style,
                    });
                    continue;
                }
                parts[similarGroupIndex].text += char.char;
            }
            return parts;
        };
        const measureSubstring = (start, end) => {
            return measureParts(findParts(start, end));
        };
        const measureParts = (parts) => {
            return parts.reduce((size, part) => {
                part.width = this.measurePart(part);
                return size + part.width;
            }, 0);
        };
        const measureHeightParts = (parts) => {
            return Math.max(...parts.map((part) => {
                return part.style.fontSize * this.lineHeight();
            }));
        };
        const addLine = (width, height, parts) => {
            if (hasFixedHeight && currentHeight + height > maxHeight) {
                return;
            }
            this.textLines.push({
                width,
                parts: parts.map((part) => {
                    part.width =
                        part.width === 0 ? this.measurePart(part) : part.width;
                    return part;
                }),
                totalHeight: height,
            });
        };
        let currentHeight = 0;
        let charCount = 0;
        for (let line of lines) {
            let lineWidth = measureSubstring(charCount, charCount + line.length);
            let lineHeight;
            if (hasFixedWidth && lineWidth > maxWidth) {
                let cursor = 0;
                while (line.length > 0) {
                    var low = 0, high = line.length, match = '', matchWidth = 0;
                    while (low < high) {
                        var mid = (low + high) >>> 1, substr = line.slice(0, mid + 1), substrWidth = measureSubstring(charCount + cursor, charCount + cursor + mid + 1) + additionalWidth;
                        if (substrWidth <= maxWidth) {
                            low = mid + 1;
                            match = substr;
                            matchWidth = substrWidth;
                        }
                        else {
                            high = mid;
                        }
                    }
                    if (match) {
                        if (wrapAtWord) {
                            let wrapIndex;
                            var nextChar = line[match.length];
                            var nextIsSpaceOrDash = nextChar === ' ' || nextChar === '-';
                            if (nextIsSpaceOrDash && matchWidth <= maxWidth) {
                                wrapIndex = match.length;
                            }
                            else {
                                wrapIndex =
                                    Math.max(match.lastIndexOf(' '), match.lastIndexOf('-')) + 1;
                            }
                            if (wrapIndex > 0) {
                                low = wrapIndex;
                                match = match.slice(0, low);
                                matchWidth = measureSubstring(charCount + cursor, charCount + cursor + low);
                            }
                        }
                        const parts = findParts(charCount + cursor, charCount + cursor + low);
                        lineHeight = measureHeightParts(parts);
                        addLine(measureParts(parts), lineHeight, parts);
                        currentHeight += lineHeight;
                        if (!shouldWrap ||
                            (hasFixedHeight &&
                                currentHeight + lineHeight > maxHeight)) {
                            const lastLine = this.textLines[this.textLines.length - 1];
                            if (lastLine) {
                                if (shouldAddEllipsis) {
                                    const lastPart = lastLine.parts[lastLine.parts.length - 1];
                                    const lastPartWidthWithEllipsis = this.measurePart({
                                        ...lastPart,
                                        text: `${lastPart.text}${ellipsis}`,
                                    });
                                    const haveSpace = lastPartWidthWithEllipsis < maxWidth;
                                    if (!haveSpace) {
                                        lastPart.text = lastPart.text.slice(0, lastPart.text.length - 3);
                                    }
                                    lastLine.parts.splice(lastLine.parts.length - 1, 1);
                                    lastLine.parts.push({
                                        ...lastPart,
                                        width: lastPartWidthWithEllipsis,
                                        text: `${lastPart.text}${ellipsis}`,
                                    });
                                }
                            }
                            break;
                        }
                        line = line.slice(low);
                        cursor += low;
                        if (line.length > 0) {
                            const parts = findParts(charCount + cursor, charCount + cursor + line.length);
                            lineWidth = measureParts(parts);
                            if (lineWidth <= maxWidth) {
                                const height = measureHeightParts(parts);
                                addLine(lineWidth, height, parts);
                                currentHeight += height;
                                break;
                            }
                        }
                    }
                    else {
                        break;
                    }
                }
            }
            else {
                const parts = findParts(charCount, charCount + line.length);
                lineHeight = measureHeightParts(parts);
                addLine(lineWidth, lineHeight, parts);
            }
            if (hasFixedHeight && currentHeight + lineHeight > maxHeight) {
                break;
            }
            charCount += line.length;
            currentHeight += lineHeight;
        }
        this.linesHeight = this.textLines.reduce((size, line) => size + line.totalHeight, 0);
        this.linesWidth = Math.max(...this.textLines.map((line) => line.width, 0));
    }
    getHeight() {
        const isAuto = this.attrs.height === 'auto' || this.attrs.height === undefined;
        if (!isAuto) {
            return this.attrs.height;
        }
        return this.linesHeight + this.padding() * 2;
    }
    getWidth() {
        const isAuto = this.attrs.width === 'auto' || this.attrs.width === undefined;
        if (!isAuto) {
            return this.attrs.width;
        }
        return this.linesWidth + this.padding() * 2;
    }
    _sceneFunc(context) {
        if (this.text().length === 0) {
            return;
        }
        const totalWidth = this.getWidth();
        const totalHeight = this.getHeight();
        context.setAttr('textBaseline', 'middle');
        context.setAttr('textAlign', 'left');
        const padding = this.padding();
        let alignY = 0;
        if (this.verticalAlign() === 'middle') {
            alignY = (totalHeight - this.linesHeight - padding * 2) / 2;
        }
        else if (this.verticalAlign() === 'bottom') {
            alignY = totalHeight - this.linesHeight - padding * 2;
        }
        context.translate(padding, alignY + padding);
        let y = this.textLines[0].totalHeight / 2;
        let lineIndex = 0;
        for (const line of this.textLines) {
            const isLastLine = lineIndex === this.textLines.length - 1;
            let lineX = 0;
            let lineY = 0;
            context.save();
            if (this.align() === 'right') {
                lineX += totalWidth - line.width - padding * 2;
            }
            else if (this.align() === 'center') {
                lineX += (totalWidth - line.width - padding * 2) / 2;
            }
            for (const part of line.parts) {
                if (part.style.textDecoration.includes('underline')) {
                    context.save();
                    context.beginPath();
                    context.moveTo(lineX, y + lineY + Math.round(part.style.fontSize / 2));
                    const spacesNumber = part.text.split(' ').length - 1;
                    const oneWord = spacesNumber === 0;
                    const lineWidth = this.align() === 'justify' && isLastLine && !oneWord
                        ? totalWidth - padding * 2
                        : part.width;
                    context.lineTo(lineX + Math.round(lineWidth), y + lineY + Math.round(part.style.fontSize / 2));
                    context.lineWidth = part.style.fontSize / 15;
                    context.strokeStyle = part.style.fill;
                    context.stroke();
                    context.restore();
                }
                if (part.style.textDecoration.includes('line-through')) {
                    context.save();
                    context.beginPath();
                    context.moveTo(lineX, y + lineY);
                    const spacesNumber = part.text.split(' ').length - 1;
                    const oneWord = spacesNumber === 0;
                    const lineWidth = this.align() === 'justify' && isLastLine && !oneWord
                        ? totalWidth - padding * 2
                        : part.width;
                    context.lineTo(lineX + Math.round(lineWidth), y + lineY);
                    context.lineWidth = part.style.fontSize / 15;
                    context.strokeStyle = part.style.fill;
                    context.stroke();
                    context.restore();
                }
                this.fill(part.style.fill);
                if (part.style.stroke) {
                    this.stroke(part.style.stroke);
                }
                context.setAttr('font', this.formatFont(part));
                if (this.letterSpacing() !== 0 || this.align() === 'justify') {
                    const spacesNumber = part.text.split(' ').length - 1;
                    var array = Array.from(part.text);
                    for (let li = 0; li < array.length; li++) {
                        const letter = array[li];
                        if (letter === ' ' &&
                            lineIndex !== this.textLines.length - 1 &&
                            this.align() === 'justify') {
                            lineX +=
                                (totalWidth - padding * 2 - line.width) /
                                    spacesNumber;
                        }
                        this.drawState = {
                            x: lineX,
                            y: y + lineY,
                            text: letter,
                        };
                        context.fillStrokeShape(this);
                        lineX +=
                            this.measurePart({ ...part, text: letter }) +
                                this.letterSpacing();
                    }
                }
                else {
                    this.drawState = {
                        x: lineX,
                        y: y + lineY,
                        text: part.text,
                    };
                    context.fillStrokeShape(this);
                    lineX += part.width + this.letterSpacing();
                }
            }
            context.restore();
            if (typeof this.textLines[lineIndex + 1] !== 'undefined') {
                y += this.textLines[lineIndex + 1].totalHeight;
            }
            ++lineIndex;
        }
    }
    _hitFunc(context) {
        context.beginPath();
        context.rect(0, 0, this.getWidth(), this.getHeight());
        context.closePath();
        context.fillStrokeShape(this);
    }
    getStrokeScaleEnabled() {
        return true;
    }
}
exports.MultiStyledText = MultiStyledText;
(0, Global_1._registerNode)(MultiStyledText);
Factory_1.Factory.overWriteSetter(MultiStyledText, 'width', (0, Validators_1.getNumberOrAutoValidator)());
Factory_1.Factory.overWriteSetter(MultiStyledText, 'height', (0, Validators_1.getNumberOrAutoValidator)());
Factory_1.Factory.addGetterSetter(MultiStyledText, 'padding', 0, (0, Validators_1.getNumberValidator)());
Factory_1.Factory.addGetterSetter(MultiStyledText, 'align', 'left');
Factory_1.Factory.addGetterSetter(MultiStyledText, 'verticalAlign', 'top');
Factory_1.Factory.addGetterSetter(MultiStyledText, 'lineHeight', 1, (0, Validators_1.getNumberValidator)());
Factory_1.Factory.addGetterSetter(MultiStyledText, 'wrap', 'word');
Factory_1.Factory.addGetterSetter(MultiStyledText, 'ellipsis', false, (0, Validators_1.getBooleanValidator)());
Factory_1.Factory.addGetterSetter(MultiStyledText, 'letterSpacing', 0, (0, Validators_1.getNumberValidator)());
Factory_1.Factory.addGetterSetter(MultiStyledText, 'text', '', (0, Validators_1.getStringValidator)());
const defaultStyle = {
    start: 0,
    fill: '#000000',
    fontFamily: 'Inter',
    fontSize: 12,
    fontStyle: 'normal',
    fontVariant: 'normal',
    textDecoration: ''
};
Factory_1.Factory.addGetterSetter(MultiStyledText, 'textStyles', [defaultStyle]);
