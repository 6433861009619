export const videoExtensions = ['mp4', 'mov'];
export const audioExtensions = ['mp3', 'm4a', 'wav', 'flac'];

export enum MediaType {
    AUDIO = 'audio',
    VIDEO = 'video'
}

export class MediaModel {
    /**
     * Creates a html video and loads the metadata. If the height and width is set its a video.
     * @param file File blob input
     */
    static async getMediaTypeFromFile(file: File) {
        return this.loadMediaInHtml(window.URL.createObjectURL(file));
    }

    /**
     * Creates a html video and loads the metadata. If the height and width is set its a video.
     * @param url source url of file
     */
    static getMediaTypeFromUrl(url: string): Promise<MediaType> {
        return this.loadMediaInHtml(url);
    }

    private static async loadMediaInHtml(source): Promise<MediaType> {
        return new Promise<MediaType>(function (resolve) {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = function () {
                const result = !!(video.videoHeight && video.videoWidth);
                video.remove();
                resolve(result ? MediaType.VIDEO : MediaType.AUDIO);
            };
            video.onerror = () => {
                video.remove();
                resolve(MediaType.VIDEO); // Fallback
            };
            video.src = source;
        });
    }
}
