// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { devEnvironment, devFirebaseFunctions } from '@type/configs';

export const environment = {
    production: false,
    emulator: 'none',
    url: 'https://podcasteditor.streamlabs.dev',
    firebase: devEnvironment.firebase,
    googleTagId: '',
    fbPixelId: '1326569474171460',
    uetqId: '56342963',
    stripeKey:
        'pk_test_51OpBEKBCb5eAmPX70hljYIOnzoawFmMzWdJJT8kmzoVx6mCpgCpiZnBLhQga0Q1ts6rW0kNtUajsmrbA9pO7wram003Ve0WWRo',
    linkedInClientId: '7734sng49cipx7',
    facebookClientId: '626693441704783',
    segmentKey: 'hqlT4uVKvHQCwWdiChpWJTAkLDYxWCHg',
    appVersion: require('../../../../package.json').version,
    slid: {
        endpoint: 'https://id.streamlabs.dev'
    },
    messageId: 'm.podcasteditor',
    placeholderVideoUrl:
        'https://firebasestorage.googleapis.com/v0/b/type-mvp-development.appspot.com/o/public%2Fplaceholder_loading_media.mp4?alt=media&token=8dc03ec9-9c31-4d3d-946e-2e252d93ad37'
};

export const firebaseFunctions = devFirebaseFunctions;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
