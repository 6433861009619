import {
    AfterViewChecked,
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    Output
} from '@angular/core';

@Directive({
    selector: '[domChange]'
})
export class DomChangeDirective implements OnDestroy, AfterViewInit {
    @Output() domChange = new EventEmitter();

    private changes: MutationObserver;

    constructor(private elementRef: ElementRef<HTMLElement>) {}

    ngOnDestroy(): void {
        this.changes.disconnect();
    }

    ngAfterViewInit() {
        const element = this.elementRef.nativeElement;
        this.changes = new MutationObserver((mutations: MutationRecord[]) => {
            mutations.forEach((mutation: MutationRecord) => this.domChange.emit(mutation));
        });

        this.changes.observe(element, {
            attributes: true
        });
    }
}
