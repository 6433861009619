import { Command } from '../command';
import { Word } from '@type/shared-models/editor/word';
import { EditorService } from '../../services/editor.service';

export class WordRemoveCommand implements Command {
    private readonly selectedWordIndices: number[];
    private words: Word[];
    private editorService: EditorService;
    private cutWords: number[] = [];
    private changedNextWordCapitalization: boolean;
    private changedNextWordCapitalizationTo: 'upper' | 'lower';
    private skipUpdates = false;
    private reloadTranscript = false;

    constructor(removeWordsObject: { editorService; indicesToRemove: number[]; words: Word[] }, skipUpdates = false) {
        this.selectedWordIndices = removeWordsObject.indicesToRemove;
        this.words = removeWordsObject.words;
        this.editorService = removeWordsObject.editorService;
        this.skipUpdates = skipUpdates;
    }

    execute() {
        const indexSet = new Set(this.selectedWordIndices);
        this.words.forEach((word, index) => {
            if (indexSet.has(index) && !word.isCut) {
                word.initCutWord();
                this.cutWords.push(index);
            }
        });
        if (this.skipUpdates) {
            this.editorService.updateWords(this.selectedWordIndices, this.skipUpdates);
            return this;
        }

        this.reloadTranscript =
            this.words
                .slice(this.selectedWordIndices[0], this.selectedWordIndices[this.selectedWordIndices.length - 1] + 1)
                .filter((word) => word.hasLinebreak).length >= 5;
        this.editorService.updateChunkWordArray(this.reloadTranscript);

        this.editorService.updateWords(this.selectedWordIndices, this.skipUpdates);
        this.editorService.updateSelectedParagraphs();

        // Check if we need to update the capitalization of the next word (only if the previous one has punctuation)
        const wordBeforeCut = this.editorService.currentTranscription.getPreviousWordByIndex(
            this.cutWords[0],
            true,
            true
        );
        if (wordBeforeCut) {
            const lastCharacter = wordBeforeCut?.word?.text[wordBeforeCut?.word?.text?.length - 1];
            this.changedNextWordCapitalization = this.editorService.changeCasingFirstLetterOfNextWord(
                ['.', '?', '!', ':'].includes(lastCharacter) ? 'upper' : 'lower',
                true,
                wordBeforeCut.index,
                true
            );
            this.changedNextWordCapitalizationTo = ['.', '?', '!', ':'].includes(lastCharacter) ? 'lower' : 'upper';
        }

        return this;
    }

    undo() {
        if (!this.skipUpdates) {
            // If we changed the capitalization of the next word, change it back here
            if (this.changedNextWordCapitalization) {
                const wordBeforeCut = this.editorService.currentTranscription.getPreviousWordByIndex(
                    this.cutWords[0],
                    true,
                    true
                );
                this.editorService.changeCasingFirstLetterOfNextWord(
                    this.changedNextWordCapitalizationTo,
                    true,
                    wordBeforeCut?.index,
                    true
                );
            }
        }
        this.words
            .filter((word, index) => this.cutWords.includes(index))
            .forEach((word: Word) => {
                word.isCut = false;
            });
        if (this.skipUpdates) {
            this.editorService.updateWords(this.selectedWordIndices, this.skipUpdates);
            return this;
        }
        this.editorService.updateChunkWordArray(this.reloadTranscript);
        this.editorService.updateWords(this.selectedWordIndices, this.skipUpdates);
        this.editorService.updateSelectedParagraphs();
    }
}
