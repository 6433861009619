import { NgModule } from '@angular/core';
import { PaymentDialogComponent } from './payment-dialog.component';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { UpgradeCreditCardInputComponent } from './components/upgrade-credit-card-input/upgrade-credit-card-input.component';
import { UpgradeFeaturesComponent } from './components/upgrade-features/upgrade-features.component';
import { UpgradeIntervalSelectorComponent } from './components/upgrade-interval-selector/upgrade-interval-selector.component';
import { UpgradePlanSelectorComponent } from './components/upgrade-plan-selector/upgrade-plan-selector.component';
import { UpgradeComponent } from './components/upgrade.component';
import { PromotionInputComponent } from './components/promotion-code-input/promotion-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { UpgradeService } from './services/payment-dialog-upgrade.service';
import { UserService } from './services/payment-dialog-user.service';
import { UiModule } from '@type/ui';
import { DialogModule } from '@type/dialog';
import { PaymentDialogService } from './payment-dialog.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoaderModule } from '@type/ui/loader';
import { RouterModule } from '@angular/router';
import { TabsModule } from '@type/ui/tabs';
import { UpgradeSubscriptionConfirmation } from './components/upgrade-credit-card-input/upgrade-subscription-confirmation.component';
import { SlidCheckoutComponent } from './components/slid-checkout/slid-checkout.component';
import { SwitchUserComponent } from './components/switch-user/switch-user.component';
const exportItems = [
    PaymentDialogComponent,
    UpgradePlanSelectorComponent,
    UpgradeSubscriptionConfirmation,
    SlidCheckoutComponent
];

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        FormsModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        ReactiveFormsModule,
        UiModule,
        DialogModule,
        AngularSvgIconModule,
        LoaderModule,
        RouterModule,
        TabsModule
    ],
    declarations: [
        UpgradeCreditCardInputComponent,
        UpgradeFeaturesComponent,
        UpgradeIntervalSelectorComponent,
        UpgradeComponent,
        PromotionInputComponent,
        UpgradeSubscriptionConfirmation,
        SwitchUserComponent,
        ...exportItems
    ],
    exports: [...exportItems],
    providers: [UserService, PaymentDialogService]
})
export class PaymentDialogModule {}
