<div *ngIf="showToggle && (currentPlanIsTrial$ | async)" class="w-full flex text-center">
    <p class="mr-2 grow w-full text-black opacity-100 self-center text-right">Monthly</p>

    <div class="grow-0">
        <app-toggle-button
            [checked]="(selectedInterval$ | async) === 'annually'"
            (checkedChange)="togglePlanInterval($event)"
        >
        </app-toggle-button>
    </div>

    <p class="ml-2 grow w-full text-black opacity-100 self-center text-left">Annually</p>
</div>

<div class="plans" [class.all-plans]="showAllPlans">
    <div *ngFor="let plan of availablePlans" class="plan-container plan-{{ plan.planType }}">
        <h2 class="flex justify-between items-center typ-h2">
            <span>{{ plan.label }}</span>
            <span class="text-4xl"> {{ plan.icon }}</span>
        </h2>
        <p class="typ-p mb-2 text-black">
            {{ plan.description }}
        </p>
        <div>
            <span class="font-bold text-black text-3xl"
                >${{
                    ((selectedInterval$ | async) === 'annually' ? plan.yearlyPrice / 100 : plan.monthlyPrice / 100)
                        | number: '1.0-2'
                }}</span
            >
            <span class="text-black3">/mo</span>
            <span
                class="text-black3 ml-1"
                *ngIf="(selectedInterval$ | async) === 'annually' && plan.planType !== planType.TRIAL"
                >(${{ (plan.yearlyPrice / 100) * 12 }}/yr)</span
            >
        </div>
        <ng-container *ngIf="(currentPlanType$ | async) !== plan.planType; else hasPlan">
            <ng-container *ngIf="isOrganizationMemberOnly$ | async; else isAllowedToBuy">
                <app-button [disabled]="true" appTooltip="Contact your team owner to upgrade your plan" color="primary"
                    >{{ plan.planType === planType.TRIAL ? 'Free plan' : 'Buy ' + plan.label }}
                </app-button>
            </ng-container>
            <ng-template #isAllowedToBuy>
                <app-button
                    *ngIf="plan.planType !== planType.ULTRA; else ultra"
                    #buyButton
                    (btnClick)="selectPlan(plan)"
                    [disabled]="
                        plan.planType === (currentPlanType$ | async) ||
                        (((currentPlanType$ | async) === planType.PRO_TEAM ||
                            (currentPlanType$ | async) === planType.BUSINESS) &&
                            (plan.planType === planType.STARTER ||
                                plan.planType === planType.STARTER_BASIC ||
                                plan.planType === planType.PRO ||
                                plan.planType === planType.PRO_TEAM ||
                                plan.planType === planType.TRIAL)) ||
                        ((currentPlanType$ | async) === planType.BASIC &&
                            (plan.planType === planType.STARTER_BASIC || plan.planType === planType.TRIAL)) ||
                        ((currentPlanType$ | async) === planType.PRO &&
                            (plan.planType === planType.STARTER ||
                                plan.planType === planType.STARTER_BASIC ||
                                plan.planType === planType.TRIAL)) ||
                        ((currentPlanType$ | async) === planType.STARTER_BASIC && plan.planType === planType.TRIAL) ||
                        ((currentPlanType$ | async) === planType.ULTRA &&
                            (plan.planType === planType.PRO || plan.planType === planType.TRIAL))
                    "
                    [disableTooltip]="!buyButton.disabled"
                    appTooltip="To downgrade to {{ plan.label }},<br>cancel your current subscription in your profile"
                    color="primary"
                    >{{ plan.planType === planType.TRIAL ? 'Free plan' : 'Buy ' + plan.label }}
                </app-button>
                <ng-template #ultra>
                    <type-slid-checkout
                        [checkoutAction]="'checkout'"
                        [planInterval]="selectedInterval$ | async"
                        [disabled]="(currentPlanType$ | async) === planType.PRO"
                    >
                        Buy {{ plan.label }}
                    </type-slid-checkout>
                </ng-template>
            </ng-template>
        </ng-container>
        <ng-template #hasPlan>
            <app-button [disabled]="true" appTooltip="This is your current subscription" color="primary"
                >Current plan
            </app-button>
        </ng-template>
        <div class="plan-features text-black8 mb-auto -mt-4">
            <div
                *ngFor="let feature of plan.featureList"
                class="flex gap-1 items-center opacity-100 text-xs text-black8"
            >
                <svg-icon class="text-black3" src="/assets/ic/16/check.svg"></svg-icon>
                {{ feature }}
            </div>
            <div *ngIf="plan.planType === planType.ULTRA">
                <p class="font-caveat text-xl text-black mt-6">
                    Includes <span class="font-caveat text-xl">Podcast Editor Pro</span>
                </p>
                <img class="mt-2 mb-12" alt="" src="assets/images/ultra-brand-icons.png" />
            </div>
        </div>
    </div>
</div>
