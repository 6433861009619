import { NgModule } from '@angular/core';
import { ButtonAnchorComponent, ButtonComponent } from './button.component';
import { CommonModule } from '@angular/common';
import { LoaderModule } from '../loader/loader.module';
import { AngularSvgIconModule } from 'angular-svg-icon';

const exportItems = [ButtonComponent, ButtonAnchorComponent];

@NgModule({
    imports: [CommonModule, LoaderModule, AngularSvgIconModule],
    exports: exportItems,
    declarations: exportItems
})
export class ButtonModule {}
