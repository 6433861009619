<div id="payment-request-button" class="mt-4 mb-4" #paymentRequestButton></div>

<div *ngIf="showPaymentRequestButton" class="separator wide__separator__or">
    <div class="separator__line"></div>
    <div class="separator__or wide__separator__or">Or pay with card</div>
    <div class="separator__line"></div>
</div>

<form id="subscription-form">
    <p class="credit-card-info">Credit card information</p>
    <div id="card-element" class="card-element" #cardInfo></div>
    <p *ngIf="upgradeService.cardError" id="card-errors" role="alert" class="card-error">
        {{ upgradeService.cardError }}
    </p>
    <!--    <p *ngIf="!upgradeService.cardError" role="alert" class="card-pricing">-->
    <!--        Total: 20$-->
    <!--    </p>-->

    <!-- PROMOTION CODES -->
    <!--    <app-promotion-input-->
    <!--        [inPaymentProcess]="true"-->
    <!--        (promotionCodeInputSuccess)="applyPromotionCode($event)"-->
    <!--        (didRemovePromotionCode)="removePromotionCode()"-->
    <!--    ></app-promotion-input>-->
    <div class="pb-4"></div>
    <app-button color="primary" (click)="submitPayment()" data-spec="upgrade-purchase-button">
        <div class="flex items-center justify-between">
            Upgrade to {{ selectedPlanData.title }}
            <span>
                ${{
                    (selectedInterval === 'monthly' ? selectedPlanData.monthlyPrice : selectedPlanData.yearlyPrice) /
                        100 | number: '1.2-2'
                }} USD
            </span>
        </div>
    </app-button>
    <p class="stripe-tag">Secured with Stripe</p>
</form>
