import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, Provider } from '@angular/core';
import { AppComponent } from './app.component';
import { Router, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { AngularFireAuth, AngularFireAuthModule, USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import {
    AngularFirestoreModule,
    SETTINGS,
    USE_EMULATOR as USE_FIRESTORE_EMULATOR
} from '@angular/fire/compat/firestore';
import { AngularFireStorageModule, USE_EMULATOR as USE_STORAGE_EMULATOR } from '@angular/fire/compat/storage';
import {
    AngularFireFunctionsModule,
    ORIGIN,
    USE_EMULATOR as USE_FUNCTIONS_EMULATOR
} from '@angular/fire/compat/functions';
import { OverlayModule } from '@angular/cdk/overlay';
import { UiModule } from '@type/ui';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule } from '@angular/common/http';
import { AngularResizeEventModule } from 'angular-resize-event';
import { DialogModule } from '@type/dialog';
import { PaymentDialogModule } from '@type/payment-dialog/payment-dialog.module';
import { LoaderModule } from '@type/ui/loader';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UiScrollModule } from 'ngx-ui-scroll';
import { appRoutes } from './app.routes';
import { TypFormsModule } from './forms/typ-forms.module';
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from './guards/guest.guard';
import { MobileUpgradeDialogModule } from './mobile-upgrade-dialog/mobile-upgrade-dialog.module';
import { EmbedComponent } from './pages/embed/embed.component';
import { CalendlyComponent } from './partials/calendly/calendly.component';
import { CameraMicrophoneAccessComponent } from './partials/camera-microphone-access/camera-microphone-access.component';
import { ContactComponent } from './partials/contact/contact.component';
import { FeedbackComponent } from './partials/feedback/feedback.component';
import { LanguageSelectionModule } from './partials/language-selection/language-selection.module';
import { MobileErrorComponent } from './partials/mobile-error/mobile-error.component';
import { NavbarComponent } from './partials/navbar/navbar.component';
import { ShortcutListComponent } from './partials/shortcut-list/shortcut-list.component';
import { UsageLimitationModule } from './partials/usage-limitation/usage-limitation.module';
import { UserConfirmationComponent } from './partials/user-confirmation/user-confirmation.component';
import { PausePipe } from './pipes/pause.pipe';
import { PurePipe } from './pipes/pure.pipe';
import { SafeSpaceComponent } from './safe-space.component';

import { ToastModule } from './toast/toast.module';
import { TypeFormModule } from './type-form/type-form.module';
import { UserNameDialogComponent } from './user-name-dialog/user-name-dialog.component';
import { HelpMenuComponent } from './partials/help-menu/help-menu.component';
// import * as Sentry from '@sentry/angular';
// import { BrowserTracing } from '@sentry/tracing';
import { SubscriptionDialogsModule } from './partials/subscription-dialogs/subscription-dialogs.module';
import { SegmentModule } from 'ngx-segment-analytics';
import { LinkComponent } from './pages/link/link.component';
import { SlidStorageComponent } from './pages/slid-storage/slid-storage.component';
import { OpenOnDesktopModule } from './shared/open-on-desktop/open-on-desktop.module';

const providers: Provider[] = [];

if (environment.emulator === 'full') {
    providers.push(
        { provide: SETTINGS, useValue: { experimentalAutoDetectLongPolling: true } },
        { provide: USE_AUTH_EMULATOR, useValue: ['http://localhost:9099', 9099] },
        { provide: USE_FIRESTORE_EMULATOR, useValue: ['localhost', 8080] },
        { provide: USE_FUNCTIONS_EMULATOR, useValue: ['localhost', 5001] },
        { provide: USE_STORAGE_EMULATOR, useValue: ['127.0.0.1', 9199] }
    );
} else if (environment.emulator === 'functions') {
    providers.push(
        { provide: ORIGIN, useValue: 'http://localhost:5001' },
        { provide: USE_FUNCTIONS_EMULATOR, useValue: ['localhost', 5001] }
    );
}

// // if (environment.production) {
// Sentry.init({
//     release: 'type@' + environment.appVersion,
//     environment: environment.production ? 'production' : 'development',
//     dsn: 'https://1b9a3f9bfdc047999ed0c7395faae056@o1295126.ingest.sentry.io/6520224',
//     integrations: [
//         new BrowserTracing({
//             tracingOrigins: ['localhost', 'https://podcasteditor.streamlabs.com ', 'https://podcasteditor.streamlabs.dev/'],
//             routingInstrumentation: Sentry.routingInstrumentation
//         })
//     ],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     // testchange
//     tracesSampleRate: 0.2
// });

// providers.push(
//     {
//         provide: ErrorHandler,
//         useValue: Sentry.createErrorHandler({
//             showDialog: false
//         })
//     },
//     {
//         provide: Sentry.TraceService,
//         deps: [Router]
//     },
//     {
//         provide: APP_INITIALIZER,
//         useFactory: () => () => {},
//         deps: [Sentry.TraceService],
//         multi: true
//     }
// );
// }
@NgModule({
    declarations: [
        AppComponent,
        UserNameDialogComponent,
        NavbarComponent,
        FeedbackComponent,
        ContactComponent,
        MobileErrorComponent,
        CalendlyComponent,
        EmbedComponent,
        // Pipes
        PausePipe,
        PurePipe,
        ShortcutListComponent,
        CameraMicrophoneAccessComponent,
        SafeSpaceComponent,
        UserConfirmationComponent,
        HelpMenuComponent,
        LinkComponent,
        SlidStorageComponent
    ],
    imports: [
        SegmentModule.forRoot({
            apiKey: environment.segmentKey,
            debug: false,
            loadOnInitialization: false
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireFunctionsModule,
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
        OverlayModule, // OverlayModule must be imported into app's root module to
        TypFormsModule,
        UiModule,
        AngularResizeEventModule,
        ToastModule,
        UiScrollModule,
        UsageLimitationModule,
        LanguageSelectionModule,
        PaymentDialogModule,
        DialogModule,
        MobileUpgradeDialogModule,
        TypeFormModule,
        LoaderModule,
        OpenOnDesktopModule,
        SubscriptionDialogsModule
    ],
    providers: [...providers, AuthGuard, GuestGuard, DeviceDetectorService],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
