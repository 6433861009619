export interface Speaker {
    index: number;
    name: string;
    color?: string;
    editable?: boolean;
}

export const defaultSpeakers: Speaker[] = [
    { index: 1, name: 'Speaker A', color: '#46B2EF', editable: false },
    { index: 2, name: 'Speaker B', color: '#FCA552', editable: false },
    { index: 3, name: 'Speaker C', color: '#FB7063', editable: false },
    { index: 4, name: 'Speaker D', color: '#57CE5A', editable: false },
    { index: 5, name: 'Speaker E', color: '#9985F8', editable: false },
    { index: 6, name: 'Speaker F', color: '#FA617E', editable: false },
    { index: 7, name: 'Speaker G', color: '#44B2A3', editable: false },
    { index: 8, name: 'Speaker H', color: '#3A4A97', editable: false },
    { index: 9, name: 'Speaker I', color: '#E25D99', editable: false },
    { index: 10, name: 'Speaker J', color: '#A1CC57', editable: false },
    { index: 11, name: 'Speaker K', color: '#F974BC', editable: false },
    { index: 12, name: 'Speaker L', color: '#5AB5C9', editable: false },
    { index: 13, name: 'Speaker M', color: '#EF8D46', editable: false },
    { index: 14, name: 'Speaker N', color: '#8B57CE', editable: false },
    { index: 15, name: 'Speaker O', color: '#51AB63', editable: false },
    { index: 16, name: 'Speaker P', color: '#F44B80', editable: false },
    { index: 17, name: 'Speaker Q', color: '#C554F8', editable: false },
    { index: 18, name: 'Speaker R', color: '#5EAD57', editable: false },
    { index: 19, name: 'Speaker S', color: '#EF6946', editable: false },
    { index: 20, name: 'Speaker T', color: '#4F80E2', editable: false },
    { index: 21, name: 'Speaker U', color: '#9EFB63', editable: false },
    { index: 22, name: 'Speaker V', color: '#B246EF', editable: false },
    { index: 23, name: 'Speaker W', color: '#F89B55', editable: false },
    { index: 24, name: 'Speaker X', color: '#65E25C', editable: false },
    { index: 25, name: 'Speaker Y', color: '#FAB057', editable: false },
    { index: 26, name: 'Speaker Z', color: '#8E63FA', editable: false },
    { index: 27, name: 'Speaker AA', color: '#57CE5A', editable: false },
    { index: 28, name: 'Speaker AB', color: '#9985F8', editable: false },
    { index: 29, name: 'Speaker AC', color: '#FCA552', editable: false },
    { index: 30, name: 'Speaker AD', color: '#46B2EF', editable: false },
    { index: 31, name: 'Speaker AE', color: '#FB7063', editable: false },
    { index: 32, name: 'Speaker AF', color: '#44B2A3', editable: false },
    { index: 33, name: 'Speaker AG', color: '#FA617E', editable: false },
    { index: 34, name: 'Speaker AH', color: '#3A4A97', editable: false },
    { index: 35, name: 'Speaker AI', color: '#E25D99', editable: false },
    { index: 36, name: 'Speaker AJ', color: '#A1CC57', editable: false },
    { index: 37, name: 'Speaker AK', color: '#F974BC', editable: false },
    { index: 38, name: 'Speaker AL', color: '#5AB5C9', editable: false },
    { index: 39, name: 'Speaker AM', color: '#EF8D46', editable: false },
    { index: 40, name: 'Speaker AN', color: '#8B57CE', editable: false }
    // { index: 15, name: 'Speaker AO', color: '#51AB63', editable: false },
    // { index: 16, name: 'Speaker AP', color: '#F44B80', editable: false },
    // { index: 17, name: 'Speaker AQ', color: '#C554F8', editable: false },
    // { index: 18, name: 'Speaker AR', color: '#5EAD57', editable: false },
    // { index: 19, name: 'Speaker AS', color: '#EF6946', editable: false },
    // { index: 20, name: 'Speaker AT', color: '#4F80E2', editable: false },
    // { index: 21, name: 'Speaker AU', color: '#9EFB63', editable: false },
    // { index: 22, name: 'Speaker AV', color: '#B246EF', editable: false },
    // { index: 23, name: 'Speaker AW', color: '#F89B55', editable: false },
    // { index: 24, name: 'Speaker AX', color: '#65E25C', editable: false },
    // { index: 25, name: 'Speaker AY', color: '#FAB057', editable: false },
    // { index: 26, name: 'Speaker AZ', color: '#8E63FA', editable: false },
];
