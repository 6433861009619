import { Component } from '@angular/core';
import { DialogRef } from '../dialog-ref';
import { fadeInOutAnimationFactory } from '@type/shared/animations';

class TooltipDataModel {
    keys: string[];
    description: string;
    message: string;
}

@Component({
    template: `
        <div class="tooltip" @fadeIn>
            <div class="tooltip-text">
                <span [innerHTML]="data.message"></span>
                <div class="mt-1 tooltip-description" [class.mb-1]="data.keys" *ngIf="data.description">
                    {{ data.description }}
                </div>
                <div class="inline-block" *ngIf="data.keys">
                    <span class="key" *ngFor="let key of data.keys">{{ key }}</span>
                </div>
            </div>
        </div>
    `,
    styleUrls: [`tooltip.component.scss`],
    animations: [fadeInOutAnimationFactory({ duration: 150 })]
})
export class TooltipComponent {
    data: TooltipDataModel;

    constructor(ref: DialogRef) {
        this.data = ref.config.data;
    }
}
