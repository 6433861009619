import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'Pure'
})
export class PurePipe implements PipeTransform {
    transform(value: number, args?: any): number {
        console.log('PurePipe changed');

        return value + 1;
    }
}
