import { NgModule } from '@angular/core';
import { LoaderComponent } from './loader.component';
import { CommonModule } from '@angular/common';

const exportItems = [LoaderComponent];

@NgModule({
    declarations: [...exportItems],
    imports: [CommonModule],
    exports: [...exportItems]
})
export class LoaderModule {}
