import { VideoRenderingClip } from '../rendering/video-rendering-clip';
import { roundTime } from '../shared-utils/time-utils';
import { defaultSpeakers } from './speaker';
import { VideoClip } from './video-clip';

export class VideoClips {
    startVideoClip: VideoClip = null;

    get length(): number {
        let currentVideoClip = this.startVideoClip;
        let clipCount = 0;
        while (currentVideoClip) {
            clipCount++;
            currentVideoClip = currentVideoClip.next;
        }
        return clipCount;
    }

    get totalLength(): number {
        let totalLength = 0;
        let currentClip = this.startVideoClip;
        while (currentClip) {
            totalLength += currentClip.endTimeInEdit - currentClip.startTimeInEdit;
            currentClip = currentClip.next;
        }
        return totalLength;
    }

    get lastVideoClip(): VideoClip {
        let currentClip = this.startVideoClip;
        while (currentClip?.next != null) {
            currentClip = currentClip.next;
        }
        return currentClip;
    }

    append(videoClip: VideoClip) {
        const lastVideoClip = this.lastVideoClip;
        if (lastVideoClip) {
            lastVideoClip.next = videoClip;
        } else {
            this.startVideoClip = videoClip;
        }
    }

    forEach(callback: (videoClip: VideoClip) => void) {
        let currentVideoClip = this.startVideoClip;
        while (currentVideoClip) {
            callback(currentVideoClip);
            currentVideoClip = currentVideoClip.next;
        }
    }

    splitBySpeaker() {
        this.forEach((videoClip) => {
            const speakerChangeInIndexRange = videoClip.speakerChanges?.filter(
                (speakerChange) =>
                    speakerChange.wordIndex > videoClip.startIndex && speakerChange.wordIndex <= videoClip.endIndex
            );
            // speakerChange.wordIndex > videoClip.startIndex && speakerChange.wordIndex <= videoClip.endIndex
            // when a speakerChange happens the first word of paragraph is a new speaker. So the speakerchange IS withing the IndexRange when selecting a whole speaker (paragraph), but needs to be ignored because it is the 'inital' speaker
            if (speakerChangeInIndexRange?.length) {
                // videoClip.endIndex = speakerChangeInIndexRange[0].wordIndex - 1;
                let previousClip: VideoClip = videoClip;

                if (speakerChangeInIndexRange.length >= 1) {
                    for (const [index, speakerChange] of Object.entries(speakerChangeInIndexRange)) {
                        const newLastClip = videoClip.clone();
                        newLastClip.speakerChanges = null;
                        newLastClip.startIndex = speakerChange.wordIndex;
                        newLastClip.endIndex = previousClip.endIndex;
                        newLastClip.next = previousClip.next;
                        previousClip.endIndex = newLastClip.startIndex - 1;

                        previousClip.next = newLastClip;

                        previousClip = newLastClip;
                    }
                }
            }
        });
    }
    getVideoRenderingClips(splitBySpeaker = false): VideoRenderingClip[] {
        if (splitBySpeaker) {
            this.splitBySpeaker();
        }
        const clips: VideoRenderingClip[] = [];
        let nextStartTime = 0;
        this.forEach((videoClip) => {
            clips.push({
                asset: {
                    trim: roundTime(videoClip.startTimeInOriginal),
                    type: 'single',
                    volume: 1
                },
                length: roundTime(videoClip.endTimeInEdit - videoClip.startTimeInEdit),
                start: roundTime(nextStartTime),
                mediaIndex: videoClip.mediaIndex,
                speakerIndex: videoClip.speakerIndex
            });
            nextStartTime += videoClip.endTimeInEdit - videoClip.startTimeInEdit;
        });
        return clips;
    }
}
