import { Injectable } from '@angular/core';
import { TypeFormComponent } from './type-form.component';
import { DialogService } from '@type/dialog';

@Injectable()
export class TypeFormService {
    constructor(private dialogService: DialogService) {}

    open(surveyId: string) {
        return this.dialogService.openFullPageDialog(TypeFormComponent, { data: { surveyId } });
    }
}
