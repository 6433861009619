<app-dialog-header>
    Confirm account
    <app-dialog-close-button appDialogClose></app-dialog-close-button>
</app-dialog-header>
<div class="leading-6">
    <div>
        We've sent a you a verification link.<br />
        Please check your inbox at <b>{{ (user$ | async)?.email }}</b>
    </div>
    <div class="mt-4">
        <ng-container *ngIf="sendStep === 0"
            >Didn't get the email?
            <span class="border-b cursor-pointer" (click)="resendEmail()">Resend email</span></ng-container
        >
        <ng-container *ngIf="sendStep === 1">Email sent. Please check your inbox and spam folder.</ng-container>
        <ng-container *ngIf="sendStep === 2">Still no email? Write us in the website chat.</ng-container>
    </div>
</div>
