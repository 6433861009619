import { EditorService } from '../../services/editor.service';
import { Command } from '../command';

export class RemoveSubtitleBreakCommand implements Command {
    private readonly wordIndex: number;
    private editorService: EditorService;
    private wordOldSubtitleState: boolean;

    constructor(removeSubtitleBreakObject: { editorService; index: number }) {
        this.editorService = removeSubtitleBreakObject.editorService;
        this.wordIndex = removeSubtitleBreakObject.index;
    }

    execute() {
        this.editorService.jumpToWordIfNotInside({ startWord: this.wordIndex, endWord: this.wordIndex });
        this.wordOldSubtitleState = this.editorService.currentTranscription.words[this.wordIndex].hasSubtitlebreak;
        this.editorService.currentTranscription.words[this.wordIndex].hasSubtitlebreak = false;
        this.editorService.updateWords([this.wordIndex]);
        return this;
    }

    undo() {
        this.editorService.jumpToWordIfNotInside({ startWord: this.wordIndex, endWord: this.wordIndex });
        this.editorService.currentTranscription.words[this.wordIndex].hasSubtitlebreak = this.wordOldSubtitleState;
        this.editorService.updateWords([this.wordIndex]);
    }
}
