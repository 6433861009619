<div class="promotion-code mt-4">
    <ng-container *ngIf="!promotionCodeValid; else isValid">
        <ng-container *ngIf="!inPaymentProcess">
            <!-- <input [(ngModel)]="promotionCodeInputValue" (ngModelChange)="promotionCodeInputChanged()"
          (focusout)="deactivatePromotionCodeInput()" (keyup.enter)="$event.preventDefault(); applyPromotionCode()"
          type="text" placeholder="Add promotion code" [ngClass]="{
              'promotion-code__field__input--error': promotionCodeErrorMessage,
              'promotion-code__field__input--success': promotionCodeValid
            }" class="button promotion-code__field__input" #promotionCodeInput /> -->

            <div class="default-input">
                <label class="default-input__label">Promotion Code</label>
                <div class="default-input__input-field promotion-code__field">
                    <input
                        [(ngModel)]="promotionCodeInputValue"
                        (ngModelChange)="promotionCodeInputChanged()"
                        (focusout)="deactivatePromotionCodeInput()"
                        (keyup.enter)="$event.preventDefault(); applyPromotionCode()"
                        type="text"
                        placeholder="Enter your code"
                        class="default-input__input-field__input"
                        [ngClass]="{
                            'default-input__input-field__input--error': promotionCodeErrorMessage,
                            'default-input__input-field__input--success': promotionCodeValid
                        }"
                        #promotionCodeInput
                    />

                    <button
                        class="button-small button-small__third promotion-code__field__button"
                        *ngIf="!requestPending"
                        [disabled]="!applyButtonVisible"
                        (click)="applyPromotionCode()"
                    >
                        Apply
                    </button>

                    <button
                        class="button-small button-small__third promotion-code__field__button promotion-code__field__button--loading"
                        *ngIf="requestPending"
                    >
                        Loading...
                    </button>
                </div>
                <p class="default-input__message default-input__message--success" *ngIf="promotionCodeValid">
                    Code activated
                </p>
                <p *ngIf="promotionCodeErrorMessage" class="default-input__message default-input__message--error">
                    {{ promotionCodeErrorMessage }}
                </p>
            </div>
            <div>
                <!-- <button class="button-small button-small__third promotion-code__field__button" disabled *ngIf="!applyButtonVisible && !requestPending" (click)="applyPromotionCode()">Apply</button> -->
            </div>

            <!-- <p *ngIf="promotionCodeErrorMessage" class="promotion-code__error-message">{{promotionCodeErrorMessage}}</p> -->
        </ng-container>
    </ng-container>
    <ng-template #isValid>
        <label class="default-input__label promotion-code__label">Promotion Code</label>
        <div class="promotion-code__field">
            <div class="promotion-code__field__added">✅ {{ validPromotionCode }}</div>
            <button
                class="button-small button-small__third promotion-code__field__input__button promotion-code__field__button--remove"
                (click)="removePromotionCode()"
            >
                Remove Coupon
            </button>
        </div>
    </ng-template>
</div>
