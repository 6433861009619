import { MetaDefinition } from '@angular/platform-browser';

export const signupMetaTags: MetaDefinition[] = [
    {
        name: 'title',
        content: 'Welcome to Streamlabs Podcast Editor - Start editing your video.'
    },

    {
        name: 'description',
        content:
            'Sign up to Streamlabs Podcast Editor and start editing your video by just editing the transcribed text online in your browser.'
    },
    {
        name: 'keywords',
        content: 'video, editing, text, transcribe, subtitles'
    },
    { name: 'robots', content: 'index, follow' },
    {
        name: 'googlebot',
        content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
    },
    {
        name: 'bingbot',
        content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
    },
    { property: 'og:locale', content: 'en_US' },
    { property: 'og:type', content: 'website' },
    {
        property: 'og:title',
        content: 'Welcome to Streamlabs Podcast Editor - Start editing your video.'
    },
    {
        property: 'og:description',
        content:
            'Sign up to Streamlabs Podcast Editor and start editing your video by just editing the transcribed text online in your browser.'
    },
    {
        property: 'og:url',
        content: 'https://podcasteditor.streamlabs.com/signup'
    },

    {
        property: 'og:site_name',
        content: 'Sign Up | Streamlabs Podcast Editor'
    },
    {
        property: 'og:image',
        content:
            'https://uploads-ssl.webflow.com/63da7cb823579527afb5c86b/64007855447e9f5f77e1cf18_Streamlabs-Podcast-Editor.jpg'
    },
    {
        property: 'og:image:secure_url',
        content:
            'https://uploads-ssl.webflow.com/63da7cb823579527afb5c86b/64007855447e9f5f77e1cf18_Streamlabs-Podcast-Editor.jpg'
    },
    { property: 'og:image:width', content: '1200' },
    { property: 'og:image:height', content: '630' },
    {
        property: 'og:image:alt',
        content: 'Streamlabs Podcast Editor App'
    },
    { property: 'og:image:type', content: 'image/jpg' },
    {
        name: 'twitter:card',
        content: 'summary_large_image'
    },
    {
        name: 'twitter:title',
        content: 'Welcome to Streamlabs Podcast Editor - Start editing your video.'
    },
    {
        name: 'twitter:description',
        content:
            'Signup to Streamlabs Podcast Editor and start editing your video - ' +
            'You can use Streamlabs Podcast Editor for free. Edit, add subtitles or just transcribe your video text-based.'
    },
    { name: 'twitter:site', content: '@editwithtype' },
    { name: 'twitter:creator', content: '@editwithtype' },
    {
        name: 'twitter:image',
        content:
            'https://uploads-ssl.webflow.com/63da7cb823579527afb5c86b/64007855447e9f5f77e1cf18_Streamlabs-Podcast-Editor.jpg'
    }
];
