import { CanvasElementType } from '@type/shared-models/canvas-elements';
import { Word } from '@type/shared-models/editor/word';
import { MediaType } from '@type/shared-models/media/media.utils';
import { BehaviorSubject } from 'rxjs';

import { StartEndConfig } from '@type/shared-models/models/canvas/canvas-element';
import { MainVideoConfig, VideoElement } from './video-element';

export class MultilaneVideoElement extends VideoElement {
    isPlaying: boolean;
    isActive: boolean;
    mediaType: MediaType;

    constructor(
        elementsInitialized: BehaviorSubject<{
            [uuid: string]: boolean;
        }> = null,
        serializedElement: string = null,
        ID: string = null,
        startEndConf: StartEndConfig,
        zIndex: number,
        mainVideoConfig: MainVideoConfig = null,
        templateId: string = null
    ) {
        super(elementsInitialized, serializedElement, ID, startEndConf, zIndex, mainVideoConfig, null);
        this.type = CanvasElementType.MULTILANEMEDIA;
        this.templateId = templateId;
        this.mediaType = mainVideoConfig.mediaType || MediaType.VIDEO;
        // Special configuration for audio elements
        if (this.mediaType === MediaType.AUDIO) {
            // this.zIndexWeight = 0;
            this.transformer?.opacity(0);
            this.konvaElement?.opacity(0);
        }
    }
    setPositionFromIndex(index: number, words: Word[]) {
        if (index >= this.startWord && index <= this.endWord) {
            this.isActive = true;
        } else {
            this.isActive = false;
        }
        if (words.length && words[index] && words[this.startWord] && words[this.endWord]) {
            const targetTime = words[index].startTimeInEdit;
            const startTime = words[this.startWord].startTimeInEdit;
            const endTime = words[this.endWord].endTimeInEdit;
            const totalProgressDuration = endTime - startTime || 0.0001;
            const timeInPercent = (targetTime - startTime) / totalProgressDuration;
            this.videoElement.currentTime = totalProgressDuration * timeInPercent;
        }
    }

    play() {
        this.videoElement?.play();
        this.isPlaying = true;
        this.isActive = true;
    }
    pause() {
        this.videoElement?.pause();
        this.isPlaying = false;
    }
    stop() {
        this.videoElement?.pause();
        this.isPlaying = false;
        this.videoElement.currentTime = 0;
    }
    protected hideCustom() {
        this.isActive = false;
        this.videoElement.volume = 0;
    }
    protected showCustom() {
        this.isActive = true;
        this.videoElement.volume = this.volume;
        if (this.mediaType === MediaType.AUDIO) {
            this.transformer?.hide();
            this.konvaElement?.hide();
        }
    }
}
