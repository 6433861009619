import { Component, Optional } from '@angular/core';
import { DialogRef } from '@type/dialog';

import { AnalyticsEvents } from '@type/shared-models/consts/analytic-events';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
    requestLanguage = false;

    constructor(@Optional() ref: DialogRef) {
        this.requestLanguage = ref?.config?.data?.requestLanguage;
    }

    startChat() {
        window.analytics.track(AnalyticsEvents.START_LIVECHAT);
        console.log('Startchat');
    }
}
