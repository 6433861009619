import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, merge, Observable, Observer, timer } from 'rxjs';
import ysFixWebmDuration from 'fix-webm-duration';
import { map, take } from 'rxjs/operators';
import { MediaType } from '@type/shared-models/media/media.utils';
import { DialogService } from '@type/dialog';
import { EditorService } from './editor.service';
import { UploadConfirmDialogComponent } from '../pages/main-editor/webcam-screen-recorder/upload-confirm-dialog/upload-confirm-dialog.component';
import { CameraMicrophoneAccessComponent } from '../partials/camera-microphone-access/camera-microphone-access.component';
import { type } from 'os';
import { UploadService } from './upload.service';
import { UserService } from './user.service';
type DeviceType = 'camera' | 'microphone' | 'screen' | 'default';

@Injectable({
    providedIn: 'root'
})
export class RecorderService {
    cameraAccess$ = new BehaviorSubject<MediaStream>(null);
    microphoneAccess$ = new BehaviorSubject<MediaStream>(null);
    screenAccess$ = new BehaviorSubject<MediaStream>(null);

    isScreenAndCam$ = new BehaviorSubject<boolean>(false);
    isScreenOnly$ = new BehaviorSubject<boolean>(false);
    isCamOnly$ = new BehaviorSubject<boolean>(false);
    isMicOnly$ = new BehaviorSubject<boolean>(false);

    activePreview$ = new BehaviorSubject<DeviceType[]>(['default']);

    currentVideoDeviceId$ = new BehaviorSubject<ConstrainDOMString>(null);
    currentAudioDeviceId$ = new BehaviorSubject<ConstrainDOMString>(null);

    isCurrentlyRecording$ = new BehaviorSubject<boolean>(false);
    mediaRecorder: MediaRecorder;
    private chunks = [];

    showScreenRecorder$ = new BehaviorSubject<boolean>(false);
    recorderStartTime$ = new BehaviorSubject<number>(null);
    isPaused$ = new BehaviorSubject<boolean>(false);
    showCountdown$ = new BehaviorSubject<boolean>(false);
    private waitingForCountdown = false;
    private recorderPauseStart: number;
    private usageCheck$;
    private showRecordingLimitReachedDialog = false;

    constructor(
        private uploadService: UploadService,
        private userService: UserService,
        private dialogService: DialogService,
        private editorService: EditorService
    ) {
        this.activePreview$.subscribe((active: DeviceType[]) => {
            const sortedPreview = active.sort((a, b) => a.localeCompare(b));

            this.isScreenAndCam$.next(
                this.compareArray(sortedPreview, ['camera', 'screen']) ||
                    this.compareArray(sortedPreview, ['camera', 'microphone', 'screen'])
            );
            this.isScreenOnly$.next(
                this.compareArray(sortedPreview, ['microphone', 'screen']) ||
                    this.compareArray(sortedPreview, ['screen'])
            );
            this.isCamOnly$.next(
                this.compareArray(sortedPreview, ['camera']) ||
                    this.compareArray(sortedPreview, ['camera', 'microphone'])
            );
            this.isMicOnly$.next(this.compareArray(sortedPreview, ['microphone']));
        });
    }

    /* REQUEST ACCESS */
    requestCameraAndMicrophoneAccess(
        mediaDeviceInfoVideoId?: ConstrainDOMString,
        mediaDeviceInfoAudioId?: ConstrainDOMString
    ) {
        console.log('Request cam and mic access');

        if (mediaDeviceInfoVideoId) {
            this.currentVideoDeviceId$.next(mediaDeviceInfoVideoId);
        }
        if (mediaDeviceInfoAudioId) {
            this.currentAudioDeviceId$.next(mediaDeviceInfoAudioId);
        }
        const constraints: MediaStreamConstraints = {
            video: {
                deviceId: this.currentVideoDeviceId$.value,
                aspectRatio: 16 / 9
            },
            audio: {
                deviceId: this.currentAudioDeviceId$.value
            }
        };
        return navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                this.cameraAccess$.next(stream);
                this.currentVideoDeviceId$.next(stream.getVideoTracks()[0].getSettings().deviceId);
                this.currentAudioDeviceId$.next(stream.getAudioTracks()[0].getSettings().deviceId);
                return true;
            })
            .catch(() => {
                console.log('No video for you!');
                return false;
            });
    }
    requestMicrophoneAccess(mediaDeviceInfoAudioId?: ConstrainDOMString) {
        console.log('Request Mic access');
        if (mediaDeviceInfoAudioId) {
            this.currentAudioDeviceId$.next(mediaDeviceInfoAudioId);
        }
        const constraints: MediaStreamConstraints = {
            audio: {
                deviceId: this.currentAudioDeviceId$.value
            }
        };
        return navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                this.microphoneAccess$.next(stream);
                this.currentAudioDeviceId$.next(stream.getAudioTracks()[0].getConstraints().deviceId);
                return true;
            })
            .catch(() => {
                this.dialogService.openDialog(CameraMicrophoneAccessComponent);
                console.log('No mic for you!');
                return false;
            });
    }
    requestScreenAndMicrophoneAccess() {
        console.log('Request screen and mic access');
        // TypeScript Bug getDisplayMedia type missing: https://github.com/microsoft/TypeScript/issues/33232
        return (navigator.mediaDevices as any)
            .getDisplayMedia()
            .then(async (stream) => {
                const audioConstraints: MediaStreamConstraints = {
                    audio: {
                        deviceId: this.currentAudioDeviceId$.value
                    }
                };
                const [videoTrack] = stream.getVideoTracks();
                const audioStream = await navigator.mediaDevices.getUserMedia(audioConstraints).catch((e) => {
                    throw e;
                });
                const [audioTrack] = audioStream.getAudioTracks();
                stream = new MediaStream([videoTrack, audioTrack]);
                this.screenAccess$.next(stream);
                return true;
            })
            .catch(() => {
                console.log('No screen for you!');
                return false;
            });
    }

    /**
     * Change the audio input and add it to the screen recording which is currently active
     *
     * @param mediaDeviceInfoAudioId mediaDeviceInfo audio device id
     */
    async changeAudioForScreen(mediaDeviceInfoAudioId: ConstrainDOMString) {
        this.currentAudioDeviceId$.next(mediaDeviceInfoAudioId);
        const audioConstraints: MediaStreamConstraints = {
            audio: {
                deviceId: this.currentAudioDeviceId$.value
            }
        };
        const [videoTrack] = this.screenAccess$.getValue().getVideoTracks();

        const audioStream = await navigator.mediaDevices.getUserMedia(audioConstraints).catch((e) => {
            throw e;
        });
        const [audioTrack] = audioStream.getAudioTracks();
        this.screenAccess$.next(new MediaStream([videoTrack, audioTrack]));
    }

    /**
     * Stop the screen preview and call it again to get the "select screen" window again
     */
    changeScreen() {
        this.stopScreenPreview();
        this.activePreview$.next(['default']);
        this.requestScreenAndMicrophoneAccess().then((result) => {
            // Microphone access granted
            if (result) {
                this.activePreview$.next(['microphone', 'screen']);
            } else {
                // TODO: Error handling
            }
        });
    }

    /**
     * Stops the screen preview and removes all tracks
     */
    stopScreenPreview() {
        this.screenAccess$
            .getValue()
            ?.getTracks()
            .forEach((track) => {
                track.stop();
            });
        this.screenAccess$.next(null);
    }

    /**
     * Stops the camera preview and removes all tracks
     */
    stopCamPreview() {
        this.cameraAccess$
            .getValue()
            ?.getTracks()
            .forEach((track) => {
                track.stop();
            });
        this.cameraAccess$.next(null);
    }

    /**
     * Stops the microphone preview and removes all tracks
     */
    stopMicrophonePreview() {
        this.microphoneAccess$
            .getValue()
            ?.getTracks()
            .forEach((track) => {
                track.stop();
            });
        this.microphoneAccess$.next(null);
    }

    /* CHANGE PREVIEWS */
    showScreenAndCam() {
        forkJoin([
            this.requestCameraAndMicrophoneAccess(),
            this.requestMicrophoneAccess(),
            this.requestScreenAndMicrophoneAccess()
        ]).subscribe((results) => {
            // Camera, Screen and Microphone access granted
            if (results.every((result) => result === true)) {
                this.activePreview$.next(['camera', 'microphone', 'screen']);
            }
        });
    }

    showScreen() {
        forkJoin([this.requestMicrophoneAccess(), this.requestScreenAndMicrophoneAccess()]).subscribe((results) => {
            // screen and Microphone access granted
            if (results.every((result) => result === true)) {
                this.stopCamPreview();
                this.activePreview$.next(['microphone', 'screen']);
            }
        });
    }

    showCam() {
        forkJoin([this.requestMicrophoneAccess(), this.requestCameraAndMicrophoneAccess()]).subscribe((results) => {
            // Camera and Microphone access granted
            if (results.every((result) => result === true)) {
                this.stopScreenPreview();
                this.activePreview$.next(['camera', 'microphone']);
            }
        });
    }

    showMic() {
        forkJoin([this.requestMicrophoneAccess()]).subscribe((results) => {
            // Microphone access granted
            if (results.every((result) => result === true)) {
                this.stopScreenPreview();
                this.stopCamPreview();
                this.activePreview$.next(['microphone']);
            }
        });
    }

    /**
     * Returns a Promise with all MediaDeviceInfo Elements filtered by type.
     *
     * @param type MediaDeviceKind (videoinput, audioinput, audiooutput)
     */
    async getUserDevices(type: MediaDeviceKind = 'videoinput'): Promise<MediaDeviceInfo[]> {
        return await navigator.mediaDevices
            .enumerateDevices()
            .then((results) => results.filter((result) => result.kind === type));
    }

    /**
     * Returns a Promise with all audio MediaDeviceInfo Elements
     */
    async getUserAudioDevices(): Promise<MediaDeviceInfo[]> {
        return await this.getUserDevices('audioinput').then((val) => {
            if (!val?.length) {
                return val;
            }

            let defaultDevice;
            val = val.filter((result) => {
                if (result.deviceId === 'default') {
                    defaultDevice = result;
                    return false;
                }
                return true;
            });

            if (this.currentAudioDeviceId$.value && this.currentAudioDeviceId$.value !== 'default') {
                return val;
            }

            defaultDevice = val.find((result) => result.groupId === defaultDevice?.groupId);
            this.currentAudioDeviceId$.next(defaultDevice?.deviceId);
            return val;
        });
    }

    /**
     * Returns a Promise with all video MediaDeviceInfo Elements
     */
    async getUserVideoDevices(): Promise<MediaDeviceInfo[]> {
        return await this.getUserDevices('videoinput').then((val) => {
            if (!val?.length) {
                return val;
            }

            let defaultDevice;
            val = val.filter((result) => {
                if (result.deviceId !== 'default') {
                    return true;
                }
                defaultDevice = result;
                return false;
            });

            if (this.currentVideoDeviceId$.value && this.currentVideoDeviceId$.value !== 'default') {
                return val;
            }

            defaultDevice = val.find((result) => result.groupId === defaultDevice?.groupId);
            this.currentVideoDeviceId$.next(defaultDevice?.deviceId);
            return val;
        });
    }

    /* RECORDING */

    /**
     * Starts a recording, sets the projects media type, handles the (auto) upload if the recording stops or the usage time is reached
     */
    async startRecording() {
        if (this.waitingForCountdown) {
            return;
        }
        this.waitingForCountdown = true;
        await this.showCountdown();
        this.waitingForCountdown = false;
        console.log('⏯ Started Recording');
        this.setUsageCheck();

        this.isCurrentlyRecording$.next(true);
        this.isPaused$.next(false);
        this.chunks = [];
        let mimeTypes: string[];
        let recorderOptions;

        if (this.isMicOnly$.getValue()) {
            mimeTypes = ['audio/webm;codecs=opus', 'audio/webm', 'audio/ogg; codecs=opus'];
            this.uploadService.mediaType = MediaType.AUDIO;
        } else {
            this.uploadService.mediaType = MediaType.VIDEO;
            mimeTypes = [
                'video/webm;codecs=h264',
                'video/webm;codecs=H264',
                'video/webm;codecs=h264,opus',
                'video/webm;codecs=h264,vp9,opus',
                'video/webm;codecs=vp9,opus',
                'video/webm;codecs=vp8,vp9,opus',
                'video/webm;codecs=vp8,opus',
                'video/WEBM;codecs=VP8,OPUS',
                'video/webm;codecs=vp9',
                'video/webm;codecs=vp8',
                'video/webm;codecs=vp9.0',
                'video/webm;codecs=vp8.0',
                'video/webm;codecs=avc1',
                'video/webm'
            ];
        }
        for (const mimeType of mimeTypes) {
            if (MediaRecorder.isTypeSupported(mimeType)) {
                console.log('Recording started with mimetype ', mimeType);
                recorderOptions = { mimeType };
                break;
            }
        }

        if (!recorderOptions) {
            // TODO Error handling
            // Recording not possible
        }

        if (this.isScreenAndCam$.getValue()) {
            // Do nothing
        } else if (this.isScreenOnly$.getValue()) {
            this.mediaRecorder = new MediaRecorder(this.screenAccess$.getValue(), recorderOptions);
        } else if (this.isCamOnly$.getValue()) {
            this.mediaRecorder = new MediaRecorder(this.cameraAccess$.getValue(), recorderOptions);
        } else if (this.isMicOnly$.getValue()) {
            this.mediaRecorder = new MediaRecorder(this.microphoneAccess$.getValue(), recorderOptions);
        } else {
            // TODO Error handling
        }

        this.mediaRecorder.ondataavailable = (event) => {
            if (event.data && event.data.size > 0) {
                this.chunks.push(event.data);
            }
        };
        this.mediaRecorder.onstart = () => {
            this.recorderStartTime$.next(Date.now());
        };
        this.mediaRecorder.onstop = () => {
            let recordingDurationMs = Date.now() - this.recorderStartTime$.value;

            //if paused and stopped after that
            if (this.recorderPauseStart) {
                recordingDurationMs += Date.now() - this.recorderPauseStart;
            }

            this.isCurrentlyRecording$.next(false);

            this.dialogService
                .openDialog(UploadConfirmDialogComponent, {
                    data: { mediaType: this.isMicOnly$.getValue() ? MediaType.AUDIO : MediaType.VIDEO }
                })
                .afterClosed()
                .subscribe((startUpload) => {
                    if (startUpload) {
                        const buggyBlob = new Blob(this.chunks, { type: recorderOptions.mimeType });
                        const fileName = this.isMicOnly$.getValue() ? 'audio_recording.webm' : 'video_recording.webm';
                        // Sadly there is a chrome bug for webm videos. https://bugs.chromium.org/p/chromium/issues/detail?id=642012
                        // Most of the time there is no duration stored in the video. This package tries to fix it.
                        ysFixWebmDuration(buggyBlob, recordingDurationMs, (blob) => {
                            const file = new File([blob], fileName);
                            this.stopScreenPreview();
                            this.stopCamPreview();
                            this.stopMicrophonePreview();
                            this.uploadService.startUpload(
                                file,
                                recordingDurationMs / 1000,
                                true,
                                this.showRecordingLimitReachedDialog,
                                0, //TODO: replace this with initalIndex for multiple Video Support
                                false,
                                true //TODO: check if mainMedia upload, if so pass true, if it's a second or following video pass false
                            );
                            this.isOnlineChecker$()
                                .pipe(take(2))
                                .subscribe((isOnline) => {
                                    if (
                                        isOnline ||
                                        !!this.uploadService.taskList$.value[this.editorService.currentProject.id]
                                    ) {
                                        return;
                                    }
                                    console.log('🚨 ~ Emergency download initiated ~ 🚨');
                                    this.immediatelyStartEmergencyDownload(blob, 'backup_download_' + fileName);
                                });
                            this.showScreenRecorder$.next(false);
                        });
                    }
                });
        };
        this.mediaRecorder.start();
    }

    stopRecording() {
        console.log('⏹ Stopped Recording');
        this.mediaRecorder.stop();
        this.recorderPauseStart = Date.now();
    }

    /**
     * Toggles the recordings state (recording or paused). If switched to recording again it shows a 3 seconds countdown before resuming the recording
     */
    async togglePauseRecording() {
        if (this.mediaRecorder.state === 'recording') {
            console.log('⏸ Paused Recording');
            this.mediaRecorder.pause();
            this.recorderPauseStart = Date.now();
        } else if (this.mediaRecorder.state === 'paused') {
            if (this.waitingForCountdown) {
                return;
            }
            this.waitingForCountdown = true;
            await this.showCountdown();
            this.waitingForCountdown = false;
            console.log('▶️ Resumed Recording');
            this.mediaRecorder.resume();
            const newStartTime = this.recorderStartTime$.getValue() + (Date.now() - this.recorderPauseStart);
            this.recorderPauseStart = null;
            this.recorderStartTime$.next(newStartTime);
        }
    }

    restartRecording() {
        console.log('🔄 Restart Recording');
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.mediaRecorder.onstop = () => {};
        this.mediaRecorder.stop();
        this.startRecording().then();
    }

    cancelRecording() {
        console.log('🚫 Cancel Recording');
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.mediaRecorder.onstop = () => {};
        this.mediaRecorder.stop();
        this.isCurrentlyRecording$.next(false);
    }

    toggleRecording() {
        if (this.isCurrentlyRecording$.value) {
            this.stopRecording();
        } else {
            this.startRecording().then();
        }
    }

    /* OTHER */
    /**
     * Checks if two arrays are equal
     *
     * @param array1
     * @param array2
     * @private
     */
    private compareArray(array1: any[], array2: any[]): boolean {
        return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
    }

    /**
     * Clears all pöd recordings and saved blobs; Sets the state to not recording
     */
    resetAll() {
        this.mediaRecorder = null;
        this.chunks = [];
        this.recorderStartTime$.next(null);
        this.isCurrentlyRecording$.next(false);
    }

    /**
     * Shows a 3 second countdown before resolving true.
     * Note: don't change the 3 seconds as the animation is css based
     */
    showCountdown() {
        return new Promise((resolve) => {
            this.showCountdown$.next(true);
            setTimeout(() => {
                this.showCountdown$.next(false);
                resolve(true);
            }, 3000);
        });
    }

    /**
     * Returns the remaining seconds in the users plan
     */
    getRemainingUserSeconds() {
        const remainingUsage = this.userService.user.subscriptionState.remainingUsage;
        return this.isPaused$.getValue()
            ? remainingUsage -
                  (Date.now() - this.recorderStartTime$.getValue() - (Date.now() - this.recorderPauseStart)) / 1000
            : remainingUsage -
                  (this.recorderStartTime$.getValue() ? (Date.now() - this.recorderStartTime$.getValue()) / 1000 : 0);
    }

    /**
     * Sets a timer to check the time usage every 200ms
     */
    setUsageCheck() {
        this.usageCheck$ = timer(0, 200)
            .pipe(map(() => this.checkUsage()))
            .subscribe();
    }

    /**
     * Stops the recording, starts the upload and show an info modal if the usage limitation is reached
     */
    checkUsage() {
        if (this.getRemainingUserSeconds() <= 0) {
            this.usageCheck$.unsubscribe();
            this.showRecordingLimitReachedDialog = true;
            this.stopRecording();
        }
    }

    /**
     * Returns an observable which state is either true or false depending on if the user is online or offline
     */
    isOnlineChecker$() {
        return merge(
            fromEvent(window, 'offline').pipe(map(() => false)),
            fromEvent(window, 'online').pipe(map(() => true)),
            new Observable((sub: Observer<boolean>) => {
                sub.next(navigator.onLine);
                sub.complete();
            })
        );
    }

    /**
     * Starts a download of the current recording to the users PC
     *
     * @private
     */
    private immediatelyStartEmergencyDownload(blob: Blob, filename: string) {
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
    }
}
