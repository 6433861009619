<!-- TODO[JK]: i broke down the toggle. Seems to work. But in row 1 u say sth about surround with label. and i dont know if this is still important -->
<div
    class="flex justify-between items-center cursor-pointer select-none"
    [ngClass]="toggleSizeMap[size]"
    [class.disabled]="disabled"
>
    <div
        class="flex text-sm font-medium items-center gap-2 flex-grow"
        (click)="switchToggle()"
        [class.disabled]="disabled"
    >
        <ng-content select="[toggle-icon]"></ng-content>
        <div class="flex flex-col">
            <ng-content select="[toggle-label]"></ng-content> <ng-content select="[toggle-description]"></ng-content>
        </div>
    </div>
    <label
        class="default-toggle"
        [ngClass]="{ small: toggleSizeMap[size] === 'sm' }"
        [class.default-toggle__disabled]="disabled"
    >
        <input type="checkbox" [checked]="checked" (change)="switchToggle($event)" [disabled]="disabled" />
        <span class="default-toggle__slider"></span>
    </label>
    <div *ngIf="surroundedByLabels" (click)="switchToggle()" [class.default-toggle__disabled]="disabled">
        <ng-content select="[toggle-label-after]" #toggleLabelAfter></ng-content>
    </div>
</div>
