import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';
import { DialogRef } from '@type/dialog';
import { overlayAnimations } from '@type/shared/animations/overlay.animations';
import { take } from 'rxjs';
import { OverlayComponentType } from '../overlay-component.model';

@Component({
    selector: 'app-menu',
    exportAs: 'appMenu',
    templateUrl: 'menu.component.html',
    styles: [
        `
            :host {
                display: none;
            }
            .scroll-container--y-auto {
                --scroll-width: 0.5rem;
            }
        `
    ],
    animations: overlayAnimations
})
export class MenuComponent extends OverlayComponentType implements OnChanges {
    @Output() menuClosed = new EventEmitter<boolean>();
    @Input() panelClass: string;
    classMap = {};

    /* The arrow down and up listeners jump to the next / previous menu item when pressed */
    @HostListener('window:keydown.arrowdown', ['$event']) handleArrowDown(event) {
        const targetElement: HTMLElement = event.target;
        // If the current element is a menu item button
        if (targetElement?.classList.contains('typ-menu-item')) {
            const listContainerElement = targetElement?.parentElement?.parentElement;
            let scrollTo;
            const nextElement = targetElement.parentElement?.nextElementSibling?.firstElementChild as HTMLElement;
            // if the next element is a button, focus it
            if (nextElement?.classList.contains('typ-menu-item')) {
                nextElement.focus();
                scrollTo = nextElement.offsetTop - listContainerElement.offsetTop - 8;
            } else {
                // No next element, select first element
                const firstElement = listContainerElement?.firstElementChild.firstElementChild as HTMLElement;
                if (firstElement?.classList.contains('typ-menu-item')) {
                    firstElement.focus();
                    scrollTo = 0;
                }
            }
            // Scroll to the selected item
            setTimeout(() => {
                listContainerElement.scrollTop = scrollTo;
            }, 100);
        }
    }
    @HostListener('window:keydown.arrowup', ['$event']) handleArrowUp(event) {
        const targetElement: HTMLElement = event.target;
        // If the current element is a menu item button
        if (targetElement?.classList.contains('typ-menu-item')) {
            const listContainerElement = targetElement?.parentElement?.parentElement;
            let scrollTo;
            const previousElement = targetElement.parentElement?.previousElementSibling
                ?.firstElementChild as HTMLElement;
            // if the previous element is a button, focus it
            if (previousElement?.classList.contains('typ-menu-item')) {
                previousElement.focus();
                scrollTo = previousElement.offsetTop - listContainerElement.offsetTop - 8;
            } else {
                // No previous element, select last element
                const lastElement = listContainerElement?.lastElementChild.firstElementChild as HTMLElement;
                if (lastElement?.classList.contains('typ-menu-item')) {
                    lastElement.focus();
                    scrollTo = listContainerElement.scrollHeight;
                }
            }
            // Scroll to the selected item
            setTimeout(() => {
                listContainerElement.scrollTop = scrollTo;
            }, 100);
        }
    }

    constructor(private el: ElementRef) {
        super();
    }
    ngOnChanges() {
        if (!this.panelClass) {
            this.classMap = {};
        } else {
            this.classMap[this.panelClass] = true;
        }
    }
    startOpen(ref: DialogRef) {
        super.startOpen(ref);
        ref.afterClosed()
            .pipe(take(1))
            .subscribe(() => {
                this.menuClosed.emit();
            });
    }
}
