import { Component } from '@angular/core';
import { take } from 'rxjs/operators';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-user-confirmation',
    templateUrl: './user-confirmation.component.html',
    styleUrls: ['./user-confirmation.component.scss']
})
export class UserConfirmationComponent {
    user$ = this.userService.user$;
    sendStep = 0;

    constructor(private userService: UserService) {}

    resendEmail() {
        this.userService.sendVerificationEmail().pipe(take(1)).subscribe();
        this.sendStep = 1;
        setTimeout(() => {
            this.sendStep = 2;
        }, 10000);
    }
}
