import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { filter, map, switchMap, take } from 'rxjs/operators';
import { throwError } from 'rxjs';
import paths from '@type/shared-models/consts/firebase-paths';
import { OrganizationFirebaseModel, OrganizationModel } from '@type/shared-models/organization/organization.models';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FirebaseFunctionNames } from '@type/shared-models/consts/firebase-function-names';
import { MemberModel, MemberRole } from '@type/shared-models/member.models';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {
    constructor(
        private db: AngularFirestore,
        private userService: UserService,
        private functions: AngularFireFunctions
    ) {}
    //
    getOrganization() {
        return this.userService.user$.pipe(
            filter((val) => !!val),
            take(1),
            switchMap((user) =>
                this.db
                    .collection<OrganizationFirebaseModel>(paths.ORGANIZATIONS, (ref) =>
                        ref.where(`members.${user.firebaseId}`, '!=', null)
                    )
                    .snapshotChanges()
                    .pipe(
                        map((docChanges) => docChanges[0]),
                        map((snapshot) =>
                            snapshot
                                ? {
                                      ...snapshot.payload.doc.data(),
                                      firebaseId: snapshot.payload.doc.id
                                  }
                                : null
                        ),
                        map((organization) =>
                            organization
                                ? OrganizationFirebaseModel.fromFirebase(organization as OrganizationFirebaseModel)
                                : ({} as OrganizationModel)
                        )
                    )
            )
        );
    }

    /**
     * Returns a true if the user is an owner of an organization
     */
    isOrganizationMember(onlyOwner = false, onlyMember = false) {
        return this.getOrganization().pipe(
            map(
                (organization) =>
                    !!organization?.members?.some(
                        (member) =>
                            member.firebaseId === this.userService.user.firebaseId &&
                            (onlyOwner ? member.role === MemberRole.OWNER : true) &&
                            (onlyMember ? member.role === MemberRole.EDITOR : true)
                    )
            )
        );
    }

    removeMember(organization: OrganizationModel, member: MemberModel) {
        return this.functions.httpsCallable(FirebaseFunctionNames.organizationRemoveMember)({
            memberUserId: (member as any).userId,
            organizationId: organization.firebaseId
        });
    }

    inviteMember(organization: OrganizationModel, email: string) {
        if (organization.members.some((member) => member.email === email)) {
            return throwError({ message: 'Member already invited.' });
        }

        return this.functions.httpsCallable(FirebaseFunctionNames.organizationInviteMember)({
            email,
            organizationId: organization.firebaseId
        });
    }
}
