import { Command } from '../command';
import { Word } from '@type/shared-models/editor/word';
import { EditorService } from '../../services/editor.service';
export class WordEditCommand implements Command {
    private readonly selectedWordIndex: number;
    private readonly words: Word[];
    private previousWord: string;
    private readonly newWord: string;
    private editorService: EditorService;

    constructor(wordEditObject: {
        selectedWordIndex: number;
        words: Word[];
        newWord: string;
        editorService: EditorService;
    }) {
        this.selectedWordIndex = wordEditObject.selectedWordIndex;
        this.words = wordEditObject.words;
        this.newWord = wordEditObject.newWord;
        this.editorService = wordEditObject.editorService;
    }

    execute() {
        this.previousWord = this.words[this.selectedWordIndex].text;
        if (this.selectedWordIndex >= 0 && this.selectedWordIndex < this.words.length) {
            const selectedWord = this.words[this.selectedWordIndex];
            selectedWord.text = this.newWord;

            selectedWord.isPause = selectedWord.text === '';
        }
        this.editorService.updateWords([this.selectedWordIndex]);
        return this;
    }

    undo() {
        this.words[this.selectedWordIndex].text = this.previousWord;
        this.words[this.selectedWordIndex].isPause = this.previousWord === '';
        this.editorService.updateChunkWordArray();
        this.editorService.updateWords([this.selectedWordIndex]);
        this.editorService.updatePreviewTemplates(this.selectedWordIndex);
        this.editorService.updateSelectedParagraphs();
    }
}
