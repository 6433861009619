import { Component, OnDestroy } from '@angular/core';
import { UserService } from '@type/payment-dialog/services/payment-dialog-user.service';
import { PlanType, PlanInterval } from '@type/shared-models/payment/products';
import { User } from '@type/shared-models/user/user';
import { map, Subscription } from 'rxjs';
import {
    BusinessPlanDescription,
    OverviewPlanDescription,
    PlanDescription,
    ProPlanDescription,
    ProTeamPlanDescription,
    StarterBasicPlanDescription,
    ExportLimitDescription,
    StarterPlanDescription
} from '../../consts/feature-lists';
import { UpgradeService } from '../../services/payment-dialog-upgrade.service';

@Component({
    selector: 'type-upgrade-features',
    templateUrl: './upgrade-features.component.html',
    styleUrls: ['./upgrade-features.component.scss']
})
export class UpgradeFeaturesComponent implements OnDestroy {
    planDescription: PlanDescription;

    planSubscription: Subscription;
    showPriceCompareLink = false;
    showToggle = false;

    trialExportButton = document.getElementById('trialExportButton');

    selectedInterval$ = this.upgradeService.planInterval$;
    currentPlanIsTrial$ = this.userService.user$.pipe(map((user: User) => user?.isTrial));

    PlanType = PlanType;

    constructor(private upgradeService: UpgradeService, private userService: UserService) {
        this.subscribePlanChanges();
    }

    ngOnDestroy(): void {
        this.planSubscription?.unsubscribe();
    }
    private subscribePlanChanges() {
        this.planSubscription = this.upgradeService.planType$.subscribe((planType) => {
            this.updateFeatureList(planType);
        });
    }
    private updateFeatureList(selectedPlan: PlanType) {
        this.showPriceCompareLink = false;

        this.positionTrialExportButton(selectedPlan);
        this.showToggle = false;
        switch (selectedPlan) {
            case PlanType.STARTER_BASIC:
                this.planDescription = StarterBasicPlanDescription;

                break;
            case PlanType.PRO:
                this.planDescription = ProPlanDescription;

                break;
            case PlanType.PRO_TEAM:
                this.planDescription = ProTeamPlanDescription;

                break;
            case PlanType.BUSINESS:
                this.planDescription = BusinessPlanDescription;

                break;
            default:
                if (location.pathname !== '/pricing') {
                    this.showPriceCompareLink = true;
                }
                if (this.trialExportButton !== null) {
                    this.planDescription = ExportLimitDescription;
                } else {
                    this.planDescription = OverviewPlanDescription;
                }
                this.showToggle = true;
                break;
        }
    }

    positionTrialExportButton(selectedPlan) {
        if (this.trialExportButton != null) {
            if (selectedPlan === null) {
                if (this.trialExportButton.classList.contains('trialExportButton__plan')) {
                    this.trialExportButton.classList.remove('trialExportButton__plan');
                }
            } else {
                if (!this.trialExportButton.classList.contains('trialExportButton__plan')) {
                    this.trialExportButton.classList.add('trialExportButton__plan');
                }
            }
        }
    }

    togglePlanInterval(event: boolean) {
        switch (event) {
            case true:
                this.upgradeService.planInterval = PlanInterval.annually;
                break;

            default:
                this.upgradeService.planInterval = PlanInterval.monthly;
                break;
        }
    }
}
