import { Component, Input } from '@angular/core';
import { PlanElement, PlanInterval, PlanType } from '@type/shared-models/payment/products';
import { UserService } from '../../services/payment-dialog-user.service';
import { UpgradeService } from '../../services/payment-dialog-upgrade.service';
import {
    BusinessPlanDescription,
    FreePlanDescription,
    ProPlanDescription,
    ProTeamPlanDescription,
    StarterBasicPlanDescription,
    StarterPlanDescription,
    UltraPlanDescription
} from '@type/payment-dialog/consts/feature-lists';
import { map, tap } from 'rxjs/operators';
import { OrganizationService } from '@type/payment-dialog/services/organization.service';
import { PaymentDialogService } from '@type/payment-dialog/payment-dialog.service';
import { PAYMENT_REASONS } from '@type/shared-models/consts/payment-reason';
import { User } from '@type/shared-models/user/user';

@Component({
    selector: 'type-upgrade-plan-selector',
    templateUrl: './upgrade-plan-selector.component.html',
    styleUrls: ['./upgrade-plan-selector.component.scss']
})
export class UpgradePlanSelectorComponent {
    get showAllPlans(): boolean {
        return this._showAllPlans;
    }

    @Input() set showAllPlans(value: boolean) {
        this._showAllPlans = value;
        if (value) {
            this.availablePlans.unshift({
                ...FreePlanDescription,
                planType: PlanType.TRIAL,
                monthlyPrice: 0,
                yearlyPrice: 0
            });
        } else {
            this.availablePlans = this.availablePlans.filter((plan) => plan.planType !== PlanType.TRIAL);
        }
    }
    private _showAllPlans = false;

    @Input() openModal;
    @Input() showToggle = false;

    availablePlans: PlanElement[] = [
        // {
        //     ...StarterBasicPlanDescription,
        //     planType: PlanType.STARTER_BASIC,
        //     monthlyPrice: this.upgradeService.planTable[PlanType.STARTER_BASIC]['monthly'].price,
        //     yearlyPrice: this.upgradeService.planTable[PlanType.STARTER_BASIC]['annually'].price / 12
        // },
        {
            ...ProPlanDescription,
            planType: PlanType.PRO,
            monthlyPrice: this.upgradeService.planTable[PlanType.PRO]['monthly'].price,
            yearlyPrice: this.upgradeService.planTable[PlanType.PRO]['annually'].price / 12
        },
        {
            ...UltraPlanDescription,
            planType: PlanType.ULTRA,
            monthlyPrice: this.upgradeService.planTable[PlanType.ULTRA]['monthly'].price,
            yearlyPrice: this.upgradeService.planTable[PlanType.ULTRA]['annually'].price / 12
        }
        // {
        //     ...ProTeamPlanDescription,
        //     planType: PlanType.PRO_TEAM,
        //     monthlyPrice: this.upgradeService.planTable[PlanType.PRO_TEAM]['monthly'].price,
        //     yearlyPrice: this.upgradeService.planTable[PlanType.PRO_TEAM]['annually'].price / 12
        // }
        // {
        //     ...BusinessPlanDescription,
        //     planType: PlanType.BUSINESS,
        //     monthlyPrice: this.upgradeService.planTable[PlanType.BUSINESS]['monthly'].price / 12
        // }
    ];

    currentPlanType$ = this.userService.user$.pipe(
        map((user: User) => user?.subscriptionState?.plan?.type),
        map((planType: PlanType) => (planType === PlanType.EXPIRED ? PlanType.TRIAL : planType)),
        tap((planType: PlanType) => {
            if (planType === PlanType.PRO) {
                this.availablePlans = this.availablePlans.filter(
                    (planElement) => planElement.planType !== PlanType.STARTER
                );
            }
        })
    );

    currentPlanIsTrial$ = this.userService.user$.pipe(map((user: User) => user?.isTrial));

    isOrganizationMemberOnly$ = this.organizationService.isOrganizationMember(false, true);
    planType = PlanType;

    selectedInterval$ = this.upgradeService.planInterval$;

    constructor(
        public upgradeService: UpgradeService,
        public userService: UserService,
        private organizationService: OrganizationService,
        private paymentDialogService: PaymentDialogService
    ) {}

    selectPlan(planButton: PlanElement) {
        if (this.openModal) {
            this.paymentDialogService.openPaymentDialog(planButton.planType, PAYMENT_REASONS.planComparison);
        } else {
            this.upgradeService.planType = planButton.planType;
        }
    }

    togglePlanInterval(event: boolean) {
        switch (event) {
            case true:
                this.upgradeService.planInterval = PlanInterval.annually;
                break;

            default:
                this.upgradeService.planInterval = PlanInterval.monthly;
                break;
        }
    }
}
