import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    QueryList,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownComponent } from '@type-mvp/app/forms/typ-select-field/dropdown.component';
import { TypSelectFieldOptionComponent } from '@type-mvp/app/forms/typ-select-field/typ-select-field-option.component';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { TypDropdownService } from '@type-mvp/app/forms/typ-select-field/typ-dropdown.service';

@Component({
    selector: 'app-select-field',
    templateUrl: './typ-select-field.component.html',
    styleUrls: ['./typ-select-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TypSelectFieldComponent),
            multi: true
        },
        TypDropdownService
    ]
})
export class TypSelectFieldComponent implements AfterViewInit, ControlValueAccessor {
    @Input()
    label: string;

    @Input()
    placeholder: string;

    @Input()
    selected: string | number | boolean;

    @Input()
    required = false;

    @Input()
    disabled = false;

    @Output()
    selectionChange = new EventEmitter<{
        selected: boolean;
        value: string | number | boolean;
    }>();

    @ViewChild('input')
    input: ElementRef;

    @ViewChild(DropdownComponent)
    dropdown: DropdownComponent;

    @ContentChildren(TypSelectFieldOptionComponent)
    options: QueryList<TypSelectFieldOptionComponent>;

    selectedOption: TypSelectFieldOptionComponent;

    displayText: string;

    private keyManager: ActiveDescendantKeyManager<TypSelectFieldOptionComponent>;

    constructor(private dropdownService: TypDropdownService) {
        this.dropdownService.register(this);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChangeFn = (_?) => {
        console.log();
    };

    onTouchedFn = () => {
        console.log('');
    };

    ngAfterViewInit() {
        setTimeout(() => {
            this.selectedOption = this.options.toArray().find((option) => option.value === this.selected);
            this.displayText = this.selectedOption ? this.selectedOption.label : '';
            this.keyManager = new ActiveDescendantKeyManager(this.options)
                .withHorizontalOrientation('ltr')
                .withVerticalOrientation()
                .withWrap();
        });
    }

    showDropdown() {
        this.dropdown.show();

        if (!this.options.length) {
            return;
        }

        this.selected ? this.keyManager.setActiveItem(this.selectedOption) : this.keyManager.setFirstItemActive();
    }

    hideDropdown() {
        this.dropdown.hide();
    }

    onDropMenuIconClick(event: UIEvent) {
        event.stopPropagation();
        setTimeout(() => {
            this.input.nativeElement.focus();
            this.input.nativeElement.click();
        }, 10);
    }

    onKeyDown(event: KeyboardEvent) {
        if (['Enter', ' ', 'ArrowDown', 'Down', 'ArrowUp', 'Up'].indexOf(event.key) > -1) {
            if (!this.dropdown.showing) {
                this.showDropdown();
                return;
            }

            if (!this.options.length) {
                event.preventDefault();
                return;
            }
        }

        if (event.key === 'Enter' || event.key === ' ') {
            this.selectedOption = this.keyManager.activeItem;
            this.selected = this.selectedOption.value;
            this.displayText = this.selectedOption ? this.selectedOption.label : '';
            this.hideDropdown();
            this.onChange();
        } else if (event.key === 'Escape' || event.key === 'Esc') {
            this.dropdown.showing && this.hideDropdown();
        } else if (
            ['ArrowUp', 'Up', 'ArrowDown', 'Down', 'ArrowRight', 'Right', 'ArrowLeft', 'Left'].indexOf(event.key) > -1
        ) {
            this.keyManager.onKeydown(event);
        } else if (event.key === 'PageUp' || event.key === 'PageDown' || event.key === 'Tab') {
            this.dropdown.showing && event.preventDefault();
        }
    }

    selectOption(option: TypSelectFieldOptionComponent) {
        this.keyManager.setActiveItem(option);
        this.selected = option.value;
        this.selectedOption = option;
        this.displayText = this.selectedOption ? this.selectedOption.label : '';
        this.hideDropdown();
        this.input.nativeElement.focus();
        this.onChange();
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.selected = obj;
    }

    onTouched() {
        this.onTouchedFn();
    }

    onChange() {
        this.selectionChange.emit({
            selected: true,
            value: this.selected
        });
        this.onChangeFn(this.selected);
    }
}
