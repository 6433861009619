import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ToggleButtonComponent } from './toggle-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

const exportComponents = [ToggleButtonComponent];

@NgModule({
    imports: [CommonModule, AngularSvgIconModule],
    declarations: [...exportComponents],
    exports: [...exportComponents]
})
export class ToggleButtonModule {}
