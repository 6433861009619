import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastComponent } from './toast.component';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    private toasts: ToastComponent[] = [];

    add(toast: any) {
        // add toast to array of active toasts
        this.toasts.push(toast);
    }

    remove(id: string) {
        // remove toast from array of active toasts
        this.toasts = this.toasts.filter((x) => x.id !== id);
    }

    open(id: string, duration: number): BehaviorSubject<number> {
        // open toast specified by id
        const toast = this.toasts.find((x) => x.id === id);
        return toast.open(duration);
    }
    cancel(id: string) {
        const toast = this.toasts.find((x) => x.id === id);
        toast.cancel();
    }

    close(id: string) {
        // close toast specified by id
        const toast = this.toasts.find((x) => x.id === id);
        toast.close();
    }
}
