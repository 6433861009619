import Konva from 'konva-custom';
import { TransformerConfig } from 'konva-custom/lib/shapes/Transformer';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
import { CanvasElementType } from '../../canvas-elements';
import { KonvaAnimationDirection, KonvaAnimationType } from '../../editor/konva-animation';

export interface StartEndConfig {
    startWord: number;
    endWord: number;
}

export abstract class CanvasElement {
    protected minScale = 0.8;
    protected transformerOptionsOverwrite: TransformerConfig = {};
    ID: string;
    startWord: number;
    endWord: number;
    transformer: Konva.Transformer;
    layer: Konva.Layer;
    tween: Konva.Tween;
    type: CanvasElementType;
    templateId: string;
    encloseTranscript: boolean;
    zIndex = 1;
    forceHide: boolean;

    isVisible$ = new BehaviorSubject<boolean>(false);
    isHovered$ = new BehaviorSubject<boolean>(false);
    isDragged$ = new BehaviorSubject<boolean>(false);
    isEditActive$ = new BehaviorSubject<boolean>(false);
    playAnimations$ = new BehaviorSubject<boolean>(false);
    updateElement$: BehaviorSubject<CanvasElement> = new BehaviorSubject(null);
    pendingTemplateElement = false;

    defaultAnimationDuration = 0.7; // in seconds
    animationDirection: KonvaAnimationDirection;
    animationTypes: KonvaAnimationType[];

    private _konvaElement: Konva.Shape | Konva.Group;
    private previousDragStartPosition: { x: number; y: number };
    private previousTransformAttributes: { scaleX: number; scaleY: number; rotation: number };
    private previewTween: Konva.Tween;
    abstract zIndexWeight: number;

    get konvaElement(): Konva.Shape | Konva.Group {
        return this._konvaElement;
    }
    set konvaElement(konvaElement: Konva.Shape | Konva.Group) {
        this._konvaElement = konvaElement;
        this.addListeners();
    }

    protected constructor(ID: string, startEndConf: StartEndConfig, encloseTranscript: boolean) {
        if (!ID) {
            this.generateUUID();
        } else {
            this.ID = ID;
        }
        if (encloseTranscript) {
            this.encloseTranscript = encloseTranscript;
        }
        this.startWord = startEndConf.startWord;
        this.endWord = startEndConf.endWord;
    }

    abstract fromJSON(
        serializedElement: string,
        hiddenOnInit: boolean,
        elementsInitialized: BehaviorSubject<{ [uuid: string]: boolean }>
    );
    abstract newKonvaElement(hiddenOnInit: boolean);

    public addToKonvaLayer(
        stage: Konva.Stage,
        additionalObject?: { addInFront: boolean; object: Konva.Shape },
        showTransformer?: boolean,
        disableRotation?: boolean,
        newLayer?: boolean
    ) {
        if (!this.konvaElement) {
            console.error('Konva Element has not been initialized yet');
            return;
        }
        if (newLayer) {
            // Subtitle has its own layer to make sure that the subtitle always stays on top
            if (stage.getLayers().length === 1) {
                this.layer = new Konva.Layer();
                stage.add(this.layer);
            } else {
                this.layer = stage.getLayers()[1] as Konva.Layer;
            }
        } else {
            this.layer = stage.getLayers()[0] as Konva.Layer;
        }
        if (this.layer) {
            const transformer = this.createTransformer(showTransformer, disableRotation);
            const nodes = [];
            if (additionalObject && !additionalObject.addInFront) {
                nodes.push(additionalObject.object);
            }

            nodes.push(this.konvaElement);

            if (additionalObject?.addInFront) {
                nodes.push(additionalObject.object);
            }

            this.layer.add(...nodes, transformer);
            this.transformer.forceUpdate();
            this.setZIndexByBaseEl();
            this.layer.draw();
        }
    }

    public setZIndexByBaseEl() {
        this.zIndex = this.konvaElement?.zIndex();
    }

    public getAsJSONstring(): string {
        if (!this.konvaElement) {
            console.error('Konva Element has not been initialized yet');
            return null;
        }
        return this.konvaElement.toJSON();
    }

    public scaleFromTemplate(scale: number) {
        this.konvaElement.scaleX(this.konvaElement.scaleX() * scale);
        this.konvaElement.scaleY(this.konvaElement.scaleY() * scale);
        this.konvaElement.x(this.konvaElement.x() * scale);
        this.konvaElement.y(this.konvaElement.y() * scale);
        this.layer.draw();
        this.preScaleFromTemplateCustom();
    }

    protected preScaleFromTemplateCustom() {
        //TODO: implement
    }

    protected playAnimationCustom() {
        //TODO: implement
    }

    protected configurationChanged() {
        this.layer?.draw();
        this.updateElement$.next(this);
    }

    private createTransformer(showTransformer: boolean, disableRotation = false): Konva.Transformer {
        const transformerOptions: TransformerConfig = {
            nodes: [this.konvaElement],
            resizeEnabled: true,
            rotateEnabled: !disableRotation,
            keepRatio: true,
            anchorCornerRadius: 5,
            anchorSize: 14,
            anchorStroke: '#141414',
            anchorStrokeWidth: 3,
            borderStroke: '#141414',
            borderStrokeWidth: 3,
            rotationSnaps: [0, 90, 180, 270],
            enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
            visible: showTransformer,
            boundBoxFunc: (oldBoundBox, newBoundBox) => {
                // Restrict scaling the element too small and prevent element from
                // flipping which causes bug in konvajs because of rectangle corner
                // radius calculation
                if (this.type === CanvasElementType.TEXT) {
                    if (
                        newBoundBox.height < 0 ||
                        this.konvaElement.scaleY() < 0 ||
                        newBoundBox.width < 5 ||
                        (newBoundBox.width < oldBoundBox.width && this.konvaElement.scaleX() - 0.1 < this.minScale)
                    ) {
                        return oldBoundBox;
                    }
                }
                return newBoundBox;
            },
            ...this.transformerOptionsOverwrite
        };
        const transformer = new Konva.Transformer(transformerOptions);
        this.transformer = transformer;
        return transformer;
    }

    private generateUUID() {
        this.ID = uuidV4();
    }

    public transformerToTop(): number {
        const currentTransformerZIndex = this.transformer?.zIndex();
        this.transformer?.moveToTop();
        return currentTransformerZIndex;
    }

    public resetTransformerZIndex(zIndex: number) {
        this.transformer?.zIndex(zIndex);
    }

    private addListeners() {
        this.isEditActive$.subscribe((active) => {
            this.transformerToggle(active);
        });
        this.isHovered$.subscribe((hovered) => {
            if (!this.isEditActive$.value) {
                this.transformerToggle(hovered);
            }
        });
    }

    private transformerToggle(visible) {
        if (this.transformer && visible && this.konvaElement.visible()) {
            this.transformer.show();
        } else if (this.transformer) {
            this.transformer.hide();
        }
        this.layer?.draw();
    }

    protected abstract changeZIndexCustom(zIndex: number);

    // fixZIndex will get real zIndex via konva method
    public fixZIndex() {
        if (this.konvaElement) {
            this.fixZIndexCustom(this.konvaElement.zIndex());
        }
    }

    protected fixZIndexCustom(canvasElZIndex: number) {
        this.zIndex = canvasElZIndex;
    }

    public changeZIndex(zIndex: number) {
        this.zIndex = zIndex;
        this.changeZIndexCustom(zIndex);
        this.configurationChanged();
    }

    protected hideCustom() {
        return null;
    }
    protected showCustom() {
        return null;
    }

    public hide() {
        let timeOut = 0;
        // If there is an out animation, wait until the animation is over and hide the element after that
        if (
            [KonvaAnimationDirection.ANIMATE_IN_OUT].includes(this.animationDirection) &&
            this.animationTypes.length > 0
        ) {
            if (this.playAnimations$.getValue()) {
                this.tween?.reverse();
                timeOut = this.defaultAnimationDuration * 1000;
            } else {
                this.tween?.reset();
            }
        } else if (
            [KonvaAnimationDirection.ANIMATE_OUT].includes(this.animationDirection) &&
            this.animationTypes.length > 0
        ) {
            if (this.playAnimations$.getValue()) {
                this.tween?.play();
                timeOut = this.defaultAnimationDuration * 1000;
            } else {
                this.tween?.finish();
            }
        }
        this.isVisible$.next(false);
        this.isEditActive$.next(false);
        this.hideCustom();
        setTimeout(() => {
            if (!this.isVisible$.getValue()) {
                this.konvaElement?.hide();
                this.transformer?.hide();
                this.layer?.draw();
            }
        }, timeOut);
    }

    public show() {
        if (this.forceHide) {
            return;
        }
        this.tween?.reset();
        this.isVisible$.next(true);
        this.showCustom();
        this.konvaElement.show();
        this.layer?.draw();
        if (
            [KonvaAnimationDirection.ANIMATE_IN, KonvaAnimationDirection.ANIMATE_IN_OUT].includes(
                this.animationDirection
            )
        ) {
            if (this.playAnimations$.getValue()) {
                this.tween?.play();
            } else {
                this.tween?.finish();
            }
        }
    }

    public updateMinScale(minScale: number) {
        this.minScale = minScale;
    }

    public getPreviousDragStartPosition(): { x: number; y: number } {
        const prevPos = this.previousDragStartPosition;
        this.previousDragStartPosition = null;
        return prevPos;
    }

    public setPreviousDragStartPosition() {
        this.previousDragStartPosition = this.konvaElement?.getPosition();
    }

    public updatePosition(position: { x: number; y: number }) {
        this.konvaElement?.x(position.x);
        this.konvaElement?.y(position.y);
        this.updatePositionCustom();
        this.configurationChanged();
    }

    public updateTemplate(templateId: string) {
        this.templateId = templateId;
        this.configurationChanged();
    }

    protected updatePositionCustom() {
        //TODO: implement
    }

    public getPreviousTransformAttributes(): { scaleX: number; scaleY: number; rotation: number } {
        const prevTransformAttrs = this.previousTransformAttributes;
        this.previousTransformAttributes = null;
        return prevTransformAttrs;
    }

    public getTransformAttributes(): { scaleX: number; scaleY: number; rotation: number } {
        return {
            scaleX: this.konvaElement?.scaleX(),
            scaleY: this.konvaElement?.scaleY(),
            rotation: this.konvaElement?.rotation()
        };
    }

    public setPreviousTransformAttributes() {
        this.previousTransformAttributes = this.getTransformAttributes();
    }

    public updateTransformAttributes(transform: { scaleX: number; scaleY: number; rotation: number }) {
        this.konvaElement?.scaleX(transform.scaleX);
        this.konvaElement?.scaleY(transform.scaleY);
        this.konvaElement?.rotation(transform.rotation);
        this.updateTransformAttributesCustom();
        this.configurationChanged();
    }

    protected updateTransformAttributesCustom() {
        return null;
    }

    public resetIfOutOfStage(
        stageWidth: number,
        stageHeight: number,
        resetElement = true,
        stage?: Konva.Stage,
        scaleByStage = false
    ): boolean {
        stage = stage || this.layer?.getStage();
        if (!stage) {
            return null;
        }
        let box = this.konvaElement.getClientRect();
        if (scaleByStage) {
            box = {
                x: box.x * stage.scaleX(),
                y: box.y * stage.scaleY(),
                width: box.width * stage.scaleX(),
                height: box.height * stage.scaleY()
            };
        }
        // Scale stage dimensions to actual dimensions on the screen - this corresponds to the dimensions of the client rect
        stageWidth *= stage.scaleX();
        stageHeight *= stage.scaleY();

        let outOfStage = false;
        if (box.x + 20 > stageWidth) {
            outOfStage = true;
        }
        if (box.x + box.width < 20) {
            outOfStage = true;
        }
        if (box.y + box.height < 20) {
            outOfStage = true;
        }
        if (box.y + 20 > stageHeight) {
            outOfStage = true;
        }
        if (outOfStage && resetElement) {
            this.konvaElement.x(0);
            this.konvaElement.y(0);
            this.konvaElement.rotation(0);
            this.resetOutOfStageCustom();
            this.layer?.draw();
        }
        return outOfStage;
    }

    protected resetOutOfStageCustom() {
        return null;
    }

    protected deleteCustom() {
        return null;
    }

    public delete() {
        this.isVisible$.next(false);
        this.isEditActive$.next(false);
        this.konvaElement?.remove();
        this.transformer?.remove();
        this.deleteCustom();
        this.layer?.draw();
    }

    initializeCanvasElementAnimations(
        animationTypes?: KonvaAnimationType[],
        animationDirection?: KonvaAnimationDirection,
        onlyPreview = false
    ) {
        this.previewTween?.finish();
        const previousAnimationDirection = this.animationDirection;
        const previousAnimationTypes = this.animationTypes;

        this.animationDirection = animationDirection || this.animationDirection;
        this.animationTypes = animationTypes || this.animationTypes;
        if ([undefined, null].includes(this.animationDirection) || [undefined, null].includes(this.animationTypes)) {
            return;
        }

        let animateIn = true;
        switch (this.animationDirection) {
            case KonvaAnimationDirection.ANIMATE_IN:
                break;
            case KonvaAnimationDirection.ANIMATE_OUT:
                animateIn = false;
                break;
            case KonvaAnimationDirection.ANIMATE_IN_OUT:
                break;
        }

        // Loop through all animations, set konvaElement attributes if needed and get the correct properties for the tween
        const allAnimationAttributes = [];
        const currentScaleX = this.konvaElement.scaleX();
        const currentScaleY = this.konvaElement.scaleY();
        for (const animationType of this.animationTypes) {
            switch (animationType) {
                case KonvaAnimationType.OPACITY:
                    this.konvaElement.opacity(animateIn ? 0 : 1);
                    allAnimationAttributes.push({ opacity: animateIn ? 1 : 0 });
                    break;
                case KonvaAnimationType.SCALE:
                    this.konvaElement.scaleX(animateIn ? 0 : currentScaleX);
                    this.konvaElement.scaleY(animateIn ? 0 : currentScaleY);
                    allAnimationAttributes.push({ scaleX: animateIn ? currentScaleX : 0 });
                    allAnimationAttributes.push({ scaleY: animateIn ? currentScaleY : 0 });
                    break;
                case KonvaAnimationType.MOVE_UP:
                    this.konvaElement.opacity(animateIn ? 0 : 1);
                    this.konvaElement.y(animateIn ? 52 : 0);
                    allAnimationAttributes.push({ opacity: animateIn ? 1 : 0, y: animateIn ? 0 : 52 });
                    break;
            }
        }

        const tweenPartConfig = {
            node: this.konvaElement,
            duration: this.defaultAnimationDuration,
            easing: Konva.Easings.Linear
        };

        if (onlyPreview) {
            this.previewTween = new Konva.Tween(Object.assign(tweenPartConfig, ...allAnimationAttributes));
        } else {
            this.tween = new Konva.Tween(Object.assign(tweenPartConfig, ...allAnimationAttributes));
        }

        // Change everything back so that nothing flashes or disappears
        for (const animationType of this.animationTypes) {
            switch (animationType) {
                case KonvaAnimationType.OPACITY:
                    this.konvaElement.opacity(1);
                    break;
                case KonvaAnimationType.SCALE:
                    this.konvaElement.scaleX(currentScaleX);
                    this.konvaElement.scaleY(currentScaleY);
                    break;
                case KonvaAnimationType.MOVE_UP:
                    this.konvaElement.opacity(1);
                    this.konvaElement.y(0);
                    break;
            }
        }
        setTimeout(() => {
            if (onlyPreview) {
                this.playAnimationCustom();
                this.previewTween.play();
                this.animationDirection = previousAnimationDirection;
                this.animationTypes = previousAnimationTypes;
            }
        }, 50);
    }
}
