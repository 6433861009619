import { OverlayConfig } from '@angular/cdk/overlay/overlay-config';
import { ComponentType } from '@angular/cdk/overlay';
import { TemplateRef, Type } from '@angular/core';
import { ThemingColorType, ThemingSizeType } from '@type/ui/models';

export type DialogContentType = string | TemplateRef<any> | Type<any>;

export type ScrollAction = 'close' | 'none' | 'block' | 'reposition';

export interface DialogConfig<T = any> extends OverlayConfig {
    backdropClass?: string;
    hasBackDrop?: boolean;
    data?: T;
    disableClose?: boolean;
    noAutoFocus?: boolean;
    scrollAction?: ScrollAction;
    defaultPosition?: DialogPositionsType;
    panelClass?: string[];
    offsetX?: number;
    offsetY?: number;
    allowHotkeys?: boolean;
    timeToLive?: number; // For toast
    goBackToComponent?: ComponentType<any>; // For dialog
    positionStrategyType?: 'global' | 'flexible';
    autoFocusInput?: boolean;
}

export const DialogPositions = {
    TOP: 'top',
    RIGHT: 'right',
    LEFT: 'left',
    INSIDE_LEFT: 'inside-left',
    BOTTOM: 'bottom',
    START_BOTTOM: 'start-bottom'
} as const;

export type DialogPositionsType = typeof DialogPositions[keyof typeof DialogPositions];

export class ToastDataModel {
    message: string;
    iconSrc?: string;
    actionLabel?: string;
    callBack?: () => void;
    buttonColor?: ThemingColorType;
    buttonSize?: ThemingSizeType;
}

export class TutorialDataModel {
    videoUri: string;
}
