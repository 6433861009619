import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { DialogService } from '../dialog.service';
import { OverlayComponentType } from '../overlay-component.model';
import { DialogConfig, DialogPositionsType } from '../dialog.models';
import { DialogRef } from '../dialog-ref';

@Directive({ selector: '[appTriggerFor]' })
export class OverlayTriggerForDirective implements OnDestroy {
    @Input() appTriggerFor: OverlayComponentType;
    @Input() disabled: boolean;
    @Input() preferredPosition: DialogPositionsType;
    @Input() dialogConfig: DialogConfig;
    ref: DialogRef;

    @HostListener('click')
    openPopover() {
        if (this.disabled) {
            return;
        }
        if (this.appTriggerFor.isOpen) {
            this.ref.close();
            this.ref = null;
        } else {
            this.ref = this.dialogService.openPopover(this.appTriggerFor.templateRef, this._el, {
                hasBackDrop: false,
                minWidth: this._el.nativeElement.scrollWidth,
                defaultPosition: this.preferredPosition,
                ...this.dialogConfig
            });
            this.appTriggerFor.startOpen(this.ref);
            if (this.dialogConfig?.autoFocusInput) {
                // If there is an input in the dialog, autofocus it if possible
                const openedHtmlDialog: HTMLElement = (this.ref.overlayRef as any)._pane;
                setTimeout(() => {
                    openedHtmlDialog.getElementsByTagName('input')[0]?.focus();
                }, 200);
            }
        }
    }

    constructor(private dialogService: DialogService, private _el: ElementRef) {}

    ngOnDestroy() {
        this.ref?.close();
    }
}
