import { Injectable } from '@angular/core';
import { AnalyticsEvents } from '@type/shared-models/consts/analytic-events';
import { FbProject, TranscriptionState } from '@type/shared-models/project';
import paths from '@type/shared-models/consts/firebase-paths';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FirebaseFunctionNames } from '@type/shared-models/consts/firebase-function-names';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { LoadFromUrlRequestData } from '@type/shared-models/transcription/link-upload-request-data.models';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import {
    DurationAudioError,
    DurationVideoError,
    ExtensionFileError,
    FilesizeFileError,
    UploadError,
    UploadErrorElement,
    UrlImportGeneralError,
    UsageLimitationReachedError,
    YouTubeChannelError,
    YouTubeLiveError,
    YouTubeNetworkError,
    YouTubeUrlError
} from '@type/shared-models/consts/error-messages';
import { Resolution } from '@type/shared-models/resolution';
import {
    ImportWebsite,
    WebsiteImport,
    WebsiteImportDomains,
    WebsiteImports
} from '@type/shared-models/consts/url-imports';
import { AnalyticsService } from './analytics.service';
import { filter, first, map, shareReplay } from 'rxjs/operators';
import { MediaModel, MediaType } from '@type/shared-models/media/media.utils';
import { MediaMetadata } from '@type/shared-models/media/media-metadata.models';
import { MediaUploadMetadata } from '@type/shared-models/media-upload-metadata.models';
import { changeExtension, generateUID } from '@type/shared-models/utils/file-utility';
import { environment } from '../../environments/environment';
import { EditorService } from './editor.service';
import { UserService } from './user.service';
import { videoToAudio } from '../utils/audio-video.utils';
import { toFixedTrunc } from '@type/shared-models/shared-utils/time-utils';
import { CanvasElementType } from '@type/shared-models/canvas-elements';
import { VideoElement } from '../models/canvas/video-element';
import { isBraveBrowser } from '../utils/browser-utils';

export class MediaUpload {
    uploadProgress: Observable<number>;
    mediaDuration: number;
}

class UploadQueue {
    [projectId: string]: MediaUpload;
}

export interface UploadResult {
    limitReached: boolean;
    uploadTask: AngularFireUploadTask;
    mediaDuration: number;
    initialIndex: number;
    targetUri?: string;
    file?: File;
    failed?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class UploadService {
    taskList$ = new BehaviorSubject<UploadQueue>({});

    websiteImports = WebsiteImports;
    websiteImportDomains = WebsiteImportDomains;

    limitations$ = this.userService.limitations$;
    // Maximum Video Length
    mediaType: MediaType;

    extensionError$ = new BehaviorSubject<UploadError>(UploadError.DEFAULT);
    durationError$ = new BehaviorSubject<UploadError>(UploadError.DEFAULT);
    filesizeError$ = new BehaviorSubject<UploadError>(UploadError.DEFAULT);
    errorMessage$ = new BehaviorSubject<UploadErrorElement>(null);
    urlErrorMessage$ = new BehaviorSubject<string>(null);
    lastUploadedExtension: string;
    errorCount$ = new BehaviorSubject<number>(0);
    urlUploadError$ = new BehaviorSubject<UploadErrorElement>(null);
    showRecordingsLimitReached$ = new BehaviorSubject<boolean>(false);

    constructor(
        private userService: UserService,
        private angularFireStorage: AngularFireStorage,
        private angularFirestore: AngularFirestore,
        private fireFunctions: AngularFireFunctions,
        private storage: AngularFireStorage,
        private analyticsService: AnalyticsService,
        private editorService: EditorService
    ) {
        this.taskList$.subscribe((tasks) => {
            if (Object.keys(tasks).length) {
                window.onbeforeunload = (e) => {
                    e.returnValue = 'Are you sure?!';
                    return 'Upload in-progress! Are you sure you want to exit?';
                };
            } else {
                window.onbeforeunload = null;
            }
        });
    }

    getProjectDocumentReference(projectId: string) {
        return this.angularFirestore.collection(paths.PROJECTS).doc<FbProject>(projectId);
    }

    loadVideoFromUrl(
        projectId: string,
        url: string,
        importWebsite: ImportWebsite,
        initialIndex = 0,
        stopAfterUpload: boolean,
        mainMediaUpload: boolean
    ) {
        const loadFromUrlRequestData: LoadFromUrlRequestData = {
            userId: this.userService.user.firebaseId,
            projectId,
            importWebsite,
            url,
            initialIndex,
            stopAfterUpload,
            mainMediaUpload
        };
        return this.fireFunctions.httpsCallable(FirebaseFunctionNames.linkUpload, { timeout: 540 * 1000 })(
            loadFromUrlRequestData
        );
    }

    /**
     * Starts the file upload
     *
     * @param file file to upload
     * @param mediaDuration duration of the file in seconds, used for analytics only
     * @param isRecording whether the file is a screen recording or a file uploaded by the user
     * @param recordingLimitReached if true it shows an info modal which explains that the upload has started automatically due to the reached limit
     */
    async startUpload(
        file: File,
        mediaDuration: number,
        isRecording = false,
        recordingLimitReached = false,
        initialIndex = 0,
        mainMediaUpload: boolean,
        stopAfterUpload: boolean,
        multilaneMediaUpload?: boolean,
        resolution?: Resolution,
        mobileUpload?: boolean
    ): Promise<{ uploadTask: AngularFireUploadTask; videoUri: string }> {
        this.editorService.showPlaceHolderVideo$.next(false);
        const projectId = this.editorService.currentProject.id;
        const userId = this.userService.user.firebaseId;
        // The projectUri
        const projectUri = `${paths.VIDEOS}/${userId}/${projectId}/`;

        let originalFileName = file.name;
        if (originalFileName.length > 100) {
            const suffix = originalFileName.split('.').pop();
            originalFileName = originalFileName.slice(0, 100) + '.' + suffix;
        }
        let newFilename = generateUID() + '_' + originalFileName;
        newFilename = newFilename.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        const fileType = file.type.split('/').length > 1 ? file.type.split('/')[1] : file.type.split('/')[0];

        // this.editorService.updateMediaType(this.mediaType);

        this.analyticsService.trackServerside(AnalyticsEvents.MEDIA_UPLOAD_STARTED, this.userService.user.firebaseId, {
            projectId,
            videoDuration: mediaDuration,
            fileSize: (file.size / 1048576).toFixed(2),
            fileType,
            isMobile: stopAfterUpload
        });

        // Validate file extension. Skip if it is recording
        const mediaType = file.type.split('/')[0].toLocaleLowerCase();
        if (!isRecording) {
            // The File object

            // Client-side validation example
            if (!['video', 'audio'].includes(mediaType)) {
                console.error('unsupported file type :( ');
                return;
            }
        } else if (recordingLimitReached) {
            this.showRecordingsLimitReached$.next(true);
        }

        const videoUri = projectUri + newFilename;
        const videoSize = +toFixedTrunc(file.size / 1024 / 1024, 2);
        const mbPerMinute = videoSize / (mediaDuration / 60);

        // Totally optional metadata
        const customMetadata: MediaUploadMetadata = {
            projectId,
            userId,
            initialIndex: String(initialIndex) ?? null,
            stopAfterUpload,
            multilaneMediaUpload,
            resolution: JSON.stringify(resolution),
            mainMediaUpload
        };

        const splitAudio = false;
        // mainMediaUpload &&
        // !mobileUpload &&
        // mediaType.includes('video') &&
        // mbPerMinute > 2 &&
        // videoSize < 256 &&
        // !(await isBraveBrowser());

        if (splitAudio) {
            console.log('Extract audio');
            const copiedMetadata = JSON.parse(JSON.stringify(customMetadata));
            customMetadata.skipTranscription = true;
            this.extractAndUploadAudio(file, videoUri, mediaDuration, copiedMetadata);
        } else {
            console.log("Don't extract audio");
        }
        // return;

        // The main task
        console.log('Start video upload');

        console.time('Finish video upload (' + videoSize + 'MB)');
        const uploadTask = this.storage.upload(videoUri, file, { customMetadata: customMetadata as any });
        uploadTask
            .then(() => {
                console.timeEnd('Finish video upload (' + videoSize + 'MB)');
                console.log('Finish video upload (' + videoSize + 'MB)');

                this._removeTask(projectId);

                this.storage
                    .ref(videoUri)
                    .getDownloadURL()
                    .subscribe((downloadURL) => {
                        this.analyticsService.trackServerside(
                            AnalyticsEvents.MEDIA_UPLOAD_COMPLETED,
                            this.userService.user.firebaseId,
                            {
                                videoUrl: downloadURL,
                                projectId,
                                videoDuration: mediaDuration,
                                fileSize: (file.size / 1048576).toFixed(2),
                                fileType,
                                isMobile: stopAfterUpload,
                                mainMediaUpload
                            }
                        );
                    });
            })
            .catch((e) => console.error(e));
        this._addTask(projectId, {
            uploadProgress: uploadTask.percentageChanges(),
            mediaDuration
        });
        return { uploadTask, videoUri };
    }

    async extractAndUploadAudio(
        file: File,
        videoUri: string,
        mediaDuration: number,
        customMetadata: MediaUploadMetadata
    ) {
        console.log('Start audio extraction');
        console.time('Finish audio extraction');
        const convertedAudioDataObj = await videoToAudio(file, 'mp3');
        console.timeEnd('Finish audio extraction');
        console.log('Finish audio extraction');
        const audioUri = changeExtension(videoUri, 'mp3');
        const taskId = 'audio-' + customMetadata.projectId;
        const audioSize = +toFixedTrunc(convertedAudioDataObj.data.size / 1024 / 1024, 2);

        console.log('Start audio upload');
        console.time('Finish audio upload (' + audioSize + 'MB)');
        const uploadTask = this.storage.upload(audioUri, convertedAudioDataObj.data, {
            customMetadata: { ...(customMetadata as any), extractedAudio: true }
        });
        uploadTask.then(() => {
            console.timeEnd('Finish audio upload (' + audioSize + 'MB)');
            console.log('Finish audio upload (' + audioSize + 'MB)');
            this._removeTask(taskId);
        });

        this._addTask(taskId, {
            uploadProgress: uploadTask.percentageChanges(),
            mediaDuration
        });
    }

    /**
     * e.g. for YouTube check that link isn't a channel or livestream
     *
     */
    preUploadUrlCheck(website: WebsiteImport, url: string) {
        switch (website.name) {
            case 'YouTube':
                if (url.includes('youtube.')) {
                    this.urlErrorMessage$.next('Import of YouTube videos is currently no longer supported.');
                    return false;
                }
                if (url.includes('/channel/') || url.includes('/@')) {
                    const error = YouTubeChannelError;
                    this.urlErrorMessage$.next(error.line1 + ' ' + (error.line2 || ''));
                    return false;
                }
                break;
            default:
                return true;
                break;
        }
    }
    /**
     * Checks the url input from the user. If we support it, start the download from the corresponding platform
     *
     * @param urlInput input url string
     * @param projectId project ID string
     * @param cb callback to fire when call is complete
     */
    async checkInputUrl(
        urlInput: string,
        projectId: string,
        cb: () => void,
        initialIndex: number,
        mainMediaUpload: boolean,
        stopAfterUpload: boolean,
        fromSlStorage?: boolean,
        isMobile = false
    ): Promise<UploadResult> {
        this.resetUploadErrors();

        // This is needed if a user inserts a link with invalid file format and retries the url import (without the progress bar is flickering)
        // If supported
        for (const website of this.websiteImports) {
            if (this.preUploadUrlCheck(website, urlInput) === false) {
                return;
            }

            if (website.regex.test(urlInput) || (fromSlStorage && website.service === ImportWebsite.STREAMLABS)) {
                console.log('FOUND ', website.name, ' URL');

                const uploadProgress = interval(100).pipe(
                    map((progress) => progress / 4),
                    filter((progress) => progress <= 100),
                    shareReplay({ bufferSize: 1, refCount: false })
                );
                this._addTask(projectId, {
                    uploadProgress,
                    mediaDuration: 40
                });
                this.editorService.transcriptionState$.next(TranscriptionState.UPLOADING);

                if (!isMobile && mainMediaUpload) {
                    this.editorService.showPlaceHolderVideo$.next(true);
                    await this.editorService.initLocalMediaFile(environment.placeholderVideoUrl);
                }
                // This is a guess at the time it takes;
                this.loadVideoFromUrl(
                    projectId,
                    urlInput,
                    website.service,
                    initialIndex,
                    stopAfterUpload,
                    mainMediaUpload
                ).subscribe(
                    (response) => {
                        this._removeTask(projectId);
                        cb();

                        if (response.message === 'Ok' && response.mediaUri) {
                            this.editorService.showPlaceHolderVideo$.next(false);
                            // const mediaUri = response.mediaUri;
                            // this.storage
                            //     .ref(mediaUri)
                            //     .getDownloadURL()
                            //     .subscribe((mediaUrl) => {
                            //         // if (!isMobile && mainMediaUpload) {
                            //         // this.editorService.initLocalMediaFile(mediaUrl, null, true);
                            //         //     this.editorService.showPlaceHolderVideo$.next(null);
                            //         //     const canvasElement = this.editorService.videoEditor.canvasElements$.value.find(
                            //         //         (currentCanvasElement) =>
                            //         //             currentCanvasElement.ID !== 'video' &&
                            //         //             currentCanvasElement.type === CanvasElementType.MEDIA
                            //         //     ) as VideoElement;
                            //         //     if (canvasElement) {
                            //         //         canvasElement.mediaUrl = mediaUrl;
                            //         //     } else {
                            //         //         console.log('🚧❌ Canvas element not found');
                            //         //     }
                            //         // }
                            //     });
                        } else {
                            if (!isMobile && mainMediaUpload) {
                                this.editorService.removePlaceholderVideo();
                            }
                            this.editorService.transcriptionState$.next(TranscriptionState.FAILED);
                            switch (JSON.stringify(response.error)) {
                                case JSON.stringify(ExtensionFileError): {
                                    this.extensionError$.next(UploadError.ERROR);
                                    const error = this.userService.getErrorWithUserLimits('ExtensionFileError');
                                    this.urlErrorMessage$.next(error.line1 + ' ' + (error.line2 || ''));
                                    break;
                                }
                                case JSON.stringify(YouTubeNetworkError):
                                    this.urlErrorMessage$.next(
                                        response.error.line1 + ' ' + (response.error.line2 || '')
                                    );
                                    break;
                                case JSON.stringify(YouTubeUrlError):
                                case JSON.stringify(UrlImportGeneralError):
                                    this.urlErrorMessage$.next(response.error.title + ' ' + response.error.line1);
                                    break;
                                case JSON.stringify(DurationVideoError):
                                    this.durationError$.next(UploadError.ERROR);
                                    this.urlUploadError$.next(
                                        this.userService.getErrorWithUserLimits('DurationVideoError')
                                    );
                                    break;
                                case JSON.stringify(DurationAudioError):
                                    this.durationError$.next(UploadError.ERROR);
                                    this.urlUploadError$.next(
                                        this.userService.getErrorWithUserLimits('DurationAudioError')
                                    );
                                    break;
                                case JSON.stringify(UsageLimitationReachedError):
                                    this.urlUploadError$.next(
                                        this.userService.getErrorWithUserLimits('UsageLimitationReachedError')
                                    );
                                    break;
                                case JSON.stringify(FilesizeFileError):
                                    this.filesizeError$.next(UploadError.ERROR);
                                    this.urlUploadError$.next(
                                        this.userService.getErrorWithUserLimits('FilesizeFileError')
                                    );
                                    // this.transcriptionService.uploadProgressTracker.setError();
                                    break;
                                default:
                                    this.urlErrorMessage$.next('Unknown error occurred. Please try again later.');
                                // TODO: I don't know what this error is, nor how to display it.
                                // this.transcriptionError = response.error || null;
                                // this.transcriptionService.uploadProgressTracker.setError();
                                // this.transcriptionService.totalProgressTracker.setError();
                            }
                        }
                    },
                    // TODO: Currently we are not able to retrieve the error message here. Check linkUpload.ts for corresponding TODO. Current workaround is sending a 200 from the server containing an error
                    (error) => {
                        console.log('Error link import', error);
                        this._removeTask(projectId);
                        this.urlErrorMessage$.next('Unknown error occurred.');
                    }
                );
                return { initialIndex, limitReached: false, mediaDuration: 40, uploadTask: null };
            }
        }

        // Not supported. If website readable show custom error message containing the website name. Also check if website is supported in general but link is broken
        let url;
        try {
            url = new URL(urlInput);
            // hostname not found, link probably not a correct url
            if (!url.hostname) {
                this.analyticsService.track(AnalyticsEvents.LINK_UPLOAD_NOT_SUPPORTED, { unreadable_url: urlInput });
                this.urlErrorMessage$.next('Something seems to be wrong with your link.');
            }
            // We know the domain and generally support it but the link is private or broken
            else if (this.websiteImportDomains.includes(url.hostname)) {
                this.urlErrorMessage$.next(
                    'Sorry, the link seems to be private or broken. Please make sure to copy the correct link.'
                );
            }
            // We don't support the website
            else {
                this.analyticsService.track(AnalyticsEvents.LINK_UPLOAD_NOT_SUPPORTED, { url: url.hostname });
                this.urlErrorMessage$.next('Sorry, we are currently not supporting direct import from ' + url.hostname);
            }
        } catch (e) {
            // url not readable
            this.analyticsService.track(AnalyticsEvents.LINK_UPLOAD_NOT_SUPPORTED, { unreadable_url: urlInput });
            this.urlErrorMessage$.next('Sorry, we are not supporting this kind of link.');
        }
        return null;
    }

    async checkFileAndStartUploadProcess(
        event: FileList,
        initialIndex = 0,
        mainMediaUpload: boolean,
        stopAfterUpload: boolean,
        multilaneUpload?: boolean,
        mobileUpload?: boolean
    ): Promise<UploadResult> {
        if (event.item.length === 1) {
            const file = event.item(0);
            let errorCount = 0;
            let duration: number;
            let resolution: Resolution;
            try {
                const metadata = await this.getFileMediaMetadata(file);
                duration = metadata.duration;
                resolution = metadata.resolution;
            } catch (error) {
                errorCount += 1;
                this.errorMessage$.next(this.userService.getErrorWithUserLimits('InvalidFileError'));
                return {
                    failed: true,
                    uploadTask: null,
                    initialIndex,
                    limitReached: false,
                    mediaDuration: duration
                };
            }
            this.mediaType = await MediaModel.getMediaTypeFromFile(file);

            if (this.mediaType === MediaType.VIDEO && !this.checkResolution(resolution)) {
                errorCount += 1;
            }
            const durationNotWithinLimits = this.checkDuration(duration);
            if (!durationNotWithinLimits) {
                errorCount += 1;
            }
            if (!this.checkFilesize(file)) {
                errorCount += 1;
            }
            if (!this.checkFileExtension(file)) {
                errorCount += 1;
            }
            let hasUsageLimitationError;
            if (durationNotWithinLimits && !this.checkUsageLimitation(duration) && !multilaneUpload) {
                errorCount += 1;
                hasUsageLimitationError = true;
            }

            this.errorCount$.next(errorCount);

            if (hasUsageLimitationError) {
                return {
                    limitReached: true,
                    uploadTask: null,
                    mediaDuration: duration,
                    initialIndex
                };
            }

            if (errorCount === 0) {
                console.log('All okay');

                const { uploadTask, videoUri } = await this.startUpload(
                    file,
                    duration,
                    false,
                    false,
                    initialIndex,
                    mainMediaUpload,
                    stopAfterUpload,
                    multilaneUpload,
                    resolution,
                    mobileUpload
                );
                return {
                    limitReached: false,
                    uploadTask,
                    mediaDuration: duration,
                    initialIndex,
                    targetUri: videoUri,
                    file
                };
            }
        }
    }

    async uploadBugFile(event: FileList) {
        if (event.item.length === 1) {
            const file = event.item(0);
            const userId = this.userService.user.firebaseId;
            const filePathInStorage = `${paths.SUPPPORT}/`;
            const { duration } = await this.getFileMediaMetadata(file);

            let originalFileName = file.name;
            if (originalFileName.length > 100) {
                const suffix = originalFileName.split('.').pop();
                originalFileName = originalFileName.slice(0, 100) + '.' + suffix;
            }
            let newFilename = userId + '_' + generateUID() + '_' + originalFileName;
            newFilename = newFilename.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

            const videoUri = filePathInStorage + newFilename;
            console.log(videoUri);
            // Totally optional metadata
            const customMetadata = { userId, originalFileName };
            // The main task
            const task = this.storage.upload(videoUri, file, { customMetadata });
            return { task, duration };
        }
    }

    resetUploadErrors() {
        this.extensionError$.next(UploadError.DEFAULT);
        this.durationError$.next(UploadError.DEFAULT);
        this.filesizeError$.next(UploadError.DEFAULT);
        this.urlErrorMessage$.next(null);
        this.urlUploadError$.next(null);
        this.errorMessage$.next(null);
        this.errorCount$.next(0);
        this.showRecordingsLimitReached$.next(false);
    }

    checkUsageLimitation(videoDuration: number) {
        if (!this.userService.user$.value.subscriptionState.eligibleToUpload(videoDuration)) {
            window.analytics.track(AnalyticsEvents.ERROR_USAGE_EXPIRED);
            console.log(AnalyticsEvents.ERROR_USAGE_EXPIRED);
            return false;
        }
        return true;
    }

    checkDuration(mediaDuration: number) {
        if (mediaDuration > this.limitations$.value.length) {
            window.analytics.track(AnalyticsEvents.ERROR_UPLOADED_VIDEO_TOO_LONG, {
                duration: mediaDuration
            });
            console.log(AnalyticsEvents.ERROR_UPLOADED_VIDEO_TOO_LONG);
            if (this.mediaType === MediaType.AUDIO) {
                this.errorMessage$.next(this.userService.getErrorWithUserLimits('DurationAudioError'));
            } else {
                this.errorMessage$.next(this.userService.getErrorWithUserLimits('DurationVideoError'));
            }
            this.durationError$.next(UploadError.ERROR);
            return false;
        }
        this.durationError$.next(UploadError.SUCCESS);
        return true;
    }

    checkFileExtension(file: File) {
        const extension = '.' + file.name.split('.').pop();
        this.lastUploadedExtension = extension;

        console.log('🚀 ~ extension', extension);

        if (
            !this.limitations$.value.extensions.audio
                .concat(this.limitations$.value.extensions.video)
                .includes(extension.toLowerCase())
        ) {
            window.analytics.track(AnalyticsEvents.ERROR_INVALID_FILE_EXTENSION, {
                extension
            });
            console.log(AnalyticsEvents.ERROR_INVALID_FILE_EXTENSION);
            this.errorMessage$.next(
                this.userService.getErrorWithUserLimits(
                    this.mediaType === MediaType.AUDIO
                        ? 'ExtensionAudioError'
                        : this.mediaType === MediaType.VIDEO
                        ? 'ExtensionVideoError'
                        : 'ExtensionFileError'
                )
            );
            this.extensionError$.next(UploadError.ERROR);
            return false;
        }

        this.extensionError$.next(UploadError.SUCCESS);
        return true;
    }

    checkFilesize(file: File) {
        if (file.size / 1048576 > this.limitations$.value.size) {
            window.analytics.track(AnalyticsEvents.ERROR_INVALID_FILE_SIZE, {
                filesize: (file.size / 1048576).toFixed(2)
            });
            console.log(AnalyticsEvents.ERROR_INVALID_FILE_SIZE);
            if (this.mediaType === MediaType.AUDIO) {
                this.errorMessage$.next(this.userService.getErrorWithUserLimits('FilesizeAudioError'));
            } else {
                this.errorMessage$.next(this.userService.getErrorWithUserLimits('FilesizeVideoError'));
            }
            this.filesizeError$.next(UploadError.ERROR);
            return false;
        }
        this.filesizeError$.next(UploadError.SUCCESS);
        return true;
    }

    checkResolution(_: Resolution) {
        // Return false for every wrong cases
        // of no case matches return true at the end
        // if (resolution.width > 1920 || resolution.width > 1920) {
        //     window.analytics.track(AnalyticsEvents.ErrorInvalidResolution, {
        //         resolution
        //     });
        //     console.log(AnalyticsEvents.ErrorInvalidResolution);
        //     this.openResolutionErrorModal();
        //     return false;
        // }

        return true;
    }

    /**
     * Returns a string of file extensions. Extension changes based on a few things
     */
    getFileExtensionLimitString(): string {
        if (this.limitations$.value.extensions.audio.includes(this.lastUploadedExtension)) {
            return '.mp4 & ' + this.lastUploadedExtension;
        } else if (this.limitations$.value.extensions.video.includes(this.lastUploadedExtension)) {
            return this.lastUploadedExtension + ' & .mp3';
        } else {
            return '.mp4 & .mp3';
        }
    }

    private _addTask(projectId: string, progressTracker: MediaUpload) {
        this.taskList$.next({ ...this.taskList$.value, [projectId]: progressTracker });
    }

    private _removeTask(projectId: string) {
        const newTaskSet = { ...this.taskList$.value };
        delete newTaskSet[projectId];
        this.taskList$.next(newTaskSet);
    }

    removeCurrentProjectTask() {
        this._removeTask(this.editorService.currentProject.id);
    }
    getFileMediaMetadata(mediaFile: File, url?: string): Promise<MediaMetadata> {
        return new Promise((resolve, reject) => {
            let timeout;
            const video = document.createElement('video');
            video.preload = 'metadata';

            video.addEventListener(
                'loadedmetadata',
                () => {
                    if (timeout) {
                        clearTimeout(timeout);
                    }

                    // retrieve dimensions and duration
                    const resolution = { width: video.videoWidth, height: video.videoHeight };
                    const duration = video.duration;
                    const mediaType = video.videoHeight && video.videoWidth ? MediaType.VIDEO : MediaType.AUDIO;

                    console.log('done', { duration, resolution: JSON.stringify(resolution) });
                    resolve({ duration, resolution, mediaType });
                },
                false
            );

            if (mediaFile || url) {
                video.src = mediaFile ? URL.createObjectURL(mediaFile) : url;
                timeout = setTimeout(() => {
                    console.log('done null');
                    reject('videoFile is invalid');
                }, 4000);
            } else {
                console.log("🚀 ~ 'videoFile is undefined'", 'videoFile is undefined');
                reject('videoFile is undefined');
            }
        });
    }
}
