import { Component, HostListener } from '@angular/core';
import { DialogRef } from '@type/dialog';

@Component({
    selector: 'app-shortcut-list',
    templateUrl: './shortcut-list.component.html',
    styleUrls: ['./shortcut-list.component.scss']
})
export class ShortcutListComponent {
    hotkeysVideoEditingLeft: { title: string; keys: string[] }[];
    hotkeysTextCorrection: { title: string; keys: string[] }[];
    hotkeysVideoEditingRight: { title: string; keys: string[] }[];
    hotkeysMouse: { title: string; keys: string[] }[];

    @HostListener('document:keydown.h', ['$event'])
    keyHandler(event: KeyboardEvent) {
        event.stopImmediatePropagation();
        this.ref.close();
    }

    constructor(private ref: DialogRef) {
        this.hotkeysVideoEditingLeft = [
            {
                title: 'Toggle playback',
                keys: ['space']
            },
            {
                title: 'Delete selection',
                keys: ['backspace']
            },
            {
                title: 'Skip forward/backward',
                keys: ['→', '←']
            },
            {
                title: 'Multiselect words',
                keys: ['shift', '→', '←']
            },
            {
                title: 'Deselect',
                keys: ['esc']
            },
            {
                title: 'Copy',
                keys: [(window as any).isMac ? 'cmd' : 'ctrl', 'c']
            },
            {
                title: 'Cut',
                keys: [(window as any).isMac ? 'cmd' : 'ctrl', 'x']
            },
            {
                title: 'Export clip',
                keys: [(window as any).isMac ? 'cmd' : 'ctrl', 'shift', 's']
            }
        ];

        this.hotkeysVideoEditingRight = [
            {
                title: 'Add text',
                keys: ['t']
            },
            {
                title: 'Add image',
                keys: ['i']
            },
            {
                title: 'Add element',
                keys: ['e']
            },
            {
                title: 'Undo',
                keys: [(window as any).isMac ? 'cmd' : 'ctrl', 'z']
            },
            {
                title: 'Redo',
                keys: [(window as any).isMac ? 'cmd' : 'ctrl', 'shift', 'z']
            },
            {
                title: 'Paste',
                keys: [(window as any).isMac ? 'cmd' : 'ctrl', 'v']
            },
            {
                title: 'Duplicate',
                keys: [(window as any).isMac ? 'cmd' : 'ctrl', 'd']
            }
        ];

        this.hotkeysTextCorrection = [
            {
                title: 'Toggle text correction',
                keys: ['shift', 'return']
            },
            {
                title: 'Play video',
                keys: ['shift', 'space']
            },
            {
                title: 'Skip forward/backward',
                keys: [(window as any).isMac ? 'option' : 'alt', '→', '←']
            },
            {
                title: 'Linebreak',
                keys: ['return']
            },
            {
                title: 'Toggle uppercase/lowercase',
                keys: ['c']
            },
            {
                title: 'Cut text',
                keys: ['x']
            },
            {
                title: 'Add punctuation',
                keys: ['.', ',', '!', '?']
            },
            {
                title: 'Capitalization toggle',
                keys: ['q', 'mouse']
            },
            {
                title: 'Punctuation toggle',
                keys: ['w', 'mouse']
            }
        ];
    }
}
