export const enum FirebaseAuthErrorCodes {
    APP_DELETED = 'auth/app-deleted',
    APP_NOT_AUTHORIZED = 'auth/app-not-authorized',
    ARGUMENT_ERROR = 'auth/argument-error',
    INVALID_API_KEY = 'auth/invalid-api-key',
    INVALID_USER_TOKEN = 'auth/invalid-user-token',
    INVALID_TENANT_ID = 'auth/invalid-tenant-id',
    NETWORK_REQUEST_FAILED = 'auth/network-request-failed',
    OPERATION_NOT_ALLOWED = 'auth/operation-not-allowed',
    REQUIRES_RECENT_LOGIN = 'auth/requires-recent-login',
    TOO_MANY_REQUESTS = 'auth/too-many-requests',
    UNAUTHORIZED_DOMAIN = 'auth/unauthorized-domain',
    USER_DISABLED = 'auth/user-disabled',
    USER_TOKEN_EXPIRED = 'auth/user-token-expired',
    WEB_STORAGE_UNSUPPORTED = 'auth/web-storage-unsupported',
    ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIALS = 'auth/account-exists-with-different-credential',
    CREDENTIALS_ALREADY_IN_USE = 'auth/credential-already-in-use',
    EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
    AUTH_DOMAIN_CONFIG_REQUIRED = 'auth/auth-domain-config-required',
    CANCELLED_POPUP_REQUEST = 'auth/cancelled-popup-request',
    OPERATION_NOT_SUPPORTED_IN_THIS_ENVIRONMENT = 'auth/operation-not-supported-in-this-environment',
    POPUP_BLOCKED = 'auth/popup-blocked',
    POPUP_CLOSED_BY_USER = 'auth/popup-closed-by-user',
    NULL_USER = 'auth/null-user',
    TENANT_ID_MISMATCH = 'auth/tenant-id-mismatch',
    EXPIRED_ACTION_CODE = 'auth/expired-action-code',
    INVALID_ACTION_CODE = 'auth/invalid-action-code',
    USER_NOT_FOUND = 'auth/user-not-found',
    EMAIL_ALREADY_EXISTS = 'auth/email-already-exists',
    WEAK_PASSWORD = 'auth/weak-password'
}

export const AUTH_ERROR_MESSAGE_MAP = {
    [FirebaseAuthErrorCodes.WEAK_PASSWORD]: 'Password seems a bit weak. Please try another.',
    [FirebaseAuthErrorCodes.EMAIL_ALREADY_EXISTS]: 'Email already exists.'
};

export function getErrorMessage(errorCode: string) {
    if (!AUTH_ERROR_MESSAGE_MAP[errorCode]) {
        console.log(`Message for error '${errorCode}' does not exist.`);
        return;
    }
    return AUTH_ERROR_MESSAGE_MAP[errorCode];
}
