import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TypeFormComponent } from './type-form.component';
import { TypeFormService } from './type-form.service';
import { UiModule } from '@type/ui';
import { DialogModule } from '@type/dialog';

@NgModule({
    imports: [DialogModule, UiModule],
    declarations: [TypeFormComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [TypeFormService]
})
export class TypeFormModule {}
