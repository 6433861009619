import { Command } from '../command';
import { Transcription } from '@type/shared-models/editor/transcription';
import { BehaviorSubject } from 'rxjs';
import { IDatasource } from 'ngx-ui-scroll';
import { WordIndex } from '@type/shared-models/editor/word';
import { EditorService } from '../../services/editor.service';

export class LinebreakRemoveCommand implements Command {
    private readonly wordWithLinebreakIndex: number;
    private readonly previousParagraphIndex: number;
    private transcription: Transcription;
    private datasource: BehaviorSubject<IDatasource>;
    private readonly oldParagraphs: WordIndex[][];
    private replacedItemLength: number;
    private editorService: EditorService;

    constructor(linebreakRemoveObject: {
        wordIndex: number;
        paragraphIndex: number;
        transcription: Transcription;
        datasource: BehaviorSubject<IDatasource>;
        oldParagraphs: WordIndex[][];
        editorService;
    }) {
        this.wordWithLinebreakIndex = linebreakRemoveObject.wordIndex;
        this.previousParagraphIndex = linebreakRemoveObject.paragraphIndex;
        this.transcription = linebreakRemoveObject.transcription;
        this.datasource = linebreakRemoveObject.datasource;
        this.oldParagraphs = linebreakRemoveObject.oldParagraphs;
        this.editorService = linebreakRemoveObject.editorService;
    }

    execute() {
        this.editorService.jumpToWordIfNotInside({
            startWord: this.wordWithLinebreakIndex,
            endWord: this.wordWithLinebreakIndex
        });

        this.transcription.setLinebreak(this.wordWithLinebreakIndex, false);

        const words: WordIndex[] = this.oldParagraphs[0].concat(this.oldParagraphs[1] || []);

        let chunkElementCount = 0;
        const wordIndexChunkArray: WordIndex[][] = [];
        let singleChunk: WordIndex[] = [];
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let index = 0; index < words.length; index++) {
            const currentWordIndex = words[index];
            singleChunk.push(currentWordIndex);
            if (
                currentWordIndex.word.hasLinebreak ||
                (chunkElementCount >= 300 && currentWordIndex.word.isEndOfSentence) ||
                chunkElementCount >= 400
            ) {
                ({ chunkElementCount, singleChunk } = LinebreakRemoveCommand.completeChunk(
                    chunkElementCount,
                    wordIndexChunkArray,
                    singleChunk
                ));
            }
            if (!currentWordIndex.word.isPause) {
                chunkElementCount++;
            }
        }
        wordIndexChunkArray.push(singleChunk);

        // If a chunk contains only pauses or cuts, merge it with the one before
        for (let i = wordIndexChunkArray.length - 1; i > 0; i--) {
            if (wordIndexChunkArray[i].every((word) => word.word.isCut || word.word.isPause)) {
                const lastTwoItems = wordIndexChunkArray[i - 1].concat(wordIndexChunkArray[i]);
                wordIndexChunkArray.splice(i - 1, 2, lastTwoItems);
            }
        }
        this.datasource.getValue().adapter.relax();
        this.datasource
            .getValue()
            .adapter.replace({
                predicate: ({ $index }) =>
                    [this.previousParagraphIndex, this.previousParagraphIndex + 1].includes($index),
                items: wordIndexChunkArray
            })
            .then();
        this.replacedItemLength = wordIndexChunkArray.length;
        this.editorService.chunkWordArrayResult.splice(this.previousParagraphIndex, 2, ...wordIndexChunkArray);
        this.editorService.updateWords([this.wordWithLinebreakIndex]);
        this.editorService.updateSelectedParagraphs();
        setTimeout(() => {
            const scrollToSpan = document.getElementById('word_' + this.wordWithLinebreakIndex);
            scrollToSpan.scrollIntoView({ block: 'center' });
        }, 500);
        return this;
    }

    undo() {
        this.editorService.jumpToWordIfNotInside({
            startWord: this.wordWithLinebreakIndex,
            endWord: this.wordWithLinebreakIndex
        });
        this.transcription.setLinebreak(this.wordWithLinebreakIndex, true);
        this.datasource.getValue().adapter.relax();
        this.datasource
            .getValue()
            .adapter.replace({
                predicate: ({ $index }) =>
                    $index >= this.previousParagraphIndex &&
                    $index < this.previousParagraphIndex + this.replacedItemLength,
                items: this.oldParagraphs
            })
            .then();
        this.editorService.updateWords([this.wordWithLinebreakIndex]);
        this.editorService.chunkWordArrayResult.splice(
            this.previousParagraphIndex,
            this.replacedItemLength,
            ...this.oldParagraphs
        );
        this.editorService.updateSelectedParagraphs();
    }

    private static completeChunk(
        chunkElementCount: number,
        wordIndexChunkArray: WordIndex[][],
        singleChunk: WordIndex[]
    ) {
        chunkElementCount = 0;
        wordIndexChunkArray.push(singleChunk);
        singleChunk = [];
        return { chunkElementCount, singleChunk };
    }
}
