import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import paths from '@type/shared-models/consts/firebase-paths';
import { VideoLimitations, TrialLimitations, getLimitationByPlanType } from '@type/shared-models/consts/limitations';
import { SubscriptionState } from '@type/shared-models/user/subscription-state';
import { User } from '@type/shared-models/user/user';
import { DateUtils } from '@type/shared-models/utils/date-utils';

@Injectable()
export class UserService {
    user$: BehaviorSubject<User> = new BehaviorSubject(null);
    limitations: VideoLimitations = TrialLimitations;
    get user(): User {
        return this.user$.value;
    }

    private sub = new Subscription();

    constructor(private firestore: AngularFirestore, angularFireAuth: AngularFireAuth) {
        this.onUserLoaded().then();
        this.subscribeUserChanges();
        this.subscribeAuthenticatedUser(angularFireAuth.authState);
    }

    subscribeUserChanges() {
        this.sub.add(
            this.user$.pipe(filter((user) => user != null)).subscribe((user) => {
                this.limitations = getLimitationByPlanType(user.subscriptionState.plan.type);
            })
        );
    }

    subscribeAuthenticatedUser(auth$: Observable<any>) {
        auth$
            .pipe(
                switchMap((firebaseUser) => {
                    return firebaseUser ? this.getUser$(firebaseUser.uid) : of(null);
                })
            )
            .subscribe(this.user$);
    }

    loadUserOnce(): Promise<User> {
        return new Promise((resolve) => {
            this.user$
                .pipe(
                    filter((user) => user != null),
                    first()
                )
                .subscribe(async (user) => {
                    const checkedUser = await this.updateAllLegacyValues(user);
                    resolve(checkedUser);
                });
        });
    }

    identifyUser(createdUser: User, referrer?: string) {
        const identifiedUserProperties = {
            id: createdUser.firebaseId,
            customerId: createdUser.customerId,
            name: createdUser.name,
            email: createdUser.email,
            createdAt: createdUser.createdAt,
            referrer
        };
    }

    async onUserLoaded() {
        const user = await this.loadUserOnce();

        // track user with its values
        this.identifyUser(user);
    }

    private getUser$(firebaseId: string): Observable<User> {
        return this.firestore
            .collection(paths.USERS)
            .doc<User>(firebaseId)
            .valueChanges()
            .pipe(
                filter((user) => user != null),
                switchMap(async (user) => new User().fromFirebase(user))
            );
    }

    async updateUsageLimitation(user: User): Promise<User> {
        if (user.subscriptionState && !user.subscriptionState.isBasic) {
            await this.renewUsageLimitation(user);
        }
        return user;
    }

    public async renewUsageLimitation(user: User): Promise<void> {
        let endDate = user.subscriptionState.endDate
            ? DateUtils.dateFromTimestamp(user.subscriptionState.endDate)
            : null;
        const currentDate = new Date();
        if (!endDate || currentDate > endDate) {
            if (!endDate) {
                endDate = new Date();
            }

            // currentDate is after endDate
            const newEndDate = new Date(endDate);
            DateUtils.addMonthsToDate(newEndDate, 1);

            user.subscriptionState.endDate = DateUtils.toUnixTimestamp(newEndDate);
            user.subscriptionState.usedSeconds = 0;

            await this.updateSubscriptionState(user.firebaseId, user.subscriptionState);
        }
    }

    async updateAllLegacyValues(user: User): Promise<User> {
        user = this.checkExampleProjectVisible(user);
        user = await this.updateUsageLimitation(user);
        return user;
    }

    checkExampleProjectVisible(user: User) {
        if (user.exampleProjectVisible == null) {
            this.updateExampleProjectVisible(true, user.firebaseId);
            user.exampleProjectVisible = true;
        }
        return user;
    }

    updatePromotionCodeId(promotionCodeId: string) {
        this.user.promotionCodeId = promotionCodeId;
        this.firestore.collection(paths.USERS).doc(this.user$.value.firebaseId).update({ promotionCodeId }).then();
    }

    updateSubscriptionState(firebaseId: string, subscriptionState: SubscriptionState) {
        return this.firestore
            .collection(paths.USERS)
            .doc(firebaseId)
            .update({ subscriptionState: subscriptionState.toFirebase() });
    }

    updateExampleProjectVisible(exampleProjectVisible: boolean, userId?: string) {
        let updateUserId;
        if (userId) {
            updateUserId = userId;
        } else {
            updateUserId = this.user$.value.firebaseId;
        }

        this.firestore.collection(paths.USERS).doc(updateUserId).update({ exampleProjectVisible }).then();
    }
}
