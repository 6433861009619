import { Component } from '@angular/core';
import { Language } from '@type/shared-models/language';
import { DialogModule, DialogRef } from '@type/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '@type/shared-models/user/user';
import { MediaType } from '@type/shared-models/media/media.utils';
import { UserService } from '../../../../services/user.service';
import { EditorService } from '../../../../services/editor.service';

@Component({
    selector: 'app-upload-confirm-dialog',
    templateUrl: './upload-confirm-dialog.component.html',
    styleUrls: ['./upload-confirm-dialog.component.scss'],
    providers: [DialogModule]
})
export class UploadConfirmDialogComponent {
    mediaType: MediaType;
    languageCodeLong$: Observable<string> = this.userService.user$.pipe(map((user: User) => user.language.codeLong));

    constructor(private ref: DialogRef, private userService: UserService, private editorService: EditorService) {
        this.mediaType = ref.config.data.mediaType;
    }

    closeDialogAndStartUpload() {
        this.ref.close(true);
    }

    languageChanged(language: Language) {
        this.userService.updateLanguage(language);
        this.editorService.updateLanguage(language);
    }
}
