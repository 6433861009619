import { FontStyle } from './font';

export enum SubtitleMode {
    disabled = 'disabled',
    dynamic = 'dynamic',
    static = 'static'
}
export type SubtitleHighlightStyle = 'none' | 'background' | 'opacity';

export interface SubtitleConfig {
    subtitleMode?: SubtitleMode;
    subtitleLength?: number;

    bgColor?: string;
    bg?: boolean;
}
