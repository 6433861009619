import { PlanType } from '../payment/products';

export interface VideoLimitations {
    size: number;
    sizeString: string;
    length: number; // in seconds
    extensions: {
        audio?: string[]; // e.g ".mp3"
        video?: string[]; // e.g ".mp4"
    };
    dynamicSubtitleLength: number;
    projectCount: number;
    planTypes: PlanType[];
}

export const defaultVideoLimitationExtensions = ['.mp4', '.mov', '.webm'];
export const defaultAudioLimitationExtensions = ['.mp3', '.m4a', '.wav', '.flac'];

export const BusinessLimitations: VideoLimitations = {
    size: 3500,
    sizeString: '3.5GB',
    extensions: {
        video: ['.mp4', '.mov', '.webm'],
        audio: ['.mp3', '.m4a', '.wav', '.flac']
    },
    length: 150 * 60, // 2.5 hours
    dynamicSubtitleLength: 20 * 60, // 3 min
    projectCount: 9999,
    planTypes: [PlanType.BUSINESS]
};
export const ProTeamLimitations: VideoLimitations = {
    size: 3500,
    sizeString: '3.5GB',
    extensions: {
        video: defaultVideoLimitationExtensions,
        audio: defaultAudioLimitationExtensions
    },
    length: 150 * 60, // 2.5 hours
    dynamicSubtitleLength: 20 * 60, // 3 min
    projectCount: 9999,
    planTypes: [PlanType.PRO_TEAM]
};
export const StarterLimitations: VideoLimitations = {
    size: 3500,
    sizeString: '3.5GB',
    extensions: {
        video: defaultVideoLimitationExtensions,
        audio: defaultAudioLimitationExtensions
    },
    length: 150 * 60, // 2.5 hours
    dynamicSubtitleLength: 20 * 60, // 3 min
    projectCount: 9999,
    planTypes: [PlanType.STARTER]
};
export const ProLimitations: VideoLimitations = {
    size: 3500,
    sizeString: '3.5GB',
    extensions: {
        video: defaultVideoLimitationExtensions,
        audio: defaultAudioLimitationExtensions
    },
    length: 150 * 60, // 2.5 hours
    dynamicSubtitleLength: 20 * 60, // 3 min
    projectCount: 9999,
    planTypes: [PlanType.PRO, PlanType.PERSONAL, PlanType.ENTERPRISE, PlanType.ULTRA]
};
export const BasicLimitations: VideoLimitations = {
    size: 1050,
    sizeString: '1GB',
    extensions: {
        video: defaultVideoLimitationExtensions,
        audio: defaultAudioLimitationExtensions
    },
    length: 60 * 60, // 30 min
    dynamicSubtitleLength: 20 * 60, // 3 min
    projectCount: 9999,
    planTypes: [PlanType.BASIC, PlanType.STARTER_BASIC]
};
export const TrialLimitations: VideoLimitations = {
    size: 1050,
    sizeString: '1GB',
    extensions: {
        video: defaultVideoLimitationExtensions,
        audio: defaultAudioLimitationExtensions
    },
    length: 60 * 60, // 60 min, video will be cut down to a 10 minute video in free plan
    dynamicSubtitleLength: 20 * 60, // 3 min
    projectCount: 9999,
    planTypes: [PlanType.TRIAL, PlanType.EXPIRED]
};

export const FREE_TRANSLATION_LIMIT = 3;
export const SPLIT_LIMITATION_TRIAL = 60 * 60;

export function getLimitationByPlanType(planType: PlanType) {
    const allLimitations: VideoLimitations[] = [
        TrialLimitations,
        BasicLimitations,
        ProLimitations,
        StarterLimitations,
        ProTeamLimitations,
        BusinessLimitations
    ];
    return allLimitations.find((limitation) => limitation.planTypes.includes(planType)) || TrialLimitations;
}
