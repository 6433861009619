import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-card',
    template: `
        <section class="typ-card card-container">
            <div class="pb-2">
                <h3 class="typ-card__label" *ngIf="label">{{ label }}</h3>
                <p class="text--light" *ngIf="description">{{ description }}</p>
            </div>
            <ng-content></ng-content>
        </section>
    `,
    styles: [
        `
            .card-container {
                display: grid;
                grid-template-rows: auto 1fr;
                align-content: start;
                height: 100%;
            }
        `
    ]
})
export class CardComponent {
    @Input() label: string;
    @Input() description: string;
}
