import {
    Directive,
    ElementRef,
    Renderer2,
    HostListener,
    OnDestroy,
    OnInit,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { CustomCursorType } from './cursor-types';

@Directive({ selector: '[appCustomCursor]' })
export class CustomCursorDirective {
    @Input() customCursorType: CustomCursorType;
    @Input() selectedIndices: number[];
    @Output() customCursorActive: EventEmitter<any> = new EventEmitter();

    private currentlySelectingClip = false;
    cursorStyle: HTMLElement;
    customSelectionCursor: HTMLElement;
    private mouseUpListener: () => void;
    private escapePressListener: () => void;
    private pointerMovedListener: () => void;

    constructor(private renderer: Renderer2, private el: ElementRef) {}

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        if (this.selectedIndices == undefined || this.selectedIndices?.length <= 1) {
            this.addClipSelectionCursor(event);
            this.customCursorActive.emit(true);
        }
    }

    addClipSelectionCursor(event: MouseEvent) {
        this.currentlySelectingClip = true;
        // this.editorService.selectedTextViaCursorSelectionButton = true;
        this.cursorStyle = this.renderer.createElement('style');
        this.cursorStyle.innerHTML = '*{cursor: text !important;}';
        this.cursorStyle.id = 'cursor-style';
        this.renderer.appendChild(document.head, this.cursorStyle);

        this.createCustomSelectionCursor(this.customCursorType);
        this.customSelectionCursor = document.getElementById('customSelectionCursor');
        this.customSelectionCursor.style.left = event.x + 'px';
        this.customSelectionCursor.style.top = event.y - 40 + 'px';

        const resetCursor = () => {
            // remove local listeners and reset cursor
            this.mouseUpListener();
            this.escapePressListener();
            this.renderer.removeChild(document.head, this.cursorStyle);
            this.renderer.removeChild(document.body, this.customSelectionCursor);

            this.toggleCustomCursor(false, this.customSelectionCursor);

            if (this.selectedIndices?.length === 0) {
                this.customCursorActive.emit(false);
            } else {
                this.customCursorActive.emit(true);
            }
        };

        if (this.currentlySelectingClip) {
            this.mouseUpListener = this.renderer.listen(document, 'mouseup', resetCursor);
            this.toggleCustomCursor(true, this.customSelectionCursor);
        }

        this.escapePressListener = this.renderer.listen('document', 'keydown.escape', () => {
            resetCursor();
        });
    }

    toggleCustomCursor(addCustomCursor: boolean, customSelectionCursor: HTMLElement) {
        const followMouse = (event) => {
            customSelectionCursor.style.left = event.x + 'px';
            customSelectionCursor.style.top = event.y - 40 + 'px';
        };

        if (addCustomCursor) {
            customSelectionCursor.style.display = 'block';
            this.pointerMovedListener = this.renderer.listen(document, 'pointermove', followMouse);
        } else {
            customSelectionCursor.style.display = 'none';
            this.pointerMovedListener();
        }
    }

    createCustomSelectionCursor(type: CustomCursorType) {
        this.customSelectionCursor = this.renderer.createElement('div');
        this.renderer.setProperty(this.customSelectionCursor, 'id', 'customSelectionCursor');

        //basic styling
        this.renderer.setStyle(this.customSelectionCursor, 'position', 'absolute');
        this.renderer.setStyle(this.customSelectionCursor, 'z-index', '50');
        this.renderer.setStyle(this.customSelectionCursor, 'pointer-events', 'none');
        this.renderer.setStyle(this.customSelectionCursor, 'display', 'none'); // Initially hidden

        //add cases when we want to add other elements
        switch (type) {
            case 'clip':
                this.renderer.setStyle(this.customSelectionCursor, 'background-color', '#141414');
                this.renderer.setStyle(this.customSelectionCursor, 'padding', '0.5rem');
                this.renderer.setStyle(this.customSelectionCursor, 'padding-left', '0.75rem');
                this.renderer.setStyle(this.customSelectionCursor, 'padding-right', '0.75rem');
                this.renderer.setStyle(this.customSelectionCursor, 'color', 'white');
                this.renderer.setStyle(this.customSelectionCursor, 'font-weight', '600');
                this.renderer.setStyle(this.customSelectionCursor, 'font-size', '0.875rem');
                this.renderer.setStyle(this.customSelectionCursor, 'border-radius', '0.5rem');
                this.renderer.setProperty(this.customSelectionCursor, 'innerText', 'Select section to clip');
                break;

            default:
                break;
        }

        // Add the customSelectionCursor element to the DOM
        this.renderer.appendChild(document.body, this.customSelectionCursor);
    }

    // //TODO: refactor that we dont need to import editorservice
    // //possible way to continue for images and other elements.
    // // trigger @Output  function when selection is made, then from there call the normal apply image function with uri and startEndConfig
}
