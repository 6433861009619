import { environment } from '@type-mvp/environments/environment';

export const canvasElements = {
    text: [
        {
            bg: false,
            startWord: 155,
            konvaElement:
                '{"attrs":{"x":170.798641136504,"y":46.39976420030082,"text":"Part 1","fontSize":18,"fontFamily":"Inter","fill":"rgba(255, 255, 255, 1)","padding":10,"draggable":true,"align":"center","id":"9f1e2506-0f2b-41d5-9f7c-e64a6e9e11e4","lineHeight":1.2,"scaleX":5.514930461735996,"scaleY":5.51493046173599},"className":"Text"}',
            endWord: 163,
            zIndex: 3,
            bgColor: '#ffffff',
            ID: '9f1e2506-0f2b-41d5-9f7c-e64a6e9e11e4'
        },
        {
            ID: '3c033d1a-0e32-4928-b6c4-08a2533db23e',
            bgColor: '#ffffff',
            konvaElement:
                '{"attrs":{"x":253.90303182159857,"y":178.98219674593375,"text":"Cut & Edit","fontSize":18,"fontFamily":"Roboto","fill":"rgba(255, 255, 255, 1)","padding":20,"draggable":true,"align":"center","id":"0c3157c9-ad2e-4752-817a-c862c281bfb9","lineHeight":1.2,"scaleX":1.819250145701262,"scaleY":1.8192501457012633},"className":"Text"}',
            bg: false,
            zIndex: 5,
            startWord: 155,
            endWord: 163
        },
        {
            bg: false,
            endWord: 420,
            zIndex: 3,
            bgColor: '#ffffff',
            startWord: 412,
            ID: '69fd4989-e739-4b93-8874-55f49b06bd0a',
            konvaElement:
                '{"attrs":{"x":163.9448641136504,"y":46.39976420030082,"text":"Part 2","fontSize":18,"fontFamily":"Inter","fill":"rgba(255, 255, 255, 1)","padding":10,"draggable":true,"align":"center","id":"69fd4989-e739-4b93-8874-55f49b06bd0a","lineHeight":1.2,"scaleX":5.514930461735996,"scaleY":5.51493046173599},"className":"Text"}'
        },
        {
            bg: false,
            bgColor: '#ffffff',
            konvaElement:
                '{"attrs":{"x":227.85241794036583,"y":178.98219674593375,"text":"Add Subtitles","fontSize":18,"fontFamily":"Roboto","fill":"rgba(255, 255, 255, 1)","padding":20,"draggable":true,"align":"center","id":"0c3157c9-ad2e-4752-817a-c862c281bfb9","lineHeight":1.2,"scaleX":1.819250145701262,"scaleY":1.8192501457012633},"className":"Text"}',
            ID: 'b5a8956f-b146-41a5-9292-eca22902b2ba',
            zIndex: 5,
            startWord: 412,
            endWord: 420
        },
        {
            zIndex: 3,
            startWord: 755,
            bg: false,
            ID: 'e67eeff5-547e-46e4-bc18-0e560f0fd42c',
            konvaElement:
                '{"attrs":{"x":162.022641136504,"y":46.39976420030082,"text":"Part 3","fontSize":18,"fontFamily":"Inter","fill":"rgba(255, 255, 255, 1)","padding":10,"draggable":true,"align":"center","id":"e67eeff5-547e-46e4-bc18-0e560f0fd42c","lineHeight":1.2,"scaleX":5.514930461735996,"scaleY":5.51493046173599},"className":"Text"}',
            endWord: 759,
            bgColor: '#ffffff'
        },
        {
            startWord: 755,
            bgColor: '#ffffff',
            endWord: 759,
            ID: '17bea551-6be7-4b1d-94e4-40cf2b79f155',
            zIndex: 5,
            bg: false,
            konvaElement:
                '{"attrs":{"x":211.93259834627912,"y":178.98219674593375,"text":"Translate Video","fontSize":18,"fontFamily":"Roboto","fill":"rgba(255, 255, 255, 1)","padding":20,"draggable":true,"align":"center","id":"0c3157c9-ad2e-4752-817a-c862c281bfb9","lineHeight":1.2,"scaleX":1.819250145701262,"scaleY":1.8192501457012633},"className":"Text"}'
        },
        {
            startWord: 890,
            ID: '1ca6a136-01a0-442f-9df3-064eb87df543',
            endWord: 899,
            konvaElement:
                '{"attrs":{"x":162.022641136504,"y":46.39976420030082,"text":"Part 4","fontSize":18,"fontFamily":"Inter","fill":"rgba(255, 255, 255, 1)","padding":10,"draggable":true,"align":"center","id":"1ca6a136-01a0-442f-9df3-064eb87df543","lineHeight":1.2,"scaleX":5.514930461735996,"scaleY":5.51493046173599},"className":"Text"}',
            zIndex: 3,
            bg: false,
            bgColor: '#ffffff'
        },
        {
            startWord: 890,
            bgColor: '#ffffff',
            ID: '5f626509-8841-4a5d-b78a-1b99d4731274',
            endWord: 899,
            bg: false,
            zIndex: 5,
            konvaElement:
                '{"attrs":{"x":232.1941869205713,"y":178.98219674593375,"text":"Aspect Ratio","fontSize":18,"fontFamily":"Roboto","fill":"rgba(255, 255, 255, 1)","padding":20,"draggable":true,"align":"center","id":"0c3157c9-ad2e-4752-817a-c862c281bfb9","lineHeight":1.2,"scaleX":1.819250145701262,"scaleY":1.8192501457012633},"className":"Text"}'
        },
        {
            bgColor: '#ffffff',
            startWord: 1130,
            endWord: 1137,
            konvaElement:
                '{"attrs":{"x":163.944641136504,"y":46.39976420030082,"text":"Part 5","fontSize":18,"fontFamily":"Inter","fill":"rgba(255, 255, 255, 1)","padding":10,"draggable":true,"align":"center","id":"cf073ff5-6022-4faf-9cf4-aab2f43e0cab","lineHeight":1.2,"scaleX":5.514930461735996,"scaleY":5.51493046173599},"className":"Text"}',
            bg: false,
            ID: 'cf073ff5-6022-4faf-9cf4-aab2f43e0cab',
            zIndex: 3
        },
        {
            bgColor: '#ffffff',
            zIndex: 5,
            endWord: 1137,
            startWord: 1130,
            ID: '0c3157c9-ad2e-4752-817a-c862c281bfb9',
            bg: false,
            konvaElement:
                '{"attrs":{"x":196.35168776872462,"y":178.98219674593375,"text":"Add Text & Image\\nAnimations","fontSize":18,"fontFamily":"Roboto","fill":"rgba(255, 255, 255, 1)","padding":20,"draggable":true,"align":"center","id":"0c3157c9-ad2e-4752-817a-c862c281bfb9","lineHeight":1.2,"scaleX":1.819250145701262,"scaleY":1.8192501457012633},"className":"Text"}'
        }
    ],
    image: [
        {
            zIndex: 3,
            endWord: 267,
            imageURI: 'videos/public/example_video/assets/01_backspace.png',
            ID: '463206d6-bd76-4803-8866-2f386252a400',
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/01_backspace.png',
            konvaElement:
                '{"attrs":{"visible":false,"scaleX":0.595562648152514,"scaleY":0.5955626481525139,"draggable":true,"id":"463206d6-bd76-4803-8866-2f386252a400","x":570,"y":36.11355644681763},"className":"Image"}',
            startWord: 239
        },
        {
            imageURI: 'videos/public/example_video/assets/02_cut.png',
            zIndex: 3,
            startWord: 244,
            ID: '384cefc7-4d18-434c-9cb8-f1dfcc1051c2',
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/02_cut.png',
            endWord: 267,
            konvaElement:
                '{"attrs":{"visible":false,"scaleX":0.5987575602390369,"scaleY":0.5987575602390371,"draggable":true,"id":"384cefc7-4d18-434c-9cb8-f1dfcc1051c2","x":546,"y":80.25439093783675},"className":"Image"}'
        },
        {
            imageURI: 'videos/public/example_video/assets/03_context_menu_cut.png',
            endWord: 267,
            zIndex: 3,
            ID: '553fcad7-f380-4896-9c29-d10d96a6ad56',
            startWord: 255,
            konvaElement:
                '{"attrs":{"visible":false,"scaleX":0.595540747189247,"scaleY":0.5955407471892467,"draggable":true,"id":"553fcad7-f380-4896-9c29-d10d96a6ad56","x":543,"y":148.60878071069783},"className":"Image"}',
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/03_context_menu_cut.png'
        },
        {
            imageURI: 'videos/public/example_video/assets/04_deleted_section.png',
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/04_deleted_section.png',
            zIndex: 3,
            endWord: 312,
            ID: '1b501411-5d3c-4b0c-ad3d-49d2fa00cddd',
            startWord: 307,
            konvaElement:
                '{"attrs":{"scaleX":0.8710311852432022,"scaleY":0.8710311852432022,"draggable":true,"id":"1b501411-5d3c-4b0c-ad3d-49d2fa00cddd","x":20.609061498646813,"y":24.31319525511475},"className":"Image"}'
        },
        {
            ID: '1031cc92-0e5c-48b5-8237-e5b75b3c3334',
            startWord: 313,
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/05_undo.png',
            imageURI: 'videos/public/example_video/assets/05_undo.png',
            zIndex: 3,
            endWord: 324,
            konvaElement:
                '{"attrs":{"visible":false,"scaleX":0.8478743597394633,"scaleY":0.8478743597394637,"draggable":true,"id":"1031cc92-0e5c-48b5-8237-e5b75b3c3334","x":20.609061498646813,"y":24.31319525511475},"className":"Image"}'
        },
        {
            startWord: 441,
            zIndex: 2,
            imageURI: 'videos/public/example_video/assets/06_subtitle_toggle.png',
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/06_subtitle_toggle.png',
            konvaElement:
                '{"attrs":{"scaleX":0.7646538397345718,"scaleY":0.7646538397345717,"draggable":true,"id":"f1ac8b51-5bad-48cd-9fb9-589dc15a1b9b","x":20.609061498646813,"y":24.31319525511475},"className":"Image"}',
            ID: 'f1ac8b51-5bad-48cd-9fb9-589dc15a1b9b',
            endWord: 452
        },
        {
            ID: '484e5952-5f6a-4fa2-afc9-fc6ae7bcbd65',
            konvaElement:
                '{"attrs":{"scaleX":0.4644327065756977,"scaleY":0.46443270657569774,"draggable":true,"id":"484e5952-5f6a-4fa2-afc9-fc6ae7bcbd65","x":17.503992711408475,"y":22.327847903894952},"className":"Image"}',
            startWord: 453,
            imageURI: 'videos/public/example_video/assets/07_subtitle_menu.png',
            zIndex: 3,
            endWord: 472,
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/07_subtitle_menu.png'
        },
        {
            startWord: 525,
            zIndex: 2,
            endWord: 536,
            konvaElement:
                '{"attrs":{"scaleX":0.4018449701037765,"scaleY":0.4018449701037765,"draggable":true,"id":"bd16810a-f409-4a9c-a53f-122fece73ff9","x":17.485559515600727,"y":27.55388740649485},"className":"Image"}',
            imageURI: 'videos/public/example_video/assets/08_context_menu_subtitle_styling.png',
            ID: 'bd16810a-f409-4a9c-a53f-122fece73ff9',
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/08_context_menu_subtitle_styling.png'
        },
        {
            imageURI: 'videos/public/example_video/assets/09_text_correction.png',
            zIndex: 3,
            startWord: 673,
            ID: 'ed419a39-fb3b-4d30-b3d3-9bfca668511a',
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/09_text_correction.png',
            endWord: 690,
            konvaElement:
                '{"attrs":{"scaleX":0.47732411550726517,"scaleY":0.47732411550726506,"draggable":true,"id":"ed419a39-fb3b-4d30-b3d3-9bfca668511a","x":17.485559515600727,"y":27.55388740649485,"visible":false},"className":"Image"}'
        },
        {
            ID: '6d2e77b8-e7b7-443b-8d50-d01d3637abd6',
            zIndex: 3,
            endWord: 792,
            startWord: 776,
            imageURI: 'videos/public/example_video/assets/10_translation_button.png',
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/10_translation_button.png',
            konvaElement:
                '{"attrs":{"scaleX":0.8342187281378031,"scaleY":0.8342187281378032,"draggable":true,"id":"6d2e77b8-e7b7-443b-8d50-d01d3637abd6"},"className":"Image"}'
        },
        {
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/11_translation_menu.png',
            endWord: 811,
            konvaElement:
                '{"attrs":{"scaleX":0.6293111266060771,"scaleY":0.6293111266060771,"draggable":true,"id":"c2d9c515-32cd-4d51-87f0-51cb9bae4d2d","visible":false},"className":"Image"}',
            imageURI: 'videos/public/example_video/assets/11_translation_menu.png',
            ID: 'c2d9c515-32cd-4d51-87f0-51cb9bae4d2d',
            zIndex: 2,
            startWord: 790
        },
        {
            imageURI: 'videos/public/example_video/assets/12_format_button.png',
            startWord: 953,
            endWord: 962,
            konvaElement:
                '{"attrs":{"scaleX":0.6929418799481097,"scaleY":0.6929418799481099,"draggable":true,"id":"c303a00a-6674-41e2-b6a7-f0d95ab37907","x":16.49093583807893,"y":15.704183963271282},"className":"Image"}',
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/12_format_button.png',
            zIndex: 3,
            ID: 'c303a00a-6674-41e2-b6a7-f0d95ab37907'
        },
        {
            endWord: 977,
            ID: 'bf11f88a-3fa0-44a4-aa35-5e8012751b18',
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/13_format_menu.png',
            imageURI: 'videos/public/example_video/assets/13_format_menu.png',
            zIndex: 2,
            konvaElement:
                '{"attrs":{"scaleX":0.42737435509839466,"scaleY":0.4273743550983946,"draggable":true,"id":"bf11f88a-3fa0-44a4-aa35-5e8012751b18"},"className":"Image"}',
            startWord: 963
        },
        {
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/14_01_highlight_text.png',
            zIndex: 3,
            startWord: 1213,
            endWord: 1225,
            ID: '165bc47b-1c1e-4b33-8170-380f7500566e',
            imageURI: 'videos/public/example_video/assets/14_01_highlight_text.png',
            konvaElement:
                '{"attrs":{"scaleX":0.4476520373997045,"scaleY":0.44765203739970444,"draggable":true,"id":"165bc47b-1c1e-4b33-8170-380f7500566e","x":22.71244088847896,"y":27.336827986600493},"className":"Image"}'
        },
        {
            imageURI: 'videos/public/example_video/assets/14_02_context_menu_editing.png',
            ID: '79d7be23-7f0a-4dff-abbe-fa47ae75fe5b',
            startWord: 1230,
            konvaElement:
                '{"attrs":{"scaleX":0.9108463925225266,"scaleY":0.9108463925225266,"draggable":true,"id":"79d7be23-7f0a-4dff-abbe-fa47ae75fe5b","visible":false,"x":15.341780874210833,"y":13.160825397478966},"className":"Image"}',
            endWord: 1282,
            zIndex: 2,
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/14_02_context_menu_editing.png'
        },
        {
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/16_context_menu_text_styling.png',
            konvaElement:
                '{"attrs":{"scaleX":0.4255611564645997,"scaleY":0.4255611564645998,"draggable":true,"id":"66d4cbb6-bfb3-47bd-96fb-9fc29b30ed3e"},"className":"Image"}',
            ID: '66d4cbb6-bfb3-47bd-96fb-9fc29b30ed3e',
            zIndex: 2,
            startWord: 1341,
            endWord: 1362,
            imageURI: 'videos/public/example_video/assets/16_context_menu_text_styling.png'
        },
        {
            imageURI: 'videos/public/example_video/assets/17_add_image.png',
            ID: 'aa83eb85-46c8-4a05-9f7c-ae4d5a2e6fed',
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/17_add_image.png',
            zIndex: 2,
            konvaElement:
                '{"attrs":{"scaleX":0.28629246459235486,"scaleY":0.2862924645923551,"draggable":true,"id":"aa83eb85-46c8-4a05-9f7c-ae4d5a2e6fed","visible":false},"className":"Image"}',
            endWord: 1458,
            startWord: 1439
        },
        {
            ID: 'f06cc880-dedc-4d85-8310-7a8ddaed8892',
            zIndex: 2,
            endWord: 1559,
            imageURI: 'videos/public/example_video/assets/18_marker.png',
            startWord: 1528,
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/18_marker.png',
            konvaElement:
                '{"attrs":{"scaleX":0.5570447372930412,"scaleY":0.5570447372930412,"draggable":true,"id":"f06cc880-dedc-4d85-8310-7a8ddaed8892"},"className":"Image"}'
        },
        {
            ID: '6f287731-35d7-4695-85ec-6e5a216d559d',
            zIndex: 50,
            endWord: 1762,
            imageURI: 'videos/public/example_video/assets/type_studio_logo_text_white.svg',
            startWord: 0,
            imageURL:
                (environment.production
                    ? 'https://storage.googleapis.com/type-mvp.appspot.com/'
                    : 'https://storage.googleapis.com/type-mvp-development.appspot.com/') +
                'videos/public/example_video/assets/type_studio_logo_text_white.svg',
            konvaElement:
                '{"attrs":{"scaleX":0.3589712924490486,"scaleY":0.35897129244904724,"draggable":true,"id":"6f287731-35d7-4695-85ec-6e5a216d559d","x":18.170258756859784,"y":359.48094121188234},"className":"Image"}'
        }
    ],
    shape: [
        {
            shape: 'rectangle',
            startWord: 155,
            zIndex: 1,
            ID: '999db4d1-5192-4e42-be8a-48d6a17d73d4',
            konvaElement:
                '{"attrs":{"scaleX":45.98550072700925,"scaleY":27.097511880290156,"draggable":true,"id":"999db4d1-5192-4e42-be8a-48d6a17d73d4","x":-309.07599083350476,"y":-193.2226694479537},"className":"Image"}',
            style: 'flat',
            strokeColor: 'rgba(255, 255, 255, 0)',
            fillColor: 'rgba(78, 98, 213, 0.8)',
            endWord: 163
        },
        {
            style: 'flat',
            konvaElement:
                '{"attrs":{"scaleX":39.64009503455071,"scaleY":26.561451276782698,"draggable":true,"id":"3b74c812-f155-4fcd-917a-2880f25ffeb2","x":-207.9907012956489,"y":-135.580987046902},"className":"Image"}',
            endWord: 420,
            ID: '3b74c812-f155-4fcd-917a-2880f25ffeb2',
            zIndex: 1,
            fillColor: 'rgba(78, 98, 213, 0.8)',
            strokeColor: 'rgba(255, 255, 255, 0)',
            startWord: 412,
            shape: 'rectangle'
        },
        {
            shape: 'rectangle',
            ID: '87d80767-1933-47e7-b14a-23fbf318e3cf',
            fillColor: 'rgba(78, 98, 213, 0.8)',
            zIndex: 1,
            style: 'flat',
            startWord: 755,
            strokeColor: 'rgba(255, 255, 255, 0)',
            konvaElement:
                '{"attrs":{"scaleX":40.316938308412965,"scaleY":24.925055750286294,"draggable":true,"id":"87d80767-1933-47e7-b14a-23fbf318e3cf","x":-218.82126803184775,"y":-158.92587478332405},"className":"Image"}',
            endWord: 759
        },
        {
            style: 'flat',
            fillColor: 'rgba(78, 98, 213, 0.8)',
            shape: 'rectangle',
            ID: '5df609ad-c250-46c1-8c52-f45f88aee1a8',
            strokeColor: 'rgba(255, 255, 255, 0)',
            konvaElement:
                '{"attrs":{"scaleX":39.950314868404256,"scaleY":23.59901109950472,"draggable":true,"id":"5df609ad-c250-46c1-8c52-f45f88aee1a8","x":-212.5034374357318,"y":-137.26474131092635},"className":"Image"}',
            endWord: 899,
            startWord: 890,
            zIndex: 1
        },
        {
            endWord: 1137,
            shape: 'rectangle',
            style: 'flat',
            fillColor: 'rgba(78, 98, 213, 0.8)',
            startWord: 1130,
            konvaElement:
                '{"attrs":{"scaleX":41.304001416128756,"scaleY":24.55827744262331,"draggable":true,"id":"23bb2fac-10ee-4dbf-8126-06e1a7a54946","x":-234.16457090812943,"y":-152.60804418720804},"className":"Image"}',
            strokeColor: 'rgba(255, 255, 255, 0)',
            zIndex: 1,
            ID: '23bb2fac-10ee-4dbf-8126-06e1a7a54946'
        },
        {
            fillColor: 'rgba(237, 86, 83, 1)',
            style: 'flat',
            konvaElement:
                '{"attrs":{"scaleX":3.090331900956423,"scaleY":2.7775150556696975,"draggable":true,"id":"3636ed42-6843-47bf-a89f-e76b79128216","x":41.165831431803646,"y":203,"rotation":-90.0000000000001,"skewX":1.045636709310693e-15},"className":"Image"}',
            strokeColor: 'rgba(255, 255, 255, 0)',
            endWord: 1248,
            ID: '3636ed42-6843-47bf-a89f-e76b79128216',
            shape: 'arrow',
            zIndex: 8,
            startWord: 1234
        },
        {
            konvaElement:
                '{"attrs":{"scaleX":3.090331900956423,"scaleY":2.7775150556696975,"draggable":true,"id":"3636ed42-6843-47bf-a89f-e76b79128216","x":88.93725700403672,"y":203.12543803711065,"rotation":-90.0000000000001,"skewX":1.045636709310693e-15},"className":"Image"}',
            style: 'flat',
            zIndex: 8,
            startWord: 1249,
            ID: '64a8d2ec-a213-43a2-8d2e-7e771806aa9a',
            fillColor: 'rgba(237, 86, 83, 1)',
            endWord: 1264,
            strokeColor: 'rgba(255, 255, 255, 0)',
            shape: 'arrow'
        },
        {
            strokeColor: 'rgba(255, 255, 255, 0)',
            startWord: 1265,
            konvaElement:
                '{"attrs":{"scaleX":3.033916381904764,"scaleY":2.721254404272274,"draggable":true,"id":"64a8d2ec-a213-43a2-8d2e-7e771806aa9a","x":135.41154119978646,"y":200.86853136552747,"rotation":-90.00000000000001,"skewX":-2.780261992762906e-16,"visible":false},"className":"Image"}',
            fillColor: 'rgba(237, 86, 83, 1)',
            ID: 'b101a0e0-411c-4b51-9c13-3dea1a6bd023',
            style: 'flat',
            zIndex: 8,
            endWord: 1282,
            shape: 'arrow'
        }
    ],
    subtitle: {
        ID: 'fa605683-afde-4140-9e77-756d825c1427',
        startWord: -1,
        endWord: -1,
        konvaElement:
            '{"attrs":{"x":163.27822663571993,"y":330.87021053810935,"text":"Anybody that chose French is going to be like, Part 4: Change the Aspect Ratio","fontSize":18,"fontFamily":"Open Sans","fill":"#000000","padding":20,"draggable":true,"align":"center","id":"fa605683-afde-4140-9e77-756d825c1427","lineHeight":1.2,"scaleX":0.6596057355865007,"scaleY":0.6596057355865005,"width":600},"className":"Text"}',
        bg: true,
        zIndex: 50,
        bgColor: '#ffffff'
    }
};
