<app-button
    [disabled]="disabled"
    data-spec="language-selection-trigger"
    *ngIf="currentSelectedLanguage"
    [color]="color"
    [appTriggerFor]="languageMenu"
    [dialogConfig]="{ autoFocusInput: true }"
    class="block"
    [isDropdown]="true"
    [textAlignment]="textAlignment"
    [size]="size"
>
    <span appIcon class="notranslate" [ngClass]="{ 'ml-2': size === 'sm' }">
        <img
            *ngIf="showFallbackEmojis; else hasEmojiSupportPrev"
            src="https://flagcdn.com/48x36/{{ currentSelectedLanguage.codeShort.toLocaleLowerCase() }}.webp"
            style="width: 19px"
        />
        <ng-template #hasEmojiSupportPrev>{{ currentSelectedLanguage.emoji }}</ng-template>
    </span>
    <span *ngIf="showLanguageName" class="notranslate" [ngClass]="{ 'ml-2': textAlignment === 'left' }">{{
        currentSelectedLanguage.name
    }}</span>
    <svg-icon src="/assets/ic/24/dropdown.svg" class="icon-size-xl" justify="end" appIcon></svg-icon>
</app-button>
<app-menu [maxHeight]="188" #languageMenu="appMenu">
    <app-menu-search
        (mousedown)="$event.stopImmediatePropagation()"
        placeholder="Search..."
        (searchString)="searchLanguage($event)"
    ></app-menu-search>
    <app-menu-item
        *ngFor="let language of filteredLanguages"
        (btnClick)="selectLanguage(language)"
        [selected]="language === currentSelectedLanguage"
        (mousedown)="$event.stopImmediatePropagation()"
    >
        <span appIcon>
            <img
                *ngIf="showFallbackEmojis; else hasEmojiSupport"
                src="https://flagcdn.com/48x36/{{ language.codeShort.toLocaleLowerCase() }}.webp"
                style="width: 19px"
            />
            <ng-template #hasEmojiSupport> {{ language.emoji }} </ng-template>
        </span>
        <span class="text-no-overflow">{{ language.name }}</span>
        <svg-icon *ngIf="showBeta && language.beta" src="/assets/ic/beta_tag.svg" justify="end"></svg-icon>
    </app-menu-item>
    <app-menu-item (btnClick)="requestLanguage($event)" class="text__cta">
        <!-- <svg-icon appIcon src="/assets/ic/24/flags.svg" class="icon-size-lg"></svg-icon> -->
        <span class="text-no-overflow">Request language</span>
    </app-menu-item>
</app-menu>
