import { ClipElement } from './clip-element';
import { Transcription } from './transcription';

export interface SpeakerChange {
    speakerIndex: number;
    wordIndex: number;
}
export class VideoClip extends ClipElement {
    video: HTMLVideoElement;
    next: VideoClip;
    mediaIndex: number;
    speakerChanges: SpeakerChange[];
    constructor(
        startIndex: number,
        endIndex: number,
        mediaElement: HTMLVideoElement,
        mediaIndex: number,
        _transcriptionReference: Transcription,
        speakerChanges: SpeakerChange[]
    ) {
        super(_transcriptionReference);
        this.video = mediaElement;
        this.mediaIndex = mediaIndex;
        this.startIndex = startIndex;
        this._transcriptionReference = _transcriptionReference;
        this.endIndex = endIndex;
        this.next = null;
        this.speakerChanges = speakerChanges.length ? speakerChanges : null;
    }
    public get speakerIndex(): number {
        return this._transcriptionReference.words[this.startIndex].speakerTag;
    }
    get hasMultipleSpeakers(): boolean {
        return this.speakerChanges.length > 0;
    }
    play() {
        this.video.play();
    }

    pause() {
        this.video.pause();
    }

    stop() {
        this.video.pause();
    }

    reset() {
        this.video.currentTime = this.startTimeInOriginal;
    }
    clone(): VideoClip {
        const clonedVideoClip = new VideoClip(
            this.startIndex,
            this.endIndex,
            this.video,
            this.mediaIndex,
            this._transcriptionReference,
            this.speakerChanges
        );
        clonedVideoClip.next = this.next;
        return clonedVideoClip;
    }
}
