<ng-template let-ref="popover">
    <div
        class="rounded-2xl bg-white py-1 shadow-floating"
        [ngStyle]="{
            'min-width.px': ref.config?.minWidth,
            'min-height.px': ref.config?.minHeight,
            'max-width.px': ref.config?.maxWidth,
            'max-height.px': ref.config?.maxHeight,
            'width.px': ref.config?.width,
            'height.px': ref.config?.height
        }"
        [ngClass]="classMap"
        [@transformMenu]="_panelAnimationState"
        (click)="$event.stopPropagation(); close()"
        (keyup.enter)="$event.stopPropagation(); close()"
        role="menu"
    >
        <ng-content select="<app-menu-search>"></ng-content>
        <div
            class="scroll-container--y-auto grid gap-1 py-1 px-2"
            [ngStyle]="{
                'max-height.px': maxHeight,
                'height.px': height,
                'max-width.px': maxWidth,
                'width.px': width
            }"
        >
            <ng-content></ng-content>
        </div>
    </div>
</ng-template>
