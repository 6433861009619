import { AfterViewInit, Component, ElementRef, Inject, isDevMode, ViewChild } from '@angular/core';
import * as typeformEmbed from '@typeform/embed';
import { DialogRef, DIALOG_DATA } from '@type/dialog';

@Component({
    templateUrl: 'type-form.component.html',
    styles: [
        `
            .typeform-container {
                height: 100vh;
                margin: -40px -24px -24px;
                width: 100vw;
            }

            app-dialog-header {
                position: absolute;
                top: 0.5rem;
                right: 1.5rem;
                left: 1.5rem;
            }
        `
    ]
})
export class TypeFormComponent implements AfterViewInit {
    @ViewChild('typeformElement', { static: true }) typeformElement: ElementRef;
    show = false;
    surveyId;

    constructor(@Inject(DIALOG_DATA) data: { surveyId?: string }, private ref: DialogRef) {
        this.surveyId = data.surveyId;
    }

    ngAfterViewInit() {
        typeformEmbed.makeWidget(this.typeformElement.nativeElement, `https://form.typeform.com/to/${this.surveyId}`, {
            hideFooters: true,
            hideHeaders: true,
            opacity: 0,
            onSubmit: async () => {
                this.close(true);
            }
        });
    }

    close(res?: boolean) {
        this.ref.close(res);
    }
}
