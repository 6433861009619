import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import routes from '@type/shared-models/consts/routes';
import { filter, first, map, startWith } from 'rxjs/operators';
import { DialogRef, DialogService } from '@type/dialog';
import { EditorService } from '../../services/editor.service';
import { AuthService } from '../../services/auth.service';
import { EmbedService } from '../../services/embed.service';
import { RecorderService } from '../../services/recorder.service';
import { UserService } from '../../services/user.service';
import { ShortcutListComponent } from '../shortcut-list/shortcut-list.component';
import { SignupComponent } from '../../pages/auth/signup/signup.component';
import { EXAMPLE_PROJECT_ID } from '../../../environments/publicTranscriptionExample';
import { ProjectService } from '@type-mvp/app/services/project.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit {
    @ViewChild('contactSelectionDialog') contactSelectionDialog;
    @ViewChild('tutorialSelectionDialog') tutorialSelectionDialog;
    @ViewChild('logoHoverTrigger') logoHoverTriggerElement: ElementRef;
    routerChanges$ = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith({ url: this.router.url })
    );
    onLogin$ = this.routerChanges$.pipe(
        map(({ url }: NavigationEnd) => url.includes('login') || url.includes(routes.SHARE))
    );

    onProjects$ = this.routerChanges$.pipe(map(({ url }: NavigationEnd) => url.includes('projects')));
    onEditor$ = this.routerChanges$.pipe(map(({ url }: NavigationEnd) => url.includes('editor')));

    onUploadPage = this.routerChanges$.pipe(
        map(({ url }: NavigationEnd) => url.includes('editor') && url.includes('isNew'))
    );
    onMobileUploadPage = this.routerChanges$.pipe(map(({ url }: NavigationEnd) => url.includes('upload')));
    onAuth$ = this.routerChanges$.pipe(map(({ url }: NavigationEnd) => url.includes('auth')));
    titleEditing = false;
    hideTitle$ = this.routerChanges$.pipe(
        map(
            ({ url }: NavigationEnd) =>
                !url.includes(routes.EDITOR) && !url.includes(routes.EDITOR + '/' + EXAMPLE_PROJECT_ID)
        )
    );

    isExampleEditor$ = this.editorService.currentProject$.pipe(map((project) => project?.isExample));
    currentProject$ = this.editorService.currentProject$;

    editedTitleInput: ElementRef;
    isOnRecorderPage$ = this.recorderService.showScreenRecorder$;
    selectedContactForm: string;
    isMobile: boolean;

    private contactSelectionDialogRef: DialogRef;
    private tutorialSelectionDialogRef: DialogRef;

    constructor(
        public auth: AuthService,
        private projectService: ProjectService,
        private editorService: EditorService,
        public userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        public embedService: EmbedService,
        private dialogService: DialogService,
        private recorderService: RecorderService,
        private cdRef: ChangeDetectorRef
    ) {
        this.isMobile = document.documentElement.clientWidth < 640;
        // if initial page is sharing page route event wont get triggered - in that case hide title

        // if (this.router.url.includes(routes.SHARE)) {
        //     this.hideTitle = true;
        // }

        this.isOnRecorderPage$.subscribe((isOnPage) => {
            if (isOnPage) {
                return;
            }
            this.recorderService.stopScreenPreview();
            this.recorderService.stopCamPreview();
            this.recorderService.stopMicrophonePreview();
        });
    }

    ngAfterViewInit(): void {}
    //     //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //     //Add 'implements AfterViewInit' to the class.
    //     console.log(0);

    //     this.cdRef.detectChanges();

    //     this.initNavbarPropertiesFromUrl();
    //     console.log(1);
    // }
    // initNavbarPropertiesFromUrl() {
    //     this.router.events
    //         .pipe(
    //             filter((event) => event instanceof NavigationEnd),
    //             startWith({ url: this.router.url })
    //         )
    //         .subscribe(({ url }: NavigationEnd) => {
    //             console.log('a');

    //             if (!url.includes(routes.EDITOR)) {
    //                 this.recorderService.showScreenRecorder$.next(false);
    //             }
    //             this.cdRef.detectChanges();
    //         });
    // }
    @ViewChild('editedTitleInput')
    set editTitleInputProperty(content: ElementRef) {
        if (content) {
            // initially setter gets called with undefined
            this.editedTitleInput = content;
            this.editedTitleInput.nativeElement.focus();
            // keycodes that should be blocked since they are used in the editing
            const editingBlockedKey = [8, 32, 37, 39, 46];

            this.editedTitleInput.nativeElement.addEventListener('keydown', (event) => {
                if (editingBlockedKey.includes(event.keyCode)) {
                    event.stopPropagation();
                }
            });
            this.editedTitleInput.nativeElement.addEventListener('keyup', (event) => {
                if (event.keyCode === 13) {
                    event.preventDefault();
                    this.finishEditingTitle();
                    event.stopPropagation();
                }
                if (editingBlockedKey.includes(event.keyCode)) {
                    event.stopPropagation();
                }
            });
            this.editedTitleInput.nativeElement.addEventListener('focusout', () => {
                this.finishEditingTitle();
            });
        }
    }

    backToProjects() {
        this.editorService.pause();
        this.logoHoverTriggerElement?.nativeElement.classList.remove('logo-hover');
        switch (this.router.url) {
            case routes.AUTH_LOGIN:
            case routes.AUTH_SIGNUP:
                window.location.href = 'https://streamlabs.com';

                break;

            default: {
                // delete params when going back from projects page or example editor page
                let params = { ...this.route.snapshot.queryParams };

                if (params?.isNew) {
                    delete params.isNew;
                }
                if (params?.url) {
                    delete params.url;
                }
                if (this.router.url.includes(routes.PROJECTS)) {
                    if (params?.crumbs) {
                        if (Array.isArray(params.crumbs)) {
                            params = { crumbs: params.crumbs.splice(0, params.crumbs.length - 1) };
                        } else {
                            delete params.crumbs;
                        }
                    }
                }

                this.router.navigate([routes.PROJECTS], { queryParams: params }).then();
                break;
            }
        }
    }

    switchOnboarding(destination: 'login' | 'signup') {
        this.onAuth$.pipe(first()).subscribe(() => {
            const utmParamQueryString = new URLSearchParams(window.location.search);

            if (utmParamQueryString) {
                this.router.navigateByUrl('auth/' + destination + '?' + utmParamQueryString.toString()).then();
            }
        });
    }

    openContactUs() {
        window.open('https://support.streamlabs.com/hc/en-us/requests/new?ticket_form_id=18779381763483', '_blank');
        // this.contactSelectionDialogRef = this.dialogService.openDialog(this.contactSelectionDialog, {
        //     minWidth: 290
        // });
    }

    openShortcutList() {
        this.dialogService.openDialog(ShortcutListComponent, { maxWidth: 1300 });
    }

    openTutorials() {
        this.tutorialSelectionDialogRef = this.dialogService.openDialog(this.tutorialSelectionDialog);
    }

    /* Next 4 methods are for the buttons in the contact / tutorial selection dialogs*/
    openHelpCenter() {
        window.open('https://streamlabs.com/content-hub/tools/podcast-editor', '_blank');
        // this.tutorialSelectionDialogRef.close();
    }

    openVideoTutorials() {
        window.open('https://www.youtube.com/channel/UCcXIB7rkjjPKy59ko6TvewQ', '_blank');
        // this.tutorialSelectionDialogRef.close();
    }

    async signOut() {
        await this.auth.signOut();
        this.router.navigate([routes.LOGIN]).then();
    }

    navigateToSignup() {
        this.router.navigate([routes.AUTH_SIGNUP]).then();
    }
    checkRoute() {
        if (this.logoHoverTriggerElement) {
            if (this.router.url.includes(routes.PROJECTS) && !this.route.snapshot.queryParams.crumbs) {
                // Reload page if user is in project Overview
                // window.location.reload();
                // console.log('remove css class');
                this.logoHoverTriggerElement.nativeElement.classList.remove('logo-hover');
            } else {
                // Toggle menu if user is everywhere else
                // this.homeMenuComponent.toggleMenu();
                // console.log('add css class');
                this.logoHoverTriggerElement.nativeElement.classList.add('logo-hover');
            }
        }
    }

    startEditingTitle() {
        this.titleEditing = true;
        this.editorService.removeSelection();
    }

    finishEditingTitle() {
        if (this.titleEditing) {
            if (this.editedTitleInput.nativeElement.value) {
                this.editorService.updateTitle(this.editedTitleInput.nativeElement.value);
                this.titleEditing = false;
            }
        }
    }

    reloadPage() {
        window.location.reload();
    }

    signUpOrLogin() {
        if (!this.userService.user.uploadedOnce) {
            this.signOut();
            this.router.navigate([routes.LOGIN]);
        } else {
            this.openSignUpDialog();
        }
    }

    openSignUpDialog() {
        this.dialogService.openDialog(SignupComponent, {
            maxWidth: 1300,
            data: { hideLogin: true, fromAnonymous: true, openPayment: false }
        });
    }

    async newProject() {
        const isMobile = this.isMobile;
        const queryParams = { isNew: true };
        const route = isMobile ? routes.UPLOAD : routes.EDITOR;
        const newProject = await this.projectService.createProject();

        await this.router.navigate([routes.PROJECTS]);
        this.router.navigate([route, newProject.id], { queryParams });
    }
    generateProjectId() {
        return this.projectService.generateId();
    }
}
