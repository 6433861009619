import { Transcription } from '@type/shared-models/editor/transcription';
import { Command } from '../command';
import { IDatasource } from 'ngx-ui-scroll';
import { WordIndex } from '@type/shared-models/editor/word';
import { BehaviorSubject } from 'rxjs';
import { EditorService } from '../../services/editor.service';

export class LinebreakAddCommand implements Command {
    private readonly wordWithLinebreakIndex: number;
    private transcription: Transcription;
    private datasource: BehaviorSubject<IDatasource>;
    private readonly paragraphIndex: number;
    private readonly newParagraphs: WordIndex[][];
    private editorService: EditorService;
    constructor(linebreakAddObject: {
        index: number;
        currentTranscription: Transcription;
        datasource: BehaviorSubject<IDatasource>;
        paragraphIndex: number;
        newParagraphs: WordIndex[][];
        editorService;
    }) {
        this.wordWithLinebreakIndex = linebreakAddObject.index;
        this.transcription = linebreakAddObject.currentTranscription;
        this.datasource = linebreakAddObject.datasource;
        this.paragraphIndex = linebreakAddObject.paragraphIndex;
        this.newParagraphs = linebreakAddObject.newParagraphs;
        this.editorService = linebreakAddObject.editorService;
    }

    execute() {
        this.editorService.jumpToWordIfNotInside({
            startWord: this.wordWithLinebreakIndex,
            endWord: this.wordWithLinebreakIndex
        });
        this.transcription.setLinebreak(this.wordWithLinebreakIndex, true);
        this.datasource.getValue().adapter.relax().then();
        this.datasource
            .getValue()
            .adapter.replace({
                predicate: ({ $index }) => this.paragraphIndex === $index,
                items: this.newParagraphs
            })
            .then(() => {
                const scrollToSpan = document.getElementById('word_' + this.wordWithLinebreakIndex);
                scrollToSpan.scrollIntoView({ block: 'center' });
            });
        this.editorService.chunkWordArrayResult.splice(this.paragraphIndex, 1, ...this.newParagraphs);
        this.editorService.updateWords([this.wordWithLinebreakIndex]);
        this.editorService.updateSelectedParagraphs();
        this.editorService.showTextLoading$.next(false);
        return this;
    }

    undo() {
        this.editorService.jumpToWordIfNotInside({
            startWord: this.wordWithLinebreakIndex,
            endWord: this.wordWithLinebreakIndex
        });
        this.transcription.setLinebreak(this.wordWithLinebreakIndex, false);
        this.datasource.getValue().adapter.relax().then();
        const updatedParagraphs = this.newParagraphs[0]
            .concat(this.newParagraphs[1])
            .filter((wordIndex) => wordIndex !== undefined);
        this.datasource
            .getValue()
            .adapter.replace({
                predicate: ({ $index }) =>
                    $index >= this.paragraphIndex && $index < this.paragraphIndex + this.newParagraphs.length,
                items: [updatedParagraphs]
            })
            .then();
        this.editorService.chunkWordArrayResult.splice(
            this.paragraphIndex,
            this.newParagraphs.length,
            ...[updatedParagraphs]
        );
        this.editorService.updateWords([this.wordWithLinebreakIndex]);
    }
}
