import { Component } from '@angular/core';
import { DialogRef } from '../dialog-ref';
import { OverlayComponentType } from '../overlay-component.model';
import { overlayAnimations } from '@type/shared/animations/overlay.animations';
import { ToastDataModel } from '@type/dialog';

@Component({
    template: `
        <div
            [@transformMenu]="_panelAnimationState"
            class="flex shadow-md gap-2 items-center bg-white border-radius-lg p-4"
        >
            <svg-icon *ngIf="data.iconSrc" [src]="data.iconSrc"></svg-icon>
            {{ data.message }}
            <app-button
                *ngIf="data.actionLabel"
                [color]="data.buttonColor || 'primary'"
                (btnClick)="data.callBack()"
                [size]="data.buttonSize || 'md'"
                >{{ data.actionLabel }}</app-button
            >
        </div>
    `,
    animations: overlayAnimations
})
export class ToastComponent extends OverlayComponentType {
    data: ToastDataModel;
    _panelAnimationState: 'void' | 'enter' = 'void';
    constructor(ref: DialogRef) {
        super();
        this._panelAnimationState = 'enter';
        this.ref = ref;
        this.data = ref.config.data;
        setTimeout(() => {
            this.close();
        }, this.ref.config.timeToLive);
    }

    close(result?) {
        this._panelAnimationState = 'void';

        setTimeout(() => {
            super.close(result);
        }, 300);
    }
}
