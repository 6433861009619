import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { ExportAuthComponent } from './pages/auth/exportauth/export-auth.component';
import { LinkComponent } from './pages/link/link.component';
import { SafeSpaceComponent } from './safe-space.component';
import { SlidStorageComponent } from './pages/slid-storage/slid-storage.component';

export const appRoutes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule)
    },
    {
        path: 'login',
        redirectTo: 'auth/login'
    },
    {
        path: 'signup',
        redirectTo: 'auth/signup'
    },
    {
        path: 'exportauth/:type',
        component: ExportAuthComponent
    },
    // TODO: remove this redirect before merging
    {
        path: 'password-reset',
        redirectTo: 'auth/password-reset'
    },
    {
        path: 'editor',
        redirectTo: 'projects'
    },
    {
        path: 'editor/:id',
        loadChildren: () => import('./pages/main-editor/main-editor.module').then((m) => m.MainEditorModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'upload',
        loadChildren: () =>
            import('./pages/mobile-media-upload/mobile-media-upload.module').then((m) => m.MediaUploadModule),
        canActivate: [AuthGuard]
        // {
        //     if (document.documentElement.clientWidth < 640) {
        //         Replace with upload page
        // return ;
        // }
        // return import('./pages/MainEditor/main-editor.module').then((m) => m.MainEditorModule);
        // },
    },
    {
        path: 'accept-invite',
        loadChildren: () => import('./pages/invite-landing/invite-landing.module').then((m) => m.InviteLandingModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'safe-space',
        component: SafeSpaceComponent
    },
    {
        path: 'projects',
        loadChildren: () => import('./pages/projects/projects.module').then((m) => m.ProjectsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'share/:id',
        loadChildren: () => import('./pages/share/share.module').then((m) => m.ShareModule)
    },
    {
        path: 'home',
        redirectTo: 'share'
    },
    {
        path: 'share',
        redirectTo: 'projects'
    },
    {
        path: 'report',
        loadChildren: () => import('./pages/bug-report/bug-report.module').then((m) => m.BugReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfileModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'pricing',
        loadChildren: () => import('./pages/pricing/pricing.module').then((m) => m.PricingModule),
        canActivate: [AuthGuard]
    },
    { path: '', redirectTo: 'projects', pathMatch: 'full' },
    {
        path: 'link',
        component: LinkComponent
    },
    {
        path: 'storage/:id',
        component: SlidStorageComponent
    }
];
