import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@type-mvp/app/services/auth.service';
import { ProjectService } from '@type-mvp/app/services/project.service';

import { UserService } from '@type-mvp/app/services/user.service';
import routes from '@type/shared-models/consts/routes';
import { getCookie } from '@type/shared/utils/cookies';
import { take } from 'rxjs';

@Component({
    selector: 'app-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {
    isSignedIn = false;
    constructor(
        private router: Router,
        private angularFireAuth: AngularFireAuth,

        private projectService: ProjectService,
        private activatedRoute: ActivatedRoute
    ) {
        this.angularFireAuth.authState.pipe(take(1)).subscribe(async (firebaseAuth) => {
            this.isSignedIn = !!firebaseAuth;

            if (this.isSignedIn) {
                // console.log('isSignedIn');

                const linkUrl = this.activatedRoute.snapshot.queryParamMap.get('url');
                await this.createProject(linkUrl);
            } else {
                if (getCookie('wasAnonymous') === 'true') {
                    // console.log('isAnon && notSignedIn in -> login');
                    this.router.navigate([routes.LOGIN], { queryParamsHandling: 'preserve' });
                } else {
                    // console.log('!Anon && notSignedIn -> anonymous');
                    this.router.navigate([routes.ANONYMOUS_LOGIN], { queryParamsHandling: 'preserve' });
                }
            }
        });
    }

    ngOnInit(): void {}

    async createProject(uploadUrl?: string) {
        // if (!this.userService.user.isVerified) {
        //     this.userService.openUserConfirmationModalIfUnverified();
        //     return;
        // }
        // User is Pro or has projects left

        const newProject = await this.projectService.createProject();
        const isMobile = document.documentElement.clientWidth < 640;
        const queryParams = { isNew: true, crumbs: null, url: null || uploadUrl };
        const route = isMobile ? routes.UPLOAD : routes.EDITOR;
        this.router.navigate([route, newProject.id], { queryParams, queryParamsHandling: 'merge' });
    }
}
