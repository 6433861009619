import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-menu-search',
    template: `
        <label class="flex items-center p-2 typ-menu-search">
            <svg-icon class="text__cta pl-3 pr-2" src="/assets/ic/24/search.svg"></svg-icon>
            <input
                autofocusDirective
                #inputElement
                class="form-input"
                type="text"
                (input)="searchString.emit(inputElement.value)"
                [placeholder]="placeholder"
            />
        </label>
    `
})
export class MenuSearchComponent {
    @Input() placeholder: string;
    @ViewChild('inputElement') inputElement: ElementRef;
    @Output() searchString = new EventEmitter<string>();

    @HostListener('click', ['$event']) clickHandler(event: MouseEvent) {
        event.stopPropagation();
    }
}
