import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-dialog-back-button',
    template: `
        <app-button
            data-spec="dialog-back-button"
            color="secondary"
            size="md"
            [disabled]="disabled"
            aria-label="Back to previous dialog section"
        >
            <svg-icon appIcon src="/assets/ic/16/back.svg"></svg-icon>
        </app-button>
    `
})
export class DialogBackButtonComponent {
    @Input() disabled: boolean;
}
