import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Pipe({
    name: 'isSubtitleBreak'
})
export class IsSubtitleBreakPipe implements PipeTransform {
    transform(
        items: {
            isHovered$?: BehaviorSubject<boolean>;
            start: number;
            isEditActive$?: BehaviorSubject<boolean>;
            isDragged$?: BehaviorSubject<boolean>;
            end: number;
        }[],
        wordIndex: number
    ): {
        isHovered$?: BehaviorSubject<boolean>;
        start: number;
        isEditActive$?: BehaviorSubject<boolean>;
        isDragged$?: BehaviorSubject<boolean>;
        end: number;
    } {
        return items ? items?.find((item) => item.end === wordIndex) : null;
    }
}
