import { Component } from '@angular/core';
import { DialogRef } from '../dialog-ref';
import { OverlayComponentType } from '../overlay-component.model';
import { overlayAnimations } from '@type/shared/animations';
import { TutorialDataModel } from '@type/dialog';

@Component({
    template: `
        <div
            [@transformMenu]="_panelAnimationState"
            class="flex shadow-md gap-2 items-center bg-white border-radius-lg p-4 tutorial-wrapper"
        >
            <video #video (ended)="videoCompleted(); video.play()" class="tutorial-video" autoplay="autoplay">
                <source [src]="data.videoUri" type="video/mp4" />
            </video>

            <app-button color="secondary" class="tutorial-close-button" (btnClick)="close()" size="sm">
                <svg-icon class="typ-icon" src="/assets/ic/24/close.svg"></svg-icon>
            </app-button>
        </div>
    `,
    animations: overlayAnimations,
    styles: [
        `
            .tutorial-wrapper {
                position: relative;
                height: 5.5rem;
                width: 27.5rem;
            }

            .tutorial-close-button {
                position: absolute;
                top: 1rem;
                right: 1rem;
            }
        `
    ]
})
export class TutorialComponent extends OverlayComponentType {
    data: TutorialDataModel;
    _panelAnimationState: 'void' | 'enter' = 'void';
    private playCount = 0;
    constructor(ref: DialogRef<TutorialDataModel>) {
        super();
        this._panelAnimationState = 'enter';
        this.ref = ref;
        this.data = ref.config.data;
    }

    close(result?) {
        this._panelAnimationState = 'void';

        setTimeout(() => {
            super.close(result);
        }, 300);
    }

    videoCompleted() {
        this.playCount++;
        if (this.playCount >= 1) this.close();
    }
}
