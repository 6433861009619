import { removeEmpty } from '../remove-empty';
import { roundTime } from '../shared-utils/time-utils';

export interface WordIndex {
    index: number;
    word: Word;
}

export interface FbWord {
    t: string;
    sto: number;
    eto: number;
    iC: boolean;
    iP: boolean;
    hL: boolean;
    vI: number;
    sT?: number;
    hS?: boolean;
    mI?: number; // mediaIndex
    cS?: number;
}
export class Word {
    // properties available from firebase
    text: string;
    startTimeInEdit: number;
    endTimeInEdit: number;
    isCut = false;
    isPause = false;
    hasLinebreak = false;
    hasSubtitlebreak?: boolean;
    speakerTag?: number;
    confidence?: number;

    // properties generated after loaded
    mediaIndex: number;
    chunkIndex: number;
    startTimeInOriginal: number;
    endTimeInOriginal: number;

    public get isEndOfSentence(): boolean {
        const punctuation = ['.', '?', '!', '。'];
        return this.text && punctuation.some((item) => this.text.includes(item));
    }

    public get hasPunctuation(): boolean {
        const punctuation = ['.', '?', '!', ':', '。'];
        return this.text && punctuation.some((item) => this.text.includes(item));
    }

    public get hasPunctuationAndComma(): boolean {
        const punctuation = ['.', ',', '?', '!', ':', ';', '。'];
        return this.text && punctuation.some((item) => this.text.includes(item));
    }
    public get isFiller(): boolean {
        const fillers = ['um', 'uh', 'hmm', 'mhm', 'uh huh'];
        return this.text && fillers.some((item) => this.text.toLowerCase().includes(item));
    }

    public get duration(): number {
        return this.endTimeInEdit - this.startTimeInEdit;
    }
    public get isRealWord(): boolean {
        return this.isCut !== true && this.isPause !== true;
    }

    public setMediaIndex(mediaIndex: number) {
        this.mediaIndex = mediaIndex;
        return this;
    }
    public setChunkIndex(chunkIndex: number) {
        this.chunkIndex = chunkIndex;
        return this;
    }

    constructor() {
        return this;
    }
    public clone(word: Word): Word {
        this.text = word.text;

        // this.startTimeInEdit = word.startTimeInEdit;
        // this.endTimeInEdit = word.endTimeInEdit;

        this.startTimeInEdit = word.startTimeInOriginal;
        this.endTimeInEdit = word.endTimeInOriginal;

        this.startTimeInOriginal = word.startTimeInOriginal;
        this.endTimeInOriginal = word.endTimeInOriginal;

        this.isCut = word.isCut || false;
        this.isPause = word.isPause || false;
        this.hasLinebreak = word.hasLinebreak || false;
        this.hasSubtitlebreak = word.hasSubtitlebreak;
        this.speakerTag = word.speakerTag;
        this.confidence = word.confidence;

        this.mediaIndex = word.mediaIndex;
        this.chunkIndex = word.chunkIndex;
        return this;
    }

    // start and endTime functions

    // moves word forwards - appears earlier
    public moveForwardsFromOriginal(time: number) {
        this.startTimeInEdit = roundTime(this.startTimeInOriginal - time);
        this.endTimeInEdit = roundTime(this.endTimeInOriginal - time);
    }
    // moves word backwards - appears later
    public moveBackwardsFromOriginal(time: number) {
        this.startTimeInEdit = roundTime(this.startTimeInOriginal + time);
        this.endTimeInEdit = roundTime(this.endTimeInOriginal + time);
    }

    public toFirebaseString(includeMediaIndex = false): string {
        return JSON.stringify(removeEmpty(this.toFirebase(includeMediaIndex)));
    }
    public toFirebase(includeMediaIndex: boolean): FbWord {
        const fbWord: FbWord = {
            t: this.text || null,
            sto: this.startTimeInOriginal || null,
            eto: this.endTimeInOriginal || null,
            iC: this.isCut || null,
            iP: this.isPause || null,
            hL: this.hasLinebreak || null,
            vI: this.mediaIndex || null,
            sT: this.speakerTag ?? null,
            hS: this.hasSubtitlebreak ?? null,
            cS: this.confidence ?? null
        };
        if (includeMediaIndex) {
            fbWord.mI = this.mediaIndex;
        }
        return fbWord;
    }

    public fromFbWord(fbWord: FbWord): Word {
        this.text = fbWord.t || '';

        this.startTimeInOriginal = fbWord.sto || 0;
        this.endTimeInOriginal = fbWord.eto || 0;

        this.startTimeInEdit = this.startTimeInOriginal;
        this.endTimeInEdit = this.endTimeInOriginal;

        this.isCut = fbWord.iC || false;
        this.isPause = fbWord.iP || false;
        this.hasLinebreak = fbWord.hL || false;
        this.hasSubtitlebreak = fbWord.hS ?? null;
        this.speakerTag = fbWord.sT || 0;

        this.mediaIndex = fbWord.vI;
        this.confidence = fbWord.cS;
        return this;
    }
    public fromFirebase(word: string | FbWord): Word {
        if (typeof word === 'string') {
            const parsedWord = JSON.parse(word) as Word;
            this.text = parsedWord.text || '';

            this.startTimeInEdit = parsedWord.startTimeInEdit || 0;
            this.endTimeInEdit = parsedWord.endTimeInEdit || 0;
            this.startTimeInOriginal = parsedWord.startTimeInOriginal || 0;
            this.endTimeInOriginal = parsedWord.endTimeInOriginal || 0;

            this.isCut = parsedWord.isCut || false;
            this.isPause = parsedWord.isPause || false;
            this.hasLinebreak = parsedWord.hasLinebreak || false;
            this.hasSubtitlebreak = parsedWord.hasSubtitlebreak ?? null;
            this.speakerTag = parsedWord.speakerTag || 0;
            this.confidence = parsedWord.confidence || null;

            return this;
        } else if (word.sto || word.eto || word.iC || word.iP) {
            this.text = word.t || '';

            // this.startTimeInEdit = parsedWord.startTimeInEdit || 0;
            // this.endTimeInEdit = parsedWord.endTimeInEdit || 0;

            // this.startTimeInEdit = parsedWord.startTimeInOriginal || 0;
            // this.endTimeInEdit = parsedWord.endTimeInOriginal || 0;

            this.startTimeInOriginal = word.sto || 0;
            this.endTimeInOriginal = word.eto || 0;

            this.startTimeInEdit = this.startTimeInOriginal;
            this.endTimeInEdit = this.endTimeInOriginal;

            this.isCut = word.iC || false;
            this.isPause = word.iP || false;
            this.hasLinebreak = word.hL || false;
            this.hasSubtitlebreak = word.hS ?? null;
            this.speakerTag = word.sT || 0;
            this.confidence = word.cS || null;

            this.mediaIndex = word.mI;

            return this;
        } else {
            console.log('from Firebase legacy');

            //legacy data structure
            return this.fromLegacyWord(word);
        }
    }

    fromLegacyWord(word): Word {
        this.text = word.word || '';

        this.startTimeInEdit = roundTime(word.startTime.seconds + word.startTime.nanos / 1000 / 1000 / 1000) || 0;
        this.endTimeInEdit = roundTime(word.endTime.seconds + word.endTime.nanos / 1000 / 1000 / 1000) || 0;
        this.startTimeInOriginal = roundTime(word.startTime.seconds + word.startTime.nanos / 1000 / 1000 / 1000) || 0;
        this.endTimeInOriginal = roundTime(word.endTime.seconds + word.endTime.nanos / 1000 / 1000 / 1000) || 0;

        this.isCut = word.isCutword || false;
        this.isPause = word.isPause || false;
        this.hasLinebreak = word.hasLinebreak || false;
        this.hasSubtitlebreak = word.hS ?? null;
        this.speakerTag = word.speakerTag || 0;

        this.mediaIndex = 0;
        return this;
    }

    public fromTranscriptionService(
        text: string,
        startTime: number,
        endTime: number,
        speakerTag: number,
        confidence: number
    ): Word {
        if (text.includes('<') && text.includes('>', text.length - 2)) {
            this.isPause = true;
        } else {
            this.text = text;
        }
        this.startTimeInEdit = startTime;
        this.startTimeInOriginal = startTime;
        this.endTimeInEdit = endTime;
        this.endTimeInOriginal = endTime;
        this.speakerTag = speakerTag || 0;
        this.confidence = confidence;
        return this;
    }

    public init(startTime: number, endTime: number, mediaIndex: number, speakerTag: number) {
        this.startTimeInEdit = startTime;
        this.endTimeInEdit = endTime;
        this.startTimeInOriginal = startTime;
        this.endTimeInOriginal = endTime;
        this.mediaIndex = mediaIndex;
        this.speakerTag = speakerTag;
        return this;
    }
    public initPauseWord(startTime: number, endTime: number, mediaIndex: number, speakerTag: number) {
        this.isPause = true;
        return this.init(startTime, endTime, mediaIndex, speakerTag);
    }
    public initZero() {
        this.startTimeInEdit = 0;
        this.endTimeInEdit = 0;
        this.startTimeInOriginal = 0;
        this.endTimeInOriginal = 0;
        return this;
    }
    public initCutWord() {
        this.isCut = true;
        return this;
    }
    public initLinebreak() {
        this.hasLinebreak = true;
        return this;
    }

    public equals(otherWord: Word): boolean {
        // properties available from firebase
        return (
            this.text === otherWord.text &&
            this.startTimeInEdit === otherWord.startTimeInEdit &&
            this.endTimeInEdit === otherWord.endTimeInEdit &&
            this.isCut === otherWord.isCut &&
            this.isPause === otherWord.isPause &&
            this.hasLinebreak === otherWord.hasLinebreak &&
            this.hasSubtitlebreak === otherWord.hasSubtitlebreak &&
            this.speakerTag === otherWord.speakerTag &&
            this.mediaIndex === otherWord.mediaIndex &&
            this.chunkIndex === otherWord.chunkIndex &&
            this.startTimeInOriginal === otherWord.startTimeInOriginal &&
            this.endTimeInOriginal === otherWord.endTimeInOriginal
        );
    }
}
