import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from './toast.service';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToastComponent implements OnInit, OnDestroy {
    @Input() id: string;
    private element: any;
    private timer: any;
    private delayTimeout: any;

    timerObservable: BehaviorSubject<number>;
    constructor(private toastService: ToastService, private el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        // ensure id attribute exists
        if (!this.id) {
            console.error('toast must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // close toastd on background click
        this.element.addEventListener('click', (el) => {
            if (el.target.className === 'app-toast') {
                this.close();
            }
        });

        // add self (this toast instance) to the toast service so it's accessible from controllers
        this.toastService.add(this);
    }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.toastService.remove(this.id);
        this.element.remove();
    }

    // open Toast for duration milliseconds
    open(duration: number): BehaviorSubject<number> {
        if (this.timerObservable) {
            this.cancel();
        }
        this.timerObservable = new BehaviorSubject(duration);
        console.log('open toast inner ');
        this.element.style.display = 'block';
        document.body.classList.remove('app-toast-close');
        document.body.classList.add('app-toast-open');
        this.start(duration);
        return this.timerObservable;
    }

    // close modal
    close(): void {
        document.body.classList.remove('app-toast-open');
        document.body.classList.add('app-toast-close');
        // this.element.style.display = 'none';
        // delay display none for 500millis
        this.delayTimeout = setTimeout(() => {
            this.element.style.display = 'none';
        }, 500);
    }

    private start(duration: number): void {
        if (this.delayTimeout) {
            clearTimeout(this.delayTimeout);
        }
        this.timer = setTimeout(() => {
            this.close();
            this.timerObservable.next(0);
            this.timerObservable = null;
        }, duration);
    }

    cancel() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timerObservable.next(-1);
            this.close();
            this.timerObservable = null;
        }
    }
}
