import { NgModule } from '@angular/core';
import { TypSelectFieldComponent } from '@type-mvp/app/forms/typ-select-field/typ-select-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TypInputFieldComponent } from '@type-mvp/app/forms/typ-input-field/typ-input-field.component';
import { TypSelectFieldOptionComponent } from './typ-select-field/typ-select-field-option.component';
import { DropdownComponent } from './typ-select-field/dropdown.component';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { UiModule } from '@type/ui';

const exportItems = [TypSelectFieldComponent, TypInputFieldComponent, TypSelectFieldOptionComponent];

@NgModule({
    imports: [ReactiveFormsModule, CommonModule, OverlayModule, PortalModule, AngularSvgIconModule, UiModule],
    declarations: [...exportItems, DropdownComponent],
    exports: [...exportItems]
})
export class TypFormsModule {}
