import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';

import {
    ThemingColorType,
    ThemingSizeType,
    HTMLButtonOrientation,
    HTMLButtonType,
    HTMLTextAlignment,
    ThemingIconPosition
} from '../models/theming';

@Component({
    selector: 'app-button:not([href])',
    template: `
        <button
            #buttonElement
            class="typ-button-base"
            [ngClass]="_buttonClasses"
            [disabled]="disabled || inProgress"
            [type]="type"
            [style]="_appliedStyles"
            [class.cursor-progress]="inProgress && size !== 'lg'"
        >
            <ng-content select="[appIcon]:not([justify='end'])"></ng-content>
            <span class="typ-button__label text-no-overflow">
                <ng-content></ng-content>
            </span>
            <ng-content select="[justify='end']"></ng-content>
            <span *ngIf="color === 'premium'"><svg-icon class="flex" src="/assets/ic/24/sparkles.svg"></svg-icon></span>
            <type-loader [color]="color" size="sm" *ngIf="inProgress"></type-loader>
        </button>
    `,
    styleUrls: [`./button.component.scss`]
})
export class ButtonComponent implements OnChanges {
    @ViewChild('buttonElement', { read: ElementRef }) buttonElement: ElementRef<HTMLButtonElement>;
    // styles
    @Input() size: ThemingSizeType = 'lg';
    @Input() orientation: HTMLButtonOrientation = 'horizontal';
    @Input() color: ThemingColorType = 'default';
    @Input() font;
    @Input() textAlignment: HTMLTextAlignment = 'center';
    @Input() icon: ThemingIconPosition = 'center';
    @Input() isDropdown = false;
    @Input() customStyles;

    // states
    @Input() inProgress = false;
    @Input() disabled = false;
    @Input() type: HTMLButtonType = 'button';
    @Input() isActive: boolean;

    @Output() btnClick = new EventEmitter<MouseEvent>();
    @Output() btnMouseDown = new EventEmitter<MouseEvent>();

    _appliedStyles = {
        ['border-radius']: '1rem'
    };
    _buttonClasses: { [prop: string]: boolean } = { 'typ-button-default': true };

    ngOnChanges({ color, size, orientation, textAlignment, icon, isActive, isDropdown, customStyles }: SimpleChanges) {
        if (color) {
            const previousValue = color.previousValue ?? 'default';
            const currentValue = color.currentValue ?? 'default';
            this._buttonClasses[`typ-button-${previousValue}`] = false;
            this._buttonClasses[`typ-button-${currentValue}`] = true;
        }
        if (size) {
            const previousValue = size.previousValue ?? 'lg';
            const currentValue = size.currentValue ?? 'lg';
            this._buttonClasses[`typ-button-${previousValue}`] = false;
            this._buttonClasses[`typ-button-${currentValue}`] = true;
        }
        if (orientation) {
            const previousValue = orientation.previousValue ?? 'horizontal';
            const currentValue = orientation.currentValue ?? 'horizontal';
            this._buttonClasses[`typ-button-${previousValue}`] = false;
            this._buttonClasses[`typ-button-${currentValue}`] = true;
        }
        if (size || orientation || customStyles) {
            if (!this.size || this.size === 'lg') {
                this._appliedStyles = {
                    ['border-radius']: '1rem',
                    ...this.customStyles
                };
            } else {
                this._appliedStyles = {
                    ['border-radius']: '.5rem',
                    ...this.customStyles
                };
            }
        }
        if (textAlignment) {
            const previousValue = textAlignment.previousValue ?? 'center';
            const currentValue = textAlignment.currentValue ?? 'center';
            this._buttonClasses[`typ-button-${previousValue}`] = false;
            this._buttonClasses[`typ-button-${currentValue}`] = true;
        }
        if (icon) {
            const previousValue = icon.previousValue ?? 'lg';
            const currentValue = icon.currentValue ?? 'lg';
            this._buttonClasses[`typ-button-icon-${previousValue}`] = false;
            this._buttonClasses[`typ-button-icon-${currentValue}`] = true;

            // this._buttonClasses[`typ-button-icon-`] = this.icon;
        }
        if (isDropdown) {
            this._buttonClasses[`typ-button-is-dropdown`] = this.isDropdown;
        }
        if (isActive) {
            this._buttonClasses['typ-button-active'] = this.isActive;
        }
    }

    @HostListener('click', ['$event'])
    buttonClicked(e: MouseEvent) {
        if (this.disabled || this.inProgress) return;
        this.btnClick.emit(e);
    }

    @HostListener('mousedown', ['$event'])
    mouseDown(e: MouseEvent) {
        this.btnMouseDown.emit(e);
    }
}

@Component({
    selector: 'app-button[href]',
    template: `
        <a
            class="typ-button-base"
            [ngClass]="_buttonClasses"
            [type]="type"
            target="_blank"
            [href]="href"
            [style]="_appliedStyles"
            [class.cursor-progress]="inProgress && size !== 'lg'"
            [class.href-disabled]="disabled || inProgress"
        >
            <ng-content select="[appIcon]:not([justify='end'])"></ng-content>
            <span class="typ-button__label text-no-overflow" [class.loading-ellipsis]="inProgress && size === 'lg'">
                <ng-content></ng-content>
            </span>
            <ng-content select="[justify='end']"></ng-content>
            <span *ngIf="color === 'premium'"><svg-icon class="flex" src="/assets/ic/24/sparkles.svg"></svg-icon></span>
        </a>
    `,
    styleUrls: [`./button.component.scss`],
    styles: [
        `
            a {
                text-align: center;
            }
        `
    ]
})
export class ButtonAnchorComponent extends ButtonComponent {
    @Input() href: string;
}
