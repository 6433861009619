import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { EditorService } from './editor.service';

@Injectable({
    providedIn: 'root'
})
export class EmbedService {
    public isEmbedded = false;
    public onlyVideo = false;

    private url = location.origin + '/share/';

    constructor(private editorService: EditorService) {}

    private readParameter(queryParams: Observable<Params>): Promise<Params> {
        return new Promise((resolve) => {
            queryParams
                .pipe(
                    filter((parameter) => Object.keys(parameter).length !== 0),
                    first()
                )
                .subscribe((parameter) => {
                    resolve(parameter);
                });
        });
    }

    async initFromParameter(parameterQuery: Observable<Params>) {
        const parameter = await this.readParameter(parameterQuery);
        if (parameter.embed === 'true') {
            this.isEmbedded = true;
        }
        if (parameter.onlyVideo === 'true') {
            this.onlyVideo = true;
        }
    }

    public getSnippet(onlyVideo: boolean): string {
        return `
            <iframe
                id="type" src="${this.url + this.editorService.currentProject.id}?embed=true${
            onlyVideo ? '&onlyVideo=true' : ''
        }"
                style="overflow: hidden;"
                scrolling="no"
                frameborder="0"
                width="100%"
                height="900px"
                ></iframe>
            <script>
                window.addEventListener('message', (e) => {
                    const iframe = document.getElementById('type');iframe.style.height = e.data.height+ 50 +"px"
                });
            </script>`;
    }

    public getSharingUrl(): string {
        return this.url + this.editorService.currentProject.id;
    }
}
