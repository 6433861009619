import { OAuthAuthorizationResponse } from '@type/shared-models/sharing/o-auth-authorization';
import { PlanInterval } from '../payment/products';
// export type AuthenticateSlidRequest = OAuthAuthorizationResponse;
export type SlidCheckoutAction = 'checkout' | 'cancel' | 'payment-method';

//get checkout url
export interface GetSlidCheckoutUrlRequest {
    userId: string;
    checkoutAction: SlidCheckoutAction;
    planInterval: PlanInterval;
    fromLocalEnvironment: boolean;
}
export interface GetSlidCheckoutUrlResponse {
    slidCheckoutUrl?: string;
    error?: { status: number; message: string };
}

//get slid storage shared id
export interface GetSlidStorageSharedIdRequest {
    firebaseStorageUrl: string;
    userId: string;
}
export interface GetSlidStorageSharedIdResponse {
    slidStorageSharedId: string;
    error?: string;
}

// get slid storage url
export interface GetSlidStorageUrlRequest {
    storageId: string;
    userId: string;
}
export interface GetSlidStorageUrlResponse {
    slidStorageUrl?: string;
    error?: { status: number; message: string };
}

// get slid authentication url
export enum SlidAuthenticationIntents {
    CONFIRM = 'confirm',
    UPGRADE = 'upgrade',
    CONNECT = 'connect',
    UPGRADE_CONFIRM = 'upgrade_confirm'
}
export type SlidAuthenticationIntent = typeof SlidAuthenticationIntents[keyof typeof SlidAuthenticationIntents];

export interface GetSlidAuthenticationUrlRequest {
    fromLocalEnvironment?: boolean;
}
export interface GetSlidAuthenticationUrlResponse {
    slidAuthenticationUrl: string;
    codeVerifierKey: string;
}
export interface AuthenticateSlidRequest extends OAuthAuthorizationResponse {
    codeVerifierKey: string;
    anonymousUserId?: string;
    fromLocalEnvironment?: boolean;
}
export interface AuthenticateSlidResponse {
    firebaseToken?: string;
    wasSignup?: boolean;
    redirectUrl?: string;
    error?: string;
}

// update slid state
export interface UpdateSlidStateRequest {
    userId: string;
    forceRefresh: boolean;
    fromLocalEnvironment: boolean;
}
export interface UpdateSlidStateResponse {
    subscriptionStateChanged: boolean;
}
// link slid user
export interface LinkSlidRequest extends OAuthAuthorizationResponse {
    fromLocalEnvironment: boolean;
}
export interface LinkSlidResponse {
    subscriptionStateChanged: boolean;
}
