<app-dialog-header>
    Confirm language
    <app-dialog-close-button appDialogClose></app-dialog-close-button>
</app-dialog-header>
<p>
    Please confirm that the selected language is the <br />
    spoken language of your {{ mediaType }}.
</p>

<div class="grid gap-2 grid-cols-2 mt-6">
    <app-language-selection
        [languageSelectionType]="'transcription'"
        [selectedLanguage]="languageCodeLong$ | async"
        [disableSelectedLanguage]="false"
        [showBeta]="true"
        [showLanguageName]="true"
        (languageChanged)="languageChanged($event)"
    ></app-language-selection>
    <button class="button button__cta upgrade-button" (click)="closeDialogAndStartUpload()">Confirm language</button>
</div>
