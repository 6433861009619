import firebase from 'firebase/compat/app';
import FieldValue = firebase.firestore.FieldValue;
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MediaCanvasElement, ProjectCanvasElements } from '@type/shared-models/canvas-elements';
import paths from '@type/shared-models/consts/firebase-paths';
import { Visibility } from '@type/shared-models/consts/visibility';
import {
    MediaUrlWithIndex,
    Transcription,
    TranscriptionMap,
    TranscriptionMapElement
} from '@type/shared-models/editor/transcription';
import { FbWord, Word, WordIndex } from '@type/shared-models/editor/word';
import { Language } from '@type/shared-models/language';
import {
    AutoEditingConfig,
    DeleteProjectsRequest,
    FbPrivateProject,
    FbProject,
    Project,
    ProjectFromSelectionRequestModel,
    ProjectFromSelectionResponseModel
} from '@type/shared-models/project';
import { Resolution } from '@type/shared-models/resolution';
import { SubtitleConfig, SubtitleMode } from '@type/shared-models/subtitle-mode';
import { WordChunk, WordChunkIndex } from '@type/shared-models/transcription/words-chunk-range';
import { changeExtension, getMp3Path, getThumbnailPath } from '@type/shared-models/utils/file-utility';
import { combineLatest, forkJoin, from, merge, Observable, of, throwError } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    first,
    map,
    pairwise,
    shareReplay,
    skipWhile,
    switchMap,
    take
} from 'rxjs/operators';
import { UserService } from './user.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FirebaseFunctionNames } from '@type/shared-models/consts/firebase-function-names';
import { sortByProperty } from '@type/shared/utils/array';
import { AnalyticsService } from './analytics.service';
import { AnalyticsEvents } from '@type/shared-models/consts/analytic-events';
import { VoiceTranslationMode } from '@type/shared-models/translation/voice-translation-mode';
import { MemberMapModel, MemberModel, MemberRole, MemberStatus } from '@type/shared-models/member.models';
import { AddToFolderRequestModel, FolderModel, UpdateFolderNameRequestModel } from '@type/shared-models/folder.models';
import { DialogService } from '@type/dialog';
import { HintVideoModel } from '@type/shared-models/hint-video.model';
import { RenderingStates } from '@type/shared-models/consts/rendering-states';
import { StartEndConfig } from '@type/shared-models/start-end-word.model';
import { UploadTaskService } from './upload-task.service';
import { getExampleProject } from '../../environments/publicTranscriptionExample';
import { StartTranscribingRetainedMediaRequestData } from '@type/shared-models/request-data/start-retained-media-transcription-request-model.model';
import { Speaker } from '@type/shared-models/editor/speaker';
import { ClipConfig, ClipStates } from '@type/shared-models/rendering/clip-config';
import { CancelRenderingRequestModel } from '@type/shared-models/rendering/cancel-rendering-request.model';
import { TranscriptionChange } from '@type/shared-models/transcription/transcription-change.models';
import { environment } from '../../environments/environment';
import { SideMenuItemKey } from '../partials/side-menu/side-menu.component';
import { removeEmpty } from '@type/shared-models/remove-empty';
@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    private userId$ = this.userService.user$.pipe(
        filter((user) => !!user),
        map((user) => user.firebaseId),
        distinctUntilChanged()
    );

    get projectsCollection(): AngularFirestoreCollection<Partial<FbProject>> {
        return this.firestore.collection<FbProject>(paths.PROJECTS);
    }

    userProjects$ = this.getProjectByMembership().pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    constructor(
        private firestore: AngularFirestore,
        private storage: AngularFireStorage,
        private userService: UserService,
        private functions: AngularFireFunctions,
        private analyticsService: AnalyticsService,
        private dialogService: DialogService,
        private uploadTaskService: UploadTaskService
    ) {}

    getHintVideos(): Observable<HintVideoModel[]> {
        return this.functions.httpsCallable(FirebaseFunctionNames.hintVideos)({});
    }

    getProjectDocumentReference(projectId: string) {
        return this.firestore.collection(paths.PROJECTS).doc<Partial<FbProject>>(projectId);
    }

    getProjectByMembership(): Observable<FbProject[]> {
        const projectStream = this.userId$.pipe(
            switchMap((userId) =>
                this.firestore
                    .collection<FbProject>(paths.PROJECTS, (ref) => ref.where(`members.${userId}`, '!=', null))
                    .valueChanges()
                    .pipe(
                        map((projects) =>
                            projects.map(
                                (project) => ({ ...project, isProjectOwner: project.userId === userId } as FbProject)
                            )
                        )
                    )
            ),
            map((projects: FbProject[]) => projects?.sort(sortByProperty('lastUsedAt')).reverse())
        );
        return projectStream;
    }

    getUserProjectFolders(userId: string): Observable<FolderModel[]> {
        return this.firestore
            .collection<FolderModel>(paths.FOLDERS, (ref) => ref.where(`members.${userId}.firebaseId`, '==', userId))
            .valueChanges()
            .pipe(
                map((folders) =>
                    folders.map((folder) => ({
                        ...folder,
                        resourceType: 'folders'
                    }))
                ),
                map((folders) => folders.sort(sortByProperty('createdAt')).reverse() as FolderModel[])
            );
    }

    getMediaCanvasElementUrlChanges(projectId: string): Observable<MediaCanvasElement> {
        return this.getProjectDocumentReference(projectId)
            .valueChanges()
            .pipe(
                filter(
                    (project) =>
                        (!!project?.mainVideoEditorConfig?.canvasElements?.media[0]?.mediaUri ||
                            !!project?.canvasElements?.media[0]?.mediaUri) &&
                        !(project?.canvasElements?.media[0] as MediaCanvasElement)?.isMultilane
                ),
                map((project) => {
                    const mediaElement =
                        project?.mainVideoEditorConfig?.canvasElements?.media[0] || project?.canvasElements?.media[0];
                    return mediaElement;
                }),
                distinctUntilChanged(
                    (previousMediaElement, currentMediaElement) =>
                        previousMediaElement.mediaUri === currentMediaElement.mediaUri ||
                        previousMediaElement.mediaUrl === currentMediaElement.mediaUrl
                )
            );
    }
    getProjectTitleChanges(projectId: string): Observable<string> {
        return this.getProjectDocumentReference(projectId)
            .valueChanges()
            .pipe(
                filter((project) => !!project),
                map((project) => project.title),
                distinctUntilChanged()
            );
    }

    getProjectTranscription(
        projectId
    ): Observable<
        [
            words: Word[],
            mediaUrlsWithIndex: MediaUrlWithIndex[],
            newTranscriptionMap: TranscriptionMap,
            previousTranscriptionMap: TranscriptionMap,
            currentMediaElements: MediaCanvasElement[]
        ]
    > {
        return this.getProjectDocumentReference(projectId)
            .valueChanges()
            .pipe(
                filter(
                    (project) =>
                        project?.transcriptionMap?.length &&
                        (!!project?.mainVideoEditorConfig?.canvasElements?.media || !!project?.canvasElements?.media)
                ),
                map((project) => {
                    const mediaElements =
                        project?.mainVideoEditorConfig?.canvasElements?.media || project?.canvasElements?.media;
                    const mediaUrlsWithIndex: MediaUrlWithIndex[] = mediaElements.map((mediaElement, index) => ({
                        mediaUrl: mediaElement.previewUrl || mediaElement.mediaUrl,
                        mediaIndex: index
                    }));
                    return { transcriptionMap: project?.transcriptionMap, mediaUrlsWithIndex, mediaElements };
                }),
                switchMap((partialProject) => {
                    const wordArray$ = this.getWordArrayForProject(projectId, partialProject.transcriptionMap);
                    return combineLatest([
                        wordArray$,
                        of(partialProject.mediaUrlsWithIndex),
                        of(partialProject.transcriptionMap),
                        of(partialProject.transcriptionMap),
                        of(partialProject.mediaElements)
                    ]);
                })
            );
    }
    getProjectTranscriptionChanges(
        projectId: string
    ): Observable<
        [
            words: Word[],
            mediaUrlsWithIndex: MediaUrlWithIndex[],
            newTranscriptionMap: TranscriptionMap,
            previousTranscriptionMap: TranscriptionMap,
            currentMediaElements: MediaCanvasElement[]
        ]
    > {
        return this.getProjectDocumentReference(projectId)
            .valueChanges()
            .pipe(
                filter(
                    (project) =>
                        project?.transcriptionMap?.length &&
                        (!!project?.mainVideoEditorConfig?.canvasElements?.media || !!project?.canvasElements?.media)
                ),
                map((project) => {
                    const mediaElements =
                        project?.mainVideoEditorConfig?.canvasElements?.media || project?.canvasElements?.media;
                    const mediaUrlsWithIndex: MediaUrlWithIndex[] = mediaElements.map((mediaElement, index) => ({
                        mediaUrl: mediaElement.previewUrl || mediaElement.mediaUrl,
                        mediaIndex: index
                    }));
                    return { transcriptionMap: project?.transcriptionMap, mediaUrlsWithIndex, mediaElements };
                }),
                pairwise(),
                filter((partialProject) => {
                    const previousTranscriptionMap = partialProject[0]?.transcriptionMap;
                    const currentTranscriptionMap = partialProject[1]?.transcriptionMap;

                    if (currentTranscriptionMap?.length > previousTranscriptionMap?.length) {
                        return true;
                    } else if (currentTranscriptionMap?.length === previousTranscriptionMap?.length) {
                        for (const currentTranscriptionMapElement of currentTranscriptionMap) {
                            const previousTranscriptionMapElement = previousTranscriptionMap.find(
                                (element) =>
                                    currentTranscriptionMapElement.transcriptionChunkIndex ===
                                    element.transcriptionChunkIndex
                            );
                            if (currentTranscriptionMapElement.endIndex !== previousTranscriptionMapElement.endIndex) {
                                return true;
                            }
                        }
                    }
                    return false;
                }),
                // map((partialProject) => partialProject[1]),
                switchMap((partialProject) => {
                    const previousProject = partialProject[0];
                    const currentProject = partialProject[1];

                    const wordArray$ = this.getWordArrayForProject(projectId, currentProject.transcriptionMap);
                    return combineLatest([
                        wordArray$,
                        of(currentProject.mediaUrlsWithIndex),
                        of(currentProject.transcriptionMap),
                        of(previousProject.transcriptionMap),
                        of(currentProject.mediaElements)
                    ]);
                })
            );
    }
    getProjectMembers(projectId: string): Observable<MemberMapModel> {
        return this.getProject$(projectId).pipe(map((project) => project.members));
    }
    getCanvasElements(projectId) {
        return this.getProject$(projectId).pipe(
            map((project) => project.mainVideoEditorConfig.canvasElements),
            filter((canvasElements) => !!canvasElements),
            first()
        );
    }

    createProject(name = 'Project title', id?: string, folderId?: string, languageCode?: string): Promise<Project> {
        return new Promise((resolve) => {
            this.userService.user$
                .pipe(
                    skipWhile((user) => user === null),
                    first()
                )
                .subscribe(async (user) => {
                    console.log('🚀 ~ user', user);
                    if (!id) {
                        id = this.generateId();
                    }
                    const emptyProject = new Project().init(id, user.firebaseId, name);
                    emptyProject.language = languageCode || 'en-US';
                    emptyProject.members = {
                        [user.firebaseId]: {
                            firebaseId: user.firebaseId,
                            name: user.name ?? null,
                            email: user.email ?? null,
                            role: MemberRole.OWNER
                        }
                    };
                    emptyProject.language = user.language.codeLong;
                    await this.projectsCollection.doc(id).set(emptyProject.toFirebase());

                    resolve(emptyProject);
                });
        });
    }

    async duplicateProject(oldProject: FbProject, folderId?: string): Promise<FbProject> {
        const newId = this.generateId();
        const copiedProject = Project.duplicate(oldProject, newId);
        if (folderId) {
            this.addToFolder(folderId, copiedProject).subscribe();
        }
        await this.projectsCollection.doc(newId).set(copiedProject);
        await this.duplicateTranscriptions(newId, oldProject);
        if (oldProject.translationMap?.length) {
            await this.duplicateTranscriptions(newId, oldProject, true); //translation
        }

        this.analyticsService.trackServerside(
            AnalyticsEvents.SERVER_PROJECT_DUPLICATED,
            this.userService.user.firebaseId,
            {
                projectId: newId,
                originalProjectId: oldProject.id
            }
        );
        return copiedProject;
    }

    deleteProjects(projectIds: string[]) {
        return this.functions.httpsCallable<DeleteProjectsRequest, any>(FirebaseFunctionNames.deleteProjects)({
            projectIds,
            userId: this.userService.user.firebaseId
        });
    }

    createProjectFromHighlight(
        project: Project,
        startEndConfigs: StartEndConfig[],
        title
    ): Observable<ProjectFromSelectionResponseModel> {
        if (project.isExample) {
            return of(null);
        }
        return this.userProjects$.pipe(
            take(1),
            switchMap(() =>
                this.functions.httpsCallable<ProjectFromSelectionRequestModel>(
                    FirebaseFunctionNames.createProjectFromHighlight
                )({
                    projectId: project.id,
                    startEndConfigs,
                    title
                })
            )
        );
    }

    private async duplicateTranscriptions(newId: string, oldProject: FbProject, isTranslation = false) {
        const oldTranscriptionsReference = this.firestore
            .collection(paths.PROJECTS)
            .doc(oldProject.id)
            .collection(isTranslation ? paths.TRANSLATIONS : paths.TRANSCRIPTIONS);

        const oldTranscriptionDocuments = (await oldTranscriptionsReference.get().toPromise()).docs;
        const newTranscription = this.firestore
            .collection(paths.PROJECTS)
            .doc(newId)
            .collection(isTranslation ? paths.TRANSLATIONS : paths.TRANSCRIPTIONS);

        for (const oldTranscription of oldTranscriptionDocuments) {
            await newTranscription.doc(oldTranscription.id).set(oldTranscription.data());
        }
    }

    // New implementation
    public getProjectById(projectId: string, handleError?: (error) => void): Promise<Project> {
        return new Promise((resolve) => {
            const project$ = this.getProject$(projectId);
            project$
                .pipe(
                    switchMap((fbProject) => {
                        if (fbProject?.transcription?.words?.length && !fbProject?.translationMap?.length) {
                            console.warn('legacy project conversion');
                            return combineLatest([
                                of(this.getWordArrayFromLegacyProject(fbProject)),
                                of(null),
                                of(fbProject)
                            ]);
                        }

                        const wordArray$ = this.getWordArrayForProject(fbProject.id, fbProject.transcriptionMap);
                        const translatedWordArray$ = fbProject.translationMap
                            ? this.getWordArrayForProject(fbProject.id, fbProject.translationMap, true)
                            : of(null);

                        return combineLatest([wordArray$, translatedWordArray$, of(fbProject)]); //+transcriptionArray
                    }),
                    map(([wordArray, translatedWordArray, fbProject]) => {
                        // creates project and applies all asynchronous generated information to it
                        const newProject = new Project().fromFirebase(fbProject);

                        if (newProject.renderingUri === 'completed') {
                            newProject.renderingUri =
                                'videos/' +
                                newProject.userId +
                                '/rendered/' +
                                newProject.id +
                                '_' +
                                newProject.title +
                                '.mp4';
                        }

                        //convert old subtitle structure
                        if (
                            (newProject?.mainVideoEditorConfig as any)?.subtitleConfig &&
                            !newProject.mainVideoEditorConfig.canvasElements?.subtitle.subtitleMode
                        ) {
                            newProject.mainVideoEditorConfig.canvasElements.subtitle = {
                                ...newProject.mainVideoEditorConfig.canvasElements.subtitle,
                                // ...(newProject.mainVideoEditorConfig as any).subtitleConfig,
                                subtitleMode: (newProject.mainVideoEditorConfig as any).subtitleConfig.subtitleMode,
                                bgColor:
                                    (newProject.mainVideoEditorConfig.canvasElements.subtitle as any).bgColor ||
                                    (newProject.mainVideoEditorConfig as any).subtitleConfig.bgColor ||
                                    null,
                                bg:
                                    (newProject.mainVideoEditorConfig.canvasElements.subtitle as any).bg ||
                                    (newProject.mainVideoEditorConfig as any).subtitleConfig.bg ||
                                    null
                            };
                            delete (newProject.mainVideoEditorConfig as any).subtitleConfig;
                        }
                        if (!newProject.mainVideoEditorConfig?.canvasElements?.subtitle.subtitleMode) {
                            newProject.mainVideoEditorConfig.canvasElements.subtitle.subtitleMode =
                                SubtitleMode.disabled;
                        }

                        let mediaUrlsWithIndex: MediaUrlWithIndex[];
                        if (newProject?.mainVideoEditorConfig?.canvasElements?.media) {
                            // handle project with incomplete uploads, replace blog with placeholder video
                            newProject?.mainVideoEditorConfig?.canvasElements?.media.forEach((mediaElement) => {
                                if (mediaElement.mediaUrl?.substring(0, 4) === 'blob') {
                                    // mediaElement.mediaUrl = environment.placeholderVideoUrl;
                                }
                            });

                            // new decoupled video editor logic
                            mediaUrlsWithIndex = newProject.mainVideoEditorConfig?.canvasElements.media.map(
                                (mediaElement, index) => ({
                                    mediaUrl: mediaElement.previewUrl || mediaElement.mediaUrl,
                                    mediaIndex: mediaElement.mediaIndex
                                })
                            );
                        } else if (fbProject?.canvasElements?.media?.length) {
                            // only one editor logic
                            mediaUrlsWithIndex = fbProject.canvasElements.media.map((mediaElement, index) => ({
                                mediaUrl: mediaElement.previewUrl || mediaElement.mediaUrl,
                                mediaIndex: mediaElement.mediaIndex
                            }));
                        } else {
                            // Convert old project to new structure
                            // console.log('🚀 ~ fbProject', fbProject);
                            console.warn('Single video project');
                            if (!fbProject.mediaUri && !fbProject.mediaUrl) {
                                console.warn('No media source found');
                            }
                            mediaUrlsWithIndex = [
                                {
                                    mediaIndex: 0,
                                    mediaUrl: fbProject.previewUrl || fbProject.mediaUrl || fbProject.videoUrl
                                }
                            ];
                            newProject.setMediaCanvasElementsFromSingleVideoProject(fbProject);
                            newProject.mainVideoEditorConfig.resolution = fbProject.transformedVideoResolution;
                            try {
                                newProject.transcriptionMap[0].mediaElementIndex = 0;
                            } catch (error) {
                                console.error(error);
                            }
                            this.updateProject(newProject, newProject.toFirebase());
                        }
                        if (wordArray) {
                            newProject.setTranscriptionFromWords(wordArray, mediaUrlsWithIndex);
                        }

                        if (translatedWordArray) {
                            newProject.setTranscriptionFromWords(
                                translatedWordArray,
                                mediaUrlsWithIndex,
                                fbProject.translationLanguage
                            );
                        }

                        return newProject;
                    })
                )
                .subscribe(
                    (initializedProject) => {
                        // console.log('🚀 ~ initializedProject', initializedProject);
                        resolve(initializedProject);
                    },
                    (error) => {
                        console.error(error);
                        handleError(error);
                    }
                );
        });
    }

    getWordArrayForProject(
        projectId: string,
        transcriptionMap: TranscriptionMap,
        isTranslation = false
    ): Observable<Word[]> {
        // array of all single transcriptionChunks as an observable
        if (transcriptionMap?.length) {
            const transcriptionChunkIndices$ = transcriptionMap.map((transcriptionMapElement) =>
                this.getTranscriptionChunk$(
                    projectId,
                    transcriptionMapElement.transcriptionChunkIndex,
                    transcriptionMapElement.startIndex,
                    transcriptionMapElement.endIndex,
                    transcriptionMapElement.mediaElementIndex,
                    isTranslation
                )
            );
            // takes all observables off the transcription chunks,
            // sorts them by index
            // flattens them to one array
            // converts all wordString to instances of Word()
            const wordArray$ = combineLatest(transcriptionChunkIndices$).pipe(
                map((transcriptionChunkIndices) => {
                    const sortedWordChunks = transcriptionChunkIndices
                        // .sort((a, b) => a.index - b.index)
                        .map((transcriptionChunkIndex) => transcriptionChunkIndex.words);

                    const flattenedWordArray: Word[] = [].concat(...sortedWordChunks);

                    return flattenedWordArray;
                })
            );

            //return all wordInstances and the project as observables
            return wordArray$;
        }
        return of(null);
    }

    getWordArrayFromLegacyProject(fbProject: FbProject) {
        const wordArray = fbProject.transcription.words.map((word) => new Word().fromLegacyWord(word));
        fbProject.transcriptionMap = [
            { startIndex: 0, endIndex: wordArray?.length - 1, transcriptionChunkIndex: 0, mediaElementIndex: 0 }
        ];
        return wordArray;
    }

    private getTranscriptionChunk$(
        projectId: string,
        chunkIndex: number,
        startIndex: number,
        endIndex: number,
        mediaIndex: number,
        isTranslation = false
    ): Observable<WordChunkIndex> {
        const transcriptionCollection = isTranslation ? paths.TRANSLATIONS : paths.TRANSCRIPTIONS;
        // console.log({ chunkIndex, startIndex, endIndex, mediaIndex, isTranslation });
        return this.projectsCollection
            .doc<Project>(projectId)
            .collection(transcriptionCollection)
            .doc<WordChunk>(String(chunkIndex))
            .valueChanges()
            .pipe(
                first(),
                map((fbWordChunk) => {
                    // console.log('create chunks');
                    // console.log({ projectId, fbWordChunk });
                    // console.log({ chunkIndex, startIndex, endIndex, mediaIndex, isTranslation });
                    const convertedTranscription = this.convertWordChunkToWordMap(
                        fbWordChunk,
                        chunkIndex,
                        startIndex,
                        endIndex,
                        mediaIndex
                    );
                    const wordArray = Object.keys(convertedTranscription).map(
                        (key) => convertedTranscription[key] as Word
                    );

                    return { index: chunkIndex, words: wordArray };
                })
            );
    }

    /**
     * convert transcription
     */
    private convertWordChunkToWordMap(
        fbWordChunk: WordChunk,
        chunkIndex: number,
        startIndex: number,
        endIndex: number,
        mediaIndex: number
    ) {
        if (mediaIndex === undefined) {
            mediaIndex = 0;
        }
        if (!fbWordChunk) {
            return {};
        }
        // converts map FbWord to new instance of Word
        // still a map
        const convertedWords = {};
        Object.keys(fbWordChunk.words).forEach((key) => {
            const wordIndex = Number(key);
            if (wordIndex >= startIndex && wordIndex <= endIndex) {
                convertedWords[key] = new Word()
                    .fromFbWord(JSON.parse(fbWordChunk.words[key]) as FbWord)
                    .setMediaIndex(mediaIndex)
                    .setChunkIndex(chunkIndex);
            }
        });
        return convertedWords;
    }

    private getProject$(projectId: string): Observable<FbProject> {
        return this.projectsCollection.doc<FbProject>(projectId).valueChanges().pipe(first());
    }

    async getPreviewUrl(previewUri: string): Promise<string> {
        return new Promise<string>((resolve) => {
            //init previewUrl if available

            this.storage
                .ref(previewUri)
                .getDownloadURL()
                .pipe(first())
                .subscribe((downloadUrl) => {
                    resolve(downloadUrl);
                });
        });
    }

    getProjectObservable(project: Project): Observable<Project> {
        if (project) {
            return this.projectsCollection.doc<Project>(project.id).valueChanges();
        }
    }

    getExampleProject(): Project {
        // setCurrentProject is not used because we don't want send changes to firebase
        // if there is still a subscription we unsubscribe it before applying new project value
        return getExampleProject();
    }

    deleteProjectById(projectId: string) {
        return from(this.projectsCollection.doc(projectId).delete());
    }

    async removeTranscription(project: Project) {
        await this.removeWordCollection(project, false);
    }

    async removeTranslation(project: Project) {
        await this.removeWordCollection(project, true);
        await this.getProjectDocumentReference(project.id).update({ translationMap: null, translationLanguage: null });
    }

    private async removeWordCollection(project: Project, isTranslation: boolean = false) {
        if (!project.isExample) {
            const documentSnapshots = await this.getProjectDocumentReference(project.id)
                .collection(isTranslation ? paths.TRANSLATIONS : paths.TRANSCRIPTIONS)
                .ref.get();
            documentSnapshots.forEach((snapshot) => {
                snapshot.ref.delete();
            });
        }
    }

    updateWords(project: Project, wordIndices: WordIndex[], isTranslation: boolean = false) {
        if (!project.isExample) {
            const wordsMapMap = {};
            for (const wordIndexPair of wordIndices) {
                const wordChunkIndex = wordIndexPair.word.chunkIndex;
                const fbWordString = wordIndexPair.word.toFirebaseString();
                wordsMapMap[wordChunkIndex] = {
                    ...wordsMapMap[wordChunkIndex],
                    ['words.' + wordIndexPair.index]: fbWordString
                };
            }

            for (const [key, value] of Object.entries(wordsMapMap)) {
                console.log('update chunk', key, 'at index', value);
                this.getProjectDocumentReference(project.id)
                    .collection(isTranslation ? paths.TRANSLATIONS : paths.TRANSCRIPTIONS)
                    .doc(String(key))
                    .update(value)
                    .then();
            }
        }
    }
    async setSingleWord(project: Project, word: Word, index: number) {
        const batch = this.firestore.firestore.batch();

        batch.update(this.getProjectDocumentReference(project.id).ref, {
            transcriptionMap: [{ startIndex: 0, endIndex: 0, transcriptionChunkIndex: 0, mediaElementIndex: 0 }]
        });
        batch.set(
            this.getProjectDocumentReference(project.id).collection(paths.TRANSCRIPTIONS).doc(String(word.chunkIndex))
                .ref,
            {
                words: {
                    [index]: word.toFirebaseString()
                }
            },
            { merge: true }
        );
        return batch.commit();
    }

    updateLanguage(project: Project, updatedLanguage: Language) {
        console.log('updateLanguage');
        if (!project.isExample) {
            this.getProjectDocumentReference(project.id)
                .update({
                    language: updatedLanguage.codeLong
                })
                .then();
        }
    }

    updateTranslationLanguage(project: Project, updatedLanguageCodeLong: string) {
        console.log('updateTranslationLanguage');
        if (!project.isExample) {
            this.getProjectDocumentReference(project.id)
                .update({
                    translationLanguage: updatedLanguageCodeLong
                })
                .then();
        }
    }

    updateSubtitleMode(project: Project, subtitleMode: SubtitleMode, clipId?: string) {
        console.log('updateSubtitleMode');
        if (!project.isExample) {
            if (clipId) {
                // this.getProjectDocumentReference(project.id)
                //     .collection(paths.PROJECTS)
                //     .doc(project.id)
                //     .set(
                //         {
                //             clipConfigs: { [clipId]: { videoEditorConfig: { subtitleConfig: { subtitleMode } } } }
                //         },
                //         { merge: true }
                //     );
            } else {
                this.firestore
                    .collection(paths.PROJECTS)
                    .doc(project.id)
                    .set(
                        {
                            mainVideoEditorConfig: { canvasElements: { subtitle: { subtitleMode } } }
                        },
                        { merge: true }
                    );
            }
        }
    }

    updateShowSubtitleBreaks(project: Project, showSubtitleBreaks: boolean) {
        if (!project.isExample) {
            this.getProjectDocumentReference(project.id)
                .update({
                    showSubtitleBreaks
                })
                .then();
        }
    }

    updateFixedSubtitleLength(project: Project, subtitleLength: number, clipId?: string) {
        console.log('updateSubtitleMode');
        if (!project.isExample) {
            if (clipId) {
                // this.getProjectDocumentReference(project.id)
                //     .collection(paths.PROJECTS)
                //     .doc(project.id)
                //     .set(
                //         {
                //             clipConfigs: { [clipId]: { videoEditorConfig: { subtitleConfig: { subtitleLength } } } }
                //         },
                //         { merge: true }
                //     );
            } else {
                this.firestore
                    .collection(paths.PROJECTS)
                    .doc(project.id)
                    .set(
                        {
                            mainVideoEditorConfig: { canvasElements: { subtitle: { subtitleLength } } }
                        },
                        { merge: true }
                    );
            }
        }
    }

    updateTitle(project: Project, updatedTitle: string) {
        console.log('updateTitle');
        if (!project.isExample) {
            this.getProjectDocumentReference(project.id)
                .update({
                    title: updatedTitle
                })
                .then();
        }
    }

    updateProjectResolution(project: Project, updatedResolution: Resolution, clipId?: string) {
        console.log('updateResolution ' + updatedResolution.width + 'x' + updatedResolution.height);
        if (!project.isExample) {
            if (clipId) {
                // this.getProjectDocumentReference(project.id)
                //     .set(
                //         {
                //             clipConfigs: { [clipId]: { videoEditorConfig: { resolution: updatedResolution } } }
                //         },
                //         { merge: true }
                //     )
                //     .then();
            } else {
                this.getProjectDocumentReference(project.id)
                    .set(
                        {
                            mainVideoEditorConfig: { resolution: updatedResolution }
                        },
                        { merge: true }
                    )
                    .then();
            }
        }
    }

    updateLastUsedAt(project: Project) {
        // console.log('updateLastUsedAt');
        if (!project.isExample) {
            this.getProjectDocumentReference(project.id)
                .update({
                    lastUsedAt: Math.floor(Date.now() / 1000)
                })
                .then();
        }
    }

    updateVisibility(project: Project, newVisibility: Visibility) {
        console.log('updateVisibility');
        if (!project.isExample) {
            this.getProjectDocumentReference(project.id)
                .update({
                    visibility: newVisibility
                })
                .then();
        }
    }

    updateSharingTitle(project: Project, newTitle: string) {
        console.log('updateSharingTitle');
        if (!project.isExample) {
            this.getProjectDocumentReference(project.id)
                .update({
                    sharingTitle: newTitle
                })
                .then();
        }
    }

    updateVoiceTranslationMode(project: Project, voiceTranslationMode: VoiceTranslationMode) {
        if (!project.isExample) {
            this.getProjectDocumentReference(project.id)
                .update({
                    voiceTranslationMode,
                    currentVoiceTranslationReferences: null
                })
                .then();
        }
    }

    updateTemplate(templateId: string, canvasElements: ProjectCanvasElements) {
        if (templateId) {
            this.firestore.collection(paths.USERTEMPLATES).doc(templateId).update({ canvasElements });
        }
    }
    updateCanvasElements(project: Project, projectCanvasElements: ProjectCanvasElements, clipId?: string) {
        if (!project.isExample) {
            const canvasElements: ProjectCanvasElements = {
                ...projectCanvasElements,
                image: projectCanvasElements.image.map((img) => {
                    if (this.uploadTaskService.getTask(img.ID)) {
                        img = { ...img };
                        img.imageURL = '';
                        img.imageURI = '';
                    }
                    return img;
                })
            };

            if (clipId) {
                // this.getProjectDocumentReference(project.id)
                //     .set({ clipConfigs: { [clipId]: { videoEditorConfig: { canvasElements } } } }, { merge: true })
                //     .then();
            } else {
                this.getProjectDocumentReference(project.id)
                    .set({ mainVideoEditorConfig: { canvasElements } }, { merge: true })
                    .then();
            }
        }
    }

    updateBackgroundColor(project: Project, color: string, clipId?: string) {
        if (!project.isExample) {
            if (clipId) {
                // this.getProjectDocumentReference(project.id)
                //     .set(
                //         { clipConfigs: { [clipId]: { videoEditorConfig: { backgroundColor: color } } } },
                //         { merge: true }
                //     )
                //     .then();
            } else {
                this.getProjectDocumentReference(project.id)
                    .set({ mainVideoEditorConfig: { backgroundColor: color } }, { merge: true })
                    .then();
            }
        }
    }

    updateSubtitleConfig(project: Project, subtitleConfig: SubtitleConfig, clipId?: string) {
        if (!project.isExample) {
            if (clipId) {
                // this.getProjectDocumentReference(project.id)
                //     .set({ clipConfigs: { [clipId]: { videoEditorConfig: { subtitleConfig } } } }, { merge: true })
                //     .then();
            } else {
                this.firestore
                    .collection(paths.PROJECTS)
                    .doc(project.id)
                    .set(
                        { mainVideoEditorConfig: { canvasElements: { subtitle: { ...subtitleConfig } } } },
                        { merge: true }
                    )
                    .then();
            }
        }
    }

    updateSpeakers(project: Project, speaker: Speaker) {
        const speakerMap = {
            [speaker.index]: {
                name: speaker.name
            }
        };

        if (!project.isExample) {
            this.getProjectDocumentReference(project.id).set({ speakerMap } as FbProject, { merge: true });
        }
    }

    async getDownloadURL(firebaseURI: string): Promise<string> {
        return new Promise<string>((resolve) => {
            this.storage
                .ref(firebaseURI)
                .getDownloadURL()
                .subscribe((downloadURL) => {
                    resolve(downloadURL);
                });
        });
    }

    inviteMember(project: Project, email: string) {
        return this.functions.httpsCallable(FirebaseFunctionNames.inviteMemberToProject)({
            email,
            projectId: project.id
        });
    }

    getInviteAccessToken(currentProject: Project, reset = true): Observable<string> {
        const handleAccessToken = (project: FbPrivateProject): Observable<string | null> => {
            if (!project) {
                return of(null);
            } else if (project.inviteAccessToken) {
                return of(project.inviteAccessToken);
            } else if (reset) {
                return this.resetInviteAccessToken(currentProject);
            } else {
                return of(null);
            }
        };
        return this.firestore
            .collection(paths.PROJECTS)
            .doc<FbProject>(currentProject.id)
            .collection(paths.PRIVATE)
            .doc(currentProject.id)
            .valueChanges()
            .pipe(take(1), switchMap(handleAccessToken), take(1));
    }

    acceptInvite(accessToken: string): Observable<string> {
        return this.functions
            .httpsCallable(FirebaseFunctionNames.acceptProjectInvite)({
                accessToken
            })
            .pipe(map((res) => res.data));
    }

    resetInviteAccessToken(currentProject: Project): Observable<string> {
        if (currentProject.isExample) {
            return of(null);
        }
        return this.functions
            .httpsCallable(FirebaseFunctionNames.generateProjectInviteAccessToken)({
                projectId: currentProject.id
            })
            .pipe(
                map((res) => res.data),
                catchError((e) => throwError(e))
            );
    }

    removeMember(currentProject: Project, member: MemberModel) {
        return this.functions.httpsCallable(FirebaseFunctionNames.projectRemoveMember)({
            userId: member.firebaseId,
            resourceId: currentProject.id,
            resourceType: paths.PROJECTS
        });
    }

    removeInviteAccessToken(currentProject: Project) {
        return this.functions.httpsCallable(FirebaseFunctionNames.removeProjectInviteAccessToken)({
            resourceId: currentProject.id,
            resourceType: paths.PROJECTS
        });
    }

    getVoiceTranslationById(projectId: string, firebaseId: string) {
        return this.getProjectDocumentReference(projectId).collection(paths.VOICE_TRANSLATIONS).doc(firebaseId).get();
    }

    generateId(): string {
        return this.firestore.createId();
    }

    createFolder(name: string, parentId?: string): Observable<DocumentReference<FolderModel>> {
        const creatorId = this.userService.user.firebaseId;
        const newFolder: FolderModel = {
            name,
            createdBy: creatorId,
            owner: {
                firebaseId: creatorId,
                resourceType: 'users'
            },
            members: {
                [creatorId]: MemberModel.fromUserModel(this.userService.user, MemberRole.OWNER, MemberStatus.ACTIVE)
            },
            resourceType: paths.PROJECTS
        };

        return this.functions.httpsCallable(FirebaseFunctionNames.createFolder)({
            folder: newFolder,
            parentId
        });
    }

    addToFolder(folderId: string, item: Project | FbProject | FolderModel): Observable<void> {
        let resource;
        if ((item as any)?.resourceType === paths.FOLDERS) {
            resource = { firebaseId: (item as any).firebaseId, resourceType: (item as any).resourceType };
        } else {
            resource = { firebaseId: (item as any).id, resourceType: paths.PROJECTS };
        }
        return this.functions.httpsCallable<AddToFolderRequestModel>(FirebaseFunctionNames.addToFolder)({
            folderId,
            resource
        });
    }

    deleteFolder(firebaseId: string): Observable<void> {
        return this.functions.httpsCallable(FirebaseFunctionNames.deleteFolder)({ firebaseId });
    }

    updateProject(project: Project, data: Partial<FbProject>): Observable<void> {
        if (project.isExample) {
            return of(null);
        }
        return from(this.firestore.collection(paths.PROJECTS).doc(project.id).update(data));
    }

    updateFolderName($event: UpdateFolderNameRequestModel): Observable<void> {
        return this.functions.httpsCallable(FirebaseFunctionNames.updateFolderName)($event);
    }

    updateRenderingState(projectId: string, renderingState: RenderingStates) {
        return this.firestore.collection(paths.PROJECTS).doc(projectId).update({ renderingState });
    }

    startTranscribingRetainedMedia(projectId: string, initialIndex: number, mediaUri: string, userId: string) {
        return this.functions.httpsCallable<StartTranscribingRetainedMediaRequestData>(
            FirebaseFunctionNames.startRetainedMediaTranscription
        )({
            projectId,
            userId,
            initialIndex,
            mediaUri
        });
    }

    addClipConfig(projectId: string, clipConfig: ClipConfig) {
        return this.firestore
            .collection(paths.PROJECTS)
            .doc(projectId)
            .set(
                {
                    clipConfigs: {
                        [clipConfig.id]: {
                            ...clipConfig
                        }
                    }
                },
                { merge: true }
            );
    }
    deleteClipRendering(projectId: string, clipId: string) {
        return this.firestore
            .collection(paths.PROJECTS)
            .doc(projectId)
            .set(
                {
                    clipConfigs: {
                        [clipId]: {
                            clipId,
                            renderingId: FieldValue.delete(),
                            renderingUri: FieldValue.delete(),
                            renderingState: FieldValue.delete(),
                            renderingType: FieldValue.delete()
                        }
                    }
                },
                { merge: true }
            );
    }
    deleteClip(projectId: string, clipId: string) {
        return this.firestore
            .collection(paths.PROJECTS)
            .doc(projectId)
            .set({ clipConfigs: { [clipId]: FieldValue.delete() } }, { merge: true });
    }

    updateClipState(projectId: string, clipId: string, clipStates: ClipStates) {
        return this.firestore
            .collection(paths.PROJECTS)
            .doc(projectId)
            .set({ clipConfigs: { [clipId]: { clipStates } } }, { merge: true });
    }

    cancelClipRendering(currentProjectId: string, clipId: string, deleteClip?: boolean) {
        this.functions
            .httpsCallable<CancelRenderingRequestModel>(FirebaseFunctionNames.cancelRendering)({
                projectId: currentProjectId,
                clipRenderingId: clipId
            })
            .subscribe(() => {
                if (deleteClip) {
                    this.deleteClipRendering(currentProjectId, clipId);
                }
            });
    }

    saveClip(projectId: string, clipConfigs: ClipConfig) {
        return this.firestore.collection(paths.PROJECTS).doc(projectId).set({ clipConfigs }, { merge: true });
    }

    updateClipThumbnail(projectId: string, clipId: string, thumbnailUrl: string) {
        return this.firestore
            .collection(paths.PROJECTS)
            .doc(projectId)
            .set({ clipConfigs: { [clipId]: { thumbnailUrl } } }, { merge: true });
    }
    async updateClipThumbnails(projectId: string, thumbnails: Array<{ clipId: string; dataUrl: string }>) {
        const updateObject = thumbnails.reduce(
            (acc, { clipId, dataUrl }) => ({ ...acc, [clipId]: { thumbnailUrl: dataUrl } }),
            {}
        );

        await this.firestore
            .collection(paths.PROJECTS)
            .doc(projectId)
            .set({ clipConfigs: updateObject }, { merge: true });
    }

    updateClipCreationCount(projectId: string, clipCreationCount: number) {
        return this.firestore.collection(paths.PROJECTS).doc(projectId).set({ clipCreationCount }, { merge: true });
    }

    updateLastOpenSideMenuItem(projectId: string, lastOpenSideMenuItem: SideMenuItemKey) {
        return this.firestore.collection(paths.PROJECTS).doc(projectId).set({ lastOpenSideMenuItem }, { merge: true });
    }

    manuallyTriggerStartTranscription(projectId: string, autoEditingConfig: AutoEditingConfig, linkUpload = false) {
        const userId = this.userService.user.firebaseId;

        return this.functions.httpsCallable(FirebaseFunctionNames.manuallyTriggerStartTranscription, {
            timeout: 540 * 1000
        })({
            projectId,
            userId,
            autoEditingConfig,
            linkUpload
        });
    }

    // Duplicate in firestore.ts
    updateAutoEditingConfig(projectId: string, autoEditingConfig: Partial<AutoEditingConfig>) {
        const updateData: any = {
            mainVideoEditorConfig: {
                autoEditingConfig: {} as AutoEditingConfig
            },
            renderingState: null as RenderingStates | null
        };

        for (const key in autoEditingConfig) {
            if (Object.prototype.hasOwnProperty.call(autoEditingConfig, key)) {
                const value = autoEditingConfig[key];
                if (value !== undefined) {
                    updateData.mainVideoEditorConfig.autoEditingConfig[key] = value;
                }
            }
        }

        if (autoEditingConfig?.startRendering === true) {
            updateData.renderingState = RenderingStates.IN_PROGRESS;
        }
        if (autoEditingConfig?.startRendering === false) {
            updateData.renderingState = null;
        }

        // Only for mobile, otherwise the subtitle-settings.component will manage the following
        if (autoEditingConfig.subtitleMode) {
            updateData.mainVideoEditorConfig.canvasElements.subtitle.subtitleMode = autoEditingConfig.subtitleMode;
        }

        return this.firestore.collection(paths.PROJECTS).doc(projectId).set(updateData, { merge: true });
    }

    setForceStreaming(projectId: string, forceStreaming: boolean) {
        return this.firestore.collection(paths.PROJECTS).doc(projectId).set({ forceStreaming }, { merge: true });
    }
    //auto rendering
    // for debugging only
    // startRenderingManually(projectId: string) {
    //     const userId = this.userService.user.firebaseId;

    //     this.functions
    //         .httpsCallable(FirebaseFunctionNames.manuallyTriggerAutoRendering)({
    //             projectId,
    //             userId
    //         })
    //         .subscribe({
    //             next: (result) => {
    //                 // console.log('Success:', result);
    //             },
    //             error: (error) => {
    //                 console.error('Error:', error);
    //             }
    //         });
    // }
}
