/**
 * copies text to Clipboard
 */
export function copyToClipboard(text: string) {
    const input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
}

export function truncateString(srt: string, truncateLength?: number) {
    if (srt.length <= truncateLength || truncateLength === undefined) {
        return srt;
    } else {
        return srt.substring(0, truncateLength - 3) + '...';
    }
}

export function removeLinebreaksFromString(str: string) {
    return str.replace(/[\r\n]+/gm, '');
}
