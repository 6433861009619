<span [ngSwitch]="checkoutAction">
    <div *ngSwitchCase="'checkout'">
        <app-button color="primary" [disabled]="disabled" (btnClick)="openCheckoutPopup()"
            ><ng-container *ngTemplateOutlet="template"></ng-container>
        </app-button>
    </div>
    <div *ngSwitchCase="'cancel'">
        <app-button color="secondary" (btnClick)="openCancelPopup()"
            ><ng-container *ngTemplateOutlet="template"></ng-container
        ></app-button>
    </div>
    <div *ngSwitchCase="'payment-method'">
        <app-button color="secondary" (btnClick)="openBillingPopup()"
            ><ng-container *ngTemplateOutlet="template"></ng-container
        ></app-button>
    </div>
    <div *ngSwitchDefault></div>
</span>

<ng-template #template>
    <ng-content></ng-content>
</ng-template>
