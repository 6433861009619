const paths = {
    USERS: 'users',
    VIDEOS: 'videos',
    TRANSCRIPTIONS: 'transcriptions',
    VOICE_TRANSLATIONS: 'voiceTranslations',
    TRANSLATIONS: 'translations',
    PROJECTS: 'projects',
    ORGANIZATIONS: 'organizations',
    RENDERINGS: 'renderings',
    RENDERING: 'rendering',
    FEATURES: 'features',
    FOLDERS: 'folders',
    SURVEYS: 'surveys',
    IMAGES: 'images',
    CUSTOMERS: 'customers',
    NOTIFICATIONS: 'notifications',
    USERTEMPLATES: 'usertemplates',
    GALLERYTEMPLATES: 'gallerytemplates',
    DATA_INTEGRITY_REPORTS: 'dataIntegrityReports',
    CLOUD_STORAGE: 'cloudStorage',
    SUPPPORT: 'support',
    TRANSCRIPT_IDS: 'transcriptionIds',
    VERIFIER_KEYS: 'verifierKeys',
    SLID_USER_LINKS: 'slidUserLinks',
    INSTANCES: 'instances',
    PRIVATE: 'private'
} as const;

export default paths;

export type PathsType = typeof paths[keyof typeof paths];
