export enum PlanType {
    TRIAL = 'trial',
    BASIC = 'basic',
    PERSONAL = 'personal', // is now pro
    PRO = 'pro',
    ULTRA = 'ultra',
    ENTERPRISE = 'enterprise',

    EXPIRED = 'expired',
    CANCELED = 'canceled',

    // NEW ADDED END NOVEMBER 2021
    STARTER = 'starter',
    PRO_TEAM = 'proTeam',
    BUSINESS = 'business',

    // NEW ADDED JANUARY
    STARTER_BASIC = 'starterBasic'
    //OlD PRO is 'pro' Again
} // PRO_TEAM is 'team'

/**
    const tier0 = [PlanType.TRIAL, PlanType.EXPIRED];
    const tier1 = [PlanType.BASIC, PlanType.STARTER_BASIC];
    const tier2 = [PlanType.PRO, PlanType.STARTER, PlanType.PERSONAL, PlanType.ENTERPRISE];
    const tier3 = [PlanType.PRO_TEAM];
 */
export function getTierFromPlanType(planType: PlanType): number {
    switch (planType) {
        case PlanType.TRIAL:
        case PlanType.EXPIRED:
            return 0;
        case PlanType.BASIC:
        case PlanType.STARTER_BASIC:
            return 1;
        case PlanType.PERSONAL:
        case PlanType.PRO:
        case PlanType.STARTER:
            return 2;
        case PlanType.PRO_TEAM:
            return 3;
        case PlanType.BUSINESS:
        case PlanType.ENTERPRISE:
            return 4;
        case PlanType.ULTRA:
            return 5;
        default:
            return 0;
    }
}
export function getPlanTypeFromTier(tier: number): PlanType {
    switch (tier) {
        case 0:
            return PlanType.TRIAL;
        case 1:
            return PlanType.STARTER_BASIC;
        case 2:
            return PlanType.PRO;
        case 3:
            return PlanType.PRO_TEAM;
        case 4:
            return PlanType.BUSINESS;
        case 5:
            return PlanType.ULTRA;
        default:
            return PlanType.TRIAL;
    }
}

export interface PlanElement {
    icon?: string;
    title: string;
    label?: string;
    primaryFeature?: string;
    description?: string;
    monthlyPrice: number;
    yearlyPrice?: number;
    planType: PlanType;
    featureList?: string[];
}

export const PLAN_LEVELS = {
    [PlanType.EXPIRED]: {
        label: 'Free',
        emoji: '⛱'
    },
    [PlanType.TRIAL]: {
        label: 'Free',
        emoji: '⛱'
    },
    [PlanType.BASIC]: {
        label: 'Starter',
        emoji: '🚀'
    },
    [PlanType.STARTER_BASIC]: {
        label: 'Starter',
        emoji: '\u26fa'
    },
    [PlanType.PERSONAL]: {
        label: 'Pro',
        emoji: '💎'
    },
    [PlanType.PRO]: {
        label: 'Pro',
        emoji: '\ud83c\udfe0'
    },
    [PlanType.ENTERPRISE]: {
        label: 'Enterprise',
        emoji: '\uD83C\uDFE2'
    },
    [PlanType.STARTER]: {
        label: 'Starter',
        emoji: '\u26fa'
    },
    [PlanType.PRO_TEAM]: {
        label: 'Team',
        emoji: '\ud83c\udfec'
    },
    [PlanType.BUSINESS]: {
        label: 'Business',
        emoji: '\ud83c\udfec'
    },
    [PlanType.ULTRA]: {
        label: 'Ultra',
        emoji: '\ud83c\udfec'
    }
};

export enum PlanInterval {
    monthly = 'monthly',
    annually = 'annually'
}

export type PlanIntervalType = typeof PlanInterval[keyof typeof PlanInterval];

export interface PlanDetails {
    id: string;
    price: number;
    planInterval: PlanInterval;
    planType: PlanType;
}

export interface IPlan {
    [PlanType.EXPIRED]: {
        [PlanInterval.monthly]: PlanDetails;
        [PlanInterval.annually]: PlanDetails;
        usageLimitation: number;
    };
    [PlanType.TRIAL]: {
        [PlanInterval.monthly]: PlanDetails;
        [PlanInterval.annually]: PlanDetails;
        usageLimitation: number;
    };
    [PlanType.PERSONAL]: {
        [PlanInterval.monthly]: PlanDetails;
        [PlanInterval.annually]: PlanDetails;
        usageLimitation: number;
    };
    [PlanType.BASIC]: {
        [PlanInterval.monthly]: PlanDetails;
        [PlanInterval.annually]: PlanDetails;
        usageLimitation: number;
    };
    [PlanType.PRO]: {
        [PlanInterval.monthly]: PlanDetails;
        [PlanInterval.annually]: PlanDetails;
        usageLimitation: number;
    };
    [PlanType.STARTER]: {
        [PlanInterval.monthly]: PlanDetails;
        [PlanInterval.annually]: PlanDetails;
        usageLimitation: number;
    };
    [PlanType.STARTER_BASIC]: {
        [PlanInterval.monthly]: PlanDetails;
        [PlanInterval.annually]: PlanDetails;
        usageLimitation: number;
    };
    [PlanType.PRO_TEAM]: {
        [PlanInterval.monthly]: PlanDetails;
        [PlanInterval.annually]: PlanDetails;
        usageLimitation: number;
    };
    [PlanType.BUSINESS]: {
        [PlanInterval.monthly]: PlanDetails;
        [PlanInterval.annually]: PlanDetails;
        usageLimitation: number;
    };
    [PlanType.ENTERPRISE]: {
        [PlanInterval.monthly]: PlanDetails;
        [PlanInterval.annually]: PlanDetails;
        usageLimitation: number;
    };
    [PlanType.ULTRA]: {
        [PlanInterval.monthly]: PlanDetails;
        [PlanInterval.annually]: PlanDetails;
        usageLimitation: number;
    };
}
export interface IPlanTable {
    production: IPlan;
    development: IPlan;
}

// NORMAL Price
export const PlanTable: IPlanTable = {
    production: {
        [PlanType.EXPIRED]: {
            monthly: {
                id: '',
                price: 0,
                planInterval: PlanInterval.monthly,
                planType: PlanType.EXPIRED
            },
            annually: {
                id: '',
                price: 0,
                planInterval: PlanInterval.annually,
                planType: PlanType.EXPIRED
            },
            usageLimitation: 1 * 60 * 60 // 10 minutes in seconds
        },
        [PlanType.TRIAL]: {
            monthly: {
                id: '',
                price: 0,
                planInterval: PlanInterval.monthly,
                planType: PlanType.TRIAL
            },
            annually: {
                id: '',
                price: 0,
                planInterval: PlanInterval.annually,
                planType: PlanType.TRIAL
            },
            usageLimitation: 1 * 60 * 60 // 60 minutes in seconds
        },
        [PlanType.PRO]: {
            monthly: {
                id: 'price_1P6TNLBCb5eAmPX70UZzjhQp',
                price: 1200,
                planInterval: PlanInterval.monthly,
                planType: PlanType.PRO
            },
            annually: {
                id: 'price_1P6TNMBCb5eAmPX7WVvIfoIV',
                price: 10800,
                planInterval: PlanInterval.annually,
                planType: PlanType.PRO
            },
            usageLimitation: 40 * 60 * 60 // 20 hours in seconds
        },
        [PlanType.ULTRA]: {
            monthly: {
                id: '',
                price: 1900,
                planInterval: PlanInterval.monthly,
                planType: PlanType.ULTRA
            },
            annually: {
                id: '',
                price: 14900,
                planInterval: PlanInterval.annually,
                planType: PlanType.ULTRA
            },
            usageLimitation: 40 * 60 * 60 // 40 hours in seconds
        },
        //
        // LEGACY PRICES
        //
        [PlanType.PERSONAL]: {
            // personal is the same as basic
            monthly: {
                id: '',
                price: 1800,
                planInterval: PlanInterval.monthly,
                planType: PlanType.PERSONAL
            },
            annually: {
                id: '',
                price: 14400,
                planInterval: PlanInterval.annually,
                planType: PlanType.PERSONAL
            },
            usageLimitation: 20 * 60 * 60 // 20 hours in seconds -> old users get pro-plan perks
        },
        [PlanType.BASIC]: {
            monthly: {
                id: '',
                price: 1800,
                planInterval: PlanInterval.monthly,
                planType: PlanType.BASIC
            },
            annually: {
                id: '',
                price: 14400,
                planInterval: PlanInterval.annually,
                planType: PlanType.BASIC
            },
            usageLimitation: 5 * 60 * 60 // 5 hours in seconds
        },
        [PlanType.STARTER_BASIC]: {
            monthly: {
                id: '',
                price: 1800,
                planInterval: PlanInterval.monthly,
                planType: PlanType.BASIC
            },
            annually: {
                id: '',
                price: 14400,
                planInterval: PlanInterval.annually,
                planType: PlanType.BASIC
            },
            usageLimitation: 5 * 60 * 60 // 5 hours in seconds
        },

        [PlanType.STARTER]: {
            monthly: {
                id: '',
                price: 2800,
                planInterval: PlanInterval.monthly,
                planType: PlanType.STARTER
            },
            annually: {
                id: '',
                price: 24000,
                planInterval: PlanInterval.annually,
                planType: PlanType.STARTER
            },
            usageLimitation: 10 * 60 * 60 // 5 hours in seconds
        },
        [PlanType.PRO_TEAM]: {
            monthly: {
                id: '',
                price: 4800,
                planInterval: PlanInterval.monthly,
                planType: PlanType.PRO_TEAM
            },
            annually: {
                id: '',
                price: 43200,
                planInterval: PlanInterval.annually,
                planType: PlanType.PRO_TEAM
            },
            usageLimitation: 20 * 60 * 60 // 20per month hours in seconds
        },
        [PlanType.BUSINESS]: {
            monthly: {
                id: '',
                price: 24500,
                planInterval: PlanInterval.monthly,
                planType: PlanType.BUSINESS
            },
            annually: {
                id: '',
                price: 192000,
                planInterval: PlanInterval.annually,
                planType: PlanType.BUSINESS
            },
            usageLimitation: 100 * 60 * 60 // 100 hours in seconds
        },
        [PlanType.ENTERPRISE]: {
            monthly: {
                id: '',
                price: 0,
                planInterval: PlanInterval.monthly,
                planType: PlanType.ENTERPRISE
            },
            annually: {
                id: '',
                price: 0,
                planInterval: PlanInterval.annually,
                planType: PlanType.ENTERPRISE
            },
            usageLimitation: 100 * 60 * 60 // 100 hours in seconds
        }
    },
    development: {
        [PlanType.EXPIRED]: {
            monthly: {
                id: '',
                price: 0,
                planInterval: PlanInterval.monthly,
                planType: PlanType.EXPIRED
            },
            annually: {
                id: '',
                price: 0,
                planInterval: PlanInterval.annually,
                planType: PlanType.EXPIRED
            },
            usageLimitation: 1 * 60 * 60 // 60 minutes in seconds
        },
        [PlanType.TRIAL]: {
            monthly: {
                id: '',
                price: 0,
                planInterval: PlanInterval.monthly,
                planType: PlanType.TRIAL
            },
            annually: {
                id: '',
                price: 0,
                planInterval: PlanInterval.annually,
                planType: PlanType.TRIAL
            },
            usageLimitation: 1 * 60 * 60 // 60 minutes in seconds
        },
        [PlanType.PRO]: {
            monthly: {
                id: 'price_1Ot8SoBCb5eAmPX7ww7FCDqt',
                price: 1200,
                planInterval: PlanInterval.monthly,
                planType: PlanType.PRO
            },
            annually: {
                id: 'price_1Ot8SoBCb5eAmPX7HcodjnvT',
                price: 10800,
                planInterval: PlanInterval.annually,
                planType: PlanType.PRO
            },
            usageLimitation: 40 * 60 * 60
        },
        [PlanType.ULTRA]: {
            monthly: {
                id: '',
                price: 1900,
                planInterval: PlanInterval.monthly,
                planType: PlanType.ULTRA
            },
            annually: {
                id: '',
                price: 14900,
                planInterval: PlanInterval.annually,
                planType: PlanType.ULTRA
            },
            usageLimitation: 40 * 60 * 60 // 40 hours in seconds
        },

        //
        // LEGACY PRICES
        //
        [PlanType.PERSONAL]: {
            monthly: {
                id: '',
                price: 1800,
                planInterval: PlanInterval.monthly,
                planType: PlanType.PERSONAL
            },
            annually: {
                id: '',
                price: 14400,
                planInterval: PlanInterval.annually,
                planType: PlanType.PERSONAL
            },
            usageLimitation: 20 * 60 * 60
        },
        [PlanType.BASIC]: {
            monthly: {
                id: '',
                price: 1800,
                planInterval: PlanInterval.monthly,
                planType: PlanType.BASIC
            },
            annually: {
                id: '',
                price: 14400,
                planInterval: PlanInterval.annually,
                planType: PlanType.BASIC
            },
            usageLimitation: 5 * 60 * 60
        },
        [PlanType.STARTER_BASIC]: {
            monthly: {
                id: '',
                price: 1800,
                planInterval: PlanInterval.monthly,
                planType: PlanType.BASIC
            },
            annually: {
                id: '',
                price: 14400,
                planInterval: PlanInterval.annually,
                planType: PlanType.BASIC
            },
            usageLimitation: 5 * 60 * 60
        },
        [PlanType.STARTER]: {
            monthly: {
                id: '',
                price: 2800,
                planInterval: PlanInterval.monthly,
                planType: PlanType.STARTER
            },
            annually: {
                id: '',
                price: 24000,
                planInterval: PlanInterval.annually,
                planType: PlanType.STARTER
            },
            usageLimitation: 10 * 60 * 60 // 10 hours in seconds
        },
        [PlanType.PRO_TEAM]: {
            monthly: {
                id: '',
                price: 4800,
                planInterval: PlanInterval.monthly,
                planType: PlanType.PRO_TEAM
            },
            annually: {
                id: '',
                price: 43200,
                planInterval: PlanInterval.annually,
                planType: PlanType.PRO_TEAM
            },
            usageLimitation: 20 * 60 * 60 // 40 hours in seconds
        },
        [PlanType.BUSINESS]: {
            monthly: {
                id: '',
                price: 24500,
                planInterval: PlanInterval.monthly,
                planType: PlanType.BUSINESS
            },
            annually: {
                id: '',
                price: 192000,
                planInterval: PlanInterval.annually,
                planType: PlanType.BUSINESS
            },
            usageLimitation: 100 * 60 * 60 // 100 hours in seconds
        },
        [PlanType.ENTERPRISE]: {
            monthly: {
                id: '',
                price: 0,
                planInterval: PlanInterval.monthly,
                planType: PlanType.ENTERPRISE
            },
            annually: {
                id: '',
                price: 0,
                planInterval: PlanInterval.annually,
                planType: PlanType.ENTERPRISE
            },
            usageLimitation: 100 * 60 * 60 // 100 hours in seconds
        }
    }
};
