import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { overlayAnimations } from '@type/shared/animations/overlay.animations';
import { OverlayComponentType } from '../overlay-component.model';

/**
 * Internal component that wraps user-provided popover content.
 */
@Component({
    selector: 'app-popover',
    exportAs: 'appPopover',
    templateUrl: './popover.component.html',
    styles: [
        `
            :host {
                display: none;
            }
        `
    ],
    animations: overlayAnimations
})
export class PopoverComponent extends OverlayComponentType implements OnChanges {
    @Input() hidePopover: boolean;

    ngOnChanges({ hidePopover }: SimpleChanges) {
        if (hidePopover && this.ref?.config) {
            this.ref.config.disableClose = this.hidePopover;
        }
    }
}
