import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pause'
})
export class PausePipe implements PipeTransform {
    transform(value: number, args?: any): string {
        // console.log(value);

        if (value === 0) {
            return '';
        }
        const pauseTimesCount = value / 0.25;
        console.log('PausePipe -> transform -> pauseTimesCount', pauseTimesCount);
        let pauseString = '';
        for (let i = 0; i < pauseTimesCount; i++) {
            pauseString += '  ▪  ';
        }
        console.log('PausePipe -> transform -> pauseString', pauseString);
        return pauseString;
    }
}
