import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges
} from '@angular/core';
import { TabComponent } from './tab.component';

class TabSelectedModel {
    index: number;
    caseValue: string | number;
}

@Component({
    selector: 'type-tabs',
    template: `
        <div class="flex justify-between items-center " [ngClass]="{ 'pb-2': !hideTabs }">
            <ul class="tabs-nav-container scroll-bar-hidden" *ngIf="!hideTabs">
                <li
                    class="tab-nav-item"
                    *ngFor="let tab of tabs"
                    (click)="selectTab(tab)"
                    [class.tab-nav-item-active]="tab.active"
                >
                    <a href="" (click)="$event.preventDefault()">
                        <svg-icon *ngIf="tab.iconPath" [src]="tab.iconPath"></svg-icon>
                        <span [class.sr-only]="tab.iconOnly">{{ tab.label }}</span>
                    </a>
                </li>
                <!--            <div [style]="indicatorStyles" class="tab-nav-indicator"></div>-->
            </ul>
            <ng-content></ng-content>
        </div>
        <div class="tab-container">
            <ng-content select="type-tab"></ng-content>
        </div>
    `,
    styleUrls: ['tabs.component.scss']
})
export class TabsComponent implements AfterContentInit, OnChanges {
    @Input() hideTabs: boolean;
    @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
    @Input() switchValue: string | number;
    @Output() tabSelected = new EventEmitter<TabSelectedModel>();

    ngOnChanges({ switchValue }: SimpleChanges) {
        if (
            switchValue &&
            !switchValue.firstChange &&
            this.switchValue &&
            switchValue.currentValue !== switchValue.previousValue
        ) {
            this._selectTabByCaseValue();
        }
    }

    private _selectTabByCaseValue(initial?: boolean) {
        const selectedTab = this.tabs.find((tab) => tab.caseValue === this.switchValue);
        this.selectTab(selectedTab, initial);
    }

    // contentChildren are set
    ngAfterContentInit() {
        // get all active tabs
        const activeTabs = this.tabs.filter((tab) => tab.active);

        // if there is no active tab set, activate the first
        if (activeTabs.length !== 0) return;
        setTimeout(() => {
            if (this.switchValue) {
                this._selectTabByCaseValue(true);
            } else {
                this.selectTab(this.tabs.first, true);
            }
        });
    }

    selectTab(tabComponent: TabComponent, initial?: boolean) {
        // deactivate all other tabs
        this.tabs.toArray().forEach((tab, index) => {
            if (tab !== tabComponent) {
                tab.active = false;
                return;
            }
            if (tab.active) return;
            tab.active = true;
            this.switchValue = tab.caseValue;
            if (initial) return;
            this.tabSelected.next({ caseValue: tab.caseValue, index });
        });

        // // move indicator
        // this.indicatorStyles = {
        //     width: `${el.offsetWidth}px`,
        //     transform: `translateX(${el.offsetLeft}px)`
        // };
    }
}
