import { Highlightable } from '@angular/cdk/a11y';
import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { TypDropdownService } from './typ-dropdown.service';
import { TypSelectFieldComponent } from '../../forms/typ-select-field/typ-select-field.component';

@Component({
    selector: 'app-select-field-option',
    template: `
        <span *ngIf="isUpgradeRequired" class="badge badge-starter-solid"
            ><svg-icon appIcon class="icon-size-lg" src="/assets/ic/24/sparkles.svg"></svg-icon
        ></span>
        {{ label }}
    `
})
export class TypSelectFieldOptionComponent implements Highlightable {
    @Input() value: string | number | boolean;
    @Input() label: string;

    @HostBinding('class.selected')
    get selected(): boolean {
        return this.select.selectedOption === this;
    }
    @HostBinding('class.typ-menu-item') menuItem = true;
    @HostBinding('class.active') active = false;

    @HostBinding('class.typ-button-upgrade-hover-bg')
    @Input()
    isUpgradeRequired;

    private select: TypSelectFieldComponent;

    constructor(dropdownService: TypDropdownService) {
        this.select = dropdownService.getSelect();
    }

    getLabel(): string {
        return this.label;
    }

    setActiveStyles(): void {
        this.active = true;
    }

    setInactiveStyles(): void {
        this.active = false;
    }

    @HostListener('click', ['$event'])
    onClick(event: UIEvent) {
        event.preventDefault();
        event.stopPropagation();
        if (this.isUpgradeRequired) {
            this.select.hideDropdown();
            // TODO: show we re-focus on select input after close
            return;
        }

        this.select.selectOption(this);
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        event.stopPropagation();
    }
}
