import { Injectable } from '@angular/core';
import Axios from 'axios';
import { firebaseFunctions } from '@type-mvp/environments/environment';
import { PlanType, PlanInterval } from '@type/shared-models/payment/products';
import { PromotionCode } from '@type/shared-models/payment/promotion-code.models';
import { CreateSubscriptionRequestData } from '@type/shared-models/payment/create-subscription-data';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { AnalyticsEvents } from '@type/shared-models/consts/analytic-events';
import { CreateCustomerPortalUrlRequestData } from '@type/shared-models/payment/create-customer-portal-url-request-data.models';
import { FirebaseFunctionNames } from '@type/shared-models/consts/firebase-function-names';
import { map } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

export interface CancelSubscriptionRequest {
    firebaseId: string;
    customerId: string;
    subscriptionId: string;
}

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    constructor(private userService: UserService, private fireFunctions: AngularFireFunctions) {}

    async createSubscription(
        paymentMethodId: any,
        plan: PlanType,
        frequency: PlanInterval,
        promotionCodeId?: string,
        reason?: string
    ) {
        const createSubscriptionRequestData: CreateSubscriptionRequestData = {
            paymentMethodId,
            firebaseId: this.userService.user.firebaseId,
            customerId: this.userService.user.customerId,
            email: this.userService.user.email,
            plan,
            frequency,
            promotionCodeId,
            paymentReason: reason
        };
        try {
            return this.fireFunctions
                .httpsCallable(firebaseFunctions.createPayment23hkkh1v23hkv1khb)(createSubscriptionRequestData)
                .pipe(
                    map((response) => ({
                        isSuccess: response?.latest_invoice?.payment_intent?.status === 'succeeded',
                        subscription: response.data,
                        invoice: response?.latest_invoice,
                        clientSecret: response?.latest_invoice.payment_intent?.client_secret
                    }))
                );
        } catch (e) {
            console.log(e);
        }
    }

    getCustomerPortalUrl(): Observable<string> {
        window.analytics.track(AnalyticsEvents.CUSTOMER_PORTAL_OPENED);

        const createCustomerPortalUrlRequest: CreateCustomerPortalUrlRequestData = {
            customerId: this.userService.user?.customerId,
            returnUrl: 'https://podcasteditor.streamlabs.com/editor'
        };
        // return response.data.data.url;
        return this.fireFunctions
            .httpsCallable<CreateCustomerPortalUrlRequestData>(FirebaseFunctionNames.createCustomerPortalUrl)(
                createCustomerPortalUrlRequest
            )
            .pipe(map((billingPortalSession) => billingPortalSession.url));
    }

    /**
     * Requests Stripe API to check if promotionCode exists and is active
     *
     * @param promotionCode that will be checked
     */
    public async validatePromotionCode(promotionCode): Promise<PromotionCode> {
        return new Promise(async (resolve, reject) => {
            try {
                if (promotionCode) {
                    const requestData: PromotionCode = { code: promotionCode };
                    const response = await Axios({
                        method: 'post',
                        url: firebaseFunctions.validatePromotionCode,
                        data: requestData
                    });
                    resolve(response.data.data);
                } else {
                    resolve(null);
                }
            } catch (error) {
                reject(error);
            }
        });
    }
}
