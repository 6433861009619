import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import paths from '@type/shared-models/consts/firebase-paths';
import { getLimitationByPlanType, TrialLimitations, VideoLimitations } from '@type/shared-models/consts/limitations';
import { Language } from '@type/shared-models/language';
import { SubscriptionState } from '@type/shared-models/user/subscription-state';
import { PersistentUiState, User, UserModel } from '@type/shared-models/user/user';
import { DateUtils, getCurrentUnixTimestamp } from '@type/shared-models/utils/date-utils';
import { BehaviorSubject, from, Observable, of, Subscription } from 'rxjs';
import { filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import firebase from 'firebase/compat';
import { AnalyticsService } from './analytics.service';
import { AnalyticsEvents } from '@type/shared-models/consts/analytic-events';
import { ShareWithType } from '@type/shared-models/sharing/share-with-type';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import {
    DurationAudioError,
    DurationVideoError,
    ExtensionAudioError,
    ExtensionFileError,
    ExtensionVideoError,
    FilesizeAudioError,
    FilesizeFileError,
    FilesizeVideoError,
    getExtensionsString,
    InvalidFileError,
    RecordingLimitationReachedError,
    UsageLimitationReachedError
} from '@type/shared-models/consts/error-messages';
import stringInject from '@type/shared/utils/string';
import { PLAN_LEVELS, PlanTable } from '@type/shared-models/payment/products';
import { DialogService } from '@type/dialog';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FirebaseFunctionNames } from '@type/shared-models/consts/firebase-function-names';
// import * as Sentry from '@sentry/angular';
import {
    LinkSlidRequest,
    LinkSlidResponse,
    UpdateSlidStateRequest,
    UpdateSlidStateResponse
} from '@type/shared-models/auth/authenticate-slid.models';
import { environment } from '@type-mvp/environments/environment';
import { isLocalEnvironment } from '../../environments/environment.utils';
import { OAuthAuthorizationResponse } from '@type/shared-models/sharing/o-auth-authorization';
import { loadTrackers, trackLogin } from '../../../../../libs/shared-models/src/lib/utils/tracking';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    user$: BehaviorSubject<User> = new BehaviorSubject(null);
    private sub = new Subscription();
    limitations$: BehaviorSubject<VideoLimitations> = new BehaviorSubject(TrialLimitations);

    public get user(): User {
        return this.user$.value;
    }

    getUserDocumentReference(userId: string) {
        return this.firestore.collection(paths.USERS).doc<UserModel>(userId).ref;
    }
    getUserDocument(userId: string) {
        return this.firestore.collection(paths.USERS).doc<UserModel>(userId);
    }

    constructor(
        private firestore: AngularFirestore,
        private analyticsService: AnalyticsService,
        private deviceService: DeviceDetectorService,
        private firebaseAuth: AngularFireAuth,
        private router: Router,
        private dialogService: DialogService,
        private angularFireFunctions: AngularFireFunctions
    ) {
        this.onUserLoaded().then();
        this.subscribeUserChanges();
    }

    resetUser() {
        this.sub.unsubscribe();
        this.user$.next(null);
    }

    subscribeUserChanges() {
        this.sub.add(
            this.user$.pipe(filter((user) => user != null)).subscribe((user) => {
                this.limitations$.next(getLimitationByPlanType(user.subscriptionState.plan.type));
            })
        );
    }
    subscribeAuthenticatedUser(auth$: Observable<firebase.User>) {
        auth$
            .pipe(
                switchMap((firebaseUser: firebase.User) => (firebaseUser ? this.getUser$(firebaseUser.uid) : of(null)))
            )
            .subscribe(this.user$);
    }

    loadUserOnce(): Promise<User> {
        return new Promise((resolve) => {
            this.user$
                .pipe(
                    filter((user) => user != null),
                    first()
                )
                .subscribe(async (user) => {
                    const checkedUser = await this.updateAllLegacyValues(user);
                    resolve(checkedUser);
                });
        });
    }

    identifyUser(createdUser: User, referrer?: string) {
        const identifiedUserProperties = {
            id: createdUser.firebaseId,
            customerId: createdUser.customerId,
            name: createdUser.name,
            email: createdUser.email,
            createdAt: createdUser.createdAt,
            plan: createdUser.subscriptionState?.plan?.type,
            referrer,
            device: this.deviceService.device,
            isMobile: this.deviceService.isMobile()
        };

        this.analyticsService.identify(createdUser.firebaseId, identifiedUserProperties);
        this.analyticsService.track(AnalyticsEvents.SESSION_STARTED, identifiedUserProperties);
        if (!createdUser.isAnonymous) {
            trackLogin();
        }
        // Sentry.configureScope((scope) => {
        //     scope.setTags({
        //         id: createdUser?.firebaseId,
        //         email: createdUser?.email,
        //         plan: createdUser.subscriptionState?.plan?.type
        //     });
        // });
    }

    async onUserLoaded() {
        const user = await this.loadUserOnce();
        console.log('load user');

        // track user with its values

        loadTrackers(window, document, environment.googleTagId, environment.fbPixelId, environment.uetqId);
        this.identifyUser(user);
        this.renewUsageLimitation(user).then();
        this.refreshSlidState(user);
    }

    checkUserExists(firebaseId: string): Promise<boolean> {
        return this.firestore
            .collection(paths.USERS)
            .doc<User>(firebaseId)
            .valueChanges()
            .pipe(
                map((user) => !!user),
                take(1)
            )
            .toPromise();
    }

    private getUser$(firebaseId: string): Observable<User> {
        return this.firestore
            .collection(paths.USERS)
            .doc<User>(firebaseId)
            .valueChanges()
            .pipe(
                filter((user) => user != null),
                switchMap(async (user) => new User().fromFirebase(user))
            );
    }

    async updateUsageLimitation(user: User): Promise<User> {
        if (user.subscriptionState && !user.subscriptionState.isBasic) {
            await this.renewUsageLimitation(user);
        }
        return user;
    }

    public async renewUsageLimitation(user: User): Promise<void> {
        let endDate = user.subscriptionState.endDate
            ? DateUtils.dateFromTimestamp(user.subscriptionState.endDate)
            : null;
        const currentDate = new Date();
        if (!endDate || currentDate > endDate) {
            if (!endDate) {
                endDate = new Date();
            }

            const newEndDate = new Date(endDate);

            // add month until newEndDate
            while (currentDate > newEndDate) {
                DateUtils.addMonthsToDate(newEndDate, 1);
            }

            user.subscriptionState.endDate = DateUtils.toUnixTimestamp(newEndDate);
            user.subscriptionState.usedSeconds = 0;

            await this.updateSubscriptionState(user.firebaseId, user.subscriptionState);
        }
    }

    async updateAllLegacyValues(user: User): Promise<User> {
        // user = this.checkSubscriptionState(user);
        user = this.checkExampleProjectVisible(user);
        user = await this.updateUsageLimitation(user);
        this.checkIfProfilePictureExists();
        return user;
    }

    checkExampleProjectVisible(user: User) {
        if (user.exampleProjectVisible == null) {
            this.updateExampleProjectVisible(true, user.firebaseId);
            user.exampleProjectVisible = true;
        }
        return user;
    }

    disableExampleProject() {
        this.user$.value.exampleProjectVisible = false;
        this.getUserDocumentReference(this.user$.value.firebaseId)
            .update({
                exampleProjectVisible: this.user$.value.exampleProjectVisible
            })
            .then();
    }

    addFeatureId(featureId: string) {
        if (featureId && !this.user.votedFeatureIds.includes(featureId)) {
            this.user.votedFeatureIds.push(featureId);
            const userReference = this.getUserDocumentReference(this.user.firebaseId);
            userReference
                .update({
                    votedFeatureIds: this.user.votedFeatureIds
                })
                .then();
        }
    }

    updateLanguage(language: Language) {
        if (language) {
            this.firestore
                .collection(paths.USERS)
                .doc(this.user$.value.firebaseId)
                .update({ language: language.codeLong })
                .then();
        }
    }

    updatePromotionCodeId(promotionCodeId: string) {
        this.user.promotionCodeId = promotionCodeId;
        this.firestore.collection(paths.USERS).doc(this.user$.value.firebaseId).update({ promotionCodeId }).then();
    }

    updateSubscriptionState(firebaseId: string, subscriptionState: SubscriptionState) {
        return this.firestore
            .collection(paths.USERS)
            .doc(firebaseId)
            .update({ subscriptionState: subscriptionState.toFirebase() });
    }

    updateName(newName: string) {
        this.user.name = newName;
        return from(this.firestore.collection(paths.USERS).doc(this.user$.value.firebaseId).update({ name: newName }));
    }
    updateEmail(newEmail: string) {
        this.user.email = newEmail;
        this.firestore.collection(paths.USERS).doc(this.user$.value.firebaseId).update({ email: newEmail }).then();
    }

    updateExampleProjectVisible(exampleProjectVisible: boolean, userId?: string) {
        let updateUserId;
        if (userId) {
            updateUserId = userId;
        } else {
            updateUserId = this.user$.value.firebaseId;
        }

        this.firestore.collection(paths.USERS).doc(updateUserId).update({ exampleProjectVisible }).then();
    }
    updateSurveyStep(newSurveyStep: number) {
        this.firestore.collection(paths.USERS).doc(this.user.firebaseId).update({ surveyStep: newSurveyStep }).then();
    }

    deleteUser(navigateToLogin = true): Observable<void> {
        return this.firebaseAuth.user.pipe(
            switchMap((user) => user.delete()),
            tap(() => {
                if (navigateToLogin) {
                    this.router.navigate(['auth', 'login']);
                }
            })
        );
    }

    updateSocialExport(type: ShareWithType, object, closeTabAfterDone) {
        this.user$
            .pipe(
                filter((user) => !!user),
                take(1)
            )
            .subscribe((user) => {
                this.getUserDocumentReference(user.firebaseId)
                    .set(
                        {
                            socialExports: {
                                [type]: object
                            }
                        },
                        { merge: true }
                    )
                    .then(() => (closeTabAfterDone ? window.close() : ''));
            });
    }

    deleteSocialExport(user: User, shareWithType: ShareWithType) {
        this.getUserDocumentReference(user.firebaseId)
            .set(
                {
                    socialExports: {
                        [shareWithType]: {}
                    }
                },
                { merge: true }
            )
            .then();
    }

    updateProfilePictureUrl(user: User, profilePicture: string) {
        this.getUserDocumentReference(user.firebaseId)
            .update({
                profilePicture
            })
            .then();
    }

    /**
     * Checks if the profile picture exists and if not deletes it.
     *
     * @private
     */
    checkIfProfilePictureExists() {
        if (!this.user?.profilePicture) {
            return;
        }
        // Check if image is loading / exists, if not delete it
        let img = new Image();
        img.src = this.user?.profilePicture;
        if (img.complete) {
            img = null;
        } else {
            img.onload = () => {
                img = null;
            };
            img.onerror = () => {
                this.updateProfilePictureUrl(this.user, null);
            };
        }
    }

    getUserUiState<
        K1 extends keyof PersistentUiState,
        K2 extends keyof PersistentUiState[K1],
        K3 extends keyof PersistentUiState[K1][K2],
        K4 extends keyof PersistentUiState[K1][K2][K3]
    >(k1: K1, k2?: K2, k3?: K3, k4?: K4);
    getUserUiState(...props: string[]) {
        let currentSlice = this.user?.uiState;
        props.every((prop) => {
            if (currentSlice === undefined || currentSlice === null) {
                return;
            }
            currentSlice = currentSlice[prop];
            return true;
        });
        return currentSlice;
    }

    updateUserUiState<
        K1 extends keyof PersistentUiState,
        K2 extends keyof PersistentUiState[K1],
        K3 extends keyof PersistentUiState[K1][K2],
        K4 extends keyof PersistentUiState[K1][K2][K3]
    >(value, k1: K1, k2?: K2, k3?: K3, k4?: K4): Observable<any>;
    updateUserUiState(value, ...props: string[]): Observable<any> {
        if (this.user) {
            const uiState = { ...this.user?.uiState };
            let currentSlice = uiState;
            props.forEach((prop, index) => {
                if (props.length - 1 === index) {
                    currentSlice[prop] = value;
                } else {
                    currentSlice[prop] = { ...currentSlice[prop] };
                    currentSlice = currentSlice[prop];
                }
            });

            return from(this.firestore.collection(paths.USERS).doc(this.user.firebaseId).update({ uiState }));
        }
    }

    /**
     * Returns the error filled up with the limitations based on the users subscription plan
     */
    getErrorWithUserLimits(
        errorName:
            | 'InvalidFileError'
            | 'DurationAudioError'
            | 'DurationVideoError'
            | 'FilesizeVideoError'
            | 'FilesizeAudioError'
            | 'FilesizeFileError'
            | 'ExtensionVideoError'
            | 'ExtensionAudioError'
            | 'ExtensionFileError'
            | 'UsageLimitationReachedError'
            | 'RecordingLimitationReachedError'
    ) {
        if (!this.user) {
            return null;
        }
        const currentPlan = this.user.subscriptionState.plan.type;
        const nextHigherPlan = this.user.getNextHigherPlanType();
        const userLimits = getLimitationByPlanType(currentPlan);
        const errorData = {
            minutes: (Math.round((userLimits.length / 60) * 100) / 100).toFixed(2),
            hoursMonth: (Math.round((PlanTable.production[currentPlan].usageLimitation / 60 / 60) * 100) / 100).toFixed(
                2
            ),
            currentPlanName: PLAN_LEVELS[currentPlan].label.toLocaleLowerCase(),
            higherPlanName: PLAN_LEVELS[nextHigherPlan]?.label?.toLocaleLowerCase(),
            sizeString: userLimits.sizeString,
            videoExtensionString: getExtensionsString(userLimits.extensions.video),
            audioExtensionString: getExtensionsString(userLimits.extensions.audio),
            allExtensionString: getExtensionsString(userLimits.extensions.video.concat(userLimits.extensions.audio))
        };
        // Helper function to inject errorData in the error
        const injectErrorDataIntoString = (error) => {
            for (const key of Object.keys(error)) {
                if (typeof error[key] === 'string' || error[key] instanceof String) {
                    error[key] = stringInject(error[key], errorData);
                }
            }
            if (!errorData.higherPlanName) {
                error.upgradeButton = false;
            }
            return error;
        };

        const addIncreasingLimitMessageIfNoUpgradeAvailable = (error, message?) => {
            if (errorData.higherPlanName) {
                return error;
            }
            return { ...error, line2: message || 'We’re working on increasing this limit.' };
        };

        let resultError;
        switch (errorName) {
            case 'DurationAudioError':
                resultError = addIncreasingLimitMessageIfNoUpgradeAvailable(
                    injectErrorDataIntoString({ ...DurationAudioError })
                );
                if (userLimits.length === 60 * 90) {
                    resultError.line2 = 'We’re working on increasing this limit.';
                    resultError.upgradeButton = false;
                }
                return resultError;
            case 'DurationVideoError':
                resultError = addIncreasingLimitMessageIfNoUpgradeAvailable(
                    injectErrorDataIntoString({ ...DurationVideoError })
                );
                if (userLimits.length === 60 * 90) {
                    resultError.line2 = 'We’re working on increasing this limit.';
                    resultError.upgradeButton = false;
                }
                return resultError;
            case 'FilesizeVideoError':
                resultError = addIncreasingLimitMessageIfNoUpgradeAvailable(
                    injectErrorDataIntoString({ ...FilesizeVideoError })
                );
                if (userLimits.size === 3550) {
                    resultError.line2 = 'We’re working on increasing this limit.';
                    resultError.upgradeButton = false;
                }
                return resultError;
            case 'FilesizeAudioError':
                resultError = addIncreasingLimitMessageIfNoUpgradeAvailable(
                    injectErrorDataIntoString({ ...FilesizeAudioError })
                );
                if (userLimits.size === 3550) {
                    resultError.line2 = 'We’re working on increasing this limit.';
                    resultError.upgradeButton = false;
                }
                return resultError;
            case 'FilesizeFileError':
                resultError = addIncreasingLimitMessageIfNoUpgradeAvailable(
                    injectErrorDataIntoString({ ...FilesizeFileError })
                );
                if (userLimits.size === 3550) {
                    resultError.line2 = 'We’re working on increasing this limit.';
                    resultError.upgradeButton = false;
                }
                return resultError;
            case 'InvalidFileError':
                return InvalidFileError;
            case 'ExtensionVideoError':
                return injectErrorDataIntoString({ ...ExtensionVideoError });
            case 'ExtensionAudioError':
                return injectErrorDataIntoString({ ...ExtensionAudioError });
            case 'ExtensionFileError':
                return injectErrorDataIntoString({ ...ExtensionFileError });
            case 'UsageLimitationReachedError':
                return addIncreasingLimitMessageIfNoUpgradeAvailable(
                    injectErrorDataIntoString({ ...UsageLimitationReachedError }),
                    'If you would like to upload more videos or audios please contact us.'
                );
            case 'RecordingLimitationReachedError':
                return injectErrorDataIntoString(
                    addIncreasingLimitMessageIfNoUpgradeAvailable({ ...RecordingLimitationReachedError })
                );
        }
    }

    /**
     * Opens the email verification modal if the user is not verified
     *
     * @return true if the user is not verified and the modal was opened; false if the user is verified and the modal was not opened
     */
    openUserConfirmationModalIfUnverified() {
        console.log('disabled');

        // this.user$
        //     .pipe(
        //         filter((user) => user != null),
        //         take(1)
        //     )
        //     .subscribe((user) => {
        //         console.log(user.isVerified);
        //         if (!user?.isVerified) {
        //             this.router.navigate([Routes.PROJECTS], { queryParamsHandling: 'merge' });
        //             this.dialogService.openDialog(UserConfirmationComponent, {});
        //         }
        //     });
    }

    /**
     * Triggers the cloud function to resend the verification email
     *
     * @return Observable
     */
    sendVerificationEmail() {
        return this.angularFireFunctions.httpsCallable(FirebaseFunctionNames.sendUserVerification, {
            timeout: 60 * 1000
        })({});
    }

    updateUserVerification(userId: string, isVerified: boolean) {
        this.getUserDocumentReference(userId)
            .update({
                isVerified
            })
            .then();
    }

    refreshSlidState(user: User, forceRefresh = false) {
        console.log('🚀 ~ user', user);
        if (user?.slidState?.lastUpdate || forceRefresh) {
            const minutesSinceLastUpdate = (getCurrentUnixTimestamp() - user?.slidState?.lastUpdate) / 60;
            if (minutesSinceLastUpdate > 60 || forceRefresh) {
                this.angularFireFunctions
                    .httpsCallable<UpdateSlidStateRequest, UpdateSlidStateResponse>(
                        FirebaseFunctionNames.updateSlidState,
                        {}
                    )({
                        userId: user.firebaseId,
                        fromLocalEnvironment: isLocalEnvironment(),
                        forceRefresh
                    })
                    .subscribe((response) => {});
            }
        }
    }
    linkSlid(oAuthResponse: OAuthAuthorizationResponse) {
        if (oAuthResponse.error_code) {
            return;
        }
        return this.angularFireFunctions.httpsCallable<LinkSlidRequest, LinkSlidResponse>(
            FirebaseFunctionNames.linkSlid,
            {}
        )({
            ...oAuthResponse,
            fromLocalEnvironment: isLocalEnvironment()
        });
    }
}
