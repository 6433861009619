import { ProjectCanvasElements } from './canvas-elements';
import paths from './consts/firebase-paths';
import { RenderingStateType } from './consts/rendering-states';
import { Visibility } from './consts/visibility';
import { defaultSpeakers, Speaker } from './editor/speaker';
import { FbTranscription, TranscriptionMapElement, Transcription, MediaUrlWithIndex } from './editor/transcription';
import { Word } from './editor/word';
import { MediaType } from './media/media.utils';
import { MemberMapModel } from './member.models';
import { OwnerModel } from './organization/organization.models';
import { Resolution } from './resolution';
import { StartEndConfig } from './start-end-word.model';
import { SubtitleConfig, SubtitleMode } from './subtitle-mode';
import { Translation } from './translation/translation';
import { VoiceTranslationState } from './translation/voice-translation';
import { VoiceTranslationMode } from './translation/voice-translation-mode';
import { cloneDeep } from 'lodash';
import { ClipConfig } from './rendering/clip-config';
import { Language } from './language';

export interface RetainedMediaDetails {
    mediaUrl: string;
    mediaUri: string;
    mediaDuration: number;
}

export interface SpeakerMap {
    [index: number]: {
        name: string;
    };
}

export enum TranscriptionState {
    INITIAL = 'initial',
    COMPLETED = 'completed',
    UPLOADING = 'uploading',
    IN_PROGRESS = 'in-progress',
    STREAMING = 'streaming',
    FAILED = 'failed'
}

export enum AutoExtractHighlightsState {
    COMPLETED = 'completed',
    IN_PROGRESS = 'in-progress',
    FAILED = 'failed'
}

export type TranscriptionStateType = typeof TranscriptionState[keyof typeof TranscriptionState];
export type RenderingType = 'audio' | 'video';

/* 
AutoEditingConfig
Subtitles: Are turned on directly in the mainEditorConfig.subtitlesConfig.subtitleMode
Translation: To refresh the transcript is needed to call registerTranslationChanges().subscribe()
*/
export interface AutoEditingConfig {
    startTranscription?: boolean;
    startRendering?: boolean;
    renderingType?: RenderingType;
    removePauses?: boolean;
    removeFillers?: boolean;
    translationLanguageCodeLong?: string | null;
    translateVoice?: boolean;
    isMobile?: boolean;
    subtitleMode?: SubtitleMode;
    transcriptionLanguageCodeLong?: string;
}

export interface VideoEditorConfig {
    canvasElements?: ProjectCanvasElements;
    backgroundColor?: string;
    // subtitleConfig?: SubtitleConfig;
    resolution?: Resolution;
    autoEditingConfig?: AutoEditingConfig;
    // templateId: string;
}

export interface FbPrivateProject {
    inviteAccessToken?: string;
}
export interface FbProject {
    id: string;
    userId: string;
    createdAt: number;
    lastUsedAt: number;
    title: string;
    isExample?: boolean;
    members?: MemberMapModel;
    owner?: OwnerModel;

    transcription?: FbTranscription;
    translation?: Translation;

    transcriptionMap: TranscriptionMapElement[];
    translationMap: TranscriptionMapElement[];
    speakerMap?: SpeakerMap;

    transcriptionState?: TranscriptionStateType;
    translationLanguage: string;
    transcriptionStartTime?: number; // Start time from unix epoch

    // Voice translation
    voiceTranslationState?: VoiceTranslationState;
    currentVoiceTranslationReferences?: string[];
    voiceTranslationMode: VoiceTranslationMode;

    subtitleOptions?;
    showSubtitleBreaks?: boolean;
    subtitleOptionsExpanded?: boolean;

    language: string;
    thumbnailUrl: string;

    // Rendering
    renderingId: string;
    renderingState: RenderingStateType;
    renderingUri: string;

    // Sharing Screen
    visibility: Visibility;
    sharingTitle: string;

    //Clip renderings
    clipCreationCount: number;
    autoExtractHighlightsState: string;
    clipConfigs: {
        [clipId: string]: ClipConfig;
    };

    mainVideoEditorConfig: VideoEditorConfig;

    lastOpenSideMenuItem: string;

    /**
     * now in VideoEditorConfig
     */
    subtitleMode?: SubtitleMode;
    canvasElements?: ProjectCanvasElements;
    projectResolution?: Resolution;
    backgroundColor?: string;
    fixedSubtitleLength?: number;
    templateId?: string;

    //Single video project support
    mediaUrl?: string;
    previewUrl?: string;

    /**
     * legacy
     */
    rotation?: number;
    transformedVideoResolution?: Resolution;
    videoScale?: number;
    videoPositionX?: number;
    videoPositionY?: number;
    videoZIndex?: number;
    mediaUri?: string;
    mediaType?: MediaType;
    videoResolution?: Resolution;
    mediaDuration?: number; // Duration in seconds
    latestMediaDuration?: number; // Duration in seconds
    videoUrl?: string;
    videoUri?: string;
    // support
    forceStreaming?: boolean;

    // retained second part of a media when usage wasn't sufficient to transcribe the full file
    retainedMediaDetails?: RetainedMediaDetails;
}
export class Project {
    id: string;
    userId: string;
    members?: MemberMapModel;
    owner?: OwnerModel;
    // inviteAccessToken?: string;
    isProjectOwner?: boolean; // generated on the frontend;

    createdAt: number;
    lastUsedAt: number;
    isExample = false;
    title: string;

    transcription: Transcription;
    transcriptionMap: TranscriptionMapElement[];
    speakers?: Speaker[];

    translation?: Translation;
    translationMap: TranscriptionMapElement[];
    translationLanguage: string;

    transcriptionState?: TranscriptionStateType;
    transcriptionStartTime?: number; // Start time from unix epoch

    public get firstMediaDuration(): number {
        return this.mainVideoEditorConfig.canvasElements.media[0]?.mediaDuration || (this as any).mediaDuration;
    }

    // Voice translation
    voiceTranslationState?: VoiceTranslationState;
    voiceTranslationIds?: string[];
    voiceTranslationMode: VoiceTranslationMode;

    showSubtitleBreaks?: boolean;

    subtitleOptionsExpanded?: boolean;
    templateId?: string;

    language: string;

    thumbnailUrl: string;

    // Rendering
    renderingId: string;
    renderingState: RenderingStateType;
    renderingUri: string;
    renderingType?: RenderingType;

    //Clips
    clipCreationCount?: number;
    autoExtractHighlightsState?: string;
    clipConfigs?: {
        [clipId: string]: ClipConfig;
    };
    mainVideoEditorConfig: VideoEditorConfig;

    lastOpenSideMenuItem?: string | null;
    // fixedSubtitleLength?: number;
    // subtitleMode: SubtitleMode;

    // videoResolution: Resolution;
    // projectResolution: Resolution;
    // canvasElements?: ProjectCanvasElements;
    // backgroundColor? = '#ffffff';

    // support
    forceStreaming?: boolean;
    // Sharing Screen
    visibility: Visibility;
    sharingTitle: string;

    // retained second part of a media when usage wasn't sufficient to transcribe the full file
    retainedMediaDetails?: RetainedMediaDetails;

    init(id: string, userId: string, title: string) {
        this.id = id;
        this.userId = userId;
        this.owner = {
            firebaseId: userId,
            resourceType: paths.USERS
        };
        this.createdAt = Math.floor(Date.now() / 1000);
        this.lastUsedAt = Math.floor(Date.now() / 1000);

        this.transcriptionMap = [];
        this.translationMap = [];
        this.title = title;
        this.transcription = null;
        this.transcriptionState = TranscriptionState.INITIAL;
        this.voiceTranslationState = null;
        this.voiceTranslationIds = null;
        this.voiceTranslationMode = VoiceTranslationMode.NONE;
        this.subtitleOptionsExpanded = false;
        this.showSubtitleBreaks = false;
        this.visibility = Visibility.PRIVATE;
        this.clipCreationCount = 0;
        this.autoExtractHighlightsState = null;
        this.lastOpenSideMenuItem = null;

        this.mainVideoEditorConfig = {
            autoEditingConfig: {},
            canvasElements: {
                text: [],
                image: [],
                shape: [],
                media: [],
                progress: [],
                subtitle: null
            },
            resolution: null,
            backgroundColor: '#ffffff'
        };
        return this;
    }
    static duplicate(project: FbProject, newId?): FbProject {
        return {
            ...project,

            createdAt: Math.floor(Date.now() / 1000),
            lastUsedAt: Math.floor(Date.now() / 1000),
            title: '[Copy] ' + project.title,
            subtitleMode: project.subtitleMode || SubtitleMode.disabled,
            showSubtitleBreaks: false,
            id: newId || null,
            renderingState: null,
            renderingId: null,
            renderingUri: null
        };
    }

    fromFirebase(project: FbProject): Project {
        this.id = project.id;
        this.createdAt = project.createdAt;
        this.lastUsedAt = project.lastUsedAt || this.createdAt;
        this.title = project.title;
        // this.inviteAccessToken = project.inviteAccessToken;
        this.userId = project.userId;
        this.members = project.members;
        this.owner = project.owner;
        this.isExample = project.isExample;

        this.thumbnailUrl = project.thumbnailUrl;
        this.language = project.language;
        this.translationLanguage = project.translationLanguage || null;
        this.templateId = project.templateId;

        this.renderingId = project.renderingId;
        this.renderingState = project.renderingState;
        this.renderingUri = project.renderingUri;

        this.clipCreationCount = project.clipCreationCount;
        this.autoExtractHighlightsState = project.autoExtractHighlightsState;
        this.clipConfigs = project.clipConfigs;

        this.lastOpenSideMenuItem = project.lastOpenSideMenuItem;

        this.visibility = project.visibility || Visibility.PRIVATE;
        this.sharingTitle = project.sharingTitle || null;

        this.transcriptionMap = project.transcriptionMap || [];
        this.transcriptionState = project.transcriptionState || null;
        this.transcriptionStartTime = project.transcriptionStartTime || null;
        this.translationMap = project.translationMap || [];
        this.setSpeakersFromProject(project);

        // Voice translation
        this.voiceTranslationState = project.voiceTranslationState || null;
        this.voiceTranslationIds = project.currentVoiceTranslationReferences || null;
        this.voiceTranslationMode = project.voiceTranslationMode || null;

        // Set video resolution

        this.setMainVideoEditorConfig(project);
        // legacy support
        // this.subtitleMode = project.subtitleMode || project.subtitleOptions.subtitleMode || SubtitleMode.disabled;
        this.showSubtitleBreaks = project.showSubtitleBreaks || false;
        // this.fixedSubtitleLength = project.fixedSubtitleLength;
        this.subtitleOptionsExpanded = project.subtitleOptionsExpanded;

        this.forceStreaming = project.forceStreaming;
        this.retainedMediaDetails = project.retainedMediaDetails;

        // if (project.canvasElements) {
        //     this.canvasElements = project.canvasElements;
        //     if (!this.canvasElements.text) {
        //         this.canvasElements.text = [];
        //     }
        //     if (!this.canvasElements.image) {
        //         this.canvasElements.image = [];
        //     }
        //     if (!this.canvasElements.shape) {
        //         this.canvasElements.shape = [];
        //     }
        //     if (!this.canvasElements.progress) {
        //         this.canvasElements.progress = [];
        //     }
        // } else {
        //     this.canvasElements = {
        //         text: [],
        //         image: [],
        //         shape: [],
        //         media: [],
        //         progress: [],
        //         subtitle: null
        //     };
        // }

        return this;
    }

    setTranscriptionFromWords(
        words: Word[],
        mediaUrlsWithIndex: MediaUrlWithIndex[],
        translationLanguageCode?: string
    ) {
        if (translationLanguageCode) {
            this.translation = {
                transcription: new Transcription().setTranslation(!!translationLanguageCode),
                language: translationLanguageCode
            };
            this.translation.transcription.words = words;
        } else {
            if (!this.transcription) this.transcription = new Transcription();
            this.transcription.words = words;
        }
        const currentTranscription = translationLanguageCode ? this.translation.transcription : this.transcription;
        if (!currentTranscription?.mediaUrlsWithIndex?.length) {
            currentTranscription.mediaUrlsWithIndex = mediaUrlsWithIndex;
        } else {
            currentTranscription.mediaUrlsWithIndex.concat(
                mediaUrlsWithIndex.filter(
                    (mediaUrlWithIndex) => !currentTranscription.mediaUrlsWithIndex.includes(mediaUrlWithIndex)
                )
            );
        }
    }

    private setMainVideoEditorConfig(project: FbProject) {
        if (project.mainVideoEditorConfig) {
            this.mainVideoEditorConfig = project.mainVideoEditorConfig;
            if (!this.mainVideoEditorConfig?.canvasElements.subtitle) {
                this.mainVideoEditorConfig.canvasElements.subtitle = {
                    ID: 'subtitle',
                    konvaElement: null,
                    subtitleMode: project.subtitleMode || SubtitleMode.disabled,
                    subtitleLength: project.fixedSubtitleLength || null
                };
            }
        } else {
            // legacy project - convert old structure to mainVideoEditorConfig

            this.mainVideoEditorConfig = {
                autoEditingConfig: {},
                canvasElements: {
                    text: [],
                    image: [],
                    shape: [],
                    media: [],
                    progress: [],
                    subtitle: {
                        ID: 'subtitle',
                        konvaElement: null,
                        subtitleLength: project.fixedSubtitleLength || null,
                        subtitleMode: project.subtitleMode || SubtitleMode.disabled
                    }
                },

                resolution: project.videoResolution || project.projectResolution || null,
                backgroundColor: project.backgroundColor || '#ffffff'
            };

            if ((project as FbProject).canvasElements) {
                this.mainVideoEditorConfig.canvasElements = project.canvasElements;
                if (!this.mainVideoEditorConfig.canvasElements.text) {
                    this.mainVideoEditorConfig.canvasElements.text = [];
                }
                if (!this.mainVideoEditorConfig.canvasElements.image) {
                    this.mainVideoEditorConfig.canvasElements.image = [];
                }
                if (!this.mainVideoEditorConfig.canvasElements.shape) {
                    this.mainVideoEditorConfig.canvasElements.shape = [];
                }
                if (!this.mainVideoEditorConfig.canvasElements.progress) {
                    this.mainVideoEditorConfig.canvasElements.progress = [];
                }
                if (!this.mainVideoEditorConfig.canvasElements.media) {
                    this.mainVideoEditorConfig.canvasElements.media = [];
                }
            } else {
                this.mainVideoEditorConfig.canvasElements = {
                    text: [],
                    image: [],
                    shape: [],
                    media: [],
                    progress: [],
                    subtitle: null
                };
            }
        }
    }

    private setSpeakersFromProject(project: Project | FbProject) {
        const speakerMap = (project as FbProject)?.speakerMap;
        if (speakerMap) {
            const enrichedDefaultSpeakers = cloneDeep(defaultSpeakers);
            //Enrich defaultSpeakers array with speakers from firestore
            Object.keys(speakerMap).map(function (key) {
                if (+key > 0) {
                    enrichedDefaultSpeakers[+key - 1].name = speakerMap[+key].name;
                }
            });
            this.speakers = Object.keys(enrichedDefaultSpeakers).map((key) => ({
                index: +key + 1,
                name: enrichedDefaultSpeakers[key].name,
                color: enrichedDefaultSpeakers[key].color,
                editable: enrichedDefaultSpeakers[key].editable
            }));
        } else {
            //defaultSpeakers
            this.speakers = cloneDeep(defaultSpeakers);
        }
    }

    public setTranscriptionMap(transcriptionMap: TranscriptionMapElement[], isTranslation: boolean) {
        if (isTranslation) {
            this.translationMap = transcriptionMap;
        } else {
            this.transcriptionMap = transcriptionMap;
        }
    }

    // Legacy support
    public setTranscriptionFromProject(project: Project | FbProject) {
        if (project.transcription) {
            this.transcription = new Transcription().fromFirebase(project.transcription);
            //.setVideoUri(project.mediaUri);
        } else {
            this.transcription = null;
        }
    }
    public setTranslationFromProject(project: Project | FbProject) {
        if (project?.translation?.transcription) {
            this.translation = {
                transcription: new Transcription().fromFirebase(project.translation.transcription),
                // .setVideoUri(project.mediaUri),
                language: project.translation.language
            };
        } else {
            this.translation = null;
        }
    }

    setMediaCanvasElementsFromSingleVideoProject(project: FbProject) {
        this.mainVideoEditorConfig.canvasElements.media = [];
        this.mainVideoEditorConfig.canvasElements.media.push({
            ID: 'single',
            startWord: -1,
            endWord: -1,
            mediaDuration: project.mediaDuration || 0,
            mediaIndex: 0,
            mediaType: project.mediaType || null,
            mediaUri: project.mediaUri || project.videoUri || null,
            mediaUrl: project.mediaUrl || project.videoUrl || null,
            resolution: project.videoResolution || null,
            zIndex: project.videoZIndex || 0,
            previewUrl: project.previewUrl || null,
            isMultilane: false,
            volume: 1,
            konvaElement: `{
            "attrs": {
                "id": "single",
                "draggable": true,
                "x":${project.videoPositionX || 0},
                "y":${project.videoPositionY || 0},
                "rotation":${project.rotation || 0},
                "scaleX":${project.videoScale || 1},
                "scaleY":${project.videoScale || 1},
                "width":${project.videoResolution?.width ?? project.transformedVideoResolution?.width},
                "height":${project.videoResolution?.height ?? project.transformedVideoResolution?.height}
            },
            "className": "Image"
        }`
        });
    }
    ///////////
    toFirebase(): FbProject {
        const project: FbProject = {
            id: this.id || null,
            userId: this.userId || null,
            title: this.title || null,
            transcriptionMap: this.transcriptionMap || null,
            translationMap: this.translationMap || null,
            members: this.members || null,
            owner: this.owner || null,
            thumbnailUrl: this.thumbnailUrl || null,
            showSubtitleBreaks: this.showSubtitleBreaks || false,
            subtitleOptionsExpanded: this.subtitleOptionsExpanded || false,
            language: this.language || null,
            createdAt: this.createdAt || null,
            lastUsedAt: this.lastUsedAt || null,
            renderingId: this.renderingId || null,
            renderingState: this.renderingState || null,
            renderingUri: this.renderingUri || null,
            clipConfigs: this.clipConfigs || null,
            clipCreationCount: this.clipCreationCount || null,
            autoExtractHighlightsState: this.autoExtractHighlightsState || null,
            lastOpenSideMenuItem: this.lastOpenSideMenuItem || null,
            visibility: this.visibility || Visibility.PRIVATE || null,
            sharingTitle: this.sharingTitle || null,
            templateId: this.templateId || null,
            transcriptionState: this.transcriptionState,
            transcriptionStartTime: this.transcriptionStartTime || null,
            translationLanguage: this.translationLanguage || this.translation?.language || null,
            voiceTranslationState: this.voiceTranslationState || null,
            currentVoiceTranslationReferences: this.voiceTranslationIds || null,
            voiceTranslationMode: this.voiceTranslationMode || VoiceTranslationMode.NONE || null,
            mainVideoEditorConfig: this.mainVideoEditorConfig || null
        };
        if (this.forceStreaming) {
            project.forceStreaming = this.forceStreaming;
        }
        if (this.retainedMediaDetails) {
            project.retainedMediaDetails = this.retainedMediaDetails;
        }
        return project;
    }
}

export class ProjectMemberInviteModel {
    email: string;
    projectId: string;
}

export class ProjectFromSelectionRequestModel {
    startEndConfigs: StartEndConfig[];
    title: string;
    projectId: string;
}

export class ProjectFromSelectionResponseModel {
    projectId: string;
}
export class DeleteProjectsRequest {
    userId: string;
    projectIds: string[];
}
