export const devEnvironment = {
    firebase: {
        apiKey: 'AIzaSyCi4vJuZqsx-v0pCvywLYwDw0utk4qb-LI',
        authDomain: 'type-mvp-development.firebaseapp.com',
        databaseURL: 'https://type-mvp-development.firebaseio.com',
        projectId: 'type-mvp-development',
        storageBucket: 'type-mvp-development.appspot.com',
        messagingSenderId: '398750546331',
        appId: '1:398750546331:web:e30d515785a9f84c0e493e'
    }
};

export const prodEnvironment = {
    firebase: {
        apiKey: 'AIzaSyBIuGvvV-bGmlehshHWH0gR5njRWt0nSvs',
        authDomain: 'type-mvp.firebaseapp.com',
        databaseURL: 'https://type-mvp.firebaseio.com',
        projectId: 'type-mvp',
        storageBucket: 'type-mvp.appspot.com',
        messagingSenderId: '666570497602',
        appId: '1:666570497602:web:dca66c9dadecee56ef17db'
    }
};
export const prodTypeInsightsEnvironment = {
    firebase: {
        apiKey: 'AIzaSyBnA9VnivlWjLywN8P2PKIMHs-otlN3eAE',
        authDomain: 'type-mvp.firebaseapp.com',
        databaseURL: 'https://type-mvp.firebaseio.com',
        projectId: 'type-mvp',
        storageBucket: 'type-mvp.appspot.com',
        messagingSenderId: '666570497602',
        appId: '1:666570497602:web:dca66c9dadecee56ef17db'
    }
};

export const devFirebaseFunctions = {
    /**
     * Payment
     */
    // createCustomer: 'https://us-central1-type-mvp-development.cloudfunctions.net/createCustomer',
    createPayment23hkkh1v23hkv1khb:
        'https://us-central1-type-mvp-development.cloudfunctions.net/createPayment23hkkh1v23hkv1khb',
    upgradeSubscription: 'https://us-central1-type-mvp-development.cloudfunctions.net/upgradeSubscription',
    validatePromotionCode: 'https://us-central1-type-mvp-development.cloudfunctions.net/validatePromotionCode'
};

export const localhostFirebaseFunctions = {
    /**
     * Payment
     */
    // createCustomer: 'http://localhost:5001/type-mvp-development/us-central1/createCustomer',
    createPayment23hkkh1v23hkv1khb:
        'http://localhost:5001/type-mvp-development/us-central1/createPayment23hkkh1v23hkv1khb',
    upgradeSubscription: 'http://localhost:5001/type-mvp-development/us-central1/upgradeSubscription',
    validatePromotionCode: 'http://localhost:5001/type-mvp-development/us-central1/validatePromotionCode'
};

export const prodFirebaseFunctions = {
    /**
     * Payment
     */
    // createCustomer: 'https://us-central1-type-mvp.cloudfunctions.net/createCustomer',
    createPayment23hkkh1v23hkv1khb: 'https://us-central1-type-mvp.cloudfunctions.net/createPayment23hkkh1v23hkv1khb',
    upgradeSubscription: 'https://us-central1-type-mvp.cloudfunctions.net/upgradeSubscription',
    validatePromotionCode: 'https://us-central1-type-mvp.cloudfunctions.net/validatePromotionCode'
};
