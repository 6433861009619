import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
    template: `
        <h2>What would you like us to call you?</h2>
        <div class="user-name-dialog-container">
            <app-input-field [control]="nameControl" placeholder="Name, please..."></app-input-field>
            <app-button
                class="user-name-dialog__save-btn"
                color="primary"
                [disabled]="nameControl.invalid"
                [appDialogClose]="nameControl.value"
            >
                Save
            </app-button>
        </div>
    `,
    styles: [
        `
            .user-name-dialog-container {
                min-width: 20rem;
                display: grid;
                grid-gap: 1rem;
            }

            .user-name-dialog__save-btn {
                justify-self: center;
            }
        `
    ]
})
export class UserNameDialogComponent {
    nameControl = new FormControl('', [Validators.required]);
}
