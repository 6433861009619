import { sortByProperty, sortFn } from '@type/shared/utils/array';
import { PathsType } from '../consts/firebase-paths';
import { MemberModel } from '@type/shared-models/member.models';

export class OrganizationMemberInviteModel {
    email: string;
    organizationId: string;
}

export class OrganizationMemberRemoveModel {
    memberUserId: string;
    organizationId: string;
}

// TODO: Consider renaming to parent
export class OwnerModel {
    name?: string;
    firebaseId?: string;
    resourceType?: PathsType;
}

export class OrganizationModel {
    firebaseId?: string;
    members: MemberModel[];
    name: string;
}

export class OrganizationFirebaseModel {
    firebaseId?: string;
    members: {
        [uid: string]: MemberModel;
    };
    name: string;

    static fromFirebase(organization: OrganizationFirebaseModel): OrganizationModel {
        return {
            name: organization.name,
            firebaseId: organization.firebaseId,
            members: Object.keys(organization.members)
                .map((userId) => ({
                    ...organization.members[userId],
                    userId
                }))
                .sort((val1, val2) =>
                    sortFn(val1.name ? val1.name : val1.email, val2.name ? val2.name : val2.email, true)
                )
                .sort(sortByProperty('role'))
                .reverse()
        };
    }
}
