import { Injectable } from '@angular/core';
import { AnalyticsEvents } from '@type/shared-models/consts/analytic-events';
import { Command } from '../commands/command';
import { AnalyticsService } from './analytics.service';

@Injectable({
    providedIn: 'root'
})
export class CommandService {
    // Stack of Commands implementing the Command Class
    readonly undoCommandStack: Command[] = [];
    readonly redoCommandStack: Command[] = [];

    constructor(private analyticsService: AnalyticsService) {}
    purgeCommands() {
        this.undoCommandStack.length = 0;
        this.redoCommandStack.length = 0;
    }

    addCommand(command: Command) {
        this.undoCommandStack.push(command.execute());
        this.redoCommandStack.length = 0;
    }

    undo(projectId: string) {
        if (this.undoCommandStack.length > 0) {
            this.analyticsService.track(AnalyticsEvents.UNDO_CLICKED, { projectId });
            const lastCommand = this.undoCommandStack.pop();
            lastCommand.undo();
            this.redoCommandStack.push(lastCommand);
        }
    }

    redo(projectId: string) {
        if (this.redoCommandStack.length > 0) {
            this.analyticsService.track(AnalyticsEvents.REDO_CLICKED, { projectId });
            const lastCommand = this.redoCommandStack.pop().execute();
            this.undoCommandStack.push(lastCommand);
        }
    }
}
