import { Directive, HostListener, Input } from '@angular/core';
import { PlanType } from '@type/shared-models/payment/products';
import { MobileUpgradeComponent } from '@type-mvp/app/mobile-upgrade-dialog/mobile-upgrade.component';
import { DialogService } from '@type/dialog';
@Directive({
    selector: `[appMobileUpgrade]`
})
export class MobileUpgradeDialogDirective {
    @Input() planType: PlanType = PlanType.PRO;
    @Input() paymentReason: string;
    constructor(private dialogService: DialogService) {}

    @HostListener('btnClick')
    openDialog() {
        this.dialogService.openFullPageDialog(MobileUpgradeComponent, {
            data: {
                planType: this.planType,
                paymentReason: this.paymentReason
            }
        });
    }
}
