export function camelize(str: string): string {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '');
}

export function isEmpty(value: any) {
    return value === null || value === undefined || value === '';
}

// Based on this: https://github.com/tjcafferkey/stringinject
export default function stringInject(str, data) {
    if (typeof str === 'string' && data instanceof Array) {
        return str.replace(/({\d})/g, function (i) {
            return data[i.replace(/{/, '').replace(/}/, '')];
        });
    } else if (typeof str === 'string' && data instanceof Object) {
        if (Object.keys(data).length === 0) {
            return str;
        }

        for (let key in data) {
            return str.replace(/({([^}]+)})/g, function (i) {
                let key = i.replace(/{/, '').replace(/}/, '');
                if (!data[key]) {
                    return i;
                }
                return data[key];
            });
        }
    }
    return str;
}
