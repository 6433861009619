import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@type/dialog';
import { ButtonModule } from '@type/ui/button';
import { FailedSubscriptionComponent } from './failed-subscription.component';
import { RetrySubscriptionComponent } from './retry-subscription.component';
import { UpgradeRequiredModule } from '../../shared/only-for-pro/upgrade-required.module';

const exportItems = [RetrySubscriptionComponent, FailedSubscriptionComponent];

@NgModule({
    declarations: [...exportItems],
    exports: [...exportItems],
    imports: [CommonModule, DialogModule, ButtonModule, UpgradeRequiredModule]
})
export class SubscriptionDialogsModule {}
