import { AfterViewInit, Component, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import routes from '@type/shared-models/consts/routes';
import { PaymentDialogService } from '@type/payment-dialog/payment-dialog.service';
import { signupMetaTags } from './meta-tag.definitions';
import { AuthService } from '../../../services/auth.service';
import { CanonicalService } from '../../../services/canonical.service';
import { UserService } from '../../../services/user.service';
import { take } from 'rxjs';
import { DialogRef } from '@type/dialog';
import { getErrorMessage } from '@type/shared-models/auth/error.models';
import { ToastService } from '../../../toast/toast.service';

export interface SignUpDialogConfig {
    hideLogin: boolean;
    fromAnonymous: boolean;
    openPayment: boolean;
    dialogReference?: DialogRef;
    headline?: string;
    subHeadline?: string;
    onSuccess: () => void;
}
@Component({
    templateUrl: './signup.component.html',
    styleUrls: ['../auth-core.scss']
})
export class SignupComponent implements AfterViewInit {
    isSigningUp = { email: false, google: false, apple: false, facebook: false, slid: false };
    signUpError = null;
    providerAuthError = null;
    nameCtrl = new FormControl('', [Validators.required]);
    emailCtrl = new FormControl('', [Validators.email, Validators.required]);
    passwordCtrl = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(255)]);

    signUpForm: FormGroup = new FormGroup({
        name: this.nameCtrl,
        email: this.emailCtrl,
        password: this.passwordCtrl
    });

    signupDialogConfig: SignUpDialogConfig;
    constructor(
        private auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private paymentDialogService: PaymentDialogService,
        private userService: UserService,
        private toastService: ToastService,
        meta: Meta,
        canonicalService: CanonicalService,
        @Optional() ref: DialogRef
    ) {
        // add meta tags
        signupMetaTags.forEach((tag) => meta.addTag(tag));
        window.analytics.page('SignUp');
        canonicalService.setCanonicalURL('https://podcasteditor.streamlabs.com/signup');
        this.signupDialogConfig = { ...ref?.config?.data, dialogReference: ref };
    }
    ngAfterViewInit(): void {
        this.notifySlidSignup();
    }

    notifySlidSignup() {
        if (this.route.snapshot.params.notifySlidSignup === 'true') {
            this.toastService.open('needsSlidSignup', 30000);
        }
    }
    // signUp(): void {
    //     if (this.signUpForm.invalid) {
    //         return;
    //     }
    //     const referrerParam =
    //         this.route.snapshot.params.referrer != null ? this.route.snapshot.params : this.route.snapshot.queryParams;

    //     console.log('SignupComponent -> ngOnInit -> params1', referrerParam.referrer);

    //     this.isSigningUp.email = true;
    //     this.signUpError = null;
    //     const { email, password, name } = this.signUpForm.value;
    //     this.auth.emailSignUp(email, password, name, referrerParam.referrer).subscribe(
    //         () => {
    //             setTimeout(() => {
    //                 this.userService.openUserConfirmationModalIfUnverified();
    //             });
    //         },
    //         (error) => {
    //             this.isSigningUp.email = false;
    //             this.signUpError = error;
    //         }
    //     );
    // }

    signInWithGoogle(): void {
        const referrerParam =
            this.route.snapshot.params.referrer != null ? this.route.snapshot.params : this.route.snapshot.queryParams;
        this.isSigningUp.google = true;
        this.providerAuthError = null;
        this.auth.googleSignIn(referrerParam).catch((error) => {
            this.isSigningUp.google = false;
            this.providerAuthError = error;
        });
    }

    signInWithFacebook() {
        const referrerParam =
            this.route.snapshot.params.referrer != null ? this.route.snapshot.params : this.route.snapshot.queryParams;
        this.isSigningUp.facebook = true;
        this.providerAuthError = null;
        this.auth.facebookSignIn(referrerParam).catch((error) => {
            this.isSigningUp.facebook = false;
            this.providerAuthError = error;
        });
    }

    signInWithApple() {
        const referrerParam =
            this.route.snapshot.params.referrer != null ? this.route.snapshot.params : this.route.snapshot.queryParams;
        this.isSigningUp.apple = true;
        this.providerAuthError = null;
        this.auth.appleSignIn(referrerParam).catch((error) => {
            this.isSigningUp.apple = false;
            this.providerAuthError = error;
        });
    }

    signInWithSlid() {
        this.isSigningUp.slid = true;
        this.providerAuthError = null;
        this.auth
            .slidSignIn(
                true,
                this.signupDialogConfig.fromAnonymous,
                this.signupDialogConfig.openPayment,
                this.signupDialogConfig.onSuccess
            )
            .pipe(take(1))
            .subscribe((error) => {
                this.isSigningUp.slid = false;
                if (error.error) {
                    this.providerAuthError = {
                        message: getErrorMessage(error.message) || 'Ooops! Something went wrong.'
                    };
                    return;
                }
                this.signupDialogConfig.dialogReference?.close();
                this.signupDialogConfig.fromAnonymous = false;
                this.signupDialogConfig.openPayment = false;
            });
    }
    async signInAnonymously() {
        this.providerAuthError = null;
        await this.auth.anonymousSignIn();
    }

    navigateLogin() {
        const utmParamQueryString = new URLSearchParams(window.location.search);
        this.router.navigateByUrl(`${routes.LOGIN}?${utmParamQueryString}`).then();
    }
}
