<app-dialog-header>
    <app-dialog-back-button *ngIf="selectedPlan" (click)="selectedPlan = null"></app-dialog-back-button>
    Upgrade
    <span *ngIf="selectedPlan"
        >to {{ selectedPlan === 'starterBasic' ? 'Starter' : selectedPlan === 'pro' ? 'Pro' : 'Team' }}</span
    >
    <app-dialog-close-button appDialogClose></app-dialog-close-button>
</app-dialog-header>

<div *ngIf="!selectedPlan" class="plan-options-wrapper">
    <div *ngFor="let plan of availablePlans" class="plan-option">
        <div class="flex justify-between">
            <span class="typ-h2 plan-label"> {{ plan.title }} </span>
            <span class="typ-h2 plan-price">
                <span>from</span> ${{ plan.yearlyPrice / 1200 | number: '1.0-2' }}/mo
            </span>
        </div>
        <div class="typ-color-gray800">
            <div class="typ-p plan-limits">{{ plan.primaryFeature }}</div>
            <div class="typ-p">{{ plan.description }}</div>
        </div>
        <app-button
            *ngIf="plan.planType !== 'ultra'; else ultra"
            class="justify-self-end"
            color="primary"
            (btnClick)="selectedPlan = plan.planType"
        >
            Upgrade to {{ plan.title }}
        </app-button>
        <ng-template #ultra>
            <type-slid-checkout class="justify-self-end" [checkoutAction]="'checkout'">
                Upgrade to {{ plan.title }}
            </type-slid-checkout>
        </ng-template>
    </div>
</div>

<div *ngIf="selectedPlan">
    <div class="plan-interval-selectors">
        <app-button
            color="stroked"
            [isActive]="selectedPlanInterval === planIntervalTypes.annually"
            (btnClick)="selectedPlanInterval = planIntervalTypes.annually"
        >
            <div class="plan-interval">
                <div class="plan-interval-details">
                    <div class="plan-interval-label">annually</div>
                    <div class="plan-interval-saving">save 20%</div>
                </div>
                <div class="plan-interval-price">
                    ${{ plansMap[selectedPlan].yearlyPrice / 1200 | number: '1.0-2' }}
                </div>
            </div>
        </app-button>
        <app-button
            color="stroked"
            [isActive]="selectedPlanInterval === planIntervalTypes.monthly"
            (btnClick)="selectedPlanInterval = planIntervalTypes.monthly"
        >
            <div class="plan-interval">
                <div class="plan-interval-details">
                    <div class="plan-interval-label">monthly</div>
                </div>
                <div class="plan-interval-price">
                    ${{ plansMap[selectedPlan].monthlyPrice / 100 | number: '1.0-2' }}
                </div>
            </div>
        </app-button>
    </div>
    <app-upgrade-credit-card-input
        [selectedPlanData]="plansMap[selectedPlan]"
        [selectedInterval]="selectedPlanInterval"
        [paymentReason]="paymentReason"
    ></app-upgrade-credit-card-input>
</div>

<type-loader *ngIf="upgradeService.loading" message="We are upgrading your account..."></type-loader>

<div class="payment-messages" *ngIf="paymentSuccessful">
    <h2>Success 🥳</h2>
    <p>Your plan has been updated.</p>
</div>
