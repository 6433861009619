import { Component, HostBinding, Input } from '@angular/core';
import { fadeInOutAnimationFactory } from '@type/shared/animations';

@Component({
    selector: 'type-tab',
    exportAs: 'appTab',
    template: `
        <div [@fadeIn]="active ? 'enter' : 'exit'" *ngIf="active" class="tab-pane">
            <ng-content></ng-content>
        </div>
    `,
    styles: [
        `
            .tab-pane {
                grid-column: 1 / 1;
                grid-row: 1 / 1;
            }
        `
    ],
    animations: [fadeInOutAnimationFactory({ duration: 200, enterState: 'enter', exitState: 'exit' })]
})
export class TabComponent {
    @HostBinding('style.display') gridArea = 'content';
    @Input() label: string;
    @Input() iconPath: string;
    @Input() iconOnly: boolean;
    @Input() active = false;
    @Input() caseValue: string;
}
