export const AnalyticsEvents = {
    SERVER_SIGNUP_COMPLETED: 'Server signup completed',
    SERVER_LEGACY_SIGNUP_COMPLETED: 'Server legacy signup completed',
    SERVER_ANONYMOUS_SIGNUP_COMPLETED: 'Server anonymous signup completed',

    // Upload

    MEDIA_UPLOAD_STARTED: 'Media upload started',
    MEDIA_UPLOAD_COMPLETED: 'Media upload completed',

    SERVER_TRANSCRIPTION_STARTED: 'Server transcription started',
    SERVER_TRANSCRIPTION_COMPLETED: 'Server transcription completed',
    SERVER_TRANSCRIPTION_FIRST_CHUNK_SAVED: 'Server transcription first chunk saved',
    SERVER_TRANSCRIPTION_SKIPPED: 'Server transcription skipped (project was deleted)',
    SERVER_LINK_UPLOAD_STARTED: 'Server link upload started',
    SERVER_LINK_UPLOAD_COMPLETED: 'Server link upload completed',
    SERVER_LINK_UPLOAD_FAILED: 'Server link upload failed',

    LINK_UPLOAD_NOT_SUPPORTED: 'Link upload not supported',

    // Project Overview
    SERVER_PROJECT_DUPLICATED: 'Project duplicated',
    SERVER_PROJECT_DELETED: 'Project deleted',
    SERVER_PROJECT_DELETED_RESOURCES: 'Project resources deleted',

    // Clips
    CLIPS_EDITOR_OPENED: 'Clips editor opened',
    SERVER_CLIP_CREATED: 'Clip created',
    SERVER_AUTO_EXTRACT_HIGHLIGHT_CLIPS: 'Auto extracted highlight clips',
    // Rendering
    SERVER_RENDERING_STARTED: 'Rendering started',
    SERVER_RENDERING_FAILED: 'Rendering failed',
    SERVER_RENDERING_FINISHED: 'Rendering finished',
    SERVER_RENDERING_CANCELED: 'Rendering canceled',
    SERVER_TEXT_EXPORTED: 'Text exported',

    // Sharing Page
    SERVER_DOWNLOAD_RENDERED_VIDEO: 'Download rendered video',

    // Translation
    SERVER_TRANSLATION_STARTED: 'Translation started',
    SERVER_VOICE_TRANSLATION_STARTED: 'Server voice translation started',

    //Collaboration
    PROJECT_INVITATION_SENT: 'Project invitation sent',
    PROJECT_INVITATION_ACCEPTED: 'Project invitation accepted',

    // Templates
    TEMPLATE_CREATED: 'Template created',
    TEMPLATE_DELETED: 'Template deleted',
    TEMPLATE_PREVIEW_APPLIED: 'Template preview applied',
    TEMPLATE_APPLIED: 'Template applied',

    LANGUAGE_REQUESTED: 'Language requested',

    //Social Export
    SOCIAL_PLATFORM_CONNECTED: 'Social platform connected',
    SOCIAL_EXPORT_PUBLISHED: 'Social export published',

    //Stripe serverside
    STRIPE_SUBSCRIPTION_CREATED: 'Stripe subscription created',
    STRIPE_SUBSCRIPTION_BILLED: 'Stripe subscription billed',
    STRIPE_SUBSCRIPTION_EXPIRED: 'Stripe subscription expired',
    STRIPE_SUBSCRIPTION_UPGRADE: 'Stripe subscription upgrade',
    STRIPE_SUBSCRIPTION_CANCELED: 'Stripe subscription canceled',
    STRIPE_PAYMENT_FAILED: 'Stripe payment failed',

    /**
     * Client side
     */
    // Payment
    PAYMENT_OPENED: 'Payment opened',
    PAYMENT_PLAN_SELECTED: 'Payment plan selected',
    PAYMENT_SUBMITTED: 'Payment submitted',
    PAYMENT_SUCCEEDED: 'Payment succeeded',
    PAYMENT_ERROR_OCCURRED: 'Payment error occurred',
    CUSTOMER_PORTAL_OPENED: 'Customer portal opened',

    // Editor
    WORDS_DELETED: 'Words deleted',
    WORD_EDITED: 'Word edited',
    PAUSES_DELETED: 'Pauses deleted',
    FILLER_WORDS_DELETED: 'Filler words deleted',
    SUBTITLE_MODE_CHANGED: 'Subtitle mode changed',
    UNDO_CLICKED: 'Undo clicked',
    REDO_CLICKED: 'Redo clicked',
    TRANSCRIPT_VIEWED: 'Transcript viewed',

    // WORD_SEARCHED: 'Word searched',
    // CANVAS_ELEMENT_ADDED: 'Canvas element added',
    FORMAT_CHANGED: 'Format changed',

    // Upload
    ERROR_INVALID_FILE_EXTENSION: 'Error - Invalid file extension',
    ERROR_INVALID_FILE_SIZE: 'Error - Invalid filesize',
    ERROR_INVALID_RESOLUTION: 'Error - Invalid Resolution',
    ERROR_RENDERING_FAILED: 'Error - Rendering failed',
    ERROR_UPLOADED_VIDEO_TOO_LONG: 'Error - Uploaded video to long',
    ERROR_USAGE_EXPIRED: 'Error - Usage limitation reached',

    //
    EXAMPLE_PROJECT_LOADED: 'Example project loaded',
    EXAMPLE_PROJECT_LOADED_FROM_UPLOAD: 'Example project loaded from upload',

    PAUSE_RENDERED_VIDEO: 'Pause rendered video clicked',
    PLAY_RENDERED_VIDEO: 'Play rendered video clicked',

    // Translation
    CLIENT_VOICE_TRANSLATION_STARTED: 'Client voice translation started',

    // Authentication
    SESSION_STARTED: 'Session started',
    SIGNIN_COMPLETE: 'SignIn completed',
    SIGNUP_COMPLETE: 'SignUp completed',
    SIGNOUT_COMPLETE: 'SignOut completed',
    DELETE_ACCOUNT: 'User account deleted',

    // Communication
    START_LIVECHAT: 'Livechat started',
    SURVEY_SUBMITTED: 'Survey submitted ',
    SEND_MAIL: 'Contact send mail',
    FEATURE_PROPOSAL_SEND: 'Feature proposal send',
    FEEDBACK_SEND: 'Feedback send'
};
