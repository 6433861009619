import { Component } from '@angular/core';
import { PlanDetails, PlanInterval, PlanType } from '@type/shared-models/payment/products';
import { Subscription } from 'rxjs';
import { UpgradeService } from '../../services/payment-dialog-upgrade.service';
import { UserService } from '../../services/payment-dialog-user.service';

export interface IntervalButton {
    planType: PlanType;
    interval: PlanInterval;
    selected?: boolean;
    title: string;
    titleDescription: string;
    monthlyPrice: number;
    totalPrice?: number;
}

@Component({
    selector: 'type-upgrade-interval-selector',
    templateUrl: './upgrade-interval-selector.component.html',
    styleUrls: ['./upgrade-interval-selector.component.scss']
})
export class UpgradeIntervalSelectorComponent {
    intervalButtons: IntervalButton[];
    intervalSubscription: Subscription;

    selectedInterval$ = this.upgradeService.planInterval$;
    constructor(public upgradeService: UpgradeService, public userService: UserService) {
        this.subscribeInterval();
    }

    intervalButtonClicked(intervalButton: IntervalButton) {
        this.deselectAllButtons();
        this.upgradeService.planInterval = intervalButton.interval;
    }

    deselectAllButtons() {
        this.intervalButtons.forEach((button) => {
            button.selected = false;
        });
    }

    private subscribeInterval() {
        this.intervalSubscription = this.upgradeService.planDetails$.subscribe((planDetails) => {
            this.updateIntervalButtons(planDetails);
        });
    }
    private updateIntervalButtons(planDetails: PlanDetails) {
        const planIntervals = [PlanInterval.monthly, PlanInterval.annually];
        this.intervalButtons = [];

        if (planDetails) {
            const monthlyPrice = this.upgradeService.planTable[planDetails.planType][PlanInterval.monthly].price / 100;
            const annualPrice =
                this.upgradeService.planTable[planDetails.planType][PlanInterval.annually].price / 100 / 12;
            const annuallyDiscount = ((monthlyPrice - annualPrice) / monthlyPrice) * 100;

            planIntervals.forEach((interval) => {
                const totalPrice = this.upgradeService.planTable[planDetails.planType][interval].price / 100;
                this.intervalButtons.push({
                    interval,
                    planType: this.upgradeService.planType,
                    selected: planDetails.planInterval === interval,
                    title: interval === PlanInterval.monthly ? 'Monthly' : 'Yearly',
                    titleDescription:
                        interval === PlanInterval.monthly
                            ? ''
                            : 'save ' + annuallyDiscount.toString().split('.', 1)[0] + '%',
                    monthlyPrice: interval === PlanInterval.monthly ? totalPrice : totalPrice / 12,
                    totalPrice
                });
            });
        }
    }
}
