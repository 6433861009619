import { Scale } from './media/media.models';
import { toFixedTrunc } from './shared-utils/time-utils';

export interface Resolution {
    width: number;
    height: number;
}
export class Resolution {
    static toFirebase(resolution: Resolution): Resolution {
        if (!resolution) return null;

        return { width: resolution.width, height: resolution.height };
    }
    static getAspectRatio(resolution: Resolution): number {
        const aspectRatio = resolution.width / resolution.height;
        return aspectRatio;
    }

    static getScaleFactor(resolutionA: Resolution, resolutionB: Resolution): Scale {
        const widerSideA = Math.max(resolutionA.width, resolutionA.height);
        const widerSideB = Math.max(resolutionB.width, resolutionB.height);
        const scaleFactor = widerSideA / widerSideB;

        return { x: scaleFactor, y: scaleFactor };
    }
}
