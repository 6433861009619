import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
    enableProdMode();
    // Overwrites console logs in production build
    if (window) {
        window.console.log = () => null;
    }
}
// if (isMobile) {
//     platformBrowserDynamic()
//         .bootstrapModule(MobileModule)
//         .catch((err) => console.error(err));
// } else {
platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
// }
