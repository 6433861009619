import { Component, OnDestroy } from '@angular/core';
import { UpgradeService } from '@type-mvp/app/mobile-upgrade-dialog/upgrade.service';
import { PlanElement, PlanInterval, PlanIntervalType, PlanType } from '@type/shared-models/payment/products';
import { Subscription } from 'rxjs';
import { DialogRef } from '@type/dialog';
import { PaymentService } from '@type-mvp/app/services/payment.service';

@Component({
    selector: 'app-upgrade',
    templateUrl: './mobile-upgrade.component.html',
    styleUrls: ['./mobile-upgrade.component.scss'],
    providers: []
})
export class MobileUpgradeComponent implements OnDestroy {
    planIntervalTypes = PlanInterval;
    selectedPlan: string;
    selectedPlanInterval: PlanInterval = PlanInterval.monthly;

    proPlan: PlanElement = {
        planType: PlanType.PRO,
        // icon: '\ud83c\udfe0',
        title: 'Pro',
        primaryFeature: '',
        description: 'All the Podcast Editor features for editing your content and creating clips',
        monthlyPrice: this.upgradeService.planTable[PlanType.PRO].monthly.price,
        yearlyPrice: this.upgradeService.planTable[PlanType.PRO].annually.price
    };

    ultraPlan: PlanElement = {
        planType: PlanType.ULTRA,
        // icon: '🏬',
        title: 'Ultra',
        primaryFeature: '',
        description: 'All the podcasting tools you need. Recording, streaming, editing and more',
        monthlyPrice: this.upgradeService.planTable[PlanType.ULTRA].monthly.price,
        yearlyPrice: this.upgradeService.planTable[PlanType.ULTRA].annually.price
    };

    availablePlans: PlanElement[] = [this.proPlan, this.ultraPlan];
    plansMap = {
        pro: this.proPlan,
        ultra: this.ultraPlan
    };
    paymentReason: any;
    private paymentSuccessSub: Subscription;
    paymentSuccessful: boolean;

    constructor(public upgradeService: UpgradeService, public paymentService: PaymentService, private ref: DialogRef) {
        this.upgradeService.paymentSuccess$.subscribe((val) => {
            if (!val) {
                return;
            }
            this.paymentSuccessful = true;
            setTimeout(() => {
                ref.close();
            }, 2500);
        });
    }

    ngOnDestroy() {
        this.paymentSuccessSub?.unsubscribe();
    }
}
