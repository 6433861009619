import { animate, state, style, transition, trigger } from '@angular/animations';

export const slideInAnimationX = [
    trigger('slideIn', [
        state('*', style({ 'overflow-x': 'hidden' })),
        state('void', style({ 'overflow-x': 'hidden' })),
        transition('* => void', [style({ width: '*' }), animate(250, style({ width: 0 }))]),
        transition('void => *', [style({ width: '0' }), animate(250, style({ width: '*' }))])
    ])
];

export const slideInAnimationY = [
    trigger('slideIn', [
        state('*', style({ 'overflow-y': 'hidden' })),
        state('void', style({ 'overflow-y': 'hidden' })),
        transition('* => void', [style({ height: '*' }), animate(250, style({ height: 0 }))]),
        transition('void => *', [style({ height: '0' }), animate(250, style({ height: '*' }))])
    ])
];
