import { NgModule } from '@angular/core';
import { UiModule } from '@type/ui';
import { LanguageSelectionComponent } from './language-selection.component';
import { DialogModule } from '@type/dialog';
import { AngularSvgIconModule } from 'angular-svg-icon';

const exportComponents = [LanguageSelectionComponent];

@NgModule({
    declarations: [...exportComponents],
    exports: [...exportComponents],
    imports: [UiModule, DialogModule, AngularSvgIconModule]
})
export class LanguageSelectionModule {}
