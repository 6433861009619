/* eslint-disable @typescript-eslint/naming-convention */
import { Component, isDevMode, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { EmbedService } from './services/embed.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { isGoogleChromeBrowser } from '@type/shared/utils/browser';
import { setCookie } from '@type/shared/utils/cookies';
import { DialogService } from '@type/dialog';
import { User } from '@type/shared-models/user/user';
import { environment } from '../environments/environment';
import { UserService } from './services/user.service';
import { FailedSubscriptionComponent } from './partials/subscription-dialogs/failed-subscription.component';
import { RetrySubscriptionComponent } from './partials/subscription-dialogs/retry-subscription.component';

@Component({
    selector: 'app-root',
    template: `
        <div [class.is-safari]="(showBanner && !isSharePage) || isMobile">
            <div class="fixed bottom-5 right-5 z-10"><app-help-menu></app-help-menu></div>
            <div
                class=" browser-banner h-12 bg-r-black flex items-center justify-center text-fullWhite typ-p"
                *ngIf="!isMobile"
            >
                Streamlabs Podcast Editor works best in Google Chrome. To avoid editing problems, please switch your
                browser.

                <app-button (btnClick)="openChrome()" size="sm" color="stroked">
                    <span class="px-2 switch-to-chrome-text">Switch to Google Chrome</span>
                </app-button>
            </div>

            <div
                (click)="openMobileDialog(templateRef)"
                class="h-14 bg-r-black flex items-center justify-between text-fullWhite typ-p pl-4 pr-2 "
                *ngIf="isMobile"
            >
                To access the full editor, switch to a <br />
                desktop device.

                <app-button size="lg">
                    <svg-icon appIcon src="assets/ic/16/linebreak-menu.svg"></svg-icon>
                </app-button>
            </div>
            <div class="relative app-wrapper">
                <app-navbar></app-navbar>
                <router-outlet></router-outlet>
            </div>
        </div>

        <ng-template #templateRef let-ref="ref">
            <app-dialog-header>
                Podcast Editor Mobile
                <app-dialog-close-button (click)="ref.close()"></app-dialog-close-button>
            </app-dialog-header>
            <p class="typ-2">
                This is a lite version of Streamlabs Podcast Editor that you can use on your phone. To use all the
                Podcast Editor features please switch to desktop.
            </p>
            <div class="w-full flex justify-start mt-4">
                <app-button (click)="ref.close()" appOpenOnDesktop="'projects'" [size]="'md'" color="secondary"
                    >Open on desktop</app-button
                >
            </div>
        </ng-template>
    `,
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    showBanner: boolean;
    isSharePage: boolean;
    isMobile: boolean;
    constructor(
        private router: Router,
        private titleService: Title,
        private route: ActivatedRoute,
        public embedService: EmbedService,
        private readonly afStorage: AngularFireStorage,
        private userService: UserService,
        private dialogService: DialogService
    ) {
        this.userService.user$
            .pipe(
                filter((val) => !!val),
                take(1)
            )
            .subscribe((user) => {
                if (!this.userService.getUserUiState('app', 'subscriptionWarningDisplayed')) {
                    this.checkSubscriptionStatus(user);
                }

                this.setCookies();
            });
        const isChrome = isGoogleChromeBrowser();
        this.showBanner = !isChrome && document.documentElement.clientWidth >= 640;
        (window as any).isChrome = isChrome;
        (window as any).isMac = navigator.platform.toUpperCase().indexOf('MAC') > -1;
        this.isMobile = document.documentElement.clientWidth < 440; // 440 instead of 640. Otherwise the banner would be shown
    }

    openMobileDialog(templateRef) {
        this.dialogService.openDialog(templateRef);
    }

    ngOnInit() {
        const splashScreen: HTMLElement = document.getElementById('splash-screen');
        if (splashScreen) {
            splashScreen.remove();
        }

        this.initEmbed().then();

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            const rt = this.getChild(this.route);
            this.isSharePage = event.url.includes('share');
            rt.data.subscribe((data) => {
                this.titleService.setTitle(
                    isDevMode() ? 'Streamlabs Podcast Editor [Staging]' : data.title || 'Streamlabs Podcast Editor'
                );
            });
        });
    }

    getChild(activatedRoute: ActivatedRoute) {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        } else {
            return activatedRoute;
        }
    }

    async initEmbed() {
        await this.embedService.initFromParameter(this.route.queryParams);
    }

    openChrome() {
        window.open('https://www.google.com/chrome/', '_blank');
    }

    private setCookies() {
        const expiryInSeconds = 365 * 24 * 60 * 60;
        // setCookie('isTypeUser', 'true', {
        //     domain: isDevMode() ? 'localhost' : '.podcasteditor.streamlabs.com',
        //     ['max-age']: expiryInSeconds
        // });
    }

    private checkSubscriptionStatus(user: User) {
        if (user.subscriptionState.status === 'failed') {
            this.dialogService.openDialog(FailedSubscriptionComponent, { maxWidth: 450 });
        } else if (user.subscriptionState.status === 'retry') {
            this.dialogService.openDialog(RetrySubscriptionComponent, { maxWidth: 450 });
        }
        this.userService.updateUserUiState(true, 'app', 'subscriptionWarningDisplayed');
    }
}
