import { Component } from '@angular/core';

@Component({
    selector: 'app-calendly',
    templateUrl: './calendly.component.html',
    styleUrls: ['./calendly.component.scss']
})
export class CalendlyComponent {
    loaded;
    constructor() {
        this.loadScript();
    }

    loadScript() {
        let isFound = false;
        const scripts = document.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('loader')) {
                isFound = true;
            }
        }

        if (!isFound) {
            const dynamicScripts = ['https://assets.calendly.com/assets/external/widget.js'];

            for (let i = 0; i < dynamicScripts.length; i++) {
                const node = document.createElement('script');
                node.src = dynamicScripts[i];
                node.type = 'text/javascript';
                node.async = false;
                node.charset = 'utf-8';
                node.onload = () => {
                    this.loaded = true;
                };
                document.getElementsByTagName('head')[0].appendChild(node);
            }
        }
    }
}
