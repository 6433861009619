import { Component, OnDestroy } from '@angular/core';
import { UpgradeService } from '../services/payment-dialog-upgrade.service';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DialogRef, DialogService } from '@type/dialog';
import { map, filter, switchMap } from 'rxjs/operators';
import { PLAN_LEVELS } from '@type/shared-models/payment/products';
import { UserService } from '@type/payment-dialog/services/payment-dialog-user.service';
import Routes from '@type/shared-models/consts/routes';
import { UpgradeSubscriptionConfirmation } from './upgrade-credit-card-input/upgrade-subscription-confirmation.component';

@Component({
    selector: 'type-upgrade',
    templateUrl: './upgrade.component.html',
    styleUrls: ['./upgrade.component.scss']
})
export class UpgradeComponent implements OnDestroy {
    emailCtrl = new FormControl(null, [Validators.email]);
    sub: Subscription;
    pricingUrl = 'https://podcasteditor.streamlabs.com' + Routes.PRICING;
    showEmail: boolean;
    paymentReason: string;
    currentPlanName$ = this.userService.user$.pipe(
        map((user) => PLAN_LEVELS[user?.subscriptionState?.plan?.type]?.label)
    );

    planPrice$ = this.upgradeService.planDetails$.pipe(map((plan) => plan?.price));
    currentPlan =
        this.upgradeService.planTable[this.userService.user?.subscriptionState?.plan?.type]?.[
            this.upgradeService.planDetails$.value?.planInterval
        ] || this.upgradeService.planTable?.trial?.monthly;
    selectedInterval$ = this.upgradeService.planInterval$;
    nextPaymentDate = this.upgradeService.nextPaymentDate;
    constructor(public upgradeService: UpgradeService, private ref: DialogRef, private userService: UserService) {
        this.upgradeService.planType = this.ref.config?.data?.planType;
        this.showEmail = this.ref.config?.data?.showEmail;
        this.paymentReason = this.ref.config?.data?.paymentReason;
        this.sub = upgradeService.modalClosed$.subscribe(() => this.closeModal());
        upgradeService.emailAlreadyInUse = false;
        upgradeService.successMessage = null;
    }

    ngOnDestroy() {
        this.sub?.unsubscribe();
    }

    openPlanSelection() {
        this.upgradeService.planType = null;
    }

    closeModal() {
        this.ref.close();
    }
}
