import { MediaType } from './media/media.utils';
import { ShapeStyles } from './shapes/shape-styles';
import { KonvaAnimationDirection, KonvaAnimationType } from './editor/konva-animation';
import { SubtitleElement } from './models/canvas/subtitle-element';
import { SubtitleConfig } from './subtitle-mode';

export const interpolateSvgProperties = (
    svgString: string,
    properties: {
        strokeColor?: string;
        strokeOpacity?: number;
        fillColor?: string;
        fillOpacity?: number;
    }
) => {
    for (let modifier of Object.keys(properties)) {
        if (typeof modifier === 'number') {
            modifier = modifier + '';
        }
        const replaceRegex = new RegExp(`{{${modifier}}}`, 'g');
        svgString = svgString.replace(replaceRegex, properties[modifier]);
    }
    return svgString;
};

//TODO: refactoring remove
export enum SvgShape {
    CIRCLE = 'circle',
    ARROW_DOWN = 'arrowDown'
}

export interface BaseCanvasElement {
    ID: string;
    startWord: number;
    endWord: number;
    konvaElement: string;
    zIndex: number;
    templateId?: string;
    encloseTranscript?: boolean;
}

export interface FrontendTextCanvasElement extends TextCanvasElement {
    bg: boolean;
    bgColor: string;
    animationDirection?: number;
    animationTypes?: number[];
}

export interface TextCanvasElement extends BaseCanvasElement {
    bg?: boolean;
    bgColor?: string;
    animationDirection?: number;
    animationTypes?: number[];
}
export interface SubtitleCanvasElement extends SubtitleConfig {
    ID?: string;
    konvaElement?: string;
    templateId?: string;
}

export interface ImageCanvasElement extends BaseCanvasElement {
    imageURL: string;
    imageURI: string;
    animationDirection?: number;
    animationTypes?: number[];
}

export interface ShapeCanvasElement extends BaseCanvasElement {
    strokeColor: string;
    fillColor: string;
    shape: SvgShape;
    style: ShapeStyles;
    animationDirection?: number;
    animationTypes?: number[];
}

export type ProgressElementStyle = 'default';
export interface ProgressCanvasElement extends BaseCanvasElement {
    foregroundColor: string;
    backgroundColor: string;
    style: ProgressElementStyle;
}
export interface MediaCanvasElement extends BaseCanvasElement {
    mediaUrl: string;
    mediaUri: string;
    mediaType: MediaType;
    mediaDuration: number;
    resolution: { width: number; height: number };
    previewUrl?: string;
    mediaFile?: File;
    mediaIndex: number;
    isMultilane: boolean;
    volume: number;
    deleteAfterFinishedTranscription?: boolean;
}

export interface ProjectCanvasElements {
    text: Array<TextCanvasElement>;
    image: Array<ImageCanvasElement>;
    shape: Array<ShapeCanvasElement>;
    media: Array<MediaCanvasElement>;
    progress: Array<ProgressCanvasElement>;
    subtitle: SubtitleCanvasElement;
}

export enum CanvasElementType {
    TEXT = 'text',
    SUBTITLE = 'subtitle',
    IMAGE = 'image',
    SHAPE = 'shape',
    BACKGROUND = 'background',
    MEDIA = 'media',
    MULTILANEMEDIA = 'multilane',
    PROGRESS = 'progress'
}

export interface CanvasElementOpenShot {
    id: string;
    x: number;
    y: number;
    offsetX?: number;
    offsetY?: number;
    startTime: number;
    endTime: number;
    zIndex: number;
    rotation: number;
    scale: number;
    scaleY?: number;
    totalStartTime?: number; // time at start index without cuts
    totalEndTime?: number; // time at end index without cuts
    type?: CanvasElementType;
    animationDirection?: KonvaAnimationDirection;
    animations?: KonvaAnimationType[];
}

export interface TextCanvasOpenshot extends CanvasElementOpenShot {
    text: string;
    width: number;
    height: number;
    fontSize: number;
    fontColor: string;
    fontFamily: string;
    backgroundColor: string;
    isBold: boolean;
    isItalic: boolean;
}

export interface ImageCanvasOpenShot extends CanvasElementOpenShot {
    imageURL: string;
    imageURI: string;
}
export interface MediaCanvasOpenShot extends CanvasElementOpenShot {
    mediaURL: string;
    mediaURI: string;
    mediaDuration: number;
    mediaIndex: number;
    absoluteWidth: number;
    absoluteHeight: number;
    mediaType: MediaType;
    volume: number;
    isMultilane: boolean;
}

export interface ShapeCanvasOpenShot extends CanvasElementOpenShot {
    svgDataURL: string;
}
export interface ProgressCanvasOpenShot extends CanvasElementOpenShot {
    foregroundX: number;
    width: number;
    height: number;
    foregroundColor: string;
    backgroundColor: string;
}

export interface TransformedCanvasElements {
    text: Array<TextCanvasOpenshot>;
    image: Array<ImageCanvasOpenShot>;
    shape: Array<ShapeCanvasOpenShot>;
    media: Array<MediaCanvasOpenShot>;
    progress: Array<ProgressCanvasOpenShot>;
}

export function getRotatedElementPosition(
    rotation: number,
    x: number,
    y: number,
    width: number,
    height: number,
    scaleX: number,
    scaleY: number = null
): { x: number; y: number } {
    if (!scaleY) {
        scaleY = scaleX;
    }
    function arcMeasure(degreeAngle) {
        return (degreeAngle / 180) * Math.PI;
    }
    const p1x = x;
    const p1y = y;
    const scaledWidth = width * scaleX;
    const scaledHeight = height * scaleY;

    const v1x = scaledWidth;
    const v1y = scaledHeight;

    const p2x = p1x + (v1x * Math.cos(arcMeasure(rotation)) - v1y * Math.sin(arcMeasure(rotation)));
    const p2y = p1y + (v1x * Math.sin(arcMeasure(rotation)) + v1y * Math.cos(arcMeasure(rotation)));

    const v2x = p2x - p1x;
    const v2y = p2y - p1y;

    const mx = p1x + (1 / 2) * v2x;
    const my = p1y + (1 / 2) * v2y;

    const t1x = mx - scaledWidth / 2;
    const t1y = my - scaledHeight / 2;
    return {
        x: t1x,
        y: t1y
    };
}
