import {
    ComponentType,
    ConnectionPositionPair,
    FlexibleConnectedPositionStrategy,
    GlobalPositionStrategy,
    Overlay
} from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector, NgZone, TemplateRef, Type } from '@angular/core';
import { DialogRef } from './dialog-ref';
import { OverlayWrapperComponent } from './overlay-wrapper/overlay-wrapper.component';
import { ToastComponent } from './toast/toast.component';
import { DialogConfig, DialogContentType, DialogPositions, ToastDataModel, TutorialDataModel } from './dialog.models';
import { DialogContainerComponent } from './dialog/dialog-container.component';
import { Subject } from 'rxjs';
import { getPositionsWithOffset } from './position.definitions';
import { TutorialComponent } from './tutorial/tutorial.component';

/**
 * Injection token that can be used to access the data that was passed in to a popover.
 * */
export const DIALOG_DATA = new InjectionToken('popover.data');

const defaultConfig: DialogConfig = {
    backdropClass: '',
    hasBackDrop: false,
    disableClose: false,
    panelClass: []
};

function getInjector(popoverRef: DialogRef, inj?: Injector) {
    return Injector.create({
        providers: [
            {
                provide: DialogRef,
                useValue: popoverRef
            },
            {
                provide: DIALOG_DATA,
                useValue: popoverRef.config.data
            }
        ],
        parent: inj
    });
}

/**
 * Service to open modal and manage popovers.
 */
let overlayId = 0;
@Injectable({ providedIn: 'root' })
export class DialogService {
    overlayOpenedSource = new Subject<DialogRef>();
    overlayOpened$ = this.overlayOpenedSource.asObservable();

    private _activeOverlays: { [id: number]: DialogRef } = {};

    constructor(private overlay: Overlay, private injector: Injector, private ngZone: NgZone) {
        this.overlayOpened$.subscribe((ref) => {
            const currId = overlayId;
            overlayId++;
            this._addOverlayRef(ref);
            ref.afterClosed().subscribe(() => {
                this._removeOverlayRef(currId);
            });
        });
    }

    openDialog<R = any, T = any>(
        content: DialogContentType,
        config: DialogConfig<T> = {},
        customInjector?: Injector
    ): DialogRef<R> {
        const dialogConfig = {
            hasBackdrop: true,
            positionStrategy: this._getGlobalPositionStrategy(config),
            scrollStrategy: this.overlay.scrollStrategies.block(),
            backdropClass: 'bg-backdrop',
            ...config,
            panelClass: ['bg-white', 'rounded-2xl', 'overflow-hidden', 'shadow-floating']
        };

        if (config?.panelClass?.length) {
            dialogConfig.panelClass.push(...config.panelClass);
        }

        const overlayRef = this.overlay.create(dialogConfig);

        const dialogRef = new DialogRef<R, T>({
            overlayRef,
            config: dialogConfig,
            content,
            overlayType: 'dialog'
        });

        const injector = getInjector(dialogRef, customInjector ? customInjector : this.injector);

        setTimeout(() => {
            overlayRef.attach(new ComponentPortal(DialogContainerComponent, null, injector));
        });

        this.overlayOpenedSource.next(dialogRef);
        return dialogRef;
    }

    // TODO: Support templates
    openFullPageDialog<R = any, T = any>(component: Type<any>, config: DialogConfig<T> = {}): DialogRef<R> {
        const dialogConfig = {
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            positionStrategy: this.overlay.position().global(),
            scrollStrategy: this.overlay.scrollStrategies.block(),
            panelClass: ['bg-white', 'full-page-dialog'],
            ...config
        };

        const overlayRef = this.overlay.create(dialogConfig);

        const dialogRef = new DialogRef<R, T>({
            overlayRef,
            config: dialogConfig,
            content: component,
            overlayType: 'dialog'
        });

        const injector = getInjector(dialogRef, this.injector);

        setTimeout(() => {
            overlayRef.attach(new ComponentPortal(DialogContainerComponent, null, injector));
        });

        this.overlayOpenedSource.next(dialogRef);
        return dialogRef;
    }

    openPopover<D = any>(
        componentOrTemplate: ComponentType<any> | TemplateRef<any>,
        target,
        config: Partial<DialogConfig> = {}
    ): DialogRef<D> {
        const popoverConfig: DialogConfig = Object.assign({}, defaultConfig, config);

        let positionStrategy;
        if (config.positionStrategyType === 'global') {
            positionStrategy = this._getGlobalPositionStrategy(popoverConfig);
        } else {
            positionStrategy = this._getFlexiblePositionStrategy(target, popoverConfig);
        }

        const overlayRef = this._createPopoverOverlay(popoverConfig, positionStrategy);

        const popoverRef = new DialogRef<D>({
            overlayRef,
            config: popoverConfig,
            overlayType: 'popover'
        });

        this.ngZone.run(() => {
            const popover = overlayRef.attach(
                new ComponentPortal(OverlayWrapperComponent, null, getInjector(popoverRef, this.injector))
            ).instance;

            if (componentOrTemplate instanceof TemplateRef) {
                // rendering a provided template dynamically
                popover.attachTemplatePortal(
                    new TemplatePortal(componentOrTemplate, null, {
                        $implicit: popoverConfig.data,
                        popover: popoverRef
                    })
                );
            } else {
                // rendering a provided component dynamically
                popover.attachComponentPortal(new ComponentPortal(componentOrTemplate, null, getInjector(popoverRef)));
            }
        });

        this.overlayOpenedSource.next(popoverRef);
        return popoverRef;
    }

    openToast<T = ToastDataModel>(config: Partial<DialogConfig<T>> = {}, content?: ComponentType<any>) {
        const toastConfig = { timeToLive: 5000, ...config };
        const positionStrategy = this.overlay.position().global().bottom('1rem').centerHorizontally();
        const overlayRef = this.overlay.create({ positionStrategy });

        const toastRef = new DialogRef<boolean>({
            overlayRef,
            config: toastConfig,
            overlayType: 'toast'
        });

        const injector = getInjector(toastRef);

        const toastPortal = new ComponentPortal(content ? content : ToastComponent, null, injector);

        overlayRef.attach(toastPortal);

        this.overlayOpenedSource.next(toastRef);
        return toastRef;
    }

    openTutorial(config: Partial<DialogConfig<TutorialDataModel>> = {}) {
        const positionStrategy = this.overlay.position().global().bottom('1rem').centerHorizontally();
        const overlayRef = this.overlay.create({ positionStrategy });

        const toastRef = new DialogRef<boolean>({
            overlayRef,
            config,
            overlayType: 'toast'
        });

        const injector = getInjector(toastRef);

        const toastPortal = new ComponentPortal(TutorialComponent, null, injector);

        overlayRef.attach(toastPortal);

        this.overlayOpenedSource.next(toastRef);
        return toastRef;
    }

    closeAll() {
        Object.keys(this._activeOverlays).forEach((key) => {
            this._activeOverlays[key]?.close();
        });
        this._activeOverlays = {};
    }

    private _createPopoverOverlay(config: DialogConfig, positionStrategy: FlexibleConnectedPositionStrategy) {
        let scrollStrategy;
        switch (config.scrollAction) {
            case 'block':
                scrollStrategy = this.overlay.scrollStrategies.block();
                break;
            case 'close':
                scrollStrategy = this.overlay.scrollStrategies.close();
                break;
            case 'none':
                scrollStrategy = this.overlay.scrollStrategies.noop();
                break;
            case 'reposition':
            default:
                scrollStrategy = this.overlay.scrollStrategies.reposition();
                break;
        }
        return this.overlay.create({
            backdropClass: 'bg-transparent',
            positionStrategy,
            scrollStrategy,
            ...config
        });
    }

    private _getGlobalPositionStrategy(config?: DialogConfig): GlobalPositionStrategy {
        const globalStrategy = this.overlay.position().global();
        if (config.defaultPosition === 'inside-left') {
            console.error('Globally positioned overlays do not have this type of position', config);
        } else if (config.defaultPosition === 'right' || config.defaultPosition === 'left') {
            return globalStrategy[config.defaultPosition]('1rem').bottom('1rem');
        } else if (config.defaultPosition === 'top' || config.defaultPosition === 'bottom') {
            return globalStrategy[config.defaultPosition]('1rem').centerHorizontally();
        }
        return globalStrategy;
    }

    private _getFlexiblePositionStrategy(target, config: DialogConfig): FlexibleConnectedPositionStrategy {
        const defaultPosition = config.defaultPosition ? config.defaultPosition : 'bottom';

        const generatedPos = getPositionsWithOffset(config);

        let defaultOrderedPositions;
        switch (defaultPosition) {
            case DialogPositions.TOP:
                defaultOrderedPositions = [
                    ...generatedPos.top,
                    ...generatedPos.right,
                    ...generatedPos.left,
                    ...generatedPos.bottom
                ];
                break;
            case DialogPositions.RIGHT:
                defaultOrderedPositions = [
                    ...generatedPos.right,
                    ...generatedPos.top,
                    ...generatedPos.bottom,
                    ...generatedPos.left
                ];
                break;
            case DialogPositions.LEFT:
                defaultOrderedPositions = [
                    ...generatedPos.left,
                    ...generatedPos.top,
                    ...generatedPos.bottom,
                    ...generatedPos.right
                ];
                break;
            case DialogPositions.INSIDE_LEFT:
                defaultOrderedPositions = [
                    ...generatedPos['inside-left'],
                    ...generatedPos.left,
                    ...generatedPos.top,
                    ...generatedPos.bottom,
                    ...generatedPos.right
                ];
                break;
            case DialogPositions.START_BOTTOM:
                defaultOrderedPositions = [
                    ...generatedPos[DialogPositions.START_BOTTOM],
                    ...generatedPos.left,
                    ...generatedPos.top,
                    ...generatedPos.bottom,
                    ...generatedPos.right
                ];
                break;
            case DialogPositions.BOTTOM:
            default:
                defaultOrderedPositions = [
                    ...generatedPos.bottom,
                    ...generatedPos.right,
                    ...generatedPos.top,
                    ...generatedPos.left
                ];
        }

        // preferred positions, in order of priority
        const positions: ConnectionPositionPair[] = [
            // bottom right push left
            ...defaultOrderedPositions,

            // bottom left
            {
                overlayX: 'start',
                overlayY: 'top',
                originX: 'start',
                originY: 'bottom'
            },
            // bottom right
            {
                // panelClass: 'right-center',
                overlayX: 'end',
                overlayY: 'top',
                originX: 'end',
                originY: 'bottom'
            },
            // top left
            {
                overlayX: 'start',
                overlayY: 'bottom',
                originX: 'center',
                originY: 'top'
            },
            // top right
            {
                overlayX: 'end',
                overlayY: 'bottom',
                originX: 'center',
                originY: 'top'
            }
        ];

        return this.overlay
            .position()
            .flexibleConnectedTo(target)
            .withPush(false)
            .withFlexibleDimensions(false)
            .withPositions(positions);
    }

    private _addOverlayRef(ref: DialogRef) {
        this._activeOverlays = { ...this._activeOverlays, [overlayId]: ref };
        overlayId++;
    }

    private _removeOverlayRef(id: number) {
        delete this._activeOverlays[id];
        this._activeOverlays = { ...this._activeOverlays };
    }
}
