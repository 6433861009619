<div class="upgradefeatures">
    <h2 class="upgradefeatures__icon">{{ planDescription.icon }}</h2>
    <h2 class="upgradefeatures__title text-black">{{ planDescription.title }}</h2>
    <p class="upgradefeatures__description text-black" *ngIf="planDescription.description">
        {{ planDescription.description }}
    </p>

    <div class="upgradefeatures__feature" *ngFor="let feature of planDescription.featureList">
        <div class="upgradefeatures__feature__check">
            <svg width="11" height="11" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.5 5L4 7.5L10 1.5"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    opacity="0.3"
                />
            </svg>
        </div>
        <p class="upgradefeatures__feature__name">{{ feature }}</p>
    </div>
    <a
        routerLink="pricing"
        fragment="compare"
        target="_blank"
        class="border-b cursor-pointer text-sm"
        *ngIf="showPriceCompareLink"
        >Compare plans</a
    >

    <div *ngIf="showToggle && (currentPlanIsTrial$ | async)" class="flex align-middle mt-4">
        <!-- <span class="text-black text-sm self-center">Monthly</span> -->
        <app-toggle-button
            [checked]="(selectedInterval$ | async) === 'annually'"
            (checkedChange)="togglePlanInterval($event)"
        >
        </app-toggle-button
        ><span class="text-black text-sm ml-2 self-center">{{ selectedInterval$ | async | titlecase }}</span>
    </div>
</div>
