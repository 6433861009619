import { Component, Input } from '@angular/core';
import { PAYMENT_REASONS } from '@type/shared-models/consts/payment-reason';
import { map, pluck } from 'rxjs/operators';
import { interval } from 'rxjs';
import { PlanType } from '@type/shared-models/payment/products';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { RecorderService } from '../../services/recorder.service';
import { DialogService } from '@type/dialog';
import { SignupComponent } from '../../pages/auth/signup/signup.component';

@Component({
    selector: 'app-usage-limitation',
    templateUrl: './usage-limitation.component.html',
    styleUrls: ['./usage-limitation.component.scss']
})
export class UsageLimitationComponent {
    @Input() showRecordingLimitation = false;
    PAYMENT_REASONS = PAYMENT_REASONS;
    planType = PlanType;
    isPaying$ = this.userService.user$.pipe(pluck('isPaying'));
    isTrial$ = this.userService.user$.pipe(pluck('isTrial'));
    isAnonymous$ = this.userService.user$.pipe(map((user) => user?.isAnonymous));
    remainingMinutes$ = interval(200).pipe(map(() => Math.floor(this.recorderService.getRemainingUserSeconds() / 60)));
    constructor(
        public userService: UserService,
        public authService: AuthService,
        public recorderService: RecorderService,
        private dialogService: DialogService
    ) {}

    openSlidModal() {
        this.dialogService.openDialog(SignupComponent, {
            maxWidth: 1300,
            data: { hideLogin: true, fromAnonymous: true, openPayment: true }
        });
    }
}
