import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '@type-mvp/app/services/user.service';
import { DialogRef, DialogService } from '@type/dialog';
import { switchMap, takeUntil, timer } from 'rxjs';
import { SignupComponent } from '../../pages/auth/signup/signup.component';

@Component({
    selector: 'app-help-menu',
    templateUrl: './help-menu.component.html',
    styleUrls: ['./help-menu.component.scss']
})
export class HelpMenuComponent implements OnInit {
    @ViewChild('featureRequestDialog') featureRequestDialog;
    private featureRequestDialogRef: DialogRef;

    hoursOfOperation = {
        from: '0:00',
        to: '17:00'
    };

    smallchatConfig = {
        config: {
            slackTeamId: 'TMB4Y8LQP',
            scChannelId: '-MLPQ36BmirPWi2z_y1C',
            slackChannelId: 'G01DY6KBQK0',
            uid: '-MLPPwYxh9vpYMBNIrS0',
            planId: null,
            accountCreated: 1604613556567
        },
        appearance: {
            brand_color: '#2d3450',
            contact_dismissible: true,
            contact_enabled: true,
            contact_field_label: 'Email',
            contact_immediately: false,
            contact_prompt:
                'Could you please provide us with your name and email? It makes it easier for us to find you a fitting solution.',
            contact_reply: "Thanks {{name}}! 😊 \nYou'll get a response here as soon as possible.",
            custom_css: '.Layout{\nmargin-left: 100	px}',
            hide_logo: false,
            hide_team_icon: true,
            launcher_pos: 'right',
            launcher_prompt: 'Talk to a human',
            launcher_type: 'button',
            messenger_blank: 'Send a message, and we’ll reply as soon as we can.',
            messenger_entry: 'Send a message...',
            messenger_prompt: 'How can we help you?',
            name_field_label: 'Name',
            offline_greeting:
                'We’re offline right now but if you drop us your question and your email address we will reach out to you asap',
            text_color: '#FFFFFF'
        },
        behavior: {
            // TODO: why are monday and thursday disabled
            monday: {
                disabled: false,
                from: '0:00',
                to: '17:00'
            },
            tuesday: this.hoursOfOperation,
            wednesday: this.hoursOfOperation,
            thursday: {
                disabled: false,
                from: '0:00',
                to: '17:00'
            },
            friday: this.hoursOfOperation,
            saturday: this.hoursOfOperation,
            sunday: this.hoursOfOperation,
            operating_hours: false,
            timezone: 'America/Los_Angeles',
            avatar_config: 0,
            hide_offline: false
        }
    };

    constructor(private dialogService: DialogService, private userService: UserService) {}

    ngOnInit(): void {
        // this.loadSmallchatScript();
    }

    openLink(link: string) {
        window.open(link, '_blank');
    }

    openFeatureRequest() {
        this.featureRequestDialogRef = this.dialogService.openDialog(this.featureRequestDialog, {
            minWidth: 290
        });
    }
    contactViaForm(type: string) {
        switch (type) {
            case 'feature':
                this.openLink('https://support.streamlabs.com/hc/en-us/requests/new?ticket_form_id=1260806623850');
                break;

            case 'support':
                this.openLink('https://support.streamlabs.com/hc/en-us/requests/new?ticket_form_id=18779381763483');
                break;
            default:
                break;
        }

        // if (!this.userService.user || this.userService.user?.isAnonymous) {
        //     this.dialogService
        //         .openDialog(SignupComponent, {
        //             maxWidth: 1300,
        //             data: { hideLogin: true, fromAnonymous: true, openPayment: false }
        //         })
        //         .afterClosed()
        //         .pipe(switchMap(() => this.userService.user$.pipe(takeUntil(timer(3000)))))
        //         .subscribe((user) => {
        //             if (user && !user?.isAnonymous) {
        //                 this.openFeatureRequest();
        //             }
        //         });
        // } else {
        //     this.openFeatureRequest();
        // }

        // }
    }

    loadSmallchatScript() {
        window.Smallchat = this.smallchatConfig;
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://static.small.chat/messenger.js';
        document.body.appendChild(script);

        this.waitForElement('#Smallchat').then((elm: HTMLElement) => {
            elm.style.visibility = 'hidden';
        });
    }

    waitForElement(selector) {
        return new Promise((resolve) => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }
            const observer = new MutationObserver((mutations) => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });
            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }
}
