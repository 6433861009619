import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardComponent } from './card/card.component';
import { ListComponent } from './list/list.component';
import { ListItemComponent } from './list/list-item.component';
import { ListActionDirective } from './list/list-action.directive';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { HideItemsPipe } from './pipes/hide-items.pipe';
import { ButtonModule } from './button/button.module';
import { IconModule } from './icon/icon.module';
import { VolumeSliderComponent } from './volume-slider/volume-slider.component';
import { AutofocusDirective } from './autofocus/autofocus.directive';
import { MatSliderModule } from '@angular/material/slider';
import { ToggleButtonModule } from './toggle-button/toggle-button.module';
import { SecondsFormattingPipe } from './pipes/seconds-formatting.pipe';
import { DomChangeDirective } from './dom/dom-change.directive';
import { CustomCursorDirective } from './custom-cursor/custom-cursor.directive';
import { ClickOutsideOfElementDirective } from './dom/click-outside-of-element.directive';

const exportedModules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    IconModule,
    MatSliderModule,
    ToggleButtonModule
];

const exportedComponents = [
    CardComponent,
    ListComponent,
    ListItemComponent,
    ListActionDirective,
    DomChangeDirective,
    ProgressBarComponent,
    HideItemsPipe,
    AutofocusDirective,
    ClickOutsideOfElementDirective,
    VolumeSliderComponent,
    SecondsFormattingPipe,
    CustomCursorDirective
];

@NgModule({
    imports: [...exportedModules],
    exports: [...exportedModules, ...exportedComponents],
    declarations: [...exportedComponents]
})
export class UiModule {}
