import { Injectable } from '@angular/core';

import { UserService } from './user.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FirebaseFunctionNames } from '@type/shared-models/consts/firebase-function-names';
import { UserFeedback } from '@type/shared-models/user-feedback.models';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {
    constructor(private userService: UserService, private fireFunctions: AngularFireFunctions) {}

    sendFeedbackRequest(message: string) {
        const sendFeedbackRequestData: UserFeedback = {
            feedback: message,
            email: this.userService.user?.email,
            name: this.userService.user?.name,
            id: this.userService.user?.firebaseId
        };

        return this.fireFunctions
            .httpsCallable(FirebaseFunctionNames.sendFeedback)(sendFeedbackRequestData)
            .pipe(first());
    }
}
