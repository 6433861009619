import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    template: `
        <app-dialog-header>
            Oh no, your payment failed
            <app-dialog-close-button appDialogClose></app-dialog-close-button>
        </app-dialog-header>

        <p class="typ-p">
            To keep using Podcast Editor's premium features, you may need to update your payment details.
        </p>
        <br />
        <p class="typ-p">We'll try your payment again over the next few days.</p>

        <app-dialog-footer>
            <app-button color="primary" (btnClick)="goToProfile()" appDialogClose>Update payment details</app-button>
        </app-dialog-footer>
    `
})
export class RetrySubscriptionComponent {
    constructor(private router: Router) {}

    goToProfile() {
        this.router.navigate(['profile']).then();
    }
}
