import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ThemingColorType, ThemingSizeType } from '../models/theming';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'type-loader',
    template: `
        <div [style]="{ background: transparent ? 'transparent' : '' }" [class]="themingClasses">
            <p *ngIf="message" class="loader-message">{{ message }}</p>
            <div class="loader-spinner"></div>
            <div class="loader-content">
                <ng-content></ng-content>
            </div>
            <span
                *ngIf="_entertainerText"
                class=" h-4 mt-6"
                [ngClass]="{ 'loading-ellipsis': _entertainerText !== ' ' }"
                [@fadeTextIn]
            >
                {{ _entertainerText }}</span
            >
        </div>
    `,
    styleUrls: ['loader.component.scss'],
    animations: [
        trigger('fadeTextIn', [
            transition(':enter', [style({ opacity: 0 }), animate('200ms 2000ms', style({ opacity: 1 }))])
        ])
    ]
})
export class LoaderComponent implements OnChanges {
    @Input() message: string;
    _entertainerText: string;
    @Input() set entertainerText(texts: string[]) {
        this._entertainerText = ' ';
        const timeTillFirstWord = 2000;
        const changeSpeed = 4000;
        texts.forEach((text, index) => {
            setTimeout(() => {
                this._entertainerText = text;
            }, timeTillFirstWord + index * changeSpeed);
        });
    }
    @Input() size: ThemingSizeType = 'md';
    @Input() color: ThemingColorType;
    @Input() spinnerOnly: boolean;
    @Input() transparent: boolean;
    @Input() standalone: boolean;
    themingClasses: any = {
        ['loader-container']: true
    };

    ngOnChanges({ color, size, spinnerOnly, standalone }: SimpleChanges) {
        if (color) {
            this.themingClasses['loader-primary'] = this.color === 'primary';
            this.themingClasses['loader-black'] = this.color === 'black';
        }

        if (spinnerOnly) {
            this.themingClasses['background-transparent'] = this.spinnerOnly;
        }
        if (standalone) {
            this.themingClasses['standalone'] = this.standalone;
        }

        if (size) {
            if (this.size === 'md') {
                this.themingClasses['loader-sm'] = false;
            } else if (this.size === 'sm') {
                this.themingClasses['loader-sm'] = true;
            }
        }
    }
}
