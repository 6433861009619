import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverlayWrapperComponent } from './overlay-wrapper/overlay-wrapper.component';
import { UiModule } from '@type/ui';
import { ClickOutsideDirective } from './click-outside.directive';
import { OverlayTriggerForDirective } from './triggers/overlay-trigger-for.directive';
import { MenuComponent } from './menu/menu.component';
import { MenuSearchComponent } from './menu/menu-search.component';
import { MenuItemAnchorComponent, MenuItemButtonComponent } from './menu/menu-item.component';
import { MenuItemSubheaderDirective } from './menu/menu-item-subheader.directive';
import { MenuItemHeaderDirective } from './menu/menu-item-header.directive';
import { PopoverComponent } from './popover/popover.component';
import { A11yModule } from '@angular/cdk/a11y';
import { TooltipTriggerDirective } from './tooltip/tooltip-trigger.directive';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TabMenuComponent } from './tab-menu/tab-menu.component';
import { TabMenuSectionComponent } from './tab-menu/tab-menu-section.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToastComponent } from './toast/toast.component';
import { DialogCloseDirective } from './dialog/dialog-close.directive';
import { DialogHeaderComponent } from './dialog/dialog-header.component';
import { DialogBackButtonComponent } from './dialog/dialog-back-button.component';
import { DialogCloseButtonComponent } from './dialog/dialog-close-button.component';
import { DialogFooterComponent, DialogFooterStartDirective } from './dialog/dialog-footer.component';
import { DialogJustifyEndDirective } from './dialog/dialog-justify-end.directive';
import { DialogContainerComponent } from './dialog/dialog-container.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { OverlayTriggerDirective } from './triggers/overlay-trigger.directive';
import { DropZoneDirective } from './drop-zone.directive';

const exportItems = [
    OverlayTriggerDirective,
    OverlayTriggerForDirective,
    MenuComponent,
    MenuItemAnchorComponent,
    MenuItemButtonComponent,
    MenuItemHeaderDirective,
    MenuItemSubheaderDirective,
    MenuSearchComponent,
    PopoverComponent,
    TooltipTriggerDirective,
    TabMenuComponent,
    TabMenuSectionComponent,
    TooltipTriggerDirective,
    ToastComponent,
    TutorialComponent,
    DropZoneDirective
];

const exportDialog = [
    DialogCloseDirective,
    DialogHeaderComponent,
    DialogBackButtonComponent,
    DialogCloseButtonComponent,
    DialogFooterComponent,
    DialogFooterStartDirective,
    DialogJustifyEndDirective
];

@NgModule({
    declarations: [
        OverlayWrapperComponent,
        ClickOutsideDirective,
        TooltipComponent,
        DialogContainerComponent,
        ...exportItems,
        ...exportDialog
    ],
    imports: [CommonModule, OverlayModule, PortalModule, UiModule, A11yModule, AngularSvgIconModule],
    exports: [...exportItems, ...exportDialog]
})
export class DialogModule {}
