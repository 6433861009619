import { Injectable } from '@angular/core';
import { AngularFireUploadTask } from '@angular/fire/compat/storage';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UploadTaskService {
    private _taskRegistrySource = new BehaviorSubject({});
    taskRegistry$ = this._taskRegistrySource.asObservable();
    private _subRegistry: { [id: string]: Subscription } = {};

    registerTask(id: string, task?: AngularFireUploadTask | Subscription, autoDeregister = false): void {
        if (autoDeregister) {
            if (task instanceof Subscription) {
                this._subRegistry[id] = task;
            } else {
                this._subRegistry[id] = task
                    .snapshotChanges()
                    .pipe(
                        finalize(() => {
                            this.removeTask(id);
                        })
                    )
                    .subscribe();
            }
        }
        this._taskRegistrySource.next({
            ...this._taskRegistrySource.value,
            [id]: task
        });
    }

    getTask(id: string): AngularFireUploadTask {
        return this._taskRegistrySource.value[id];
    }

    getTasks(): { [prop: string]: AngularFireUploadTask } {
        return { ...this._taskRegistrySource.value };
    }

    removeTask(id: string): void {
        this._subRegistry[id]?.unsubscribe();
        delete this._subRegistry[id];
        const tasks = this.getTasks();
        delete tasks[id];
        this._taskRegistrySource.next(tasks);
    }
}
