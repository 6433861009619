import { Component } from '@angular/core';

@Component({
    template: `
        <app-dialog-header>
            Upgrade plan
            <app-dialog-close-button appDialogClose></app-dialog-close-button>
        </app-dialog-header>
        <p class="typ-p typ-color-black8">Are you sure you want to upgrade?</p>
        <app-dialog-footer>
            <app-button appDialogClose>Cancel</app-button>
            <app-button color="primary" [appDialogClose]="true">Upgrade</app-button>
        </app-dialog-footer>
    `
})
export class UpgradeSubscriptionConfirmation {}
