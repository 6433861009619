import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
    selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
    skipInitialClick = true;
    @Input() hasBackDrop: boolean;
    @Input() disableClose: boolean;

    @Output('appClickOutside')
    clickOutside = new EventEmitter();

    constructor(private _elementRef: ElementRef) {
        setTimeout(() => {
            this.skipInitialClick = false;
        }, 0);
    }

    @HostListener('document:mousedown', ['$event.target'])
    onClick(eventTarget) {
        if (this.hasBackDrop || this.skipInitialClick || this.disableClose) return;

        const isCdkOverlayPopover = eventTarget.closest('.cdk-overlay-connected-position-bounding-box');
        const isCdkOverlayDialog = eventTarget.closest('.cdk-global-overlay-wrapper');

        // This value check is for cases when the date picker is opened and the years options is opened and then selected.
        // Because of the switch back to the calendar component, the `closest` method fails to see the
        // cdk-overlay-container in the years' component path.
        const clickedInside = this._elementRef.nativeElement.contains(eventTarget);
        if (!clickedInside && (!isCdkOverlayPopover || !isCdkOverlayDialog)) {
            this.clickOutside.emit(null);
        }
    }
}
