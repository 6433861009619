import { PlanTable, PlanType } from '@type/shared-models/payment/products';

export class SubscriptionPlan {
    type: PlanType;
    // Rendering Limitation in Seconds
    usageLimitation: number;

    constructor(type: PlanType) {
        this.type = type;

        switch (type) {
            case PlanType.EXPIRED:
                this.usageLimitation = PlanTable.production[PlanType.TRIAL].usageLimitation;
                break;
            case PlanType.PERSONAL:
            case PlanType.ENTERPRISE:
                this.usageLimitation = PlanTable.production[PlanType.PRO].usageLimitation;
                break;
            default:
                this.usageLimitation = PlanTable.production[type].usageLimitation;
                break;
        }
        return this;
    }

    fromFirebase(subscriptionPlan: SubscriptionPlan) {
        this.type = subscriptionPlan.type;
        this.usageLimitation = subscriptionPlan.usageLimitation;
        return this;
    }

    toFirebase() {
        return {
            type: this.type,
            usageLimitation: this.usageLimitation
        };
    }
}
