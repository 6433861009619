import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { filter, map, switchMap, take } from 'rxjs/operators';
import paths from '@type/shared-models/consts/firebase-paths';
import { OrganizationFirebaseModel, OrganizationModel } from '@type/shared-models/organization/organization.models';
import { MemberRole } from '@type/shared-models/member.models';
import { of } from 'rxjs';
import { UserService } from '@type/payment-dialog/services/payment-dialog-user.service';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {
    constructor(private db: AngularFirestore, private userService: UserService) {}

    getOrganization() {
        return this.userService.user$.pipe(
            filter((val) => !!val),
            take(1),
            switchMap((user) =>
                this.db
                    .collection<OrganizationFirebaseModel>(paths.ORGANIZATIONS, (ref) =>
                        ref.where(`members.${user.firebaseId}`, '!=', null)
                    )
                    .snapshotChanges()
                    .pipe(
                        map((docChanges) => docChanges[0]),
                        map((snapshot) =>
                            snapshot
                                ? {
                                      ...snapshot.payload.doc.data(),
                                      firebaseId: snapshot.payload.doc.id
                                  }
                                : null
                        ),
                        map((organization) =>
                            organization
                                ? OrganizationFirebaseModel.fromFirebase(organization as OrganizationFirebaseModel)
                                : ({} as OrganizationModel)
                        )
                    )
            )
        );
    }

    /**
     * Returns a true if the user is an owner of an organization
     */
    isOrganizationMember(onlyOwner = false, onlyMember = false) {
        if (!this.userService.user) return of(null);
        return this.getOrganization().pipe(
            map(
                (organization) =>
                    !!organization?.members?.some(
                        (member) =>
                            member.firebaseId === this.userService.user.firebaseId &&
                            (onlyOwner ? member.role === MemberRole.OWNER : true) &&
                            (onlyMember ? member.role === MemberRole.EDITOR : true)
                    )
            )
        );
    }
}
