import { BehaviorSubject, Observable } from 'rxjs';
import path from 'path-browserify';
export class DownloadRequestModel {
    progress: number;
    payload?: Blob;
    error?: any;
    loading: boolean;
}

function loadMedia(url: string): Observable<DownloadRequestModel> {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'arraybuffer';
    xhr.open('GET', url);
    xhr.send();
    const progress = new BehaviorSubject<DownloadRequestModel>({
        progress: 0,
        payload: null,
        loading: true
    });
    xhr.onload = () => {
        const blob = xhr.response;
        progress.next({
            progress: 1,
            payload: blob,
            loading: false
        });
        progress.complete();
    };

    xhr.onerror = (ev) => {
        progress.next({
            progress: (ev.loaded / ev.total) * 100,
            error: ev,
            loading: false
        });
        progress.complete();
    };

    xhr.onprogress = (event) => {
        progress.next({
            progress: (event.loaded / event.total) * 100,
            payload: null,
            loading: true
        });
    };
    return progress.asObservable();
}

export function downloadFile(data, type: string, filename: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob([data], { type }));

    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    window.URL.revokeObjectURL(downloadLink.href);
}

export function downloadMediaFromUrl(
    url: string,
    filename: string,
    type: 'audio' | 'video'
): Observable<DownloadRequestModel> {
    const request = loadMedia(url);
    request.subscribe((res) => {
        if (!res.payload) return;
        const fileType = type === 'audio' ? 'audio/mpeg-3' : 'video/mp4';
        const fileExtension = type === 'audio' ? '.mp3' : '.mp4';
        downloadFile(res.payload, fileType, filename + fileExtension);
    });
    return request;
}

export function getRenderedVideoUriFromVideoUri(videoUri: string): string {
    const filename = baseName(videoUri);
    const filepath = dirName(videoUri);

    return `${filepath}/rendered/${filename}`;
}

export function blobToBase64String(blob: Blob): Promise<string> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64dataWithFileHeader = reader.result as string;
            const base64data = base64dataWithFileHeader.substr(base64dataWithFileHeader.indexOf(',') + 1);
            resolve(base64data);
        };
    });
}

export function getMp3Path(videoPath: string): string {
    const filenameWithoutExtension = path.basename(videoPath, path.extname(videoPath));
    const fileDirectory = path.dirname(videoPath);
    const outputPath = `${fileDirectory}/audio_${filenameWithoutExtension}.mp3`;

    return outputPath;
}

export function getThumbnailPath(videoPath: string): string {
    const extension = getExtension(videoPath);
    return videoPath.replace(extension, '.jpeg');
}
export function changeExtension(videoPath: string, newExtension: string) {
    const extension = getExtension(videoPath);
    return videoPath.replace(extension, '.' + newExtension);
}

export function getExtension(input: string): string {
    return '.' + input.split('.').pop();
}

function baseName(str: string): string {
    let base = new String(str).substring(str.lastIndexOf('/') + 1);
    if (base.lastIndexOf('.') != -1) base = base.substring(0, base.lastIndexOf('.'));
    return base;
}

function dirName(str: string): string {
    return str.substring(0, str.lastIndexOf('/'));
}

export function getFilenameFromPath(inputPath: string) {
    return inputPath.replace(/^.*[\\/]/, '');
}
/**
 *  I generate the UID from two parts here 
    to ensure the random number provide enough bits.
 * @returns uid like "aX4j9Z"
 */
export function generateUID() {
    const firstPart = ('000' + ((Math.random() * 46656) | 0).toString(36)).slice(-3);
    const secondPart = ('000' + ((Math.random() * 46656) | 0).toString(36)).slice(-3);
    return firstPart + secondPart;
}
