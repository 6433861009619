<div *ngIf="authService.isSignedIn && (userService.user$ | async)?.subscriptionState" class="usage-limitation">
    <div class="progress-bar-container">
        <div class="progress-bar">
            <round-progress
                [current]="
                    +(
                        (showRecordingLimitation
                            ? (userService.user$ | async)?.subscriptionState.plan.usageLimitation / 60 -
                              (remainingMinutes$ | async)
                            : (userService.user$ | async)?.subscriptionState.usedSeconds / 60
                        ) | number: '1.0-0'
                    )
                "
                [max]="(userService.user$ | async)?.subscriptionState.plan.usageLimitation / 60"
                [color]="'#141414'"
                [background]="'#DDDEE7'"
                [stroke]="24"
                [rounded]="true"
                [clockwise]="true"
                [responsive]="true"
                [duration]="800"
            >
            </round-progress>
            <div class="percentage">
                <span
                    class="value"
                    [ngClass]="
                        (showRecordingLimitation
                            ? (((userService.user$ | async)?.subscriptionState.plan.usageLimitation / 60 -
                                  (remainingMinutes$ | async)) /
                                  ((userService.user$ | async)?.subscriptionState.plan.usageLimitation / 60)) *
                              100
                            : (((userService.user$ | async)?.subscriptionState.usedSeconds / 60) * 100) /
                              ((userService.user$ | async)?.subscriptionState.plan.usageLimitation / 60)) === 100
                            ? 'text-sm font-bold -ml-1'
                            : 'text-base font-bold'
                    "
                    >{{
                        (showRecordingLimitation
                            ? (((userService.user$ | async)?.subscriptionState.plan.usageLimitation / 60 -
                                  (remainingMinutes$ | async)) /
                                  ((userService.user$ | async)?.subscriptionState.plan.usageLimitation / 60)) *
                              100
                            : (((userService.user$ | async)?.subscriptionState.usedSeconds / 60) * 100) /
                              ((userService.user$ | async)?.subscriptionState.plan.usageLimitation / 60)
                        ) | number: '1.0-0'
                    }}</span
                >
                <span class="text-xs text-black3">%</span>
            </div>
        </div>
    </div>
    <div class="text-container flex flex-col">
        <h3 class="leading-5">
            <ng-container *ngIf="isTrial$ | async"> Free </ng-container>
            {{ showRecordingLimitation ? 'Recording Time' : 'Upload Limit' }}
        </h3>
        <p *ngIf="!showRecordingLimitation; else showRecorderInfo">
            You have used {{ (userService.user$ | async)?.subscriptionState.usedSeconds / 60 | number: '1.0-0' }} of
            {{ (userService.user$ | async)?.subscriptionState.plan.usageLimitation / 60 | number: '1.0-1' }} mins
        </p>
        <ng-template #showRecorderInfo
            ><p>You have {{ remainingMinutes$ | async | number: '1.0-0' }} minutes left</p></ng-template
        >
    </div>
    <div>
        <ng-container *ngIf="(isAnonymous$ | async) === false; else isAnonymous">
            <type-payment-dialog
                [paymentReason]="PAYMENT_REASONS.usageLimitationButton"
                data-spec="usage-limit-upgrade-button"
            >
                <app-button color="upgrade" *ngIf="isTrial$ | async"> Upgrade </app-button>
            </type-payment-dialog>
        </ng-container>

        <ng-template #isAnonymous>
            <app-button (btnClick)="openSlidModal()" color="upgrade" *ngIf="isTrial$ | async"> Upgrade </app-button>
        </ng-template>
    </div>
</div>
