import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-tab-menu-section',
    template: `
        <button
            class="color-foreground-dark typ-p px-3 py-2 tab-menu-title"
            [class.selected]="selected"
            type="button"
            (click)="clicked.emit($event)"
        >
            <span class="pb-1">{{ title }}</span>
        </button>
        <div class="tab-menu-content grid gap-4 px-3 overflow-hidden" *ngIf="selected">
            <ng-content></ng-content>
        </div>
    `,
    styles: [
        `
            :host {
                display: contents;
            }
        `
    ]
})
export class TabMenuSectionComponent {
    // states
    @Input() title: string;
    @Input() selected: boolean;
    @Output() clicked = new EventEmitter<MouseEvent>();
}
