<app-dialog-header>
    Camera and microphone access required
    <app-dialog-close-button appDialogClose></app-dialog-close-button>
</app-dialog-header>
<div class="grid grid-cols-2">
    <div>
        <strong>To give access:</strong>
        <ul class="access-list">
            <li>Click on the lock in your url bar</li>
            <li>Allow permission to your camera</li>
            <li>Allow permission to your microphone</li>
        </ul>
        <div class="my-4">
            Once permission is granted, <br />
            reload the browsers.
        </div>
        <app-button color="secondary" size="md" (btnClick)="reloadPage()">
            <svg-icon appIcon src="assets/ic/16/reload.svg" class="mr-1"></svg-icon>Reload
        </app-button>
    </div>
    <div>
        <img
            class="ml-8 permission-image"
            src="assets/recorder/permission-access.png"
            alt="Google Chrome enable camera and microphone access"
        />
    </div>
</div>
