import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList
} from '@angular/core';
import { overlayAnimations } from '@type/shared/animations/overlay.animations';
import { OverlayComponentType } from '../overlay-component.model';
import { TabMenuSectionComponent } from './tab-menu-section.component';

@Component({
    selector: 'app-tab-menu',
    exportAs: 'appTabMenu',
    templateUrl: './tab-menu.component.html',
    styles: [
        `
            :host {
                display: none;
            }
            .scroll-container--y-auto {
                --scroll-width: 0.5rem;
            }
        `
    ],
    animations: overlayAnimations
})
export class TabMenuComponent extends OverlayComponentType implements OnInit, AfterContentInit {
    columnCount = 4;
    @Input() selectedTab: string;
    @Output() tabSelected = new EventEmitter<string>();
    @ContentChildren(TabMenuSectionComponent) templates: QueryList<TabMenuSectionComponent>;

    ngOnInit() {
        if (this.selectedTab) {
            setTimeout(() => {
                this.setSelectedTabByTitle(this.selectedTab);
            });
        }
    }

    ngAfterContentInit() {
        this.columnCount = this.templates.length;
        this.templates.forEach((template) => {
            template.clicked.subscribe((mouseEvent: MouseEvent) => {
                mouseEvent.stopPropagation();
                this.setSelectedTab(template);
            });
        });
    }

    private setSelectedTabByTitle(title: string) {
        this.templates.forEach((template) => {
            if (template.title === title) {
                template.selected = true;
                this.tabSelected.emit(template.title);
            } else {
                template.selected = false;
            }
        });
    }

    private setSelectedTab(selectedTab: TabMenuSectionComponent) {
        this.templates.forEach((template) => {
            if (template === selectedTab) {
                template.selected = true;
                this.tabSelected.emit(template.title);
            } else {
                template.selected = false;
            }
        });
    }
}
