import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    exportAs: 'progressBar',
    template: `
        <div
            class="progress-bar-wrapper"
            [class.progress-bar-sm]="size === 'sm'"
            [class.progress-bar-bg-cta]="bgColor === 'cta'"
        >
            <div
                class="progress-bar"
                [style.width.%]="progress"
                [style.transition-duration.ms]="_transitionDuration"
            ></div>
            <div class="progress-bar-content color-secondary">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges {
    @Input() complete = false;
    @Input() bgColor: 'cta' | null;
    @Input() size: 'sm' | 'md' = 'md';
    _transitionDuration = 400;
    @Input() transitionDuration = 300;

    private _progress: number;
    @Input()
    set progress(val: number) {
        if (val > 100) {
            this._progress = 100;
        } else if (val < 0) {
            this._progress = 0;
        } else {
            this._progress = val;
        }
    }
    get progress() {
        if (this.complete) return 100;
        return this._progress;
    }

    ngOnChanges({ progress }: SimpleChanges) {
        if (progress.isFirstChange()) {
            setTimeout(() => {
                this._transitionDuration = this.transitionDuration;
            }, this._transitionDuration + 200);
        }
    }
}
