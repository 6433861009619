<div class="mobile-disclaimer">
    <div>
        <div class="copy-link">
            <div class="disclaimer-icons">
                <h1>📱</h1>
                <h3>→</h3>
                <h1>🖥</h1>
            </div>
            <h3>
                Please open Podcast Editor<br />
                on your computer
            </h3>
            <button class="button-small button-small__cta" (click)="copyURL()">
                <div>
                    {{ urlCopied ? 'Link copied 👍' : 'Copy link' }}
                </div>
            </button>
        </div>
        <div class="private-beta">
            <h3>
                We're working on<br />
                a mobile version
            </h3>
            <p>
                Apply for early access<br />
                to our private beta.
            </p>

            <button class="button-small button-small__cta" (click)="joinWaitlist()">
                {{ joinedWaitlist ? "You're on the list 📃" : 'Put me on the list' }}
            </button>
        </div>
    </div>
</div>
