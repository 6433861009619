import { CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Component, ComponentRef, EmbeddedViewRef, ViewChild } from '@angular/core';
import { DialogRef } from '../dialog-ref';

/**
 * Internal component that wraps user-provided popover content.
 */
@Component({
    selector: 'type-overlay-wrapper',
    template: `
        <div
            data-spec="overlay-wrapper"
            [disableClose]="ref.config.disableClose"
            [hasBackDrop]="hasBackDrop"
            (appClickOutside)="close()"
            [cdkTrapFocus]="!ref.config.allowHotkeys"
            [cdkTrapFocusAutoCapture]="!ref.config.allowHotkeys"
        >
            <button *ngIf="autoFocus" #focusItem class="hidden-button"></button>
            <ng-container [cdkPortalOutlet]></ng-container>
        </div>
    `,
    styles: [
        `
            :host {
                display: contents;
            }
            .hidden-button {
                opacity: 0;
                height: 0;
                width: 0;
                border: none;
                position: absolute;
                left: -10000px;
            }
        `
    ]
})
export class OverlayWrapperComponent {
    hasBackDrop: boolean;
    disableClose: boolean;
    autoFocus: boolean;
    @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;
    @ViewChild('focusItem') focusItem;
    constructor(public ref: DialogRef) {
        this.hasBackDrop = ref.config?.hasBackDrop;
        this.autoFocus = !ref.config.noAutoFocus;
        setTimeout(() => {
            if (!this.focusItem) return;
            this.focusItem.nativeElement.tabIndex = -1;
        });
    }

    close() {
        this.ref.close();
    }

    attachComponentPortal<T>(componentPortal: ComponentPortal<any>): ComponentRef<T> {
        return this.portalOutlet.attachComponentPortal(componentPortal);
    }

    attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
        return this.portalOutlet.attachTemplatePortal(portal);
    }
}
