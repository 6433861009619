const routes = {
    HOME: '/home',
    LOGIN: '/login',
    ANONYMOUS_LOGIN: '/auth/anonymous-login',
    SIGNUP: '/signup',
    AUTH_LOGIN: '/auth/login',
    AUTH_SIGNUP: '/auth/signup',
    AUTH_RESET_PASSWORD: '/auth/password-reset',
    TRANSCRIPTION: '/transcription',
    RENDERING: '/rendering',
    FORM: '/form',
    EDITOR: '/editor',
    PROJECTS: '/projects',
    SHARE: '/share',
    UPLOAD: '/upload',
    UPGRADE: '/upgrade',
    CONTACT: '/contact',
    PROFILE: '/profile',
    PRICING: '/pricing',
    EXAMPLE_PROJECT: '/editor/example',
    LINK: '/link'
};

export default routes;
