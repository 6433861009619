import { SubtitleMode } from '@type/shared-models/subtitle-mode';
import { EventEmitter, Injectable, Injector, OnDestroy } from '@angular/core';
import { AnalyticsEvents } from '@type/shared-models/consts/analytic-events';
import {
    BehaviorSubject,
    combineLatest,
    firstValueFrom,
    lastValueFrom,
    Observable,
    of,
    Subject,
    Subscription
} from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    pairwise,
    switchMap,
    take,
    takeUntil,
    takeWhile,
    tap,
    timeout
} from 'rxjs/operators';
import { WordEditCommand } from '../commands/text/word-edit-command';
import { WordRemoveCommand } from '../commands/text/word-remove-command';
import { downloadFile } from '@type/shared-models/utils/file-utility';
import { ProjectService } from './project.service';
import { copyToClipboard } from '@type/shared-models/utils/text-utility';
import { UserService } from './user.service';
import { EditingMode } from '@type/shared-models/editing-mode';
import { WordReAddCommand } from '../commands/text/word-re-add-command';
import { LinebreakAddCommand } from '../commands/text/linebreak-add-command';
import { LinebreakRemoveCommand } from '../commands/text/linebreak-remove-command';
import { Project, TranscriptionState, VideoEditorConfig } from '@type/shared-models/project';
import { MediaUrlWithIndex, Transcription, TranscriptionMap } from '@type/shared-models/editor/transcription';
import { Word, WordIndex } from '@type/shared-models/editor/word';
import {
    CanvasElementType,
    ImageCanvasElement,
    MediaCanvasElement,
    ProgressCanvasElement,
    ShapeCanvasElement,
    SubtitleCanvasElement,
    TextCanvasElement
} from '@type/shared-models/canvas-elements';
import { ImageElement } from '../models/canvas/image-element';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { PlaybackRate } from '@type/shared-models/editor/playback-rate';
import { Language, Languages } from '@type/shared-models/language';
import { Visibility } from '@type/shared-models/consts/visibility';
import { FrontendTextElement } from '../models/canvas/frontend-text-element';
import { ShapeElement } from '@type/shared-models/models/canvas/shape-element';
import { VideoElement } from '../models/canvas/video-element';
import { MultilaneVideoElement } from '../models/canvas/multilane-media-element';
import { Datasource, IDatasource, SizeStrategy } from 'ngx-ui-scroll';
import { Punctuation } from '@type/shared-models/editor/punctuation';
import { VoiceTranslationMode } from '@type/shared-models/translation/voice-translation-mode';
import { VoiceTranslation, VoiceTranslationState } from '@type/shared-models/translation/voice-translation';
import { getExampleVoiceTranslation } from '../../environments/publicTranscriptionExample';
import { AnalyticsService } from './analytics.service';
import { FirebaseFunctionErrorModel } from '@type/shared-models/errors/firebase-function-error.models';
import { MediaModel, MediaType } from '@type/shared-models/media/media.utils';
import { CanvasElement } from '@type/shared-models/models/canvas/canvas-element';
import { SelectionType } from '@type/shared-models/editor/selection-type';
import { ContextMenu } from '@type/shared-models/editor/context-menu';
import { DialogRef, DialogService } from '@type/dialog';
import { CommandService } from './command.service';
import { VideoDimension } from '@type/shared-models/video-dimension';
import { AddSubtitleBreakCommand } from '../commands/subtitle/add-subtitle-break-command';
import { ChangeSubtitleBreakCommand } from '../commands/subtitle/change-subtitle-break-command';
import { RemoveSubtitleBreakCommand } from '../commands/subtitle/remove-subtitle-break-command';
import { DualMediaSource, MediaSource } from '@type/shared-models/editor/media-source.models';
import { Command } from '../commands/command';
import { IsCanvasMarkerPipe } from '../pages/main-editor/is-canvas-marker.pipe';
import { IsSubtitleBreakPipe } from '../pages/main-editor/is-subtitle-break.pipe';
import { ProgressElement } from '../models/canvas/progress-element';
import { ReplaceWordCommand } from '../commands/text/replace-word-command';
import { StartEndConfig } from '@type/shared-models/start-end-word.model';
import { ActivatedRoute } from '@angular/router';
import { SubtitleClips } from '@type/shared-models/editor/subtitle-clips';
import { VideoClip } from '@type/shared-models/editor/video-clip';
import { VideoClips } from '@type/shared-models/editor/video-clips';
import { arrayFromRange, splitConsecutiveNumberArray } from '@type/shared-models/shared-utils/array-utils';
import { SignupComponent } from '../pages/auth/signup/signup.component';
import { Speaker, defaultSpeakers } from '@type/shared-models/editor/speaker';
import { HotkeyService } from './hotkey.service';
import { ClipConfig } from '@type/shared-models/rendering/clip-config';
import { Resolution } from '@type/shared-models/resolution';
import { deepCopy } from '@firebase/util';
import { trackEditorLoad } from '../../../../../libs/shared-models/src/lib/utils/tracking';
import { VideoEditor } from '../models/video-editor/video-editor';
import { getVideoFormatByResolution } from '@type/shared-models/editor/video-format-options.models';
import { environment } from '../../environments/environment';
import { TextElement } from '@type/shared-models/models/canvas/text-element';
import { SideMenuItemKey, SideMenuOpenMenuItem } from '../partials/side-menu/side-menu.component';
import Konva from 'konva-custom';
import { SubtitleElement } from '@type/shared-models/models/canvas/subtitle-element';
import { removeEmpty } from '@type/shared-models/remove-empty';
// import { exampleStreamingResponses } from '../utils/exampleStreamingLong';

const TTS_DURATION_LIMITATION_MINUTES = 60;
@Injectable({
    providedIn: 'root'
})
export class EditorService {
    currentProject$: BehaviorSubject<Project> = new BehaviorSubject(null);
    projectSubscription: Subscription;
    get currentProject(): Project {
        return this.currentProject$.value;
    }

    set currentProject(newProject: Project) {
        this.currentProject$.next(newProject);
    }

    currentTranscription$: BehaviorSubject<Transcription> = new BehaviorSubject(null);

    get currentTranscription(): Transcription {
        return this.currentTranscription$.value;
    }

    initialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    // Stack of Commands implementing the Command Class
    commandStack: Command[] = [];

    public get editingMode(): EditingMode {
        return this.editingMode$.value;
    }

    public set editingMode(editingMode: EditingMode) {
        this.editingMode$.next(editingMode);
    }

    saveEditedText = new EventEmitter();

    // Video editing properties
    public set SelectedWordIndex(index: number) {
        this.selectedWordIndex = index;
        this.setVideoPositionToCursor();
    }

    selectedWordIndex = -1;
    editingWordIndex$ = new BehaviorSubject(-1);
    editingWordSize = 0;

    private _playingWordIndex = -1;
    public get playingWordIndex(): number {
        return this._playingWordIndex;
    }

    public set playingWordIndex(index: number) {
        this._playingWordIndex = index;
        this.playingWordIndex$.next(index);
        this.selectedWordIndex = index;
    }

    private _markerTutorialOpened = false;
    private _cutTutorialOpened = false;

    CHUNK_SIZE = 400;
    datasource$: BehaviorSubject<IDatasource<WordIndex[]>> = new BehaviorSubject<IDatasource<WordIndex[]>>(null);
    chunkWordArrayResult: WordIndex[][];

    // Video
    videoEditor: VideoEditor;
    videoDuration: number;
    runningVideo: HTMLVideoElement;
    nextVideo: HTMLVideoElement;
    waitingToPlay = false;

    videoA: HTMLVideoElement;
    videoB: HTMLVideoElement;
    translatedAudio: HTMLAudioElement;

    currentVideoDimensions: VideoDimension;
    currentStageDimensions: VideoDimension;
    // currentClipDimensions: VideoDimension;
    currentVideoRotation: number;
    currentVideoPosition: {
        x: number;
        y: number;
    } = { x: 0, y: 0 };
    currentVideoScale: number;

    videoClips: VideoClips;
    currentVideoClip: VideoClip;

    allowedLength = 60;
    mainDivWidth: number;

    totalClicks = 0;
    rageThreshold = 6;

    // change triggers
    videoIsRunning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showTextLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isUpdatingTimeline$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    showVideoLoading$ = new BehaviorSubject(false);
    runningVideoChanged$: Subject<void> = new Subject();
    videoPositionChanged$: Subject<void> = new Subject();
    transcriptionChangedCompletely$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    transcriptionChangedCompletelySubscription: Subscription;
    transcriptionVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    canvasLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    canvasLoadedSubscription: Subscription;
    subtitleChanged$: BehaviorSubject<string> = new BehaviorSubject(null);
    removedAllPauses$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    removedAllFillerWords$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    update$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    transcriptChangedAfterVoiceAdded$ = new BehaviorSubject(false);
    showPlaceHolderVideo$ = new BehaviorSubject<boolean>(null);

    // has values
    textEditorContextMenu$: BehaviorSubject<ContextMenu> = new BehaviorSubject(null);
    closeTextEditorContextMenu$: Subject<null> = new Subject();
    selectedParagraphs$ = new BehaviorSubject<{ index: number; selected: boolean }[]>([]);

    selectedIndices$: BehaviorSubject<number[]> = new BehaviorSubject([]);

    public get selectedIndices(): number[] {
        return this.selectedIndices$.value;
    }

    public set selectedIndices(selectedIndices: number[]) {
        this.selectedIndices$.next(selectedIndices);
    }

    searchHighlightedIndices$ = new BehaviorSubject<number[]>([]);
    searchHighlightedIndex$ = new BehaviorSubject<number>(-1);
    disableReplaceBecauseOnlyMultipleWordsFound$ = new BehaviorSubject(false);
    // Whenever update is changed a frame gets drawn / current positions are changed
    subtitleClips$ = new BehaviorSubject<SubtitleClips>(null);
    currentMarkerIndex$ = new BehaviorSubject(null);
    playingWordIndex$: BehaviorSubject<number> = new BehaviorSubject(-1);
    jumpedToIndex$: BehaviorSubject<number> = new BehaviorSubject(-1);
    currentSpeakerIndex$: BehaviorSubject<number> = new BehaviorSubject(0);

    currentSelectionType$ = new BehaviorSubject(SelectionType.WORD);
    selectedWordsRemoved$: Subject<Array<number>> = new Subject();
    allowVoiceTranslation$ = new BehaviorSubject(true);
    editingMode$: BehaviorSubject<EditingMode> = new BehaviorSubject('video');
    textPreviewTemplates: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    private openSpeakerMenu = new Subject<number>();
    speakerMenuState$ = this.openSpeakerMenu.asObservable();
    activeSpeakerIndex = 1;
    defaultSpeakers = defaultSpeakers;
    speakerAmount: number;

    isUploadingVideo = false;

    transcriptionState$: BehaviorSubject<TranscriptionState> = new BehaviorSubject(null);
    private transcriptionStateSubscription: Subscription;
    private completeTranscriptionSubscription: Subscription;

    private removePausesOrFillerWordsToast: DialogRef<boolean>;

    private backgroundColorSubscription: Subscription;

    currentlyScrolling$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public get currentlyScrolling(): boolean {
        return this.currentlyScrolling$.value;
    }

    public set currentlyScrolling(currentlyScrolling: boolean) {
        this.currentlyScrolling$.next(currentlyScrolling);
    }

    clipEditorSetupCommand: WordRemoveCommand;
    clipSectionsInTranscript = {};

    //unsubscribe?
    clipRenderings$ = this.currentProject$.pipe(
        filter((data) => data != null),
        switchMap(() =>
            this.projectService.getProjectObservable(this.currentProject).pipe(
                filter((fbProject) => !!fbProject?.clipConfigs),
                map((fbProject) =>
                    Object.entries(fbProject.clipConfigs)
                        .map<ClipConfig>(([clipRenderingId, renderingInformation]) => ({
                            duration: this.currentTranscription.calculateSelectionDuration(
                                renderingInformation.startEndConfig
                            ),
                            description: this.getWordsFromTranscript(renderingInformation.startEndConfig, 14, false),
                            id: clipRenderingId,
                            ...renderingInformation
                        }))
                        .sort((clipA, clipB) => clipA.creationCount - clipB.creationCount)
                )
            )
        )
    );

    selectedTextViaCursorSelectionButton = false;

    //SideMenu
    setActiveSidemenuItem$: BehaviorSubject<SideMenuOpenMenuItem> = new BehaviorSubject(null); // only use to set from upload flow
    activeSideMenuItem: SideMenuItemKey;

    //Quick Edit
    registerTranslationChangeSubscription: Subscription;
    updateTranscriptionAfterAutoEditsSubscription: Subscription; //forceUpdateTranscription when auto edits where made in quick edit menu

    constructor(
        private projectService: ProjectService,
        private storage: AngularFireStorage,
        private userService: UserService,
        private analyticsService: AnalyticsService,
        private dialogService: DialogService,
        private commandService: CommandService,
        private activatedRoute: ActivatedRoute,
        private hotkeyService: HotkeyService,
        // private renderer: Renderer2,
        private injector: Injector
    ) {}

    /////////////////////////////////////////////////////////////
    // Initialization
    ////////////////////////////////////////////////////////////
    async init(sharePage?: boolean, videoEditor?: VideoEditor) {
        console.log('--- init EditorService ---');
        this.subtitleChanged$.next(null);
        this.initialized$.next(false);
        this.videoEditor = videoEditor;

        if (!sharePage) {
            trackEditorLoad();

            // await this.setVideoSourceFromProject();
            await this.initVideoClips();
            this.initDuration();
            this.initResolution();
            this.registerVideoAddedListener();
            this.registerTranscriptionChanges();
            await this.updateTimeline(true);
            this.initTranslatedAudioClip();
            this.speakerAmount = this.getAmountOfSpeakers();
            this.canvasLoadedSubscription = this.videoEditor.canvasLoaded$.subscribe((loaded) => {
                this.canvasLoaded$.next(loaded);
            });
        }
        if (sharePage) {
            this.generateSubtitleClips();
            this.currentTranscription.calculateTimeInEdit();
        }
        await this.initUiScroll();
        if (this.currentProject.isExample) {
            this.checkForExampleProjectClipCuts();
        }
        this.updateSelectedParagraphs();
        this.updatePreviewTemplates();
        this.commandService.purgeCommands();

        this.initialized$.next(true);
        console.log('init EditorService done ✅');
    }

    // public setCanvasLoadedSubject(subject: Subject<boolean>) {
    //     this.canvasLoaded$ = subject;
    //     this.canvasLoaded$.pipe(take(1)).subscribe(() => this.updatePreviewTemplates());
    // }

    // Needed for First frame to be loaded
    limitedLoop(max: number, current: number) {
        if (current < max) {
            this.update$.next(true);
            setTimeout(() => {
                this.limitedLoop(max, current + 1);
            }, 500);
            // requestAnimationFrame();
        }
    }

    drawFrame() {
        this.videoPositionChanged$.next();
        this.update$.next(true);
    }

    async initVideoClips(localMediaSources: DualMediaSource = null) {
        if (!this.currentProject?.transcription) {
            return;
        }
        let mediaSources: DualMediaSource[] = [localMediaSources];
        if (!localMediaSources) {
            mediaSources = await Promise.all(
                this.currentProject.transcription.mediaUrlsWithIndex.map(({ mediaIndex, mediaUrl }) =>
                    this.createDualMediaSource(mediaUrl, mediaIndex)
                )
            );
        }
        if (!mediaSources.length) {
            return;
        }
        this.videoClips = this.currentProject.transcription.generateVideoClips(mediaSources);
        this.currentVideoClip = this.videoClips.startVideoClip;

        this.runningVideo = this.currentVideoClip.video;
        this.runningVideo.currentTime = this.currentVideoClip.startTimeInOriginal;
        this.runningVideoChanged$.next();

        this.textEditorContextMenu$.next(null);
        this.playingWordIndex = this.getFirstNonCutWord();
        this.updateNextVideoPositions();

        // Adds a loader to the preview
        this.runningVideo.onwaiting = () => {
            this.showVideoLoading$.next(true);
            this.translatedAudio?.pause();
        };
        this.runningVideo.onplaying = () => {
            this.showVideoLoading$.next(false);
            this.syncTranslatedAudioToVideoAndPlay();
        };
        this.showVideoLoading$.next(false);
        // Call once so the first frame is loaded to canvas
        this.update$.next(true);
    }

    async initLocalMediaFile(
        file: File | string,
        mediaCanvasElement?: MediaCanvasElement,
        skipInitialTranscription = false
    ) {
        let fileUrl;
        let mediaType: MediaType;
        if (file instanceof File) {
            fileUrl = URL.createObjectURL(file);
            mediaType = await MediaModel.getMediaTypeFromFile(file);
        } else {
            fileUrl = file;
            mediaType = await MediaModel.getMediaTypeFromUrl(fileUrl);
        }

        const mediaSources = await this.createDualMediaSource(fileUrl, 0);

        if (!skipInitialTranscription) {
            await this.initInitialTranscription(mediaSources.A.duration, fileUrl);
        } else if (this.currentProject.transcription.words.length === 1) {
            this.currentTranscription.words[0].endTimeInOriginal = mediaSources.A.duration;
        }
        this.videoEditor?.addLocalMediaElement(mediaSources.A, mediaType, mediaCanvasElement);
        // if (mediaSources?.A?.videoWidth && mediaSources?.A?.videoHeight) {
        this.videoEditor?.formatSelected(
            getVideoFormatByResolution({ width: mediaSources.A.videoWidth, height: mediaSources.A.videoHeight }),
            { width: mediaSources.A.videoWidth, height: mediaSources.A.videoHeight },
            true,
            true
        );
        // }
        await this.initVideoClips(mediaSources);
        await this.updateTimeline();
    }

    private async initInitialTranscription(duration: number, fileUrl: any) {
        const singlePauseWord = new Word().init(0, duration, 0, 0);
        singlePauseWord.chunkIndex = 0;
        singlePauseWord.text = Languages[this.currentProject.language].uploadingPlaceholderText;
        this.transcriptionState$.next(TranscriptionState.UPLOADING);
        this.currentProject.setTranscriptionFromWords([singlePauseWord], [{ mediaUrl: fileUrl, mediaIndex: 0 }]);
        this.currentTranscription$.next(this.currentProject.transcription);
        await this.projectService.setSingleWord(this.currentProject, singlePauseWord, 0);
        this.transcriptionChangedCompletely$.next(true);
    }

    removePlaceholderVideo() {
        this.videoEditor.canvasElements$.value
            .filter(
                (el) =>
                    (el as VideoElement).mediaUrl === environment.placeholderVideoUrl ||
                    (el as VideoElement).deleteAfterFinishedTranscription
            )
            .forEach((el) => {
                this.videoEditor.deleteElement(el.ID);
                this.runningVideo.src = '';
            });
        this.showPlaceHolderVideo$.next(null);
    }

    updateSpeakers(speaker: Speaker) {
        this.currentProject.speakers[speaker.index - 1].name = speaker.name;
        this.projectService.updateSpeakers(this.currentProject, speaker);
        this.emitCurrentProject();
    }

    getAmountOfSpeakers() {
        const onlyUnique = (value, index, self) => self.indexOf(value) === index;
        const uniqueSpeakers = this.currentTranscription.words
            .filter((word) => !word.isCut)
            .map((word) => word.speakerTag)
            .filter(onlyUnique);
        const amountUniqueSpeakers = Math.max(...uniqueSpeakers.map((value) => value), 0);

        return amountUniqueSpeakers;
    }

    async createDualMediaSource(videoUrl: string, mediaIndex: number): Promise<DualMediaSource> {
        const sourceA = document.createElement('video');
        sourceA.setAttribute('playsinline', 'true');
        sourceA.setAttribute('webkit-playsinline', 'true');
        sourceA.crossOrigin = 'Anonymous';

        sourceA.src = videoUrl;
        sourceA.innerHTML = String(mediaIndex);
        sourceA.id = 'A';

        await this.checkAndFixMedia(sourceA);

        sourceA.currentTime = 0;
        const sourceB = sourceA.cloneNode(true) as HTMLVideoElement;
        sourceB.id = 'B';
        return { A: sourceA, B: sourceB };
    }

    private async checkAndFixMedia(sourceA: HTMLVideoElement) {
        const canPlay = await this.waitForCanPlayThrough(sourceA);
        if (canPlay) {
            this.waitForSeeked(sourceA);
            this.showVideoLoading$.next(false);
        } else {
            sourceA.src = environment.placeholderVideoUrl;
            await this.waitForCanPlayThrough(sourceA);
            sourceA.currentTime = 0;
        }
    }

    createMediaSource(videoUrl: string, mediaIndex: number, onCanPlayThrough?: () => void): MediaSource {
        const sourceA = document.createElement('video');
        sourceA.setAttribute('playsinline', 'true');
        sourceA.setAttribute('webkit-playsinline', 'true');
        sourceA.crossOrigin = 'Anonymous';

        sourceA.src = videoUrl;
        sourceA.innerHTML = String(mediaIndex);
        sourceA.id = 'multilane';
        this.waitForCanPlayThrough(sourceA, onCanPlayThrough);
        this.waitForSeeked(sourceA);

        sourceA.currentTime = 0;
        return sourceA;
    }

    async initTranslatedAudioClip() {
        if (
            this.currentProject.voiceTranslationMode === VoiceTranslationMode.NONE ||
            ((!this.currentProject.voiceTranslationIds || this.currentProject.voiceTranslationIds?.length <= 0) &&
                !this.currentProject.isExample)
        ) {
            this.translatedAudio = null;
            return;
        }

        this.muteVideoForVoiceover();
        this.translatedAudio = document.createElement('audio');
        this.translatedAudio.setAttribute('playsinline', 'true');
        this.translatedAudio.setAttribute('webkit-playsinline', 'true');
        this.translatedAudio.crossOrigin = 'Anonymous';

        console.log('translated audio set from File');

        if (this.currentProject.isExample) {
            const voiceTranslationUri = `videos/CvWLBP4hYhRZM91iq8xF3sNAONJ2/27mZf4ATDcsKYunC8mbW/voice-translations/${this.currentProject.translation.language}_${this.currentProject.voiceTranslationMode}.mp3`;
            this.storage
                .ref(voiceTranslationUri)
                .getDownloadURL()
                .subscribe((downloadUrl) => {
                    this.translatedAudio.src = downloadUrl;
                });
        } else {
            this.projectService
                .getVoiceTranslationById(this.currentProject.id, this.currentProject.voiceTranslationIds[0])
                .pipe(take(1))
                .subscribe((voiceTranslationDocument) => {
                    const voiceTranslation = voiceTranslationDocument.data() as VoiceTranslation;
                    this.translatedAudio.src = voiceTranslation.voiceTranslationUrl;
                });
        }

        this.translatedAudio.load();
        await this.waitForCanPlayThrough(this.translatedAudio);

        // Adds a loader to the preview
        // this.runningVideo.onwaiting = () => {
        //     this.showVideoLoading$.next(true);
        // };
        // this.runningVideo.onplaying = () => {
        //     this.showVideoLoading$.next(false);
        // };
        // this.showVideoLoading$.next(false);
    }

    resetEditor() {
        console.log('🔄 reset editorService');
        if (this.runningVideo) {
            delete this.runningVideo;
        }
        if (this.currentProject) {
            this.currentProject.mainVideoEditorConfig = null;
        }

        this.videoClips = null;
        this.transcriptionState$.next(null);
        this.initialized$.next(false);
        this.backgroundColorSubscription?.unsubscribe();
        this.subtitleChanged$.next(null);
        this.transcriptionChangedCompletelySubscription?.unsubscribe();
        this.canvasLoadedSubscription?.unsubscribe();
        this.projectSubscription?.unsubscribe();
        if (this.registerTranslationChangeSubscription) {
            this.registerTranslationChangeSubscription.unsubscribe();
        }
        if (this.updateTranscriptionAfterAutoEditsSubscription) {
            this.updateTranscriptionAfterAutoEditsSubscription.unsubscribe();
        }
        this.currentProject = null;
        this.currentTranscription$.next(null);
        this.subtitleClips$.next(null);
        this.allowVoiceTranslation$.next(true);
        this.transcriptionStateSubscription?.unsubscribe();
        this.completeTranscriptionSubscription?.unsubscribe();
        this.chunkWordArrayResult = null;
        this.commandService.purgeCommands();
        this.videoEditor?.destroy();
        this.textEditorContextMenu$.next({ show: false });
        delete this.videoEditor;
        this.videoEditor = null;
    }

    /////////////////////////////////////////////////////////////
    // Project handling
    ////////////////////////////////////////////////////////////
    async setCurrentProjectById(
        projectId?: string,
        errorHandler?: (error: FirebaseFunctionErrorModel) => void
    ): Promise<Project> {
        if (projectId) {
            const project = await this.projectService.getProjectById(projectId, errorHandler);
            if (project.isExample) {
                this.trackExampleVideo();
            }
            this.currentProject$.next(project);

            this.updateCurrentTranscription();
            this.projectService.updateLastUsedAt(this.currentProject);
        }
        return this.currentProject;
    }

    private trackExampleVideo() {
        this.userService.user$
            .pipe(
                filter((user) => user != null),
                first(),
                timeout({ first: 5000 }),
                catchError((_) => of(null))
            )
            .subscribe((user) => {
                this.analyticsService.trackServerside(
                    AnalyticsEvents.EXAMPLE_PROJECT_LOADED,
                    user?.firebaseId || 'null',
                    {
                        authenticated: user ? true : false
                    }
                );
            });
    }

    /**
     * If the project is an exported highlight of the example project, cut the right words out
     *
     * @private
     */
    private checkForExampleProjectClipCuts() {
        const startWordIndex = parseInt(this.activatedRoute.snapshot.queryParamMap.get('startWord'), 10);
        const endWordIndex = parseInt(this.activatedRoute.snapshot.queryParamMap.get('endWord'), 10) + 1;
        const newTitle = this.activatedRoute.snapshot.queryParamMap.get('title');
        this.updateTitle(newTitle);
        if (startWordIndex) {
            const indicesToRemoveStart = Array.from(Array(startWordIndex).keys());
            this.moveMarkerIfInRemovedIndices(indicesToRemoveStart);
            new WordRemoveCommand({
                editorService: this,
                indicesToRemove: indicesToRemoveStart,
                words: this.currentTranscription.words
            }).execute();
        }
        if (endWordIndex) {
            const indicesToRemoveEnd = Array.from(
                { length: this.currentTranscription.words.length - endWordIndex },
                (_, i) => i + endWordIndex
            );
            this.moveMarkerIfInRemovedIndices(indicesToRemoveEnd);
            new WordRemoveCommand({
                editorService: this,
                indicesToRemove: indicesToRemoveEnd,
                words: this.currentTranscription.words
            }).execute();
        }
    }

    setCurrentProject(newProject: Project) {
        this.currentProject$.next(newProject);
        this.updateCurrentTranscription();
        this.updatePreviewTemplates();
        // if there is still a subscription on the current project, remove it before creating a new subscription
        // this.initProjectChangeHandler();
    }

    updateCurrentTranscription() {
        this.currentTranscription$.next(
            this.currentProject?.translation
                ? this.currentProject?.translation?.transcription || null
                : this.currentProject?.transcription || new Transcription()
        );
        this.allowVoiceTranslation$.next(
            this.currentTranscription.getEditedDuration() / 60 <= TTS_DURATION_LIMITATION_MINUTES
        );
        this.speakerAmount = this.getAmountOfSpeakers();
        this.updatePreviewTemplates();
        // this.editedVideoDuration = this.currentTranscription?.getEditedDuration() || 0;
    }

    updateProjectMembers() {
        return this.projectService.getProjectMembers(this.currentProject.id).pipe(
            tap((memberMap) => {
                this.currentProject.members = memberMap;
                this.currentProject$.next(this.currentProject);
            })
        );
    }
    registerVideoAddedListener() {
        this.projectSubscription = this.projectService
            .getProjectTranscriptionChanges(this.currentProject.id)
            .subscribe(async ([wordArray, mediaUrlsWithIndex, transcriptionMap, previousTranscriptionMap]) => {
                if (
                    transcriptionMap.filter(
                        (transcriptionMapElement) => transcriptionMapElement.mediaElementIndex !== 0
                    ).length <= 0
                ) {
                    return;
                }
                // console.log('🚀 ~ wordArray', wordArray);
                // console.log('🚀 ~ mediaUrlsWithIndex', mediaUrlsWithIndex);
                const hasNewMediaIndex = transcriptionMap.some((currentMapElement) =>
                    previousTranscriptionMap.includes(currentMapElement)
                );

                const updateFrom = previousTranscriptionMap[0]?.endIndex + 1;
                const newTranscription = new Transcription();
                newTranscription.words = wordArray;
                newTranscription.calculateTimeInEdit();
                const lastWordInPreviousTranscription =
                    this.currentProject.transcription.words[this.currentProject.transcription.words.length - 1];
                const indexInUpdatedWordArray = wordArray.findIndex((word) =>
                    word.equals(lastWordInPreviousTranscription)
                );

                if (
                    indexInUpdatedWordArray <= this.currentProject.transcription.words.length - 1 &&
                    indexInUpdatedWordArray !== -1
                ) {
                    wordArray[indexInUpdatedWordArray]?.initLinebreak();
                }
                this.currentProject.setTranscriptionFromWords(wordArray, mediaUrlsWithIndex);
                this.currentProject.setTranscriptionMap(transcriptionMap, false);
                this.updateCurrentTranscription();
                if (indexInUpdatedWordArray !== -1) {
                    this.updateWords([indexInUpdatedWordArray]);
                }

                // update media information
                // create new sources
                // generate Clips with new sources
                const newMediaUrlsWithIndex = mediaUrlsWithIndex.filter(
                    (mediaUrlWithIndex) =>
                        !this.currentProject.transcription.mediaUrlsWithIndex.some(
                            (oldMediaUrlWithIndex) => oldMediaUrlWithIndex.mediaIndex === mediaUrlWithIndex.mediaIndex
                            // &&oldMediaUrlWithIndex.mediaUrl === mediaUrlWithIndex.mediaUrl
                        )
                );
                this.currentProject.transcription.mediaUrlsWithIndex.push(...newMediaUrlsWithIndex);

                const newMediaSources = await Promise.all(
                    newMediaUrlsWithIndex.map(({ mediaIndex, mediaUrl }) =>
                        this.createDualMediaSource(mediaUrl, mediaIndex)
                    )
                );
                const combinedMediaSources = this.currentProject.transcription.mediaSources.concat(newMediaSources);
                this.currentProject.transcription.calculateTimeInEdit();
                this.videoClips = this.currentProject.transcription.generateVideoClips(combinedMediaSources);
                // console.log('video clips created right after upload', this.videoClips);

                this.transcriptionChangedCompletely$.next(true);
                this.transcriptionState$.next(TranscriptionState.COMPLETED);
                this.updatePreviewTemplates();
            });
    }

    registerTranscriptionChanges() {
        this.transcriptionStateSubscription = this.projectService
            .getProjectDocumentReference(this.currentProject.id)
            .valueChanges()
            .pipe(
                map((project) => project?.transcriptionState),
                filter((state) => !!state),
                distinctUntilChanged(),
                takeWhile((state) => state !== TranscriptionState.COMPLETED, true)
            )
            .subscribe((state) => {
                this.transcriptionState$.next(state);
            });

        this.completeTranscriptionSubscription = this.transcriptionState$
            .pipe(
                filter((state) => !!state),
                takeWhile((state) => state !== TranscriptionState.COMPLETED, true),
                pairwise(),
                filter(([previousState, currentState]) => {
                    if (
                        previousState !== TranscriptionState.COMPLETED &&
                        currentState === TranscriptionState.COMPLETED
                    ) {
                        return true;
                    }
                    return false;
                }),
                take(1)
            )
            .subscribe(() => {
                this.forceUpdateLocalTranscriptionOnce();
            });
    }

    forceUpdateLocalTranscriptionOnce() {
        this.projectService
            .getProjectTranscription(this.currentProject.id)
            .pipe(take(1))
            .subscribe(
                ([
                    words,
                    mediaUrlsWithIndex,
                    currentTranscriptionMap,
                    previousTranscriptionMap,
                    currentMediaElements
                ]) => {
                    console.log('Force update local transcription ');

                    this.updateLocalTranscription(
                        words,
                        mediaUrlsWithIndex,
                        currentTranscriptionMap,
                        true
                        // previousTranscriptionMap,
                        // currentMediaElements
                    );
                    this.updateLocalCanvasElementStartEndMarker();
                }
            );
    }

    updateLocalTranscription(
        wordArray: Word[],
        mediaUrlsWithIndex: MediaUrlWithIndex[],
        transcriptionMap: TranscriptionMap,
        forceUpdateWholeTranscription = false
        // previousWordArray: Word[],
        // previousMediaUrlsWithIndex: MediaUrlWithIndex[],
        // previousTranscriptionMap: TranscriptionMap,
        // currentMediaElements: MediaCanvasElement[]
    ) {
        if (!mediaUrlsWithIndex.length) {
            return;
        }
        let updateFrom: number;
        if (this.currentProject.transcription?.words?.length === 1) {
            updateFrom = 0;
        } else {
            updateFrom = this.currentProject.transcription?.words?.length || 0;
        }

        if (forceUpdateWholeTranscription) {
            updateFrom = 0;
        }

        if (updateFrom === 0) {
            // this.currentProject.transcription.words = wordArray;
            this.currentProject.setTranscriptionFromWords(wordArray, mediaUrlsWithIndex);

            setTimeout(async () => {
                if (mediaUrlsWithIndex[0]) {
                    this.pause();
                    const newMediaSource = await this.createDualMediaSource(
                        mediaUrlsWithIndex[0].mediaUrl,
                        mediaUrlsWithIndex[0].mediaIndex
                    );

                    this.initVideoClips(newMediaSource);
                }
            });
            this.transcriptionChangedCompletely$.next(true);
        } else {
            // console.log('🚀 ~ wordArray:', wordArray);
            const newWords = wordArray.slice(updateFrom);
            // console.log(
            //     '🚀 ~ newWords:',
            //     newWords.map((word) => `${word.text} -  ${word.speakerTag} - ${word.hasLinebreak}`)
            // );
            const chunkWordArrayLength = this.chunkWordArrayResult?.length || 0;
            this.chunkWordArrayResult = this.chunkWordArray(wordArray, true);
            // console.log('🚀 ~ this.chunkWordArrayResult:', this.chunkWordArrayResult);
            const chunkedNew = this.chunkWordArrayResult.slice(chunkWordArrayLength);
            // console.log('🚀 ~ chunkedNew:', chunkedNew);
            this.datasource$.value?.adapter.relax();
            this.datasource$.value?.adapter.append(chunkedNew);

            this.currentProject.transcription.words.push(...newWords);
        }
        this.updatePreviewTemplates();
        this.updateSelectedParagraphs();
        this.currentProject.setTranscriptionMap(transcriptionMap, false);
        this.updateTimeline();
        this.emitCurrentProject();
        // this.reloadUiScroll();
        // this.transcriptionChangedCompletely$.next(true);
    }

    async updateLocalCanvasElementStartEndMarker() {
        this.projectService.getCanvasElements(this.currentProject.id).subscribe((canvasElements) => {
            for (const image of canvasElements.image) {
                this.videoEditor.changeCanvasStartEnd(image.ID, 'end', image.endWord, false, true);
            }
            for (const text of canvasElements.text) {
                this.videoEditor.changeCanvasStartEnd(text.ID, 'end', text.endWord, false, true);
            }
            for (const shape of canvasElements.shape) {
                this.videoEditor.changeCanvasStartEnd(shape.ID, 'end', shape.endWord, false, true);
            }
            for (const progress of canvasElements.progress) {
                this.videoEditor.changeCanvasStartEnd(progress.ID, 'end', progress.endWord, false, true);
            }
        });
    }

    private async initUiScroll() {
        this.datasource$.next(
            new Datasource({
                get: (index, count, success) => {
                    this.showTextLoading$.next(true);
                    setTimeout(() => {
                        const subChunkArray = this.chunkWordArrayResult?.slice(index, index + count);
                        success(subChunkArray);
                    }, 50);
                },
                settings: {
                    startIndex: 0,
                    adapter: true,
                    minIndex: 0,
                    infinite: false
                }
            })
        );
        await this.datasource$
            .getValue()
            .adapter.isLoading$.pipe(debounceTime(10))
            .subscribe((loading) => {
                this.showTextLoading$.next(loading);
            });

        this.transcriptionChangedCompletelySubscription = this.transcriptionChangedCompletely$.subscribe(() => {
            this.updateChunkWordArray();
        });

        const chunkWordArrayResult = await firstValueFrom(this.getChunkWordArrayResult());
        this.datasource$.getValue().settings.maxIndex = chunkWordArrayResult.length;
        this.datasource$.getValue().settings.bufferSize = chunkWordArrayResult.length;
    }

    async reloadUiScroll() {
        this.transcriptionVisible.next(false);
        await this.datasource$.getValue().adapter.relax();
        const chunkWordArrayResult = await firstValueFrom(this.getChunkWordArrayResult());
        this.datasource$.getValue().settings.maxIndex = chunkWordArrayResult.length;
        this.datasource$.getValue().settings.bufferSize = chunkWordArrayResult.length;
        this.datasource$.getValue().adapter.reload();
        this.transcriptionVisible.next(true);
    }

    async updateChunkWordArray(reloadUiScroll = false) {
        const words = this.currentTranscription.words;
        const updateDatasourceAndChunks =
            reloadUiScroll || this.transcriptionChangedCompletely$.getValue() || !this.chunkWordArrayResult;
        this.chunkWordArrayResult = this.chunkWordArray(words, updateDatasourceAndChunks);
        // if at some point the whole transcript has changed, we need to trigger a full reload of the virtual scroll here
        // we also reset that transcriptionChangedCompletely$ variable so the next time when e.g. just a
        // word changes it doesn't reload the whole virtual-scroll container
        if (updateDatasourceAndChunks) {
            await this.reloadUiScroll();
            this.transcriptionChangedCompletely$.next(false);
            this.datasource$.getValue().adapter.reload().then();
        }
        // console.timeEnd('replace words');
    }

    getChunkWordArrayResult(): Observable<WordIndex[][]> {
        return new Observable<WordIndex[][]>((observer) => {
            const checkInterval = setInterval(() => {
                if (this.chunkWordArrayResult !== undefined) {
                    clearInterval(checkInterval);
                    observer.next(this.chunkWordArrayResult);
                    observer.complete();
                }
            }, 100);
        });
    }

    private chunkWordArray(words: Word[], changedCompletely = true): WordIndex[][] {
        // console.time('🔪 chunkWordArray');
        let wordIndexChunkArray: WordIndex[][] = [];
        if (changedCompletely) {
            let chunkElementCount = 0;
            let singleChunk: WordIndex[] = [];
            for (let wordIndex = 0; wordIndex < words.length; wordIndex++) {
                const currentWord = words[wordIndex];
                singleChunk.push({ index: wordIndex, word: currentWord });

                // End of sentence and next sentence starts with new speaker
                // or maxChunkSize is reached or sentence ended 10 words before maxChunkSize
                const previousAndNextWordAreCut = words[wordIndex - 1]?.isCut && words[wordIndex + 1]?.isCut;
                if (
                    (!previousAndNextWordAreCut && currentWord.hasLinebreak) ||
                    (chunkElementCount >= this.CHUNK_SIZE - 100 && currentWord.isEndOfSentence) ||
                    chunkElementCount >= this.CHUNK_SIZE
                ) {
                    wordIndexChunkArray.push(singleChunk);
                    chunkElementCount = 0;
                    singleChunk = [];
                }
                if (!currentWord.isPause) {
                    chunkElementCount++;
                }
            }
            wordIndexChunkArray.push(singleChunk);
        } else {
            wordIndexChunkArray = this.chunkWordArrayResult;
        }

        // console.timeEnd('🔪 chunkWordArray');
        wordIndexChunkArray = wordIndexChunkArray.filter((singleChunk) => singleChunk.length !== 0);
        return wordIndexChunkArray;
    }

    /**
     * If a chunk contains only pauses or cuts, merge it with the one before
     */
    // mergePausesAndCutChunks() {
    //     if (!this.chunkWordArrayResult) {
    //         return;
    //     }
    //     for (let i = this.chunkWordArrayResult.length - 1; i > 0; i--) {
    //         if (this.chunkWordArrayResult[i].every((word) => word.word.isCut || word.word.isPause)) {
    //             const lastTwoItems = this.chunkWordArrayResult[i - 1].concat(this.chunkWordArrayResult[i]);
    //             this.chunkWordArrayResult.splice(i - 1, 2, lastTwoItems);
    //             this.datasource$.getValue().adapter.relax()?.then();
    //             this.datasource$
    //                 .getValue()
    //                 .adapter.replace({
    //                     predicate: ({ $index }) => $index === i - 1 || $index === i,
    //                     items: [lastTwoItems]
    //                 })
    //                 ?.then();
    //         }
    //     }
    // }

    updateWords(wordIndices: number[], skipUpdate = false) {
        const wordIndexPairs: WordIndex[] = [];
        // console.time('⏱ creating wordIndices took');
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < wordIndices.length; i++) {
            const wordIndex = wordIndices[i];
            const indexInChunk = this.currentTranscription.getIndexInChunk(
                wordIndex,
                this.currentProject.transcriptionMap
            );
            const updatedWord = this.currentTranscription.words[wordIndex];
            if (updatedWord) {
                wordIndexPairs.push({ word: updatedWord, index: indexInChunk });
            }
        }
        // console.timeEnd('⏱ creating wordIndices took');
        this.updateTimeline();
        if (
            this.currentProject.voiceTranslationMode === VoiceTranslationMode.MALE ||
            this.currentProject.voiceTranslationMode === VoiceTranslationMode.FEMALE
        ) {
            this.transcriptChangedAfterVoiceAdded$.next(true);
        }

        if (!skipUpdate) {
            this.projectService.updateWords(
                this.currentProject,
                wordIndexPairs,
                this.currentTranscription.isTranslation
            );
        }
        this.updatePreviewTemplates(wordIndices[0], wordIndices[wordIndices.length - 1]);
    }

    async addTranslation(translatedTranscription: Transcription, language: string, translationMap: TranscriptionMap) {
        this.currentProject.translation = { transcription: translatedTranscription, language };

        const newTranslationMap = translationMap;
        this.currentProject.setTranscriptionMap(newTranslationMap, true);
        this.currentProject.translationLanguage = language;
        this.updateCurrentTranscription();
        this.updateTimeline();

        this.currentProject.setTranscriptionMap(translationMap, true);
        this.projectService.updateTranslationLanguage(this.currentProject, language);
        this.allowVoiceTranslation$.next(
            this.currentTranscription.getEditedDuration() / 60 <= TTS_DURATION_LIMITATION_MINUTES
        );
        this.transcriptChangedAfterVoiceAdded$.next(false);
        this.transcriptionChangedCompletely$.next(true);
        this.emitCurrentProject();
        // this.updateWords(...arrayFromRange(0, translatedTranscription.words.length - 1));
    }

    removeTranslation() {
        this.currentProject.translation = null;
        this.currentProject.translationMap = null;
        this.removeTranslatedAudio();
        this.updateVoiceTranslationMode(VoiceTranslationMode.NONE);
        this.updateCurrentTranscription();
        this.updateTimeline();
        if (!this.currentProject.isExample) {
            this.projectService.removeTranslation(this.currentProject).then();
        }
        this.transcriptChangedAfterVoiceAdded$.next(false);
        this.transcriptionChangedCompletely$.next(true);
    }

    /**
     * Calculates the ssml characters based in the transcription. ssml will be generated in cloud function.
     */
    calculateSsmlCharactersFromTranscription() {
        if (!this.currentProject.translation || !this.currentProject.translation?.transcription) {
            return 0;
        }
        const translatedTranscription = new Transcription().clone(this.currentProject.translation.transcription);
        translatedTranscription.words = translatedTranscription.words.filter((word) => !word.isCut);

        const staticCharacters = 12 + 14; // '<speak><seq>' + '</seq></speak>';
        const staticCharactersPerSentence = 19 + 22; // '<media><s><prosody>' + '</prosody></s></media>' (end time for media and rate time for prosody is not in here
        let variableCharacters = 0;

        // This loop is more or less the same like in the cloud function startVoiceTranslation.ts -> makeTranscriptionToSsml
        for (const words of translatedTranscription.getSentencesArray()) {
            if (
                words.filter((word) => word.isCut || word.isPause || word.text.trim().length === 0).length ===
                words.length
            ) {
                continue;
            }

            const length = words[words.length - 1].endTimeInEdit - words[0].startTimeInEdit;
            const endTimeLength = length < 0.3 ? 0 : 7 + length.toFixed(2).length; // Either empty or end="s" + the seconds in the tag

            const wordSpeedRate = 11; // Sometimes 0 but in 90% of the time its 11 so it makes no sense to calculate this.

            variableCharacters += staticCharactersPerSentence + endTimeLength + wordSpeedRate;
            for (let i = 0; i < words.length; i++) {
                if (words[i].isPause) {
                    continue;
                }
                variableCharacters += i === words.length - 1 ? words[i].text.length : words[i].text.length + 1;
            }
        }
        return staticCharacters + variableCharacters;
    }

    emitCurrentProject() {
        this.currentProject$.next(this.currentProject);
    }

    updateLanguage(updatedLanguage: Language) {
        this.currentProject.language = updatedLanguage.codeLong;
        this.updateTimeline();
        this.projectService.updateLanguage(this.currentProject, updatedLanguage);
    }

    updateSubtitleMode(updatedSubtitleMode: SubtitleMode, clipId?: string, skipJumpToNextWord = false) {
        if (!clipId) {
            this.videoEditor.changeSubtitleMode(updatedSubtitleMode);
        } else {
            this.currentProject.clipConfigs[clipId].videoEditorConfig.canvasElements.subtitle.subtitleMode =
                updatedSubtitleMode;
        }
        if (
            !skipJumpToNextWord &&
            updatedSubtitleMode !== SubtitleMode.disabled &&
            this.currentTranscription.words[this.playingWordIndex]?.isPause
        ) {
            this.jumpToNextWordByIndex();
        }
        // Must be triggered to update subtitle settings component
        this.emitCurrentProject();
        this.updateTimeline(false, clipId);
        this.projectService.updateSubtitleMode(this.currentProject, updatedSubtitleMode, clipId);
        this.updatePreviewTemplates();
    }
    jumpToNextWordByIndex() {
        const newIndex = this.currentTranscription.getNextWordByIndex(this.playingWordIndex, true, true)?.index ?? -1;
        if (newIndex !== -1) {
            this.SelectedWordIndex = newIndex;
            this.playingWordIndex = newIndex;
            this.removeSelection();
        }
    }
    updateShowSubtitleBreaks(show) {
        this.currentProject.showSubtitleBreaks = show;
        this.updatePreviewTemplates();
        this.projectService.updateShowSubtitleBreaks(this.currentProject, show);
    }

    updateFixedSubtitleBreaks(fixedSubtitleLength: number, clipId?: string) {
        if (!clipId) {
            this.currentProject.mainVideoEditorConfig.canvasElements.subtitle.subtitleLength = fixedSubtitleLength;
        }
        this.updatePreviewTemplates();
        this.projectService.updateFixedSubtitleLength(this.currentProject, fixedSubtitleLength, clipId);
    }

    updateTitle(updatedTitle: string, skipUpdate = false) {
        if (updatedTitle) {
            this.currentProject.title = updatedTitle;
            if (!skipUpdate) {
                this.projectService.updateTitle(this.currentProject, updatedTitle);
            }
        }
    }

    updateVisibility(newVisibility: Visibility) {
        this.currentProject.visibility = newVisibility;
        this.projectService.updateVisibility(this.currentProject, newVisibility);
    }

    updateSharingTitle(newTitle: string) {
        this.currentProject.sharingTitle = newTitle;
        this.projectService.updateSharingTitle(this.currentProject, newTitle);
    }

    updateVoiceTranslationMode(voiceTranslationMode: VoiceTranslationMode) {
        this.currentProject.voiceTranslationMode = voiceTranslationMode;
        this.pause();
        if (voiceTranslationMode === VoiceTranslationMode.NONE) {
            this.currentProject.voiceTranslationIds = [];
            this.removeTranslatedAudio();
        } else {
            this.emitCurrentProject();
        }
    }

    updateVoiceTranslationState(voiceTranslationState: VoiceTranslationState) {
        if (!this.currentProject.isExample) {
            this.currentProject.voiceTranslationState = voiceTranslationState;
            this.emitCurrentProject();
        }
    }

    updateProjectResolution(width: number, height: number, clipId?: string) {
        const activeVideoEditorConfig = this.getActiveVideoEditorConfig(clipId);
        if (activeVideoEditorConfig.resolution) {
            activeVideoEditorConfig.resolution.width = width;
            activeVideoEditorConfig.resolution.height = height;
        } else {
            activeVideoEditorConfig.resolution = { width, height };
        }
        this.projectService.updateProjectResolution(
            this.currentProject,
            { ...activeVideoEditorConfig.resolution },
            clipId
        );
        this.generateSubtitleClips(clipId);
    }

    addTextCanvasElement(textElement: FrontendTextElement, clipId?: string) {
        const activeVideoEditorConfig = this.getActiveVideoEditorConfig(clipId);
        const textEl: TextCanvasElement = this.getTextCanvasElementAttributes(textElement);
        if (
            activeVideoEditorConfig.canvasElements.text.some(
                (currentTextElement) => currentTextElement.ID === textEl.ID
            )
        ) {
            return;
        }
        activeVideoEditorConfig.canvasElements.text.push(textEl);
        this.updatePreviewTemplates(textElement.startWord, textElement.endWord);
        this.projectService.updateCanvasElements(this.currentProject, activeVideoEditorConfig.canvasElements, clipId);
    }
    addProgressCanvasElement(progressElement: ProgressElement, clipId?: string) {
        console.log('➡️ ~ asdsadsadsadsa:', clipId);
        const activeVideoEditorConfig = this.getActiveVideoEditorConfig(clipId);
        const progressEl: ProgressCanvasElement = this.getProgressElementAttributes(progressElement);
        activeVideoEditorConfig.canvasElements.progress.push(progressEl);
        this.updatePreviewTemplates(progressElement.startWord, progressElement.endWord);
        this.projectService.updateCanvasElements(this.currentProject, activeVideoEditorConfig.canvasElements, clipId);
    }

    addMediaCanvasElement(videoElement: VideoElement, clipId?: string) {
        console.log('➡️ ~ dsadsadsadsdsadsa:', clipId);
        const activeVideoEditorConfig = this.getActiveVideoEditorConfig(clipId);
        const mediaEl: MediaCanvasElement = this.getMediaElAttributes(videoElement);
        activeVideoEditorConfig.canvasElements.media.push(mediaEl);
        // console.log('🚀 ~ mediaEl', mediaEl);
        this.projectService.updateCanvasElements(this.currentProject, activeVideoEditorConfig.canvasElements, clipId);
    }

    updateTemplateId(templateId: string) {
        this.currentProject.templateId = templateId;
        this.projectService.updateProject(this.currentProject, { templateId });
    }

    /**
     * Shows a hint at the bottom of the screen how to use the markers (only if the hints haven't been showed before)
     */
    showMarkerHints() {
        if (
            this.activeSideMenuItem !== 'templates' &&
            !this.userService.getUserUiState('editor', 'hints', 'markerShown') &&
            !this._markerTutorialOpened
        ) {
            this._markerTutorialOpened = true;
            this.dialogService
                .openTutorial({ data: { videoUri: '/assets/videos/marker-tutorial.webm' } })
                .afterClosed()
                .pipe(switchMap(() => this.userService.updateUserUiState(true, 'editor', 'hints', 'markerShown')))
                ?.subscribe();
        } else {
        }
    }

    showCutHints() {
        if (
            this.activeSideMenuItem !== 'templates' &&
            !this.userService.getUserUiState('editor', 'hints', 'cutHintShown') &&
            !this._cutTutorialOpened
        ) {
            this._cutTutorialOpened = true;
            this.dialogService
                .openTutorial({ data: { videoUri: '/assets/videos/cut-tutorial.webm' } })
                .afterClosed()
                .pipe(switchMap(() => this.userService.updateUserUiState(true, 'editor', 'hints', 'cutHintShown')))
                ?.subscribe();
        }
    }

    addImageCanvasElement(imageElement: ImageElement, clipId?: string) {
        const activeVideoEditorConfig = this.getActiveVideoEditorConfig(clipId);
        const imageEl: ImageCanvasElement = this.getImageElAttributes(imageElement);
        if (
            activeVideoEditorConfig.canvasElements.image.some(
                (currentImageElement) => currentImageElement.ID === imageEl.ID
            )
        ) {
            return;
        }
        activeVideoEditorConfig.canvasElements.image.push(imageEl);
        this.updatePreviewTemplates(imageElement.startWord, imageElement.endWord);
        this.projectService.updateCanvasElements(this.currentProject, activeVideoEditorConfig.canvasElements, clipId);
    }

    addShapeCanvasElement(shapeElement: ShapeElement, clipId?: string) {
        const activeVideoEditorConfig = this.getActiveVideoEditorConfig(clipId);
        const shapeEl: ShapeCanvasElement = this.getShapeElAttributes(shapeElement);
        if (
            activeVideoEditorConfig.canvasElements.shape.some(
                (currentShapeElement) => currentShapeElement.ID === shapeEl.ID
            )
        ) {
            return;
        }
        activeVideoEditorConfig.canvasElements.shape.push(shapeEl);
        this.updatePreviewTemplates(shapeElement.startWord, shapeElement.endWord);
        this.projectService.updateCanvasElements(this.currentProject, activeVideoEditorConfig.canvasElements, clipId);
    }

    async deleteCanvasElement(id: string, type: CanvasElementType, clipId?: string) {
        const activeVideoEditorConfig = this.getActiveVideoEditorConfig(clipId);
        let elementsArray;
        switch (type) {
            case CanvasElementType.TEXT:
                elementsArray = activeVideoEditorConfig.canvasElements.text;
                break;
            case CanvasElementType.SUBTITLE:
                //can't delete subtitles
                elementsArray = [];
                break;
            case CanvasElementType.IMAGE:
                elementsArray = activeVideoEditorConfig.canvasElements.image;
                break;
            case CanvasElementType.SHAPE:
                elementsArray = activeVideoEditorConfig.canvasElements.shape;
                break;
            case CanvasElementType.PROGRESS:
                elementsArray = activeVideoEditorConfig.canvasElements.progress;
                break;
            case CanvasElementType.MEDIA:
            case CanvasElementType.MULTILANEMEDIA:
                elementsArray = activeVideoEditorConfig.canvasElements.media;
                break;
        }
        const index = elementsArray.findIndex((el) => el.ID === id);
        if (index >= 0) {
            elementsArray.splice(index, 1);
            // somehow elementsArray is not a reference and will not automatically mutate canvasElements - so we do it manually
            switch (type) {
                case CanvasElementType.TEXT:
                    activeVideoEditorConfig.canvasElements = {
                        ...activeVideoEditorConfig.canvasElements,
                        text: elementsArray
                    };
                    break;
                case CanvasElementType.IMAGE:
                    activeVideoEditorConfig.canvasElements = {
                        ...activeVideoEditorConfig.canvasElements,
                        image: elementsArray
                    };
                    break;
                case CanvasElementType.SHAPE:
                    activeVideoEditorConfig.canvasElements = {
                        ...activeVideoEditorConfig.canvasElements,
                        shape: elementsArray
                    };
                    break;
                case CanvasElementType.PROGRESS:
                    activeVideoEditorConfig.canvasElements = {
                        ...activeVideoEditorConfig.canvasElements,
                        progress: elementsArray
                    };
                    break;
                case CanvasElementType.MULTILANEMEDIA:
                case CanvasElementType.MEDIA:
                    activeVideoEditorConfig.canvasElements = {
                        ...activeVideoEditorConfig.canvasElements,
                        media: elementsArray
                    };
                    break;
            }
            this.currentSelectionType$.next(SelectionType.WORD);
        }
        this.updatePreviewTemplates();
        this.projectService.updateCanvasElements(this.currentProject, activeVideoEditorConfig.canvasElements, clipId);
    }
    updateMediaCanvasElement(videoElement: VideoElement, clipId?: string) {
        if (videoElement?.ID && videoElement?.konvaElement) {
            const activeVideoEditorConfig = this.getActiveVideoEditorConfig(clipId);
            const mediaElements = [...activeVideoEditorConfig.canvasElements.media];
            if (mediaElements.length) {
                activeVideoEditorConfig.canvasElements.media = mediaElements.map((el) => {
                    if (
                        (el.ID !== videoElement.ID && el.ID !== videoElement.konvaElID) ||
                        el.ID === 'video' ||
                        videoElement.ID === 'video'
                    ) {
                        return el;
                    } else {
                        // console.log('UPDATE MEDIA', videoElement.konvaElID, this.getMediaElAttributes(videoElement));
                        return this.getMediaElAttributes(videoElement);
                    }
                });
                this.projectService.updateCanvasElements(
                    this.currentProject,
                    activeVideoEditorConfig.canvasElements,
                    clipId
                );
            }
        }
    }

    getActiveVideoEditorConfig(clipId?: string): VideoEditorConfig {
        if (clipId) {
            if (!this.currentProject.clipConfigs) {
                this.currentProject.clipConfigs = {};
            }
            if (
                !this.currentProject.clipConfigs?.[clipId]?.videoEditorConfig?.resolution &&
                !this.currentProject.clipConfigs?.[clipId]?.videoEditorConfig?.canvasElements
            ) {
                const latestClipsConfig = this.getLatestClipsConfig(this.currentProject.clipConfigs);
                if (
                    latestClipsConfig?.videoEditorConfig?.resolution &&
                    latestClipsConfig?.videoEditorConfig?.canvasElements
                ) {
                    this.currentProject.clipConfigs[clipId] = {
                        videoEditorConfig: deepCopy(latestClipsConfig.videoEditorConfig),
                        speakerConfig: latestClipsConfig.speakerConfig
                    };
                } else {
                    this.currentProject.clipConfigs[clipId] = {
                        videoEditorConfig: deepCopy(this.currentProject.mainVideoEditorConfig),
                        speakerConfig: {}
                    };
                }
                this.currentProject.clipConfigs[clipId].videoEditorConfig.canvasElements.subtitle.subtitleLength = null;
            }
            return this.currentProject.clipConfigs?.[clipId]?.videoEditorConfig;
        } else {
            return this.currentProject.mainVideoEditorConfig;
        }
    }

    getLatestClipsConfig(configs: { [clipId: string]: ClipConfig }): ClipConfig | undefined {
        let latestClipsConfig;
        let tempConfigArray = Object.keys(configs).map((key) => {
            return configs[key];
        });

        const tempConfigArray2 = tempConfigArray.filter((item) => !!item.creationCount);

        if (tempConfigArray2?.length > 0) {
            latestClipsConfig = tempConfigArray2?.reduce((prevConf, currConf) =>
                prevConf.creationCount > currConf.creationCount ? prevConf : currConf
            );
        } else {
            latestClipsConfig = undefined;
        }

        return latestClipsConfig;
    }
    updateTextCanvasElement(textElement: FrontendTextElement | TextElement, clipId?: string) {
        if (textElement?.ID) {
            const activeVideoEditorConfig = this.getActiveVideoEditorConfig(clipId);
            const textElements = [...activeVideoEditorConfig.canvasElements.text];
            activeVideoEditorConfig.canvasElements.text = textElements.map((el) => {
                if (el.ID !== textElement.ID) {
                    return el;
                } else {
                    return this.getTextCanvasElementAttributes(textElement);
                }
            });
            this.updatePreviewTemplates(textElement.startWord, textElement.endWord);
            this.projectService.updateCanvasElements(
                this.currentProject,
                activeVideoEditorConfig.canvasElements,
                clipId
            );
        }
    }

    updateSubtitleCanvasElement(subtitleElement: SubtitleElement, clipId?: string) {
        const activeVideoEditorConfig = this.getActiveVideoEditorConfig(clipId);
        activeVideoEditorConfig.canvasElements.subtitle = this.getSubtitleCanvasElementAttributes(subtitleElement);
        this.projectService.updateCanvasElements(this.currentProject, activeVideoEditorConfig.canvasElements, clipId);
        if (!clipId) {
            this.userService.updateUserUiState(
                {
                    bgColor: subtitleElement.config.bgColor || null,
                    bg: subtitleElement.config.bg || null,
                    konvaElement: subtitleElement.getAsJSONstring()
                },
                'editor',
                'subtitles',
                'lastAppliedStyles'
            );
        }
        this.emitCurrentProject();
    }

    updateImageCanvasElement(imageElement: ImageElement, clipId?: string) {
        if (imageElement?.ID) {
            const activeVideoEditorConfig = this.getActiveVideoEditorConfig(clipId);
            const imageElements = [...activeVideoEditorConfig.canvasElements.image];
            activeVideoEditorConfig.canvasElements.image = imageElements.map((el) => {
                if (el.ID !== imageElement.ID) {
                    return el;
                } else {
                    return this.getImageElAttributes(imageElement);
                }
            });
            this.updatePreviewTemplates(imageElement.startWord, imageElement.endWord);
            this.projectService.updateCanvasElements(
                this.currentProject,
                activeVideoEditorConfig.canvasElements,
                clipId
            );
        }
    }

    updateShapeCanvasElement(shapeElement: ShapeElement, clipId?: string) {
        if (shapeElement?.ID) {
            const activeVideoEditorConfig = this.getActiveVideoEditorConfig(clipId);
            const shapeElements = [...activeVideoEditorConfig.canvasElements.shape];
            activeVideoEditorConfig.canvasElements.shape = shapeElements.map((el) => {
                if (el.ID !== shapeElement.ID) {
                    return el;
                } else {
                    return this.getShapeElAttributes(shapeElement);
                }
            });
            this.updatePreviewTemplates(shapeElement.startWord, shapeElement.endWord);
            this.projectService.updateCanvasElements(
                this.currentProject,
                activeVideoEditorConfig.canvasElements,
                clipId
            );
        }
    }
    updateProgressCanvasElement(progressElement: ProgressElement, clipId?: string) {
        if (progressElement?.ID) {
            const activeVideoEditorConfig = this.getActiveVideoEditorConfig(clipId);
            const progressElements = [...activeVideoEditorConfig.canvasElements.progress];
            activeVideoEditorConfig.canvasElements.progress = progressElements.map((el) => {
                if (el.ID !== progressElement.ID) {
                    return el;
                } else {
                    return this.getProgressElementAttributes(progressElement);
                }
            });
            this.updatePreviewTemplates(progressElement.startWord, progressElement.endWord);

            this.projectService.updateCanvasElements(
                this.currentProject,
                activeVideoEditorConfig.canvasElements,
                clipId
            );
        }
    }

    updateBackgroundColor(colorCommand: { color: string; saveChanges: boolean }, clipId?: string) {
        this.currentProject.mainVideoEditorConfig.backgroundColor = colorCommand.color;
        if (colorCommand.saveChanges) {
            this.projectService.updateBackgroundColor(this.currentProject, colorCommand.color, clipId);
        }
    }

    removeRetainedMediaDetails() {
        this.currentProject.retainedMediaDetails = null;
        this.emitCurrentProject();
    }

    //dev helper function
    updateUserTemplate() {
        // this.projectService.updateTemplate(this.currentProject.templateId, activeVideoEditorConfig.canvasElements);
    }

    getTextCanvasElementAttributes(textElement: FrontendTextElement | TextElement): TextCanvasElement {
        let textCanvasElement: TextCanvasElement = {
            ID: textElement.ID,
            startWord: textElement.startWord,
            endWord: textElement.endWord,
            konvaElement: textElement.getAsJSONstring(),
            bg: (textElement as FrontendTextElement).bg,
            zIndex: textElement.zIndex,
            bgColor: (textElement as FrontendTextElement).bgColor,
            templateId: textElement.templateId,
            animationDirection: textElement.animationDirection || null,
            animationTypes: textElement.animationTypes || null
        };

        if (textElement.encloseTranscript) {
            textCanvasElement.encloseTranscript = textElement.encloseTranscript;
        }

        return textCanvasElement;
    }
    getSubtitleCanvasElementAttributes(subtitleElement: SubtitleElement): SubtitleCanvasElement {
        let subtitleCanvasElement: SubtitleCanvasElement = {
            konvaElement: subtitleElement.getAsJSONstring(),
            templateId: subtitleElement.templateId,
            subtitleMode: subtitleElement.config.subtitleMode,
            bgColor: subtitleElement.config.bgColor,
            bg: subtitleElement.config.bg,
            subtitleLength: subtitleElement.config.subtitleLength
            // highlightStyle: subtitleElement.config.highlightStyle,
            // highlightColor: subtitleElement.config.highlightColor,
        };

        return removeEmpty(subtitleCanvasElement);
    }

    public getImageElAttributes(imageElement: ImageElement): ImageCanvasElement {
        let imageCanvasElement: ImageCanvasElement = {
            ID: imageElement.ID,
            startWord: imageElement.startWord,
            endWord: imageElement.endWord,
            konvaElement: imageElement.getAsJSONstring(),
            zIndex: imageElement.zIndex,
            imageURL: imageElement.imageURL,
            imageURI: imageElement.imageURI,
            templateId: imageElement.templateId,
            animationDirection: imageElement.animationDirection || null,
            animationTypes: imageElement.animationTypes || null
        };

        if (imageElement.encloseTranscript) {
            imageCanvasElement.encloseTranscript = imageElement.encloseTranscript;
        }

        return imageCanvasElement;
    }
    public getMediaElAttributes(videoElement: VideoElement): MediaCanvasElement {
        const attributes = {
            ID: videoElement.ID,
            startWord: videoElement.startWord,
            endWord: videoElement.endWord,
            konvaElement: videoElement.getAsJSONstring(),
            zIndex: videoElement.zIndex,
            mediaUri: videoElement.mediaUri,
            mediaUrl: videoElement.mediaUrl,
            mediaDuration: videoElement.mediaDuration,
            mediaType: videoElement.mediaType,
            resolution: { width: videoElement.resolution.width, height: videoElement.resolution.height },
            previewUrl: videoElement.previewUrl || null,
            mediaIndex: videoElement.mediaIndex ?? null,
            isMultilane: videoElement.type === CanvasElementType.MULTILANEMEDIA,
            templateId: videoElement.templateId || null,
            volume: videoElement.volume ?? null
        };
        if (videoElement.deleteAfterFinishedTranscription) {
            (attributes as any).deleteAfterFinishedTranscription =
                videoElement.deleteAfterFinishedTranscription || null;
        }
        return attributes;
    }

    public getShapeElAttributes(shapeElement: ShapeElement): ShapeCanvasElement {
        let shapeCanvasElement: ShapeCanvasElement = {
            ID: shapeElement.ID,
            startWord: shapeElement.startWord,
            endWord: shapeElement.endWord,
            konvaElement: shapeElement.getAsJSONstring(),
            zIndex: shapeElement.zIndex,
            strokeColor: shapeElement.strokeColor,
            fillColor: shapeElement.fillColor,
            shape: shapeElement.shape,
            style: shapeElement.style,
            templateId: shapeElement.templateId,
            animationDirection: shapeElement.animationDirection,
            animationTypes: shapeElement.animationTypes
        };

        if (shapeElement.encloseTranscript) {
            shapeCanvasElement.encloseTranscript = shapeElement.encloseTranscript;
        }

        return shapeCanvasElement;
    }
    public getProgressElementAttributes(progressElement: ProgressElement): ProgressCanvasElement {
        let progressCanvasElement: ProgressCanvasElement = {
            ID: progressElement.ID,
            startWord: progressElement.startWord,
            endWord: progressElement.endWord,
            zIndex: progressElement.zIndex,
            foregroundColor: progressElement.foregroundColor,
            backgroundColor: progressElement.backgroundColor,
            style: progressElement.style,
            konvaElement: progressElement.getAsJSONstring(),
            templateId: progressElement.templateId
        };

        if (progressElement.encloseTranscript) {
            progressCanvasElement.encloseTranscript = progressElement.encloseTranscript;
        }

        return progressCanvasElement;
    }

    /////////////////////////////////////////////////////////////
    // Video editing
    ////////////////////////////////////////////////////////////
    // checks if updateTimeline is running
    // if so it waits for it to be finished and then starts playing the video
    async schedulePlay() {
        if (!this.waitingToPlay) {
            this.editingMode$.next('video');
            if (this.isUpdatingTimeline$.value === true) {
                this.waitingToPlay = true;
                this.isUpdatingTimeline$
                    .pipe(
                        filter((isUpdating) => isUpdating === false),
                        first()
                    )
                    .subscribe(() => {
                        this.play();
                        this.waitingToPlay = false;
                    });
            } else {
                this.play().then();
            }
        } else {
            // play is already scheduled
            console.log('play is already scheduled');
        }
    }

    togglePlay() {
        if (this.videoIsRunning$.value) {
            this.pause();
        } else {
            this.currentSelectionType$.next(SelectionType.WORD);
            this.schedulePlay().then();
        }
    }

    play(): Promise<void> {
        if (this.translatedAudio) {
            this.syncTranslatedAudioToVideoAndPlay();
        }
        if (this.runningVideo) {
            this.removeSelection();
            this.videoIsRunning$.next(true);
            this.enableCanvasElementAnimations();
            this.loop().then();
            return this.runningVideo.play();
        }
    }

    pause() {
        if (this.translatedAudio) {
            this.translatedAudio.pause();
        }
        if (this.runningVideo) {
            this.videoIsRunning$.next(false);
            this.disableCanvasElementAnimations();
            this.runningVideo.pause();
        }
    }

    setPlaybackRate(playbackRateOption: PlaybackRate) {
        this.videoClips.forEach((clip) => {
            clip.video.playbackRate = playbackRateOption.rate;
        });
    }

    async loop() {
        if (this.videoIsRunning$.value) {
            // Update Frame
            this.update$.next(true);
            // check for jumps
            this.updateCurrentClip();
            this.updateMultilaneClip();

            // check which word is played right now.
            this.updatePlayingWordIndex();
            requestAnimationFrame(() => {
                this.loop();
            });
        }
    }

    async waitForSeeked(videoElement: HTMLVideoElement): Promise<boolean> {
        return new Promise((resolve) => {
            const handler = () => {
                videoElement.removeEventListener('seeked', handler);
                resolve(true);
            };
            videoElement.addEventListener('seeked', handler);
        });
    }

    // Waits until video is fully loaded
    // Only then duration of video is visible
    async waitForCanPlayThrough(
        htmlElement: HTMLVideoElement | HTMLAudioElement,
        onCanPlayThrough?: () => void
    ): Promise<boolean> {
        return new Promise((resolve) => {
            const handler = () => {
                htmlElement.removeEventListener('canplay', handler);
                // console.log('Can play through');

                if (onCanPlayThrough) {
                    onCanPlayThrough();
                }
                resolve(true);
            };

            htmlElement.addEventListener('canplay', handler);
            const errorHandler = () => {
                console.error("Error with videoUrl: Can't play video " + htmlElement?.src);
                htmlElement.removeEventListener('canplay', handler);
                htmlElement.removeEventListener('error', errorHandler);
                resolve(false);
            };
            htmlElement.addEventListener('error', errorHandler);
        });
    }

    /**
     * Sets the time of the current audio translation to the current playtime time of the video
     */
    syncTranslatedAudioToVideoAndPlay() {
        if (!this.translatedAudio) {
            this.unmuteVideoFromVoiceover();
            return;
        }
        console.log('Synced voiceTranslation to video');
        this.muteVideoForVoiceover();
        this.translatedAudio.currentTime = this.getCurrentPosition();
        this.translatedAudio.play().then();
    }

    removeTranslatedAudio() {
        this.translatedAudio = null;
        this.unmuteVideoFromVoiceover();
    }

    muteVideoForVoiceover() {
        if (
            this.currentProject.voiceTranslationMode === VoiceTranslationMode.MALE ||
            this.currentProject.voiceTranslationMode === VoiceTranslationMode.FEMALE
        ) {
            this.runningVideo.muted = true;
        }
    }

    unmuteVideoFromVoiceover() {
        if (this.currentProject.voiceTranslationMode === VoiceTranslationMode.NONE) {
            this.runningVideo.muted = false;
        }
    }

    initResolution() {
        // console.log('init Resolution', this.currentProject);
        if (this.currentProject.mainVideoEditorConfig.resolution) {
            this.updateProjectResolution(
                this.currentProject.mainVideoEditorConfig.resolution.width,
                this.currentProject.mainVideoEditorConfig.resolution.height
            );
        } else {
            const activeVideoEditorConfig = this.getActiveVideoEditorConfig();
            const firstMediaElement = activeVideoEditorConfig.canvasElements?.media?.[0];
            if (firstMediaElement?.mediaType === MediaType.VIDEO) {
                this.updateProjectResolution(firstMediaElement.resolution.width, firstMediaElement.resolution.height);
            } else {
                // this.updateProjectResolution(1080, 1080);
            }
        }
    }

    initDuration() {
        this.videoDuration = this.runningVideo?.duration ?? 0;
    }

    /////////////////////////////////////////////////////////////
    // Video jumping
    ////////////////////////////////////////////////////////////

    setVideoPositionToCursor() {
        if (this.currentTranscription.words.length > 0 && this.selectedWordIndex > -1) {
            // this.videoPositionChanged$.next();
            this.pause();

            const selectedWord = this.currentTranscription.words[this.selectedWordIndex];

            // gets the starttime of the selected/clicked word
            if (selectedWord) {
                this.jumpedToIndex$.next(this.selectedWordIndex);
                this.currentSpeakerIndex$.next(this.getWordByIndex(this.selectedWordIndex).speakerTag);
                const newStartTime = selectedWord.startTimeInOriginal;

                // currentClip gets
                this.currentVideoClip = this.jumpInClipTo(newStartTime, selectedWord.mediaIndex);

                this.runningVideo = this.currentVideoClip?.video;
                this.updateNextVideoPositions();
                this.runningVideoChanged$.next();

                this.pause();

                // this.limitedLoop(15, 0);
                this.drawFrame();
                this.playingWordIndex = this.selectedWordIndex;
            }
        }
    }

    /**
     * Searches for the clip that start before the given time (cursor time)
     * when the clip is found, the current time gets sets to the start time of the searched word (cursor time)
     *
     * @param cursorTime new currentTime of the video
     */
    jumpInClipTo(cursorTime: number, mediaIndex: number): VideoClip {
        let tempClip = this.videoClips.startVideoClip;

        // this.videoClips.forEach((vc) =>
        //     console.log(`👁 [${vc.mediaIndex}, ${vc.video.id}] ${vc.startTimeInOriginal} - ${vc.endTimeInOriginal}`)
        // );

        while (tempClip) {
            // console.log(
            //     `🥸 Media index ${mediaIndex} - ${tempClip.mediaIndex} \n Time: ${tempClip.startTimeInOriginal} <= ${cursorTime} <= ${tempClip.endTimeInOriginal}`
            // );

            if (
                tempClip.mediaIndex === mediaIndex &&
                cursorTime >= tempClip.startTimeInOriginal &&
                cursorTime <= tempClip.endTimeInOriginal
            ) {
                // console.log('jump to', tempClip.mediaIndex, cursorTime);
                tempClip.video.currentTime = cursorTime;
                return tempClip;
            }
            tempClip = tempClip.next;
        }
        // if nothing is found start from the beginning
        return this.videoClips.startVideoClip;
    }

    async loadVideoData(video: HTMLVideoElement) {
        return new Promise((resolve) => {
            video.onloadeddata = () => {
                console.log('loadVideoData');
                resolve(null);
            };
        });
    }

    getWordByIndex(index: number): Word {
        return this.currentTranscription.getWordByIndex(index);
        // return this.currentProject.transcription.getWordByIndex(index);
    }

    // updateProgressElement(){
    //     if(this.currentProject.canvasElements.progress.length){
    //         for (let index = 0; index < this.currentProject.canvasElements.progress.length; index++) {
    //             const progressElement = this.currentProject.canvasElements.progress[index];
    //         }
    //         this.currentProject.canvasElements.progress
    //     }
    // }
    updateCurrentClip() {
        const currentTime = this.runningVideo.currentTime;
        const currentMediaIndex = +this.runningVideo.innerHTML;
        if (this.currentVideoClip) {
            // this.updateProgressElement();
            // console.log(
            //     `🪓 [${this.currentVideoClip.video.innerHTML}]${currentTime} >= ${
            //         this.currentVideoClip.endTimeInOriginal - 0.065
            //     } or \n
            //     ${currentMediaIndex} !== ${this.currentVideoClip.mediaIndex}`
            // );

            if (
                currentTime >= this.currentVideoClip.endTimeInOriginal - 0.065 ||
                currentMediaIndex !== this.currentVideoClip.mediaIndex
            ) {
                // console.log(
                //     `🪓 [${this.currentVideoClip.video.innerHTML}]${currentTime} >= ${
                //         this.currentVideoClip.endTimeInOriginal - 0.065
                //     } or \n
                //         ${currentMediaIndex} !== ${this.currentVideoClip.mediaIndex}`
                // );
                // console.log(
                //     'currentVideoClip',
                //     '[' + this.currentVideoClip.video.innerHTML + ' - ' + this.currentVideoClip.video.id + ']'
                // );

                // console.log('before');

                // this.videoClips.forEach((vc) =>
                //     console.log(
                //         '[' +
                //             vc.video.innerHTML +
                //             ' - ' +
                //             vc.video.id +
                //             ']' +
                //             vc.startTimeInEdit +
                //             ' - ' +
                //             vc.video.currentTime +
                //             ' - ' +
                //             vc.endTimeInEdit
                //     )
                // );
                // console.log('\n');

                // if (currentMediaIndex !== this.currentVideoClip.mediaIndex)
                if (this.currentVideoClip.next != null) {
                    // console.log('🎬 switch clips');
                    // stop
                    // set next video to running
                    // set currentTime of next

                    // stop current
                    this.currentVideoClip.stop();
                    // override currentClip with next
                    this.currentVideoClip = this.currentVideoClip.next;
                    this.runningVideo = this.currentVideoClip.video;
                    this.currentVideoClip.reset();

                    this.updateNextVideoPositions();
                    this.muteVideoForVoiceover();
                    this.currentVideoClip.play();
                    this.syncTranslatedAudioToVideoAndPlay();
                } else if (this.runningVideo.paused) {
                    // Video was stopped and the end and played again - start on first videos start
                    this.videoIsRunning$.next(true);
                    this.enableCanvasElementAnimations();
                    this.currentVideoClip = this.videoClips.startVideoClip;
                    this.currentVideoClip.reset();
                    this.runningVideo = this.currentVideoClip.video;
                    this.muteVideoForVoiceover();
                    this.currentVideoClip.play();
                } else {
                    // On video ended
                    this.videoIsRunning$.next(false);
                    this.disableCanvasElementAnimations();
                    this.currentVideoClip.stop();
                    this.currentVideoClip = this.videoClips.startVideoClip;
                    this.runningVideo = this.videoClips.startVideoClip.video;
                    this.currentVideoClip.reset();
                    this.jumpToWordByIndex(this.getFirstNonCutWord());
                }
                this.runningVideoChanged$.next();
                // console.log('after');
                // this.videoClips.forEach((vc) =>
                //     console.log(
                //         '[' +
                //             vc.video.innerHTML +
                //             ' - ' +
                //             vc.video.id +
                //             ']' +
                //             vc.startTimeInEdit +
                //             ' - ' +
                //             vc.video.currentTime +
                //             ' - ' +
                //             vc.endTimeInEdit
                //     )
                // );
                // console.log('\n');
            }
        }
    }

    updateMultilaneClip() {
        const currentTime = this.runningVideo.currentTime;
        const currentMediaIndex = +this.runningVideo.innerHTML;
        const multilaneElements = this.videoEditor.canvasElements$.value?.filter(
            (el) => el.type === CanvasElementType.MULTILANEMEDIA
        ) as MultilaneVideoElement[];
        multilaneElements.forEach((el) => {
            const startWord = this.currentProject.transcription.words[el.startWord];
            const endWord = this.currentProject.transcription.words[el.endWord];

            //start & end same media index
            //currentTime is between start and end
            // console.log(
            //     'multilane',
            //     '[' + startWord.mediaIndex + ' - ' + currentMediaIndex + ' - ' + endWord.mediaIndex + ']'
            // );
            //end is in next media index
            if (
                // start and end are in the same audio/video
                (startWord.mediaIndex === currentMediaIndex &&
                    endWord.mediaIndex === currentMediaIndex &&
                    currentTime - 0.065 >= startWord.startTimeInOriginal &&
                    currentTime < endWord.endTimeInOriginal) ||
                // end is in the following audio/video file
                (endWord.mediaIndex !== startWord.mediaIndex &&
                    // currentWord is in the first files
                    ((startWord.mediaIndex === currentMediaIndex &&
                        currentTime - 0.065 >= startWord.startTimeInOriginal) ||
                        //currentWord is in the second file
                        (endWord.mediaIndex === currentMediaIndex &&
                            currentTime - 0.065 < endWord.startTimeInOriginal)))
            ) {
                if (!el.isPlaying) {
                    console.log('play element');
                    el.play();
                    this.videoEditor.canvasElements$.next(this.videoEditor.canvasElements$.value);
                }
            }
            if (endWord.mediaIndex === currentMediaIndex && currentTime - 0.065 >= endWord.endTimeInOriginal) {
                if (el.isPlaying) {
                    console.log('pause element');
                    el.stop();
                    this.videoEditor.canvasElements$.next(this.videoEditor.canvasElements$.value);
                }
            }
        });
    }
    /**
     * Returns the current pointer position relative to the edited video length
     */
    getCurrentPosition() {
        return (
            this.currentVideoClip.video.currentTime -
            (this.currentVideoClip.startTimeInOriginal - this.currentVideoClip.startTimeInEdit)
        );
    }

    // Alternative Timeline approach
    // Triggered at cutting
    updateTimeline(initialUpdate?: boolean, clipId?: string) {
        this.isUpdatingTimeline$.next(true);

        if (this.runningVideo && this.currentProject.transcription) {
            if (this.currentProject.transcription.words.length > 0) {
                this.updateCurrentTranscription();
                // this.pause();
                let lastCurrentTime = this.runningVideo.currentTime;
                const lastMediaIndex = +this.runningVideo.innerHTML;
                // this.startClip = (await this.calculateNextClip(0, 0)).clip;

                this.currentProject.transcription.calculateTimeInEdit();
                this.videoClips = this.currentProject.transcription.generateVideoClips();
                this.generateSubtitleClips(clipId);

                lastCurrentTime = initialUpdate ? this.videoClips.startVideoClip.startTimeInOriginal : lastCurrentTime;

                this.updateRemovedFillerWords();
                this.updateRemovedPauses();
                this.currentVideoClip = this.jumpInClipTo(lastCurrentTime, lastMediaIndex);
                this.runningVideo = this.currentVideoClip.video;
                this.runningVideoChanged$.next();
                this.runningVideo.currentTime = lastCurrentTime;
                this.updateNextVideoPositions();

                this.drawFrame();
            }
        }
        this.isUpdatingTimeline$.next(false);
    }

    updateNextVideoPositions() {
        if (this.currentVideoClip?.next) {
            // console.log(
            //     `set ${this.currentVideoClip.next.video.innerHTML} time to ${this.currentVideoClip.next.startTimeInOriginal}`
            // );
            this.currentVideoClip.next.video.currentTime = this.currentVideoClip.next.startTimeInOriginal;
        }
    }

    enableCanvasElementAnimations() {
        this.videoEditor?.canvasElements$.value?.forEach((element) => element.playAnimations$.next(true));
    }
    disableCanvasElementAnimations() {
        this.videoEditor?.canvasElements$.value?.forEach((element) => element.playAnimations$.next(false));
    }

    isPreviousWordCutword(currentIndex: number): boolean {
        if (currentIndex > 0) {
            const previousWord = this.currentTranscription.words[currentIndex - 1];
            return previousWord?.isCut;
        }
        return false;
    }

    // sets isCut to false until next word that is not a cutword
    reAddWordFromIndex(startIndex): void {
        this.pause();
        const removeTill = this.getLastCutwordInCutwordSequence(startIndex);
        this.commandService.addCommand(
            new WordReAddCommand({
                editorService: this,
                startIndex,
                endIndex: removeTill,
                words: this.currentTranscription.words
            })
        );
    }

    revertAllCuts(onlyPauses = true) {
        const startIndex = 0;
        const endIndex = this.currentProject.transcription.words.length - 1;

        for (let i = startIndex; i <= endIndex; i++) {
            const word = this.currentProject.transcription.words[i];
            if (word.isCut && onlyPauses && word.isPause) {
                word.isCut = false;
            }
        }
        this.updateWords(arrayFromRange(startIndex, endIndex));
    }

    private getLastCutwordInCutwordSequence(startIndex: number) {
        for (let i = startIndex; i < this.currentTranscription.words.length; i++) {
            if (!this.currentTranscription.words[i].isCut) {
                return i - 1;
            }
        }
        return this.currentTranscription.words.length - 1;
    }

    /////////////////////////////////////////////////////////////
    // Text Editing
    ////////////////////////////////////////////////////////////

    // resets playing word index so its not visible in the UI anymore
    resetPlayingWordIndex() {
        this.playingWordIndex = -1;
    }

    // check which word is played right now.
    updatePlayingWordIndex(): void {
        const currentTime = this.runningVideo.currentTime;
        const currentMediaIndex = Number(this.runningVideo.innerHTML);

        if (
            (this.currentTranscription.words[this.playingWordIndex]?.mediaIndex === currentMediaIndex ||
                this.currentTranscription.words[this.playingWordIndex]?.mediaIndex === -1) &&
            this.currentTranscription.words[this.playingWordIndex]?.startTimeInOriginal < currentTime &&
            this.currentTranscription.words[this.playingWordIndex]?.endTimeInOriginal >= currentTime
        ) {
            return;
        }
        let i;
        let currentSpeakerIndex;
        if (this.currentProject) {
            for (i = 0; i < this.currentTranscription.words.length; i++) {
                const word = this.currentTranscription.words[i];
                if (!word.isCut) {
                    if (
                        (currentMediaIndex === word.mediaIndex || word.mediaIndex === -1) &&
                        currentTime >= word.startTimeInOriginal &&
                        currentTime <= word.endTimeInOriginal
                    ) {
                        currentSpeakerIndex = word.speakerTag;
                        break;
                    }
                }
            }

            this.playingWordIndex = i;
            this.currentSpeakerIndex$.next(currentSpeakerIndex);
            // this.currentEditedTimePosition = this.currentTranscription.words[i].startTimeInEdit;
        }
    }

    public removeSelection() {
        this.selectedIndices = [];
        this.selectedParagraphs$.next(
            this.selectedParagraphs$.getValue().map((paragraph) => ({ ...paragraph, selected: false }))
        );
        this.playingWordIndex = this.selectedWordIndex;
        window.getSelection().removeAllRanges();
        this.updatePreviewTemplates();
    }

    selectFullWordFromIndex(startIndex: number, endIndex: number): void {
        const wholeTranscriptSelected =
            startIndex === this.getFirstNonCutWord() && endIndex === this.getLastNonCutWord();
        const oldPlayingWordIndex = this.playingWordIndex;
        // tempIndex gets set to the smaller index of startIndex or endIndex
        const tempIndex = endIndex >= startIndex ? startIndex - 1 : endIndex - 1;
        const oldIndex = this.SelectedWordIndex;
        this.SelectedWordIndex = tempIndex + 1;

        const absoluteLength = Math.abs(endIndex - startIndex) + 1;
        // reset playingWordIndex so the selection is display for the complete word
        if (absoluteLength > 1) {
            this.playingWordIndex = startIndex;
        }

        if (wholeTranscriptSelected) {
            this.playingWordIndex = oldPlayingWordIndex;
        }
        this.selectedIndices = Array.from({ length: absoluteLength }, (v, k) => k + tempIndex + 1);

        this.updateSelectedParagraphs();
        this.updatePreviewTemplates(startIndex, endIndex);
        this.updatePreviewTemplates(oldIndex);

        // Set current preview frame to selection end
        if (!wholeTranscriptSelected) {
            this.jumpToWordByIndex(startIndex);
        } else {
            this.jumpToWordByIndex(oldPlayingWordIndex);
        }
    }

    getFullTranscriptSelected(): boolean {
        return (
            this.selectedIndices[0] === this.getFirstNonCutWord() &&
            this.selectedIndices[this.selectedIndices.length - 1] === this.getLastNonCutWord()
        );
    }

    getTextByIndex(startIndex: number, endIndex: number, skipCuts = false): string {
        // tempIndex gets set to the smaller index of startIndex or endIndex
        const wordsList: Word[] = [];
        const transcription = this.currentTranscription;
        for (let i = startIndex; i <= endIndex; i++) {
            wordsList.push(transcription.getWordByIndex(i));
        }

        if (skipCuts) {
            return wordsList
                .filter((word) => !word.isCut)
                .map((word) => word?.text)
                .filter((val) => !!val)
                .join(' ');
        } else {
            return wordsList
                .map((word) => word?.text)
                .filter((val) => !!val)
                .join(' ');
        }
    }

    /**
     * Update preview video to word index
     *
     * @param index word index
     */
    jumpToWordByIndex(index: number) {
        const oldSelectedWordIndices = this.selectedIndices;
        const selectedWord = this.getWordByIndex(index);
        const newStartTime = selectedWord?.startTimeInOriginal;
        this.jumpedToIndex$.next(index);
        this.currentSpeakerIndex$.next(this.getWordByIndex(index)?.speakerTag);
        this.currentVideoClip = this.jumpInClipTo(newStartTime, selectedWord.mediaIndex);
        this.updateNextVideoPositions();
        this.updatePreviewTemplates(
            oldSelectedWordIndices[0],
            oldSelectedWordIndices[oldSelectedWordIndices.length - 1]
        );
        this.updatePreviewTemplates(index);
        // this.runningVideoChanged$.next();
        this.drawFrame();
    }

    addWordsToSelection(startIndex: number, endIndex: number) {
        const newIndices = this.selectedIndices.concat(
            Array.from({ length: endIndex - startIndex + 1 }, (_, i) => i + startIndex)
        );
        this.selectedIndices = [...new Set(newIndices)].sort((a, b) => a - b); // Only unique values sorted
        this.updateSelectedParagraphs();
        this.updatePreviewTemplates(startIndex, endIndex);
    }

    removeWordsFromSelection(startIndex: number, endIndex: number) {
        this.selectedIndices = this.selectedIndices.filter((index) => index < startIndex || index > endIndex);
        this.updateSelectedParagraphs();
        this.updatePreviewTemplates(startIndex, endIndex);
    }

    /**
     * Checks which paragraphs are completely in the selection
     */
    updateSelectedParagraphs() {
        const allSelectedParagraphs = this.chunkWordArrayResult.map((chunk, paragraphIndex) => ({
            index: paragraphIndex,
            selected: chunk
                .filter((wordIndex) => !wordIndex.word.isCut)
                .map((wordIndex) => wordIndex.index)
                .every((index) => this.selectedIndices.includes(index))
        }));
        this.selectedParagraphs$.next(allSelectedParagraphs);
    }

    /**
     * Sets the speakerIndex for the paragraphs words
     *
     * @param speakerIndex
     */
    setSpeakerForParagraph(speakerIndex: number) {
        for (const paragraph of this.selectedParagraphs$
            .getValue()
            .filter((selectedParagraph) => selectedParagraph.selected)) {
            const wordChunk = this.chunkWordArrayResult[paragraph.index];
            const startIndex = this.currentTranscription.getNextWordByIndex(wordChunk[0].index, false, false).index - 1;
            const endIndex =
                this.currentTranscription.getPreviousWordByIndex(wordChunk[wordChunk.length - 1].index, false, false)
                    .index + 1;
            for (let i = startIndex; i <= endIndex; i++) {
                this.currentTranscription.words[i].speakerTag = speakerIndex;
            }
            this.updateWords(
                Array.from({ length: endIndex - startIndex + 1 }, (_, wordIndex) => wordIndex + startIndex)
            );
        }
        this.speakerAmount = this.getAmountOfSpeakers();
    }

    /**
     * Either selects or deselects the whole paragraph
     *
     * @param index paragraph index
     * @param forceSelection if set to true the paragraph won't be deselected
     */
    toggleParagraphSelectionByIndex(index: number, forceSelection = false) {
        this.playingWordIndex = -1;
        if (!this.selectedParagraphs$.getValue().find((paragraph) => paragraph.selected)) {
            this.selectedIndices = [];
        }
        const isSelected = this.selectedParagraphs$.getValue()[index].selected;
        const wordChunk = this.chunkWordArrayResult[index];
        const startIndex = this.currentTranscription.getNextWordByIndex(wordChunk[0].index, false, true, true).index;
        const endIndex = this.currentTranscription.getPreviousWordByIndex(
            wordChunk[wordChunk.length - 1].index,
            false,
            true,
            true
        ).index;

        if (!isSelected) {
            this.addWordsToSelection(startIndex, endIndex);
            return true;
        } else if (!forceSelection) {
            this.removeWordsFromSelection(startIndex, endIndex);
            return false;
        }
    }

    /**
     * Selects the whole selection if nothing is selected, deselect everything if all or some words are selected
     *
     * @return false if text gets unselected
     * @return true if text gets selected
     */
    toggleWholeTextSelection() {
        if (this.selectedParagraphs$.getValue().some((paragraph) => paragraph.selected)) {
            this.removeSelection();
            this.updateSelectedParagraphs();
            return false;
        } else {
            this.selectFullWordFromIndex(0, this.getLastNonCutWord());
            this.updateSelectedParagraphs();
            return true;
        }
    }

    /**
     * Selects the whole text, starting at the first non cut word and ending at the last non cut word
     * @param hideTextEditorContextMenu if true the textEditorContextMenu will
     */
    selectWholeText(hideTextEditorContextMenu?: boolean) {
        this.dialogService.closeAll();
        if (hideTextEditorContextMenu === true) {
            this.closeTextEditorContextMenu();
        }
        this.selectFullWordFromIndex(this.getFirstNonCutWord(), this.getLastNonCutWord());
        window.getSelection().empty();
    }

    /**
     * Returns the word index of the first non cut word (this includes pauses)
     */
    getFirstNonCutWord(): number | null {
        let firstNonCutWordIndex;
        const words = this.currentTranscription$?.getValue()?.words;
        if (words?.length) {
            for (let i = 0; i < words.length; i++) {
                if (!words[i].isCut) {
                    firstNonCutWordIndex = i;
                    break;
                }
            }
        }
        return firstNonCutWordIndex;
    }

    /**
     * Returns the word index of the last non cut word (this includes pauses)
     *
     * @param transcription transcription
     */
    getLastNonCutWord(transcription?: Transcription): number | null {
        let lastNonCutWordIndex;
        const words = (transcription || this.currentTranscription$?.getValue())?.words;
        if (words?.length) {
            for (let i = words.length - 1; i >= 0; i--) {
                if (!words[i].isCut) {
                    lastNonCutWordIndex = i;
                    break;
                }
            }
        }
        return lastNonCutWordIndex;
    }

    /**
     * Returns the start word and end word index of the current selection
     */
    getStartEndConfig(): StartEndConfig | null {
        const startWord = this.selectedIndices[0];
        const endWord = this.selectedIndices[this.selectedIndices.length - 1];
        if (Number.isInteger(startWord) && Number.isInteger(endWord)) {
            return { startWord, endWord };
        } else {
            return {
                startWord: this.playingWordIndex,
                endWord: this.playingWordIndex
            };
        }
    }

    /**
     * Returns the start word and end word index of the current selection
     */
    getMultipleStartEndConfigs(): StartEndConfig[] | null {
        const indexGroups = splitConsecutiveNumberArray(this.selectedIndices);
        const startEndConfigs: StartEndConfig[] = [];
        for (const selectedIndices of indexGroups) {
            if (selectedIndices.length > 1) {
                const startWord = this.currentTranscription.getNextWordByIndex(
                    selectedIndices[0],
                    false,
                    true,
                    true
                ).index;
                const endWord = this.currentTranscription.getPreviousWordByIndex(
                    selectedIndices[selectedIndices.length - 1],
                    false,
                    true,
                    true
                ).index;

                startEndConfigs.push({ startWord, endWord });
            }
        }
        return startEndConfigs;
    }

    getSpeakerIndices(skipCuts) {
        return this.currentTranscription.getSpeakerIndices(skipCuts);
    }

    /**
     * When the playingIndex is in the start end config and no text is selected, jump to the start position
     *
     * @param startEndConfig
     */
    jumpToWordIfNotInside(startEndConfig: StartEndConfig) {
        if (
            startEndConfig.endWord !== -1 &&
            startEndConfig.startWord !== -1 &&
            this.selectedIndices.length === 0 &&
            (this.playingWordIndex < startEndConfig.startWord || this.playingWordIndex > startEndConfig.endWord)
        ) {
            const newIndex = this.currentTranscription.getNextWordByIndex(
                startEndConfig.startWord - 1,
                false,
                true
            ).index;
            this.jumpToWordByIndex(newIndex);
            this.SelectedWordIndex = newIndex;
            this.selectedIndices.length = 0;
            this.playingWordIndex = newIndex;
        }
    }

    copySelectedWords() {
        const selectionStartEndConfig: StartEndConfig = this.getStartEndConfig();

        // filter plain text from selected indices
        const selectedText = this.filterPlainTextFromSelection(selectionStartEndConfig);

        if (!this.currentProject.isExample && this.userService.user.isAnonymous && selectedText.length > 25) {
            this.dialogService.openDialog(SignupComponent, {
                maxWidth: 1300,
                data: {
                    hideLogin: true,
                    fromAnonymous: true,
                    openPayment: false,
                    headline: 'Sign up to copy text',
                    subHeadline: 'To copy the text to your clipboard, you must create an account'
                }
            });
            return false;
        } else {
            copyToClipboard(selectedText);
            return true;
        }
    }

    /**
     * Filters all the cut words and pauses and returns a string
     *
     * @param startEndConfig
     */
    filterPlainTextFromSelection(startEndConfig: StartEndConfig): string {
        return this.currentTranscription.words
            .slice(startEndConfig.startWord, startEndConfig.endWord + 1)
            .map((word) => {
                if (word.isRealWord) {
                    return word.text;
                }
                return null;
            })
            .filter((wordString) => wordString != null)
            .join(' ');
    }

    getCurrentWord(): Word {
        if (this.playingWordIndex > -1) {
            return this.currentTranscription.words[this.playingWordIndex];
        }
        return null;
    }

    getNextWord(): Word {
        const nextIndex = this.playingWordIndex + 1;
        if (this.playingWordIndex > -1 && nextIndex <= this.currentTranscription.words.length) {
            return this.currentTranscription.words[this.playingWordIndex];
        }
        return null;
    }

    getSubtitleLine(clipId?: string): string {
        const activeVideoEditorConfig = this.getActiveVideoEditorConfig(clipId);
        if (
            this.runningVideo &&
            activeVideoEditorConfig?.canvasElements?.subtitle?.subtitleMode !== SubtitleMode.disabled
        ) {
            const currentTime = this.runningVideo.currentTime;
            const currentMediaIndex = Number(this.runningVideo.innerHTML);
            return this.subtitleClips$.getValue()?.getClipByTime(currentTime, currentMediaIndex)?.text;
        }
    }

    public setVideoEditingMode() {
        this.editingMode = 'video';
    }

    public setTextEditingMode() {
        this.editingMode = 'text';
    }

    /**
     * Transcription manipulation functions
     */
    changeSelectedWord(newWord: string, index?: number, skipCommandCreation = false) {
        if (this.selectedWordIndex > -1 || this.selectedIndices.length > 0) {
            const wordBeforeChangeIndex = index ?? this.editingWordIndex$.getValue();
            const wordBeforeChange = this.currentTranscription.words[wordBeforeChangeIndex];
            if (wordBeforeChange) {
                const wordTextBeforeChange = wordBeforeChange.text;

                // only apply when word actually has changed
                if (wordTextBeforeChange !== newWord) {
                    this.analyticsService.track(AnalyticsEvents.WORD_EDITED, { projectId: this.currentProject.id });
                    const command = new WordEditCommand({
                        selectedWordIndex: index || this.editingWordIndex$.getValue(),
                        words: this.currentTranscription.words,
                        newWord,
                        editorService: this
                    });
                    if (skipCommandCreation) {
                        command.execute();
                    } else {
                        this.commandService.addCommand(command);
                    }
                }
                if (wordBeforeChange.confidence !== 1) {
                    wordBeforeChange.confidence = 1;
                    this.updateWords([wordBeforeChangeIndex]);
                }
            }
        }
    }

    changeCasingFirstLetterOfNextWord(
        newCasing: 'upper' | 'lower',
        skipCheck = false,
        wordIndex?: number,
        skipCommandCreation = false
    ) {
        if (this.editingWordIndex$.getValue() > -1 || skipCheck) {
            const { word: nextWord, index } = this.currentTranscription.getNextWordByIndex(
                wordIndex ?? (skipCheck ? this.playingWordIndex : this.editingWordIndex$.getValue()),
                true,
                true
            ) || {
                index: null,
                word: null
            };
            if (nextWord && nextWord.text) {
                // make first letter uppercase
                let newBeginning;
                const iCombinations = ['I', "I'll", "I've", "I'd", "I'm"];
                for (let i = 0; i < 5; i++) {
                    for (const punctuation of ['.', '?', '!', ':']) {
                        iCombinations.push(iCombinations[i] + punctuation);
                    }
                }
                const oldBeginning = nextWord.text[0];
                if (newCasing === 'lower' && !iCombinations.includes(nextWord.text)) {
                    newBeginning = nextWord.text[0].toLowerCase();
                } else {
                    newBeginning = nextWord.text[0].toUpperCase();
                }
                const capitalizedWord = nextWord.text.replace(nextWord.text[0], newBeginning);
                // move one word ahead and update word, then move back
                const previousIndex = this.editingWordIndex$.getValue();
                this.editingWordIndex$.next(index);

                this.changeSelectedWord(capitalizedWord, index, skipCommandCreation);
                this.editingWordIndex$.next(previousIndex);
                return oldBeginning !== newBeginning;
            }
        }
        return false;
    }

    removeSelectedWords(moveCursorToRight = false) {
        if (this.currentTranscription.isTranslation) {
            this.dialogService.openToast({
                data: {
                    message: 'It is not possible to cut the video after it has been translated',
                    iconSrc: 'assets/ic/24/info.svg'
                }
            });
            return;
        }

        this.detectRageCut();

        this.analyticsService.track(AnalyticsEvents.WORDS_DELETED, { projectId: this.currentProject.id });

        if (
            (this.selectedWordIndex === -1 && this.editingWordIndex$.getValue() === -1) ||
            this.selectedWordIndex !== this.editingWordIndex$.getValue()
        ) {
            let indicesToRemove: number[] = [];
            if (this.selectedIndices.length > 0) {
                indicesToRemove = this.selectedIndices;
            } else {
                indicesToRemove.push(this.selectedWordIndex);
            }

            if (indicesToRemove?.length > 0) {
                // if playingWordIndex is in removed selection -> set playing Word before or after removed selection
                let newIndex;
                if (!moveCursorToRight) {
                    newIndex =
                        this.currentTranscription.getPreviousWordByIndex(indicesToRemove[0], false, true)?.index ??
                        this.currentTranscription.getNextWordByIndex(
                            indicesToRemove[indicesToRemove.length - 1],
                            false,
                            true
                        )?.index;
                } else {
                    newIndex =
                        this.currentTranscription.getNextWordByIndex(indicesToRemove[0], false, true)?.index ??
                        this.currentTranscription.getPreviousWordByIndex(
                            indicesToRemove[indicesToRemove.length - 1],
                            false,
                            true
                        )?.index;
                }
                this.SelectedWordIndex = newIndex;
                this.playingWordIndex = newIndex;
            }

            this.selectedWordsRemoved$.next(indicesToRemove);

            // create Command
            this.commandService.addCommand(
                new WordRemoveCommand({
                    editorService: this,
                    indicesToRemove,
                    words: this.currentTranscription.words
                })
            );

            const words = indicesToRemove.map((index) => this.getWordByIndex(index));

            if (
                words.every((word) => word.isFiller) &&
                !this.removePausesOrFillerWordsToast &&
                this.currentProject.language === 'en-US'
            ) {
                this.removePausesOrFillerWordsToast = this.dialogService.openToast({
                    allowHotkeys: true,
                    data: {
                        message: 'Remove all filler words?',
                        actionLabel: 'Remove',
                        callBack: () => {
                            this.removeAllFillerWords();
                            this.removePausesOrFillerWordsToast.close();
                        },
                        buttonColor: 'secondary'
                    }
                });
            }

            // remove Selection
            this.removeSelection();
        }
        this.drawFrame();
    }

    detectRageCut() {
        setTimeout(() => {
            this.totalClicks = 0;
        }, 1400);

        this.totalClicks++;
        // console.log(this.totalClicks);

        if (this.totalClicks >= this.rageThreshold) {
            this.totalClicks = 0;
            this.showCutHints();
        }
    }

    editWord(event?: MouseEvent) {
        this.setTextEditingMode();
        // if (this.currentProject.subtitleMode === SubtitleMode.disabled) {
        //     this.updateSubtitleMode(SubtitleMode.static);
        // }
        if (this.selectedWordIndex === -1) {
            if (this.selectedIndices.length > 0) {
                this.SelectedWordIndex = this.selectedIndices[0];
            }
        }
        let editingWord: Word;
        let characterPosition;
        this.selectedIndices.length = 0;

        if (this.selectedWordIndex !== -1) {
            if (event) {
                characterPosition = this.getCharacterPositionByMousePosition(
                    event,
                    this.currentTranscription.words[this.selectedWordIndex].text
                );
            }
            this.editingWordIndex$.next(this.selectedWordIndex);
            editingWord = this.currentTranscription.words[this.editingWordIndex$.getValue()];
            if (editingWord && !editingWord.isCut) {
                this.editingWordSize = editingWord.text.length || 3;
            }
        }

        // Try to find the position of the correct character at which the user clicked
        // Timeout because the input field might not be created yet
        setTimeout(() => {
            if (event) {
                const editWordInput = document.getElementsByClassName('edit-input')[0] as HTMLInputElement;
                editWordInput.setSelectionRange(characterPosition, characterPosition);
            }
        }, 100);
    }

    /**
     * Returns the character position (starting at 0)
     *
     * @param event Mouse event where the user clicked
     * @param text the text of the input field
     */
    getCharacterPositionByMousePosition(event: MouseEvent, text: string) {
        let target = event.target as HTMLElement;
        if (!target.classList.contains('word-real')) {
            target = (target.getElementsByClassName('word-real')[0] as HTMLElement) || target;
        }
        const markerOffset =
            this.videoEditor.canvasElements$.value?.filter(
                (element: CanvasElement) => element.startWord === this.selectedWordIndex
            ).length * 20; // 20 = px per marker image
        const offsetAtElement = event.offsetX - markerOffset - (markerOffset > 0 ? 4.34375 : 0); // 4.34375px padding of a word

        // Create div and add one character after each other until the position is found
        const newDiv = document.createElement('span');
        document.body.appendChild(newDiv);
        let lastLength = 0;
        let i = 0;

        // Loop through each character, add it to the div and check if the divs width is bigger than the offset clicked
        for (i; i < text.length; i++) {
            newDiv.innerText = text.substring(0, i);
            if (offsetAtElement <= newDiv.offsetWidth) {
                // If clicked at a character check if the click was more at the left or right of the character
                if (offsetAtElement - lastLength < newDiv.offsetWidth - offsetAtElement) {
                    newDiv.remove();
                    return i - 1;
                }
                newDiv.remove();
                return i;
            }
            lastLength = newDiv.offsetWidth;
        }
        newDiv.remove();
        return i;
    }

    finishEditingWord(editedWord: string, quitTextEditingMode: boolean) {
        if (this.editingWordIndex$.getValue() !== -1) {
            // console.log('finish Editing');
            this.changeSelectedWord(editedWord);
            if (quitTextEditingMode) {
                this.editingWordIndex$.next(-1);
                this.selectedIndices = [];
                this.setVideoEditingMode();
            }
        }
    }

    jumpWordBack(skipPauses: boolean = true, editWord: boolean = true, clipEditor?: boolean) {
        if (this.selectedWordIndex !== -1) {
            this.pause();
            this.removeSelection();
            if (this.selectedWordIndex > 0) {
                const { index: previousWordIndex } = this.currentTranscription.getPreviousWordByIndex(
                    this.selectedWordIndex,
                    skipPauses,
                    true
                ) || { index: this.selectedWordIndex };
                this.SelectedWordIndex = previousWordIndex;
                if (editWord) {
                    this.editWord();
                } else {
                    if (!clipEditor) {
                        this.textEditorContextMenu$.next({
                            target: document.getElementById('word_' + this.selectedWordIndex),
                            menuType: SelectionType.WORD
                        });
                    }
                }
            } else {
                // index to small
            }
        } else {
            // edit word at current position
            this.jumpNextIndex();
        }
    }

    jumpWordFurther(skipPauses: boolean = true, clipEditor?: boolean) {
        if (this.selectedWordIndex !== -1) {
            this.pause();
            this.removeSelection();
            if (this.selectedWordIndex < this.currentTranscription.words.length - 1) {
                const { index: nextWordIndex } = this.currentTranscription.getNextWordByIndex(
                    this.selectedWordIndex,
                    skipPauses,
                    true
                ) || {
                    index: this.selectedWordIndex
                };
                this.SelectedWordIndex = nextWordIndex;
                if (this.editingMode === 'text') {
                    this.editWord();
                } else {
                    if (!clipEditor) {
                        this.textEditorContextMenu$.next({
                            target: document.getElementById('word_' + this.selectedWordIndex),
                            menuType: SelectionType.WORD
                        });
                    }
                }
            } else {
                // console.log('index to large');
            }
        } else {
            // edit word at current position
            this.jumpNextIndex();
        }
    }

    // edit word at current position in the video
    jumpNextIndex() {
        if (this.runningVideo) {
            const nextIndex = this.currentProject.transcription.getNextIndexByTime(this.runningVideo.currentTime);
            if (nextIndex > -1) {
                this.SelectedWordIndex = nextIndex;
                if (this.editingMode === 'text') {
                    this.editWord();
                }
            }
        }
    }

    /**
     * Searches for a string in the whole transcript and adds all results to the search highlights
     *
     * @param searchValue
     */
    searchWord(searchValue: string) {
        if (searchValue.length === 0) {
            this.searchHighlightedIndices$.next([]);
            this.searchHighlightedIndex$.next(-1);
        }
        this.searchHighlightedIndices$.next([]);
        searchValue = searchValue.replace(/\s+/g, '').toLocaleLowerCase();
        let searchResults: number[] = [];
        if (
            searchValue &&
            ([',', '.', '?', '!', ':', ';', '-'].includes(searchValue) ? true : searchValue.length >= 2)
        ) {
            this.disableReplaceBecauseOnlyMultipleWordsFound$.next(true);
            for (let i = 0; i < this.currentTranscription.words.length; i++) {
                // Skip cutted words and pauses
                if (!this.currentTranscription.words[i].isRealWord) {
                    continue;
                }
                const wordsToAdd = this.searchNext(searchValue, i).map((word) => word.wordIndex);
                searchResults = searchResults.concat(wordsToAdd);

                i += wordsToAdd.length;
                // If there is min. 1 result which is only one word, enable the replace button
                if (wordsToAdd.length === 1) {
                    this.disableReplaceBecauseOnlyMultipleWordsFound$.next(false);
                }
            }

            // set searchHighlightIndex so scroll position is updated
            if (searchResults[0]) {
                this.searchHighlightedIndex$.next(searchResults[0]);
            }
        } else {
            this.searchHighlightedIndex$.next(null);
        }
        this.searchHighlightedIndices$.next(searchResults.filter((index) => index !== undefined));
    }

    /**
     * Searches in the transcription words for the search value.
     *
     * @param searchValue text input
     * @param wordIndex index of a word to check against
     * @param foundIndices previously found indices and the length of the characters found in the word (if multiple words are entered in the search the function calls itself recursively and adds them here)
     * @private
     */
    private searchNext(
        searchValue: string,
        wordIndex: number,
        foundIndices: { wordIndex: number; length: number }[] = []
    ): { wordIndex: number; length: number }[] {
        const word = this.currentTranscription.words[wordIndex].text?.toLocaleLowerCase();
        if (!word) {
            return [];
        }

        // Go through each character of the word from the transcription
        const wordCharArray = Array.from(word);
        let foundLength = 0;
        // Count characters found until now
        for (const index of foundIndices) {
            foundLength += index.length;
        }
        const foundLengthBefore = foundLength;

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let index = 0; index < wordCharArray.length; index++) {
            if (!(foundLength === 0 && searchValue[foundLength] !== wordCharArray[index])) {
                if (searchValue[foundLength] === wordCharArray[index]) {
                    foundLength++;
                } else if (foundLength < searchValue.length) {
                    return [];
                }
            }
        }

        if (foundLengthBefore === foundLength) {
            return [];
        }
        foundIndices.push({ wordIndex, length: foundLength - foundLengthBefore });
        if (foundLength >= searchValue.length) {
            // End of searchValue and all good
            return foundIndices;
        } else {
            // Found but there is more in the search value
            return this.searchNext(searchValue, wordIndex + 1, foundIndices);
        }
    }

    /**
     * Jumps to the previous text search result
     *
     * @param jumpNumber How many indices to jump forward
     */
    jumpPreviousSearchResult(jumpNumber: number = 1) {
        if (this.searchHighlightedIndex$.value >= 0) {
            const oldIndex = this.searchHighlightedIndices$
                .getValue()
                .findIndex((index) => index === this.searchHighlightedIndex$.value);
            if (0 <= oldIndex - 1) {
                this.searchHighlightedIndex$.next(this.searchHighlightedIndices$.getValue()[oldIndex - jumpNumber]);
            } else {
                // no next search result jump back to first one
                this.searchHighlightedIndex$.next(
                    this.searchHighlightedIndices$.getValue()[
                        this.searchHighlightedIndices$.getValue().length - jumpNumber
                    ]
                );
            }
        }
    }

    /**
     * Jumps to the next text search result
     *
     * @param jumpNumber How many indices to jump forward
     */
    jumpNextSearchResult(jumpNumber: number = 1) {
        if (this.searchHighlightedIndex$.value >= 0) {
            const oldIndex = this.searchHighlightedIndices$
                .getValue()
                .findIndex((index) => index === this.searchHighlightedIndex$.value);
            if (this.searchHighlightedIndices$.getValue().length > oldIndex + jumpNumber) {
                this.searchHighlightedIndex$.next(this.searchHighlightedIndices$.getValue()[oldIndex + jumpNumber]);
            } else {
                // no next search result jump back to first one
                this.searchHighlightedIndex$.next(this.searchHighlightedIndices$.getValue()[0]);
            }
        }
    }

    /**
     * Replaces all words which are containing the old value with the new value
     *
     * @param oldValue
     * @param newValue
     */
    replaceAllWords(oldValue, newValue) {
        this.commandService.addCommand(
            new ReplaceWordCommand({
                oldValue,
                newValue,
                indicesToCheck: this.searchHighlightedIndices$.getValue(),
                editorService: this
            })
        );
    }

    /**
     * Replaces only the word at the given index
     *
     * @param oldValue
     * @param newValue
     * @param index
     */
    replaceWord(oldValue, newValue, index) {
        this.commandService.addCommand(
            new ReplaceWordCommand({ oldValue, newValue, indicesToCheck: [index], editorService: this })
        );
    }

    /**
     * Returns the index of the paragraph determined by the index of a word
     *
     * @param index word index
     */
    getParagraphIndexByWordIndex(index: number) {
        return this.chunkWordArrayResult
            ?.map((wordIndices) => wordIndices.find((wordIndex) => wordIndex.index === index))
            .findIndex((indices) => !!indices);
    }

    addLinebreakAfterIndex(wordIndex: number) {
        this.showTextLoading$.next(true);
        setTimeout(() => {
            const paragraphIndex: number = this.getParagraphIndexByWordIndex(wordIndex);
            const oldParagraph = this.chunkWordArrayResult[paragraphIndex];
            if (!oldParagraph) {
                return;
            }
            const wordIndexInArray = oldParagraph.findIndex((word) => word.index === wordIndex);
            const paragraph1 = oldParagraph.slice(0, wordIndexInArray + 1);
            const paragraph2 = oldParagraph.slice(wordIndexInArray + 1);
            const newParagraphs = [paragraph1, paragraph2].filter((paragraph) => paragraph.length > 0);
            this.commandService.addCommand(
                new LinebreakAddCommand({
                    index: wordIndex,
                    currentTranscription: this.currentTranscription,
                    datasource: this.datasource$,
                    paragraphIndex,
                    newParagraphs,
                    editorService: this
                })
            );
        }, 50);
    }

    removeLinebreakAfterIndex(paragraphIndex: number) {
        this.showTextLoading$.next(true);
        setTimeout(() => {
            const wordIndex =
                this.chunkWordArrayResult[paragraphIndex][this.chunkWordArrayResult[paragraphIndex].length - 1].index;
            const oldParagraphs = [
                this.chunkWordArrayResult[paragraphIndex],
                this.chunkWordArrayResult[paragraphIndex + 1]
            ];

            this.commandService.addCommand(
                new LinebreakRemoveCommand({
                    wordIndex,
                    paragraphIndex,
                    transcription: this.currentTranscription,
                    datasource: this.datasource$,
                    oldParagraphs,
                    editorService: this
                })
            );

            this.showTextLoading$.next(false);
        }, 50);
    }

    /**
     * Adds two linebreaks and selects the words between the two new linebreaks
     * TODO: Needs functionality to remove linebreaks if the selection includes multiple paragraphs.
     * Returns paragraphIndex of last linebreak.
     */
    createNewParagraphFromSelection() {
        let baseTimeout = 0;
        const timeoutPlus = 200;

        let showMenuAtParagraphIndex = 0;
        const firstParagraphIndex: number = this.getParagraphIndexByWordIndex(this.selectedIndices[0]);
        const firstWordInParagraph = this.chunkWordArrayResult[firstParagraphIndex][0];

        const lastParagraphIndex: number = this.getParagraphIndexByWordIndex(
            this.selectedIndices[this.selectedIndices.length - 1]
        );
        const lastWordInParagraph =
            this.chunkWordArrayResult[lastParagraphIndex][this.chunkWordArrayResult[lastParagraphIndex].length - 1];

        if (
            lastWordInParagraph.index === this.selectedIndices[this.selectedIndices.length - 1] &&
            this.selectedIndices[0] - 1 > 0 &&
            firstWordInParagraph.index === this.selectedIndices[0]
        ) {
            showMenuAtParagraphIndex = firstParagraphIndex - 1;
        } else {
            if (this.selectedIndices[0] - 1 > 0 && firstWordInParagraph.index !== this.selectedIndices[0]) {
                setTimeout(() => {
                    this.addLinebreakAfterIndex(this.selectedIndices[0] - 1);
                }, baseTimeout);
                baseTimeout = +timeoutPlus;
                showMenuAtParagraphIndex = firstParagraphIndex;
            }

            if (lastWordInParagraph.index !== this.selectedIndices[this.selectedIndices.length - 1]) {
                setTimeout(() => {
                    this.addLinebreakAfterIndex(this.selectedIndices[this.selectedIndices.length - 1]);
                }, baseTimeout);
                baseTimeout = +timeoutPlus;
                showMenuAtParagraphIndex = lastParagraphIndex;
            }
        }

        return showMenuAtParagraphIndex;

        //Linebreak removal missing. Will be added in the future when needed directly in the removeLinebreak Command.
    }

    /**
     * Exports
     */
    downloadSRTFile() {
        this.trackTextExport('srt');
        downloadFile(
            this.subtitleClips$
                .getValue()
                .getSrtString(this.currentProject.mainVideoEditorConfig.canvasElements.subtitle.subtitleMode),
            'text/plain',
            this.currentProject.title + '.srt'
        );
    }

    downloadVTTFile() {
        this.trackTextExport('vtt');
        downloadFile(
            this.subtitleClips$
                .getValue()
                .getVttString(this.currentProject.mainVideoEditorConfig.canvasElements.subtitle.subtitleMode),
            'text/plain',
            this.currentProject.title + '.vtt'
        );
    }

    downloadTXTFile(includeTimestamps = false, includeSpeakers = false) {
        this.trackTextExport('txt');
        downloadFile(
            this.currentTranscription.getText(
                includeTimestamps,
                includeSpeakers,
                includeTimestamps || includeSpeakers ? this.chunkWordArrayResult : null,
                this.currentProject.speakers
            ),
            'text/plain',
            this.currentProject.title + '.txt'
        );
    }

    copyTranscriptionToClipboard() {
        this.trackTextExport('clipboard');
        copyToClipboard(this.currentTranscription.getText());
    }

    private trackTextExport(textType: 'srt' | 'vtt' | 'txt' | 'clipboard') {
        this.analyticsService.trackServerside(AnalyticsEvents.SERVER_TEXT_EXPORTED, this.userService.user?.firebaseId, {
            projectId: this.currentProject.id,
            textType,
            isTranslation: this.currentTranscription?.isTranslation
        });
    }

    /**
     * Toggle current word upper / lower case
     */
    toggleUpperLowerCase() {
        let newText = this.getCurrentWord()?.text;
        if (!newText) {
            return;
        }
        const firstCharacter = newText[0];

        // Upper case
        if (firstCharacter === firstCharacter.toUpperCase()) {
            newText = newText.charAt(0).toLocaleLowerCase() + newText.slice(1);
        }
        // Lower case
        if (firstCharacter === firstCharacter.toLowerCase()) {
            newText = newText.charAt(0).toLocaleUpperCase() + newText.slice(1);
        }

        this.changeSelectedWord(newText, this.playingWordIndex);
    }

    /**
     * Toggle between . , ? ! at the end of the current word
     */
    togglePunctuation() {
        const punctuations = ['.', ',', '?', '!', ''];

        let newText = this.getCurrentWord()?.text;
        if (!newText) {
            return;
        }
        let lastCharacter = newText[newText.length - 1];

        const index = punctuations.indexOf(lastCharacter);
        if (index === -1) {
            newText += punctuations[0];
        } else {
            newText =
                newText.slice(0, newText.length - 1) +
                (punctuations[index + 1].length !== undefined ? punctuations[index + 1] : punctuations[0]);
        }
        lastCharacter = newText[newText.length - 1];
        this.changeSelectedWord(newText, this.playingWordIndex);
        this.changeCasingFirstLetterOfNextWord(['.', '?', '!', ':'].includes(lastCharacter) ? 'upper' : 'lower', true);
    }

    /**
     * Change the punctuation at the end of the current word
     *
     * @param punctuationType
     */
    changeCurrentWordPunctuation(punctuationType: Punctuation) {
        const punctuations = ['.', ',', '?', '!', ':'];

        let newText = this.getCurrentWord()?.text;
        if (!newText) {
            return;
        }
        let lastCharacter = newText[newText.length - 1];

        const index = punctuations.indexOf(lastCharacter);
        if (index === -1) {
            newText += punctuations[punctuationType];
        } else {
            newText =
                newText.slice(0, newText.length - 1) + (index === punctuationType ? '' : punctuations[punctuationType]);
        }
        lastCharacter = newText[newText.length - 1];
        this.changeSelectedWord(newText, this.playingWordIndex);
        this.changeCasingFirstLetterOfNextWord(['.', '?', '!', ':'].includes(lastCharacter) ? 'upper' : 'lower', true);
    }

    generateSubtitleClips(clipId?: string) {
        const activeVideoEditorConfig = this.getActiveVideoEditorConfig(clipId);
        this.subtitleClips$.next(
            this.currentTranscription?.generateSubtitleClips(
                activeVideoEditorConfig.canvasElements.subtitle.subtitleMode,
                Resolution.getAspectRatio(activeVideoEditorConfig.resolution),
                activeVideoEditorConfig.canvasElements.subtitle.subtitleLength
            )
        );
        this.drawFrame();
    }

    /**
     * Moves the subtitle break to a new word
     *
     * @param oldWordIndex old subtitle break word index
     * @param newWordIndex new subtitle break word index
     */
    changeSubtitleBreakPosition(oldWordIndex: number, newWordIndex: number) {
        this.commandService.addCommand(
            new ChangeSubtitleBreakCommand({ editorService: this, oldWordIndex, newWordIndex })
        );
    }

    /**
     * Removes the subtitle break for that word Index at the current marker index
     */
    deleteSubtitleBreak() {
        const index = this.currentMarkerIndex$.getValue();
        this.commandService.addCommand(new RemoveSubtitleBreakCommand({ editorService: this, index }));
    }

    /**
     * Adds a subtitle break to the following word. Takes the current marker index as default
     */
    addSubtitleBreak() {
        const index = this.currentMarkerIndex$.getValue();
        const nextWordIndex = this.currentTranscription.getNextWordByIndex(index, true, true)?.index;
        if (!nextWordIndex) {
            return;
        }
        this.commandService.addCommand(new AddSubtitleBreakCommand({ editorService: this, index, nextWordIndex }));
    }

    /**
     * Removes pauses greater than count
     *
     * @param indicesToRemove Indices of pauses that will be removed
     */
    removePauses(indicesToRemove: number[]) {
        if (!indicesToRemove?.length) {
            return;
        }
        if (this.currentTranscription.isTranslation) {
            return;
        }
        this.analyticsService.track(AnalyticsEvents.PAUSES_DELETED, { projectId: this.currentProject.id });
        this.moveMarkerIfInRemovedIndices(indicesToRemove);

        // create Command
        this.commandService.addCommand(
            new WordRemoveCommand({ editorService: this, indicesToRemove, words: this.currentTranscription.words })
        );
    }

    undoRemovePauses() {
        const updatedWords = [];
        this.currentTranscription.words.forEach((word, index) => {
            if (word.isCut) {
                word.isCut = false;
                updatedWords.push(index);
            }
        });
        this.updateChunkWordArray();
        this.updateWords(updatedWords);
        this.updateSelectedParagraphs();
    }

    updateRemovedPauses() {
        const runtimeInMinutes = this.videoDuration / 60;

        if (runtimeInMinutes < 30) {
            this.removedAllPauses$.next(!(this.currentTranscription.getAllPauses()?.length > 0));
            return;
        }
        const min = runtimeInMinutes < 60 ? 1 : 2;
        const consecutivePauses = this.currentTranscription.getConsecutivePauses();
        let maxPauses = consecutivePauses?.reduce((max, pauses) => Math.max(max, pauses.length), 0) - 1 || 0;
        maxPauses = maxPauses > 18 ? 18 : maxPauses;
        this.removedAllPauses$.next(maxPauses <= min);
    }

    /**
     * Removes filler words in the current selection
     */
    removeAllFillerWords(startEndConfig?: StartEndConfig) {
        if (this.currentTranscription.isTranslation) {
            return;
        }
        this.analyticsService.track(AnalyticsEvents.FILLER_WORDS_DELETED, { projectId: this.currentProject.id });
        const indicesToRemove = this.getAllFillerWords(startEndConfig || this.getStartEndConfig(), true);

        if (indicesToRemove?.length <= 0) {
            return;
        }

        this.moveMarkerIfInRemovedIndices(indicesToRemove);

        // create Command
        this.commandService.addCommand(
            new WordRemoveCommand({ editorService: this, indicesToRemove, words: this.currentTranscription.words })
        );
    }

    /**
     * Returns filler words (uhm, ehm)
     */
    private getAllFillerWords(startEndConfig: StartEndConfig, includeAdjacentPauses = false) {
        const words = this.currentTranscription.words;

        const fillerWordsToSearch = ['um', 'uh', 'hmm', 'mhm', 'uh huh'];
        const indicesToRemove: number[] = [];

        for (let wordIndex = startEndConfig.startWord; wordIndex <= startEndConfig.endWord; wordIndex++) {
            if (
                fillerWordsToSearch.includes(words[wordIndex]?.text.toLocaleLowerCase().replace(/([.|,|?|!|])/, '')) &&
                !words[wordIndex]?.isCut
            ) {
                indicesToRemove.push(wordIndex);
                if (includeAdjacentPauses && words[wordIndex - 1]?.isPause) {
                    indicesToRemove.push(wordIndex - 1);
                }
                if (includeAdjacentPauses && words[wordIndex + 1]?.isPause) {
                    indicesToRemove.push(wordIndex + 1);
                }
            }
        }
        return indicesToRemove;
    }

    /**
     * Checks if markers (e.g. images or shaped) are in the removed indices and moves them if needed. Move the playing index if necessary
     *
     * @param indicesToRemove
     * @private
     */
    private moveMarkerIfInRemovedIndices(indicesToRemove: number[]) {
        const removeWordsNextToEachOther: number[][] = [];
        let currentArray = [];
        for (let i = 0; i < indicesToRemove.length; i++) {
            if (indicesToRemove[i] + 1 === indicesToRemove[i + 1]) {
                if (currentArray.length === 0) {
                    currentArray.push(indicesToRemove[i]);
                }
                currentArray.push(indicesToRemove[i + 1]);
            } else {
                if (currentArray.length === 0) {
                    removeWordsNextToEachOther.push([indicesToRemove[i]]);
                } else {
                    removeWordsNextToEachOther.push(currentArray);
                }

                currentArray = [];
            }
        }
        removeWordsNextToEachOther.forEach((array) => this.selectedWordsRemoved$.next(array));

        // Move playing index if it's at a word which is going to be removed
        for (let newIndex = this.playingWordIndex; newIndex < this.currentTranscription.words.length; newIndex++) {
            if (!this.currentTranscription.words[newIndex]?.isCut && !indicesToRemove.includes(newIndex)) {
                this.SelectedWordIndex = newIndex;
                this.playingWordIndex = newIndex;
                break;
            }
        }
    }

    updateRemovedFillerWords() {
        this.removedAllFillerWords$.next(!(this.getAllFillerWords(this.getStartEndConfig())?.length > 0));
    }

    /**
     * Updates the preview templates and regenerates them.
     *
     * @param wordIndexParagraphToUpdateFrom Optionally add the wordIndex whose paragraph should be updated only
     * @param wordIndexParagraphToUpdateTo Optionally add the wordIndex whose paragraph should be updated only to (all between from and to will be updated)
     */
    updatePreviewTemplates(wordIndexParagraphToUpdateFrom?: number, wordIndexParagraphToUpdateTo?: number) {
        // If to greater than from, switch variables
        if (wordIndexParagraphToUpdateTo < wordIndexParagraphToUpdateFrom) {
            const tempIndex = wordIndexParagraphToUpdateFrom;
            wordIndexParagraphToUpdateFrom = wordIndexParagraphToUpdateTo;
            wordIndexParagraphToUpdateTo = tempIndex;
        }

        const showSubtitleBreaks = this.currentProject?.showSubtitleBreaks;
        const selectedIndices = this.selectedIndices;
        const canvasElements = this.videoEditor?.canvasElements$?.value;
        const templateElement = { startWord: null, endWord: null };
        for (let h = 0; h < canvasElements?.length; h++) {
            if (!canvasElements[h]?.templateId) {
                continue;
            }
            templateElement.startWord = canvasElements[h]?.startWord;
            templateElement.endWord = canvasElements[h]?.endWord;
            break;
        }

        const subtitleClipStartEnds = this.subtitleClips$
            .getValue()
            ?.getStartEndArray(this.currentProject?.mainVideoEditorConfig?.canvasElements?.subtitle?.subtitleMode);
        const paragraphToUpdateFrom = this.getParagraphIndexByWordIndex(wordIndexParagraphToUpdateFrom);
        const paragraphToUpdateTo = this.getParagraphIndexByWordIndex(wordIndexParagraphToUpdateTo);

        const finalArray = paragraphToUpdateFrom > -1 ? this.textPreviewTemplates.getValue() : [];

        // Loop through all paragraphs or only the one to update
        for (
            let k = paragraphToUpdateFrom > -1 ? paragraphToUpdateFrom : 0;
            k <
            (paragraphToUpdateFrom > -1
                ? paragraphToUpdateFrom + (paragraphToUpdateTo ? paragraphToUpdateTo - paragraphToUpdateFrom + 1 : 1)
                : this.chunkWordArrayResult?.length);
            k++
        ) {
            const wordIndexArray = this.chunkWordArrayResult[k];
            let selectionStarted = false;
            let resultString = '';
            let previousMarkerIsEndMarker = false;
            for (let i = 0; i < wordIndexArray?.length; i++) {
                const wordIndex = wordIndexArray[i];

                // Start selection
                if (
                    selectedIndices[0] <= wordIndex.index &&
                    selectedIndices[selectedIndices.length - 1] >= wordIndex.index &&
                    !selectionStarted
                ) {
                    selectionStarted = true;
                    resultString += '<span class="selection">';
                }

                // Cutword
                if (wordIndex.word.isCut) {
                    if (!this.isPreviousWordCutword(wordIndex.index) || i === 0) {
                        resultString += '[...] ';
                    }
                    continue;
                }

                // Loop through canvas elements for start
                let canvasStartMarker = new IsCanvasMarkerPipe().transform(wordIndex.index, canvasElements, 'start');
                let templateId;

                // Save markers in 2 variables to attach them in the correct order (first template, then normal)
                let normalMarkerStrings = '';
                let templateMarkerStrings = '';

                for (let j = 0; j < canvasStartMarker?.length; j++) {
                    const canvasElement = canvasStartMarker[j];
                    resultString += previousMarkerIsEndMarker ? ' ' : '';
                    previousMarkerIsEndMarker = false;
                    // Element belongs to template
                    if (canvasElement.templateId) {
                        if (!templateId && templateElement.startWord === wordIndex.index) {
                            templateId = canvasElement.templateId;
                            templateMarkerStrings += `<span class="start-marker marker-template no-hover"><img class="marker-icon" src="/assets/ic/editor-contextmenu-icon-template.svg"/></span>`;
                        }
                    } else {
                        normalMarkerStrings +=
                            `<span class="start-marker">` +
                            '<img class="marker-icon" src="/assets/ic/16/editor-contextmenu-icon-' +
                            canvasElement.type +
                            (canvasElement.type === 'multilane' && (canvasElement as any)?.mediaType === 'audio'
                                ? '-audio'
                                : '') +
                            '.svg" />' +
                            '</span>';
                    }
                }
                previousMarkerIsEndMarker = false;
                resultString += templateMarkerStrings + normalMarkerStrings;

                const isPrevWordSubtitleSubtitleBreak =
                    showSubtitleBreaks &&
                    new IsSubtitleBreakPipe().transform(subtitleClipStartEnds, wordIndexArray[i - 1]?.index);
                // Text
                if (!wordIndex.word.isPause) {
                    resultString += (isPrevWordSubtitleSubtitleBreak ? '' : ' ') + wordIndex.word.text + ' ';
                }
                // Pause
                else {
                    resultString += (isPrevWordSubtitleSubtitleBreak ? '' : ' ') + '▪ ';
                }

                // Loop through canvas elements for end
                canvasStartMarker = new IsCanvasMarkerPipe().transform(wordIndex.index, canvasElements, 'end');
                templateId = undefined;
                // Save markers in 2 variables to attach them in the correct order (first template, then normal)
                normalMarkerStrings = '';
                templateMarkerStrings = '';
                for (let j = 0; j < canvasStartMarker?.length; j++) {
                    const canvasElement = canvasStartMarker[j];

                    // Element belongs to template
                    if (canvasElement.templateId) {
                        if (!templateId && templateElement.endWord === wordIndex.index) {
                            templateId = canvasElement.templateId;
                            previousMarkerIsEndMarker = true;
                            templateMarkerStrings +=
                                '<span class="end-marker marker-template no-hover"><img class="marker-icon" src="/assets/ic/editor-contextmenu-icon-template-end.svg"/></span>';
                        }
                    } else {
                        previousMarkerIsEndMarker = true;
                        normalMarkerStrings += '<span class="end-marker"><span class="marker"></span></span>';
                    }
                }
                resultString += normalMarkerStrings + templateMarkerStrings;

                // Subtitle Breaks (all but not if its the last word)
                if (
                    showSubtitleBreaks &&
                    (k === this.chunkWordArrayResult?.length - 1 ? i < wordIndexArray?.length - 1 : true) &&
                    new IsSubtitleBreakPipe().transform(subtitleClipStartEnds, wordIndex.index)
                ) {
                    resultString +=
                        '<span class="start-marker subtitle-break no-hover"><img class="marker-icon" src="/assets/ic/24/subtitle-break-light.svg"/></span>';
                }

                // End selection
                if (selectionStarted && selectedIndices[selectedIndices.length - 1] === wordIndex.index) {
                    selectionStarted = false;
                    resultString += '</span>';
                }
            }

            // If selection didn't end in the loop close it here
            if (selectionStarted) {
                resultString += '</span>';
            }
            finalArray[k] = resultString;
        }
        this.textPreviewTemplates.next(finalArray);
    }

    /**
     * Copies the current word in the users clipboard and replaces the current word with a pause
     */
    cutCurrentWord() {
        this.copySelectedWords();
        if (this.selectedIndices.length) {
            for (const wordIndex of this.selectedIndices) {
                this.changeSelectedWord('', wordIndex, false);
            }
        } else if (this.selectedWordIndex) {
            this.changeSelectedWord('', this.selectedWordIndex, false);
        }
    }

    getCurrentSelectionStartEnd(): StartEndConfig {
        if (this.selectedIndices.length) {
            return {
                startWord: this.selectedIndices[0],
                endWord: this.selectedIndices[this.selectedIndices.length - 1]
            };
        }
        return null;
    }

    /**
     * Clip functions
     */
    initClipEditor(startEndConfig: StartEndConfig) {
        this.trackOpenClipsEditor(startEndConfig);

        this.videoEditor.canvasElements$.value
            .filter((element) => !(element instanceof VideoElement) || element instanceof MultilaneVideoElement)
            .forEach((element) => {
                element.forceHide = true;
                element.hide();
            });

        const indicesToRemove = [
            ...Array(startEndConfig.startWord).keys(),
            ...Array.from(
                { length: this.currentTranscription.words.length - startEndConfig.endWord - 1 },
                (_, i) => i + startEndConfig.endWord + 1
            )
        ];

        this.clipEditorSetupCommand = new WordRemoveCommand(
            {
                editorService: this,
                indicesToRemove,
                words: this.currentTranscription.words
            },
            true
        ).execute();

        this.resetPlayingWordIndex();
        this.jumpToWordByIndex(startEndConfig.startWord);
        this.updatePlayingWordIndex();
    }
    destroyClipEditor(startEndConfig: StartEndConfig) {
        this.pause();
        this.videoEditor.canvasElements$.value
            .filter((element) => !(element instanceof VideoElement) || element instanceof MultilaneVideoElement)
            .forEach((element) => {
                element.forceHide = false;
            });
        this.clipEditorSetupCommand.undo();

        this.jumpToWordByIndex(startEndConfig.endWord);
        this.updatePlayingWordIndex();
    }

    getStartEndConfigDuration(startEndConfig?: StartEndConfig): number {
        let currentStartEndConfig: StartEndConfig[] = [];
        if (startEndConfig) {
            currentStartEndConfig.push(startEndConfig);
        } else {
            currentStartEndConfig = this.getMultipleStartEndConfigs();
        }

        let selectionDuration = 0;

        if (currentStartEndConfig.length === 0) {
            return 0;
        } else {
            currentStartEndConfig.forEach((element) => {
                const startIndex = element.startWord;
                const endIndex = element.endWord;
                selectionDuration =
                    selectionDuration +
                    (this.currentTranscription.words[endIndex].endTimeInEdit -
                        this.currentTranscription.words[startIndex].startTimeInEdit);
            });
            return selectionDuration;
        }
    }

    trackOpenClipsEditor(startEndConfig: StartEndConfig) {
        const clipDuration = this.getStartEndConfigDuration(startEndConfig);
        this.analyticsService.track(AnalyticsEvents.CLIPS_EDITOR_OPENED, {
            projectId: this.currentProject.id,
            clipDuration
        });
    }

    openSpeakerMenuViaShortcut() {
        // Function not used right now. can be used to implement shortcut logic
        let openSpeakerMenuTimeout = 0;
        let openAtParagraph = 0;

        if (this.selectedIndices.length > 1) {
            openAtParagraph = this.createNewParagraphFromSelection();
            openAtParagraph += 1;
            openSpeakerMenuTimeout = 500;
        } else {
            openAtParagraph = this.getParagraphIndexByWordIndex(this.playingWordIndex);
        }

        setTimeout(
            () => {
                this.openSpeakerMenuAtParagraph(openAtParagraph);
            },
            openSpeakerMenuTimeout,
            openAtParagraph
        );
    }
    /**
     * Opens speaker menu at specific paragraph.
     *
     * @param paragraphIndex Where the menu will open
     */
    openSpeakerMenuAtParagraph(paragraphIndex): void {
        this.dialogService.closeAll();
        this.openSpeakerMenu.next(paragraphIndex);
    }

    getWordsFromTranscript(startEndConfig: StartEndConfig, wordAmount: number, removePunctuations?: boolean): string {
        return this.currentTranscription.words
            .slice(
                startEndConfig?.startWord,
                startEndConfig?.startWord + Math.min(startEndConfig?.endWord - startEndConfig?.startWord, wordAmount)
            )

            .map((word) =>
                removePunctuations === true
                    ? word.text.replace('.', '').replace(',', '').replace('?', '').replace('!', '')
                    : word.text
            )
            .join(' ');
    }

    /**
     * Selects section of transcript, scrolls to selection and opens context menu
     *
     * @param startEndConfig selection span
     */
    selectSectionFromTranscript(startEndConfig: StartEndConfig) {
        this.removeSelection();
        this.addWordsToSelection(startEndConfig.startWord, startEndConfig.endWord);

        //scroll to position via search, then remove searchword again
        this.searchHighlightedIndex$.next(startEndConfig.startWord);
        this.searchWord('');
        this.SelectedWordIndex = startEndConfig.startWord;

        //open context menu when scrolling stopped
        this.currentlyScrolling$
            .pipe(
                filter((isScrolling) => isScrolling === false),
                take(2)
            )
            .subscribe(() => {
                this.textEditorContextMenu$.next({
                    target: document.getElementById('word_' + startEndConfig.startWord),
                    menuType: SelectionType.WORD
                });
            });
    }

    /**
     * Returns new clipCreationCount and updates database
     */
    async updateClipCreationCount() {
        const clipCreationCount$ = this.projectService
            .getProjectDocumentReference(this.currentProject.id)
            .valueChanges()
            .pipe(
                take(1),
                map((project) => project.clipCreationCount)
            );

        const processedClipCreationCount$ = clipCreationCount$.pipe(
            map((clipCreationCount) => {
                let newCreationCount = clipCreationCount;
                if (isNaN(newCreationCount) || newCreationCount === null) {
                    newCreationCount = 1;
                } else {
                    newCreationCount++;
                }
                this.currentProject.clipCreationCount = newCreationCount;
                this.projectService.updateClipCreationCount(this.currentProject.id, newCreationCount);
                return newCreationCount;
            })
        );
        try {
            const updatedClipCreationCount = await firstValueFrom(processedClipCreationCount$);
            return updatedClipCreationCount;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
    closeTextEditorContextMenu() {
        this.closeTextEditorContextMenu$.next(null);
    }

    getOriginalResolution(): Resolution {
        return this.currentProject?.mainVideoEditorConfig?.canvasElements?.media.filter(
            (el) => el.mediaUrl !== environment.placeholderVideoUrl
        )[0]?.resolution;
    }

    // testIndex = 0;
    // importSingleStreamTranscript() {
    //     // const previousResponse = JSON.parse(exampleStreamingResponses[this.testIndex]);
    //     // const previousWords = previousResponse.words.map((word) => new Word().clone(word));
    //     const response = JSON.parse(exampleStreamingResponses[this.testIndex]);
    //     const words = response.words.map((word) => new Word().clone(word));
    //     this.updateLocalTranscription(words, response.mediaUrlsWithIndex, response.transcriptionMap);
    //     this.testIndex++;
    //     if (this.testIndex === exampleStreamingResponses.length - 1) {
    //         this.transcriptionState$.next(TranscriptionState.COMPLETED);
    //     }
    // }
    // testIndex = 0;
    // testSubtitles() {
    //     this.testNativeSubtitles(this.testIndex++);
    // }

    // testNativeSubtitles(index) {
    //     this.videoEditor.addSubtitleElement(null);
    // }
}
