// Tools related to arrays
export function arrayInRange(range: number, offset = 0) {
    return Array(range)
        .fill(0)
        .map((_, i) => i + offset);
}

export function sortFn(a, b, caseInsensitive?: boolean) {
    if (!caseInsensitive) return a > b ? 1 : -1;
    return `${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1;
}

export function sortByProperty(propName: string, caseInsensitive?: boolean) {
    if (caseInsensitive) {
        return function (a, b) {
            const valA = typeof a[propName] === 'string' ? a[propName].toLowerCase() : a[propName];
            const valB = typeof b[propName] === 'string' ? b[propName].toLowerCase() : b[propName];
            return valA > valB ? 1 : -1;
        };
    }
    return function (a, b) {
        return a[propName] > b[propName] ? 1 : -1;
    };
}

export function toObject(arr, key = 'key') {
    if (!Array.isArray(arr)) {
        return arr;
    }
    const rv = {};
    for (let i = 0; i < arr.length; ++i) {
        rv[arr[i][key]] = arr[i];
    }
    return rv;
}

export function toArraySet(array: any[]): any[] {
    const arraySet = [];
    array.forEach((drc) => {
        if (arraySet.indexOf(drc) === -1) {
            arraySet.push(drc);
        }
    });
    return arraySet;
}

export function upsertItem(array: object[], newItem: object, key = 'id') {
    let itemUpdated = false;
    if (!array?.length) return [newItem];
    const newArray = array.map((item) => {
        if (newItem[key] !== item[key]) return item;
        itemUpdated = true;
        return { ...item, ...newItem };
    });
    if (itemUpdated) return newArray;
    return [...array, newItem];
}

// returns items in arr1 that are not in arr2
export function getDifference(arr1, arr2) {
    return arr1.filter((x) => !arr2.includes(x));
}

// Gets all items that are in one array and not the other in both directions
export function getSymmetricDifference(arr1, arr2) {
    return arr1.filter((x) => !arr2.includes(x)).concat(arr2.filter((x) => !arr1.includes(x)));
}
