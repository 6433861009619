import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileUpgradeComponent } from './mobile-upgrade.component';
import { MobileUpgradeDialogDirective } from '@type-mvp/app/mobile-upgrade-dialog/mobile-upgrade-dialog.directive';
import { UpgradeCreditCardInputComponent } from '@type-mvp/app/mobile-upgrade-dialog/upgrade-credit-card-input/upgrade-credit-card-input.component';
import { UpgradeService } from '@type-mvp/app/mobile-upgrade-dialog/upgrade.service';
import { PaymentService } from '@type-mvp/app/services/payment.service';
import { DialogModule } from '@type/dialog';
import { UiModule } from '@type/ui';
import { LoaderModule } from '@type/ui/loader';
import { PaymentDialogModule } from '@type/payment-dialog/payment-dialog.module';

const exportItems = [MobileUpgradeDialogDirective];

@NgModule({
    imports: [CommonModule, UiModule, DialogModule, LoaderModule, PaymentDialogModule],
    declarations: [MobileUpgradeComponent, UpgradeCreditCardInputComponent, ...exportItems],
    exports: [...exportItems],
    providers: [UpgradeService, PaymentService]
})
export class MobileUpgradeDialogModule {}
