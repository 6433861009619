import { environment } from '../environment';

// TODO: Add correct paths
export const transcriptionExample = {
    videoFileURL: environment.production
        ? 'https://storage.googleapis.com/type-mvp.appspot.com/videos/public/example_video/example_h264.mp4'
        : 'https://storage.googleapis.com/type-mvp-development.appspot.com/videos/public/example_video/example_h264.mp4',
    videoURI: 'videos/public/example_video/example_h264.mp4',
    transcription: [
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 0,
            endTimeInOriginal: 0.25,
            startTimeInEdit: 0,
            endTimeInEdit: 0.25
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 0.25,
            endTimeInOriginal: 0.5,
            startTimeInEdit: 0.25,
            endTimeInEdit: 0.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 0.5,
            endTimeInOriginal: 0.75,
            startTimeInEdit: 0.5,
            endTimeInEdit: 0.75
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 0.75,
            endTimeInOriginal: 1,
            startTimeInEdit: 0.75,
            endTimeInEdit: 1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 1,
            endTimeInOriginal: 1.25,
            startTimeInEdit: 1,
            endTimeInEdit: 1.25
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 1.25,
            endTimeInOriginal: 1.5,
            startTimeInEdit: 1.25,
            endTimeInEdit: 1.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 1.5,
            endTimeInOriginal: 1.75,
            startTimeInEdit: 1.5,
            endTimeInEdit: 1.75
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 1.75,
            endTimeInOriginal: 2,
            startTimeInEdit: 1.75,
            endTimeInEdit: 2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 2,
            endTimeInOriginal: 2.25,
            startTimeInEdit: 2,
            endTimeInEdit: 2.25
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 2.25,
            endTimeInOriginal: 2.5,
            startTimeInEdit: 2.25,
            endTimeInEdit: 2.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 2.5,
            endTimeInOriginal: 2.75,
            startTimeInEdit: 2.5,
            endTimeInEdit: 2.75
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 2.75,
            endTimeInOriginal: 3,
            startTimeInEdit: 2.75,
            endTimeInEdit: 3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 3,
            endTimeInOriginal: 3.25,
            startTimeInEdit: 3,
            endTimeInEdit: 3.25
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 3.25,
            endTimeInOriginal: 3.5,
            startTimeInEdit: 3.25,
            endTimeInEdit: 3.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 3.5,
            endTimeInOriginal: 3.75,
            startTimeInEdit: 3.5,
            endTimeInEdit: 3.75
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Oh',
            startTimeInOriginal: 3.75,
            endTimeInOriginal: 3.9,
            startTimeInEdit: 3.75,
            endTimeInEdit: 3.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'hi,',
            startTimeInOriginal: 3.9,
            endTimeInOriginal: 4.4,
            startTimeInEdit: 3.9,
            endTimeInEdit: 4.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 4.4,
            endTimeInOriginal: 4.5,
            startTimeInEdit: 4.4,
            endTimeInEdit: 4.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 4.5,
            endTimeInOriginal: 4.6,
            startTimeInEdit: 4.5,
            endTimeInEdit: 4.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'welcome',
            startTimeInOriginal: 4.6,
            endTimeInOriginal: 4.8,
            startTimeInEdit: 4.6,
            endTimeInEdit: 4.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 4.8,
            endTimeInOriginal: 4.9,
            startTimeInEdit: 4.8,
            endTimeInEdit: 4.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Type',
            startTimeInOriginal: 4.9,
            endTimeInOriginal: 5.1,
            startTimeInEdit: 4.9,
            endTimeInEdit: 5.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Studio.',
            startTimeInOriginal: 5.1,
            endTimeInOriginal: 5.6,
            startTimeInEdit: 5.1,
            endTimeInEdit: 5.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'If',
            startTimeInOriginal: 5.6,
            endTimeInOriginal: 5.7,
            startTimeInEdit: 5.6,
            endTimeInEdit: 5.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "you're",
            startTimeInOriginal: 5.7,
            endTimeInOriginal: 5.8,
            startTimeInEdit: 5.7,
            endTimeInEdit: 5.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'watching',
            startTimeInOriginal: 5.8,
            endTimeInOriginal: 6.1,
            startTimeInEdit: 5.8,
            endTimeInEdit: 6.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'this',
            startTimeInOriginal: 6.1,
            endTimeInOriginal: 6.2,
            startTimeInEdit: 6.1,
            endTimeInEdit: 6.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video,',
            startTimeInOriginal: 6.2,
            endTimeInOriginal: 6.7,
            startTimeInEdit: 6.2,
            endTimeInEdit: 6.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 6.7,
            endTimeInOriginal: 6.8,
            startTimeInEdit: 6.7,
            endTimeInEdit: 6.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'probably',
            startTimeInOriginal: 6.8,
            endTimeInOriginal: 7.1,
            startTimeInEdit: 6.8,
            endTimeInEdit: 7.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'means',
            startTimeInOriginal: 7.1,
            endTimeInOriginal: 7.4,
            startTimeInEdit: 7.1,
            endTimeInEdit: 7.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 7.4,
            endTimeInOriginal: 7.5,
            startTimeInEdit: 7.4,
            endTimeInEdit: 7.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "you've",
            startTimeInOriginal: 7.5,
            endTimeInOriginal: 7.9,
            startTimeInEdit: 7.5,
            endTimeInEdit: 7.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'clicked',
            startTimeInOriginal: 7.9,
            endTimeInOriginal: 8.2,
            startTimeInEdit: 7.9,
            endTimeInEdit: 8.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 8.2,
            endTimeInOriginal: 8.3,
            startTimeInEdit: 8.2,
            endTimeInEdit: 8.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'option',
            startTimeInOriginal: 8.3,
            endTimeInOriginal: 8.6,
            startTimeInEdit: 8.3,
            endTimeInEdit: 8.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 8.6,
            endTimeInOriginal: 8.7,
            startTimeInEdit: 8.6,
            endTimeInEdit: 8.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do',
            startTimeInOriginal: 8.7,
            endTimeInOriginal: 9,
            startTimeInEdit: 8.7,
            endTimeInEdit: 9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: '"Example',
            startTimeInOriginal: 9,
            endTimeInOriginal: 9.5,
            startTimeInEdit: 9,
            endTimeInEdit: 9.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Video,"',
            startTimeInOriginal: 9.5,
            endTimeInOriginal: 10.1,
            startTimeInEdit: 9.5,
            endTimeInEdit: 10.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 10.1,
            endTimeInOriginal: 10.299999999999999,
            startTimeInEdit: 10.1,
            endTimeInEdit: 10.299999999999999
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 10.3,
            endTimeInOriginal: 10.5,
            startTimeInEdit: 10.3,
            endTimeInEdit: 10.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'now',
            startTimeInOriginal: 10.5,
            endTimeInOriginal: 10.6,
            startTimeInEdit: 10.5,
            endTimeInEdit: 10.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "you're",
            startTimeInOriginal: 10.6,
            endTimeInOriginal: 10.7,
            startTimeInEdit: 10.6,
            endTimeInEdit: 10.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'trying',
            startTimeInOriginal: 10.7,
            endTimeInOriginal: 10.9,
            startTimeInEdit: 10.7,
            endTimeInEdit: 10.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 10.9,
            endTimeInOriginal: 11,
            startTimeInEdit: 10.9,
            endTimeInEdit: 11
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'figure',
            startTimeInOriginal: 11,
            endTimeInOriginal: 11.2,
            startTimeInEdit: 11,
            endTimeInEdit: 11.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'out',
            startTimeInOriginal: 11.2,
            endTimeInOriginal: 11.3,
            startTimeInEdit: 11.2,
            endTimeInEdit: 11.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'how',
            startTimeInOriginal: 11.3,
            endTimeInOriginal: 11.6,
            startTimeInEdit: 11.3,
            endTimeInEdit: 11.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 11.6,
            endTimeInOriginal: 11.7,
            startTimeInEdit: 11.6,
            endTimeInEdit: 11.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'all',
            startTimeInOriginal: 11.7,
            endTimeInOriginal: 11.8,
            startTimeInEdit: 11.7,
            endTimeInEdit: 11.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'this',
            startTimeInOriginal: 11.8,
            endTimeInOriginal: 12,
            startTimeInEdit: 11.8,
            endTimeInEdit: 12
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'stuff',
            startTimeInOriginal: 12,
            endTimeInOriginal: 12.2,
            startTimeInEdit: 12,
            endTimeInEdit: 12.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'works.',
            startTimeInOriginal: 12.2,
            endTimeInOriginal: 12.6,
            startTimeInEdit: 12.2,
            endTimeInEdit: 12.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 12.6,
            endTimeInOriginal: 12.7,
            startTimeInEdit: 12.6,
            endTimeInEdit: 12.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'All',
            startTimeInOriginal: 12.7,
            endTimeInOriginal: 12.8,
            startTimeInEdit: 12.7,
            endTimeInEdit: 12.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'right,',
            startTimeInOriginal: 12.8,
            endTimeInOriginal: 13.2,
            startTimeInEdit: 12.8,
            endTimeInEdit: 13.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 13.2,
            endTimeInOriginal: 13.399999999999999,
            startTimeInEdit: 13.2,
            endTimeInEdit: 13.399999999999999
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'so',
            startTimeInOriginal: 13.4,
            endTimeInOriginal: 13.5,
            startTimeInEdit: 13.4,
            endTimeInEdit: 13.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "I'm",
            startTimeInOriginal: 13.5,
            endTimeInOriginal: 13.6,
            startTimeInEdit: 13.5,
            endTimeInEdit: 13.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'gonna',
            startTimeInOriginal: 13.6,
            endTimeInOriginal: 13.7,
            startTimeInEdit: 13.6,
            endTimeInEdit: 13.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do',
            startTimeInOriginal: 13.7,
            endTimeInOriginal: 13.9,
            startTimeInEdit: 13.7,
            endTimeInEdit: 13.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 13.9,
            endTimeInOriginal: 14,
            startTimeInEdit: 13.9,
            endTimeInEdit: 14
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 14,
            endTimeInOriginal: 14,
            startTimeInEdit: 14,
            endTimeInEdit: 14
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'favor.',
            startTimeInOriginal: 14,
            endTimeInOriginal: 14.6,
            startTimeInEdit: 14,
            endTimeInEdit: 14.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 14.6,
            endTimeInOriginal: 14.7,
            startTimeInEdit: 14.6,
            endTimeInEdit: 14.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "I'm",
            startTimeInOriginal: 14.7,
            endTimeInOriginal: 14.8,
            startTimeInEdit: 14.7,
            endTimeInEdit: 14.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'gonna',
            startTimeInOriginal: 14.8,
            endTimeInOriginal: 14.9,
            startTimeInEdit: 14.8,
            endTimeInEdit: 14.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'break',
            startTimeInOriginal: 14.9,
            endTimeInOriginal: 15.1,
            startTimeInEdit: 14.9,
            endTimeInEdit: 15.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'this',
            startTimeInOriginal: 15.1,
            endTimeInOriginal: 15.2,
            startTimeInEdit: 15.1,
            endTimeInEdit: 15.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'down',
            startTimeInOriginal: 15.2,
            endTimeInOriginal: 15.5,
            startTimeInEdit: 15.2,
            endTimeInEdit: 15.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'into',
            startTimeInOriginal: 15.5,
            endTimeInOriginal: 15.6,
            startTimeInEdit: 15.5,
            endTimeInEdit: 15.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'five',
            startTimeInOriginal: 15.6,
            endTimeInOriginal: 15.9,
            startTimeInEdit: 15.6,
            endTimeInEdit: 15.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'different',
            startTimeInOriginal: 15.9,
            endTimeInOriginal: 16.2,
            startTimeInEdit: 15.9,
            endTimeInEdit: 16.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'parts,',
            startTimeInOriginal: 16.2,
            endTimeInOriginal: 16.7,
            startTimeInEdit: 16.2,
            endTimeInEdit: 16.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 16.7,
            endTimeInOriginal: 16.9,
            startTimeInEdit: 16.7,
            endTimeInEdit: 16.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'so',
            startTimeInOriginal: 16.9,
            endTimeInOriginal: 17,
            startTimeInEdit: 16.9,
            endTimeInEdit: 17
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 17,
            endTimeInOriginal: 17.3,
            startTimeInEdit: 17,
            endTimeInEdit: 17.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'each',
            startTimeInOriginal: 17.3,
            endTimeInOriginal: 17.5,
            startTimeInEdit: 17.3,
            endTimeInEdit: 17.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'part',
            startTimeInOriginal: 17.5,
            endTimeInOriginal: 18,
            startTimeInEdit: 17.5,
            endTimeInEdit: 18
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "you'll",
            startTimeInOriginal: 18,
            endTimeInOriginal: 18.2,
            startTimeInEdit: 18,
            endTimeInEdit: 18.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'learn',
            startTimeInOriginal: 18.2,
            endTimeInOriginal: 18.5,
            startTimeInEdit: 18.2,
            endTimeInEdit: 18.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 18.5,
            endTimeInOriginal: 18.5,
            startTimeInEdit: 18.5,
            endTimeInEdit: 18.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'different',
            startTimeInOriginal: 18.5,
            endTimeInOriginal: 18.9,
            startTimeInEdit: 18.5,
            endTimeInEdit: 18.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'feature',
            startTimeInOriginal: 18.9,
            endTimeInOriginal: 19.5,
            startTimeInEdit: 18.9,
            endTimeInEdit: 19.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 19.5,
            endTimeInOriginal: 19.6,
            startTimeInEdit: 19.5,
            endTimeInEdit: 19.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 19.6,
            endTimeInOriginal: 19.7,
            startTimeInEdit: 19.6,
            endTimeInEdit: 19.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Type',
            startTimeInOriginal: 19.7,
            endTimeInOriginal: 20,
            startTimeInEdit: 19.7,
            endTimeInEdit: 20
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Studio.',
            startTimeInOriginal: 20,
            endTimeInOriginal: 20.5,
            startTimeInEdit: 20,
            endTimeInEdit: 20.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'That',
            startTimeInOriginal: 20.5,
            endTimeInOriginal: 20.7,
            startTimeInEdit: 20.5,
            endTimeInEdit: 20.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'way',
            startTimeInOriginal: 20.7,
            endTimeInOriginal: 20.9,
            startTimeInEdit: 20.7,
            endTimeInEdit: 20.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'also,',
            startTimeInOriginal: 20.9,
            endTimeInOriginal: 21.2,
            startTimeInEdit: 20.9,
            endTimeInEdit: 21.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'if',
            startTimeInOriginal: 21.2,
            endTimeInOriginal: 21.3,
            startTimeInEdit: 21.2,
            endTimeInEdit: 21.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "you're",
            startTimeInOriginal: 21.3,
            endTimeInOriginal: 21.4,
            startTimeInEdit: 21.3,
            endTimeInEdit: 21.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'only',
            startTimeInOriginal: 21.4,
            endTimeInOriginal: 21.6,
            startTimeInEdit: 21.4,
            endTimeInEdit: 21.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'here',
            startTimeInOriginal: 21.6,
            endTimeInOriginal: 21.7,
            startTimeInEdit: 21.6,
            endTimeInEdit: 21.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 21.7,
            endTimeInOriginal: 21.9,
            startTimeInEdit: 21.7,
            endTimeInEdit: 21.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'try',
            startTimeInOriginal: 21.9,
            endTimeInOriginal: 22.1,
            startTimeInEdit: 21.9,
            endTimeInEdit: 22.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 22.1,
            endTimeInOriginal: 22.2,
            startTimeInEdit: 22.1,
            endTimeInEdit: 22.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'figure',
            startTimeInOriginal: 22.2,
            endTimeInOriginal: 22.4,
            startTimeInEdit: 22.2,
            endTimeInEdit: 22.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'out',
            startTimeInOriginal: 22.4,
            endTimeInOriginal: 22.5,
            startTimeInEdit: 22.4,
            endTimeInEdit: 22.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'one',
            startTimeInOriginal: 22.5,
            endTimeInOriginal: 22.7,
            startTimeInEdit: 22.5,
            endTimeInEdit: 22.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 22.7,
            endTimeInOriginal: 22.7,
            startTimeInEdit: 22.7,
            endTimeInEdit: 22.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'two',
            startTimeInOriginal: 22.7,
            endTimeInOriginal: 22.8,
            startTimeInEdit: 22.7,
            endTimeInEdit: 22.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'things',
            startTimeInOriginal: 22.8,
            endTimeInOriginal: 23.3,
            startTimeInEdit: 22.8,
            endTimeInEdit: 23.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 23.3,
            endTimeInOriginal: 23.5,
            startTimeInEdit: 23.3,
            endTimeInEdit: 23.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 23.5,
            endTimeInOriginal: 23.6,
            startTimeInEdit: 23.5,
            endTimeInEdit: 23.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 23.6,
            endTimeInOriginal: 23.7,
            startTimeInEdit: 23.6,
            endTimeInEdit: 23.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'skip',
            startTimeInOriginal: 23.7,
            endTimeInOriginal: 23.9,
            startTimeInEdit: 23.7,
            endTimeInEdit: 23.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 23.9,
            endTimeInOriginal: 23.9,
            startTimeInEdit: 23.9,
            endTimeInEdit: 23.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'those',
            startTimeInOriginal: 23.9,
            endTimeInOriginal: 24.1,
            startTimeInEdit: 23.9,
            endTimeInEdit: 24.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'different',
            startTimeInOriginal: 24.1,
            endTimeInOriginal: 24.4,
            startTimeInEdit: 24.1,
            endTimeInEdit: 24.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'parts,',
            startTimeInOriginal: 24.4,
            endTimeInOriginal: 24.8,
            startTimeInEdit: 24.4,
            endTimeInEdit: 24.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'each',
            startTimeInOriginal: 24.8,
            endTimeInOriginal: 25,
            startTimeInEdit: 24.8,
            endTimeInEdit: 25
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 25,
            endTimeInOriginal: 25.1,
            startTimeInEdit: 25,
            endTimeInEdit: 25.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'these',
            startTimeInOriginal: 25.1,
            endTimeInOriginal: 25.2,
            startTimeInEdit: 25.1,
            endTimeInEdit: 25.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'parts',
            startTimeInOriginal: 25.2,
            endTimeInOriginal: 25.5,
            startTimeInEdit: 25.2,
            endTimeInEdit: 25.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'is',
            startTimeInOriginal: 25.5,
            endTimeInOriginal: 25.6,
            startTimeInEdit: 25.5,
            endTimeInEdit: 25.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'going',
            startTimeInOriginal: 25.6,
            endTimeInOriginal: 25.7,
            startTimeInEdit: 25.6,
            endTimeInEdit: 25.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 25.7,
            endTimeInOriginal: 25.7,
            startTimeInEdit: 25.7,
            endTimeInEdit: 25.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'help',
            startTimeInOriginal: 25.7,
            endTimeInOriginal: 25.9,
            startTimeInEdit: 25.7,
            endTimeInEdit: 25.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 25.9,
            endTimeInOriginal: 26,
            startTimeInEdit: 25.9,
            endTimeInEdit: 26
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'understand',
            startTimeInOriginal: 26,
            endTimeInOriginal: 26.5,
            startTimeInEdit: 26,
            endTimeInEdit: 26.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 26.5,
            endTimeInOriginal: 26.6,
            startTimeInEdit: 26.5,
            endTimeInEdit: 26.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'ins',
            startTimeInOriginal: 26.6,
            endTimeInOriginal: 26.8,
            startTimeInEdit: 26.6,
            endTimeInEdit: 26.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 26.8,
            endTimeInOriginal: 27,
            startTimeInEdit: 26.8,
            endTimeInEdit: 27
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'outs',
            startTimeInOriginal: 27,
            endTimeInOriginal: 27.3,
            startTimeInEdit: 27,
            endTimeInEdit: 27.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 27.3,
            endTimeInOriginal: 27.6,
            startTimeInEdit: 27.3,
            endTimeInEdit: 27.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Type',
            startTimeInOriginal: 27.6,
            endTimeInOriginal: 27.8,
            startTimeInEdit: 27.6,
            endTimeInEdit: 27.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Studio,',
            startTimeInOriginal: 27.8,
            endTimeInOriginal: 28.5,
            startTimeInEdit: 27.8,
            endTimeInEdit: 28.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'so',
            startTimeInOriginal: 28.5,
            endTimeInOriginal: 28.6,
            startTimeInEdit: 28.5,
            endTimeInEdit: 28.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 28.6,
            endTimeInOriginal: 28.7,
            startTimeInEdit: 28.6,
            endTimeInEdit: 28.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 28.7,
            endTimeInOriginal: 28.8,
            startTimeInEdit: 28.7,
            endTimeInEdit: 28.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'go',
            startTimeInOriginal: 28.8,
            endTimeInOriginal: 29,
            startTimeInEdit: 28.8,
            endTimeInEdit: 29
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'off',
            startTimeInOriginal: 29,
            endTimeInOriginal: 29.2,
            startTimeInEdit: 29,
            endTimeInEdit: 29.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 29.2,
            endTimeInOriginal: 29.4,
            startTimeInEdit: 29.2,
            endTimeInEdit: 29.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'create',
            startTimeInOriginal: 29.4,
            endTimeInOriginal: 30.1,
            startTimeInEdit: 29.4,
            endTimeInEdit: 30.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 30.1,
            endTimeInOriginal: 30.3,
            startTimeInEdit: 30.1,
            endTimeInEdit: 30.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'own',
            startTimeInOriginal: 30.3,
            endTimeInOriginal: 30.4,
            startTimeInEdit: 30.3,
            endTimeInEdit: 30.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 30.4,
            endTimeInOriginal: 30.599999999999998,
            startTimeInEdit: 30.4,
            endTimeInEdit: 30.599999999999998
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video',
            startTimeInOriginal: 30.6,
            endTimeInOriginal: 30.8,
            startTimeInEdit: 30.6,
            endTimeInEdit: 30.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'masterpieces,',
            startTimeInOriginal: 30.8,
            endTimeInOriginal: 31.8,
            startTimeInEdit: 30.8,
            endTimeInEdit: 31.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 31.8,
            endTimeInOriginal: 31.900000000000002,
            startTimeInEdit: 31.8,
            endTimeInEdit: 31.900000000000002
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'sound',
            startTimeInOriginal: 31.9,
            endTimeInOriginal: 32.2,
            startTimeInEdit: 31.9,
            endTimeInEdit: 32.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'good?',
            startTimeInOriginal: 32.2,
            endTimeInOriginal: 32.5,
            startTimeInEdit: 32.2,
            endTimeInEdit: 32.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 32.5,
            endTimeInOriginal: 32.75,
            startTimeInEdit: 32.5,
            endTimeInEdit: 32.75
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 32.75,
            endTimeInOriginal: 33,
            startTimeInEdit: 32.75,
            endTimeInEdit: 33
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 33,
            endTimeInOriginal: 33.25,
            startTimeInEdit: 33,
            endTimeInEdit: 33.25
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 33.25,
            endTimeInOriginal: 33.5,
            startTimeInEdit: 33.25,
            endTimeInEdit: 33.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Wait,',
            startTimeInOriginal: 33.5,
            endTimeInOriginal: 33.7,
            startTimeInEdit: 33.5,
            endTimeInEdit: 33.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'how',
            startTimeInOriginal: 33.7,
            endTimeInOriginal: 33.8,
            startTimeInEdit: 33.7,
            endTimeInEdit: 33.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'did',
            startTimeInOriginal: 33.8,
            endTimeInOriginal: 34,
            startTimeInEdit: 33.8,
            endTimeInEdit: 34
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'I',
            startTimeInOriginal: 34,
            endTimeInOriginal: 34.1,
            startTimeInEdit: 34,
            endTimeInEdit: 34.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'get',
            startTimeInOriginal: 34.1,
            endTimeInOriginal: 34.3,
            startTimeInEdit: 34.1,
            endTimeInEdit: 34.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: true,
            text: 'here?',
            startTimeInOriginal: 34.3,
            endTimeInOriginal: 34.8,
            startTimeInEdit: 34.3,
            endTimeInEdit: 34.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Part',
            startTimeInOriginal: 34.8,
            endTimeInOriginal: 35.1,
            startTimeInEdit: 34.8,
            endTimeInEdit: 35.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: '1:',
            startTimeInOriginal: 35.1,
            endTimeInOriginal: 35.5,
            startTimeInEdit: 35.1,
            endTimeInEdit: 35.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Cut',
            startTimeInOriginal: 35.5,
            endTimeInOriginal: 35.75,
            startTimeInEdit: 35.5,
            endTimeInEdit: 35.75
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: '&',
            startTimeInOriginal: 35.75,
            endTimeInOriginal: 35.8,
            startTimeInEdit: 35.75,
            endTimeInEdit: 35.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Edit',
            startTimeInOriginal: 35.8,
            endTimeInOriginal: 36.1,
            startTimeInEdit: 35.8,
            endTimeInEdit: 36.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Your',
            startTimeInOriginal: 36.1,
            endTimeInOriginal: 36.4,
            startTimeInEdit: 36.1,
            endTimeInEdit: 36.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Video',
            startTimeInOriginal: 36.4,
            endTimeInOriginal: 36.6,
            startTimeInEdit: 36.4,
            endTimeInEdit: 36.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 36.6,
            endTimeInOriginal: 36.7,
            startTimeInEdit: 36.6,
            endTimeInEdit: 36.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 36.7,
            endTimeInOriginal: 37.2,
            startTimeInEdit: 36.7,
            endTimeInEdit: 37.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 37.2,
            endTimeInOriginal: 37.45,
            startTimeInEdit: 37.2,
            endTimeInEdit: 37.45
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 37.45,
            endTimeInOriginal: 37.7,
            startTimeInEdit: 37.45,
            endTimeInEdit: 37.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: true,
            text: '',
            startTimeInOriginal: 37.7,
            endTimeInOriginal: 37.900000000000006,
            startTimeInEdit: 37.7,
            endTimeInEdit: 37.900000000000006
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Right',
            startTimeInOriginal: 37.9,
            endTimeInOriginal: 38.1,
            startTimeInEdit: 37.9,
            endTimeInEdit: 38.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'now,',
            startTimeInOriginal: 38.1,
            endTimeInOriginal: 38.3,
            startTimeInEdit: 38.1,
            endTimeInEdit: 38.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 38.3,
            endTimeInOriginal: 38.4,
            startTimeInEdit: 38.3,
            endTimeInEdit: 38.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'should',
            startTimeInOriginal: 38.4,
            endTimeInOriginal: 38.5,
            startTimeInEdit: 38.4,
            endTimeInEdit: 38.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'see',
            startTimeInOriginal: 38.5,
            endTimeInOriginal: 38.7,
            startTimeInEdit: 38.5,
            endTimeInEdit: 38.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'my',
            startTimeInOriginal: 38.7,
            endTimeInOriginal: 38.9,
            startTimeInEdit: 38.7,
            endTimeInEdit: 38.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'face',
            startTimeInOriginal: 38.9,
            endTimeInOriginal: 39.2,
            startTimeInEdit: 38.9,
            endTimeInEdit: 39.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 39.2,
            endTimeInOriginal: 39.3,
            startTimeInEdit: 39.2,
            endTimeInEdit: 39.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 39.3,
            endTimeInOriginal: 39.3,
            startTimeInEdit: 39.3,
            endTimeInEdit: 39.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'right',
            startTimeInOriginal: 39.3,
            endTimeInOriginal: 39.6,
            startTimeInEdit: 39.3,
            endTimeInEdit: 39.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'side',
            startTimeInOriginal: 39.6,
            endTimeInOriginal: 39.7,
            startTimeInEdit: 39.6,
            endTimeInEdit: 39.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 39.7,
            endTimeInOriginal: 39.8,
            startTimeInEdit: 39.7,
            endTimeInEdit: 39.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 39.8,
            endTimeInOriginal: 39.9,
            startTimeInEdit: 39.8,
            endTimeInEdit: 39.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'screen',
            startTimeInOriginal: 39.9,
            endTimeInOriginal: 40.2,
            startTimeInEdit: 39.9,
            endTimeInEdit: 40.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 40.2,
            endTimeInOriginal: 40.4,
            startTimeInEdit: 40.2,
            endTimeInEdit: 40.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'transcribe',
            startTimeInOriginal: 40.4,
            endTimeInOriginal: 40.9,
            startTimeInEdit: 40.4,
            endTimeInEdit: 40.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'dialogue',
            startTimeInOriginal: 40.9,
            endTimeInOriginal: 41.3,
            startTimeInEdit: 40.9,
            endTimeInEdit: 41.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 41.3,
            endTimeInOriginal: 41.5,
            startTimeInEdit: 41.3,
            endTimeInEdit: 41.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 41.5,
            endTimeInOriginal: 41.6,
            startTimeInEdit: 41.5,
            endTimeInEdit: 41.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'left.',
            startTimeInOriginal: 41.6,
            endTimeInOriginal: 42,
            startTimeInEdit: 41.6,
            endTimeInEdit: 42
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'If',
            startTimeInOriginal: 42,
            endTimeInOriginal: 42.2,
            startTimeInEdit: 42,
            endTimeInEdit: 42.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'so',
            startTimeInOriginal: 42.2,
            endTimeInOriginal: 42.6,
            startTimeInEdit: 42.2,
            endTimeInEdit: 42.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "that's",
            startTimeInOriginal: 42.6,
            endTimeInOriginal: 42.8,
            startTimeInEdit: 42.6,
            endTimeInEdit: 42.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'good.',
            startTimeInOriginal: 42.8,
            endTimeInOriginal: 43.3,
            startTimeInEdit: 42.8,
            endTimeInEdit: 43.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'If',
            startTimeInOriginal: 43.3,
            endTimeInOriginal: 43.5,
            startTimeInEdit: 43.3,
            endTimeInEdit: 43.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'not,',
            startTimeInOriginal: 43.5,
            endTimeInOriginal: 44.1,
            startTimeInEdit: 43.5,
            endTimeInEdit: 44.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 44.1,
            endTimeInOriginal: 44.35,
            startTimeInEdit: 44.1,
            endTimeInEdit: 44.35
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 44.35,
            endTimeInOriginal: 44.6,
            startTimeInEdit: 44.35,
            endTimeInEdit: 44.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 44.6,
            endTimeInOriginal: 44.7,
            startTimeInEdit: 44.6,
            endTimeInEdit: 44.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'I',
            startTimeInOriginal: 44.7,
            endTimeInOriginal: 44.8,
            startTimeInEdit: 44.7,
            endTimeInEdit: 44.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "don't",
            startTimeInOriginal: 44.8,
            endTimeInOriginal: 44.9,
            startTimeInEdit: 44.8,
            endTimeInEdit: 44.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'know',
            startTimeInOriginal: 44.9,
            endTimeInOriginal: 45,
            startTimeInEdit: 44.9,
            endTimeInEdit: 45
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'man.',
            startTimeInOriginal: 45,
            endTimeInOriginal: 45.4,
            startTimeInEdit: 45,
            endTimeInEdit: 45.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 45.4,
            endTimeInOriginal: 45.5,
            startTimeInEdit: 45.4,
            endTimeInEdit: 45.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'But',
            startTimeInOriginal: 45.5,
            endTimeInOriginal: 45.6,
            startTimeInEdit: 45.5,
            endTimeInEdit: 45.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'either',
            startTimeInOriginal: 45.6,
            endTimeInOriginal: 45.8,
            startTimeInEdit: 45.6,
            endTimeInEdit: 45.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'way',
            startTimeInOriginal: 45.8,
            endTimeInOriginal: 45.9,
            startTimeInEdit: 45.8,
            endTimeInEdit: 45.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 45.9,
            endTimeInOriginal: 46,
            startTimeInEdit: 45.9,
            endTimeInEdit: 46
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'cut',
            startTimeInOriginal: 46,
            endTimeInOriginal: 46.2,
            startTimeInEdit: 46,
            endTimeInEdit: 46.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 46.2,
            endTimeInOriginal: 46.3,
            startTimeInEdit: 46.2,
            endTimeInEdit: 46.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video',
            startTimeInOriginal: 46.3,
            endTimeInOriginal: 46.6,
            startTimeInEdit: 46.3,
            endTimeInEdit: 46.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 46.6,
            endTimeInOriginal: 46.7,
            startTimeInEdit: 46.6,
            endTimeInEdit: 46.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Type,',
            startTimeInOriginal: 46.7,
            endTimeInOriginal: 47,
            startTimeInEdit: 46.7,
            endTimeInEdit: 47
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "you're",
            startTimeInOriginal: 47,
            endTimeInOriginal: 47.1,
            startTimeInEdit: 47,
            endTimeInEdit: 47.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'going',
            startTimeInOriginal: 47.1,
            endTimeInOriginal: 47.2,
            startTimeInEdit: 47.1,
            endTimeInEdit: 47.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 47.2,
            endTimeInOriginal: 47.3,
            startTimeInEdit: 47.2,
            endTimeInEdit: 47.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'use',
            startTimeInOriginal: 47.3,
            endTimeInOriginal: 47.5,
            startTimeInEdit: 47.3,
            endTimeInEdit: 47.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 47.5,
            endTimeInOriginal: 47.7,
            startTimeInEdit: 47.5,
            endTimeInEdit: 47.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'text',
            startTimeInOriginal: 47.7,
            endTimeInOriginal: 48,
            startTimeInEdit: 47.7,
            endTimeInEdit: 48
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 48,
            endTimeInOriginal: 48.1,
            startTimeInEdit: 48,
            endTimeInEdit: 48.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 48.1,
            endTimeInOriginal: 48.2,
            startTimeInEdit: 48.1,
            endTimeInEdit: 48.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'left.',
            startTimeInOriginal: 48.2,
            endTimeInOriginal: 48.6,
            startTimeInEdit: 48.2,
            endTimeInEdit: 48.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'By',
            startTimeInOriginal: 48.6,
            endTimeInOriginal: 48.8,
            startTimeInEdit: 48.6,
            endTimeInEdit: 48.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'selecting',
            startTimeInOriginal: 48.8,
            endTimeInOriginal: 49.2,
            startTimeInEdit: 48.8,
            endTimeInEdit: 49.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 49.2,
            endTimeInOriginal: 49.3,
            startTimeInEdit: 49.2,
            endTimeInEdit: 49.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'word',
            startTimeInOriginal: 49.3,
            endTimeInOriginal: 49.7,
            startTimeInEdit: 49.3,
            endTimeInEdit: 49.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 49.7,
            endTimeInOriginal: 50,
            startTimeInEdit: 49.7,
            endTimeInEdit: 50
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 50,
            endTimeInOriginal: 50.2,
            startTimeInEdit: 50,
            endTimeInEdit: 50.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'group',
            startTimeInOriginal: 50.2,
            endTimeInOriginal: 50.4,
            startTimeInEdit: 50.2,
            endTimeInEdit: 50.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 50.4,
            endTimeInOriginal: 50.6,
            startTimeInEdit: 50.4,
            endTimeInEdit: 50.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'words,',
            startTimeInOriginal: 50.6,
            endTimeInOriginal: 51,
            startTimeInEdit: 50.6,
            endTimeInEdit: 51
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 51,
            endTimeInOriginal: 51.2,
            startTimeInEdit: 51,
            endTimeInEdit: 51.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 51.2,
            endTimeInOriginal: 51.3,
            startTimeInEdit: 51.2,
            endTimeInEdit: 51.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'phrase,',
            startTimeInOriginal: 51.3,
            endTimeInOriginal: 51.8,
            startTimeInEdit: 51.3,
            endTimeInEdit: 51.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'some',
            startTimeInOriginal: 51.8,
            endTimeInOriginal: 52,
            startTimeInEdit: 51.8,
            endTimeInEdit: 52
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'would',
            startTimeInOriginal: 52,
            endTimeInOriginal: 52.1,
            startTimeInEdit: 52,
            endTimeInEdit: 52.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'call',
            startTimeInOriginal: 52.1,
            endTimeInOriginal: 52.4,
            startTimeInEdit: 52.1,
            endTimeInEdit: 52.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it,',
            startTimeInOriginal: 52.4,
            endTimeInOriginal: 52.8,
            startTimeInEdit: 52.4,
            endTimeInEdit: 52.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 52.8,
            endTimeInOriginal: 52.9,
            startTimeInEdit: 52.8,
            endTimeInEdit: 52.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'then',
            startTimeInOriginal: 52.9,
            endTimeInOriginal: 53.1,
            startTimeInEdit: 52.9,
            endTimeInEdit: 53.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'either',
            startTimeInOriginal: 53.1,
            endTimeInOriginal: 53.4,
            startTimeInEdit: 53.1,
            endTimeInEdit: 53.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'hitting',
            startTimeInOriginal: 53.4,
            endTimeInOriginal: 53.7,
            startTimeInEdit: 53.4,
            endTimeInEdit: 53.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 53.7,
            endTimeInOriginal: 54,
            startTimeInEdit: 53.7,
            endTimeInEdit: 54
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'delete',
            startTimeInOriginal: 54,
            endTimeInOriginal: 54.4,
            startTimeInEdit: 54,
            endTimeInEdit: 54.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'key',
            startTimeInOriginal: 54.4,
            endTimeInOriginal: 54.8,
            startTimeInEdit: 54.4,
            endTimeInEdit: 54.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 54.8,
            endTimeInOriginal: 55,
            startTimeInEdit: 54.8,
            endTimeInEdit: 55
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 55,
            endTimeInOriginal: 55.3,
            startTimeInEdit: 55,
            endTimeInEdit: 55.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 55.3,
            endTimeInOriginal: 55.9,
            startTimeInEdit: 55.3,
            endTimeInEdit: 55.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'cut',
            startTimeInOriginal: 55.9,
            endTimeInOriginal: 56.2,
            startTimeInEdit: 55.9,
            endTimeInEdit: 56.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'key',
            startTimeInOriginal: 56.2,
            endTimeInOriginal: 56.5,
            startTimeInEdit: 56.2,
            endTimeInEdit: 56.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'above',
            startTimeInOriginal: 56.5,
            endTimeInOriginal: 56.9,
            startTimeInEdit: 56.5,
            endTimeInEdit: 56.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 56.9,
            endTimeInOriginal: 57,
            startTimeInEdit: 56.9,
            endTimeInEdit: 57
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'transcribe',
            startTimeInOriginal: 57,
            endTimeInOriginal: 57.6,
            startTimeInEdit: 57,
            endTimeInEdit: 57.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'dialogue,',
            startTimeInOriginal: 57.6,
            endTimeInOriginal: 58.2,
            startTimeInEdit: 57.6,
            endTimeInEdit: 58.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 58.2,
            endTimeInOriginal: 58.4,
            startTimeInEdit: 58.2,
            endTimeInEdit: 58.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'by',
            startTimeInOriginal: 58.4,
            endTimeInOriginal: 58.6,
            startTimeInEdit: 58.4,
            endTimeInEdit: 58.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'clicking,',
            startTimeInOriginal: 58.6,
            endTimeInOriginal: 59,
            startTimeInEdit: 58.6,
            endTimeInEdit: 59
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 59,
            endTimeInOriginal: 59,
            startTimeInEdit: 59,
            endTimeInEdit: 59
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'little',
            startTimeInOriginal: 59,
            endTimeInOriginal: 59.3,
            startTimeInEdit: 59,
            endTimeInEdit: 59.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'delete',
            startTimeInOriginal: 59.3,
            endTimeInOriginal: 59.9,
            startTimeInEdit: 59.3,
            endTimeInEdit: 59.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'icon',
            startTimeInOriginal: 59.9,
            endTimeInOriginal: 60.3,
            startTimeInEdit: 59.9,
            endTimeInEdit: 60.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 60.3,
            endTimeInOriginal: 60.4,
            startTimeInEdit: 60.3,
            endTimeInEdit: 60.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 60.4,
            endTimeInOriginal: 60.5,
            startTimeInEdit: 60.4,
            endTimeInEdit: 60.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'pops',
            startTimeInOriginal: 60.5,
            endTimeInOriginal: 60.9,
            startTimeInEdit: 60.5,
            endTimeInEdit: 60.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'up',
            startTimeInOriginal: 60.9,
            endTimeInOriginal: 61,
            startTimeInEdit: 60.9,
            endTimeInEdit: 61
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'after',
            startTimeInOriginal: 61,
            endTimeInOriginal: 61.2,
            startTimeInEdit: 61,
            endTimeInEdit: 61.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 61.2,
            endTimeInOriginal: 61.3,
            startTimeInEdit: 61.2,
            endTimeInEdit: 61.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'select',
            startTimeInOriginal: 61.3,
            endTimeInOriginal: 61.7,
            startTimeInEdit: 61.3,
            endTimeInEdit: 61.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 61.7,
            endTimeInOriginal: 61.7,
            startTimeInEdit: 61.7,
            endTimeInEdit: 61.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'word,',
            startTimeInOriginal: 61.7,
            endTimeInOriginal: 62.2,
            startTimeInEdit: 61.7,
            endTimeInEdit: 62.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 62.2,
            endTimeInOriginal: 62.3,
            startTimeInEdit: 62.2,
            endTimeInEdit: 62.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 62.3,
            endTimeInOriginal: 62.4,
            startTimeInEdit: 62.3,
            endTimeInEdit: 62.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'cut',
            startTimeInOriginal: 62.4,
            endTimeInOriginal: 62.6,
            startTimeInEdit: 62.4,
            endTimeInEdit: 62.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'out',
            startTimeInOriginal: 62.6,
            endTimeInOriginal: 62.7,
            startTimeInEdit: 62.6,
            endTimeInEdit: 62.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'those',
            startTimeInOriginal: 62.7,
            endTimeInOriginal: 62.9,
            startTimeInEdit: 62.7,
            endTimeInEdit: 62.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'certain',
            startTimeInOriginal: 62.9,
            endTimeInOriginal: 63.2,
            startTimeInEdit: 62.9,
            endTimeInEdit: 63.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'portions',
            startTimeInOriginal: 63.2,
            endTimeInOriginal: 63.6,
            startTimeInEdit: 63.2,
            endTimeInEdit: 63.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 63.6,
            endTimeInOriginal: 63.6,
            startTimeInEdit: 63.6,
            endTimeInEdit: 63.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 63.6,
            endTimeInOriginal: 63.7,
            startTimeInEdit: 63.6,
            endTimeInEdit: 63.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video',
            startTimeInOriginal: 63.7,
            endTimeInOriginal: 64,
            startTimeInEdit: 63.7,
            endTimeInEdit: 64
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 64,
            endTimeInOriginal: 64.1,
            startTimeInEdit: 64,
            endTimeInEdit: 64.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'make',
            startTimeInOriginal: 64.1,
            endTimeInOriginal: 64.2,
            startTimeInEdit: 64.1,
            endTimeInEdit: 64.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 64.2,
            endTimeInOriginal: 64.3,
            startTimeInEdit: 64.2,
            endTimeInEdit: 64.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'simple',
            startTimeInOriginal: 64.3,
            endTimeInOriginal: 64.6,
            startTimeInEdit: 64.3,
            endTimeInEdit: 64.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'cut.',
            startTimeInOriginal: 64.6,
            endTimeInOriginal: 65,
            startTimeInEdit: 64.6,
            endTimeInEdit: 65
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Do',
            startTimeInOriginal: 65,
            endTimeInOriginal: 65.2,
            startTimeInEdit: 65,
            endTimeInEdit: 65.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'this',
            startTimeInOriginal: 65.2,
            endTimeInOriginal: 65.3,
            startTimeInEdit: 65.2,
            endTimeInEdit: 65.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'as',
            startTimeInOriginal: 65.3,
            endTimeInOriginal: 65.4,
            startTimeInEdit: 65.3,
            endTimeInEdit: 65.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'many',
            startTimeInOriginal: 65.4,
            endTimeInOriginal: 65.6,
            startTimeInEdit: 65.4,
            endTimeInEdit: 65.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'times',
            startTimeInOriginal: 65.6,
            endTimeInOriginal: 65.8,
            startTimeInEdit: 65.6,
            endTimeInEdit: 65.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'as',
            startTimeInOriginal: 65.8,
            endTimeInOriginal: 66,
            startTimeInEdit: 65.8,
            endTimeInEdit: 66
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "you'd",
            startTimeInOriginal: 66,
            endTimeInOriginal: 66.1,
            startTimeInEdit: 66,
            endTimeInEdit: 66.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'like,',
            startTimeInOriginal: 66.1,
            endTimeInOriginal: 66.4,
            startTimeInEdit: 66.1,
            endTimeInEdit: 66.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'because',
            startTimeInOriginal: 66.4,
            endTimeInOriginal: 66.6,
            startTimeInEdit: 66.4,
            endTimeInEdit: 66.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 66.6,
            endTimeInOriginal: 66.7,
            startTimeInEdit: 66.6,
            endTimeInEdit: 66.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 66.7,
            endTimeInOriginal: 66.8,
            startTimeInEdit: 66.7,
            endTimeInEdit: 66.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'always',
            startTimeInOriginal: 66.8,
            endTimeInOriginal: 67.1,
            startTimeInEdit: 66.8,
            endTimeInEdit: 67.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'undo',
            startTimeInOriginal: 67.1,
            endTimeInOriginal: 67.5,
            startTimeInEdit: 67.1,
            endTimeInEdit: 67.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 67.5,
            endTimeInOriginal: 67.7,
            startTimeInEdit: 67.5,
            endTimeInEdit: 67.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'cut.',
            startTimeInOriginal: 67.7,
            endTimeInOriginal: 68,
            startTimeInEdit: 67.7,
            endTimeInEdit: 68
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'When',
            startTimeInOriginal: 68,
            endTimeInOriginal: 68.1,
            startTimeInEdit: 68,
            endTimeInEdit: 68.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 68.1,
            endTimeInOriginal: 68.2,
            startTimeInEdit: 68.1,
            endTimeInEdit: 68.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'delete',
            startTimeInOriginal: 68.2,
            endTimeInOriginal: 68.6,
            startTimeInEdit: 68.2,
            endTimeInEdit: 68.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 68.6,
            endTimeInOriginal: 68.6,
            startTimeInEdit: 68.6,
            endTimeInEdit: 68.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'word',
            startTimeInOriginal: 68.6,
            endTimeInOriginal: 68.9,
            startTimeInEdit: 68.6,
            endTimeInEdit: 68.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 68.9,
            endTimeInOriginal: 69.1,
            startTimeInEdit: 68.9,
            endTimeInEdit: 69.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'phrase',
            startTimeInOriginal: 69.1,
            endTimeInOriginal: 69.4,
            startTimeInEdit: 69.1,
            endTimeInEdit: 69.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 69.4,
            endTimeInOriginal: 69.6,
            startTimeInEdit: 69.4,
            endTimeInEdit: 69.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "it's",
            startTimeInOriginal: 69.6,
            endTimeInOriginal: 69.7,
            startTimeInEdit: 69.6,
            endTimeInEdit: 69.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'place,',
            startTimeInOriginal: 69.7,
            endTimeInOriginal: 70.1,
            startTimeInEdit: 69.7,
            endTimeInEdit: 70.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: '3 dots',
            startTimeInOriginal: 70.1,
            endTimeInOriginal: 70.8,
            startTimeInEdit: 70.1,
            endTimeInEdit: 70.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'surrounded',
            startTimeInOriginal: 70.8,
            endTimeInOriginal: 71.1,
            startTimeInEdit: 70.8,
            endTimeInEdit: 71.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'by',
            startTimeInOriginal: 71.1,
            endTimeInOriginal: 71.3,
            startTimeInEdit: 71.1,
            endTimeInEdit: 71.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'parentheses',
            startTimeInOriginal: 71.3,
            endTimeInOriginal: 71.9,
            startTimeInEdit: 71.3,
            endTimeInEdit: 71.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 71.9,
            endTimeInOriginal: 72.1,
            startTimeInEdit: 71.9,
            endTimeInEdit: 72.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'appear.',
            startTimeInOriginal: 72.1,
            endTimeInOriginal: 72.4,
            startTimeInEdit: 72.1,
            endTimeInEdit: 72.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'This',
            startTimeInOriginal: 72.4,
            endTimeInOriginal: 72.7,
            startTimeInEdit: 72.4,
            endTimeInEdit: 72.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'icon',
            startTimeInOriginal: 72.7,
            endTimeInOriginal: 73.1,
            startTimeInEdit: 72.7,
            endTimeInEdit: 73.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'represents',
            startTimeInOriginal: 73.1,
            endTimeInOriginal: 73.6,
            startTimeInEdit: 73.1,
            endTimeInEdit: 73.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 73.6,
            endTimeInOriginal: 73.8,
            startTimeInEdit: 73.6,
            endTimeInEdit: 73.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'cut',
            startTimeInOriginal: 73.8,
            endTimeInOriginal: 74.2,
            startTimeInEdit: 73.8,
            endTimeInEdit: 74.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'word',
            startTimeInOriginal: 74.2,
            endTimeInOriginal: 74.5,
            startTimeInEdit: 74.2,
            endTimeInEdit: 74.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 74.5,
            endTimeInOriginal: 74.6,
            startTimeInEdit: 74.5,
            endTimeInEdit: 74.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'phrase',
            startTimeInOriginal: 74.6,
            endTimeInOriginal: 75,
            startTimeInEdit: 74.6,
            endTimeInEdit: 75
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 75,
            endTimeInOriginal: 75.2,
            startTimeInEdit: 75,
            endTimeInEdit: 75.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 75.2,
            endTimeInOriginal: 75.4,
            startTimeInEdit: 75.2,
            endTimeInEdit: 75.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'removed',
            startTimeInOriginal: 75.4,
            endTimeInOriginal: 75.8,
            startTimeInEdit: 75.4,
            endTimeInEdit: 75.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'from',
            startTimeInOriginal: 75.8,
            endTimeInOriginal: 76,
            startTimeInEdit: 75.8,
            endTimeInEdit: 76
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 76,
            endTimeInOriginal: 76.1,
            startTimeInEdit: 76,
            endTimeInEdit: 76.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video',
            startTimeInOriginal: 76.1,
            endTimeInOriginal: 76.7,
            startTimeInEdit: 76.1,
            endTimeInEdit: 76.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 76.7,
            endTimeInOriginal: 76.8,
            startTimeInEdit: 76.7,
            endTimeInEdit: 76.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 76.8,
            endTimeInOriginal: 76.9,
            startTimeInEdit: 76.8,
            endTimeInEdit: 76.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'allows',
            startTimeInOriginal: 76.9,
            endTimeInOriginal: 77.2,
            startTimeInEdit: 76.9,
            endTimeInEdit: 77.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 77.2,
            endTimeInOriginal: 77.4,
            startTimeInEdit: 77.2,
            endTimeInEdit: 77.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'at',
            startTimeInOriginal: 77.4,
            endTimeInOriginal: 77.5,
            startTimeInEdit: 77.4,
            endTimeInEdit: 77.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'any',
            startTimeInOriginal: 77.5,
            endTimeInOriginal: 77.7,
            startTimeInEdit: 77.5,
            endTimeInEdit: 77.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'time,',
            startTimeInOriginal: 77.7,
            endTimeInOriginal: 78,
            startTimeInEdit: 77.7,
            endTimeInEdit: 78
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'if',
            startTimeInOriginal: 78,
            endTimeInOriginal: 78.2,
            startTimeInEdit: 78,
            endTimeInEdit: 78.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 78.2,
            endTimeInOriginal: 78.3,
            startTimeInEdit: 78.2,
            endTimeInEdit: 78.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'click',
            startTimeInOriginal: 78.3,
            endTimeInOriginal: 78.5,
            startTimeInEdit: 78.3,
            endTimeInEdit: 78.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 78.5,
            endTimeInOriginal: 78.7,
            startTimeInEdit: 78.5,
            endTimeInEdit: 78.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'icon,',
            startTimeInOriginal: 78.7,
            endTimeInOriginal: 79.3,
            startTimeInEdit: 78.7,
            endTimeInEdit: 79.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 79.3,
            endTimeInOriginal: 79.39999999999999,
            startTimeInEdit: 79.3,
            endTimeInEdit: 79.39999999999999
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 79.4,
            endTimeInOriginal: 79.5,
            startTimeInEdit: 79.4,
            endTimeInEdit: 79.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'put',
            startTimeInOriginal: 79.5,
            endTimeInOriginal: 79.8,
            startTimeInEdit: 79.5,
            endTimeInEdit: 79.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 79.8,
            endTimeInOriginal: 80.1,
            startTimeInEdit: 79.8,
            endTimeInEdit: 80.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'word',
            startTimeInOriginal: 80.1,
            endTimeInOriginal: 80.3,
            startTimeInEdit: 80.1,
            endTimeInEdit: 80.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 80.3,
            endTimeInOriginal: 80.4,
            startTimeInEdit: 80.3,
            endTimeInEdit: 80.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'phrase',
            startTimeInOriginal: 80.4,
            endTimeInOriginal: 80.9,
            startTimeInEdit: 80.4,
            endTimeInEdit: 80.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'back',
            startTimeInOriginal: 80.9,
            endTimeInOriginal: 81.3,
            startTimeInEdit: 80.9,
            endTimeInEdit: 81.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 81.3,
            endTimeInOriginal: 81.3,
            startTimeInEdit: 81.3,
            endTimeInEdit: 81.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 81.3,
            endTimeInOriginal: 81.5,
            startTimeInEdit: 81.3,
            endTimeInEdit: 81.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video.',
            startTimeInOriginal: 81.5,
            endTimeInOriginal: 82,
            startTimeInEdit: 81.5,
            endTimeInEdit: 82
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'So,',
            startTimeInOriginal: 82,
            endTimeInOriginal: 82.1,
            startTimeInEdit: 82,
            endTimeInEdit: 82.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "let's",
            startTimeInOriginal: 82.1,
            endTimeInOriginal: 82.3,
            startTimeInEdit: 82.1,
            endTimeInEdit: 82.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'try',
            startTimeInOriginal: 82.3,
            endTimeInOriginal: 82.5,
            startTimeInEdit: 82.3,
            endTimeInEdit: 82.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it.',
            startTimeInOriginal: 82.5,
            endTimeInOriginal: 82.7,
            startTimeInEdit: 82.5,
            endTimeInEdit: 82.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Remove',
            startTimeInOriginal: 82.7,
            endTimeInOriginal: 83,
            startTimeInEdit: 82.7,
            endTimeInEdit: 83
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 83,
            endTimeInOriginal: 83.1,
            startTimeInEdit: 83,
            endTimeInEdit: 83.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'last',
            startTimeInOriginal: 83.1,
            endTimeInOriginal: 83.4,
            startTimeInEdit: 83.1,
            endTimeInEdit: 83.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'sentence,',
            startTimeInOriginal: 83.4,
            endTimeInOriginal: 83.8,
            startTimeInEdit: 83.4,
            endTimeInEdit: 83.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'well,',
            startTimeInOriginal: 83.8,
            endTimeInOriginal: 84.2,
            startTimeInEdit: 83.8,
            endTimeInEdit: 84.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 84.2,
            endTimeInOriginal: 84.45,
            startTimeInEdit: 84.2,
            endTimeInEdit: 84.45
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 84.45,
            endTimeInOriginal: 84.5,
            startTimeInEdit: 84.45,
            endTimeInEdit: 84.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'this',
            startTimeInOriginal: 84.5,
            endTimeInOriginal: 84.7,
            startTimeInEdit: 84.5,
            endTimeInEdit: 84.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'sentence.',
            startTimeInOriginal: 84.7,
            endTimeInOriginal: 85.3,
            startTimeInEdit: 84.7,
            endTimeInEdit: 85.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Remove',
            startTimeInOriginal: 85.3,
            endTimeInOriginal: 85.6,
            startTimeInEdit: 85.3,
            endTimeInEdit: 85.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'this',
            startTimeInOriginal: 85.6,
            endTimeInOriginal: 85.7,
            startTimeInEdit: 85.6,
            endTimeInEdit: 85.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'sentence',
            startTimeInOriginal: 85.7,
            endTimeInOriginal: 86.2,
            startTimeInEdit: 85.7,
            endTimeInEdit: 86.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'from',
            startTimeInOriginal: 86.2,
            endTimeInOriginal: 86.3,
            startTimeInEdit: 86.2,
            endTimeInEdit: 86.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 86.3,
            endTimeInOriginal: 86.4,
            startTimeInEdit: 86.3,
            endTimeInEdit: 86.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video,',
            startTimeInOriginal: 86.4,
            endTimeInOriginal: 86.7,
            startTimeInEdit: 86.4,
            endTimeInEdit: 86.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'by',
            startTimeInOriginal: 86.7,
            endTimeInOriginal: 87,
            startTimeInEdit: 86.7,
            endTimeInEdit: 87
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 87,
            endTimeInOriginal: 87.1,
            startTimeInEdit: 87,
            endTimeInEdit: 87.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'highlighting',
            startTimeInOriginal: 87.1,
            endTimeInOriginal: 87.6,
            startTimeInEdit: 87.1,
            endTimeInEdit: 87.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it,',
            startTimeInOriginal: 87.6,
            endTimeInOriginal: 88,
            startTimeInEdit: 87.6,
            endTimeInEdit: 88
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 88,
            endTimeInOriginal: 88.3,
            startTimeInEdit: 88,
            endTimeInEdit: 88.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'going',
            startTimeInOriginal: 88.3,
            endTimeInOriginal: 88.6,
            startTimeInEdit: 88.3,
            endTimeInEdit: 88.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'one',
            startTimeInOriginal: 88.6,
            endTimeInOriginal: 88.7,
            startTimeInEdit: 88.6,
            endTimeInEdit: 88.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'by',
            startTimeInOriginal: 88.7,
            endTimeInOriginal: 88.9,
            startTimeInEdit: 88.7,
            endTimeInEdit: 88.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'one',
            startTimeInOriginal: 88.9,
            endTimeInOriginal: 89.3,
            startTimeInEdit: 88.9,
            endTimeInEdit: 89.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 89.3,
            endTimeInOriginal: 89.4,
            startTimeInEdit: 89.3,
            endTimeInEdit: 89.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'deleting',
            startTimeInOriginal: 89.4,
            endTimeInOriginal: 89.8,
            startTimeInEdit: 89.4,
            endTimeInEdit: 89.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'each',
            startTimeInOriginal: 89.8,
            endTimeInOriginal: 90,
            startTimeInEdit: 89.8,
            endTimeInEdit: 90
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'word.',
            startTimeInOriginal: 90,
            endTimeInOriginal: 90.4,
            startTimeInEdit: 90,
            endTimeInEdit: 90.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 90.4,
            endTimeInOriginal: 90.65,
            startTimeInEdit: 90.4,
            endTimeInEdit: 90.65
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 90.65,
            endTimeInOriginal: 90.7,
            startTimeInEdit: 90.65,
            endTimeInEdit: 90.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Whichever',
            startTimeInOriginal: 90.7,
            endTimeInOriginal: 91.1,
            startTimeInEdit: 90.7,
            endTimeInEdit: 91.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 91.1,
            endTimeInOriginal: 91.2,
            startTimeInEdit: 91.1,
            endTimeInEdit: 91.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'prefer.',
            startTimeInOriginal: 91.2,
            endTimeInOriginal: 91.7,
            startTimeInEdit: 91.2,
            endTimeInEdit: 91.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 91.7,
            endTimeInOriginal: 91.8,
            startTimeInEdit: 91.7,
            endTimeInEdit: 91.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'And',
            startTimeInOriginal: 91.8,
            endTimeInOriginal: 91.9,
            startTimeInEdit: 91.8,
            endTimeInEdit: 91.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'if',
            startTimeInOriginal: 91.9,
            endTimeInOriginal: 92,
            startTimeInEdit: 91.9,
            endTimeInEdit: 92
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 92,
            endTimeInOriginal: 92.1,
            startTimeInEdit: 92,
            endTimeInEdit: 92.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do',
            startTimeInOriginal: 92.1,
            endTimeInOriginal: 92.2,
            startTimeInEdit: 92.1,
            endTimeInEdit: 92.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 92.2,
            endTimeInOriginal: 92.4,
            startTimeInEdit: 92.2,
            endTimeInEdit: 92.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'right,',
            startTimeInOriginal: 92.4,
            endTimeInOriginal: 92.6,
            startTimeInEdit: 92.4,
            endTimeInEdit: 92.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'which',
            startTimeInOriginal: 92.6,
            endTimeInOriginal: 92.8,
            startTimeInEdit: 92.6,
            endTimeInEdit: 92.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'I',
            startTimeInOriginal: 92.8,
            endTimeInOriginal: 93.2,
            startTimeInEdit: 92.8,
            endTimeInEdit: 93.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'believe',
            startTimeInOriginal: 93.2,
            endTimeInOriginal: 93.5,
            startTimeInEdit: 93.2,
            endTimeInEdit: 93.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 93.5,
            endTimeInOriginal: 93.6,
            startTimeInEdit: 93.5,
            endTimeInEdit: 93.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can,',
            startTimeInOriginal: 93.6,
            endTimeInOriginal: 94.1,
            startTimeInEdit: 93.6,
            endTimeInEdit: 94.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 94.1,
            endTimeInOriginal: 94.19999999999999,
            startTimeInEdit: 94.1,
            endTimeInEdit: 94.19999999999999
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "it'll",
            startTimeInOriginal: 94.2,
            endTimeInOriginal: 94.3,
            startTimeInEdit: 94.2,
            endTimeInEdit: 94.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'be',
            startTimeInOriginal: 94.3,
            endTimeInOriginal: 94.4,
            startTimeInEdit: 94.3,
            endTimeInEdit: 94.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'like',
            startTimeInOriginal: 94.4,
            endTimeInOriginal: 94.6,
            startTimeInEdit: 94.4,
            endTimeInEdit: 94.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'I',
            startTimeInOriginal: 94.6,
            endTimeInOriginal: 94.7,
            startTimeInEdit: 94.6,
            endTimeInEdit: 94.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'never',
            startTimeInOriginal: 94.7,
            endTimeInOriginal: 95.1,
            startTimeInEdit: 94.7,
            endTimeInEdit: 95.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'said',
            startTimeInOriginal: 95.1,
            endTimeInOriginal: 95.4,
            startTimeInEdit: 95.1,
            endTimeInEdit: 95.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'anything',
            startTimeInOriginal: 95.4,
            endTimeInOriginal: 95.8,
            startTimeInEdit: 95.4,
            endTimeInEdit: 95.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'at',
            startTimeInOriginal: 95.8,
            endTimeInOriginal: 96,
            startTimeInEdit: 95.8,
            endTimeInEdit: 96
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'all',
            startTimeInOriginal: 96,
            endTimeInOriginal: 96.5,
            startTimeInEdit: 96,
            endTimeInEdit: 96.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 96.5,
            endTimeInOriginal: 96.75,
            startTimeInEdit: 96.5,
            endTimeInEdit: 96.75
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 96.75,
            endTimeInOriginal: 97,
            startTimeInEdit: 96.75,
            endTimeInEdit: 97
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: true,
            text: '',
            startTimeInOriginal: 97,
            endTimeInOriginal: 97.25,
            startTimeInEdit: 97,
            endTimeInEdit: 97.25
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 97.25,
            endTimeInOriginal: 97.5,
            startTimeInEdit: 97.25,
            endTimeInEdit: 97.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 97.5,
            endTimeInOriginal: 97.75,
            startTimeInEdit: 97.5,
            endTimeInEdit: 97.75
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 97.75,
            endTimeInOriginal: 98,
            startTimeInEdit: 97.75,
            endTimeInEdit: 98
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 98,
            endTimeInOriginal: 98.1,
            startTimeInEdit: 98,
            endTimeInEdit: 98.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Part',
            startTimeInOriginal: 98.1,
            endTimeInOriginal: 98.3,
            startTimeInEdit: 98.1,
            endTimeInEdit: 98.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: '2:',
            startTimeInOriginal: 98.3,
            endTimeInOriginal: 98.7,
            startTimeInEdit: 98.3,
            endTimeInEdit: 98.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Adding',
            startTimeInOriginal: 98.7,
            endTimeInOriginal: 98.8,
            startTimeInEdit: 98.7,
            endTimeInEdit: 98.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Subtitles',
            startTimeInOriginal: 98.8,
            endTimeInOriginal: 99.1,
            startTimeInEdit: 98.8,
            endTimeInEdit: 99.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 99.1,
            endTimeInOriginal: 99.9,
            startTimeInEdit: 99.1,
            endTimeInEdit: 99.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: true,
            text: '',
            startTimeInOriginal: 99.9,
            endTimeInOriginal: 100.10000000000001,
            startTimeInEdit: 99.9,
            endTimeInEdit: 100.10000000000001
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'To',
            startTimeInOriginal: 100.1,
            endTimeInOriginal: 100.2,
            startTimeInEdit: 100.1,
            endTimeInEdit: 100.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'add',
            startTimeInOriginal: 100.2,
            endTimeInOriginal: 100.4,
            startTimeInEdit: 100.2,
            endTimeInEdit: 100.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'subtitles',
            startTimeInOriginal: 100.4,
            endTimeInOriginal: 100.9,
            startTimeInEdit: 100.4,
            endTimeInEdit: 100.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'here,',
            startTimeInOriginal: 100.9,
            endTimeInOriginal: 101.2,
            startTimeInEdit: 100.9,
            endTimeInEdit: 101.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "let's",
            startTimeInOriginal: 101.2,
            endTimeInOriginal: 101.3,
            startTimeInEdit: 101.2,
            endTimeInEdit: 101.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do',
            startTimeInOriginal: 101.3,
            endTimeInOriginal: 101.4,
            startTimeInEdit: 101.3,
            endTimeInEdit: 101.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 101.4,
            endTimeInOriginal: 101.5,
            startTimeInEdit: 101.4,
            endTimeInEdit: 101.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'together.',
            startTimeInOriginal: 101.5,
            endTimeInOriginal: 102,
            startTimeInEdit: 101.5,
            endTimeInEdit: 102
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Go',
            startTimeInOriginal: 102,
            endTimeInOriginal: 102.1,
            startTimeInEdit: 102,
            endTimeInEdit: 102.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'up',
            startTimeInOriginal: 102.1,
            endTimeInOriginal: 102.2,
            startTimeInEdit: 102.1,
            endTimeInEdit: 102.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 102.2,
            endTimeInOriginal: 102.3,
            startTimeInEdit: 102.2,
            endTimeInEdit: 102.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 102.3,
            endTimeInOriginal: 102.4,
            startTimeInEdit: 102.3,
            endTimeInEdit: 102.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'top',
            startTimeInOriginal: 102.4,
            endTimeInOriginal: 102.7,
            startTimeInEdit: 102.4,
            endTimeInEdit: 102.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 102.7,
            endTimeInOriginal: 102.7,
            startTimeInEdit: 102.7,
            endTimeInEdit: 102.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 102.7,
            endTimeInOriginal: 102.8,
            startTimeInEdit: 102.7,
            endTimeInEdit: 102.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'screen',
            startTimeInOriginal: 102.8,
            endTimeInOriginal: 103.1,
            startTimeInEdit: 102.8,
            endTimeInEdit: 103.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'above',
            startTimeInOriginal: 103.1,
            endTimeInOriginal: 103.3,
            startTimeInEdit: 103.1,
            endTimeInEdit: 103.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'my',
            startTimeInOriginal: 103.3,
            endTimeInOriginal: 103.5,
            startTimeInEdit: 103.3,
            endTimeInEdit: 103.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'head,',
            startTimeInOriginal: 103.5,
            endTimeInOriginal: 103.9,
            startTimeInEdit: 103.5,
            endTimeInEdit: 103.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 103.9,
            endTimeInOriginal: 104,
            startTimeInEdit: 103.9,
            endTimeInEdit: 104
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'click',
            startTimeInOriginal: 104,
            endTimeInOriginal: 104.2,
            startTimeInEdit: 104,
            endTimeInEdit: 104.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 104.2,
            endTimeInOriginal: 104.3,
            startTimeInEdit: 104.2,
            endTimeInEdit: 104.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'subtitles',
            startTimeInOriginal: 104.3,
            endTimeInOriginal: 104.8,
            startTimeInEdit: 104.3,
            endTimeInEdit: 104.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'button.',
            startTimeInOriginal: 104.8,
            endTimeInOriginal: 105.2,
            startTimeInEdit: 104.8,
            endTimeInEdit: 105.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 105.2,
            endTimeInOriginal: 105.3,
            startTimeInEdit: 105.2,
            endTimeInEdit: 105.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'This',
            startTimeInOriginal: 105.3,
            endTimeInOriginal: 105.4,
            startTimeInEdit: 105.3,
            endTimeInEdit: 105.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 105.4,
            endTimeInOriginal: 105.5,
            startTimeInEdit: 105.4,
            endTimeInEdit: 105.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'give',
            startTimeInOriginal: 105.5,
            endTimeInOriginal: 105.7,
            startTimeInEdit: 105.5,
            endTimeInEdit: 105.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 105.7,
            endTimeInOriginal: 105.8,
            startTimeInEdit: 105.7,
            endTimeInEdit: 105.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 105.8,
            endTimeInOriginal: 105.8,
            startTimeInEdit: 105.8,
            endTimeInEdit: 105.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'drop',
            startTimeInOriginal: 105.8,
            endTimeInOriginal: 106.1,
            startTimeInEdit: 105.8,
            endTimeInEdit: 106.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'down',
            startTimeInOriginal: 106.1,
            endTimeInOriginal: 106.3,
            startTimeInEdit: 106.1,
            endTimeInEdit: 106.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'menu,',
            startTimeInOriginal: 106.3,
            endTimeInOriginal: 106.5,
            startTimeInEdit: 106.3,
            endTimeInEdit: 106.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'which',
            startTimeInOriginal: 106.5,
            endTimeInOriginal: 106.7,
            startTimeInEdit: 106.5,
            endTimeInEdit: 106.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 106.7,
            endTimeInOriginal: 106.9,
            startTimeInEdit: 106.7,
            endTimeInEdit: 106.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'allow',
            startTimeInOriginal: 106.9,
            endTimeInOriginal: 107.1,
            startTimeInEdit: 106.9,
            endTimeInEdit: 107.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 107.1,
            endTimeInOriginal: 107.2,
            startTimeInEdit: 107.1,
            endTimeInEdit: 107.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 107.2,
            endTimeInOriginal: 107.4,
            startTimeInEdit: 107.2,
            endTimeInEdit: 107.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'choose',
            startTimeInOriginal: 107.4,
            endTimeInOriginal: 107.6,
            startTimeInEdit: 107.4,
            endTimeInEdit: 107.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'from',
            startTimeInOriginal: 107.6,
            endTimeInOriginal: 107.8,
            startTimeInEdit: 107.6,
            endTimeInEdit: 107.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'one',
            startTimeInOriginal: 107.8,
            endTimeInOriginal: 107.9,
            startTimeInEdit: 107.8,
            endTimeInEdit: 107.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 107.9,
            endTimeInOriginal: 108,
            startTimeInEdit: 107.9,
            endTimeInEdit: 108
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'three',
            startTimeInOriginal: 108,
            endTimeInOriginal: 108.2,
            startTimeInEdit: 108,
            endTimeInEdit: 108.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'options.',
            startTimeInOriginal: 108.2,
            endTimeInOriginal: 108.7,
            startTimeInEdit: 108.2,
            endTimeInEdit: 108.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Whether',
            startTimeInOriginal: 108.7,
            endTimeInOriginal: 109,
            startTimeInEdit: 108.7,
            endTimeInEdit: 109
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 109,
            endTimeInOriginal: 109.1,
            startTimeInEdit: 109,
            endTimeInEdit: 109.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'be',
            startTimeInOriginal: 109.1,
            endTimeInOriginal: 109.4,
            startTimeInEdit: 109.1,
            endTimeInEdit: 109.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'static,',
            startTimeInOriginal: 109.4,
            endTimeInOriginal: 110,
            startTimeInEdit: 109.4,
            endTimeInEdit: 110
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'dynamic,',
            startTimeInOriginal: 110,
            endTimeInOriginal: 110.6,
            startTimeInEdit: 110,
            endTimeInEdit: 110.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 110.6,
            endTimeInOriginal: 110.9,
            startTimeInEdit: 110.6,
            endTimeInEdit: 110.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'none,',
            startTimeInOriginal: 110.9,
            endTimeInOriginal: 111.4,
            startTimeInEdit: 110.9,
            endTimeInEdit: 111.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'if',
            startTimeInOriginal: 111.4,
            endTimeInOriginal: 111.5,
            startTimeInEdit: 111.4,
            endTimeInEdit: 111.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "you'd",
            startTimeInOriginal: 111.5,
            endTimeInOriginal: 111.6,
            startTimeInEdit: 111.5,
            endTimeInEdit: 111.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'like',
            startTimeInOriginal: 111.6,
            endTimeInOriginal: 111.8,
            startTimeInEdit: 111.6,
            endTimeInEdit: 111.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 111.8,
            endTimeInOriginal: 111.9,
            startTimeInEdit: 111.8,
            endTimeInEdit: 111.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'get',
            startTimeInOriginal: 111.9,
            endTimeInOriginal: 112.2,
            startTimeInEdit: 111.9,
            endTimeInEdit: 112.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'rid',
            startTimeInOriginal: 112.2,
            endTimeInOriginal: 112.3,
            startTimeInEdit: 112.2,
            endTimeInEdit: 112.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 112.3,
            endTimeInOriginal: 112.6,
            startTimeInEdit: 112.3,
            endTimeInEdit: 112.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 112.6,
            endTimeInOriginal: 112.8,
            startTimeInEdit: 112.6,
            endTimeInEdit: 112.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'subtitles',
            startTimeInOriginal: 112.8,
            endTimeInOriginal: 113.4,
            startTimeInEdit: 112.8,
            endTimeInEdit: 113.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'at',
            startTimeInOriginal: 113.4,
            endTimeInOriginal: 113.5,
            startTimeInEdit: 113.4,
            endTimeInEdit: 113.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 113.5,
            endTimeInOriginal: 113.5,
            startTimeInEdit: 113.5,
            endTimeInEdit: 113.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'later',
            startTimeInOriginal: 113.5,
            endTimeInOriginal: 113.8,
            startTimeInEdit: 113.5,
            endTimeInEdit: 113.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'time.',
            startTimeInOriginal: 113.8,
            endTimeInOriginal: 114.4,
            startTimeInEdit: 113.8,
            endTimeInEdit: 114.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'After',
            startTimeInOriginal: 114.4,
            endTimeInOriginal: 114.7,
            startTimeInEdit: 114.4,
            endTimeInEdit: 114.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'selecting',
            startTimeInOriginal: 114.7,
            endTimeInOriginal: 115,
            startTimeInEdit: 114.7,
            endTimeInEdit: 115
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'either',
            startTimeInOriginal: 115,
            endTimeInOriginal: 115.2,
            startTimeInEdit: 115,
            endTimeInEdit: 115.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'static',
            startTimeInOriginal: 115.2,
            endTimeInOriginal: 115.7,
            startTimeInEdit: 115.2,
            endTimeInEdit: 115.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 115.7,
            endTimeInOriginal: 115.8,
            startTimeInEdit: 115.7,
            endTimeInEdit: 115.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'dynamic',
            startTimeInOriginal: 115.8,
            endTimeInOriginal: 116.3,
            startTimeInEdit: 115.8,
            endTimeInEdit: 116.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'for',
            startTimeInOriginal: 116.3,
            endTimeInOriginal: 116.4,
            startTimeInEdit: 116.3,
            endTimeInEdit: 116.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 116.4,
            endTimeInOriginal: 116.5,
            startTimeInEdit: 116.4,
            endTimeInEdit: 116.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'subtitles,',
            startTimeInOriginal: 116.5,
            endTimeInOriginal: 117.3,
            startTimeInEdit: 116.5,
            endTimeInEdit: 117.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'if you',
            startTimeInOriginal: 117.3,
            endTimeInOriginal: 117.4,
            startTimeInEdit: 117.3,
            endTimeInEdit: 117.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'hit',
            startTimeInOriginal: 117.4,
            endTimeInOriginal: 117.6,
            startTimeInEdit: 117.4,
            endTimeInEdit: 117.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 117.6,
            endTimeInOriginal: 117.7,
            startTimeInEdit: 117.6,
            endTimeInEdit: 117.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'play',
            startTimeInOriginal: 117.7,
            endTimeInOriginal: 117.9,
            startTimeInEdit: 117.7,
            endTimeInEdit: 117.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'button,',
            startTimeInOriginal: 117.9,
            endTimeInOriginal: 118.3,
            startTimeInEdit: 117.9,
            endTimeInEdit: 118.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 118.3,
            endTimeInOriginal: 118.5,
            startTimeInEdit: 118.3,
            endTimeInEdit: 118.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'subtitles',
            startTimeInOriginal: 118.5,
            endTimeInOriginal: 118.8,
            startTimeInEdit: 118.5,
            endTimeInEdit: 118.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 118.8,
            endTimeInOriginal: 119,
            startTimeInEdit: 118.8,
            endTimeInEdit: 119
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'show',
            startTimeInOriginal: 119,
            endTimeInOriginal: 119.1,
            startTimeInEdit: 119,
            endTimeInEdit: 119.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'up',
            startTimeInOriginal: 119.1,
            endTimeInOriginal: 119.2,
            startTimeInEdit: 119.1,
            endTimeInEdit: 119.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'automatically',
            startTimeInOriginal: 119.2,
            endTimeInOriginal: 119.8,
            startTimeInEdit: 119.2,
            endTimeInEdit: 119.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 119.8,
            endTimeInOriginal: 119.9,
            startTimeInEdit: 119.8,
            endTimeInEdit: 119.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'top',
            startTimeInOriginal: 119.9,
            endTimeInOriginal: 120.1,
            startTimeInEdit: 119.9,
            endTimeInEdit: 120.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 120.1,
            endTimeInOriginal: 120.1,
            startTimeInEdit: 120.1,
            endTimeInEdit: 120.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 120.1,
            endTimeInOriginal: 120.2,
            startTimeInEdit: 120.1,
            endTimeInEdit: 120.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video.',
            startTimeInOriginal: 120.2,
            endTimeInOriginal: 120.4,
            startTimeInEdit: 120.2,
            endTimeInEdit: 120.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 120.4,
            endTimeInOriginal: 120.5,
            startTimeInEdit: 120.4,
            endTimeInEdit: 120.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 120.5,
            endTimeInOriginal: 120.7,
            startTimeInEdit: 120.5,
            endTimeInEdit: 120.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'These',
            startTimeInOriginal: 120.7,
            endTimeInOriginal: 120.8,
            startTimeInEdit: 120.7,
            endTimeInEdit: 120.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'subtitles',
            startTimeInOriginal: 120.8,
            endTimeInOriginal: 121.2,
            startTimeInEdit: 120.8,
            endTimeInEdit: 121.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 121.2,
            endTimeInOriginal: 121.4,
            startTimeInEdit: 121.2,
            endTimeInEdit: 121.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'be',
            startTimeInOriginal: 121.4,
            endTimeInOriginal: 121.5,
            startTimeInEdit: 121.4,
            endTimeInEdit: 121.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'adjusted',
            startTimeInOriginal: 121.5,
            endTimeInOriginal: 122,
            startTimeInEdit: 121.5,
            endTimeInEdit: 122
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'by',
            startTimeInOriginal: 122,
            endTimeInOriginal: 122.1,
            startTimeInEdit: 122,
            endTimeInEdit: 122.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'clicking',
            startTimeInOriginal: 122.1,
            endTimeInOriginal: 122.5,
            startTimeInEdit: 122.1,
            endTimeInEdit: 122.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 122.5,
            endTimeInOriginal: 122.6,
            startTimeInEdit: 122.5,
            endTimeInEdit: 122.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'them,',
            startTimeInOriginal: 122.6,
            endTimeInOriginal: 122.9,
            startTimeInEdit: 122.6,
            endTimeInEdit: 122.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'if',
            startTimeInOriginal: 122.9,
            endTimeInOriginal: 123,
            startTimeInEdit: 122.9,
            endTimeInEdit: 123
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 123,
            endTimeInOriginal: 123.1,
            startTimeInEdit: 123,
            endTimeInEdit: 123.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do',
            startTimeInOriginal: 123.1,
            endTimeInOriginal: 123.3,
            startTimeInEdit: 123.1,
            endTimeInEdit: 123.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'so,',
            startTimeInOriginal: 123.3,
            endTimeInOriginal: 123.7,
            startTimeInEdit: 123.3,
            endTimeInEdit: 123.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 123.7,
            endTimeInOriginal: 123.8,
            startTimeInEdit: 123.7,
            endTimeInEdit: 123.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 123.8,
            endTimeInOriginal: 123.9,
            startTimeInEdit: 123.8,
            endTimeInEdit: 123.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'adjust',
            startTimeInOriginal: 123.9,
            endTimeInOriginal: 124.2,
            startTimeInEdit: 123.9,
            endTimeInEdit: 124.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 124.2,
            endTimeInOriginal: 124.3,
            startTimeInEdit: 124.2,
            endTimeInEdit: 124.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'font,',
            startTimeInOriginal: 124.3,
            endTimeInOriginal: 124.8,
            startTimeInEdit: 124.3,
            endTimeInEdit: 124.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'color,',
            startTimeInOriginal: 124.8,
            endTimeInOriginal: 125.3,
            startTimeInEdit: 124.8,
            endTimeInEdit: 125.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 125.3,
            endTimeInOriginal: 125.4,
            startTimeInEdit: 125.3,
            endTimeInEdit: 125.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'transparency',
            startTimeInOriginal: 125.4,
            endTimeInOriginal: 126.2,
            startTimeInEdit: 125.4,
            endTimeInEdit: 126.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 126.2,
            endTimeInOriginal: 126.5,
            startTimeInEdit: 126.2,
            endTimeInEdit: 126.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 126.5,
            endTimeInOriginal: 126.6,
            startTimeInEdit: 126.5,
            endTimeInEdit: 126.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'subtitles.',
            startTimeInOriginal: 126.6,
            endTimeInOriginal: 127.2,
            startTimeInEdit: 126.6,
            endTimeInEdit: 127.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 127.2,
            endTimeInOriginal: 127.3,
            startTimeInEdit: 127.2,
            endTimeInEdit: 127.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'You',
            startTimeInOriginal: 127.3,
            endTimeInOriginal: 127.4,
            startTimeInEdit: 127.3,
            endTimeInEdit: 127.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 127.4,
            endTimeInOriginal: 127.4,
            startTimeInEdit: 127.4,
            endTimeInEdit: 127.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'also',
            startTimeInOriginal: 127.4,
            endTimeInOriginal: 127.7,
            startTimeInEdit: 127.4,
            endTimeInEdit: 127.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'click',
            startTimeInOriginal: 127.7,
            endTimeInOriginal: 127.9,
            startTimeInEdit: 127.7,
            endTimeInEdit: 127.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 127.9,
            endTimeInOriginal: 128,
            startTimeInEdit: 127.9,
            endTimeInEdit: 128
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'drag',
            startTimeInOriginal: 128,
            endTimeInOriginal: 128.3,
            startTimeInEdit: 128,
            endTimeInEdit: 128.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'them',
            startTimeInOriginal: 128.3,
            endTimeInOriginal: 128.5,
            startTimeInEdit: 128.3,
            endTimeInEdit: 128.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 128.5,
            endTimeInOriginal: 128.6,
            startTimeInEdit: 128.5,
            endTimeInEdit: 128.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'make',
            startTimeInOriginal: 128.6,
            endTimeInOriginal: 128.7,
            startTimeInEdit: 128.6,
            endTimeInEdit: 128.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'them',
            startTimeInOriginal: 128.7,
            endTimeInOriginal: 128.9,
            startTimeInEdit: 128.7,
            endTimeInEdit: 128.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'bigger',
            startTimeInOriginal: 128.9,
            endTimeInOriginal: 129.4,
            startTimeInEdit: 128.9,
            endTimeInEdit: 129.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 129.4,
            endTimeInOriginal: 129.5,
            startTimeInEdit: 129.4,
            endTimeInEdit: 129.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'by',
            startTimeInOriginal: 129.5,
            endTimeInOriginal: 129.7,
            startTimeInEdit: 129.5,
            endTimeInEdit: 129.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'clicking',
            startTimeInOriginal: 129.7,
            endTimeInOriginal: 130.1,
            startTimeInEdit: 129.7,
            endTimeInEdit: 130.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 130.1,
            endTimeInOriginal: 130.2,
            startTimeInEdit: 130.1,
            endTimeInEdit: 130.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'corners',
            startTimeInOriginal: 130.2,
            endTimeInOriginal: 130.7,
            startTimeInEdit: 130.2,
            endTimeInEdit: 130.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 130.7,
            endTimeInOriginal: 130.8,
            startTimeInEdit: 130.7,
            endTimeInEdit: 130.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'dragging',
            startTimeInOriginal: 130.8,
            endTimeInOriginal: 131.1,
            startTimeInEdit: 130.8,
            endTimeInEdit: 131.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'them',
            startTimeInOriginal: 131.1,
            endTimeInOriginal: 131.3,
            startTimeInEdit: 131.1,
            endTimeInEdit: 131.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'out, ',
            startTimeInOriginal: 131.3,
            endTimeInOriginal: 131.7,
            startTimeInEdit: 131.3,
            endTimeInEdit: 131.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 131.7,
            endTimeInOriginal: 131.79999999999998,
            startTimeInEdit: 131.7,
            endTimeInEdit: 131.79999999999998
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 131.8,
            endTimeInOriginal: 131.9,
            startTimeInEdit: 131.8,
            endTimeInEdit: 131.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 131.9,
            endTimeInOriginal: 132,
            startTimeInEdit: 131.9,
            endTimeInEdit: 132
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do',
            startTimeInOriginal: 132,
            endTimeInOriginal: 132.2,
            startTimeInEdit: 132,
            endTimeInEdit: 132.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'all',
            startTimeInOriginal: 132.2,
            endTimeInOriginal: 132.4,
            startTimeInEdit: 132.2,
            endTimeInEdit: 132.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 132.4,
            endTimeInOriginal: 132.4,
            startTimeInEdit: 132.4,
            endTimeInEdit: 132.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'this',
            startTimeInOriginal: 132.4,
            endTimeInOriginal: 132.7,
            startTimeInEdit: 132.4,
            endTimeInEdit: 132.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'right',
            startTimeInOriginal: 132.7,
            endTimeInOriginal: 132.8,
            startTimeInEdit: 132.7,
            endTimeInEdit: 132.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 132.8,
            endTimeInOriginal: 132.9,
            startTimeInEdit: 132.8,
            endTimeInEdit: 132.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'front',
            startTimeInOriginal: 132.9,
            endTimeInOriginal: 133,
            startTimeInEdit: 132.9,
            endTimeInEdit: 133
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 133,
            endTimeInOriginal: 133.1,
            startTimeInEdit: 133,
            endTimeInEdit: 133.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'my',
            startTimeInOriginal: 133.1,
            endTimeInOriginal: 133.2,
            startTimeInEdit: 133.1,
            endTimeInEdit: 133.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'face',
            startTimeInOriginal: 133.2,
            endTimeInOriginal: 133.6,
            startTimeInEdit: 133.2,
            endTimeInEdit: 133.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'while',
            startTimeInOriginal: 133.6,
            endTimeInOriginal: 133.7,
            startTimeInEdit: 133.6,
            endTimeInEdit: 133.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "I'm",
            startTimeInOriginal: 133.7,
            endTimeInOriginal: 133.9,
            startTimeInEdit: 133.7,
            endTimeInEdit: 133.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'saying',
            startTimeInOriginal: 133.9,
            endTimeInOriginal: 134.1,
            startTimeInEdit: 133.9,
            endTimeInEdit: 134.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'this,',
            startTimeInOriginal: 134.1,
            endTimeInOriginal: 134.3,
            startTimeInEdit: 134.1,
            endTimeInEdit: 134.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'if',
            startTimeInOriginal: 134.3,
            endTimeInOriginal: 134.5,
            startTimeInEdit: 134.3,
            endTimeInEdit: 134.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "you'd",
            startTimeInOriginal: 134.5,
            endTimeInOriginal: 134.6,
            startTimeInEdit: 134.5,
            endTimeInEdit: 134.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'like.',
            startTimeInOriginal: 134.6,
            endTimeInOriginal: 134.9,
            startTimeInEdit: 134.6,
            endTimeInEdit: 134.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 134.9,
            endTimeInOriginal: 135,
            startTimeInEdit: 134.9,
            endTimeInEdit: 135
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'As',
            startTimeInOriginal: 135,
            endTimeInOriginal: 135.1,
            startTimeInEdit: 135,
            endTimeInEdit: 135.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 135.1,
            endTimeInOriginal: 135.1,
            startTimeInEdit: 135.1,
            endTimeInEdit: 135.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'quick',
            startTimeInOriginal: 135.1,
            endTimeInOriginal: 135.3,
            startTimeInEdit: 135.1,
            endTimeInEdit: 135.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'side',
            startTimeInOriginal: 135.3,
            endTimeInOriginal: 135.5,
            startTimeInEdit: 135.3,
            endTimeInEdit: 135.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'note,',
            startTimeInOriginal: 135.5,
            endTimeInOriginal: 135.8,
            startTimeInEdit: 135.5,
            endTimeInEdit: 135.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'typically',
            startTimeInOriginal: 135.8,
            endTimeInOriginal: 136.3,
            startTimeInEdit: 135.8,
            endTimeInEdit: 136.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 136.3,
            endTimeInOriginal: 136.4,
            startTimeInEdit: 136.3,
            endTimeInEdit: 136.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'transcription',
            startTimeInOriginal: 136.4,
            endTimeInOriginal: 136.9,
            startTimeInEdit: 136.4,
            endTimeInEdit: 136.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 136.9,
            endTimeInOriginal: 137,
            startTimeInEdit: 136.9,
            endTimeInEdit: 137
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 137,
            endTimeInOriginal: 137,
            startTimeInEdit: 137,
            endTimeInEdit: 137
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video',
            startTimeInOriginal: 137,
            endTimeInOriginal: 137.3,
            startTimeInEdit: 137,
            endTimeInEdit: 137.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'is',
            startTimeInOriginal: 137.3,
            endTimeInOriginal: 137.6,
            startTimeInEdit: 137.3,
            endTimeInEdit: 137.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'pretty',
            startTimeInOriginal: 137.6,
            endTimeInOriginal: 137.9,
            startTimeInEdit: 137.6,
            endTimeInEdit: 137.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'accurate,',
            startTimeInOriginal: 137.9,
            endTimeInOriginal: 138.5,
            startTimeInEdit: 137.9,
            endTimeInEdit: 138.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'but',
            startTimeInOriginal: 138.5,
            endTimeInOriginal: 138.6,
            startTimeInEdit: 138.5,
            endTimeInEdit: 138.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'sometimes',
            startTimeInOriginal: 138.6,
            endTimeInOriginal: 139.1,
            startTimeInEdit: 138.6,
            endTimeInEdit: 139.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'if',
            startTimeInOriginal: 139.1,
            endTimeInOriginal: 139.2,
            startTimeInEdit: 139.1,
            endTimeInEdit: 139.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 139.2,
            endTimeInOriginal: 139.3,
            startTimeInEdit: 139.2,
            endTimeInEdit: 139.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'mumble',
            startTimeInOriginal: 139.3,
            endTimeInOriginal: 139.9,
            startTimeInEdit: 139.3,
            endTimeInEdit: 139.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 139.9,
            endTimeInOriginal: 140,
            startTimeInEdit: 139.9,
            endTimeInEdit: 140
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 140,
            endTimeInOriginal: 140.1,
            startTimeInEdit: 140,
            endTimeInEdit: 140.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'talk',
            startTimeInOriginal: 140.1,
            endTimeInOriginal: 140.4,
            startTimeInEdit: 140.1,
            endTimeInEdit: 140.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'really',
            startTimeInOriginal: 140.4,
            endTimeInOriginal: 140.6,
            startTimeInEdit: 140.4,
            endTimeInEdit: 140.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'fast',
            startTimeInOriginal: 140.6,
            endTimeInOriginal: 141,
            startTimeInEdit: 140.6,
            endTimeInEdit: 141
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'like',
            startTimeInOriginal: 141,
            endTimeInOriginal: 141.4,
            startTimeInEdit: 141,
            endTimeInEdit: 141.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'I',
            startTimeInOriginal: 141.4,
            endTimeInOriginal: 141.5,
            startTimeInEdit: 141.4,
            endTimeInEdit: 141.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do,',
            startTimeInOriginal: 141.5,
            endTimeInOriginal: 142,
            startTimeInEdit: 141.5,
            endTimeInEdit: 142
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 142,
            endTimeInOriginal: 142.2,
            startTimeInEdit: 142,
            endTimeInEdit: 142.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'then',
            startTimeInOriginal: 142.2,
            endTimeInOriginal: 142.5,
            startTimeInEdit: 142.2,
            endTimeInEdit: 142.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 142.5,
            endTimeInOriginal: 142.6,
            startTimeInEdit: 142.5,
            endTimeInEdit: 142.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'transcription',
            startTimeInOriginal: 142.6,
            endTimeInOriginal: 143,
            startTimeInEdit: 142.6,
            endTimeInEdit: 143
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 143,
            endTimeInOriginal: 143.2,
            startTimeInEdit: 143,
            endTimeInEdit: 143.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'be',
            startTimeInOriginal: 143.2,
            endTimeInOriginal: 143.3,
            startTimeInEdit: 143.2,
            endTimeInEdit: 143.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 143.3,
            endTimeInOriginal: 143.3,
            startTimeInEdit: 143.3,
            endTimeInEdit: 143.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'little',
            startTimeInOriginal: 143.3,
            endTimeInOriginal: 143.6,
            startTimeInEdit: 143.3,
            endTimeInEdit: 143.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'off.',
            startTimeInOriginal: 143.6,
            endTimeInOriginal: 144,
            startTimeInEdit: 143.6,
            endTimeInEdit: 144
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'So',
            startTimeInOriginal: 144,
            endTimeInOriginal: 144.1,
            startTimeInEdit: 144,
            endTimeInEdit: 144.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'if',
            startTimeInOriginal: 144.1,
            endTimeInOriginal: 144.2,
            startTimeInEdit: 144.1,
            endTimeInEdit: 144.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "that's",
            startTimeInOriginal: 144.2,
            endTimeInOriginal: 144.4,
            startTimeInEdit: 144.2,
            endTimeInEdit: 144.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 144.4,
            endTimeInOriginal: 144.5,
            startTimeInEdit: 144.4,
            endTimeInEdit: 144.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'case,',
            startTimeInOriginal: 144.5,
            endTimeInOriginal: 144.9,
            startTimeInEdit: 144.5,
            endTimeInEdit: 144.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'where',
            startTimeInOriginal: 144.9,
            endTimeInOriginal: 145.1,
            startTimeInEdit: 144.9,
            endTimeInEdit: 145.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 145.1,
            endTimeInOriginal: 145.2,
            startTimeInEdit: 145.1,
            endTimeInEdit: 145.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'few',
            startTimeInOriginal: 145.2,
            endTimeInOriginal: 145.4,
            startTimeInEdit: 145.2,
            endTimeInEdit: 145.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'words are',
            startTimeInOriginal: 145.4,
            endTimeInOriginal: 145.6,
            startTimeInEdit: 145.4,
            endTimeInEdit: 145.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 145.6,
            endTimeInOriginal: 145.7,
            startTimeInEdit: 145.6,
            endTimeInEdit: 145.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'little',
            startTimeInOriginal: 145.7,
            endTimeInOriginal: 145.9,
            startTimeInEdit: 145.7,
            endTimeInEdit: 145.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'bit',
            startTimeInOriginal: 145.9,
            endTimeInOriginal: 146,
            startTimeInEdit: 145.9,
            endTimeInEdit: 146
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'off,',
            startTimeInOriginal: 146,
            endTimeInOriginal: 146.4,
            startTimeInEdit: 146,
            endTimeInEdit: 146.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 146.4,
            endTimeInOriginal: 146.6,
            startTimeInEdit: 146.4,
            endTimeInEdit: 146.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 146.6,
            endTimeInOriginal: 146.6,
            startTimeInEdit: 146.6,
            endTimeInEdit: 146.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'actually',
            startTimeInOriginal: 146.6,
            endTimeInOriginal: 146.9,
            startTimeInEdit: 146.6,
            endTimeInEdit: 146.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'change',
            startTimeInOriginal: 146.9,
            endTimeInOriginal: 147.3,
            startTimeInEdit: 146.9,
            endTimeInEdit: 147.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'those',
            startTimeInOriginal: 147.3,
            endTimeInOriginal: 147.6,
            startTimeInEdit: 147.3,
            endTimeInEdit: 147.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'words',
            startTimeInOriginal: 147.6,
            endTimeInOriginal: 147.9,
            startTimeInEdit: 147.6,
            endTimeInEdit: 147.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 147.9,
            endTimeInOriginal: 148,
            startTimeInEdit: 147.9,
            endTimeInEdit: 148
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'make',
            startTimeInOriginal: 148,
            endTimeInOriginal: 148.2,
            startTimeInEdit: 148,
            endTimeInEdit: 148.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'them',
            startTimeInOriginal: 148.2,
            endTimeInOriginal: 148.3,
            startTimeInEdit: 148.2,
            endTimeInEdit: 148.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'correct',
            startTimeInOriginal: 148.3,
            endTimeInOriginal: 148.7,
            startTimeInEdit: 148.3,
            endTimeInEdit: 148.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 148.7,
            endTimeInOriginal: 149,
            startTimeInEdit: 148.7,
            endTimeInEdit: 149
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 149,
            endTimeInOriginal: 149.1,
            startTimeInEdit: 149,
            endTimeInEdit: 149.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'subtitles',
            startTimeInOriginal: 149.1,
            endTimeInOriginal: 149.7,
            startTimeInEdit: 149.1,
            endTimeInEdit: 149.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'as',
            startTimeInOriginal: 149.7,
            endTimeInOriginal: 149.8,
            startTimeInEdit: 149.7,
            endTimeInEdit: 149.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'well',
            startTimeInOriginal: 149.8,
            endTimeInOriginal: 150,
            startTimeInEdit: 149.8,
            endTimeInEdit: 150
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'as',
            startTimeInOriginal: 150,
            endTimeInOriginal: 150.1,
            startTimeInEdit: 150,
            endTimeInEdit: 150.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 150.1,
            endTimeInOriginal: 150.2,
            startTimeInEdit: 150.1,
            endTimeInEdit: 150.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 150.2,
            endTimeInOriginal: 150.4,
            startTimeInEdit: 150.2,
            endTimeInEdit: 150.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 150.4,
            endTimeInOriginal: 150.5,
            startTimeInEdit: 150.4,
            endTimeInEdit: 150.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'transcription.',
            startTimeInOriginal: 150.5,
            endTimeInOriginal: 150.9,
            startTimeInEdit: 150.5,
            endTimeInEdit: 150.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'So,',
            startTimeInOriginal: 150.9,
            endTimeInOriginal: 151.3,
            startTimeInEdit: 150.9,
            endTimeInEdit: 151.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 151.3,
            endTimeInOriginal: 151.4,
            startTimeInEdit: 151.3,
            endTimeInEdit: 151.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do',
            startTimeInOriginal: 151.4,
            endTimeInOriginal: 151.6,
            startTimeInEdit: 151.4,
            endTimeInEdit: 151.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'this,',
            startTimeInOriginal: 151.6,
            endTimeInOriginal: 151.7,
            startTimeInEdit: 151.6,
            endTimeInEdit: 151.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 151.7,
            endTimeInOriginal: 151.9,
            startTimeInEdit: 151.7,
            endTimeInEdit: 151.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'have',
            startTimeInOriginal: 151.9,
            endTimeInOriginal: 152,
            startTimeInEdit: 151.9,
            endTimeInEdit: 152
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'two',
            startTimeInOriginal: 152,
            endTimeInOriginal: 152.3,
            startTimeInEdit: 152,
            endTimeInEdit: 152.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'options.',
            startTimeInOriginal: 152.3,
            endTimeInOriginal: 152.6,
            startTimeInEdit: 152.3,
            endTimeInEdit: 152.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Either',
            startTimeInOriginal: 152.6,
            endTimeInOriginal: 152.9,
            startTimeInEdit: 152.6,
            endTimeInEdit: 152.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 152.9,
            endTimeInOriginal: 153.2,
            startTimeInEdit: 152.9,
            endTimeInEdit: 153.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 153.2,
            endTimeInOriginal: 153.4,
            startTimeInEdit: 153.2,
            endTimeInEdit: 153.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'double',
            startTimeInOriginal: 153.4,
            endTimeInOriginal: 153.7,
            startTimeInEdit: 153.4,
            endTimeInEdit: 153.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'click',
            startTimeInOriginal: 153.7,
            endTimeInOriginal: 153.7,
            startTimeInEdit: 153.7,
            endTimeInEdit: 153.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 153.7,
            endTimeInOriginal: 153.9,
            startTimeInEdit: 153.7,
            endTimeInEdit: 153.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 153.9,
            endTimeInOriginal: 154,
            startTimeInEdit: 153.9,
            endTimeInEdit: 154
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'word',
            startTimeInOriginal: 154,
            endTimeInOriginal: 154.1,
            startTimeInEdit: 154,
            endTimeInEdit: 154.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 154.1,
            endTimeInOriginal: 154.4,
            startTimeInEdit: 154.1,
            endTimeInEdit: 154.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'is',
            startTimeInOriginal: 154.4,
            endTimeInOriginal: 154.5,
            startTimeInEdit: 154.4,
            endTimeInEdit: 154.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'wrong,',
            startTimeInOriginal: 154.5,
            endTimeInOriginal: 154.9,
            startTimeInEdit: 154.5,
            endTimeInEdit: 154.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'which',
            startTimeInOriginal: 154.9,
            endTimeInOriginal: 155.1,
            startTimeInEdit: 154.9,
            endTimeInEdit: 155.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 155.1,
            endTimeInOriginal: 155.3,
            startTimeInEdit: 155.1,
            endTimeInEdit: 155.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'change',
            startTimeInOriginal: 155.3,
            endTimeInOriginal: 155.6,
            startTimeInEdit: 155.3,
            endTimeInEdit: 155.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 155.6,
            endTimeInOriginal: 155.9,
            startTimeInEdit: 155.6,
            endTimeInEdit: 155.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'highlighted',
            startTimeInOriginal: 155.9,
            endTimeInOriginal: 156.8,
            startTimeInEdit: 155.9,
            endTimeInEdit: 156.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'area',
            startTimeInOriginal: 156.8,
            endTimeInOriginal: 157.2,
            startTimeInEdit: 156.8,
            endTimeInEdit: 157.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'around',
            startTimeInOriginal: 157.2,
            endTimeInOriginal: 157.4,
            startTimeInEdit: 157.2,
            endTimeInEdit: 157.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 157.4,
            endTimeInOriginal: 157.6,
            startTimeInEdit: 157.4,
            endTimeInEdit: 157.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 157.6,
            endTimeInOriginal: 157.8,
            startTimeInEdit: 157.6,
            endTimeInEdit: 157.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Green,',
            startTimeInOriginal: 157.8,
            endTimeInOriginal: 158.2,
            startTimeInEdit: 157.8,
            endTimeInEdit: 158.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'which',
            startTimeInOriginal: 158.2,
            endTimeInOriginal: 158.5,
            startTimeInEdit: 158.2,
            endTimeInEdit: 158.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'indicates',
            startTimeInOriginal: 158.5,
            endTimeInOriginal: 158.6,
            startTimeInEdit: 158.5,
            endTimeInEdit: 158.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 158.6,
            endTimeInOriginal: 159,
            startTimeInEdit: 158.6,
            endTimeInEdit: 159
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "it's",
            startTimeInOriginal: 159,
            endTimeInOriginal: 159.3,
            startTimeInEdit: 159,
            endTimeInEdit: 159.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'ready',
            startTimeInOriginal: 159.3,
            endTimeInOriginal: 159.7,
            startTimeInEdit: 159.3,
            endTimeInEdit: 159.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 159.7,
            endTimeInOriginal: 159.8,
            startTimeInEdit: 159.7,
            endTimeInEdit: 159.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'be',
            startTimeInOriginal: 159.8,
            endTimeInOriginal: 159.9,
            startTimeInEdit: 159.8,
            endTimeInEdit: 159.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'edited',
            startTimeInOriginal: 159.9,
            endTimeInOriginal: 160.1,
            startTimeInEdit: 159.9,
            endTimeInEdit: 160.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 160.1,
            endTimeInOriginal: 160.5,
            startTimeInEdit: 160.1,
            endTimeInEdit: 160.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 160.5,
            endTimeInOriginal: 160.6,
            startTimeInEdit: 160.5,
            endTimeInEdit: 160.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 160.6,
            endTimeInOriginal: 160.7,
            startTimeInEdit: 160.6,
            endTimeInEdit: 160.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'edit',
            startTimeInOriginal: 160.7,
            endTimeInOriginal: 160.9,
            startTimeInEdit: 160.7,
            endTimeInEdit: 160.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 160.9,
            endTimeInOriginal: 161,
            startTimeInEdit: 160.9,
            endTimeInEdit: 161
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'like',
            startTimeInOriginal: 161,
            endTimeInOriginal: 161.2,
            startTimeInEdit: 161,
            endTimeInEdit: 161.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 161.2,
            endTimeInOriginal: 161.3,
            startTimeInEdit: 161.2,
            endTimeInEdit: 161.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'regular',
            startTimeInOriginal: 161.3,
            endTimeInOriginal: 161.4,
            startTimeInEdit: 161.3,
            endTimeInEdit: 161.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'text',
            startTimeInOriginal: 161.4,
            endTimeInOriginal: 161.9,
            startTimeInEdit: 161.4,
            endTimeInEdit: 161.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'document.',
            startTimeInOriginal: 161.9,
            endTimeInOriginal: 162.5,
            startTimeInEdit: 161.9,
            endTimeInEdit: 162.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Or',
            startTimeInOriginal: 162.5,
            endTimeInOriginal: 162.5,
            startTimeInEdit: 162.5,
            endTimeInEdit: 162.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'if',
            startTimeInOriginal: 162.5,
            endTimeInOriginal: 162.9,
            startTimeInEdit: 162.5,
            endTimeInEdit: 162.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 162.9,
            endTimeInOriginal: 163,
            startTimeInEdit: 162.9,
            endTimeInEdit: 163
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'just',
            startTimeInOriginal: 163,
            endTimeInOriginal: 163.1,
            startTimeInEdit: 163,
            endTimeInEdit: 163.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'click',
            startTimeInOriginal: 163.1,
            endTimeInOriginal: 163.3,
            startTimeInEdit: 163.1,
            endTimeInEdit: 163.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 163.3,
            endTimeInOriginal: 163.5,
            startTimeInEdit: 163.3,
            endTimeInEdit: 163.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 163.5,
            endTimeInOriginal: 163.6,
            startTimeInEdit: 163.5,
            endTimeInEdit: 163.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'word',
            startTimeInOriginal: 163.6,
            endTimeInOriginal: 163.6,
            startTimeInEdit: 163.6,
            endTimeInEdit: 163.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 163.6,
            endTimeInOriginal: 163.8,
            startTimeInEdit: 163.6,
            endTimeInEdit: 163.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'hit',
            startTimeInOriginal: 163.8,
            endTimeInOriginal: 164,
            startTimeInEdit: 163.8,
            endTimeInEdit: 164
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 164,
            endTimeInOriginal: 164.2,
            startTimeInEdit: 164,
            endTimeInEdit: 164.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'enter',
            startTimeInOriginal: 164.2,
            endTimeInOriginal: 164.3,
            startTimeInEdit: 164.2,
            endTimeInEdit: 164.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'key,',
            startTimeInOriginal: 164.3,
            endTimeInOriginal: 164.5,
            startTimeInEdit: 164.3,
            endTimeInEdit: 164.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 164.5,
            endTimeInOriginal: 164.8,
            startTimeInEdit: 164.5,
            endTimeInEdit: 164.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 164.8,
            endTimeInOriginal: 164.9,
            startTimeInEdit: 164.8,
            endTimeInEdit: 164.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do',
            startTimeInOriginal: 164.9,
            endTimeInOriginal: 165.1,
            startTimeInEdit: 164.9,
            endTimeInEdit: 165.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'exactly',
            startTimeInOriginal: 165.1,
            endTimeInOriginal: 165.1,
            startTimeInEdit: 165.1,
            endTimeInEdit: 165.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 165.1,
            endTimeInOriginal: 165.6,
            startTimeInEdit: 165.1,
            endTimeInEdit: 165.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'same,',
            startTimeInOriginal: 165.6,
            endTimeInOriginal: 165.7,
            startTimeInEdit: 165.6,
            endTimeInEdit: 165.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'highlight it',
            startTimeInOriginal: 165.7,
            endTimeInOriginal: 166.6,
            startTimeInEdit: 165.7,
            endTimeInEdit: 166.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 166.6,
            endTimeInOriginal: 166.7,
            startTimeInEdit: 166.6,
            endTimeInEdit: 166.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Green',
            startTimeInOriginal: 166.7,
            endTimeInOriginal: 167,
            startTimeInEdit: 166.7,
            endTimeInEdit: 167
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 167,
            endTimeInOriginal: 167.3,
            startTimeInEdit: 167,
            endTimeInEdit: 167.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'allow',
            startTimeInOriginal: 167.3,
            endTimeInOriginal: 167.5,
            startTimeInEdit: 167.3,
            endTimeInEdit: 167.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 167.5,
            endTimeInOriginal: 167.7,
            startTimeInEdit: 167.5,
            endTimeInEdit: 167.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 167.7,
            endTimeInOriginal: 167.8,
            startTimeInEdit: 167.7,
            endTimeInEdit: 167.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'edit',
            startTimeInOriginal: 167.8,
            endTimeInOriginal: 168,
            startTimeInEdit: 167.8,
            endTimeInEdit: 168
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it.',
            startTimeInOriginal: 168,
            endTimeInOriginal: 168.2,
            startTimeInEdit: 168,
            endTimeInEdit: 168.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'And',
            startTimeInOriginal: 168.2,
            endTimeInOriginal: 168.2,
            startTimeInEdit: 168.2,
            endTimeInEdit: 168.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'once',
            startTimeInOriginal: 168.2,
            endTimeInOriginal: 168.4,
            startTimeInEdit: 168.2,
            endTimeInEdit: 168.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "you're",
            startTimeInOriginal: 168.4,
            endTimeInOriginal: 168.5,
            startTimeInEdit: 168.4,
            endTimeInEdit: 168.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'done,',
            startTimeInOriginal: 168.5,
            endTimeInOriginal: 168.5,
            startTimeInEdit: 168.5,
            endTimeInEdit: 168.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'hit',
            startTimeInOriginal: 168.5,
            endTimeInOriginal: 168.8,
            startTimeInEdit: 168.5,
            endTimeInEdit: 168.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 168.8,
            endTimeInOriginal: 169,
            startTimeInEdit: 168.8,
            endTimeInEdit: 169
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'enter',
            startTimeInOriginal: 169,
            endTimeInOriginal: 169.2,
            startTimeInEdit: 169,
            endTimeInEdit: 169.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'button',
            startTimeInOriginal: 169.2,
            endTimeInOriginal: 169.3,
            startTimeInEdit: 169.2,
            endTimeInEdit: 169.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'again.',
            startTimeInOriginal: 169.3,
            endTimeInOriginal: 169.9,
            startTimeInEdit: 169.3,
            endTimeInEdit: 169.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Once',
            startTimeInOriginal: 169.9,
            endTimeInOriginal: 170.3,
            startTimeInEdit: 169.9,
            endTimeInEdit: 170.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'again,',
            startTimeInOriginal: 170.3,
            endTimeInOriginal: 170.3,
            startTimeInEdit: 170.3,
            endTimeInEdit: 170.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'once',
            startTimeInOriginal: 170.3,
            endTimeInOriginal: 170.7,
            startTimeInEdit: 170.3,
            endTimeInEdit: 170.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 170.7,
            endTimeInOriginal: 170.9,
            startTimeInEdit: 170.7,
            endTimeInEdit: 170.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'edit',
            startTimeInOriginal: 170.9,
            endTimeInOriginal: 171.1,
            startTimeInEdit: 170.9,
            endTimeInEdit: 171.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 171.1,
            endTimeInOriginal: 171.2,
            startTimeInEdit: 171.1,
            endTimeInEdit: 171.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'word',
            startTimeInOriginal: 171.2,
            endTimeInOriginal: 171.4,
            startTimeInEdit: 171.2,
            endTimeInEdit: 171.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 171.4,
            endTimeInOriginal: 171.5,
            startTimeInEdit: 171.4,
            endTimeInEdit: 171.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 171.5,
            endTimeInOriginal: 171.6,
            startTimeInEdit: 171.5,
            endTimeInEdit: 171.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'transcription',
            startTimeInOriginal: 171.6,
            endTimeInOriginal: 172.1,
            startTimeInEdit: 171.6,
            endTimeInEdit: 172.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'side',
            startTimeInOriginal: 172.1,
            endTimeInOriginal: 172.4,
            startTimeInEdit: 172.1,
            endTimeInEdit: 172.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 172.4,
            endTimeInOriginal: 172.4,
            startTimeInEdit: 172.4,
            endTimeInEdit: 172.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 172.4,
            endTimeInOriginal: 172.5,
            startTimeInEdit: 172.4,
            endTimeInEdit: 172.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video',
            startTimeInOriginal: 172.5,
            endTimeInOriginal: 172.6,
            startTimeInEdit: 172.5,
            endTimeInEdit: 172.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'editor,',
            startTimeInOriginal: 172.6,
            endTimeInOriginal: 173.1,
            startTimeInEdit: 172.6,
            endTimeInEdit: 173.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 173.1,
            endTimeInOriginal: 173.5,
            startTimeInEdit: 173.1,
            endTimeInEdit: 173.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 173.5,
            endTimeInOriginal: 173.6,
            startTimeInEdit: 173.5,
            endTimeInEdit: 173.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'show',
            startTimeInOriginal: 173.6,
            endTimeInOriginal: 173.9,
            startTimeInEdit: 173.6,
            endTimeInEdit: 173.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'up',
            startTimeInOriginal: 173.9,
            endTimeInOriginal: 174.1,
            startTimeInEdit: 173.9,
            endTimeInEdit: 174.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 174.1,
            endTimeInOriginal: 174.2,
            startTimeInEdit: 174.1,
            endTimeInEdit: 174.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 174.2,
            endTimeInOriginal: 174.4,
            startTimeInEdit: 174.2,
            endTimeInEdit: 174.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'subtitles',
            startTimeInOriginal: 174.4,
            endTimeInOriginal: 174.6,
            startTimeInEdit: 174.4,
            endTimeInEdit: 174.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: true,
            text: 'themselves.',
            startTimeInOriginal: 174.6,
            endTimeInOriginal: 174.8,
            startTimeInEdit: 174.6,
            endTimeInEdit: 174.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Part',
            startTimeInOriginal: 174.8,
            endTimeInOriginal: 175.3,
            startTimeInEdit: 174.8,
            endTimeInEdit: 175.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: '3:',
            startTimeInOriginal: 175.3,
            endTimeInOriginal: 175.9,
            startTimeInEdit: 175.3,
            endTimeInEdit: 175.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Translate',
            startTimeInOriginal: 175.9,
            endTimeInOriginal: 176.5,
            startTimeInEdit: 175.9,
            endTimeInEdit: 176.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Your',
            startTimeInOriginal: 176.5,
            endTimeInOriginal: 176.8,
            startTimeInEdit: 176.5,
            endTimeInEdit: 176.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: true,
            text: 'Video',
            startTimeInOriginal: 176.8,
            endTimeInOriginal: 177,
            startTimeInEdit: 176.8,
            endTimeInEdit: 177
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'So',
            startTimeInOriginal: 177,
            endTimeInOriginal: 178.1,
            startTimeInEdit: 177,
            endTimeInEdit: 178.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 178.1,
            endTimeInOriginal: 178.4,
            startTimeInEdit: 178.1,
            endTimeInEdit: 178.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'translate',
            startTimeInOriginal: 178.4,
            endTimeInOriginal: 178.6,
            startTimeInEdit: 178.4,
            endTimeInEdit: 178.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 178.6,
            endTimeInOriginal: 178.8,
            startTimeInEdit: 178.6,
            endTimeInEdit: 178.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video,',
            startTimeInOriginal: 178.8,
            endTimeInOriginal: 179.1,
            startTimeInEdit: 178.8,
            endTimeInEdit: 179.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "you're",
            startTimeInOriginal: 179.1,
            endTimeInOriginal: 179.2,
            startTimeInEdit: 179.1,
            endTimeInEdit: 179.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'gonna',
            startTimeInOriginal: 179.2,
            endTimeInOriginal: 179.3,
            startTimeInEdit: 179.2,
            endTimeInEdit: 179.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'go',
            startTimeInOriginal: 179.3,
            endTimeInOriginal: 179.4,
            startTimeInEdit: 179.3,
            endTimeInEdit: 179.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 179.4,
            endTimeInOriginal: 179.5,
            startTimeInEdit: 179.4,
            endTimeInEdit: 179.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 179.5,
            endTimeInOriginal: 179.6,
            startTimeInEdit: 179.5,
            endTimeInEdit: 179.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'top',
            startTimeInOriginal: 179.6,
            endTimeInOriginal: 179.7,
            startTimeInEdit: 179.6,
            endTimeInEdit: 179.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 179.7,
            endTimeInOriginal: 179.9,
            startTimeInEdit: 179.7,
            endTimeInEdit: 179.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 179.9,
            endTimeInOriginal: 180,
            startTimeInEdit: 179.9,
            endTimeInEdit: 180
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'screen',
            startTimeInOriginal: 180,
            endTimeInOriginal: 180.2,
            startTimeInEdit: 180,
            endTimeInEdit: 180.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 180.2,
            endTimeInOriginal: 180.4,
            startTimeInEdit: 180.2,
            endTimeInEdit: 180.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 180.4,
            endTimeInOriginal: 180.5,
            startTimeInEdit: 180.4,
            endTimeInEdit: 180.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'click',
            startTimeInOriginal: 180.5,
            endTimeInOriginal: 180.7,
            startTimeInEdit: 180.5,
            endTimeInEdit: 180.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 180.7,
            endTimeInOriginal: 180.8,
            startTimeInEdit: 180.7,
            endTimeInEdit: 180.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'button',
            startTimeInOriginal: 180.8,
            endTimeInOriginal: 181,
            startTimeInEdit: 180.8,
            endTimeInEdit: 181
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 181,
            endTimeInOriginal: 181.1,
            startTimeInEdit: 181,
            endTimeInEdit: 181.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'says',
            startTimeInOriginal: 181.1,
            endTimeInOriginal: 181.4,
            startTimeInEdit: 181.1,
            endTimeInEdit: 181.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'translation.',
            startTimeInOriginal: 181.4,
            endTimeInOriginal: 182.2,
            startTimeInEdit: 181.4,
            endTimeInEdit: 182.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'This',
            startTimeInOriginal: 182.2,
            endTimeInOriginal: 182.3,
            startTimeInEdit: 182.2,
            endTimeInEdit: 182.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 182.3,
            endTimeInOriginal: 182.4,
            startTimeInEdit: 182.3,
            endTimeInEdit: 182.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'bring',
            startTimeInOriginal: 182.4,
            endTimeInOriginal: 182.6,
            startTimeInEdit: 182.4,
            endTimeInEdit: 182.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'up',
            startTimeInOriginal: 182.6,
            endTimeInOriginal: 182.6,
            startTimeInEdit: 182.6,
            endTimeInEdit: 182.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 182.6,
            endTimeInOriginal: 182.7,
            startTimeInEdit: 182.6,
            endTimeInEdit: 182.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'drop-down',
            startTimeInOriginal: 182.7,
            endTimeInOriginal: 183.2,
            startTimeInEdit: 182.7,
            endTimeInEdit: 183.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'menu',
            startTimeInOriginal: 183.2,
            endTimeInOriginal: 183.4,
            startTimeInEdit: 183.2,
            endTimeInEdit: 183.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 183.4,
            endTimeInOriginal: 183.6,
            startTimeInEdit: 183.4,
            endTimeInEdit: 183.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 183.6,
            endTimeInOriginal: 183.7,
            startTimeInEdit: 183.6,
            endTimeInEdit: 183.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'offer',
            startTimeInOriginal: 183.7,
            endTimeInOriginal: 184,
            startTimeInEdit: 183.7,
            endTimeInEdit: 184
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 184,
            endTimeInOriginal: 184.1,
            startTimeInEdit: 184,
            endTimeInEdit: 184.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 184.1,
            endTimeInOriginal: 184.2,
            startTimeInEdit: 184.1,
            endTimeInEdit: 184.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'option',
            startTimeInOriginal: 184.2,
            endTimeInOriginal: 184.6,
            startTimeInEdit: 184.2,
            endTimeInEdit: 184.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 184.6,
            endTimeInOriginal: 184.8,
            startTimeInEdit: 184.6,
            endTimeInEdit: 184.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'choose',
            startTimeInOriginal: 184.8,
            endTimeInOriginal: 185.2,
            startTimeInEdit: 184.8,
            endTimeInEdit: 185.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'which',
            startTimeInOriginal: 185.2,
            endTimeInOriginal: 185.4,
            startTimeInEdit: 185.2,
            endTimeInEdit: 185.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'language',
            startTimeInOriginal: 185.4,
            endTimeInOriginal: 185.7,
            startTimeInEdit: 185.4,
            endTimeInEdit: 185.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "you'd",
            startTimeInOriginal: 185.7,
            endTimeInOriginal: 185.9,
            startTimeInEdit: 185.7,
            endTimeInEdit: 185.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'like',
            startTimeInOriginal: 185.9,
            endTimeInOriginal: 186,
            startTimeInEdit: 185.9,
            endTimeInEdit: 186
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 186,
            endTimeInOriginal: 186.1,
            startTimeInEdit: 186,
            endTimeInEdit: 186.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'translate',
            startTimeInOriginal: 186.1,
            endTimeInOriginal: 186.5,
            startTimeInEdit: 186.1,
            endTimeInEdit: 186.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 186.5,
            endTimeInOriginal: 186.6,
            startTimeInEdit: 186.5,
            endTimeInEdit: 186.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'subtitles',
            startTimeInOriginal: 186.6,
            endTimeInOriginal: 187.2,
            startTimeInEdit: 186.6,
            endTimeInEdit: 187.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to.',
            startTimeInOriginal: 187.2,
            endTimeInOriginal: 187.5,
            startTimeInEdit: 187.2,
            endTimeInEdit: 187.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Make',
            startTimeInOriginal: 187.5,
            endTimeInOriginal: 187.7,
            startTimeInEdit: 187.5,
            endTimeInEdit: 187.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 187.7,
            endTimeInOriginal: 187.8,
            startTimeInEdit: 187.7,
            endTimeInEdit: 187.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'choice',
            startTimeInOriginal: 187.8,
            endTimeInOriginal: 188.1,
            startTimeInEdit: 187.8,
            endTimeInEdit: 188.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 188.1,
            endTimeInOriginal: 188.2,
            startTimeInEdit: 188.1,
            endTimeInEdit: 188.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 188.2,
            endTimeInOriginal: 188.3,
            startTimeInEdit: 188.2,
            endTimeInEdit: 188.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'many',
            startTimeInOriginal: 188.3,
            endTimeInOriginal: 188.6,
            startTimeInEdit: 188.3,
            endTimeInEdit: 188.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'different',
            startTimeInOriginal: 188.6,
            endTimeInOriginal: 188.8,
            startTimeInEdit: 188.6,
            endTimeInEdit: 188.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'languages',
            startTimeInOriginal: 188.8,
            endTimeInOriginal: 189.3,
            startTimeInEdit: 188.8,
            endTimeInEdit: 189.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 189.3,
            endTimeInOriginal: 189.5,
            startTimeInEdit: 189.3,
            endTimeInEdit: 189.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'we',
            startTimeInOriginal: 189.5,
            endTimeInOriginal: 189.6,
            startTimeInEdit: 189.5,
            endTimeInEdit: 189.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'support,',
            startTimeInOriginal: 189.6,
            endTimeInOriginal: 190.2,
            startTimeInEdit: 189.6,
            endTimeInEdit: 190.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 190.2,
            endTimeInOriginal: 190.4,
            startTimeInEdit: 190.2,
            endTimeInEdit: 190.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'then',
            startTimeInOriginal: 190.4,
            endTimeInOriginal: 190.6,
            startTimeInEdit: 190.4,
            endTimeInEdit: 190.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'click',
            startTimeInOriginal: 190.6,
            endTimeInOriginal: 190.8,
            startTimeInEdit: 190.6,
            endTimeInEdit: 190.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 190.8,
            endTimeInOriginal: 190.9,
            startTimeInEdit: 190.8,
            endTimeInEdit: 190.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'translate',
            startTimeInOriginal: 190.9,
            endTimeInOriginal: 191.3,
            startTimeInEdit: 190.9,
            endTimeInEdit: 191.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'button.',
            startTimeInOriginal: 191.3,
            endTimeInOriginal: 191.7,
            startTimeInEdit: 191.3,
            endTimeInEdit: 191.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 191.7,
            endTimeInOriginal: 191.79999999999998,
            startTimeInEdit: 191.7,
            endTimeInEdit: 191.79999999999998
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'This',
            startTimeInOriginal: 191.8,
            endTimeInOriginal: 191.9,
            startTimeInEdit: 191.8,
            endTimeInEdit: 191.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'should',
            startTimeInOriginal: 191.9,
            endTimeInOriginal: 192,
            startTimeInEdit: 191.9,
            endTimeInEdit: 192
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do',
            startTimeInOriginal: 192,
            endTimeInOriginal: 192.1,
            startTimeInEdit: 192,
            endTimeInEdit: 192.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 192.1,
            endTimeInOriginal: 192.2,
            startTimeInEdit: 192.1,
            endTimeInEdit: 192.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'trick,',
            startTimeInOriginal: 192.2,
            endTimeInOriginal: 192.6,
            startTimeInEdit: 192.2,
            endTimeInEdit: 192.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 192.6,
            endTimeInOriginal: 192.8,
            startTimeInEdit: 192.6,
            endTimeInEdit: 192.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'if',
            startTimeInOriginal: 192.8,
            endTimeInOriginal: 192.9,
            startTimeInEdit: 192.8,
            endTimeInEdit: 192.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'so,',
            startTimeInOriginal: 192.9,
            endTimeInOriginal: 193.2,
            startTimeInEdit: 192.9,
            endTimeInEdit: 193.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'both',
            startTimeInOriginal: 193.2,
            endTimeInOriginal: 193.6,
            startTimeInEdit: 193.2,
            endTimeInEdit: 193.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 193.6,
            endTimeInOriginal: 193.7,
            startTimeInEdit: 193.6,
            endTimeInEdit: 193.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'subtitles',
            startTimeInOriginal: 193.7,
            endTimeInOriginal: 194.2,
            startTimeInEdit: 193.7,
            endTimeInEdit: 194.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'below',
            startTimeInOriginal: 194.2,
            endTimeInOriginal: 194.4,
            startTimeInEdit: 194.2,
            endTimeInEdit: 194.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'my',
            startTimeInOriginal: 194.4,
            endTimeInOriginal: 194.5,
            startTimeInEdit: 194.4,
            endTimeInEdit: 194.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'head',
            startTimeInOriginal: 194.5,
            endTimeInOriginal: 195,
            startTimeInEdit: 194.5,
            endTimeInEdit: 195
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 195,
            endTimeInOriginal: 195.1,
            startTimeInEdit: 195,
            endTimeInEdit: 195.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 195.1,
            endTimeInOriginal: 195.3,
            startTimeInEdit: 195.1,
            endTimeInEdit: 195.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 195.3,
            endTimeInOriginal: 195.4,
            startTimeInEdit: 195.3,
            endTimeInEdit: 195.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'transcription',
            startTimeInOriginal: 195.4,
            endTimeInOriginal: 196.2,
            startTimeInEdit: 195.4,
            endTimeInEdit: 196.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 196.2,
            endTimeInOriginal: 196.4,
            startTimeInEdit: 196.2,
            endTimeInEdit: 196.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 196.4,
            endTimeInOriginal: 196.6,
            startTimeInEdit: 196.4,
            endTimeInEdit: 196.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'left',
            startTimeInOriginal: 196.6,
            endTimeInOriginal: 196.9,
            startTimeInEdit: 196.6,
            endTimeInEdit: 196.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 196.9,
            endTimeInOriginal: 197,
            startTimeInEdit: 196.9,
            endTimeInEdit: 197
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'me,',
            startTimeInOriginal: 197,
            endTimeInOriginal: 197.3,
            startTimeInEdit: 197,
            endTimeInEdit: 197.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 197.3,
            endTimeInOriginal: 197.4,
            startTimeInEdit: 197.3,
            endTimeInEdit: 197.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'should',
            startTimeInOriginal: 197.4,
            endTimeInOriginal: 197.7,
            startTimeInEdit: 197.4,
            endTimeInEdit: 197.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'be',
            startTimeInOriginal: 197.7,
            endTimeInOriginal: 197.9,
            startTimeInEdit: 197.7,
            endTimeInEdit: 197.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 197.9,
            endTimeInOriginal: 198.1,
            startTimeInEdit: 197.9,
            endTimeInEdit: 198.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 198.1,
            endTimeInOriginal: 198.2,
            startTimeInEdit: 198.1,
            endTimeInEdit: 198.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'different',
            startTimeInOriginal: 198.2,
            endTimeInOriginal: 198.5,
            startTimeInEdit: 198.2,
            endTimeInEdit: 198.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'language',
            startTimeInOriginal: 198.5,
            endTimeInOriginal: 198.8,
            startTimeInEdit: 198.5,
            endTimeInEdit: 198.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'right',
            startTimeInOriginal: 198.8,
            endTimeInOriginal: 199,
            startTimeInEdit: 198.8,
            endTimeInEdit: 199
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'now.',
            startTimeInOriginal: 199,
            endTimeInOriginal: 199.5,
            startTimeInEdit: 199,
            endTimeInEdit: 199.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'I',
            startTimeInOriginal: 199.5,
            endTimeInOriginal: 199.5,
            startTimeInEdit: 199.5,
            endTimeInEdit: 199.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'wonder',
            startTimeInOriginal: 199.5,
            endTimeInOriginal: 199.7,
            startTimeInEdit: 199.5,
            endTimeInEdit: 199.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'what',
            startTimeInOriginal: 199.7,
            endTimeInOriginal: 199.8,
            startTimeInEdit: 199.7,
            endTimeInEdit: 199.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'language',
            startTimeInOriginal: 199.8,
            endTimeInOriginal: 200.2,
            startTimeInEdit: 199.8,
            endTimeInEdit: 200.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 200.2,
            endTimeInOriginal: 200.4,
            startTimeInEdit: 200.2,
            endTimeInEdit: 200.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 200.4,
            endTimeInOriginal: 200.5,
            startTimeInEdit: 200.4,
            endTimeInEdit: 200.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'chose.',
            startTimeInOriginal: 200.5,
            endTimeInOriginal: 201.1,
            startTimeInEdit: 200.5,
            endTimeInEdit: 201.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 201.1,
            endTimeInOriginal: 201.2,
            startTimeInEdit: 201.1,
            endTimeInEdit: 201.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Hmm.',
            startTimeInOriginal: 201.2,
            endTimeInOriginal: 201.5,
            startTimeInEdit: 201.2,
            endTimeInEdit: 201.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 201.5,
            endTimeInOriginal: 201.75,
            startTimeInEdit: 201.5,
            endTimeInEdit: 201.75
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 201.75,
            endTimeInOriginal: 202,
            startTimeInEdit: 201.75,
            endTimeInEdit: 202
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 202,
            endTimeInOriginal: 202.1,
            startTimeInEdit: 202,
            endTimeInEdit: 202.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "I'm gonna",
            startTimeInOriginal: 202.1,
            endTimeInOriginal: 202.3,
            startTimeInEdit: 202.1,
            endTimeInEdit: 202.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'guess',
            startTimeInOriginal: 202.3,
            endTimeInOriginal: 202.9,
            startTimeInEdit: 202.3,
            endTimeInEdit: 202.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 202.9,
            endTimeInOriginal: 203.15,
            startTimeInEdit: 202.9,
            endTimeInEdit: 203.15
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 203.15,
            endTimeInOriginal: 203.4,
            startTimeInEdit: 203.15,
            endTimeInEdit: 203.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 203.4,
            endTimeInOriginal: 203.4,
            startTimeInEdit: 203.4,
            endTimeInEdit: 203.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'chose',
            startTimeInOriginal: 203.4,
            endTimeInOriginal: 203.7,
            startTimeInEdit: 203.4,
            endTimeInEdit: 203.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'French.',
            startTimeInOriginal: 203.7,
            endTimeInOriginal: 204.3,
            startTimeInEdit: 203.7,
            endTimeInEdit: 204.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 204.3,
            endTimeInOriginal: 204.55,
            startTimeInEdit: 204.3,
            endTimeInEdit: 204.55
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 204.55,
            endTimeInOriginal: 204.60000000000002,
            startTimeInEdit: 204.55,
            endTimeInEdit: 204.60000000000002
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Anybody',
            startTimeInOriginal: 204.6,
            endTimeInOriginal: 205.1,
            startTimeInEdit: 204.6,
            endTimeInEdit: 205.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 205.1,
            endTimeInOriginal: 205.2,
            startTimeInEdit: 205.1,
            endTimeInEdit: 205.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'chose',
            startTimeInOriginal: 205.2,
            endTimeInOriginal: 205.6,
            startTimeInEdit: 205.2,
            endTimeInEdit: 205.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'French',
            startTimeInOriginal: 205.6,
            endTimeInOriginal: 206,
            startTimeInEdit: 205.6,
            endTimeInEdit: 206
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'is',
            startTimeInOriginal: 206,
            endTimeInOriginal: 206.1,
            startTimeInEdit: 206,
            endTimeInEdit: 206.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'going',
            startTimeInOriginal: 206.1,
            endTimeInOriginal: 206.2,
            startTimeInEdit: 206.1,
            endTimeInEdit: 206.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 206.2,
            endTimeInOriginal: 206.3,
            startTimeInEdit: 206.2,
            endTimeInEdit: 206.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'be',
            startTimeInOriginal: 206.3,
            endTimeInOriginal: 206.3,
            startTimeInEdit: 206.3,
            endTimeInEdit: 206.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'like.',
            startTimeInOriginal: 206.3,
            endTimeInOriginal: 206.8,
            startTimeInEdit: 206.3,
            endTimeInEdit: 206.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 206.8,
            endTimeInOriginal: 207.05,
            startTimeInEdit: 206.8,
            endTimeInEdit: 207.05
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 207.05,
            endTimeInOriginal: 207.3,
            startTimeInEdit: 207.05,
            endTimeInEdit: 207.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 207.3,
            endTimeInOriginal: 207.55,
            startTimeInEdit: 207.3,
            endTimeInEdit: 207.55
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: true,
            text: '',
            startTimeInOriginal: 207.55,
            endTimeInOriginal: 207.8,
            startTimeInEdit: 207.55,
            endTimeInEdit: 207.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 207.8,
            endTimeInOriginal: 208.05,
            startTimeInEdit: 207.8,
            endTimeInEdit: 208.05
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 208.05,
            endTimeInOriginal: 208.3,
            startTimeInEdit: 208.05,
            endTimeInEdit: 208.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 208.3,
            endTimeInOriginal: 208.55,
            startTimeInEdit: 208.3,
            endTimeInEdit: 208.55
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 208.55,
            endTimeInOriginal: 208.60000000000002,
            startTimeInEdit: 208.55,
            endTimeInEdit: 208.60000000000002
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Part',
            startTimeInOriginal: 208.6,
            endTimeInOriginal: 208.9,
            startTimeInEdit: 208.6,
            endTimeInEdit: 208.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: '4:',
            startTimeInOriginal: 208.9,
            endTimeInOriginal: 209.2,
            startTimeInEdit: 208.9,
            endTimeInEdit: 209.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Change',
            startTimeInOriginal: 209.2,
            endTimeInOriginal: 209.29999999999998,
            startTimeInEdit: 209.2,
            endTimeInEdit: 209.29999999999998
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 209.3,
            endTimeInOriginal: 209.6,
            startTimeInEdit: 209.3,
            endTimeInEdit: 209.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Aspect',
            startTimeInOriginal: 209.6,
            endTimeInOriginal: 209.8,
            startTimeInEdit: 209.6,
            endTimeInEdit: 209.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Ratio',
            startTimeInOriginal: 209.8,
            endTimeInOriginal: 210.2,
            startTimeInEdit: 209.8,
            endTimeInEdit: 210.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 210.2,
            endTimeInOriginal: 210.8,
            startTimeInEdit: 210.2,
            endTimeInEdit: 210.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: true,
            text: '',
            startTimeInOriginal: 210.8,
            endTimeInOriginal: 210.9,
            startTimeInEdit: 210.8,
            endTimeInEdit: 210.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Sometimes',
            startTimeInOriginal: 210.9,
            endTimeInOriginal: 211.3,
            startTimeInEdit: 210.9,
            endTimeInEdit: 211.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'different',
            startTimeInOriginal: 211.3,
            endTimeInOriginal: 211.6,
            startTimeInEdit: 211.3,
            endTimeInEdit: 211.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'platforms',
            startTimeInOriginal: 211.6,
            endTimeInOriginal: 212.1,
            startTimeInEdit: 211.6,
            endTimeInEdit: 212.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'prefer',
            startTimeInOriginal: 212.1,
            endTimeInOriginal: 212.6,
            startTimeInEdit: 212.1,
            endTimeInEdit: 212.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'specific',
            startTimeInOriginal: 212.6,
            endTimeInOriginal: 213.3,
            startTimeInEdit: 212.6,
            endTimeInEdit: 213.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'aspect',
            startTimeInOriginal: 213.3,
            endTimeInOriginal: 213.8,
            startTimeInEdit: 213.3,
            endTimeInEdit: 213.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'ratios.',
            startTimeInOriginal: 213.8,
            endTimeInOriginal: 214.5,
            startTimeInEdit: 213.8,
            endTimeInEdit: 214.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'So,',
            startTimeInOriginal: 214.5,
            endTimeInOriginal: 214.7,
            startTimeInEdit: 214.5,
            endTimeInEdit: 214.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 214.7,
            endTimeInOriginal: 214.9,
            startTimeInEdit: 214.7,
            endTimeInEdit: 214.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'make',
            startTimeInOriginal: 214.9,
            endTimeInOriginal: 215,
            startTimeInEdit: 214.9,
            endTimeInEdit: 215
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'sure',
            startTimeInOriginal: 215,
            endTimeInOriginal: 215.2,
            startTimeInEdit: 215,
            endTimeInEdit: 215.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 215.2,
            endTimeInOriginal: 215.3,
            startTimeInEdit: 215.2,
            endTimeInEdit: 215.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 215.3,
            endTimeInOriginal: 215.5,
            startTimeInEdit: 215.3,
            endTimeInEdit: 215.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video',
            startTimeInOriginal: 215.5,
            endTimeInOriginal: 215.7,
            startTimeInEdit: 215.5,
            endTimeInEdit: 215.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'works',
            startTimeInOriginal: 215.7,
            endTimeInOriginal: 216,
            startTimeInEdit: 215.7,
            endTimeInEdit: 216
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 216,
            endTimeInOriginal: 216.2,
            startTimeInEdit: 216,
            endTimeInEdit: 216.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'every',
            startTimeInOriginal: 216.2,
            endTimeInOriginal: 216.4,
            startTimeInEdit: 216.2,
            endTimeInEdit: 216.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'platform.',
            startTimeInOriginal: 216.4,
            endTimeInOriginal: 217,
            startTimeInEdit: 216.4,
            endTimeInEdit: 217
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "We've",
            startTimeInOriginal: 217,
            endTimeInOriginal: 217.2,
            startTimeInEdit: 217,
            endTimeInEdit: 217.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'given',
            startTimeInOriginal: 217.2,
            endTimeInOriginal: 217.4,
            startTimeInEdit: 217.2,
            endTimeInEdit: 217.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 217.4,
            endTimeInOriginal: 217.6,
            startTimeInEdit: 217.4,
            endTimeInEdit: 217.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 217.6,
            endTimeInOriginal: 217.7,
            startTimeInEdit: 217.6,
            endTimeInEdit: 217.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'ability',
            startTimeInOriginal: 217.7,
            endTimeInOriginal: 218.1,
            startTimeInEdit: 217.7,
            endTimeInEdit: 218.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 218.1,
            endTimeInOriginal: 218.3,
            startTimeInEdit: 218.1,
            endTimeInEdit: 218.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'change',
            startTimeInOriginal: 218.3,
            endTimeInOriginal: 218.8,
            startTimeInEdit: 218.3,
            endTimeInEdit: 218.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 218.8,
            endTimeInOriginal: 218.9,
            startTimeInEdit: 218.8,
            endTimeInEdit: 218.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'aspect',
            startTimeInOriginal: 218.9,
            endTimeInOriginal: 219.4,
            startTimeInEdit: 218.9,
            endTimeInEdit: 219.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'ratio',
            startTimeInOriginal: 219.4,
            endTimeInOriginal: 219.7,
            startTimeInEdit: 219.4,
            endTimeInEdit: 219.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 219.7,
            endTimeInOriginal: 219.8,
            startTimeInEdit: 219.7,
            endTimeInEdit: 219.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 219.8,
            endTimeInOriginal: 219.9,
            startTimeInEdit: 219.8,
            endTimeInEdit: 219.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video.',
            startTimeInOriginal: 219.9,
            endTimeInOriginal: 220.4,
            startTimeInEdit: 219.9,
            endTimeInEdit: 220.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'So',
            startTimeInOriginal: 220.4,
            endTimeInOriginal: 220.8,
            startTimeInEdit: 220.4,
            endTimeInEdit: 220.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 220.8,
            endTimeInOriginal: 220.8,
            startTimeInEdit: 220.8,
            endTimeInEdit: 220.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do,',
            startTimeInOriginal: 220.8,
            endTimeInOriginal: 221,
            startTimeInEdit: 220.8,
            endTimeInEdit: 221
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'just',
            startTimeInOriginal: 221,
            endTimeInOriginal: 221.3,
            startTimeInEdit: 221,
            endTimeInEdit: 221.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 221.3,
            endTimeInOriginal: 221.6,
            startTimeInEdit: 221.3,
            endTimeInEdit: 221.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 221.6,
            endTimeInOriginal: 221.7,
            startTimeInEdit: 221.6,
            endTimeInEdit: 221.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 221.7,
            endTimeInOriginal: 221.8,
            startTimeInEdit: 221.7,
            endTimeInEdit: 221.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'right',
            startTimeInOriginal: 221.8,
            endTimeInOriginal: 221.9,
            startTimeInEdit: 221.8,
            endTimeInEdit: 221.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'hand',
            startTimeInOriginal: 221.9,
            endTimeInOriginal: 222.1,
            startTimeInEdit: 221.9,
            endTimeInEdit: 222.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'side',
            startTimeInOriginal: 222.1,
            endTimeInOriginal: 222.3,
            startTimeInEdit: 222.1,
            endTimeInEdit: 222.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 222.3,
            endTimeInOriginal: 222.4,
            startTimeInEdit: 222.3,
            endTimeInEdit: 222.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 222.4,
            endTimeInOriginal: 222.4,
            startTimeInEdit: 222.4,
            endTimeInEdit: 222.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'screen',
            startTimeInOriginal: 222.4,
            endTimeInOriginal: 222.7,
            startTimeInEdit: 222.4,
            endTimeInEdit: 222.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'above',
            startTimeInOriginal: 222.7,
            endTimeInOriginal: 223,
            startTimeInEdit: 222.7,
            endTimeInEdit: 223
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'my',
            startTimeInOriginal: 223,
            endTimeInOriginal: 223.2,
            startTimeInEdit: 223,
            endTimeInEdit: 223.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'head,',
            startTimeInOriginal: 223.2,
            endTimeInOriginal: 223.6,
            startTimeInEdit: 223.2,
            endTimeInEdit: 223.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "there's",
            startTimeInOriginal: 223.6,
            endTimeInOriginal: 223.9,
            startTimeInEdit: 223.6,
            endTimeInEdit: 223.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 223.9,
            endTimeInOriginal: 224.1,
            startTimeInEdit: 223.9,
            endTimeInEdit: 224.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'button',
            startTimeInOriginal: 224.1,
            endTimeInOriginal: 224.4,
            startTimeInEdit: 224.1,
            endTimeInEdit: 224.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 224.4,
            endTimeInOriginal: 224.7,
            startTimeInEdit: 224.4,
            endTimeInEdit: 224.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'reads',
            startTimeInOriginal: 224.7,
            endTimeInOriginal: 225,
            startTimeInEdit: 224.7,
            endTimeInEdit: 225
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'original.',
            startTimeInOriginal: 225,
            endTimeInOriginal: 225.6,
            startTimeInEdit: 225,
            endTimeInEdit: 225.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Go',
            startTimeInOriginal: 225.6,
            endTimeInOriginal: 225.7,
            startTimeInEdit: 225.6,
            endTimeInEdit: 225.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'ahead',
            startTimeInOriginal: 225.7,
            endTimeInOriginal: 225.9,
            startTimeInEdit: 225.7,
            endTimeInEdit: 225.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 225.9,
            endTimeInOriginal: 226,
            startTimeInEdit: 225.9,
            endTimeInEdit: 226
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'click',
            startTimeInOriginal: 226,
            endTimeInOriginal: 226.2,
            startTimeInEdit: 226,
            endTimeInEdit: 226.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 226.2,
            endTimeInOriginal: 226.4,
            startTimeInEdit: 226.2,
            endTimeInEdit: 226.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 226.4,
            endTimeInOriginal: 226.5,
            startTimeInEdit: 226.4,
            endTimeInEdit: 226.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 226.5,
            endTimeInOriginal: 226.5,
            startTimeInEdit: 226.5,
            endTimeInEdit: 226.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'drop-down',
            startTimeInOriginal: 226.5,
            endTimeInOriginal: 227,
            startTimeInEdit: 226.5,
            endTimeInEdit: 227
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'menu',
            startTimeInOriginal: 227,
            endTimeInOriginal: 227.2,
            startTimeInEdit: 227,
            endTimeInEdit: 227.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 227.2,
            endTimeInOriginal: 227.4,
            startTimeInEdit: 227.2,
            endTimeInEdit: 227.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'appear',
            startTimeInOriginal: 227.4,
            endTimeInOriginal: 227.8,
            startTimeInEdit: 227.4,
            endTimeInEdit: 227.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'offering',
            startTimeInOriginal: 227.8,
            endTimeInOriginal: 228.2,
            startTimeInEdit: 227.8,
            endTimeInEdit: 228.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 228.2,
            endTimeInOriginal: 228.4,
            startTimeInEdit: 228.2,
            endTimeInEdit: 228.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 228.4,
            endTimeInOriginal: 228.6,
            startTimeInEdit: 228.4,
            endTimeInEdit: 228.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'number',
            startTimeInOriginal: 228.6,
            endTimeInOriginal: 229.1,
            startTimeInEdit: 228.6,
            endTimeInEdit: 229.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 229.1,
            endTimeInOriginal: 229.2,
            startTimeInEdit: 229.1,
            endTimeInEdit: 229.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'different',
            startTimeInOriginal: 229.2,
            endTimeInOriginal: 229.5,
            startTimeInEdit: 229.2,
            endTimeInEdit: 229.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'aspect',
            startTimeInOriginal: 229.5,
            endTimeInOriginal: 229.9,
            startTimeInEdit: 229.5,
            endTimeInEdit: 229.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'ratios',
            startTimeInOriginal: 229.9,
            endTimeInOriginal: 230.4,
            startTimeInEdit: 229.9,
            endTimeInEdit: 230.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 230.4,
            endTimeInOriginal: 230.5,
            startTimeInEdit: 230.4,
            endTimeInEdit: 230.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'choose',
            startTimeInOriginal: 230.5,
            endTimeInOriginal: 230.7,
            startTimeInEdit: 230.5,
            endTimeInEdit: 230.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'from.',
            startTimeInOriginal: 230.7,
            endTimeInOriginal: 231,
            startTimeInEdit: 230.7,
            endTimeInEdit: 231
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 231,
            endTimeInOriginal: 231.1,
            startTimeInEdit: 231,
            endTimeInEdit: 231.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Make',
            startTimeInOriginal: 231.1,
            endTimeInOriginal: 231.4,
            startTimeInEdit: 231.1,
            endTimeInEdit: 231.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 231.4,
            endTimeInOriginal: 231.5,
            startTimeInEdit: 231.4,
            endTimeInEdit: 231.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'choice',
            startTimeInOriginal: 231.5,
            endTimeInOriginal: 232,
            startTimeInEdit: 231.5,
            endTimeInEdit: 232
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'wisely',
            startTimeInOriginal: 232,
            endTimeInOriginal: 232.8,
            startTimeInEdit: 232,
            endTimeInEdit: 232.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 232.8,
            endTimeInOriginal: 232.9,
            startTimeInEdit: 232.8,
            endTimeInEdit: 232.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 232.9,
            endTimeInOriginal: 233.1,
            startTimeInEdit: 232.9,
            endTimeInEdit: 233.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'then',
            startTimeInOriginal: 233.1,
            endTimeInOriginal: 233.2,
            startTimeInEdit: 233.1,
            endTimeInEdit: 233.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'click',
            startTimeInOriginal: 233.2,
            endTimeInOriginal: 233.4,
            startTimeInEdit: 233.2,
            endTimeInEdit: 233.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 233.4,
            endTimeInOriginal: 233.5,
            startTimeInEdit: 233.4,
            endTimeInEdit: 233.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 233.5,
            endTimeInOriginal: 233.6,
            startTimeInEdit: 233.5,
            endTimeInEdit: 233.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'preferred',
            startTimeInOriginal: 233.6,
            endTimeInOriginal: 234,
            startTimeInEdit: 233.6,
            endTimeInEdit: 234
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'aspect',
            startTimeInOriginal: 234,
            endTimeInOriginal: 234.4,
            startTimeInEdit: 234,
            endTimeInEdit: 234.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'ratio.',
            startTimeInOriginal: 234.4,
            endTimeInOriginal: 234.8,
            startTimeInEdit: 234.4,
            endTimeInEdit: 234.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 234.8,
            endTimeInOriginal: 234.9,
            startTimeInEdit: 234.8,
            endTimeInEdit: 234.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'This',
            startTimeInOriginal: 234.9,
            endTimeInOriginal: 235,
            startTimeInEdit: 234.9,
            endTimeInEdit: 235
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 235,
            endTimeInOriginal: 235.1,
            startTimeInEdit: 235,
            endTimeInEdit: 235.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'adjust',
            startTimeInOriginal: 235.1,
            endTimeInOriginal: 235.4,
            startTimeInEdit: 235.1,
            endTimeInEdit: 235.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 235.4,
            endTimeInOriginal: 235.5,
            startTimeInEdit: 235.4,
            endTimeInEdit: 235.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'aspect',
            startTimeInOriginal: 235.5,
            endTimeInOriginal: 235.8,
            startTimeInEdit: 235.5,
            endTimeInEdit: 235.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'ratio',
            startTimeInOriginal: 235.8,
            endTimeInOriginal: 236.1,
            startTimeInEdit: 235.8,
            endTimeInEdit: 236.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 236.1,
            endTimeInOriginal: 236.2,
            startTimeInEdit: 236.1,
            endTimeInEdit: 236.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 236.2,
            endTimeInOriginal: 236.3,
            startTimeInEdit: 236.2,
            endTimeInEdit: 236.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'canvas',
            startTimeInOriginal: 236.3,
            endTimeInOriginal: 237,
            startTimeInEdit: 236.3,
            endTimeInEdit: 237
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 237,
            endTimeInOriginal: 237.3,
            startTimeInEdit: 237,
            endTimeInEdit: 237.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'fit',
            startTimeInOriginal: 237.3,
            endTimeInOriginal: 237.5,
            startTimeInEdit: 237.3,
            endTimeInEdit: 237.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 237.5,
            endTimeInOriginal: 237.6,
            startTimeInEdit: 237.5,
            endTimeInEdit: 237.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video',
            startTimeInOriginal: 237.6,
            endTimeInOriginal: 237.8,
            startTimeInEdit: 237.6,
            endTimeInEdit: 237.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 237.8,
            endTimeInOriginal: 237.9,
            startTimeInEdit: 237.8,
            endTimeInEdit: 237.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 237.9,
            endTimeInOriginal: 238.1,
            startTimeInEdit: 237.9,
            endTimeInEdit: 238.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'accordingly.',
            startTimeInOriginal: 238.1,
            endTimeInOriginal: 238.7,
            startTimeInEdit: 238.1,
            endTimeInEdit: 238.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'To',
            startTimeInOriginal: 238.7,
            endTimeInOriginal: 238.8,
            startTimeInEdit: 238.7,
            endTimeInEdit: 238.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'alter',
            startTimeInOriginal: 238.8,
            endTimeInOriginal: 239.1,
            startTimeInEdit: 238.8,
            endTimeInEdit: 239.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 239.1,
            endTimeInOriginal: 239.2,
            startTimeInEdit: 239.1,
            endTimeInEdit: 239.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'size',
            startTimeInOriginal: 239.2,
            endTimeInOriginal: 239.5,
            startTimeInEdit: 239.2,
            endTimeInEdit: 239.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 239.5,
            endTimeInOriginal: 239.7,
            startTimeInEdit: 239.5,
            endTimeInEdit: 239.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'position',
            startTimeInOriginal: 239.7,
            endTimeInOriginal: 240.2,
            startTimeInEdit: 239.7,
            endTimeInEdit: 240.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 240.2,
            endTimeInOriginal: 240.4,
            startTimeInEdit: 240.2,
            endTimeInEdit: 240.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 240.4,
            endTimeInOriginal: 240.5,
            startTimeInEdit: 240.4,
            endTimeInEdit: 240.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video,',
            startTimeInOriginal: 240.5,
            endTimeInOriginal: 240.9,
            startTimeInEdit: 240.5,
            endTimeInEdit: 240.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 240.9,
            endTimeInOriginal: 241.1,
            startTimeInEdit: 240.9,
            endTimeInEdit: 241.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'make',
            startTimeInOriginal: 241.1,
            endTimeInOriginal: 241.2,
            startTimeInEdit: 241.1,
            endTimeInEdit: 241.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'sure',
            startTimeInOriginal: 241.2,
            endTimeInOriginal: 241.4,
            startTimeInEdit: 241.2,
            endTimeInEdit: 241.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 241.4,
            endTimeInOriginal: 241.6,
            startTimeInEdit: 241.4,
            endTimeInEdit: 241.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'fits',
            startTimeInOriginal: 241.6,
            endTimeInOriginal: 241.8,
            startTimeInEdit: 241.6,
            endTimeInEdit: 241.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 241.8,
            endTimeInOriginal: 242,
            startTimeInEdit: 241.8,
            endTimeInEdit: 242
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'whatever',
            startTimeInOriginal: 242,
            endTimeInOriginal: 242.3,
            startTimeInEdit: 242,
            endTimeInEdit: 242.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'new',
            startTimeInOriginal: 242.3,
            endTimeInOriginal: 242.5,
            startTimeInEdit: 242.3,
            endTimeInEdit: 242.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'aspect',
            startTimeInOriginal: 242.5,
            endTimeInOriginal: 242.9,
            startTimeInEdit: 242.5,
            endTimeInEdit: 242.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'ratio',
            startTimeInOriginal: 242.9,
            endTimeInOriginal: 243.3,
            startTimeInEdit: 242.9,
            endTimeInEdit: 243.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 243.3,
            endTimeInOriginal: 243.5,
            startTimeInEdit: 243.3,
            endTimeInEdit: 243.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 243.5,
            endTimeInOriginal: 243.6,
            startTimeInEdit: 243.5,
            endTimeInEdit: 243.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'have,',
            startTimeInOriginal: 243.6,
            endTimeInOriginal: 244.2,
            startTimeInEdit: 243.6,
            endTimeInEdit: 244.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "all you're",
            startTimeInOriginal: 244.2,
            endTimeInOriginal: 244.3,
            startTimeInEdit: 244.2,
            endTimeInEdit: 244.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'going',
            startTimeInOriginal: 244.3,
            endTimeInOriginal: 244.5,
            startTimeInEdit: 244.3,
            endTimeInEdit: 244.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 244.5,
            endTimeInOriginal: 244.5,
            startTimeInEdit: 244.5,
            endTimeInEdit: 244.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do',
            startTimeInOriginal: 244.5,
            endTimeInOriginal: 244.6,
            startTimeInEdit: 244.5,
            endTimeInEdit: 244.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'is',
            startTimeInOriginal: 244.6,
            endTimeInOriginal: 244.8,
            startTimeInEdit: 244.6,
            endTimeInEdit: 244.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "you're",
            startTimeInOriginal: 244.8,
            endTimeInOriginal: 244.9,
            startTimeInEdit: 244.8,
            endTimeInEdit: 244.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'going',
            startTimeInOriginal: 244.9,
            endTimeInOriginal: 245,
            startTimeInEdit: 244.9,
            endTimeInEdit: 245
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 245,
            endTimeInOriginal: 245,
            startTimeInEdit: 245,
            endTimeInEdit: 245
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'click',
            startTimeInOriginal: 245,
            endTimeInOriginal: 245.3,
            startTimeInEdit: 245,
            endTimeInEdit: 245.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 245.3,
            endTimeInOriginal: 245.4,
            startTimeInEdit: 245.3,
            endTimeInEdit: 245.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'drag',
            startTimeInOriginal: 245.4,
            endTimeInOriginal: 245.7,
            startTimeInEdit: 245.4,
            endTimeInEdit: 245.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it,',
            startTimeInOriginal: 245.7,
            endTimeInOriginal: 245.8,
            startTimeInEdit: 245.7,
            endTimeInEdit: 245.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 245.8,
            endTimeInOriginal: 245.9,
            startTimeInEdit: 245.8,
            endTimeInEdit: 245.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 245.9,
            endTimeInOriginal: 246,
            startTimeInEdit: 245.9,
            endTimeInEdit: 246
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'move',
            startTimeInOriginal: 246,
            endTimeInOriginal: 246.2,
            startTimeInEdit: 246,
            endTimeInEdit: 246.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 246.2,
            endTimeInOriginal: 246.3,
            startTimeInEdit: 246.2,
            endTimeInEdit: 246.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'around',
            startTimeInOriginal: 246.3,
            endTimeInOriginal: 246.9,
            startTimeInEdit: 246.3,
            endTimeInEdit: 246.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 246.9,
            endTimeInOriginal: 247,
            startTimeInEdit: 246.9,
            endTimeInEdit: 247
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 247,
            endTimeInOriginal: 247.1,
            startTimeInEdit: 247,
            endTimeInEdit: 247.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 247.1,
            endTimeInOriginal: 247.3,
            startTimeInEdit: 247.1,
            endTimeInEdit: 247.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'also',
            startTimeInOriginal: 247.3,
            endTimeInOriginal: 247.6,
            startTimeInEdit: 247.3,
            endTimeInEdit: 247.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'change',
            startTimeInOriginal: 247.6,
            endTimeInOriginal: 247.9,
            startTimeInEdit: 247.6,
            endTimeInEdit: 247.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 247.9,
            endTimeInOriginal: 248,
            startTimeInEdit: 247.9,
            endTimeInEdit: 248
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'size',
            startTimeInOriginal: 248,
            endTimeInOriginal: 248.4,
            startTimeInEdit: 248,
            endTimeInEdit: 248.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 248.4,
            endTimeInOriginal: 248.4,
            startTimeInEdit: 248.4,
            endTimeInEdit: 248.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 248.4,
            endTimeInOriginal: 248.6,
            startTimeInEdit: 248.4,
            endTimeInEdit: 248.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'by',
            startTimeInOriginal: 248.6,
            endTimeInOriginal: 248.7,
            startTimeInEdit: 248.6,
            endTimeInEdit: 248.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'clicking',
            startTimeInOriginal: 248.7,
            endTimeInOriginal: 249,
            startTimeInEdit: 248.7,
            endTimeInEdit: 249
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 249,
            endTimeInOriginal: 249.1,
            startTimeInEdit: 249,
            endTimeInEdit: 249.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 249.1,
            endTimeInOriginal: 249.2,
            startTimeInEdit: 249.1,
            endTimeInEdit: 249.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'corners,',
            startTimeInOriginal: 249.2,
            endTimeInOriginal: 249.7,
            startTimeInEdit: 249.2,
            endTimeInEdit: 249.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 249.7,
            endTimeInOriginal: 249.79999999999998,
            startTimeInEdit: 249.7,
            endTimeInEdit: 249.79999999999998
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 249.8,
            endTimeInOriginal: 249.9,
            startTimeInEdit: 249.8,
            endTimeInEdit: 249.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'moving',
            startTimeInOriginal: 249.9,
            endTimeInOriginal: 250.2,
            startTimeInEdit: 249.9,
            endTimeInEdit: 250.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 250.2,
            endTimeInOriginal: 250.4,
            startTimeInEdit: 250.2,
            endTimeInEdit: 250.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'out,',
            startTimeInOriginal: 250.4,
            endTimeInOriginal: 250.6,
            startTimeInEdit: 250.4,
            endTimeInEdit: 250.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 250.6,
            endTimeInOriginal: 250.7,
            startTimeInEdit: 250.6,
            endTimeInEdit: 250.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'moving',
            startTimeInOriginal: 250.7,
            endTimeInOriginal: 251,
            startTimeInEdit: 250.7,
            endTimeInEdit: 251
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 251,
            endTimeInOriginal: 251.1,
            startTimeInEdit: 251,
            endTimeInEdit: 251.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in to',
            startTimeInOriginal: 251.1,
            endTimeInOriginal: 251.4,
            startTimeInEdit: 251.1,
            endTimeInEdit: 251.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'make',
            startTimeInOriginal: 251.4,
            endTimeInOriginal: 251.5,
            startTimeInEdit: 251.4,
            endTimeInEdit: 251.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 251.5,
            endTimeInOriginal: 251.6,
            startTimeInEdit: 251.5,
            endTimeInEdit: 251.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'bigger',
            startTimeInOriginal: 251.6,
            endTimeInOriginal: 251.9,
            startTimeInEdit: 251.6,
            endTimeInEdit: 251.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 251.9,
            endTimeInOriginal: 252.1,
            startTimeInEdit: 251.9,
            endTimeInEdit: 252.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'smaller.',
            startTimeInOriginal: 252.1,
            endTimeInOriginal: 252.7,
            startTimeInEdit: 252.1,
            endTimeInEdit: 252.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'If',
            startTimeInOriginal: 252.7,
            endTimeInOriginal: 252.8,
            startTimeInEdit: 252.7,
            endTimeInEdit: 252.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'at',
            startTimeInOriginal: 252.8,
            endTimeInOriginal: 252.9,
            startTimeInEdit: 252.8,
            endTimeInEdit: 252.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'any',
            startTimeInOriginal: 252.9,
            endTimeInOriginal: 253.1,
            startTimeInEdit: 252.9,
            endTimeInEdit: 253.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'point,',
            startTimeInOriginal: 253.1,
            endTimeInOriginal: 253.3,
            startTimeInEdit: 253.1,
            endTimeInEdit: 253.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 253.3,
            endTimeInOriginal: 253.4,
            startTimeInEdit: 253.3,
            endTimeInEdit: 253.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'want',
            startTimeInOriginal: 253.4,
            endTimeInOriginal: 253.5,
            startTimeInEdit: 253.4,
            endTimeInEdit: 253.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 253.5,
            endTimeInOriginal: 253.6,
            startTimeInEdit: 253.5,
            endTimeInEdit: 253.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'go',
            startTimeInOriginal: 253.6,
            endTimeInOriginal: 253.6,
            startTimeInEdit: 253.6,
            endTimeInEdit: 253.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'back',
            startTimeInOriginal: 253.6,
            endTimeInOriginal: 253.8,
            startTimeInEdit: 253.6,
            endTimeInEdit: 253.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 253.8,
            endTimeInOriginal: 253.9,
            startTimeInEdit: 253.8,
            endTimeInEdit: 253.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 253.9,
            endTimeInOriginal: 254.1,
            startTimeInEdit: 253.9,
            endTimeInEdit: 254.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'original',
            startTimeInOriginal: 254.1,
            endTimeInOriginal: 254.5,
            startTimeInEdit: 254.1,
            endTimeInEdit: 254.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'aspect',
            startTimeInOriginal: 254.5,
            endTimeInOriginal: 254.8,
            startTimeInEdit: 254.5,
            endTimeInEdit: 254.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'ratio,',
            startTimeInOriginal: 254.8,
            endTimeInOriginal: 255.1,
            startTimeInEdit: 254.8,
            endTimeInEdit: 255.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'all',
            startTimeInOriginal: 255.1,
            endTimeInOriginal: 255.2,
            startTimeInEdit: 255.1,
            endTimeInEdit: 255.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 255.2,
            endTimeInOriginal: 255.3,
            startTimeInEdit: 255.2,
            endTimeInEdit: 255.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'got',
            startTimeInOriginal: 255.3,
            endTimeInOriginal: 255.4,
            startTimeInEdit: 255.3,
            endTimeInEdit: 255.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 255.4,
            endTimeInOriginal: 255.4,
            startTimeInEdit: 255.4,
            endTimeInEdit: 255.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do',
            startTimeInOriginal: 255.4,
            endTimeInOriginal: 255.6,
            startTimeInEdit: 255.4,
            endTimeInEdit: 255.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'is',
            startTimeInOriginal: 255.6,
            endTimeInOriginal: 255.6,
            startTimeInEdit: 255.6,
            endTimeInEdit: 255.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'go',
            startTimeInOriginal: 255.6,
            endTimeInOriginal: 255.8,
            startTimeInEdit: 255.6,
            endTimeInEdit: 255.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 255.8,
            endTimeInOriginal: 255.9,
            startTimeInEdit: 255.8,
            endTimeInEdit: 255.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 255.9,
            endTimeInOriginal: 256,
            startTimeInEdit: 255.9,
            endTimeInEdit: 256
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'drop-down',
            startTimeInOriginal: 256,
            endTimeInOriginal: 256.5,
            startTimeInEdit: 256,
            endTimeInEdit: 256.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'menu',
            startTimeInOriginal: 256.5,
            endTimeInOriginal: 256.8,
            startTimeInEdit: 256.5,
            endTimeInEdit: 256.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'above',
            startTimeInOriginal: 256.8,
            endTimeInOriginal: 257.2,
            startTimeInEdit: 256.8,
            endTimeInEdit: 257.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 257.2,
            endTimeInOriginal: 257.3,
            startTimeInEdit: 257.2,
            endTimeInEdit: 257.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video',
            startTimeInOriginal: 257.3,
            endTimeInOriginal: 257.6,
            startTimeInEdit: 257.3,
            endTimeInEdit: 257.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'preview,',
            startTimeInOriginal: 257.6,
            endTimeInOriginal: 258.1,
            startTimeInEdit: 257.6,
            endTimeInEdit: 258.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 258.1,
            endTimeInOriginal: 258.20000000000005,
            startTimeInEdit: 258.1,
            endTimeInEdit: 258.20000000000005
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'click',
            startTimeInOriginal: 258.2,
            endTimeInOriginal: 258.4,
            startTimeInEdit: 258.2,
            endTimeInEdit: 258.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 258.4,
            endTimeInOriginal: 258.6,
            startTimeInEdit: 258.4,
            endTimeInEdit: 258.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 258.6,
            endTimeInOriginal: 258.7,
            startTimeInEdit: 258.6,
            endTimeInEdit: 258.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'then',
            startTimeInOriginal: 258.7,
            endTimeInOriginal: 258.9,
            startTimeInEdit: 258.7,
            endTimeInEdit: 258.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'go',
            startTimeInOriginal: 258.9,
            endTimeInOriginal: 259,
            startTimeInEdit: 258.9,
            endTimeInEdit: 259
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 259,
            endTimeInOriginal: 259.2,
            startTimeInEdit: 259,
            endTimeInEdit: 259.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'click',
            startTimeInOriginal: 259.2,
            endTimeInOriginal: 259.5,
            startTimeInEdit: 259.2,
            endTimeInEdit: 259.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 259.5,
            endTimeInOriginal: 259.6,
            startTimeInEdit: 259.5,
            endTimeInEdit: 259.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'original',
            startTimeInOriginal: 259.6,
            endTimeInOriginal: 260.2,
            startTimeInEdit: 259.6,
            endTimeInEdit: 260.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 260.2,
            endTimeInOriginal: 260.4,
            startTimeInEdit: 260.2,
            endTimeInEdit: 260.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 260.4,
            endTimeInOriginal: 260.6,
            startTimeInEdit: 260.4,
            endTimeInEdit: 260.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "it'll",
            startTimeInOriginal: 260.6,
            endTimeInOriginal: 260.9,
            startTimeInEdit: 260.6,
            endTimeInEdit: 260.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 260.9,
            endTimeInOriginal: 261.09999999999997,
            startTimeInEdit: 260.9,
            endTimeInEdit: 261.09999999999997
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'put',
            startTimeInOriginal: 261.1,
            endTimeInOriginal: 261.3,
            startTimeInEdit: 261.1,
            endTimeInEdit: 261.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 261.3,
            endTimeInOriginal: 261.4,
            startTimeInEdit: 261.3,
            endTimeInEdit: 261.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'back',
            startTimeInOriginal: 261.4,
            endTimeInOriginal: 261.6,
            startTimeInEdit: 261.4,
            endTimeInEdit: 261.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 261.6,
            endTimeInOriginal: 261.6,
            startTimeInEdit: 261.6,
            endTimeInEdit: 261.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 261.6,
            endTimeInOriginal: 261.7,
            startTimeInEdit: 261.6,
            endTimeInEdit: 261.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'original.',
            startTimeInOriginal: 261.7,
            endTimeInOriginal: 262.3,
            startTimeInEdit: 261.7,
            endTimeInEdit: 262.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Crazy',
            startTimeInOriginal: 262.3,
            endTimeInOriginal: 262.4,
            startTimeInEdit: 262.3,
            endTimeInEdit: 262.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'how',
            startTimeInOriginal: 262.4,
            endTimeInOriginal: 262.6,
            startTimeInEdit: 262.4,
            endTimeInEdit: 262.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'straightforward',
            startTimeInOriginal: 262.6,
            endTimeInOriginal: 262.8,
            startTimeInEdit: 262.6,
            endTimeInEdit: 262.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 262.8,
            endTimeInOriginal: 263,
            startTimeInEdit: 262.8,
            endTimeInEdit: 263
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'is',
            startTimeInOriginal: 263,
            endTimeInOriginal: 263.1,
            startTimeInEdit: 263,
            endTimeInEdit: 263.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: true,
            text: 'right?',
            startTimeInOriginal: 263.1,
            endTimeInOriginal: 263.7,
            startTimeInEdit: 263.1,
            endTimeInEdit: 263.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 263.7,
            endTimeInOriginal: 264.5,
            startTimeInEdit: 263.7,
            endTimeInEdit: 264.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Part',
            startTimeInOriginal: 264.5,
            endTimeInOriginal: 264.9,
            startTimeInEdit: 264.5,
            endTimeInEdit: 264.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: '5:',
            startTimeInOriginal: 264.9,
            endTimeInOriginal: 265,
            startTimeInEdit: 264.9,
            endTimeInEdit: 265
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Add',
            startTimeInOriginal: 265,
            endTimeInOriginal: 265.1,
            startTimeInEdit: 265,
            endTimeInEdit: 265.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Text',
            startTimeInOriginal: 265.1,
            endTimeInOriginal: 265.3,
            startTimeInEdit: 265.1,
            endTimeInEdit: 265.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 265.3,
            endTimeInOriginal: 265.7,
            startTimeInEdit: 265.3,
            endTimeInEdit: 265.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Image',
            startTimeInOriginal: 265.7,
            endTimeInOriginal: 266,
            startTimeInEdit: 265.7,
            endTimeInEdit: 266
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Animations',
            startTimeInOriginal: 266,
            endTimeInOriginal: 266.3,
            startTimeInEdit: 266,
            endTimeInEdit: 266.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: true,
            text: '',
            startTimeInOriginal: 266.3,
            endTimeInOriginal: 267.1,
            startTimeInEdit: 266.3,
            endTimeInEdit: 267.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Now',
            startTimeInOriginal: 267.1,
            endTimeInOriginal: 267.2,
            startTimeInEdit: 267.1,
            endTimeInEdit: 267.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'this ',
            startTimeInOriginal: 267.2,
            endTimeInOriginal: 267.4,
            startTimeInEdit: 267.2,
            endTimeInEdit: 267.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'one',
            startTimeInOriginal: 267.4,
            endTimeInOriginal: 267.4,
            startTimeInEdit: 267.4,
            endTimeInEdit: 267.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'is',
            startTimeInOriginal: 267.4,
            endTimeInOriginal: 267.6,
            startTimeInEdit: 267.4,
            endTimeInEdit: 267.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 267.6,
            endTimeInOriginal: 267.6,
            startTimeInEdit: 267.6,
            endTimeInEdit: 267.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'little',
            startTimeInOriginal: 267.6,
            endTimeInOriginal: 267.8,
            startTimeInEdit: 267.6,
            endTimeInEdit: 267.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'bit',
            startTimeInOriginal: 267.8,
            endTimeInOriginal: 268,
            startTimeInEdit: 267.8,
            endTimeInEdit: 268
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'more',
            startTimeInOriginal: 268,
            endTimeInOriginal: 268.1,
            startTimeInEdit: 268,
            endTimeInEdit: 268.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'tricky',
            startTimeInOriginal: 268.1,
            endTimeInOriginal: 268.6,
            startTimeInEdit: 268.1,
            endTimeInEdit: 268.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 268.6,
            endTimeInOriginal: 268.7,
            startTimeInEdit: 268.6,
            endTimeInEdit: 268.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'may',
            startTimeInOriginal: 268.7,
            endTimeInOriginal: 268.9,
            startTimeInEdit: 268.7,
            endTimeInEdit: 268.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'take',
            startTimeInOriginal: 268.9,
            endTimeInOriginal: 269.2,
            startTimeInEdit: 268.9,
            endTimeInEdit: 269.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 269.2,
            endTimeInOriginal: 269.2,
            startTimeInEdit: 269.2,
            endTimeInEdit: 269.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'little',
            startTimeInOriginal: 269.2,
            endTimeInOriginal: 269.5,
            startTimeInEdit: 269.2,
            endTimeInEdit: 269.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'bit',
            startTimeInOriginal: 269.5,
            endTimeInOriginal: 269.8,
            startTimeInEdit: 269.5,
            endTimeInEdit: 269.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 269.8,
            endTimeInOriginal: 270,
            startTimeInEdit: 269.8,
            endTimeInEdit: 270
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'effort.',
            startTimeInOriginal: 270,
            endTimeInOriginal: 270.5,
            startTimeInEdit: 270,
            endTimeInEdit: 270.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'And',
            startTimeInOriginal: 270.5,
            endTimeInOriginal: 270.6,
            startTimeInEdit: 270.5,
            endTimeInEdit: 270.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "that's",
            startTimeInOriginal: 270.6,
            endTimeInOriginal: 270.8,
            startTimeInEdit: 270.6,
            endTimeInEdit: 270.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'because',
            startTimeInOriginal: 270.8,
            endTimeInOriginal: 271.1,
            startTimeInEdit: 270.8,
            endTimeInEdit: 271.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 271.1,
            endTimeInOriginal: 271.2,
            startTimeInEdit: 271.1,
            endTimeInEdit: 271.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'order',
            startTimeInOriginal: 271.2,
            endTimeInOriginal: 271.4,
            startTimeInEdit: 271.2,
            endTimeInEdit: 271.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 271.4,
            endTimeInOriginal: 271.7,
            startTimeInEdit: 271.4,
            endTimeInEdit: 271.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'add',
            startTimeInOriginal: 271.7,
            endTimeInOriginal: 272,
            startTimeInEdit: 271.7,
            endTimeInEdit: 272
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'image',
            startTimeInOriginal: 272,
            endTimeInOriginal: 272.4,
            startTimeInEdit: 272,
            endTimeInEdit: 272.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 272.4,
            endTimeInOriginal: 272.6,
            startTimeInEdit: 272.4,
            endTimeInEdit: 272.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'text',
            startTimeInOriginal: 272.6,
            endTimeInOriginal: 272.9,
            startTimeInEdit: 272.6,
            endTimeInEdit: 272.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'animations,',
            startTimeInOriginal: 272.9,
            endTimeInOriginal: 273.8,
            startTimeInEdit: 272.9,
            endTimeInEdit: 273.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 273.8,
            endTimeInOriginal: 274,
            startTimeInEdit: 273.8,
            endTimeInEdit: 274
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'actually',
            startTimeInOriginal: 274,
            endTimeInOriginal: 274.4,
            startTimeInEdit: 274,
            endTimeInEdit: 274.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'have',
            startTimeInOriginal: 274.4,
            endTimeInOriginal: 274.5,
            startTimeInEdit: 274.4,
            endTimeInEdit: 274.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 274.5,
            endTimeInOriginal: 274.6,
            startTimeInEdit: 274.5,
            endTimeInEdit: 274.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'go',
            startTimeInOriginal: 274.6,
            endTimeInOriginal: 274.7,
            startTimeInEdit: 274.6,
            endTimeInEdit: 274.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'vegan.',
            startTimeInOriginal: 274.7,
            endTimeInOriginal: 275.4,
            startTimeInEdit: 274.7,
            endTimeInEdit: 275.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 275.4,
            endTimeInOriginal: 275.65,
            startTimeInEdit: 275.4,
            endTimeInEdit: 275.65
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 275.65,
            endTimeInOriginal: 275.9,
            startTimeInEdit: 275.65,
            endTimeInEdit: 275.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'No',
            startTimeInOriginal: 275.9,
            endTimeInOriginal: 276,
            startTimeInEdit: 275.9,
            endTimeInEdit: 276
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 276,
            endTimeInOriginal: 276.25,
            startTimeInEdit: 276,
            endTimeInEdit: 276.25
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 276.25,
            endTimeInOriginal: 276.3,
            startTimeInEdit: 276.25,
            endTimeInEdit: 276.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "no I'm",
            startTimeInOriginal: 276.3,
            endTimeInOriginal: 276.6,
            startTimeInEdit: 276.3,
            endTimeInEdit: 276.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'just',
            startTimeInOriginal: 276.6,
            endTimeInOriginal: 276.8,
            startTimeInEdit: 276.6,
            endTimeInEdit: 276.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'kidding.',
            startTimeInOriginal: 276.8,
            endTimeInOriginal: 277.2,
            startTimeInEdit: 276.8,
            endTimeInEdit: 277.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 277.2,
            endTimeInOriginal: 277.4,
            startTimeInEdit: 277.2,
            endTimeInEdit: 277.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Imagine',
            startTimeInOriginal: 277.4,
            endTimeInOriginal: 277.9,
            startTimeInEdit: 277.4,
            endTimeInEdit: 277.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'though,',
            startTimeInOriginal: 277.9,
            endTimeInOriginal: 278.3,
            startTimeInEdit: 277.9,
            endTimeInEdit: 278.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 278.3,
            endTimeInOriginal: 278.55,
            startTimeInEdit: 278.3,
            endTimeInEdit: 278.55
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 278.55,
            endTimeInOriginal: 278.8,
            startTimeInEdit: 278.55,
            endTimeInEdit: 278.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 278.8,
            endTimeInOriginal: 278.90000000000003,
            startTimeInEdit: 278.8,
            endTimeInEdit: 278.90000000000003
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'imagine.',
            startTimeInOriginal: 278.9,
            endTimeInOriginal: 279.5,
            startTimeInEdit: 278.9,
            endTimeInEdit: 279.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 279.5,
            endTimeInOriginal: 279.6,
            startTimeInEdit: 279.5,
            endTimeInEdit: 279.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'To',
            startTimeInOriginal: 279.6,
            endTimeInOriginal: 279.7,
            startTimeInEdit: 279.6,
            endTimeInEdit: 279.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do',
            startTimeInOriginal: 279.7,
            endTimeInOriginal: 279.8,
            startTimeInEdit: 279.7,
            endTimeInEdit: 279.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'this,',
            startTimeInOriginal: 279.8,
            endTimeInOriginal: 280,
            startTimeInEdit: 279.8,
            endTimeInEdit: 280
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "you're",
            startTimeInOriginal: 280,
            endTimeInOriginal: 280.1,
            startTimeInEdit: 280,
            endTimeInEdit: 280.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'going',
            startTimeInOriginal: 280.1,
            endTimeInOriginal: 280.2,
            startTimeInEdit: 280.1,
            endTimeInEdit: 280.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 280.2,
            endTimeInOriginal: 280.2,
            startTimeInEdit: 280.2,
            endTimeInEdit: 280.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'highlight',
            startTimeInOriginal: 280.2,
            endTimeInOriginal: 280.5,
            startTimeInEdit: 280.2,
            endTimeInEdit: 280.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 280.5,
            endTimeInOriginal: 280.6,
            startTimeInEdit: 280.5,
            endTimeInEdit: 280.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'text',
            startTimeInOriginal: 280.6,
            endTimeInOriginal: 280.9,
            startTimeInEdit: 280.6,
            endTimeInEdit: 280.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'area',
            startTimeInOriginal: 280.9,
            endTimeInOriginal: 281.2,
            startTimeInEdit: 280.9,
            endTimeInEdit: 281.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 281.2,
            endTimeInOriginal: 281.3,
            startTimeInEdit: 281.2,
            endTimeInEdit: 281.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'which',
            startTimeInOriginal: 281.3,
            endTimeInOriginal: 281.5,
            startTimeInEdit: 281.3,
            endTimeInEdit: 281.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 281.5,
            endTimeInOriginal: 281.6,
            startTimeInEdit: 281.5,
            endTimeInEdit: 281.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'want',
            startTimeInOriginal: 281.6,
            endTimeInOriginal: 281.7,
            startTimeInEdit: 281.6,
            endTimeInEdit: 281.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 281.7,
            endTimeInOriginal: 281.7,
            startTimeInEdit: 281.7,
            endTimeInEdit: 281.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'add',
            startTimeInOriginal: 281.7,
            endTimeInOriginal: 281.9,
            startTimeInEdit: 281.7,
            endTimeInEdit: 281.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 281.9,
            endTimeInOriginal: 282,
            startTimeInEdit: 281.9,
            endTimeInEdit: 282
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'text',
            startTimeInOriginal: 282,
            endTimeInOriginal: 282.3,
            startTimeInEdit: 282,
            endTimeInEdit: 282.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'block',
            startTimeInOriginal: 282.3,
            endTimeInOriginal: 282.7,
            startTimeInEdit: 282.3,
            endTimeInEdit: 282.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 282.7,
            endTimeInOriginal: 282.9,
            startTimeInEdit: 282.7,
            endTimeInEdit: 282.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'image',
            startTimeInOriginal: 282.9,
            endTimeInOriginal: 283.2,
            startTimeInEdit: 282.9,
            endTimeInEdit: 283.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 283.2,
            endTimeInOriginal: 283.6,
            startTimeInEdit: 283.2,
            endTimeInEdit: 283.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 283.6,
            endTimeInOriginal: 283.7,
            startTimeInEdit: 283.6,
            endTimeInEdit: 283.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 283.7,
            endTimeInOriginal: 283.8,
            startTimeInEdit: 283.7,
            endTimeInEdit: 283.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video.',
            startTimeInOriginal: 283.8,
            endTimeInOriginal: 284.2,
            startTimeInEdit: 283.8,
            endTimeInEdit: 284.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Once',
            startTimeInOriginal: 284.2,
            endTimeInOriginal: 284.4,
            startTimeInEdit: 284.2,
            endTimeInEdit: 284.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "you've",
            startTimeInOriginal: 284.4,
            endTimeInOriginal: 284.5,
            startTimeInEdit: 284.4,
            endTimeInEdit: 284.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'done',
            startTimeInOriginal: 284.5,
            endTimeInOriginal: 284.7,
            startTimeInEdit: 284.5,
            endTimeInEdit: 284.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 284.7,
            endTimeInOriginal: 284.8,
            startTimeInEdit: 284.7,
            endTimeInEdit: 284.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 284.8,
            endTimeInOriginal: 284.9,
            startTimeInEdit: 284.8,
            endTimeInEdit: 284.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'menu',
            startTimeInOriginal: 284.9,
            endTimeInOriginal: 285.1,
            startTimeInEdit: 284.9,
            endTimeInEdit: 285.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 285.1,
            endTimeInOriginal: 285.3,
            startTimeInEdit: 285.1,
            endTimeInEdit: 285.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'appear',
            startTimeInOriginal: 285.3,
            endTimeInOriginal: 285.6,
            startTimeInEdit: 285.3,
            endTimeInEdit: 285.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'above',
            startTimeInOriginal: 285.6,
            endTimeInOriginal: 286.2,
            startTimeInEdit: 285.6,
            endTimeInEdit: 286.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 286.2,
            endTimeInOriginal: 286.4,
            startTimeInEdit: 286.2,
            endTimeInEdit: 286.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'highlighted',
            startTimeInOriginal: 286.4,
            endTimeInOriginal: 286.8,
            startTimeInEdit: 286.4,
            endTimeInEdit: 286.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'section',
            startTimeInOriginal: 286.8,
            endTimeInOriginal: 287.2,
            startTimeInEdit: 286.8,
            endTimeInEdit: 287.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'offering',
            startTimeInOriginal: 287.2,
            endTimeInOriginal: 287.7,
            startTimeInEdit: 287.2,
            endTimeInEdit: 287.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 287.7,
            endTimeInOriginal: 288.1,
            startTimeInEdit: 287.7,
            endTimeInEdit: 288.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'three',
            startTimeInOriginal: 288.1,
            endTimeInOriginal: 288.4,
            startTimeInEdit: 288.1,
            endTimeInEdit: 288.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'options.',
            startTimeInOriginal: 288.4,
            endTimeInOriginal: 289.1,
            startTimeInEdit: 288.4,
            endTimeInEdit: 289.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'These',
            startTimeInOriginal: 289.1,
            endTimeInOriginal: 289.3,
            startTimeInEdit: 289.1,
            endTimeInEdit: 289.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'three',
            startTimeInOriginal: 289.3,
            endTimeInOriginal: 289.5,
            startTimeInEdit: 289.3,
            endTimeInEdit: 289.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'options',
            startTimeInOriginal: 289.5,
            endTimeInOriginal: 289.9,
            startTimeInEdit: 289.5,
            endTimeInEdit: 289.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'include',
            startTimeInOriginal: 289.9,
            endTimeInOriginal: 290.3,
            startTimeInEdit: 289.9,
            endTimeInEdit: 290.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'number',
            startTimeInOriginal: 290.3,
            endTimeInOriginal: 290.5,
            startTimeInEdit: 290.3,
            endTimeInEdit: 290.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'one,',
            startTimeInOriginal: 290.5,
            endTimeInOriginal: 291,
            startTimeInEdit: 290.5,
            endTimeInEdit: 291
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 291,
            endTimeInOriginal: 291.2,
            startTimeInEdit: 291,
            endTimeInEdit: 291.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'delete',
            startTimeInOriginal: 291.2,
            endTimeInOriginal: 291.5,
            startTimeInEdit: 291.2,
            endTimeInEdit: 291.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'key',
            startTimeInOriginal: 291.5,
            endTimeInOriginal: 291.8,
            startTimeInEdit: 291.5,
            endTimeInEdit: 291.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 291.8,
            endTimeInOriginal: 291.9,
            startTimeInEdit: 291.8,
            endTimeInEdit: 291.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'delete',
            startTimeInOriginal: 291.9,
            endTimeInOriginal: 292.2,
            startTimeInEdit: 291.9,
            endTimeInEdit: 292.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 292.2,
            endTimeInOriginal: 292.3,
            startTimeInEdit: 292.2,
            endTimeInEdit: 292.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'section',
            startTimeInOriginal: 292.3,
            endTimeInOriginal: 292.7,
            startTimeInEdit: 292.3,
            endTimeInEdit: 292.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'which',
            startTimeInOriginal: 292.7,
            endTimeInOriginal: 293,
            startTimeInEdit: 292.7,
            endTimeInEdit: 293
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "we're",
            startTimeInOriginal: 293,
            endTimeInOriginal: 293.2,
            startTimeInEdit: 293,
            endTimeInEdit: 293.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'not',
            startTimeInOriginal: 293.2,
            endTimeInOriginal: 293.4,
            startTimeInEdit: 293.2,
            endTimeInEdit: 293.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'going',
            startTimeInOriginal: 293.4,
            endTimeInOriginal: 293.5,
            startTimeInEdit: 293.4,
            endTimeInEdit: 293.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 293.5,
            endTimeInOriginal: 293.6,
            startTimeInEdit: 293.5,
            endTimeInEdit: 293.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do.',
            startTimeInOriginal: 293.6,
            endTimeInOriginal: 293.9,
            startTimeInEdit: 293.6,
            endTimeInEdit: 293.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'The',
            startTimeInOriginal: 293.9,
            endTimeInOriginal: 294,
            startTimeInEdit: 293.9,
            endTimeInEdit: 294
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'second',
            startTimeInOriginal: 294,
            endTimeInOriginal: 294.3,
            startTimeInEdit: 294,
            endTimeInEdit: 294.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'one,',
            startTimeInOriginal: 294.3,
            endTimeInOriginal: 294.5,
            startTimeInEdit: 294.3,
            endTimeInEdit: 294.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'being',
            startTimeInOriginal: 294.5,
            endTimeInOriginal: 294.8,
            startTimeInEdit: 294.5,
            endTimeInEdit: 294.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 294.8,
            endTimeInOriginal: 294.9,
            startTimeInEdit: 294.8,
            endTimeInEdit: 294.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'little',
            startTimeInOriginal: 294.9,
            endTimeInOriginal: 295.2,
            startTimeInEdit: 294.9,
            endTimeInEdit: 295.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'T',
            startTimeInOriginal: 295.2,
            endTimeInOriginal: 295.6,
            startTimeInEdit: 295.2,
            endTimeInEdit: 295.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'icon,',
            startTimeInOriginal: 295.6,
            endTimeInOriginal: 296.1,
            startTimeInEdit: 295.6,
            endTimeInEdit: 296.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 296.1,
            endTimeInOriginal: 296.2,
            startTimeInEdit: 296.1,
            endTimeInEdit: 296.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'indicates',
            startTimeInOriginal: 296.2,
            endTimeInOriginal: 296.9,
            startTimeInEdit: 296.2,
            endTimeInEdit: 296.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'text',
            startTimeInOriginal: 296.9,
            endTimeInOriginal: 297.2,
            startTimeInEdit: 296.9,
            endTimeInEdit: 297.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'block',
            startTimeInOriginal: 297.2,
            endTimeInOriginal: 297.45,
            startTimeInEdit: 297.2,
            endTimeInEdit: 297.45
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 297.45,
            endTimeInOriginal: 297.5,
            startTimeInEdit: 297.45,
            endTimeInEdit: 297.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'animation.',
            startTimeInOriginal: 297.5,
            endTimeInOriginal: 298.2,
            startTimeInEdit: 297.5,
            endTimeInEdit: 298.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 298.2,
            endTimeInOriginal: 298.3,
            startTimeInEdit: 298.2,
            endTimeInEdit: 298.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'And',
            startTimeInOriginal: 298.3,
            endTimeInOriginal: 298.4,
            startTimeInEdit: 298.3,
            endTimeInEdit: 298.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'then',
            startTimeInOriginal: 298.4,
            endTimeInOriginal: 298.5,
            startTimeInEdit: 298.4,
            endTimeInEdit: 298.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'finally,',
            startTimeInOriginal: 298.5,
            endTimeInOriginal: 298.9,
            startTimeInEdit: 298.5,
            endTimeInEdit: 298.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 298.9,
            endTimeInOriginal: 299,
            startTimeInEdit: 298.9,
            endTimeInEdit: 299
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'third',
            startTimeInOriginal: 299,
            endTimeInOriginal: 299.2,
            startTimeInEdit: 299,
            endTimeInEdit: 299.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'option,',
            startTimeInOriginal: 299.2,
            endTimeInOriginal: 299.4,
            startTimeInEdit: 299.2,
            endTimeInEdit: 299.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a ',
            startTimeInOriginal: 299.4,
            endTimeInOriginal: 299.5,
            startTimeInEdit: 299.4,
            endTimeInEdit: 299.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'little',
            startTimeInOriginal: 299.5,
            endTimeInOriginal: 299.8,
            startTimeInEdit: 299.5,
            endTimeInEdit: 299.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'image',
            startTimeInOriginal: 299.8,
            endTimeInOriginal: 300.1,
            startTimeInEdit: 299.8,
            endTimeInEdit: 300.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'icon',
            startTimeInOriginal: 300.1,
            endTimeInOriginal: 300.5,
            startTimeInEdit: 300.1,
            endTimeInEdit: 300.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'which',
            startTimeInOriginal: 300.5,
            endTimeInOriginal: 300.9,
            startTimeInEdit: 300.5,
            endTimeInEdit: 300.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 300.9,
            endTimeInOriginal: 301.1,
            startTimeInEdit: 300.9,
            endTimeInEdit: 301.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'guessed',
            startTimeInOriginal: 301.1,
            endTimeInOriginal: 301.4,
            startTimeInEdit: 301.1,
            endTimeInEdit: 301.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it,',
            startTimeInOriginal: 301.4,
            endTimeInOriginal: 301.6,
            startTimeInEdit: 301.4,
            endTimeInEdit: 301.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'is',
            startTimeInOriginal: 301.6,
            endTimeInOriginal: 301.8,
            startTimeInEdit: 301.6,
            endTimeInEdit: 301.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'for',
            startTimeInOriginal: 301.8,
            endTimeInOriginal: 302.1,
            startTimeInEdit: 301.8,
            endTimeInEdit: 302.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 302.1,
            endTimeInOriginal: 302.4,
            startTimeInEdit: 302.1,
            endTimeInEdit: 302.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'image',
            startTimeInOriginal: 302.4,
            endTimeInOriginal: 302.8,
            startTimeInEdit: 302.4,
            endTimeInEdit: 302.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'animation.',
            startTimeInOriginal: 302.8,
            endTimeInOriginal: 303.5,
            startTimeInEdit: 302.8,
            endTimeInEdit: 303.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'So',
            startTimeInOriginal: 303.5,
            endTimeInOriginal: 303.7,
            startTimeInEdit: 303.5,
            endTimeInEdit: 303.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'first,',
            startTimeInOriginal: 303.7,
            endTimeInOriginal: 303.9,
            startTimeInEdit: 303.7,
            endTimeInEdit: 303.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "let's",
            startTimeInOriginal: 303.9,
            endTimeInOriginal: 304.1,
            startTimeInEdit: 303.9,
            endTimeInEdit: 304.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'talk',
            startTimeInOriginal: 304.1,
            endTimeInOriginal: 304.4,
            startTimeInEdit: 304.1,
            endTimeInEdit: 304.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'text',
            startTimeInOriginal: 304.4,
            endTimeInOriginal: 304.7,
            startTimeInEdit: 304.4,
            endTimeInEdit: 304.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'animation.',
            startTimeInOriginal: 304.7,
            endTimeInOriginal: 305.3,
            startTimeInEdit: 304.7,
            endTimeInEdit: 305.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'If',
            startTimeInOriginal: 305.3,
            endTimeInOriginal: 305.4,
            startTimeInEdit: 305.3,
            endTimeInEdit: 305.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 305.4,
            endTimeInOriginal: 305.5,
            startTimeInEdit: 305.4,
            endTimeInEdit: 305.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'select',
            startTimeInOriginal: 305.5,
            endTimeInOriginal: 305.8,
            startTimeInEdit: 305.5,
            endTimeInEdit: 305.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 305.8,
            endTimeInOriginal: 305.9,
            startTimeInEdit: 305.8,
            endTimeInEdit: 305.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'T',
            startTimeInOriginal: 305.9,
            endTimeInOriginal: 306.1,
            startTimeInEdit: 305.9,
            endTimeInEdit: 306.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'icon,',
            startTimeInOriginal: 306.1,
            endTimeInOriginal: 306.7,
            startTimeInEdit: 306.1,
            endTimeInEdit: 306.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 306.7,
            endTimeInOriginal: 306.7,
            startTimeInEdit: 306.7,
            endTimeInEdit: 306.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'text',
            startTimeInOriginal: 306.7,
            endTimeInOriginal: 307.1,
            startTimeInEdit: 306.7,
            endTimeInEdit: 307.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'box',
            startTimeInOriginal: 307.1,
            endTimeInOriginal: 307.4,
            startTimeInEdit: 307.1,
            endTimeInEdit: 307.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 307.4,
            endTimeInOriginal: 307.5,
            startTimeInEdit: 307.4,
            endTimeInEdit: 307.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'appear',
            startTimeInOriginal: 307.5,
            endTimeInOriginal: 308,
            startTimeInEdit: 307.5,
            endTimeInEdit: 308
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 308,
            endTimeInOriginal: 308.4,
            startTimeInEdit: 308,
            endTimeInEdit: 308.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 308.4,
            endTimeInOriginal: 308.8,
            startTimeInEdit: 308.4,
            endTimeInEdit: 308.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video',
            startTimeInOriginal: 308.8,
            endTimeInOriginal: 309.1,
            startTimeInEdit: 308.8,
            endTimeInEdit: 309.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'preview.',
            startTimeInOriginal: 309.1,
            endTimeInOriginal: 309.6,
            startTimeInEdit: 309.1,
            endTimeInEdit: 309.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Now,',
            startTimeInOriginal: 309.6,
            endTimeInOriginal: 309.8,
            startTimeInEdit: 309.6,
            endTimeInEdit: 309.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'go',
            startTimeInOriginal: 309.8,
            endTimeInOriginal: 310,
            startTimeInEdit: 309.8,
            endTimeInEdit: 310
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'ahead',
            startTimeInOriginal: 310,
            endTimeInOriginal: 310.2,
            startTimeInEdit: 310,
            endTimeInEdit: 310.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 310.2,
            endTimeInOriginal: 310.3,
            startTimeInEdit: 310.2,
            endTimeInEdit: 310.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'double',
            startTimeInOriginal: 310.3,
            endTimeInOriginal: 310.4,
            startTimeInEdit: 310.3,
            endTimeInEdit: 310.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'click',
            startTimeInOriginal: 310.4,
            endTimeInOriginal: 310.6,
            startTimeInEdit: 310.4,
            endTimeInEdit: 310.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 310.6,
            endTimeInOriginal: 310.7,
            startTimeInEdit: 310.6,
            endTimeInEdit: 310.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 310.7,
            endTimeInOriginal: 310.9,
            startTimeInEdit: 310.7,
            endTimeInEdit: 310.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'text',
            startTimeInOriginal: 310.9,
            endTimeInOriginal: 311.1,
            startTimeInEdit: 310.9,
            endTimeInEdit: 311.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'box,',
            startTimeInOriginal: 311.1,
            endTimeInOriginal: 311.4,
            startTimeInEdit: 311.1,
            endTimeInEdit: 311.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'so',
            startTimeInOriginal: 311.4,
            endTimeInOriginal: 311.5,
            startTimeInEdit: 311.4,
            endTimeInEdit: 311.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 311.5,
            endTimeInOriginal: 311.6,
            startTimeInEdit: 311.5,
            endTimeInEdit: 311.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 311.6,
            endTimeInOriginal: 311.7,
            startTimeInEdit: 311.6,
            endTimeInEdit: 311.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 311.7,
            endTimeInOriginal: 311.8,
            startTimeInEdit: 311.7,
            endTimeInEdit: 311.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'adjust',
            startTimeInOriginal: 311.8,
            endTimeInOriginal: 312.2,
            startTimeInEdit: 311.8,
            endTimeInEdit: 312.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 312.2,
            endTimeInOriginal: 312.2,
            startTimeInEdit: 312.2,
            endTimeInEdit: 312.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'wording',
            startTimeInOriginal: 312.2,
            endTimeInOriginal: 312.5,
            startTimeInEdit: 312.2,
            endTimeInEdit: 312.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'within',
            startTimeInOriginal: 312.5,
            endTimeInOriginal: 312.7,
            startTimeInEdit: 312.5,
            endTimeInEdit: 312.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it.',
            startTimeInOriginal: 312.7,
            endTimeInOriginal: 312.9,
            startTimeInEdit: 312.7,
            endTimeInEdit: 312.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'You',
            startTimeInOriginal: 312.9,
            endTimeInOriginal: 313,
            startTimeInEdit: 312.9,
            endTimeInEdit: 313
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 313,
            endTimeInOriginal: 313.1,
            startTimeInEdit: 313,
            endTimeInEdit: 313.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'also',
            startTimeInOriginal: 313.1,
            endTimeInOriginal: 313.3,
            startTimeInEdit: 313.1,
            endTimeInEdit: 313.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'adjust',
            startTimeInOriginal: 313.3,
            endTimeInOriginal: 313.7,
            startTimeInEdit: 313.3,
            endTimeInEdit: 313.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 313.7,
            endTimeInOriginal: 313.8,
            startTimeInEdit: 313.7,
            endTimeInEdit: 313.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'font,',
            startTimeInOriginal: 313.8,
            endTimeInOriginal: 314.4,
            startTimeInEdit: 313.8,
            endTimeInEdit: 314.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'color,',
            startTimeInOriginal: 314.4,
            endTimeInOriginal: 314.8,
            startTimeInEdit: 314.4,
            endTimeInEdit: 314.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 314.8,
            endTimeInOriginal: 314.9,
            startTimeInEdit: 314.8,
            endTimeInEdit: 314.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'transparency',
            startTimeInOriginal: 314.9,
            endTimeInOriginal: 315.7,
            startTimeInEdit: 314.9,
            endTimeInEdit: 315.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 315.7,
            endTimeInOriginal: 315.9,
            startTimeInEdit: 315.7,
            endTimeInEdit: 315.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'this',
            startTimeInOriginal: 315.9,
            endTimeInOriginal: 316.2,
            startTimeInEdit: 315.9,
            endTimeInEdit: 316.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'text',
            startTimeInOriginal: 316.2,
            endTimeInOriginal: 316.4,
            startTimeInEdit: 316.2,
            endTimeInEdit: 316.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'block',
            startTimeInOriginal: 316.4,
            endTimeInOriginal: 317,
            startTimeInEdit: 316.4,
            endTimeInEdit: 317
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'by',
            startTimeInOriginal: 317,
            endTimeInOriginal: 317.2,
            startTimeInEdit: 317,
            endTimeInEdit: 317.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'just',
            startTimeInOriginal: 317.2,
            endTimeInOriginal: 317.4,
            startTimeInEdit: 317.2,
            endTimeInEdit: 317.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'clicking',
            startTimeInOriginal: 317.4,
            endTimeInOriginal: 317.6,
            startTimeInEdit: 317.4,
            endTimeInEdit: 317.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 317.6,
            endTimeInOriginal: 317.8,
            startTimeInEdit: 317.6,
            endTimeInEdit: 317.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 317.8,
            endTimeInOriginal: 317.9,
            startTimeInEdit: 317.8,
            endTimeInEdit: 317.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'once.',
            startTimeInOriginal: 317.9,
            endTimeInOriginal: 318.3,
            startTimeInEdit: 317.9,
            endTimeInEdit: 318.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'And',
            startTimeInOriginal: 318.3,
            endTimeInOriginal: 318.5,
            startTimeInEdit: 318.3,
            endTimeInEdit: 318.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'when',
            startTimeInOriginal: 318.5,
            endTimeInOriginal: 318.5,
            startTimeInEdit: 318.5,
            endTimeInEdit: 318.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 318.5,
            endTimeInOriginal: 318.6,
            startTimeInEdit: 318.5,
            endTimeInEdit: 318.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do',
            startTimeInOriginal: 318.6,
            endTimeInOriginal: 318.8,
            startTimeInEdit: 318.6,
            endTimeInEdit: 318.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that,',
            startTimeInOriginal: 318.8,
            endTimeInOriginal: 318.9,
            startTimeInEdit: 318.8,
            endTimeInEdit: 318.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 318.9,
            endTimeInOriginal: 319,
            startTimeInEdit: 318.9,
            endTimeInEdit: 319
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'menu',
            startTimeInOriginal: 319,
            endTimeInOriginal: 319.2,
            startTimeInEdit: 319,
            endTimeInEdit: 319.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 319.2,
            endTimeInOriginal: 319.4,
            startTimeInEdit: 319.2,
            endTimeInEdit: 319.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'pop',
            startTimeInOriginal: 319.4,
            endTimeInOriginal: 319.5,
            startTimeInEdit: 319.4,
            endTimeInEdit: 319.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'up',
            startTimeInOriginal: 319.5,
            endTimeInOriginal: 319.7,
            startTimeInEdit: 319.5,
            endTimeInEdit: 319.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'above',
            startTimeInOriginal: 319.7,
            endTimeInOriginal: 320,
            startTimeInEdit: 319.7,
            endTimeInEdit: 320
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 320,
            endTimeInOriginal: 320.1,
            startTimeInEdit: 320,
            endTimeInEdit: 320.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 320.1,
            endTimeInOriginal: 320.2,
            startTimeInEdit: 320.1,
            endTimeInEdit: 320.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'give',
            startTimeInOriginal: 320.2,
            endTimeInOriginal: 320.4,
            startTimeInEdit: 320.2,
            endTimeInEdit: 320.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 320.4,
            endTimeInOriginal: 320.7,
            startTimeInEdit: 320.4,
            endTimeInEdit: 320.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'access',
            startTimeInOriginal: 320.7,
            endTimeInOriginal: 321.1,
            startTimeInEdit: 320.7,
            endTimeInEdit: 321.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 321.1,
            endTimeInOriginal: 321.3,
            startTimeInEdit: 321.1,
            endTimeInEdit: 321.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'adjusting',
            startTimeInOriginal: 321.3,
            endTimeInOriginal: 321.8,
            startTimeInEdit: 321.3,
            endTimeInEdit: 321.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'all',
            startTimeInOriginal: 321.8,
            endTimeInOriginal: 321.9,
            startTimeInEdit: 321.8,
            endTimeInEdit: 321.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 321.9,
            endTimeInOriginal: 322,
            startTimeInEdit: 321.9,
            endTimeInEdit: 322
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'those',
            startTimeInOriginal: 322,
            endTimeInOriginal: 322.2,
            startTimeInEdit: 322,
            endTimeInEdit: 322.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'different',
            startTimeInOriginal: 322.2,
            endTimeInOriginal: 322.4,
            startTimeInEdit: 322.2,
            endTimeInEdit: 322.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'options.',
            startTimeInOriginal: 322.4,
            endTimeInOriginal: 323,
            startTimeInEdit: 322.4,
            endTimeInEdit: 323
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Now,',
            startTimeInOriginal: 323,
            endTimeInOriginal: 323.2,
            startTimeInEdit: 323,
            endTimeInEdit: 323.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'if',
            startTimeInOriginal: 323.2,
            endTimeInOriginal: 323.3,
            startTimeInEdit: 323.2,
            endTimeInEdit: 323.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 323.3,
            endTimeInOriginal: 323.3,
            startTimeInEdit: 323.3,
            endTimeInEdit: 323.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'went',
            startTimeInOriginal: 323.3,
            endTimeInOriginal: 323.5,
            startTimeInEdit: 323.3,
            endTimeInEdit: 323.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 323.5,
            endTimeInOriginal: 323.6,
            startTimeInEdit: 323.5,
            endTimeInEdit: 323.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'other',
            startTimeInOriginal: 323.6,
            endTimeInOriginal: 323.8,
            startTimeInEdit: 323.6,
            endTimeInEdit: 323.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'way',
            startTimeInOriginal: 323.8,
            endTimeInOriginal: 323.9,
            startTimeInEdit: 323.8,
            endTimeInEdit: 323.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 323.9,
            endTimeInOriginal: 324.1,
            startTimeInEdit: 323.9,
            endTimeInEdit: 324.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 324.1,
            endTimeInOriginal: 324.2,
            startTimeInEdit: 324.1,
            endTimeInEdit: 324.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'added',
            startTimeInOriginal: 324.2,
            endTimeInOriginal: 324.4,
            startTimeInEdit: 324.2,
            endTimeInEdit: 324.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'an',
            startTimeInOriginal: 324.4,
            endTimeInOriginal: 324.6,
            startTimeInEdit: 324.4,
            endTimeInEdit: 324.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'image',
            startTimeInOriginal: 324.6,
            endTimeInOriginal: 324.9,
            startTimeInEdit: 324.6,
            endTimeInEdit: 324.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'instead',
            startTimeInOriginal: 324.9,
            endTimeInOriginal: 325.2,
            startTimeInEdit: 324.9,
            endTimeInEdit: 325.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 325.2,
            endTimeInOriginal: 325.3,
            startTimeInEdit: 325.2,
            endTimeInEdit: 325.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 325.3,
            endTimeInOriginal: 325.4,
            startTimeInEdit: 325.3,
            endTimeInEdit: 325.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'text',
            startTimeInOriginal: 325.4,
            endTimeInOriginal: 325.7,
            startTimeInEdit: 325.4,
            endTimeInEdit: 325.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'block,',
            startTimeInOriginal: 325.7,
            endTimeInOriginal: 326.2,
            startTimeInEdit: 325.7,
            endTimeInEdit: 326.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 326.2,
            endTimeInOriginal: 326.3,
            startTimeInEdit: 326.2,
            endTimeInEdit: 326.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'first,',
            startTimeInOriginal: 326.3,
            endTimeInOriginal: 326.6,
            startTimeInEdit: 326.3,
            endTimeInEdit: 326.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'I',
            startTimeInOriginal: 326.6,
            endTimeInOriginal: 326.7,
            startTimeInEdit: 326.6,
            endTimeInEdit: 326.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'want',
            startTimeInOriginal: 326.7,
            endTimeInOriginal: 326.8,
            startTimeInEdit: 326.7,
            endTimeInEdit: 326.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 326.8,
            endTimeInOriginal: 326.9,
            startTimeInEdit: 326.8,
            endTimeInEdit: 326.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'congratulate',
            startTimeInOriginal: 326.9,
            endTimeInOriginal: 327.3,
            startTimeInEdit: 326.9,
            endTimeInEdit: 327.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 327.3,
            endTimeInOriginal: 327.40000000000003,
            startTimeInEdit: 327.3,
            endTimeInEdit: 327.40000000000003
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 327.4,
            endTimeInOriginal: 327.4,
            startTimeInEdit: 327.4,
            endTimeInEdit: 327.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 327.4,
            endTimeInOriginal: 327.6,
            startTimeInEdit: 327.4,
            endTimeInEdit: 327.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 327.6,
            endTimeInOriginal: 327.7,
            startTimeInEdit: 327.6,
            endTimeInEdit: 327.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 327.7,
            endTimeInOriginal: 327.8,
            startTimeInEdit: 327.7,
            endTimeInEdit: 327.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'tell',
            startTimeInOriginal: 327.8,
            endTimeInOriginal: 327.9,
            startTimeInEdit: 327.8,
            endTimeInEdit: 327.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 327.9,
            endTimeInOriginal: 328.1,
            startTimeInEdit: 327.9,
            endTimeInEdit: 328.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'how',
            startTimeInOriginal: 328.1,
            endTimeInOriginal: 328.4,
            startTimeInEdit: 328.1,
            endTimeInEdit: 328.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'proud',
            startTimeInOriginal: 328.4,
            endTimeInOriginal: 328.8,
            startTimeInEdit: 328.4,
            endTimeInEdit: 328.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'I',
            startTimeInOriginal: 328.8,
            endTimeInOriginal: 328.9,
            startTimeInEdit: 328.8,
            endTimeInEdit: 328.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'am',
            startTimeInOriginal: 328.9,
            endTimeInOriginal: 329.1,
            startTimeInEdit: 328.9,
            endTimeInEdit: 329.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 329.1,
            endTimeInOriginal: 329.3,
            startTimeInEdit: 329.1,
            endTimeInEdit: 329.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you.',
            startTimeInOriginal: 329.3,
            endTimeInOriginal: 329.6,
            startTimeInEdit: 329.3,
            endTimeInEdit: 329.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "You're",
            startTimeInOriginal: 329.6,
            endTimeInOriginal: 329.7,
            startTimeInEdit: 329.6,
            endTimeInEdit: 329.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'definitely',
            startTimeInOriginal: 329.7,
            endTimeInOriginal: 330.1,
            startTimeInEdit: 329.7,
            endTimeInEdit: 330.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'way',
            startTimeInOriginal: 330.1,
            endTimeInOriginal: 330.3,
            startTimeInEdit: 330.1,
            endTimeInEdit: 330.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'cooler',
            startTimeInOriginal: 330.3,
            endTimeInOriginal: 330.7,
            startTimeInEdit: 330.3,
            endTimeInEdit: 330.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'than',
            startTimeInOriginal: 330.7,
            endTimeInOriginal: 330.9,
            startTimeInEdit: 330.7,
            endTimeInEdit: 330.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 330.9,
            endTimeInOriginal: 331.1,
            startTimeInEdit: 330.9,
            endTimeInEdit: 331.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: '"I\'m',
            startTimeInOriginal: 331.1,
            endTimeInOriginal: 331.20000000000005,
            startTimeInEdit: 331.1,
            endTimeInEdit: 331.20000000000005
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'just',
            startTimeInOriginal: 331.2,
            endTimeInOriginal: 331.5,
            startTimeInEdit: 331.2,
            endTimeInEdit: 331.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'gonna',
            startTimeInOriginal: 331.5,
            endTimeInOriginal: 331.8,
            startTimeInEdit: 331.5,
            endTimeInEdit: 331.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'add',
            startTimeInOriginal: 331.8,
            endTimeInOriginal: 332.1,
            startTimeInEdit: 331.8,
            endTimeInEdit: 332.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'text blocks',
            startTimeInOriginal: 332.1,
            endTimeInOriginal: 332.9,
            startTimeInEdit: 332.1,
            endTimeInEdit: 332.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 332.9,
            endTimeInOriginal: 333,
            startTimeInEdit: 332.9,
            endTimeInEdit: 333
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'my',
            startTimeInOriginal: 333,
            endTimeInOriginal: 333.2,
            startTimeInEdit: 333,
            endTimeInEdit: 333.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video"',
            startTimeInOriginal: 333.2,
            endTimeInOriginal: 333.7,
            startTimeInEdit: 333.2,
            endTimeInEdit: 333.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'type',
            startTimeInOriginal: 333.7,
            endTimeInOriginal: 334,
            startTimeInEdit: 333.7,
            endTimeInEdit: 334
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 334,
            endTimeInOriginal: 334.1,
            startTimeInEdit: 334,
            endTimeInEdit: 334.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'people,',
            startTimeInOriginal: 334.1,
            endTimeInOriginal: 334.5,
            startTimeInEdit: 334.1,
            endTimeInEdit: 334.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'so',
            startTimeInOriginal: 334.5,
            endTimeInOriginal: 334.9,
            startTimeInEdit: 334.5,
            endTimeInEdit: 334.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 334.9,
            endTimeInOriginal: 335.15,
            startTimeInEdit: 334.9,
            endTimeInEdit: 335.15
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 335.15,
            endTimeInOriginal: 335.4,
            startTimeInEdit: 335.15,
            endTimeInEdit: 335.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 335.4,
            endTimeInOriginal: 335.5,
            startTimeInEdit: 335.4,
            endTimeInEdit: 335.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'congratulations',
            startTimeInOriginal: 335.5,
            endTimeInOriginal: 336.4,
            startTimeInEdit: 335.5,
            endTimeInEdit: 336.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 336.4,
            endTimeInOriginal: 336.5,
            startTimeInEdit: 336.4,
            endTimeInEdit: 336.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that.',
            startTimeInOriginal: 336.5,
            endTimeInOriginal: 337.1,
            startTimeInEdit: 336.5,
            endTimeInEdit: 337.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 337.1,
            endTimeInOriginal: 337.35,
            startTimeInEdit: 337.1,
            endTimeInEdit: 337.35
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 337.35,
            endTimeInOriginal: 337.40000000000003,
            startTimeInEdit: 337.35,
            endTimeInEdit: 337.40000000000003
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Anyways,',
            startTimeInOriginal: 337.4,
            endTimeInOriginal: 338.2,
            startTimeInEdit: 337.4,
            endTimeInEdit: 338.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 338.2,
            endTimeInOriginal: 338.45,
            startTimeInEdit: 338.2,
            endTimeInEdit: 338.45
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 338.45,
            endTimeInOriginal: 338.5,
            startTimeInEdit: 338.45,
            endTimeInEdit: 338.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'if',
            startTimeInOriginal: 338.5,
            endTimeInOriginal: 338.7,
            startTimeInEdit: 338.5,
            endTimeInEdit: 338.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 338.7,
            endTimeInOriginal: 338.9,
            startTimeInEdit: 338.7,
            endTimeInEdit: 338.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'choose',
            startTimeInOriginal: 338.9,
            endTimeInOriginal: 339.2,
            startTimeInEdit: 338.9,
            endTimeInEdit: 339.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 339.2,
            endTimeInOriginal: 339.3,
            startTimeInEdit: 339.2,
            endTimeInEdit: 339.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'image',
            startTimeInOriginal: 339.3,
            endTimeInOriginal: 339.7,
            startTimeInEdit: 339.3,
            endTimeInEdit: 339.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'icon,',
            startTimeInOriginal: 339.7,
            endTimeInOriginal: 340.3,
            startTimeInEdit: 339.7,
            endTimeInEdit: 340.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 340.3,
            endTimeInOriginal: 340.5,
            startTimeInEdit: 340.3,
            endTimeInEdit: 340.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'pop-up',
            startTimeInOriginal: 340.5,
            endTimeInOriginal: 340.8,
            startTimeInEdit: 340.5,
            endTimeInEdit: 340.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'menu',
            startTimeInOriginal: 340.8,
            endTimeInOriginal: 341.1,
            startTimeInEdit: 340.8,
            endTimeInEdit: 341.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 341.1,
            endTimeInOriginal: 341.3,
            startTimeInEdit: 341.1,
            endTimeInEdit: 341.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'upload',
            startTimeInOriginal: 341.3,
            endTimeInOriginal: 341.7,
            startTimeInEdit: 341.3,
            endTimeInEdit: 341.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 341.7,
            endTimeInOriginal: 341.9,
            startTimeInEdit: 341.7,
            endTimeInEdit: 341.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'image',
            startTimeInOriginal: 341.9,
            endTimeInOriginal: 342.3,
            startTimeInEdit: 341.9,
            endTimeInEdit: 342.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'into',
            startTimeInOriginal: 342.3,
            endTimeInOriginal: 342.8,
            startTimeInEdit: 342.3,
            endTimeInEdit: 342.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 342.8,
            endTimeInOriginal: 342.9,
            startTimeInEdit: 342.8,
            endTimeInEdit: 342.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'platform',
            startTimeInOriginal: 342.9,
            endTimeInOriginal: 343.4,
            startTimeInEdit: 342.9,
            endTimeInEdit: 343.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 343.4,
            endTimeInOriginal: 343.6,
            startTimeInEdit: 343.4,
            endTimeInEdit: 343.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'appear.',
            startTimeInOriginal: 343.6,
            endTimeInOriginal: 344,
            startTimeInEdit: 343.6,
            endTimeInEdit: 344
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'You',
            startTimeInOriginal: 344,
            endTimeInOriginal: 344.1,
            startTimeInEdit: 344,
            endTimeInEdit: 344.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 344.1,
            endTimeInOriginal: 344.2,
            startTimeInEdit: 344.1,
            endTimeInEdit: 344.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'drag',
            startTimeInOriginal: 344.2,
            endTimeInOriginal: 344.4,
            startTimeInEdit: 344.2,
            endTimeInEdit: 344.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 344.4,
            endTimeInOriginal: 344.5,
            startTimeInEdit: 344.4,
            endTimeInEdit: 344.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'drop',
            startTimeInOriginal: 344.5,
            endTimeInOriginal: 344.6,
            startTimeInEdit: 344.5,
            endTimeInEdit: 344.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 344.6,
            endTimeInOriginal: 344.7,
            startTimeInEdit: 344.6,
            endTimeInEdit: 344.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'image',
            startTimeInOriginal: 344.7,
            endTimeInOriginal: 345,
            startTimeInEdit: 344.7,
            endTimeInEdit: 345
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'file',
            startTimeInOriginal: 345,
            endTimeInOriginal: 345.3,
            startTimeInEdit: 345,
            endTimeInEdit: 345.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'right',
            startTimeInOriginal: 345.3,
            endTimeInOriginal: 345.6,
            startTimeInEdit: 345.3,
            endTimeInEdit: 345.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'into',
            startTimeInOriginal: 345.6,
            endTimeInOriginal: 345.8,
            startTimeInEdit: 345.6,
            endTimeInEdit: 345.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 345.8,
            endTimeInOriginal: 346.1,
            startTimeInEdit: 345.8,
            endTimeInEdit: 346.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 346.1,
            endTimeInOriginal: 346.2,
            startTimeInEdit: 346.1,
            endTimeInEdit: 346.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 346.2,
            endTimeInOriginal: 346.4,
            startTimeInEdit: 346.2,
            endTimeInEdit: 346.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 346.4,
            endTimeInOriginal: 346.5,
            startTimeInEdit: 346.4,
            endTimeInEdit: 346.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'upload',
            startTimeInOriginal: 346.5,
            endTimeInOriginal: 346.9,
            startTimeInEdit: 346.5,
            endTimeInEdit: 346.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'into',
            startTimeInOriginal: 346.9,
            endTimeInOriginal: 347.2,
            startTimeInEdit: 346.9,
            endTimeInEdit: 347.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 347.2,
            endTimeInOriginal: 347.3,
            startTimeInEdit: 347.2,
            endTimeInEdit: 347.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'project.',
            startTimeInOriginal: 347.3,
            endTimeInOriginal: 347.9,
            startTimeInEdit: 347.3,
            endTimeInEdit: 347.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Once',
            startTimeInOriginal: 347.9,
            endTimeInOriginal: 348.1,
            startTimeInEdit: 347.9,
            endTimeInEdit: 348.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'uploaded,',
            startTimeInOriginal: 348.1,
            endTimeInOriginal: 348.5,
            startTimeInEdit: 348.1,
            endTimeInEdit: 348.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 348.5,
            endTimeInOriginal: 348.6,
            startTimeInEdit: 348.5,
            endTimeInEdit: 348.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 348.6,
            endTimeInOriginal: 348.7,
            startTimeInEdit: 348.6,
            endTimeInEdit: 348.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'adjust',
            startTimeInOriginal: 348.7,
            endTimeInOriginal: 349,
            startTimeInEdit: 348.7,
            endTimeInEdit: 349
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 349,
            endTimeInOriginal: 349.1,
            startTimeInEdit: 349,
            endTimeInEdit: 349.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'size',
            startTimeInOriginal: 349.1,
            endTimeInOriginal: 349.4,
            startTimeInEdit: 349.1,
            endTimeInEdit: 349.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 349.4,
            endTimeInOriginal: 349.5,
            startTimeInEdit: 349.4,
            endTimeInEdit: 349.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'position',
            startTimeInOriginal: 349.5,
            endTimeInOriginal: 349.9,
            startTimeInEdit: 349.5,
            endTimeInEdit: 349.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 349.9,
            endTimeInOriginal: 350,
            startTimeInEdit: 349.9,
            endTimeInEdit: 350
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'this',
            startTimeInOriginal: 350,
            endTimeInOriginal: 350.2,
            startTimeInEdit: 350,
            endTimeInEdit: 350.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'image',
            startTimeInOriginal: 350.2,
            endTimeInOriginal: 350.6,
            startTimeInEdit: 350.2,
            endTimeInEdit: 350.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 350.6,
            endTimeInOriginal: 350.8,
            startTimeInEdit: 350.6,
            endTimeInEdit: 350.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 350.8,
            endTimeInOriginal: 350.9,
            startTimeInEdit: 350.8,
            endTimeInEdit: 350.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'screen.',
            startTimeInOriginal: 350.9,
            endTimeInOriginal: 351.3,
            startTimeInEdit: 350.9,
            endTimeInEdit: 351.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Now',
            startTimeInOriginal: 351.3,
            endTimeInOriginal: 351.5,
            startTimeInEdit: 351.3,
            endTimeInEdit: 351.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'real',
            startTimeInOriginal: 351.5,
            endTimeInOriginal: 351.6,
            startTimeInEdit: 351.5,
            endTimeInEdit: 351.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'quick',
            startTimeInOriginal: 351.6,
            endTimeInOriginal: 351.8,
            startTimeInEdit: 351.6,
            endTimeInEdit: 351.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'as',
            startTimeInOriginal: 351.8,
            endTimeInOriginal: 351.9,
            startTimeInEdit: 351.8,
            endTimeInEdit: 351.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'a',
            startTimeInOriginal: 351.9,
            endTimeInOriginal: 351.9,
            startTimeInEdit: 351.9,
            endTimeInEdit: 351.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'final',
            startTimeInOriginal: 351.9,
            endTimeInOriginal: 352.3,
            startTimeInEdit: 351.9,
            endTimeInEdit: 352.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'note',
            startTimeInOriginal: 352.3,
            endTimeInOriginal: 352.6,
            startTimeInEdit: 352.3,
            endTimeInEdit: 352.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'for',
            startTimeInOriginal: 352.6,
            endTimeInOriginal: 352.7,
            startTimeInEdit: 352.6,
            endTimeInEdit: 352.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 352.7,
            endTimeInOriginal: 353,
            startTimeInEdit: 352.7,
            endTimeInEdit: 353
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'text',
            startTimeInOriginal: 353,
            endTimeInOriginal: 353.3,
            startTimeInEdit: 353,
            endTimeInEdit: 353.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'box',
            startTimeInOriginal: 353.3,
            endTimeInOriginal: 353.8,
            startTimeInEdit: 353.3,
            endTimeInEdit: 353.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 353.8,
            endTimeInOriginal: 354,
            startTimeInEdit: 353.8,
            endTimeInEdit: 354
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'image',
            startTimeInOriginal: 354,
            endTimeInOriginal: 354.5,
            startTimeInEdit: 354,
            endTimeInEdit: 354.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'adders,',
            startTimeInOriginal: 354.5,
            endTimeInOriginal: 355,
            startTimeInEdit: 354.5,
            endTimeInEdit: 355
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 355,
            endTimeInOriginal: 355.1,
            startTimeInEdit: 355,
            endTimeInEdit: 355.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'determine',
            startTimeInOriginal: 355.1,
            endTimeInOriginal: 355.5,
            startTimeInEdit: 355.1,
            endTimeInEdit: 355.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 355.5,
            endTimeInOriginal: 355.5,
            startTimeInEdit: 355.5,
            endTimeInEdit: 355.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'length',
            startTimeInOriginal: 355.5,
            endTimeInOriginal: 355.8,
            startTimeInEdit: 355.5,
            endTimeInEdit: 355.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 355.8,
            endTimeInOriginal: 356,
            startTimeInEdit: 355.8,
            endTimeInEdit: 356
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'time',
            startTimeInOriginal: 356,
            endTimeInOriginal: 356.3,
            startTimeInEdit: 356,
            endTimeInEdit: 356.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 356.3,
            endTimeInOriginal: 356.5,
            startTimeInEdit: 356.3,
            endTimeInEdit: 356.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 356.5,
            endTimeInOriginal: 356.6,
            startTimeInEdit: 356.5,
            endTimeInEdit: 356.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'text',
            startTimeInOriginal: 356.6,
            endTimeInOriginal: 356.9,
            startTimeInEdit: 356.6,
            endTimeInEdit: 356.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'block',
            startTimeInOriginal: 356.9,
            endTimeInOriginal: 357.1,
            startTimeInEdit: 356.9,
            endTimeInEdit: 357.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 357.1,
            endTimeInOriginal: 357.2,
            startTimeInEdit: 357.1,
            endTimeInEdit: 357.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 357.2,
            endTimeInOriginal: 357.4,
            startTimeInEdit: 357.2,
            endTimeInEdit: 357.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'image',
            startTimeInOriginal: 357.4,
            endTimeInOriginal: 357.7,
            startTimeInEdit: 357.4,
            endTimeInEdit: 357.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'stays',
            startTimeInOriginal: 357.7,
            endTimeInOriginal: 357.9,
            startTimeInEdit: 357.7,
            endTimeInEdit: 357.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 357.9,
            endTimeInOriginal: 358.1,
            startTimeInEdit: 357.9,
            endTimeInEdit: 358.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'screen,',
            startTimeInOriginal: 358.1,
            endTimeInOriginal: 358.6,
            startTimeInEdit: 358.1,
            endTimeInEdit: 358.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 358.6,
            endTimeInOriginal: 358.70000000000005,
            startTimeInEdit: 358.6,
            endTimeInEdit: 358.70000000000005
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "we're",
            startTimeInOriginal: 358.7,
            endTimeInOriginal: 358.7,
            startTimeInEdit: 358.7,
            endTimeInEdit: 358.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'going',
            startTimeInOriginal: 358.7,
            endTimeInOriginal: 358.9,
            startTimeInEdit: 358.7,
            endTimeInEdit: 358.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 358.9,
            endTimeInOriginal: 358.9,
            startTimeInEdit: 358.9,
            endTimeInEdit: 358.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'be',
            startTimeInOriginal: 358.9,
            endTimeInOriginal: 359,
            startTimeInEdit: 358.9,
            endTimeInEdit: 359
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'looking',
            startTimeInOriginal: 359,
            endTimeInOriginal: 359.2,
            startTimeInEdit: 359,
            endTimeInEdit: 359.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'at',
            startTimeInOriginal: 359.2,
            endTimeInOriginal: 359.4,
            startTimeInEdit: 359.2,
            endTimeInEdit: 359.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 359.4,
            endTimeInOriginal: 359.5,
            startTimeInEdit: 359.4,
            endTimeInEdit: 359.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'little',
            startTimeInOriginal: 359.5,
            endTimeInOriginal: 359.9,
            startTimeInEdit: 359.5,
            endTimeInEdit: 359.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'icon',
            startTimeInOriginal: 359.9,
            endTimeInOriginal: 360.5,
            startTimeInEdit: 359.9,
            endTimeInEdit: 360.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 360.5,
            endTimeInOriginal: 360.9,
            startTimeInEdit: 360.5,
            endTimeInEdit: 360.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'column',
            startTimeInOriginal: 360.9,
            endTimeInOriginal: 361.3,
            startTimeInEdit: 360.9,
            endTimeInEdit: 361.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 361.3,
            endTimeInOriginal: 361.5,
            startTimeInEdit: 361.3,
            endTimeInEdit: 361.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 361.5,
            endTimeInOriginal: 361.6,
            startTimeInEdit: 361.5,
            endTimeInEdit: 361.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'be',
            startTimeInOriginal: 361.6,
            endTimeInOriginal: 361.7,
            startTimeInEdit: 361.6,
            endTimeInEdit: 361.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'found',
            startTimeInOriginal: 361.7,
            endTimeInOriginal: 362.3,
            startTimeInEdit: 361.7,
            endTimeInEdit: 362.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 362.3,
            endTimeInOriginal: 362.55,
            startTimeInEdit: 362.3,
            endTimeInEdit: 362.55
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 362.55,
            endTimeInOriginal: 362.6,
            startTimeInEdit: 362.55,
            endTimeInEdit: 362.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'over',
            startTimeInOriginal: 362.6,
            endTimeInOriginal: 362.9,
            startTimeInEdit: 362.6,
            endTimeInEdit: 362.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 362.9,
            endTimeInOriginal: 363.1,
            startTimeInEdit: 362.9,
            endTimeInEdit: 363.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 363.1,
            endTimeInOriginal: 363.2,
            startTimeInEdit: 363.1,
            endTimeInEdit: 363.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'transcription',
            startTimeInOriginal: 363.2,
            endTimeInOriginal: 363.8,
            startTimeInEdit: 363.2,
            endTimeInEdit: 363.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'itself.',
            startTimeInOriginal: 363.8,
            endTimeInOriginal: 364.3,
            startTimeInEdit: 363.8,
            endTimeInEdit: 364.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'This',
            startTimeInOriginal: 364.3,
            endTimeInOriginal: 364.6,
            startTimeInEdit: 364.3,
            endTimeInEdit: 364.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'icon',
            startTimeInOriginal: 364.6,
            endTimeInOriginal: 365.1,
            startTimeInEdit: 364.6,
            endTimeInEdit: 365.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 365.1,
            endTimeInOriginal: 365.4,
            startTimeInEdit: 365.1,
            endTimeInEdit: 365.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'column',
            startTimeInOriginal: 365.4,
            endTimeInOriginal: 365.7,
            startTimeInEdit: 365.4,
            endTimeInEdit: 365.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 365.7,
            endTimeInOriginal: 365.8,
            startTimeInEdit: 365.7,
            endTimeInEdit: 365.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'determine',
            startTimeInOriginal: 365.8,
            endTimeInOriginal: 366.3,
            startTimeInEdit: 365.8,
            endTimeInEdit: 366.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 366.3,
            endTimeInOriginal: 366.5,
            startTimeInEdit: 366.3,
            endTimeInEdit: 366.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'beginning',
            startTimeInOriginal: 366.5,
            endTimeInOriginal: 367.1,
            startTimeInEdit: 366.5,
            endTimeInEdit: 367.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 367.1,
            endTimeInOriginal: 367.35,
            startTimeInEdit: 367.1,
            endTimeInEdit: 367.35
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 367.35,
            endTimeInOriginal: 367.40000000000003,
            startTimeInEdit: 367.35,
            endTimeInEdit: 367.40000000000003
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 367.4,
            endTimeInOriginal: 367.7,
            startTimeInEdit: 367.4,
            endTimeInEdit: 367.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'end',
            startTimeInOriginal: 367.7,
            endTimeInOriginal: 368,
            startTimeInEdit: 367.7,
            endTimeInEdit: 368
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 368,
            endTimeInOriginal: 368.2,
            startTimeInEdit: 368,
            endTimeInEdit: 368.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'when',
            startTimeInOriginal: 368.2,
            endTimeInOriginal: 368.6,
            startTimeInEdit: 368.2,
            endTimeInEdit: 368.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 368.6,
            endTimeInOriginal: 368.7,
            startTimeInEdit: 368.6,
            endTimeInEdit: 368.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'text',
            startTimeInOriginal: 368.7,
            endTimeInOriginal: 369,
            startTimeInEdit: 368.7,
            endTimeInEdit: 369
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'block',
            startTimeInOriginal: 369,
            endTimeInOriginal: 369.4,
            startTimeInEdit: 369,
            endTimeInEdit: 369.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'or',
            startTimeInOriginal: 369.4,
            endTimeInOriginal: 369.7,
            startTimeInEdit: 369.4,
            endTimeInEdit: 369.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'image',
            startTimeInOriginal: 369.7,
            endTimeInOriginal: 370.3,
            startTimeInEdit: 369.7,
            endTimeInEdit: 370.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 370.3,
            endTimeInOriginal: 370.40000000000003,
            startTimeInEdit: 370.3,
            endTimeInEdit: 370.40000000000003
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 370.4,
            endTimeInOriginal: 370.5,
            startTimeInEdit: 370.4,
            endTimeInEdit: 370.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'be',
            startTimeInOriginal: 370.5,
            endTimeInOriginal: 370.7,
            startTimeInEdit: 370.5,
            endTimeInEdit: 370.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 370.7,
            endTimeInOriginal: 370.8,
            startTimeInEdit: 370.7,
            endTimeInEdit: 370.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'screen',
            startTimeInOriginal: 370.8,
            endTimeInOriginal: 371.1,
            startTimeInEdit: 370.8,
            endTimeInEdit: 371.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'for.',
            startTimeInOriginal: 371.1,
            endTimeInOriginal: 371.5,
            startTimeInEdit: 371.1,
            endTimeInEdit: 371.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 371.5,
            endTimeInOriginal: 371.6,
            startTimeInEdit: 371.5,
            endTimeInEdit: 371.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'This',
            startTimeInOriginal: 371.6,
            endTimeInOriginal: 371.8,
            startTimeInEdit: 371.6,
            endTimeInEdit: 371.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 371.8,
            endTimeInOriginal: 371.9,
            startTimeInEdit: 371.8,
            endTimeInEdit: 371.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'be',
            startTimeInOriginal: 371.9,
            endTimeInOriginal: 372,
            startTimeInEdit: 371.9,
            endTimeInEdit: 372
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'adjusted',
            startTimeInOriginal: 372,
            endTimeInOriginal: 372.6,
            startTimeInEdit: 372,
            endTimeInEdit: 372.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'if',
            startTimeInOriginal: 372.6,
            endTimeInOriginal: 372.7,
            startTimeInEdit: 372.6,
            endTimeInEdit: 372.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 372.7,
            endTimeInOriginal: 372.8,
            startTimeInEdit: 372.7,
            endTimeInEdit: 372.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'want',
            startTimeInOriginal: 372.8,
            endTimeInOriginal: 372.9,
            startTimeInEdit: 372.8,
            endTimeInEdit: 372.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 372.9,
            endTimeInOriginal: 373,
            startTimeInEdit: 372.9,
            endTimeInEdit: 373
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'just',
            startTimeInOriginal: 373,
            endTimeInOriginal: 373.1,
            startTimeInEdit: 373,
            endTimeInEdit: 373.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'click',
            startTimeInOriginal: 373.1,
            endTimeInOriginal: 373.3,
            startTimeInEdit: 373.1,
            endTimeInEdit: 373.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 373.3,
            endTimeInOriginal: 373.4,
            startTimeInEdit: 373.3,
            endTimeInEdit: 373.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'drag',
            startTimeInOriginal: 373.4,
            endTimeInOriginal: 373.7,
            startTimeInEdit: 373.4,
            endTimeInEdit: 373.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'them',
            startTimeInOriginal: 373.7,
            endTimeInOriginal: 373.8,
            startTimeInEdit: 373.7,
            endTimeInEdit: 373.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 373.8,
            endTimeInOriginal: 373.9,
            startTimeInEdit: 373.8,
            endTimeInEdit: 373.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'whatever',
            startTimeInOriginal: 373.9,
            endTimeInOriginal: 374.2,
            startTimeInEdit: 373.9,
            endTimeInEdit: 374.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'word,',
            startTimeInOriginal: 374.2,
            endTimeInOriginal: 374.6,
            startTimeInEdit: 374.2,
            endTimeInEdit: 374.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'our',
            startTimeInOriginal: 374.6,
            endTimeInOriginal: 374.8,
            startTimeInEdit: 374.6,
            endTimeInEdit: 374.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'area',
            startTimeInOriginal: 374.8,
            endTimeInOriginal: 375.2,
            startTimeInEdit: 374.8,
            endTimeInEdit: 375.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 375.2,
            endTimeInOriginal: 375.4,
            startTimeInEdit: 375.2,
            endTimeInEdit: 375.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 375.4,
            endTimeInOriginal: 375.5,
            startTimeInEdit: 375.4,
            endTimeInEdit: 375.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'transcription',
            startTimeInOriginal: 375.5,
            endTimeInOriginal: 376.2,
            startTimeInEdit: 375.5,
            endTimeInEdit: 376.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 376.2,
            endTimeInOriginal: 376.3,
            startTimeInEdit: 376.2,
            endTimeInEdit: 376.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 376.3,
            endTimeInOriginal: 376.4,
            startTimeInEdit: 376.3,
            endTimeInEdit: 376.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 376.4,
            endTimeInOriginal: 376.5,
            startTimeInEdit: 376.4,
            endTimeInEdit: 376.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'want',
            startTimeInOriginal: 376.5,
            endTimeInOriginal: 376.6,
            startTimeInEdit: 376.5,
            endTimeInEdit: 376.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'them',
            startTimeInOriginal: 376.6,
            endTimeInOriginal: 376.7,
            startTimeInEdit: 376.6,
            endTimeInEdit: 376.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 376.7,
            endTimeInOriginal: 376.8,
            startTimeInEdit: 376.7,
            endTimeInEdit: 376.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'start',
            startTimeInOriginal: 376.8,
            endTimeInOriginal: 377.2,
            startTimeInEdit: 376.8,
            endTimeInEdit: 377.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 377.2,
            endTimeInOriginal: 377.4,
            startTimeInEdit: 377.2,
            endTimeInEdit: 377.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 377.4,
            endTimeInOriginal: 377.5,
            startTimeInEdit: 377.4,
            endTimeInEdit: 377.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'end.',
            startTimeInOriginal: 377.5,
            endTimeInOriginal: 377.9,
            startTimeInEdit: 377.5,
            endTimeInEdit: 377.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'You',
            startTimeInOriginal: 377.9,
            endTimeInOriginal: 378,
            startTimeInEdit: 377.9,
            endTimeInEdit: 378
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'can',
            startTimeInOriginal: 378,
            endTimeInOriginal: 378.1,
            startTimeInEdit: 378,
            endTimeInEdit: 378.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do',
            startTimeInOriginal: 378.1,
            endTimeInOriginal: 378.3,
            startTimeInEdit: 378.1,
            endTimeInEdit: 378.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 378.3,
            endTimeInOriginal: 378.5,
            startTimeInEdit: 378.3,
            endTimeInEdit: 378.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'at',
            startTimeInOriginal: 378.5,
            endTimeInOriginal: 378.6,
            startTimeInEdit: 378.5,
            endTimeInEdit: 378.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'any',
            startTimeInOriginal: 378.6,
            endTimeInOriginal: 378.8,
            startTimeInEdit: 378.6,
            endTimeInEdit: 378.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'time.',
            startTimeInOriginal: 378.8,
            endTimeInOriginal: 379.3,
            startTimeInEdit: 378.8,
            endTimeInEdit: 379.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 379.3,
            endTimeInOriginal: 379.40000000000003,
            startTimeInEdit: 379.3,
            endTimeInEdit: 379.40000000000003
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'So',
            startTimeInOriginal: 379.4,
            endTimeInOriginal: 379.5,
            startTimeInEdit: 379.4,
            endTimeInEdit: 379.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'once',
            startTimeInOriginal: 379.5,
            endTimeInOriginal: 379.6,
            startTimeInEdit: 379.5,
            endTimeInEdit: 379.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'again',
            startTimeInOriginal: 379.6,
            endTimeInOriginal: 379.8,
            startTimeInEdit: 379.6,
            endTimeInEdit: 379.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 379.8,
            endTimeInOriginal: 380,
            startTimeInEdit: 379.8,
            endTimeInEdit: 380
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'icon',
            startTimeInOriginal: 380,
            endTimeInOriginal: 380.3,
            startTimeInEdit: 380,
            endTimeInEdit: 380.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'itself',
            startTimeInOriginal: 380.3,
            endTimeInOriginal: 380.7,
            startTimeInEdit: 380.3,
            endTimeInEdit: 380.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'indicates',
            startTimeInOriginal: 380.7,
            endTimeInOriginal: 381.2,
            startTimeInEdit: 380.7,
            endTimeInEdit: 381.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'when',
            startTimeInOriginal: 381.2,
            endTimeInOriginal: 381.3,
            startTimeInEdit: 381.2,
            endTimeInEdit: 381.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 381.3,
            endTimeInOriginal: 381.5,
            startTimeInEdit: 381.3,
            endTimeInEdit: 381.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 381.5,
            endTimeInOriginal: 381.6,
            startTimeInEdit: 381.5,
            endTimeInEdit: 381.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'show',
            startTimeInOriginal: 381.6,
            endTimeInOriginal: 381.8,
            startTimeInEdit: 381.6,
            endTimeInEdit: 381.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'up',
            startTimeInOriginal: 381.8,
            endTimeInOriginal: 382,
            startTimeInEdit: 381.8,
            endTimeInEdit: 382
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 382,
            endTimeInOriginal: 382.1,
            startTimeInEdit: 382,
            endTimeInEdit: 382.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'screen',
            startTimeInOriginal: 382.1,
            endTimeInOriginal: 382.7,
            startTimeInEdit: 382.1,
            endTimeInEdit: 382.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 382.7,
            endTimeInOriginal: 382.8,
            startTimeInEdit: 382.7,
            endTimeInEdit: 382.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 382.8,
            endTimeInOriginal: 383,
            startTimeInEdit: 382.8,
            endTimeInEdit: 383
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'be',
            startTimeInOriginal: 383,
            endTimeInOriginal: 383.1,
            startTimeInEdit: 383,
            endTimeInEdit: 383.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'visible,',
            startTimeInOriginal: 383.1,
            endTimeInOriginal: 383.7,
            startTimeInEdit: 383.1,
            endTimeInEdit: 383.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 383.7,
            endTimeInOriginal: 383.9,
            startTimeInEdit: 383.7,
            endTimeInEdit: 383.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'then',
            startTimeInOriginal: 383.9,
            endTimeInOriginal: 384.3,
            startTimeInEdit: 383.9,
            endTimeInEdit: 384.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 384.3,
            endTimeInOriginal: 384.40000000000003,
            startTimeInEdit: 384.3,
            endTimeInEdit: 384.40000000000003
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 384.4,
            endTimeInOriginal: 384.5,
            startTimeInEdit: 384.4,
            endTimeInEdit: 384.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'column',
            startTimeInOriginal: 384.5,
            endTimeInOriginal: 384.9,
            startTimeInEdit: 384.5,
            endTimeInEdit: 384.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'indicates',
            startTimeInOriginal: 384.9,
            endTimeInOriginal: 385.4,
            startTimeInEdit: 384.9,
            endTimeInEdit: 385.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'when',
            startTimeInOriginal: 385.4,
            endTimeInOriginal: 385.7,
            startTimeInEdit: 385.4,
            endTimeInEdit: 385.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 385.7,
            endTimeInOriginal: 385.9,
            startTimeInEdit: 385.7,
            endTimeInEdit: 385.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 385.9,
            endTimeInOriginal: 386.2,
            startTimeInEdit: 385.9,
            endTimeInEdit: 386.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'end',
            startTimeInOriginal: 386.2,
            endTimeInOriginal: 386.6,
            startTimeInEdit: 386.2,
            endTimeInEdit: 386.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'being',
            startTimeInOriginal: 386.6,
            endTimeInOriginal: 386.9,
            startTimeInEdit: 386.6,
            endTimeInEdit: 386.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'on',
            startTimeInOriginal: 386.9,
            endTimeInOriginal: 387,
            startTimeInEdit: 386.9,
            endTimeInEdit: 387
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'screen.',
            startTimeInOriginal: 387,
            endTimeInOriginal: 387.2,
            startTimeInEdit: 387,
            endTimeInEdit: 387.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 387.2,
            endTimeInOriginal: 387.4,
            startTimeInEdit: 387.2,
            endTimeInEdit: 387.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 387.4,
            endTimeInOriginal: 387.5,
            startTimeInEdit: 387.4,
            endTimeInEdit: 387.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'It',
            startTimeInOriginal: 387.5,
            endTimeInOriginal: 387.6,
            startTimeInEdit: 387.5,
            endTimeInEdit: 387.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'will',
            startTimeInOriginal: 387.6,
            endTimeInOriginal: 387.6,
            startTimeInEdit: 387.6,
            endTimeInEdit: 387.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'no',
            startTimeInOriginal: 387.6,
            endTimeInOriginal: 387.8,
            startTimeInEdit: 387.6,
            endTimeInEdit: 387.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'longer',
            startTimeInOriginal: 387.8,
            endTimeInOriginal: 388.1,
            startTimeInEdit: 387.8,
            endTimeInEdit: 388.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'be',
            startTimeInOriginal: 388.1,
            endTimeInOriginal: 388.2,
            startTimeInEdit: 388.1,
            endTimeInEdit: 388.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'visible',
            startTimeInOriginal: 388.2,
            endTimeInOriginal: 388.6,
            startTimeInEdit: 388.2,
            endTimeInEdit: 388.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'after',
            startTimeInOriginal: 388.6,
            endTimeInOriginal: 388.9,
            startTimeInEdit: 388.6,
            endTimeInEdit: 388.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 388.9,
            endTimeInOriginal: 389.1,
            startTimeInEdit: 388.9,
            endTimeInEdit: 389.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'point.',
            startTimeInOriginal: 389.1,
            endTimeInOriginal: 389.5,
            startTimeInEdit: 389.1,
            endTimeInEdit: 389.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'So,',
            startTimeInOriginal: 389.5,
            endTimeInOriginal: 389.6,
            startTimeInEdit: 389.5,
            endTimeInEdit: 389.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'go',
            startTimeInOriginal: 389.6,
            endTimeInOriginal: 389.7,
            startTimeInEdit: 389.6,
            endTimeInEdit: 389.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'ahead',
            startTimeInOriginal: 389.7,
            endTimeInOriginal: 389.9,
            startTimeInEdit: 389.7,
            endTimeInEdit: 389.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 389.9,
            endTimeInOriginal: 390,
            startTimeInEdit: 389.9,
            endTimeInEdit: 390
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'drag',
            startTimeInOriginal: 390,
            endTimeInOriginal: 390.2,
            startTimeInEdit: 390,
            endTimeInEdit: 390.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 390.2,
            endTimeInOriginal: 390.3,
            startTimeInEdit: 390.2,
            endTimeInEdit: 390.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'drop',
            startTimeInOriginal: 390.3,
            endTimeInOriginal: 390.5,
            startTimeInEdit: 390.3,
            endTimeInEdit: 390.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 390.5,
            endTimeInOriginal: 390.8,
            startTimeInEdit: 390.5,
            endTimeInEdit: 390.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'icon',
            startTimeInOriginal: 390.8,
            endTimeInOriginal: 391.2,
            startTimeInEdit: 390.8,
            endTimeInEdit: 391.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 391.2,
            endTimeInOriginal: 391.4,
            startTimeInEdit: 391.2,
            endTimeInEdit: 391.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'column',
            startTimeInOriginal: 391.4,
            endTimeInOriginal: 391.8,
            startTimeInEdit: 391.4,
            endTimeInEdit: 391.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 391.8,
            endTimeInOriginal: 391.9,
            startTimeInEdit: 391.8,
            endTimeInEdit: 391.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 391.9,
            endTimeInOriginal: 392,
            startTimeInEdit: 391.9,
            endTimeInEdit: 392
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'places',
            startTimeInOriginal: 392,
            endTimeInOriginal: 392.4,
            startTimeInEdit: 392,
            endTimeInEdit: 392.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'in',
            startTimeInOriginal: 392.4,
            endTimeInOriginal: 392.5,
            startTimeInEdit: 392.4,
            endTimeInEdit: 392.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'which',
            startTimeInOriginal: 392.5,
            endTimeInOriginal: 392.7,
            startTimeInEdit: 392.5,
            endTimeInEdit: 392.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'you',
            startTimeInOriginal: 392.7,
            endTimeInOriginal: 392.8,
            startTimeInEdit: 392.7,
            endTimeInEdit: 392.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'want',
            startTimeInOriginal: 392.8,
            endTimeInOriginal: 392.9,
            startTimeInEdit: 392.8,
            endTimeInEdit: 392.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'your',
            startTimeInOriginal: 392.9,
            endTimeInOriginal: 393.1,
            startTimeInEdit: 392.9,
            endTimeInEdit: 393.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'element',
            startTimeInOriginal: 393.1,
            endTimeInOriginal: 393.5,
            startTimeInEdit: 393.1,
            endTimeInEdit: 393.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 393.5,
            endTimeInOriginal: 393.6,
            startTimeInEdit: 393.5,
            endTimeInEdit: 393.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'be',
            startTimeInOriginal: 393.6,
            endTimeInOriginal: 393.7,
            startTimeInEdit: 393.6,
            endTimeInEdit: 393.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'visible.',
            startTimeInOriginal: 393.7,
            endTimeInOriginal: 394.3,
            startTimeInEdit: 393.7,
            endTimeInEdit: 394.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'For',
            startTimeInOriginal: 394.3,
            endTimeInOriginal: 394.4,
            startTimeInEdit: 394.3,
            endTimeInEdit: 394.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'instance,',
            startTimeInOriginal: 394.4,
            endTimeInOriginal: 394.7,
            startTimeInEdit: 394.4,
            endTimeInEdit: 394.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'if',
            startTimeInOriginal: 394.7,
            endTimeInOriginal: 394.9,
            startTimeInEdit: 394.7,
            endTimeInEdit: 394.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'I',
            startTimeInOriginal: 394.9,
            endTimeInOriginal: 394.9,
            startTimeInEdit: 394.9,
            endTimeInEdit: 394.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'wanted',
            startTimeInOriginal: 394.9,
            endTimeInOriginal: 395.2,
            startTimeInEdit: 394.9,
            endTimeInEdit: 395.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'my',
            startTimeInOriginal: 395.2,
            endTimeInOriginal: 395.3,
            startTimeInEdit: 395.2,
            endTimeInEdit: 395.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'logo,',
            startTimeInOriginal: 395.3,
            endTimeInOriginal: 395.7,
            startTimeInEdit: 395.3,
            endTimeInEdit: 395.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 395.7,
            endTimeInOriginal: 395.8,
            startTimeInEdit: 395.7,
            endTimeInEdit: 395.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Type',
            startTimeInOriginal: 395.8,
            endTimeInOriginal: 396.1,
            startTimeInEdit: 395.8,
            endTimeInEdit: 396.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Studio',
            startTimeInOriginal: 396.1,
            endTimeInOriginal: 396.3,
            startTimeInEdit: 396.1,
            endTimeInEdit: 396.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'logo',
            startTimeInOriginal: 396.3,
            endTimeInOriginal: 396.6,
            startTimeInEdit: 396.3,
            endTimeInEdit: 396.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 396.6,
            endTimeInOriginal: 396.6,
            startTimeInEdit: 396.6,
            endTimeInEdit: 396.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'stay',
            startTimeInOriginal: 396.6,
            endTimeInOriginal: 396.8,
            startTimeInEdit: 396.6,
            endTimeInEdit: 396.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'here,',
            startTimeInOriginal: 396.8,
            endTimeInOriginal: 397,
            startTimeInEdit: 396.8,
            endTimeInEdit: 397
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 397,
            endTimeInOriginal: 397.1,
            startTimeInEdit: 397,
            endTimeInEdit: 397.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'entirety',
            startTimeInOriginal: 397.1,
            endTimeInOriginal: 397.5,
            startTimeInEdit: 397.1,
            endTimeInEdit: 397.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 397.5,
            endTimeInOriginal: 397.6,
            startTimeInEdit: 397.5,
            endTimeInEdit: 397.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 397.6,
            endTimeInOriginal: 397.7,
            startTimeInEdit: 397.6,
            endTimeInEdit: 397.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'video.',
            startTimeInOriginal: 397.7,
            endTimeInOriginal: 398.2,
            startTimeInEdit: 397.7,
            endTimeInEdit: 398.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'What',
            startTimeInOriginal: 398.2,
            endTimeInOriginal: 398.4,
            startTimeInEdit: 398.2,
            endTimeInEdit: 398.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'I',
            startTimeInOriginal: 398.4,
            endTimeInOriginal: 398.4,
            startTimeInEdit: 398.4,
            endTimeInEdit: 398.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'would',
            startTimeInOriginal: 398.4,
            endTimeInOriginal: 398.6,
            startTimeInEdit: 398.4,
            endTimeInEdit: 398.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'do',
            startTimeInOriginal: 398.6,
            endTimeInOriginal: 399,
            startTimeInEdit: 398.6,
            endTimeInEdit: 399
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 399,
            endTimeInOriginal: 399.1,
            startTimeInEdit: 399,
            endTimeInEdit: 399.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'is',
            startTimeInOriginal: 399.1,
            endTimeInOriginal: 399.3,
            startTimeInEdit: 399.1,
            endTimeInEdit: 399.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'I',
            startTimeInOriginal: 399.3,
            endTimeInOriginal: 399.3,
            startTimeInEdit: 399.3,
            endTimeInEdit: 399.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'would',
            startTimeInOriginal: 399.3,
            endTimeInOriginal: 399.4,
            startTimeInEdit: 399.3,
            endTimeInEdit: 399.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'bring',
            startTimeInOriginal: 399.4,
            endTimeInOriginal: 399.8,
            startTimeInEdit: 399.4,
            endTimeInEdit: 399.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 399.8,
            endTimeInOriginal: 400.2,
            startTimeInEdit: 399.8,
            endTimeInEdit: 400.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'icon',
            startTimeInOriginal: 400.2,
            endTimeInOriginal: 400.7,
            startTimeInEdit: 400.2,
            endTimeInEdit: 400.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 400.7,
            endTimeInOriginal: 400.8,
            startTimeInEdit: 400.7,
            endTimeInEdit: 400.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'image',
            startTimeInOriginal: 400.8,
            endTimeInOriginal: 401.1,
            startTimeInEdit: 400.8,
            endTimeInEdit: 401.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'icon',
            startTimeInOriginal: 401.1,
            endTimeInOriginal: 401.6,
            startTimeInEdit: 401.1,
            endTimeInEdit: 401.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'all',
            startTimeInOriginal: 401.6,
            endTimeInOriginal: 401.8,
            startTimeInEdit: 401.6,
            endTimeInEdit: 401.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 401.8,
            endTimeInOriginal: 401.9,
            startTimeInEdit: 401.8,
            endTimeInEdit: 401.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'way',
            startTimeInOriginal: 401.9,
            endTimeInOriginal: 402,
            startTimeInEdit: 401.9,
            endTimeInEdit: 402
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 402,
            endTimeInOriginal: 402.1,
            startTimeInEdit: 402,
            endTimeInEdit: 402.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 402.1,
            endTimeInOriginal: 402.2,
            startTimeInEdit: 402.1,
            endTimeInEdit: 402.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'beginning',
            startTimeInOriginal: 402.2,
            endTimeInOriginal: 402.8,
            startTimeInEdit: 402.2,
            endTimeInEdit: 402.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 402.8,
            endTimeInOriginal: 402.90000000000003,
            startTimeInEdit: 402.8,
            endTimeInEdit: 402.90000000000003
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'and',
            startTimeInOriginal: 402.9,
            endTimeInOriginal: 403.1,
            startTimeInEdit: 402.9,
            endTimeInEdit: 403.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'then',
            startTimeInOriginal: 403.1,
            endTimeInOriginal: 403.2,
            startTimeInEdit: 403.1,
            endTimeInEdit: 403.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 403.2,
            endTimeInOriginal: 403.3,
            startTimeInEdit: 403.2,
            endTimeInEdit: 403.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'column',
            startTimeInOriginal: 403.3,
            endTimeInOriginal: 403.9,
            startTimeInEdit: 403.3,
            endTimeInEdit: 403.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'all',
            startTimeInOriginal: 403.9,
            endTimeInOriginal: 404.1,
            startTimeInEdit: 403.9,
            endTimeInEdit: 404.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 404.1,
            endTimeInOriginal: 404.2,
            startTimeInEdit: 404.1,
            endTimeInEdit: 404.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'way',
            startTimeInOriginal: 404.2,
            endTimeInOriginal: 404.3,
            startTimeInEdit: 404.2,
            endTimeInEdit: 404.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'to',
            startTimeInOriginal: 404.3,
            endTimeInOriginal: 404.4,
            startTimeInEdit: 404.3,
            endTimeInEdit: 404.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 404.4,
            endTimeInOriginal: 404.6,
            startTimeInEdit: 404.4,
            endTimeInEdit: 404.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'end',
            startTimeInOriginal: 404.6,
            endTimeInOriginal: 405.1,
            startTimeInEdit: 404.6,
            endTimeInEdit: 405.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'so',
            startTimeInOriginal: 405.1,
            endTimeInOriginal: 405.3,
            startTimeInEdit: 405.1,
            endTimeInEdit: 405.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'that',
            startTimeInOriginal: 405.3,
            endTimeInOriginal: 405.5,
            startTimeInEdit: 405.3,
            endTimeInEdit: 405.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 405.5,
            endTimeInOriginal: 405.6,
            startTimeInEdit: 405.5,
            endTimeInEdit: 405.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'stays',
            startTimeInOriginal: 405.6,
            endTimeInOriginal: 406.2,
            startTimeInEdit: 405.6,
            endTimeInEdit: 406.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'for',
            startTimeInOriginal: 406.2,
            endTimeInOriginal: 406.3,
            startTimeInEdit: 406.2,
            endTimeInEdit: 406.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 406.3,
            endTimeInOriginal: 406.4,
            startTimeInEdit: 406.3,
            endTimeInEdit: 406.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'entirety',
            startTimeInOriginal: 406.4,
            endTimeInOriginal: 406.8,
            startTimeInEdit: 406.4,
            endTimeInEdit: 406.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'of',
            startTimeInOriginal: 406.8,
            endTimeInOriginal: 406.9,
            startTimeInEdit: 406.8,
            endTimeInEdit: 406.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'the',
            startTimeInOriginal: 406.9,
            endTimeInOriginal: 407,
            startTimeInEdit: 406.9,
            endTimeInEdit: 407
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: true,
            text: 'video.',
            startTimeInOriginal: 407,
            endTimeInOriginal: 407.5,
            startTimeInEdit: 407,
            endTimeInEdit: 407.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'Alright,',
            startTimeInOriginal: 407.5,
            endTimeInOriginal: 407.9,
            startTimeInEdit: 407.5,
            endTimeInEdit: 407.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'I',
            startTimeInOriginal: 407.9,
            endTimeInOriginal: 407.9,
            startTimeInEdit: 407.9,
            endTimeInEdit: 407.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'think',
            startTimeInOriginal: 407.9,
            endTimeInOriginal: 408.1,
            startTimeInEdit: 407.9,
            endTimeInEdit: 408.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "that's",
            startTimeInOriginal: 408.1,
            endTimeInOriginal: 408.4,
            startTimeInEdit: 408.1,
            endTimeInEdit: 408.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'about',
            startTimeInOriginal: 408.4,
            endTimeInOriginal: 408.7,
            startTimeInEdit: 408.4,
            endTimeInEdit: 408.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it.',
            startTimeInOriginal: 408.7,
            endTimeInOriginal: 409.1,
            startTimeInEdit: 408.7,
            endTimeInEdit: 409.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'And',
            startTimeInOriginal: 409.1,
            endTimeInOriginal: 409.3,
            startTimeInEdit: 409.1,
            endTimeInEdit: 409.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'it',
            startTimeInOriginal: 409.3,
            endTimeInOriginal: 409.5,
            startTimeInEdit: 409.3,
            endTimeInEdit: 409.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 409.5,
            endTimeInOriginal: 409.75,
            startTimeInEdit: 409.5,
            endTimeInEdit: 409.75
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 409.75,
            endTimeInOriginal: 410,
            startTimeInEdit: 409.75,
            endTimeInEdit: 410
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 410,
            endTimeInOriginal: 410.2,
            startTimeInEdit: 410,
            endTimeInEdit: 410.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'seems',
            startTimeInOriginal: 410.2,
            endTimeInOriginal: 410.5,
            startTimeInEdit: 410.2,
            endTimeInEdit: 410.5
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'like',
            startTimeInOriginal: 410.5,
            endTimeInOriginal: 410.6,
            startTimeInEdit: 410.5,
            endTimeInEdit: 410.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "I'm",
            startTimeInOriginal: 410.6,
            endTimeInOriginal: 410.9,
            startTimeInEdit: 410.6,
            endTimeInEdit: 410.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'fading',
            startTimeInOriginal: 410.9,
            endTimeInOriginal: 411.3,
            startTimeInEdit: 410.9,
            endTimeInEdit: 411.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'out',
            startTimeInOriginal: 411.3,
            endTimeInOriginal: 411.4,
            startTimeInEdit: 411.3,
            endTimeInEdit: 411.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'again.',
            startTimeInOriginal: 411.4,
            endTimeInOriginal: 411.7,
            startTimeInEdit: 411.4,
            endTimeInEdit: 411.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'So,',
            startTimeInOriginal: 411.7,
            endTimeInOriginal: 412.4,
            startTimeInEdit: 411.7,
            endTimeInEdit: 412.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 412.4,
            endTimeInOriginal: 412.65,
            startTimeInEdit: 412.4,
            endTimeInEdit: 412.65
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 412.65,
            endTimeInOriginal: 412.9,
            startTimeInEdit: 412.65,
            endTimeInEdit: 412.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 412.9,
            endTimeInOriginal: 413.09999999999997,
            startTimeInEdit: 412.9,
            endTimeInEdit: 413.09999999999997
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'oh',
            startTimeInOriginal: 413.1,
            endTimeInOriginal: 413.2,
            startTimeInEdit: 413.1,
            endTimeInEdit: 413.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'God.',
            startTimeInOriginal: 413.2,
            endTimeInOriginal: 413.8,
            startTimeInEdit: 413.2,
            endTimeInEdit: 413.8
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 413.8,
            endTimeInOriginal: 414.05,
            startTimeInEdit: 413.8,
            endTimeInEdit: 414.05
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 414.05,
            endTimeInOriginal: 414.3,
            startTimeInEdit: 414.05,
            endTimeInEdit: 414.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'I',
            startTimeInOriginal: 414.3,
            endTimeInOriginal: 414.4,
            startTimeInEdit: 414.3,
            endTimeInEdit: 414.4
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'wonder,',
            startTimeInOriginal: 414.4,
            endTimeInOriginal: 414.6,
            startTimeInEdit: 414.4,
            endTimeInEdit: 414.6
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: "where it's",
            startTimeInOriginal: 414.6,
            endTimeInOriginal: 414.7,
            startTimeInEdit: 414.6,
            endTimeInEdit: 414.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'gonna',
            startTimeInOriginal: 414.7,
            endTimeInOriginal: 414.9,
            startTimeInEdit: 414.7,
            endTimeInEdit: 414.9
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'take',
            startTimeInOriginal: 414.9,
            endTimeInOriginal: 415,
            startTimeInEdit: 414.9,
            endTimeInEdit: 415
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'me',
            startTimeInOriginal: 415,
            endTimeInOriginal: 415.1,
            startTimeInEdit: 415,
            endTimeInEdit: 415.1
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'this',
            startTimeInOriginal: 415.1,
            endTimeInOriginal: 415.3,
            startTimeInEdit: 415.1,
            endTimeInEdit: 415.3
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: false,
            hasLinebreak: false,
            text: 'time.',
            startTimeInOriginal: 415.3,
            endTimeInOriginal: 415.7,
            startTimeInEdit: 415.3,
            endTimeInEdit: 415.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 415.7,
            endTimeInOriginal: 415.95,
            startTimeInEdit: 415.7,
            endTimeInEdit: 415.95
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 415.95,
            endTimeInOriginal: 416.2,
            startTimeInEdit: 415.95,
            endTimeInEdit: 416.2
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 416.2,
            endTimeInOriginal: 416.45,
            startTimeInEdit: 416.2,
            endTimeInEdit: 416.45
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 416.45,
            endTimeInOriginal: 416.7,
            startTimeInEdit: 416.45,
            endTimeInEdit: 416.7
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 416.7,
            endTimeInOriginal: 416.95,
            startTimeInEdit: 416.7,
            endTimeInEdit: 416.95
        },
        {
            speakerTag: 0,
            isCut: false,
            isPause: true,
            hasLinebreak: false,
            text: '',
            startTimeInOriginal: 416.95,
            endTimeInOriginal: 417.13,
            startTimeInEdit: 416.95,
            endTimeInEdit: 417.13
        }
    ]
};
