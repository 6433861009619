import { SubscriptionState } from './subscription-state';
import { Language, Languages } from '../language';
import { getLimitationByPlanType } from '../consts/limitations';
import { ImageProviderType } from '../gallery/image-gallery.models';
import { PlanType, getTierFromPlanType, getPlanTypeFromTier } from '../payment/products';
import { FbUltraPlanDetails } from './slid-user';

export class EditorUiState {
    templateMenu?: {
        defaultTab: string;
        hideTemplateTabs: boolean;
        quickEditLocationTooltipShown: number;
    };
    translation: {
        lastUsed: string;
    };
    hints?: {
        markerShown: boolean;
        cutHintShown: boolean;
    };
    subtitles?: {
        lastAppliedStyles?: {
            bg?: boolean;
            bgColor?: string;
            konvaElement?: string;
        };
    };
    libraries?: {
        hasImagesUploaded?: boolean;
        lastUsedImageProvider?: ImageProviderType;
    };
    lastPlayedHint?;
    elementsGallery?: {
        lastUsedProvider?: 'emojis' | 'elements';
    };
    pauseRemovalCount?: number;
    selectedExportTab?: string;
    textExport?: {
        includeTimestamps?: boolean;
        includeSpeakers?: boolean;
    };
    manualClipCreated?: boolean;
}

export class PersistentUiState {
    editor?: EditorUiState;
    app?: {
        subscriptionWarningDisplayed?: boolean;
    };
}

export interface SlidState {
    id: number;
    email: string;
    accessToken: string;
    refreshToken: string;
    expirationDate: number;
    lastUpdate: number;
    isUltra: boolean;
    plan: FbUltraPlanDetails;
}

export class UserModel {
    firebaseId: string;
    customerId: string;
    email: string;
    name: string;
    profilePicture: string;
    createdAt: number;
    language: Language;
    subscriptionState: SubscriptionState;
    slidState: SlidState;
    isAnonymous: boolean;

    promotionCodeId: string;
    socialExports?;
    uiState?: PersistentUiState;

    uploadedOnce: boolean;
    requiresSuccessfulUploadEmail? = true;
    translationCount: number;
    isVerified: boolean;

    exampleProjectVisible;
    votedFeatureIds: string[];

    // 0->nothing completed | 1 -> emoji completed | 2 -> pmf completed
    surveyStep: number;

    // needed to verify that every user went through the slid email verification
    needsSlidSignup?: boolean;
}

export class User extends UserModel {
    /**
     * Returns true if the user has the TRIAL (free) plan
     */
    get isTrial(): boolean {
        return this.subscriptionState?.isTrial;
    }

    /**
     * Returns true if the user has the PERSONAL or PRO or Enterprise plan
     */
    get isStarter(): boolean {
        return this.subscriptionState.isStarter || this.subscriptionState.isEnterprise;
    }
    /**
     * Returns true if the user has the PERSONAL or PRO or Enterprise plan
     */
    get isStarterBasic(): boolean {
        return this.subscriptionState?.isBasic;
    }

    /**
     * Returns true if the user has the Pro or Enterprise plan
     */
    get isPro(): boolean {
        return this.subscriptionState.isPro;
    }
    /**
     * Returns true if the user has the PRO_TEAM or Enterprise plan
     */
    get isProTeam(): boolean {
        return this.subscriptionState.isProTeam;
    }

    /**
     * Returns true if the user has the PERSONAL or PRO or Enterprise plan
     */
    get isBusiness(): boolean {
        return this.subscriptionState.isBusiness;
    }

    /**
     * Returns true if the user has the ENTERPRISE plan
     */
    get isEnterprise(): boolean {
        return this.subscriptionState?.isEnterprise;
    }

    /**
     * Returns true if the user has a payed plan (BASIC, PERSONAL, PRO, ENTERPRISE, CANCELED)
     */
    get isPaying(): boolean {
        return this.subscriptionState?.isPaying;
    }

    /**
     * Returns the next higher plan tier
     * @example Current user has Starter plan => Returns Pro plan
     */
    getNextHigherPlanType(startAt?: PlanType): PlanType {
        let targetPlan: PlanType;
        switch (this.subscriptionState.plan.type) {
            case PlanType.TRIAL:
            case PlanType.EXPIRED:
                targetPlan = PlanType.PRO;
                break;
            case PlanType.BASIC:
            case PlanType.STARTER_BASIC:
                targetPlan = PlanType.PRO;
                break;
            case PlanType.PERSONAL:
            case PlanType.PRO:
            case PlanType.STARTER:
                return null;
                targetPlan = PlanType.PRO_TEAM;
                break;
            case PlanType.PRO_TEAM:
                return null;
                targetPlan = PlanType.BUSINESS;
                break;
            default:
                return null;
        }

        if (startAt) {
            const targetTier = getTierFromPlanType(targetPlan);
            const startTier = getTierFromPlanType(startAt);
            if (targetTier < startTier) {
                targetPlan = getPlanTypeFromTier(startTier);
            }
        }
        return targetPlan;
    }

    /**
     * Returns the limitations for the users plan
     */
    getLimitations() {
        return getLimitationByPlanType(this.subscriptionState.plan.type);
    }

    constructor() {
        super();
        this.firebaseId = '';
        this.customerId = '';
        this.name = '';
        this.email = '';
        this.profilePicture = '';
        this.language = Languages['en-US'];
        this.createdAt = Math.floor(Date.now() / 1000);
        this.subscriptionState = new SubscriptionState();
        this.uploadedOnce = false;
        this.isVerified = false;
        this.exampleProjectVisible = true;
        this.votedFeatureIds = [];
        this.surveyStep = 0;
    }

    fromAuthentication(firebaseId: string, email: string, name: string, profilePicture?: string): User {
        this.firebaseId = firebaseId;
        this.email = email;
        this.name = name;
        this.profilePicture = profilePicture;
        this.requiresSuccessfulUploadEmail = true;
        this.subscriptionState = new SubscriptionState();
        this.language = Languages['en-US'];
        this.isVerified = false;

        return this;
    }

    initSlidState(slidState: SlidState) {
        this.slidState = slidState;
        return this;
    }
    initAnonymous() {
        this.isAnonymous = true;
        return this;
    }

    fromFirebase(user: User): User {
        this.firebaseId = user.firebaseId;
        this.customerId = user.customerId;
        this.email = user.email;
        this.name = user.name;
        this.profilePicture = user.profilePicture;
        this.createdAt = user.createdAt;
        this.uploadedOnce = user.uploadedOnce || false;

        this.requiresSuccessfulUploadEmail = user.requiresSuccessfulUploadEmail;
        this.exampleProjectVisible = user.exampleProjectVisible || false;
        this.promotionCodeId = user.promotionCodeId;
        this.translationCount = user.translationCount || 0;
        this.socialExports = user.socialExports;
        this.uiState = user.uiState;
        this.isVerified = user.isVerified === undefined ? true : user.isVerified; // If undefined it's an old user who didn't need to verify
        this.slidState = user.slidState || null;
        this.isAnonymous = user?.isAnonymous || false;
        if (user.subscriptionState) {
            this.subscriptionState = new SubscriptionState(user.subscriptionState);
        } else {
            this.subscriptionState = new SubscriptionState();
        }
        if (typeof user.language === 'string') {
            this.language = Languages[user.language] || Languages['en-US'];
        }
        this.votedFeatureIds = user.votedFeatureIds || [];
        this.needsSlidSignup = user.needsSlidSignup || null;
        this.setSurveyStep(user.surveyStep);

        return this;
    }

    toFirebase() {
        const userObject = {
            firebaseId: this.firebaseId || null,
            name: this.name || null,
            email: this.email || null,
            profilePicture: this.profilePicture || null,
            language: this.language.codeLong || null,
            createdAt: this.createdAt || null,
            uploadedOnce: this.uploadedOnce || null,
            requiresSuccessfulUploadEmail: this.requiresSuccessfulUploadEmail || null,
            exampleProjectVisible: this.exampleProjectVisible || null,
            votedFeatureIds: this.votedFeatureIds || null,
            surveyStep: this.surveyStep || null,
            subscriptionState: this.subscriptionState.toFirebase(),
            slidState: this.slidState || null,
            promotionCodeId: this.promotionCodeId || null,
            socialExports: this.socialExports || null,
            uiState: this.uiState || null,
            isVerified: this.isVerified || null,
            translationCounter:
                this.translationCount === null || this.translationCount === undefined ? null : this.translationCount
        };
        if (this.customerId) {
            userObject['customerId'] = this.customerId;
        }
        if (this.isAnonymous) {
            userObject['isAnonymous'] = this.isAnonymous;
        }
        if (this.needsSlidSignup) {
            userObject['needsSlidSignup'] = this.needsSlidSignup;
        }
        return userObject;
    }

    setSurveyStep(newSurveyStep: number) {
        // if user uploaded already emoji survey is not shown again
        if (newSurveyStep >= 1) {
            this.surveyStep = newSurveyStep;
        } else {
            if (newSurveyStep) {
                this.surveyStep = 1;
            } else {
                this.surveyStep = 0;
            }
        }
    }
}

export class ResetUserPasswordRequestModel {
    password: string;
}
