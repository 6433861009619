import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: '[appTrigger]' })
export class OverlayTriggerDirective {
    @Output() appTrigger = new EventEmitter<ElementRef>();

    @HostListener('click')
    onClick() {
        this.appTrigger.emit(this._el);
    }

    constructor(private _el: ElementRef) {}
}
