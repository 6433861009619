export const FirebaseFunctionNames = {
    authenticateSlid: 'authenticateSlid',
    linkSlid: 'linkSlid',
    updateSlidState: 'updateSlidState',
    getSlidAuthenticationUrl: 'getSlidAuthenticationUrl',
    getSlidStorageUrl: 'getSlidStorageUrl',
    getSlidStorageSharedId: 'getSlidStorageSharedId',
    getSlidCheckoutUrl: 'getSlidCheckoutUrl',
    userUpdatePassword: 'userUpdatePassword',
    sendUserVerification: 'sendUserVerification',
    organizationInviteMember: 'organizationInviteMember',
    organizationRemoveMember: 'organizationRemoveMember',
    inviteMemberToProject: 'inviteMemberToProject',
    sendFeedback: 'sendFeedback',
    sendReport: 'sendReport',
    validatePromotionCode: 'validatePromotionCode',
    createCustomerPortalUrl: 'createCustomerPortalUrl',
    transcribeVideo: 'transcribeVideo',
    startTranslation: 'startTranslation',
    startVoiceTranslation: 'startVoiceTranslation',
    getSsmlForTranscription: 'getSsmlForTranscription',
    requestVideoRendering: 'startRendering',
    getRenderingProgress: 'getRenderingProgress',
    linkUpload: 'linkUpload',
    generateProjectInviteAccessToken: 'generateProjectInviteAccessToken',
    projectRemoveMember: 'projectRemoveMember',
    createProject: 'createProject',
    deleteProjects: 'deleteProjects',
    acceptProjectInvite: 'acceptProjectInvite',
    removeProjectInviteAccessToken: 'removeProjectInviteAccessToken',
    userSignup: 'userSignup',
    copyFile: 'copyFile',
    trackEvent: 'trackEvent',
    createFolder: 'createFolder',
    deleteFolder: 'deleteFolder',
    addToFolder: 'addToFolder',
    updateFolderName: 'updateFolderName',
    shareExport: {
        linkedin: 'getAccessTokenLinkedIn',
        facebook: 'getAccessTokenFacebook',
        youtube: 'getAccessTokenYouTube',
        publishPostToFacebook: 'publishPostToFacebook',
        publishPostToLinkedIn: 'publishPostToLinkedIn',
        publishPostToYouTube: 'publishPostToYouTube'
    },
    getImagesByTerm: 'getImagesByTerm',
    saveImageFromGallery: 'saveImageFromGallery',
    getImagesBySelectedText: 'getImagesBySelectedText',
    getEmojisByCategory: 'getEmojisByCategory',
    saveEmojiFromGallery: 'saveEmojiFromGallery',
    createProjectFromHighlight: 'createProjectFromHighlight',
    hintVideos: 'hintVideos',
    cancelRendering: 'cancelRendering',
    startRetainedMediaTranscription: 'startRetainedMediaTranscription',
    manuallyTriggerStartTranscription: 'manuallyTriggerStartTranscription',
    manuallyTriggerAutoRendering: 'manuallyTriggerAutoRendering',
    createSubscription: 'createPayment23hkkh1v23hkv1khb',
    extractHighlightClips: 'extractHighlightClips'
};
