import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-toggle-button',
    templateUrl: './toggle-button.component.html'
})
export class ToggleButtonComponent {
    toggleSizeMap = {
        sm: 'h-6',
        md: 'h-10',
        auto: 'h-fill'
    };

    @Input() size: 'sm' | 'md' | 'auto' = 'md';
    @Input() surroundedByLabels = false;
    @Input() checked: boolean;
    @Input() disabled: boolean;
    @Input()
    set toggleState(state: boolean) {
        if (state && this.checked !== state) {
            this.switchToggle();
        }
    }

    @Output() checkedChange = new EventEmitter<boolean>();

    // TODO: this component should not store it's own state
    switchToggle(e?: any) {
        if (e === undefined && !this.disabled) {
            this.checked = !this.checked;
        } else {
            this.checked = e.target.checked;
        }
        this.checkedChange.emit(this.checked);
    }
}
