<ng-template let-ref="popover">
    <div
        class="rounded-2xl bg-white px-4 pt-3 pb-6 shadow-floating"
        [ngStyle]="{
            'min-width.px': ref.config?.minWidth,
            'min-height.px': ref.config?.minHeight,
            'max-width.px': ref.config?.maxWidth,
            'max-height.px': ref.config?.maxHeight,
            'width.px': ref.config?.width,
            'height.px': ref.config?.height
        }"
        [@transformMenu]="_panelAnimationState"
        (click)="$event.stopPropagation()"
        role="menu"
    >
        <div
            class="scroll-container--y-auto flex flex-wrap"
            [ngStyle]="{
                'max-height.px': maxHeight,
                'height.px': height,
                'max-width.px': maxWidth,
                'width.px': width
            }"
        >
            <ng-content></ng-content>
        </div>
    </div>
</ng-template>
