import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import routes from '@type/shared-models/consts/routes';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { EXAMPLE_PROJECT_ID } from '../../environments/publicTranscriptionExample';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private userService: UserService,
        private auth: AuthService,
        private router: Router,
        private route: ActivatedRoute
    ) {}
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        // always allow example page
        if (next.paramMap.get('id') === EXAMPLE_PROJECT_ID) {
            return true;
        }
        if (this.userService.user) {
            return true;
        }
        return this.auth.firebaseAuth$.pipe(
            take(1),
            map((user) => !!user),
            switchMap((loggedIn) => {
                if (!loggedIn) {
                    this.auth.redirectUrl = new URL(state.url, document.location.origin);
                    let targetRoute = routes.SIGNUP;

                    if (state.root.queryParams.pricing) {
                        targetRoute = routes.ANONYMOUS_LOGIN;
                    }

                    return this.router.navigate([targetRoute], {
                        queryParams: state.root.queryParams,
                        queryParamsHandling: 'merge'
                    });
                } else {
                    // wait for user to be set before navigation
                    return this.userService.user$.pipe(
                        filter((val) => !!val),
                        map((val) => !!val)
                    );
                }
            })
        );
    }
}
