import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShareWithType } from '@type/shared-models/sharing/share-with-type';
import {
    OAuthAuthorizationResponseFailed,
    OAuthAuthorizationResponseSuccess
} from '@type/shared-models/sharing/o-auth-authorization';
import { take } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';

@Component({ template: '<div class="relative h-60 w-full"><type-loader></type-loader></div>' })
export class ExportAuthComponent {
    constructor(private activatedRoute: ActivatedRoute, private userService: UserService) {
        this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
            const type: ShareWithType = this.activatedRoute.snapshot.params.type;
            const oauthResponseParams = params as OAuthAuthorizationResponseSuccess | OAuthAuthorizationResponseFailed;
            switch (type) {
                case ShareWithType.FACEBOOK:
                case ShareWithType.LINKEDIN:
                case ShareWithType.YOUTUBE:
                    // TODO: Check if it is the same pattern for every login and generalise if possible
                    if ('error' in oauthResponseParams) {
                        const { state, error_code, error_reason, ...newOauthResponseParams } = oauthResponseParams;
                        this.userService.updateSocialExport(type, { ...newOauthResponseParams }, true);
                    } else {
                        this.userService.updateSocialExport(type, { code: oauthResponseParams.code }, true);
                    }
                    break;
                case ShareWithType.INSTAGRAM_BUSINESS:
                    break;
                case ShareWithType.TWITTER:
                    break;
                case ShareWithType.EMBEDDED:
                    break;
            }
        });
        // This is a backup. If after 6 seconds the window is still not closed, reload it and run the logic check again
        setTimeout(() => {
            location.reload();
        }, 6000);
    }
}
