import { NgModule } from '@angular/core';
import { TriggerDirective } from './trigger.directive';

const exportItems = [TriggerDirective];

@NgModule({
    imports: [],
    declarations: exportItems,
    exports: exportItems
})
export class TriggerModule {}
