import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@type-mvp/app/services/auth.service';
import { ProjectService } from '@type-mvp/app/services/project.service';

import { UserService } from '@type-mvp/app/services/user.service';
import { DialogService } from '@type/dialog';
import routes from '@type/shared-models/consts/routes';
import { getCookie } from '@type/shared/utils/cookies';
import { EMPTY, Subject, Subscription, filter, switchMap, take, takeUntil, tap, timeout } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FirebaseFunctionNames } from '@type/shared-models/consts/firebase-function-names';
import { GetSlidStorageUrlRequest, GetSlidStorageUrlResponse } from '@type/shared-models/auth/authenticate-slid.models';
import { SignupComponent } from '../auth/signup/signup.component';
interface SlidStorageError {
    errorStatus: number;
    title: string;
    subtitle: string;
    buttonLabel?: string;
}
const SlidStorageErrors: { [errorStatus: number]: SlidStorageError } = {
    401: {
        errorStatus: 401,
        title: 'You are not signed in.',
        subtitle: 'To open the file, log in with the same account you used to log in to the origin tool.',
        buttonLabel: 'Login'
    },
    403: {
        errorStatus: 403,
        title: 'You do not have access to open this Streamlabs file.',
        subtitle: 'To open the file, log in with the same account you used to log in to the origin tool.',
        buttonLabel: 'Switch account'
    },
    404: {
        errorStatus: 404,
        title: 'The file you are trying to view does not exist.',
        subtitle: 'Make sure that the file exists in the original tool.'
    }
};

@Component({
    selector: 'app-slid-storage',
    templateUrl: './slid-storage.component.html',
    styleUrls: ['./slid-storage.component.scss']
})
export class SlidStorageComponent implements OnInit, OnDestroy {
    isSignedIn = false;
    storageError: SlidStorageError;
    authSubscription: Subscription;
    constructor(
        private router: Router,
        private angularFireAuth: AngularFireAuth,
        private projectService: ProjectService,
        private activatedRoute: ActivatedRoute,
        private dialogService: DialogService,
        private userService: UserService,
        private firebaseFunctions: AngularFireFunctions,
        private authService: AuthService,
        private route: ActivatedRoute
    ) {
        const id = this.route.snapshot.paramMap.get('id');
        this.authSubscription = this.angularFireAuth.authState
            .pipe(
                switchMap((firebaseAuth) => {
                    this.storageError = null;
                    this.isSignedIn = !!firebaseAuth;

                    if (this.isSignedIn) {
                        return this.userService?.user$.pipe(
                            filter((user) => !!user),
                            take(1),
                            tap((user) => console.log(user))
                        );
                    } else {
                        this.storageError = SlidStorageErrors[401];
                        return EMPTY;
                    }
                }),
                switchMap((user) => {
                    if (user?.slidState) {
                        //request file url from streamlabs
                        // open link route with that url
                        return this.firebaseFunctions.httpsCallable<
                            GetSlidStorageUrlRequest,
                            GetSlidStorageUrlResponse
                        >(FirebaseFunctionNames.getSlidStorageUrl)({ storageId: id, userId: user.firebaseId });
                    } else {
                        if (user?.isAnonymous) {
                            this.storageError = SlidStorageErrors[401];
                        } else {
                            this.storageError = SlidStorageErrors[403];
                        }
                        return EMPTY;
                    }
                })
            )
            .subscribe((response: GetSlidStorageUrlResponse) => {
                if (response?.slidStorageUrl) {
                    router.navigate([routes.LINK], {
                        queryParams: { url: response.slidStorageUrl, fromSlStorage: true }
                    });
                }
                if (response?.error) {
                    this.storageError = SlidStorageErrors[response.error.status];
                    console.error(response.error);
                }
            });
    }
    ngOnInit(): void {}
    ngOnDestroy(): void {
        this.authSubscription?.unsubscribe();
    }

    createProject(uploadUrl?: string) {
        const projectId = this.projectService.generateId();
        const isMobile = document.documentElement.clientWidth < 640;
        const queryParams = { isNew: true, crumbs: null, url: null || uploadUrl };
        const route = isMobile ? routes.UPLOAD : routes.EDITOR;

        this.router.navigate([route, projectId], { queryParams, queryParamsHandling: 'merge' });
    }
    async switchUser() {
        const currentRoute = this.router.url;
        if (!this.userService.user?.isAnonymous) {
            await this.authService.signOut();
            this.authService.slidSignIn(false, this.userService.user?.isAnonymous, false, () => {
                this.router.navigateByUrl(decodeURIComponent(currentRoute));
            });
        } else {
            this.dialogService
                .openDialog(SignupComponent, {
                    maxWidth: 1300,
                    data: { hideLogin: true, fromAnonymous: true, openPayment: false }
                })
                .afterClosed()
                .subscribe(() => {
                    this.userService.user$
                        .pipe(
                            timeout({ each: 20000 }),
                            filter((user) => !!user && !user.isAnonymous),
                            take(1)
                        )
                        .subscribe(() => {
                            this.router.navigateByUrl(decodeURIComponent(currentRoute));
                        });
                });
        }
    }
}
