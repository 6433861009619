import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import routes from '@type/shared-models/consts/routes';
import { ResetUserPasswordRequestModel, User as UserModel } from '@type/shared-models/user/user';
import { AnalyticsEvents } from '@type/shared-models/consts/analytic-events';
import { combineLatest, from, Observable, of, race, Subject, Subscription, timer } from 'rxjs';
import { ProjectService } from './project.service';
import { EditorService } from './editor.service';
import { filter, first, map, skip, switchMap, take, tap } from 'rxjs/operators';

import firebase from 'firebase/compat/app';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FirebaseFunctionNames } from '@type/shared-models/consts/firebase-function-names';
import { AnalyticsService } from './analytics.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PaymentDialogService } from '@type/payment-dialog/payment-dialog.service';
import { deleteCookie } from '@type/shared/utils/cookies';
import User = firebase.User;

import FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import OAuthProvider = firebase.auth.OAuthProvider;
import { OAuthAuthorizationResponse } from '@type/shared-models/sharing/o-auth-authorization';
import {
    AuthenticateSlidRequest,
    AuthenticateSlidResponse,
    GetSlidAuthenticationUrlRequest,
    GetSlidAuthenticationUrlResponse,
    SlidAuthenticationIntent
} from '@type/shared-models/auth/authenticate-slid.models';
import { environment } from '../../environments/environment';
import { PAYMENT_REASONS } from '@type/shared-models/consts/payment-reason';
import { DialogService } from '@type/dialog';
import { PlanType } from '@type/shared-models/payment/products';
import { trackSignUp } from '../../../../../libs/shared-models/src/lib/utils/tracking';
import { ToastService } from '../toast/toast.service';
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    redirectUrl: URL = new URL(window.location.origin);
    firebaseAuth$: Observable<User>;
    isSignedIn = false;

    authSubscription: Subscription;

    slidAuthClosed$: Subject<{ error: boolean; message?: string }>;
    constructor(
        private angularFireAuth: AngularFireAuth,
        private angularFireFunctions: AngularFireFunctions,
        private userService: UserService,
        private router: Router,
        private projectService: ProjectService,
        private editorService: EditorService,
        private analyticsService: AnalyticsService,
        private deviceService: DeviceDetectorService,
        private paymentDialogService: PaymentDialogService,
        private dialogService: DialogService,
        private toastService: ToastService
    ) {
        this.firebaseAuth$ = angularFireAuth.authState;

        this.authSubscription = this.firebaseAuth$.subscribe((firebaseAuth) => {
            this.isSignedIn = !!firebaseAuth;
        });

        this.userService.subscribeAuthenticatedUser(this.firebaseAuth$);
    }

    resetPassword({ code, password }) {
        return from(this.angularFireAuth.confirmPasswordReset(code, password));
    }

    updatePassword(password) {
        return this.angularFireFunctions
            .httpsCallable<ResetUserPasswordRequestModel>(FirebaseFunctionNames.userUpdatePassword)({ password })
            .pipe(switchMap((token) => this.angularFireAuth.signInWithCustomToken(token)));
    }

    // emailSignUp(email: string, password: string, name: string, parameter?: string): Observable<any> {
    //     return this.angularFireFunctions
    //         .httpsCallable(FirebaseFunctionNames.userSignup)({
    //             email,
    //             password,
    //             name
    //         })
    //         .pipe(
    //             tap(({ data }) => {
    //                 const newUser = new UserModel().fromAuthentication(data.uid, data.email, data.displayName);
    //                 this.userService.identifyUser(newUser, parameter);

    //                 window.analytics.track(AnalyticsEvents.SIGNUP_COMPLETE, { method: 'email' });
    //                 this.tagManagerTrackSignUp(email);

    //                 // this.router.navigate([routes.PROJECTS], { queryParamsHandling: 'merge' });
    //             }),
    //             switchMap(() => this.emailSignIn(email, password, true))
    //             // tap(() => this.createProject())
    //         );
    // }

    emailSignIn(email: string, password: string, noRouting?: boolean) {
        return this.angularFireAuth.signInWithEmailAndPassword(email, password).then(async () => {
            const needsSlidSignup = await this.handleNonSlidSignup();
            if (needsSlidSignup) {
                return;
            }
            window.analytics.track(AnalyticsEvents.SIGNIN_COMPLETE);
            if (noRouting) {
                return;
            }
            this.router.navigate([this.redirectUrl.pathname || routes.PROJECTS]).then();
        });
    }

    updateEmail(newEmail: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.firebaseAuth$
                .pipe(
                    filter((user) => user != null),
                    first()
                )
                .subscribe(async (user) => {
                    try {
                        await user.updateEmail(newEmail);
                        resolve();
                    } catch (error) {
                        reject();
                    }
                });
        });
    }

    confirmSignIn(email: string, password: string) {
        return this.angularFireAuth.signInWithEmailAndPassword(email, password);
    }

    sendResetPassword(email: string) {
        return this.angularFireAuth.sendPasswordResetEmail(email);
    }

    tagManagerTrackSignUp(email: string) {
        const gtmTag = {
            event: 'signup-click',
            data: email
        };
    }

    async signOut() {
        await this.router.navigate(['safe-space']).then(() => {
            deleteCookie('isTypeUser');
            this.userService.resetUser();
            this.editorService.resetEditor();
            return this.angularFireAuth.signOut();
        });

        window.analytics.track(AnalyticsEvents.SIGNOUT_COMPLETE);
    }

    isAuthenticated() {
        return new Promise((resolve) => {
            this.firebaseAuth$.pipe(first()).subscribe((user) => {
                resolve(!!user);
            });
        });
    }

    async googleSignIn(referrerParam?) {
        return this._providerSignIn(new GoogleAuthProvider(), referrerParam);
    }

    async facebookSignIn(referrerParam?) {
        return this._providerSignIn(new FacebookAuthProvider(), referrerParam);
    }

    async appleSignIn(referrerParam?) {
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        return this._providerSignIn(provider, referrerParam);
    }

    slidSignIn(isSignUp = false, connectAnonymousUser = false, openPaymentDialog = false, onSuccess?: () => void) {
        const slidWindow = window.open(`${environment.url}/safe-space`, '_blank', 'height=600,width=588');
        this.angularFireFunctions
            .httpsCallable<GetSlidAuthenticationUrlRequest, GetSlidAuthenticationUrlResponse>(
                FirebaseFunctionNames.getSlidAuthenticationUrl
            )({
                fromLocalEnvironment:
                    environment.emulator === 'localhost' ||
                    environment.emulator === 'functions' ||
                    environment.emulator === 'full'
            })
            .subscribe((slidAuthenticationUrlResponse) => {
                const codeVerifierKey = slidAuthenticationUrlResponse.codeVerifierKey;
                slidWindow.location = `${slidAuthenticationUrlResponse.slidAuthenticationUrl}${
                    isSignUp ? '&intent=signup' : ''
                }`;

                const completeSlidSignIn = (event) => {
                    if (event.origin === location.origin && event?.data?.id === environment.messageId) {
                        console.log('🚀 ~ event:', event);
                        if (event?.data.opened === true) {
                            slidWindow.postMessage({ id: environment.messageId, codeVerifierKey }, location.origin);
                            return;
                        }

                        if (event?.data?.success) {
                            this.slidWindowClosed(connectAnonymousUser, openPaymentDialog, onSuccess);
                        } else {
                            this.slidAuthClosed$.next({ error: true, message: event.data });
                            console.log('Error', event);
                            slidWindow.close();
                        }
                        if (event?.data?.wasSignup) {
                            if (event.data?.wasSignup) {
                                setTimeout(() => {
                                    console.log('trackSignUp');
                                    trackSignUp();
                                }, 1000);
                            }
                        }
                        window.removeEventListener('message', completeSlidSignIn);
                    }
                };
                window.addEventListener('message', completeSlidSignIn);

                const popupTick = setInterval(() => {
                    if (slidWindow?.closed) {
                        clearInterval(popupTick);
                        this.slidAuthClosed$.next({ error: false });
                        window.removeEventListener('message', completeSlidSignIn);
                    }
                }, 500);
            });

        this.slidAuthClosed$ = new Subject();
        return this.slidAuthClosed$;
    }

    private slidWindowClosed(connectAnonymousUser = false, openPaymentDialog = false, onSuccess?: () => void) {
        this.slidAuthClosed$.next({ error: false });
        this.firebaseAuth$
            .pipe(
                filter((user) => !!user),
                take(1)
            )
            .subscribe((user) => {
                if (openPaymentDialog) {
                    this.dialogService.closeAll();
                    this.paymentDialogService.openPaymentDialog(null, PAYMENT_REASONS.afterAnonymousSignup);
                } else if (!connectAnonymousUser) {
                    this.router
                        .navigate([this.redirectUrl.pathname || routes.PROJECTS], { queryParamsHandling: 'preserve' })
                        .then(() => {});
                }
                if (onSuccess) {
                    onSuccess();
                }
            });
    }

    authenticateSlid(oAuthResponse: OAuthAuthorizationResponse, codeVerifierKey: string) {
        if (oAuthResponse.error_code) {
            this.slidAuthClosed$.next({ error: true, message: oAuthResponse.error_code });
            return;
        }
        // check if is authenticated && is anonymous, if so send anonymous id to slidAuthentication to merge accounts
        const userTimeout$ = timer(2000).pipe(
            take(1),
            map(() => null)
        );

        const possibleAnonymousAuth$ = this.firebaseAuth$.pipe(
            filter((auth) => !!auth),
            tap((possibleAuth) => console.log('auth:', possibleAuth.uid))
        );

        return race([possibleAnonymousAuth$, userTimeout$]).pipe(
            map((auth) => auth),
            take(1),
            tap((auth) => console.log(auth?.isAnonymous ? 'Is Anonymous' : 'Is not anonymous')),
            switchMap((auth: firebase.User) =>
                this.angularFireFunctions
                    .httpsCallable<AuthenticateSlidRequest, AuthenticateSlidResponse>(
                        FirebaseFunctionNames.authenticateSlid
                    )({
                        ...oAuthResponse,
                        anonymousUserId: auth?.isAnonymous ? auth.uid : null,
                        codeVerifierKey,
                        fromLocalEnvironment:
                            environment.emulator === 'localhost' ||
                            environment.emulator === 'functions' ||
                            environment.emulator === 'full'
                    })
                    .pipe(
                        switchMap(async (responseData) => {
                            if (responseData.redirectUrl) {
                                return responseData.redirectUrl;
                            }
                            if (responseData.error) {
                                throw responseData.error;
                            }
                            if (auth?.isAnonymous) {
                                await this.angularFireAuth.signOut();
                            }

                            return {
                                user: this.angularFireAuth.signInWithCustomToken(responseData.firebaseToken),
                                wasSignup: responseData.wasSignup
                            }; // user credentials
                        }),
                        switchMap((response) => {
                            if (typeof response === 'string') {
                                return of(response);
                            }
                            return this.angularFireAuth.authState.pipe(
                                filter((authState) => !!authState),
                                map(() => !!response.wasSignup)
                            );
                        })
                    )
            )
        );

        //error handling
    }

    async anonymousSignIn() {
        try {
            const anonymousUser = await this.angularFireAuth.signInAnonymously();
            this.analyticsService.track(AnalyticsEvents.SIGNIN_COMPLETE, {
                provider: 'Anonymous',
                device: this.deviceService.device,
                isMobile: this.deviceService.isMobile()
            });

            await this.router.navigate([this.redirectUrl.pathname || routes.EDITOR]);
        } catch (error) {
            console.error(error);
        }
    }

    private handleNonSlidSignup() {
        return new Promise((resolve) => {
            try {
                this.angularFireAuth.user
                    .pipe(
                        switchMap((user) => this.userService.getUserDocument(user.uid).valueChanges()),
                        filter((user) => !!user),
                        take(1)
                    )
                    .subscribe(async (user: UserModel) => {
                        if (user.needsSlidSignup) {
                            await this.angularFireAuth.signOut();
                            await this.router.navigate([routes.SIGNUP, { notifySlidSignup: true }]);
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
            } catch (error) {
                console.error(error);
                resolve(false);
            }
        });
    }
    private _providerSignIn(provider, referrerParam?) {
        return this.angularFireAuth.signInWithPopup(provider).then(async (userCredential) => {
            // const userExists = await this.userService.checkUserExists(userCredential.user.uid);
            const needsSlidSignup = await this.handleNonSlidSignup();
            if (!needsSlidSignup) {
                const loadedUser = await this.userService.loadUserOnce();
                // if the user existed before stripe customers were created on signup
                // no customerId is found. Then a customer will be created manually now

                // If the user has no profilepicture or it's a new URL -> update it
                if (
                    !loadedUser.profilePicture ||
                    (loadedUser.profilePicture !== loadedUser.profilePicture && !!loadedUser.profilePicture)
                ) {
                    this.userService.updateProfilePictureUrl(loadedUser, userCredential.user.photoURL);
                }
                this.analyticsService.track(AnalyticsEvents.SIGNIN_COMPLETE, {
                    provider: provider.providerId,
                    device: this.deviceService.device,
                    isMobile: this.deviceService.isMobile()
                });
                console.log(this.redirectUrl.pathname);

                this.router
                    .navigate([this.redirectUrl.pathname || routes.EDITOR], { queryParamsHandling: 'preserve' })
                    .then();
            }
        });
    }

    generateProjectId() {
        return this.projectService.generateId();
    }
}
