<form [formGroup]="feedbackForm" (ngSubmit)="submitFeedback()" class="">
    <div class="form-tile">
        <!-- <label for="feedback" class="">Is there anything you'd like to tell us? 💬</label> -->
        <!-- <div class="">
      <input id="feedback" type="text" formControlName="feedback" class="form-input" name="feedback"
        placeholder="Enter feedback">

    </div> -->
        <h3>
            <ng-content></ng-content>
        </h3>
        <textarea
            [attr.disabled]="feedbackSubmitted ? true : null"
            id="feedback"
            type="text"
            formControlName="feedback"
            class="form-input mb-1"
            name="feedback"
            rows="5"
            cols="58"
            placeholder="Tell us your feedback..."
            #feedbackInput
        ></textarea>
    </div>

    <div *ngIf="error">
        <p class="error-message">Oops, the textfield still seems to be empty.</p>
    </div>

    <div class="form-group">
        <div>
            <app-button type="submit" class="mx-auto" color="primary" [disabled]="feedbackSubmitted">
                {{ !feedbackSubmitted ? 'Send' : !submissionCompleted ? 'Sending...' : ' Thank you 🙏' }}
            </app-button>
        </div>
    </div>
</form>
