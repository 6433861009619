<app-dialog-header>
    Shortcuts
    <app-dialog-close-button appDialogClose></app-dialog-close-button>
</app-dialog-header>
<div>
    Show shortcut list
    <div class="key">h</div>
</div>
<div class="shortcut-list">
    <div class="shortcuts-section">
        <h4>Video editing</h4>
        <div class="shortcut-single" *ngFor="let hotkey of hotkeysVideoEditingLeft">
            <div>{{ hotkey.title }}</div>
            <div class="keys">
                <ng-container *ngFor="let key of hotkey.keys">
                    <svg-icon *ngIf="key === 'mouse'; else noMouse" src="assets/ic/mouse.svg"></svg-icon>
                    <ng-template #noMouse>
                        <div class="key">{{ key }}</div>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="shortcuts-section">
        <div class="shortcut-single" *ngFor="let hotkey of hotkeysVideoEditingRight">
            <div>{{ hotkey.title }}</div>
            <div class="keys">
                <ng-container *ngFor="let key of hotkey.keys">
                    <svg-icon *ngIf="key === 'mouse'; else noMouse" src="assets/ic/mouse.svg"></svg-icon>
                    <ng-template #noMouse>
                        <div class="key">{{ key }}</div>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="shortcuts-section ml-8">
        <h4>Text correction</h4>
        <div class="shortcut-single" *ngFor="let hotkey of hotkeysTextCorrection">
            <div>{{ hotkey.title }}</div>
            <div class="keys">
                <ng-container *ngFor="let key of hotkey.keys">
                    <svg-icon *ngIf="key === 'mouse'; else noMouse" src="assets/ic/mouse.svg"></svg-icon>
                    <ng-template #noMouse>
                        <div class="key">{{ key }}</div>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </div>
</div>
