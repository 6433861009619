<button [appTriggerFor]="helpMenu" class="help-menu-button bg-white rounded-full" data-spec="help-menu-button">
    <svg-icon appIcon src="/assets/ic/24/questionmark.svg" class="w-10 h-10 icon-size-2xl"></svg-icon>
</button>

<app-menu #helpMenu="appMenu">
    <!-- <app-menu-item data-spec="open-help-center-menu-item" (btnClick)="openHelpCenter()">
        <span class="mr-2 text-base">📚</span>Help center
    </app-menu-item> -->
    <app-menu-item
        data-spec="chat-menu-item"
        (btnClick)="openLink('https://streamlabs.com/content-hub/tools/podcast-editor')"
    >
        <span class="mr-2 text-base">📚</span>Help center</app-menu-item
    >
    <app-menu-item data-spec="feature-request-menu-item" (btnClick)="contactViaForm('support')">
        <span class="mr-2 text-base">🛠</span>Request a feature
    </app-menu-item>
    <app-menu-item data-spec="chat-menu-item" (btnClick)="contactViaForm('support')">
        <span class="mr-2 text-base">🧑</span>Talk to a human</app-menu-item
    >
</app-menu>

<ng-template let-ref="ref" #featureRequestDialog>
    <ng-container>
        <app-dialog-header>
            Send feedback
            <app-dialog-close-button (click)="ref.close()"></app-dialog-close-button>
        </app-dialog-header>
        <app-contact></app-contact>
    </ng-container>
</ng-template>
