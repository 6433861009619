import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import routes from '@type/shared-models/consts/routes';
import { map, take, tap } from 'rxjs/operators';
import { deleteCookie } from '@type/shared/utils/cookies';

@Injectable()
export class GuestGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.auth.firebaseAuth$.pipe(
            take(1),
            map((user) => !user),
            tap((guest) => {
                if (!guest) {
                    this.router.navigate([routes.EDITOR], { queryParamsHandling: 'preserve' });
                } else {
                    deleteCookie('isTypeUser');
                }
            })
        );
    }
}
