export const fbq = (...args: any[]) => typeof window.fbq === 'function' && window.fbq(...args);

const track = (eventConfig: {
    name: string;
    category?: string;
    label?: string;
    once?: boolean;
    customProperties?: {};
}) => {
    if (eventConfig.once) {
        const key = `tracking::${eventConfig.category}::${eventConfig.label}`;
        if (localStorage.getItem(key)) {
            return;
        }

        localStorage.setItem(key, '1');
    }
    console.log('Track event', eventConfig);
    window.dataLayer.push({
        event: eventConfig.name,
        event_category: eventConfig.category,
        event_label: eventConfig.label,
        ...eventConfig.customProperties
    });
    fbq('track', eventConfig.name);

    if (typeof window.uetq !== 'undefined') {
        window.uetq.push('event', eventConfig.name, {
            event_category: eventConfig.category,
            event_label: eventConfig.label
        });
    }
};
export const loadTrackers = (window, document, googleTagId, fbPixelId, uetqId) => {
    loadGtag(window, document, googleTagId);
    loadFBQ(window, document, fbPixelId);
    loadUETQ(window, document, uetqId);
};

const loadGtag = (window, document, googleTagId) => {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = document.getElementsByTagName('script')[0];
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtm.js?id=' + googleTagId;
    f.parentNode.insertBefore(script, f);

    // window.gtag('js', new Date());
    // window.gtag('config', googleTagId);
};
const loadFBQ = (window, document, fbPixelId) => {
    (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) {
            f._fbq = n;
        }
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', fbPixelId);
    fbq('track', 'PageView');
    // console.log('fbq loaded');
};
const loadUETQ = (window, document, uetqId) => {
    (function (w, d, t, r, u) {
        var f, n, i;
        (w[u] = w[u] || []),
            (f = function () {
                var o = { ti: uetqId, enableAutoSpaTracking: true, q: w[u] };
                (o.q = w[u]), (w[u] = new w.UET(o)), w[u].push('pageLoad');
            }),
            (n = d.createElement(t)),
            (n.src = r),
            (n.async = 1),
            (n.onload = n.onreadystatechange =
                function () {
                    var s = this.readyState;
                    (s && s !== 'loaded' && s !== 'complete') || (f(), (n.onload = n.onreadystatechange = null));
                }),
            (i = d.getElementsByTagName(t)[0]),
            i.parentNode.insertBefore(n, i);
    })(window, document, 'script', 'https://bat.bing.com/bat.js', 'uetq');
    // console.log('uetq loaded');
};

export const trackLogin = () =>
    track({
        name: 'podcast_editor_log_in',
        category: 'Podcast Editor Login',
        label: 'Podcast Editor Login',
        once: false
    });
export const trackSignUp = () =>
    track({
        name: 'sign_up',
        category: 'Podcast Editor SignUp',
        label: 'Podcast Editor Account Creation',
        once: false,
        customProperties: {
            source: 'podcast_editor',
            sign_up_platform: 'id.streamlabs.com'
        }
    });

export const trackPurchase = (planType: string) =>
    track({
        name: 'podcast_editor_purchase',
        category: 'Podcast Editor Purchase',
        label: 'Podcast Editor Purchase',
        once: false,
        customProperties: {
            plan_type: planType.toLocaleLowerCase()
        }
    });

export const trackCancel = () =>
    track({
        name: 'podcast_editor_cancel',
        category: 'Podcast Editor Cancel',
        label: 'Podcast Editor Cancel',
        once: false
    });

export const trackEditorLoad = () =>
    track({
        name: 'podcast_editor_editor_load',
        category: 'Podcast Editor Editor Load',
        label: 'Podcast Editor Editor Page Load',
        once: false
    });
export const trackRenderingStarted = () =>
    track({
        name: 'podcast_editor_rendering_started',
        category: 'Podcast Editor Rendering Started',
        label: 'Podcast Editor Editor Rendering Started',
        once: false
    });
