import { Injectable } from '@angular/core';
import { PlanDetails, PlanInterval, PlanTable, PlanType } from '@type/shared-models/payment/products';
import { PromotionCode } from '@type/shared-models/payment/promotion-code.models';
import { BehaviorSubject, Subject, Subscription, of } from 'rxjs';
import { catchError, filter, first, map, take } from 'rxjs/operators';
import { AnalyticsEvents } from '@type/shared-models/consts/analytic-events';
import ErrorMessages from '@type/shared-models/consts/error-messages';
import { User } from '@type/shared-models/user/user';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import axios from 'axios';
import { CreateSubscriptionRequestData } from '@type/shared-models/payment/create-subscription-data';
import { OrganizationService } from '../services/organization.service';
import { environment } from '../../environments/environment';
import { UserService } from '../services/user.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FirebaseFunctionNames } from '@type/shared-models/consts/firebase-function-names';
import { FirebaseAuthErrorCodes } from '@type/shared-models/auth/error.models';
import { trackPurchase } from '../../../../../libs/shared-models/src/lib/utils/tracking';

@Injectable()
export class UpgradeService {
    planTable = environment.production ? PlanTable.production : PlanTable.development;

    planInterval$: BehaviorSubject<PlanInterval> = new BehaviorSubject(PlanInterval.annually);
    paymentSuccess$ = new Subject();
    get planInterval(): PlanInterval {
        return this.planInterval$.value;
    }
    set planInterval(planInterval: PlanInterval) {
        this.planInterval$.next(planInterval);
        this.updatePlanDetails();
    }

    planType$: BehaviorSubject<PlanType> = new BehaviorSubject(PlanType.STARTER_BASIC);
    get planType(): PlanType {
        return this.planType$.value;
    }
    set planType(planType: PlanType) {
        this.planType$.next(planType);
        this.updatePlanDetails();
        // Just a backup. Members of organizations should never be able to access this page but who knows..
        this.organizationService
            .isOrganizationMember(false, true)
            .pipe(take(1))
            .subscribe((isMember) => {
                if (isMember) {
                    this.planType$.next(null);
                    this.updatePlanDetails();
                }
            });
    }

    planDetails$: BehaviorSubject<PlanDetails> = new BehaviorSubject(this.planTable.pro.annually);

    // credit card payment loading state
    loading = false;

    promotionCodeData$: BehaviorSubject<PromotionCode> = new BehaviorSubject(null);
    cardError: string;

    // PlanSelector
    availablePlans: PlanType[];

    // IntervalSelector
    availableIntervals: PlanInterval[];

    private stripe$: BehaviorSubject<Stripe> = new BehaviorSubject(null);

    public get stripe(): Promise<Stripe> {
        return this.stripe$
            .pipe(
                filter((stripe) => stripe != null),
                first()
            )
            .toPromise();
    }

    private userSubscription: Subscription;

    constructor(
        private userService: UserService,
        private organizationService: OrganizationService,
        private firebaseFunctions: AngularFireFunctions
    ) {
        this.initStripe();
        this.subscribeUserChanges();
    }
    async initStripe() {
        this.stripe$.next(await loadStripe(environment.stripeKey));
    }
    destroy() {
        this.userSubscription?.unsubscribe();
    }

    //  Section: Handling Payment
    public async submitPayment(card, planType: PlanType, reason?: string) {
        this.planType = planType;
        window.analytics.track(AnalyticsEvents.PAYMENT_SUBMITTED, {
            plan: this.planType,
            frequency: this.planInterval
        });

        this.loading = true;
        const { paymentMethod, error } = await this.createPaymentMethod(card);
        if (paymentMethod) {
            console.log('paymentMethod created successfully');
            console.log('submitPayment -> paymentMethod', paymentMethod);

            await this.createSubscription(paymentMethod, reason);
        } else {
            this.loading = false;
            this.onError(error);
        }
    }

    private subscribeUserChanges() {
        this.userSubscription = this.userService.user$.pipe(filter((user) => user != null)).subscribe((user) => {
            this.initAvailablePlans(user);
            this.initAvailableIntervals(user);
        });
    }

    private initAvailablePlans(user: User) {
        this.availablePlans = [];
        switch (user.subscriptionState?.plan?.type) {
            case PlanType.TRIAL:
            case PlanType.EXPIRED:
                this.availablePlans.push(PlanType.STARTER_BASIC);
                this.availablePlans.push(PlanType.PRO_TEAM);
                this.availablePlans.push(PlanType.BUSINESS);
                break;
            case PlanType.BASIC:
                this.availablePlans.push(PlanType.PRO_TEAM);
                this.availablePlans.push(PlanType.BUSINESS);
                this.planType = PlanType.PRO_TEAM;
                break;
            case PlanType.STARTER:
                this.availablePlans.push(PlanType.PRO_TEAM);
                this.availablePlans.push(PlanType.BUSINESS);
                this.planType = PlanType.PRO_TEAM;
                break;
            case PlanType.PRO_TEAM:
                this.availablePlans.push(PlanType.BUSINESS);
                this.planType = PlanType.BUSINESS;
        }
    }
    private initAvailableIntervals(user: User) {
        this.availableIntervals = [];

        const intervalDurationDays = (user.subscriptionState.endDate - user.subscriptionState.startDate) / 60 / 60 / 24;
        if (intervalDurationDays > 360) {
            this.availableIntervals.push(PlanInterval.annually);
            this.planInterval = PlanInterval.annually;
        } else {
            this.availableIntervals.push(PlanInterval.monthly);
            this.availableIntervals.push(PlanInterval.annually);
        }
    }

    private updatePlanDetails() {
        if (this.planType) {
            this.planDetails$.next(this.planTable[this.planType][this.planInterval || PlanInterval.annually]);
        } else {
            this.planDetails$.next(null);
        }
    }

    private async createPaymentMethod(card) {
        return await (
            await this.stripe
        ).createPaymentMethod({
            type: 'card',
            card
        });
    }

    async createSubscription(
        paymentMethod,
        email?: string,
        paymentReason?: string,
        usedPaymentRequestButton?: boolean
    ): Promise<boolean> {
        // Checks if every needed variable is set and send subscription to firebaseFunction
        if (this.planInterval && paymentMethod) {
            try {
                const createSubscriptionRequestData: CreateSubscriptionRequestData = {
                    paymentMethodId: paymentMethod.id,
                    firebaseId: this.userService.user?.firebaseId,
                    customerId: this.userService.user?.customerId,
                    email: this.userService.user ? this.userService.user.email : email,
                    plan: this.planType,
                    frequency: this.planInterval,
                    promotionCodeId: this.promotionCodeData$.value?.promotionCodeId,
                    paymentReason,
                    usedPaymentRequestButton
                };

                this.firebaseFunctions
                    .httpsCallable(FirebaseFunctionNames.createSubscription)(createSubscriptionRequestData)
                    .pipe(
                        map((response) => ({
                            isSuccess: response?.latest_invoice?.payment_intent?.status === 'succeeded',
                            subscription: response?.data,
                            invoice: response?.latest_invoice,
                            clientSecret: response?.latest_invoice.payment_intent?.client_secret
                        })),
                        catchError((error) => {
                            if (!error?.code) {
                                this.onError({ message: 'There was a problem with this payment. Please try again.' });
                            } else {
                                this.onError(error);
                            }

                            return of(null);
                        })
                    )
                    .subscribe(async (result) => {
                        if (!result) {
                            this.loading = false;
                            return false;
                        }

                        const { subscription, invoice } = result;

                        // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
                        // If it's a retry, the payment intent will be on the invoice itself.
                        console.log('invoice', invoice);
                        const paymentIntent = invoice
                            ? invoice.payment_intent
                            : subscription?.latest_invoice?.payment_intent;

                        // isRetry === true &&
                        if (paymentIntent.status === 'requires_action') {
                            // Start Authentication UI
                            const secure3dResponse = await (
                                await this.stripe
                            ).confirmCardPayment(paymentIntent.client_secret, {
                                payment_method: paymentMethod.id
                            });
                            console.log('Secure3dResponse:', secure3dResponse);
                            if (secure3dResponse.error) {
                                // Start code flow to handle updating the payment details.
                                // Display error message in your UI.
                                // The card was declined (i.e. insufficient funds, card has expired, etc).
                                this.onError(secure3dResponse.error);
                                return false;
                            } else {
                                if (secure3dResponse.paymentIntent.status === 'succeeded') {
                                    this.onSucceed();
                                    return true;
                                }
                            }
                        } else if (paymentIntent.status === 'requires_payment_method') {
                            this.onError(Error(ErrorMessages.CardDeclined));
                            return false;
                        } else if (paymentIntent.status === 'succeeded') {
                            this.onSucceed();
                            return true;
                        }
                    });
            } catch (error) {
                this.onError(error);
                console.error(error);
                return false;
            }
        }
    }

    onSucceed() {
        window.analytics.track(AnalyticsEvents.PAYMENT_SUCCEEDED);
        trackPurchase(this.planType);
        this.paymentSuccess$.next(true);
        this.loading = false;
    }

    onError(error) {
        window.analytics.track(AnalyticsEvents.PAYMENT_ERROR_OCCURRED, { error });

        if (error.message) {
            this.loading = false;
            this.cardError = error.message;
        }
    }
}
