import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AnalyticsEvents } from '@type/shared-models/consts/analytic-events';
import { FeedbackService } from '../../services/feedback.service';
import { AnalyticsService } from '../../services/analytics.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent {
    @Input() isLanguageRequest = false;

    feedbackInput: ElementRef;
    @ViewChild('feedbackInput')
    set editTitleInputProperty(content: ElementRef) {
        if (content) {
            // initially setter gets called with undefined
            this.feedbackInput = content;
            this.feedbackInput.nativeElement.focus();
            // keycodes that should be blocked since they are used in the editing
            const editingBlockedKey = [8, 32, 37, 39, 46, 190, 191];

            this.feedbackInput.nativeElement.addEventListener('keydown', (event) => {
                if (editingBlockedKey.includes(event.keyCode)) {
                    event.stopPropagation();
                }
            });
            this.feedbackInput.nativeElement.addEventListener('keyup', (event) => {
                if (editingBlockedKey.includes(event.keyCode)) {
                    event.stopPropagation();
                }
            });
        }
    }

    feedbackForm: FormGroup = new FormGroup({
        feedback: new FormControl('', [Validators.required])
    });

    public get feedback(): string {
        return this.feedbackForm.get('feedback').value;
    }

    feedbackSubmitted = false;
    submissionCompleted = false;

    error = false;
    constructor(
        private feedbackService: FeedbackService,
        private analyticsService: AnalyticsService,
        private userService: UserService
    ) {}

    async submitFeedback() {
        if (this.feedbackForm.valid) {
            try {
                this.feedbackSubmitted = true;
                this.error = false;
                if (this.isLanguageRequest) {
                    this.sendLanguageRequest();
                } else {
                    this.sendFeedback();
                }
            } catch (error) {
                this.error = true;
                this.feedbackSubmitted = false;
            }
        } else {
            this.error = true;
            this.feedbackSubmitted = false;
        }
    }

    private async sendFeedback() {
        this.analyticsService.trackServerside(AnalyticsEvents.FEEDBACK_SEND, this.userService.user?.firebaseId, {});
        this.feedbackService.sendFeedbackRequest(this.feedback).subscribe(() => {
            this.submissionCompleted = true;
        });
    }
    private sendLanguageRequest() {
        this.analyticsService.trackServerside(AnalyticsEvents.LANGUAGE_REQUESTED, this.userService.user?.firebaseId, {
            language: this.feedback.trim().toLowerCase()
        });
        this.submissionCompleted = true;
    }
}
