import { Directive, HostListener, Input, isDevMode } from '@angular/core';
import { DialogService } from '@type/dialog';
import { getEnvironmentUrl } from '../../../environments/environment.utils';

@Directive({
    selector: '[appOpenOnDesktop]'
})
export class OpenOnDesktopDirective {
    @Input() appOpenOnDesktop: string;
    @Input() disableOpenOnDesktop: boolean;
    @Input() inProgressOpenOnDesktop: boolean;

    constructor(private dialogService: DialogService) {}

    @HostListener('click')
    openOnDesktop() {
        if (this.disableOpenOnDesktop) {
            return;
        }

        const baseUrl = getEnvironmentUrl();
        const resourceUrl = `${baseUrl}/${this.appOpenOnDesktop}`;
        if (navigator.share) {
            navigator
                .share({
                    title: 'Open on desktop',
                    url: resourceUrl
                })
                .then(() => {
                    console.log('Thanks for sharing!');
                })
                .catch(console.error);
        } else {
            this.dialogService.openToast({
                data: {
                    message: this.inProgressOpenOnDesktop
                        ? 'Please wait, exporting video...'
                        : 'Link copied to your clipboard'
                }
            });
            this._copyLink(resourceUrl);
        }
    }

    private _copyLink(url: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = url;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
}
