import { Component } from '@angular/core';

@Component({
    selector: 'app-camera-microphone-access',
    templateUrl: './camera-microphone-access.component.html',
    styleUrls: ['./camera-microphone-access.component.scss']
})
export class CameraMicrophoneAccessComponent {
    reloadPage() {
        window.location.reload();
    }
}
