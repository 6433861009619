import { CanvasElement } from '@type/shared-models/models/canvas/canvas-element';
import { BehaviorSubject } from 'rxjs';
import { CanvasElementType } from '@type/shared-models/canvas-elements';
import Konva from 'konva-custom';
import { MediaType } from '@type/shared-models/media/media.utils';
import { ImageConfig } from 'konva-custom/lib/shapes/Image';
import { StartEndConfig } from '@type/shared-models/start-end-word.model';
import { Word } from '@type/shared-models/editor/word';
import { VideoDimension } from '@type/shared-models/video-dimension';
import { Box } from 'konva-custom/lib/shapes/Transformer';
import { RectConfig } from 'konva-custom/lib/shapes/Rect';
import { Resolution } from '@type/shared-models/resolution';

export interface MainVideoConfig {
    videoElement: HTMLVideoElement;
    x: number;
    y: number;
    rotation: number;
    scale: number;
    width: number;
    height: number;
    mediaUri: string;
    mediaUrl: string;
    mediaDuration: number;
    mediaType: MediaType;
    volume: number;
    mediaIndex: number;
    previewUrl?: string;
    deleteAfterFinishedTranscription?: boolean;
}

export class VideoElement extends CanvasElement {
    public zIndexWeight = 2; // Video + Transformer
    public updateElement$: BehaviorSubject<VideoElement> = new BehaviorSubject(null);
    public mediaUri: string;
    public mediaUrl: string;
    public mediaDuration: number;
    public mediaType: MediaType;
    public resolution: Resolution;
    public previewUrl?: string;
    public mediaIndex: number;
    public konvaElID: string;
    public videoElement: HTMLVideoElement;
    public deleteAfterFinishedTranscription = false;

    private _volume: number;

    public get volume(): number {
        return this._volume;
    }

    public set volume(newVolume: number) {
        this._volume = newVolume;
        if (this.videoElement) {
            this.videoElement.volume = this.volume;
        }
        this.configurationChanged();
    }

    constructor(
        elementsInitialized: BehaviorSubject<{
            [uuid: string]: boolean;
        }> = null,
        serializedElement: string = null,
        ID: string = null,
        startEndConf: StartEndConfig,
        zIndex: number,
        mainVideoConfig: MainVideoConfig = null,
        mediaIndex: number,
        encloseTranscript?: boolean
    ) {
        super(ID, startEndConf, encloseTranscript);
        this.konvaElID = ID;
        this.zIndex = zIndex;
        this.mediaIndex = mediaIndex;
        this.type = CanvasElementType.MEDIA;
        // 1. serializedElement
        // 2. mainVideoConfig
        // 3. newKonvaElement -> not a thing for now
        if (serializedElement) {
            this.fromJSON(serializedElement);
            this.mediaDuration = mainVideoConfig.mediaDuration;
            this.mediaIndex = mainVideoConfig.mediaIndex;
            this.mediaType = mainVideoConfig.mediaType;
            this.mediaUri = mainVideoConfig.mediaUri;
            this.mediaUrl = mainVideoConfig.mediaUrl;
            this.previewUrl = mainVideoConfig.previewUrl;
            this.volume = mainVideoConfig.volume;
            this.resolution = { width: mainVideoConfig.width, height: mainVideoConfig.height };
            this.deleteAfterFinishedTranscription = mainVideoConfig.deleteAfterFinishedTranscription;
        } else {
            if (mainVideoConfig) {
                // Render Video Element for the main video of the project
                const elementsInitializedNew = {
                    ...elementsInitialized.value,
                    [this.ID]: false
                };
                elementsInitialized.next(elementsInitializedNew);
                this.createVideo(ID, mainVideoConfig, elementsInitialized);
            } else {
                this.newKonvaElement();
            }
        }
    }

    fromJSON(serializedElement: string) {
        const videoElement: Konva.Image = Konva.Node.create(serializedElement);
        videoElement.show();
        this.konvaElement = videoElement;
        this.konvaElID = videoElement.id();
    }

    newKonvaElement() {
        // TODO: implement
    }

    public changeVideo(
        newVideo: HTMLVideoElement,
        updatedMediaCanvasElement: VideoElement,
        onlyUpdateAttributes: boolean = false
    ) {
        if (!onlyUpdateAttributes) {
            this.videoElement = newVideo;
            (this.konvaElement as Konva.Image).image(newVideo);
        }
        if (updatedMediaCanvasElement) {
            this.applyVideoAttributes(updatedMediaCanvasElement.konvaElement.getAttrs());
        }
    }

    public getClipDimensions(): { width: number; height: number } {
        return {
            width: this.konvaElement.getClientRect().width,
            height: this.konvaElement.getClientRect().height
        };
    }

    private createVideo(
        id: string,
        mainVideoConfig: MainVideoConfig,
        elementsInitialized: BehaviorSubject<{ [uuid: string]: boolean }>
    ) {
        this.videoElement = mainVideoConfig.videoElement;
        this.resolution = { width: mainVideoConfig.width, height: mainVideoConfig.height };
        this.konvaElement = new Konva.Image({
            id,
            image: this.videoElement,
            draggable: true,
            x: mainVideoConfig.x,
            y: mainVideoConfig.y,
            rotation: mainVideoConfig.rotation,
            scaleX: mainVideoConfig.scale,
            scaleY: mainVideoConfig.scale,
            width: mainVideoConfig.width,
            height: mainVideoConfig.height
        });
        this.mediaDuration = mainVideoConfig.mediaDuration;
        this.mediaIndex = mainVideoConfig.mediaIndex;
        this.mediaType = mainVideoConfig.mediaType;
        this.mediaUri = mainVideoConfig.mediaUri;
        this.mediaUrl = mainVideoConfig.mediaUrl;
        this.previewUrl = mainVideoConfig.previewUrl;
        this.volume = mainVideoConfig.volume;
        this.deleteAfterFinishedTranscription = mainVideoConfig.deleteAfterFinishedTranscription;
        const elementsInitializedNew = {
            ...elementsInitialized.value,
            [this.ID]: true
        };
        elementsInitialized.next(elementsInitializedNew);
    }

    public applyVideoAttributes(attributes: Partial<ImageConfig>) {
        attributes = {
            ...attributes,
            image: this.konvaElement.attrs.image,
            id: this.konvaElID || 'video',
            scaleX: attributes.scaleX || 1,
            scaleY: attributes.scaleY || 1,
            x: attributes.x || 0,
            y: attributes.y || 0,
            rotation: attributes.rotation
        };
        if (
            this.konvaElement.attrs.cropX &&
            this.konvaElement.attrs.cropY &&
            this.konvaElement.attrs.cropWidth &&
            this.konvaElement.attrs.cropHeight
        ) {
            attributes = {
                ...attributes,
                width: this.konvaElement.attrs.width,
                height: this.konvaElement.attrs.height,
                cropX: this.konvaElement.attrs.cropX,
                cropY: this.konvaElement.attrs.cropY,
                cropWidth: this.konvaElement.attrs.cropWidth,
                cropHeight: this.konvaElement.attrs.cropHeight,
                draggable: this.konvaElement.attrs.draggable
            };
        }
        // console.log('🚀 ~ attributes:', attributes);
        this.konvaElement?.setAttrs(attributes);
        this.layer?.draw();
    }

    public fixZIndexCustom(canvasElZIndex: number) {
        this.zIndex = canvasElZIndex;
    }

    protected changeZIndexCustom(zIndex: number) {
        this.konvaElement.zIndex(zIndex);
        this.transformer?.zIndex(zIndex + 1);
    }

    log(text = '') {
        console.log(`${text} ~ [${this.mediaIndex}] - ${this.ID} - ${this.konvaElID}- ${this.getAsJSONstring()}`);
    }

    cropVideo(stageSize: VideoDimension, bounds: { x: number; y: number; width: number; height: number }) {
        this.konvaElement.setAttrs({
            ...stageSize
        });
        (this.konvaElement as Konva.Image).crop({
            ...bounds
        });
        this.layer.draw();
    }
}
