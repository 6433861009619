import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-input-field',
    template: `
        <label class="block">
            <span class="text-gray-700 mb-1" *ngIf="label">{{ label }}</span>
            <input [formControl]="control" class="form-input block w-full" placeholder="{{ placeholder }}" />
        </label>
    `,
    styles: [
        `
            .form-input {
                background: none;
                padding-left: 16px;
            }
        `
    ]
})
export class TypInputFieldComponent {
    @Input() label: string;
    @Input() placeholder: string;
    @Input() control: FormControl;
}
