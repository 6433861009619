export function getCurrentUnixTimestamp(): number {
    return Math.floor(new Date().getTime() / 1000);
}

export function addMonthsToDate(date: Date, months: number): Date;
export function addMonthsToDate(date: number, months: number): number;
export function addMonthsToDate(date: Date | number, months: number): Date | number {
    if (date instanceof Date) {
        const d = date.getDate();
        date.setMonth(date.getMonth() + +months);
        if (date.getDate() !== d) {
            date.setDate(0);
        }
        return date;
    } else {
        const dateObj = dateFromTimestamp(date);
        const d = dateFromTimestamp(date).getDate();
        dateObj.setMonth(dateObj.getMonth() + +months);
        if (dateObj.getDate() !== d) {
            dateObj.setDate(0);
        }
        return toUnixTimestamp(dateObj);
    }
}
export function toUnixTimestamp(date: Date): number {
    return Math.floor(date.getTime() / 1000);
}
export function dateFromTimestamp(timestamp: number): Date {
    return new Date(timestamp * 1000);
}

export * as DateUtils from './date-utils';
