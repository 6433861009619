<div class="flex justify-around content-center font-medium min-w-220">
    <app-button size="md" (btnClick)="mute()">
        <span [hidden]="volume <= 0.5">
            <svg-icon appIcon class="icon-size-xl">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.1703 6.31L7.156 9.52142H3.94458V14.3386H7.156L11.1703 17.55V6.31Z"
                        stroke="currentColor"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M17.6493 6.25378C19.1544 7.75936 19.9999 9.80109 19.9999 11.93C19.9999 14.0589 19.1544 16.1006 17.6493 17.6062M14.8152 9.08787C15.5677 9.84065 15.9905 10.8615 15.9905 11.926C15.9905 12.9904 15.5677 14.0113 14.8152 14.7641"
                        stroke="currentColor"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </svg-icon>
        </span>
        <span [hidden]="volume > 0.5 || volume < 0.01">
            <svg-icon appIcon class="icon-size-xl">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.1703 6.31L7.156 9.52142H3.94458V14.3386H7.156L11.1703 17.55V6.31Z"
                        stroke="currentColor"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M14.8152 9.08787C15.5677 9.84065 15.9905 10.8615 15.9905 11.926C15.9905 12.9904 15.5677 14.0113 14.8152 14.7641"
                        stroke="currentColor"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </svg-icon>
        </span>
        <span [hidden]="volume !== 0">
            <svg-icon appIcon class="icon-size-xl">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.1703 6.31L7.156 9.52142H3.94458V14.3386H7.156L11.1703 17.55V6.31Z"
                        stroke="currentColor"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </svg-icon>
        </span>
    </app-button>
    <mat-slider
        [ngModel]="volume"
        (input)="sliderChanged($event)"
        (change)="emitVolume()"
        step="0.01"
        [max]="1"
        [min]="0"
        aria-label="units"
    ></mat-slider>
    <div class="grid content-center ml-2 typ-p w-9">{{ volume * 100 | number: '1.0-0' }}%</div>
</div>
