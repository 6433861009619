import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { UpgradeService } from '../../mobile-upgrade-dialog/upgrade.service';
import { UserService } from '../../services/user.service';
import { PlanElement, PlanInterval } from '@type/shared-models/payment/products';
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-upgrade-credit-card-input',
    templateUrl: './upgrade-credit-card-input.component.html',
    styleUrls: ['./upgrade-credit-card-input.component.scss']
})
export class UpgradeCreditCardInputComponent implements OnDestroy {
    showPaymentRequestButton = false;
    paymentRequestButton: HTMLElement;
    @ViewChild('paymentRequestButton') set paymentRequestButtonProperty(content: ElementRef) {
        if (content) {
            this.paymentRequestButton = content.nativeElement;
            this.initPaymentRequestButton();
        }
    }
    cardInfo: HTMLElement;
    @ViewChild('cardInfo') set cardInfoProperty(content: ElementRef) {
        if (content) {
            this.cardInfo = content.nativeElement;
            this.initiateCardElement();
        }
    }
    card;
    private _selectedPlanData: PlanElement;
    get selectedPlanData() {
        return this._selectedPlanData;
    }
    @Input() set selectedPlanData(planData: PlanElement) {
        this._selectedPlanData = planData;
        this.upgradeService.planType = planData.planType;
        this.initPaymentRequestButton();
    }

    private _selectedInterval;
    get selectedInterval() {
        return this._selectedInterval;
    }
    @Input() set selectedInterval(interval: PlanInterval) {
        this._selectedInterval = interval;
        this.upgradeService.planInterval = interval;
        this.initPaymentRequestButton();
    }
    @Input() paymentReason: string;
    planDetailsSubscription: Subscription;

    onChange = (change) => {
        const { error } = change;
        if (error) {
            this.upgradeService.cardError = error.message;
        } else {
            this.upgradeService.cardError = null;
        }
        this.cdRef.detectChanges();
    };

    constructor(
        public upgradeService: UpgradeService,
        private cdRef: ChangeDetectorRef,
        public userService: UserService
    ) {}

    ngOnDestroy() {
        if (this.card) {
            // We remove event listener here to keep memory clean
            this.card.removeEventListener('change', this.onChange);
            this.card.destroy();
        }
    }

    async initiateCardElement() {
        this.card = (await this.upgradeService.stripe).elements().create('card', {
            hidePostalCode: false,
            style: {
                base: {
                    color: '#969696',
                    fontFamily: '"Inter", Helvetica, sans-serif',
                    fontSize: '16px',
                    '::placeholder': {
                        color: '#969696'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            }
        });
        this.card.mount(this.cardInfo);
        this.card.addEventListener('change', this.onChange);
    }

    submitPayment() {
        this.upgradeService.submitPayment(this.card, this.selectedPlanData.planType, this.paymentReason).then((res) => {
            console.log(res);
        });
    }

    /**
     * Apple Pay
     * Google Pay
     */

    async initPaymentRequestButton() {
        const calculatedPrice =
            this.selectedInterval === PlanInterval.annually
                ? this.selectedPlanData.yearlyPrice
                : this.selectedPlanData.monthlyPrice;

        const paymentRequest = (await this.upgradeService.stripe).paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: this.selectedPlanData.planType,
                amount: calculatedPrice
            }
        });
        const prButton = (await this.upgradeService.stripe)
            .elements()
            .create('paymentRequestButton', { paymentRequest });

        // Check the availability of the Payment Request API first.
        const canMakePayment = false; //await paymentRequest.canMakePayment();

        console.log('canMakePayment', canMakePayment);
        if (canMakePayment) {
            this.showPaymentRequestButton = true;
            prButton.mount(this.paymentRequestButton);
            paymentRequest.on('paymentmethod', async (event) => {
                const paymentSuccessful = await this.upgradeService.createSubscription(
                    event.paymentMethod,
                    null,
                    this.paymentReason,
                    true
                );
                if (paymentSuccessful) {
                    this.planDetailsSubscription?.unsubscribe();
                    event.complete('success');
                } else {
                    this.planDetailsSubscription?.unsubscribe();
                    event.complete('fail');
                }
            });
        } else {
            document.getElementById('payment-request-button').style.display = 'none';
        }
    }
}
